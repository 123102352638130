export const paletteData = {
  0: ["#ee7722", "#dd44cc", "#ee8833", "#cc99bb", "#775511"],
  1: ["#44ddcc", "#882222", "#883377", "#77cc88", "#bb77ff"],
  2: ["#7733bb", "#992233", "#ff3322", "#44dd88", "#22dd88"],
  3: ["#6644ff", "#ee11ff", "#33eedd", "#0077dd", "#aa6644"],
  4: ["#aa33aa", "#4400ee", "#ffffee", "#338899", "#bb0022"],
  5: ["#bbbb00", "#445544", "#33ccee", "#eeffcc", "#442299"],
  6: ["#bb4444", "#445533", "#55ddee", "#ee6600", "#dd4400"],
  7: ["#bb2222", "#bbeecc", "#77cccc", "#bb6699", "#ffff66"],
  8: ["#00eeff", "#cc4499", "#115511", "#eecc77", "#449922"],
  9: ["#aabbbb", "#bb77aa", "#001177", "#4400ee", "#ccdddd"],
  10: ["#990099", "#33aa00", "#ccbb88", "#aa1144", "#997766"],
  11: ["#bbcccc", "#112277", "#11ee55", "#99cccc", "#99aa44"],
  12: ["#cc6633", "#772244", "#5599ee", "#117733", "#cc9999"],
  13: ["#ccdd33", "#ff5566", "#5566aa", "#cccccc", "#99bbcc"],
  14: ["#003322", "#ff7799", "#ff9900", "#112200", "#22ddee"],
  15: ["#77aa99", "#77bb11", "#44bbcc", "#66bb11", "#ccee77"],
  16: ["#77ee22", "#884477", "#777777", "#663366", "#4488ff"],
  17: ["#aa77dd", "#6677bb", "#bbaaee", "#00dd22", "#00ee33"],
  18: ["#ee8822", "#005511", "#55cc44", "#66ee00", "#dd3355"],
  19: ["#ee33ff", "#445533", "#22ddaa", "#776688", "#2200cc"],
  20: ["#55bbcc", "#88ee66", "#aadd88", "#66cc33", "#770022"],
  21: ["#223333", "#1188dd", "#88dd55", "#66ddaa", "#66ff99"],
  22: ["#770033", "#ccbb66", "#22aacc", "#aa00ee", "#553388"],
  23: ["#00eedd", "#4477ff", "#9977ee", "#aa8899", "#005511"],
  24: ["#4411dd", "#00ee22", "#222233", "#1122dd", "#55cc77"],
  25: ["#bb0055", "#8899dd", "#dd22dd", "#ee6688", "#55ff33"],
  26: ["#bbee77", "#eeccbb", "#bbeebb", "#11aa22", "#bb22bb"],
  27: ["#3388aa", "#338833", "#554433", "#ccccaa", "#008855"],
  28: ["#aa7799", "#99dd77", "#ffaa55", "#771188", "#778855"],
  29: ["#aaee55", "#aa55ff", "#999933", "#77cccc", "#ffdd00"],
  30: ["#773311", "#222222", "#99aa22", "#66ff44", "#113366"],
  31: ["#002211", "#222288", "#88cccc", "#4466cc", "#eeeeee"],
  32: ["#ff7777", "#5566aa", "#ff6677", "#ccddcc", "#77cc00"],
  33: ["#77cc22", "#aa6699", "#001111", "#33bbaa", "#551199"],
  34: ["#55bb00", "#447733", "#88ccdd", "#99ddbb", "#4466bb"],
  35: ["#770088", "#99dd44", "#1199bb", "#aa9999", "#33aa00"],
  36: ["#110088", "#77ffff", "#bb11dd", "#ee7755", "#ddccaa"],
  37: ["#999955", "#bb00aa", "#66aa44", "#22cc22", "#550000"],
  38: ["#4400bb", "#229911", "#772299", "#88ee66", "#33ff55"],
  39: ["#ffccee", "#bb4488", "#66cc66", "#33bb00", "#22ee99"],
  40: ["#bb9922", "#ee00bb", "#99ccbb", "#77bb66", "#aa9944"],
  41: ["#99ccee", "#44aa22", "#55eeff", "#ee4433", "#55dd55"],
  42: ["#44dd55", "#770088", "#991144", "#7777ee", "#ee7788"],
  43: ["#77eeee", "#ccaa99", "#ee8800", "#66ee33", "#1177dd"],
  44: ["#44bb55", "#88dd44", "#aabb77", "#660066", "#994400"],
  45: ["#bbaa99", "#446600", "#ccff00", "#0044bb", "#33dd88"],
  46: ["#0044dd", "#aaaaaa", "#44dddd", "#33ddcc", "#bbdd55"],
  47: ["#ddee99", "#aa7777", "#cc2266", "#7733ee", "#5511ee"],
  48: ["#117700", "#ddcc22", "#bbbbcc", "#aa7766", "#ff0000"],
  49: ["#ee5500", "#aa55dd", "#994400", "#116655", "#bbcc22"],
  50: ["#aa0033", "#ddeeee", "#6644ee", "#554499", "#ff0000"],
  51: ["#77aaee", "#667799", "#44eeaa", "#006688", "#4411ff"],
  52: ["#bb00cc", "#552255", "#99aa55", "#55dd44", "#ff22bb"],
  53: ["#ccee88", "#990011", "#002299", "#ffee88", "#dd0055"],
  54: ["#eedd11", "#eeaa22", "#ccbbff", "#ddffaa", "#bbccaa"],
  55: ["#22ccaa", "#aa00cc", "#336655", "#11ee99", "#333355"],
  56: ["#33ffff", "#ee7777", "#ee11ff", "#1177ff", "#887711"],
  57: ["#bb99bb", "#447788", "#cc7766", "#eeeecc", "#662299"],
  58: ["#ddee00", "#3322aa", "#dd9977", "#cc0066", "#ff4400"],
  59: ["#33aabb", "#447744", "#55ffaa", "#5522dd", "#aa77cc"],
  60: ["#ffffee", "#22ccbb", "#33dd99", "#5522ee", "#1111ff"],
  61: ["#cc6611", "#443399", "#770088", "#440011", "#ff11dd"],
  62: ["#ff55bb", "#dddd88", "#aa0099", "#881177", "#ee2222"],
  63: ["#77cc44", "#bbddaa", "#ff99ff", "#88ff88", "#cc8822"],
  64: ["#4488ee", "#9933aa", "#8877ee", "#aacc44", "#eeee66"],
  65: ["#1144aa", "#cc4477", "#eeeedd", "#ee77ff", "#bbcc11"],
  66: ["#113399", "#331188", "#bbaaff", "#33aa33", "#dd7766"],
  67: ["#22ff99", "#99aa88", "#dd33ee", "#00ffaa", "#55ee11"],
  68: ["#ff3322", "#00eeff", "#bb3377", "#885555", "#ff33dd"],
  69: ["#221111", "#7766bb", "#99dd44", "#990022", "#884477"],
  70: ["#aa7722", "#bb1155", "#ddbb33", "#112266", "#bbbb33"],
  71: ["#3388dd", "#441122", "#773311", "#0088cc", "#bbdd99"],
  72: ["#9911dd", "#99ee99", "#eeaacc", "#771144", "#223344"],
  73: ["#006688", "#dd00ff", "#55ccff", "#226655", "#880077"],
  74: ["#33ddbb", "#cc0099", "#220066", "#eeeeff", "#776600"],
  75: ["#eeffaa", "#115599", "#ff2211", "#667766", "#aabb66"],
  76: ["#662211", "#ccaa22", "#557733", "#3333dd", "#7755cc"],
  77: ["#ffff88", "#ff88cc", "#33cc44", "#99cc55", "#999999"],
  78: ["#88bb88", "#33bb22", "#ee33dd", "#227744", "#008844"],
  79: ["#889966", "#5511ee", "#eeff00", "#553322", "#eecc66"],
  80: ["#224400", "#ee3344", "#330022", "#ffdd22", "#33ffbb"],
  81: ["#ff55ff", "#559900", "#77ffff", "#443366", "#5522ee"],
  82: ["#11aacc", "#772277", "#aaee99", "#88dd88", "#11aa66"],
  83: ["#cc1188", "#ff8811", "#225500", "#88ee99", "#44eeaa"],
  84: ["#775555", "#99eebb", "#ee9900", "#88ccbb", "#449977"],
  85: ["#5577dd", "#7799dd", "#cceeaa", "#4444cc", "#771111"],
  86: ["#dd0099", "#eeddee", "#cc11ff", "#0011ee", "#333311"],
  87: ["#ff8822", "#8800cc", "#bb1122", "#663333", "#339977"],
  88: ["#dd33aa", "#ff1166", "#ee4444", "#99aa00", "#dddd44"],
  89: ["#ddcc88", "#dd55aa", "#5533cc", "#dd66cc", "#331100"],
  90: ["#0044ee", "#44dd88", "#88ffbb", "#111177", "#77dd22"],
  91: ["#ccdd66", "#bbaaaa", "#5500dd", "#556666", "#440077"],
  92: ["#66aaee", "#22aadd", "#11cc33", "#bbcc88", "#ff33bb"],
  93: ["#776688", "#116622", "#bb5599", "#4400ff", "#bb55bb"],
  94: ["#bbcc00", "#dd11ff", "#cc9966", "#ee55bb", "#3377cc"],
  95: ["#7711dd", "#eedd55", "#cc5577", "#33dd55", "#ccaacc"],
  96: ["#ddffaa", "#44ee11", "#88dd66", "#0033aa", "#220055"],
  97: ["#4488bb", "#771100", "#0077ee", "#330055", "#ff3366"],
  98: ["#776633", "#cc00aa", "#2200dd", "#11bbee", "#88aaee"],
  99: ["#ddee11", "#8888aa", "#88aa00", "#22dd11", "#9988aa"],
  100: ["#ffccdd", "#66bb55", "#ffdd66", "#88dd33", "#ffcc22"],
  101: ["#442233", "#cccc33", "#772255", "#aa2266", "#99ddff"],
  102: ["#2222aa", "#0044ee", "#220099", "#aacc11", "#8844ff"],
  103: ["#ff4444", "#bbcc88", "#1199ee", "#3300aa", "#2244aa"],
  104: ["#22dd22", "#33dd22", "#333355", "#ddcc44", "#1100ee"],
  105: ["#cc3399", "#0088aa", "#aaeeff", "#777744", "#55bbff"],
  106: ["#ff55dd", "#cc8822", "#bbdd55", "#55dd00", "#ee7777"],
  107: ["#cccc44", "#bb0000", "#ddbb66", "#bbee00", "#ccddaa"],
  108: ["#770077", "#ddbbbb", "#dd22dd", "#bb0099", "#00eedd"],
  109: ["#771144", "#00ccbb", "#ccccff", "#113355", "#886633"],
  110: ["#bb1199", "#77ffee", "#88ddbb", "#cc22dd", "#ddaa11"],
  111: ["#ee9922", "#6688cc", "#33cc44", "#2200dd", "#554400"],
  112: ["#66ccff", "#449988", "#444455", "#aa4455", "#7733dd"],
  113: ["#4411ee", "#883344", "#44dd99", "#ffaa66", "#11aa44"],
  114: ["#eedd44", "#225522", "#bb33cc", "#224499", "#0055ee"],
  115: ["#442200", "#66eedd", "#005555", "#115566", "#0077ee"],
  116: ["#dd11ff", "#ff3355", "#991133", "#99bb66", "#6644ee"],
  117: ["#4499dd", "#114499", "#337744", "#ee11bb", "#77ffff"],
  118: ["#ee88dd", "#55cc00", "#66ff44", "#882288", "#99ffdd"],
  119: ["#aacc33", "#99aa77", "#9911ff", "#110022", "#cc3322"],
  120: ["#aa22ee", "#ddaa33", "#11ff00", "#1188bb", "#5577bb"],
  121: ["#884411", "#666611", "#778866", "#55eedd", "#44bbff"],
  122: ["#2211dd", "#220055", "#55aa11", "#1177cc", "#33bbff"],
  123: ["#7788ff", "#337700", "#22ff55", "#33ff99", "#2266dd"],
  124: ["#442299", "#770066", "#cc4488", "#bbaaff", "#44ff55"],
  125: ["#2277aa", "#ff66cc", "#11eecc", "#772266", "#aa66dd"],
  126: ["#4466aa", "#bb0033", "#ff7733", "#003322", "#6677bb"],
  127: ["#115599", "#3399aa", "#aabbbb", "#bb8899", "#229911"],
  128: ["#cc8822", "#114499", "#88aa88", "#66cc77", "#444422"],
  129: ["#66dd22", "#ff1188", "#88eeff", "#6600cc", "#11ddaa"],
  130: ["#bb7744", "#bb5522", "#005599", "#aaff44", "#669977"],
  131: ["#661177", "#990011", "#bb7799", "#33bb66", "#5599bb"],
  132: ["#00ee88", "#bb9911", "#aa1111", "#113333", "#886633"],
  133: ["#998855", "#0055ff", "#44aaff", "#55ff66", "#bb3322"],
  134: ["#115555", "#00dd55", "#7744cc", "#dd4466", "#5522ee"],
  135: ["#0099ff", "#ddbb99", "#0099dd", "#110044", "#aa5511"],
  136: ["#eeaa55", "#eeff00", "#111111", "#ee8866", "#0011bb"],
  137: ["#eecc99", "#eeeeff", "#6699ff", "#aa2255", "#7722ee"],
  138: ["#4433ff", "#770077", "#44ff44", "#222222", "#66bb22"],
  139: ["#663300", "#2277bb", "#557777", "#999966", "#aa6677"],
  140: ["#cc2255", "#ccaa11", "#11bb22", "#553355", "#ff44cc"],
  141: ["#dd00cc", "#88cc88", "#bbffee", "#991100", "#99ddff"],
  142: ["#331155", "#bbaa55", "#443311", "#77dddd", "#2277ff"],
  143: ["#558899", "#ffaa66", "#66aaaa", "#5511aa", "#cc2277"],
  144: ["#2200dd", "#ccaa33", "#88dd88", "#4433cc", "#66ff22"],
  145: ["#ccff22", "#ffff44", "#aa5555", "#994499", "#ff9999"],
  146: ["#2211bb", "#dd3355", "#55aa11", "#aabb77", "#339944"],
  147: ["#eeee88", "#cc7766", "#ccee66", "#dd5577", "#9922ee"],
  148: ["#aa3344", "#112211", "#eedd22", "#33ddee", "#33ff33"],
  149: ["#bbaa33", "#bbaa00", "#ccbbee", "#667700", "#33bb99"],
  150: ["#bbbb00", "#117788", "#8866ff", "#1199aa", "#99bb77"],
  151: ["#77cccc", "#44ee44", "#667788", "#777722", "#bb2200"],
  152: ["#cc2233", "#cc7733", "#991166", "#554466", "#1199ff"],
  153: ["#5577cc", "#22cc22", "#334477", "#55bbdd", "#660044"],
  154: ["#11bbff", "#771133", "#6688dd", "#99ffbb", "#881166"],
  155: ["#448800", "#aa99dd", "#550055", "#66dddd", "#889955"],
  156: ["#77ff22", "#ff9933", "#aa4411", "#dd77aa", "#44aa55"],
  157: ["#bb44aa", "#5533ee", "#ff55ff", "#886633", "#77ccee"],
  158: ["#0099bb", "#cc11ee", "#aacc66", "#00eebb", "#9955cc"],
  159: ["#7766dd", "#eecc99", "#eeff11", "#337755", "#779900"],
  160: ["#bb44dd", "#661177", "#66ffdd", "#666677", "#ffaabb"],
  161: ["#003388", "#886644", "#2277aa", "#00bbee", "#7766ee"],
  162: ["#22ccdd", "#88dd55", "#227711", "#ff1133", "#ee22ee"],
  163: ["#bb44ff", "#2233bb", "#006699", "#66ffcc", "#55cc99"],
  164: ["#22dd44", "#ee55dd", "#228833", "#1177ff", "#cc11ee"],
  165: ["#77ee44", "#ffdd55", "#443333", "#bbdd66", "#77aa88"],
  166: ["#ee5544", "#442211", "#2222ff", "#cc77aa", "#66ffff"],
  167: ["#aa99cc", "#ffbb33", "#554455", "#11ee88", "#88bbbb"],
  168: ["#cccc66", "#bbdd88", "#449977", "#44bbdd", "#887722"],
  169: ["#ccee33", "#99ccbb", "#aa33bb", "#bb2200", "#66eecc"],
  170: ["#ffff55", "#aa6688", "#00bb99", "#eeff00", "#aa5522"],
  171: ["#440044", "#aa11dd", "#ee3333", "#4477dd", "#339933"],
  172: ["#bbffbb", "#22ff44", "#995588", "#dd7777", "#eeee00"],
  173: ["#22ff99", "#771166", "#111122", "#11aa22", "#cc6688"],
  174: ["#99ffff", "#882222", "#7711cc", "#ff88aa", "#445544"],
  175: ["#4422ee", "#338855", "#889944", "#11cc44", "#55cc22"],
  176: ["#1133dd", "#aabbee", "#332255", "#4411cc", "#5588ff"],
  177: ["#992222", "#22dd99", "#886655", "#2222aa", "#eeeeee"],
  178: ["#11ff66", "#88ddcc", "#99ffee", "#ddbb55", "#0044ee"],
  179: ["#00bb77", "#0066aa", "#77dd44", "#cc1199", "#aa8855"],
  180: ["#aa7755", "#bbbb77", "#bb3300", "#22bb88", "#00ffaa"],
  181: ["#ff3300", "#663311", "#55bb66", "#3377cc", "#77ccbb"],
  182: ["#9977bb", "#77ff33", "#118844", "#884455", "#ff2211"],
  183: ["#77aa88", "#44ccbb", "#bb7744", "#336600", "#337733"],
  184: ["#ddff33", "#bbaa66", "#446611", "#ee4433", "#668833"],
  185: ["#5566ff", "#66dd11", "#cc11cc", "#6666bb", "#cc11bb"],
  186: ["#eeaa22", "#00aadd", "#99eeaa", "#1155bb", "#cc6611"],
  187: ["#22aa88", "#66aa77", "#7733dd", "#ff55aa", "#2299aa"],
  188: ["#ddcc00", "#88ff88", "#1155aa", "#ddff22", "#55ffbb"],
  189: ["#00aa00", "#ff22aa", "#885566", "#115544", "#eeddee"],
  190: ["#4488ee", "#eecc33", "#ee99ee", "#88dd88", "#00ee99"],
  191: ["#113366", "#1111ee", "#66ff44", "#ff66dd", "#77bbcc"],
  192: ["#334499", "#117733", "#eeffee", "#00eebb", "#aa22dd"],
  193: ["#ddcc44", "#77ff11", "#aa11dd", "#ffaadd", "#990044"],
  194: ["#bbee88", "#ff99cc", "#cc55ff", "#559911", "#ff1122"],
  195: ["#33dd77", "#ff5500", "#885522", "#0077dd", "#ffcc33"],
  196: ["#4444bb", "#66dd88", "#aabb77", "#117744", "#119933"],
  197: ["#ccdd11", "#773355", "#22ff55", "#772222", "#775566"],
  198: ["#551111", "#88eebb", "#cc99bb", "#999911", "#bb1199"],
  199: ["#6633ff", "#443399", "#557755", "#778844", "#aacc22"],
  200: ["#ff44ee", "#004400", "#ffeedd", "#555511", "#ee2255"],
  201: ["#aa0022", "#44ffff", "#ee0099", "#0044bb", "#333366"],
  202: ["#ddcc66", "#0000dd", "#bbaa22", "#ff00dd", "#55aa55"],
  203: ["#4411ff", "#555522", "#332211", "#ffff55", "#bb3344"],
  204: ["#bbaa77", "#ff44dd", "#3355ee", "#ddaacc", "#447766"],
  205: ["#dd44ee", "#dddd44", "#66ff22", "#003399", "#00ff77"],
  206: ["#112222", "#bbff88", "#6600dd", "#66dd11", "#33bbee"],
  207: ["#bb99aa", "#dd2277", "#ddee66", "#77dd44", "#5599ff"],
  208: ["#336644", "#7788cc", "#4499ee", "#55eebb", "#777766"],
  209: ["#448877", "#001144", "#aa9966", "#77aa33", "#668888"],
  210: ["#aaff77", "#440088", "#99aa88", "#44dd11", "#770011"],
  211: ["#776600", "#cc99cc", "#662244", "#aa99dd", "#66ccdd"],
  212: ["#330000", "#aa8844", "#11dddd", "#22dd99", "#330088"],
  213: ["#4477bb", "#774455", "#6655aa", "#ee66cc", "#bb8822"],
  214: ["#eedd66", "#550000", "#66dd88", "#cc5588", "#bb6688"],
  215: ["#007788", "#66bb33", "#11ee00", "#774455", "#aaaa77"],
  216: ["#1144bb", "#6677cc", "#991188", "#ccaa66", "#ee00ff"],
  217: ["#1144aa", "#886666", "#5500ee", "#ff2266", "#557766"],
  218: ["#33cc11", "#cc5577", "#5500bb", "#66bbcc", "#ddbb55"],
  219: ["#eedd55", "#aa33cc", "#77ee44", "#33cc77", "#ddddff"],
  220: ["#77ffbb", "#00ff77", "#5577ff", "#ddcc66", "#bbbb44"],
  221: ["#eeccee", "#aaee00", "#557722", "#ff99cc", "#99bbbb"],
  222: ["#662288", "#22bb55", "#88aa00", "#88bb66", "#bb3333"],
  223: ["#66bb77", "#336677", "#bbbb33", "#22aabb", "#ff66dd"],
  224: ["#ee7799", "#44bb88", "#22aabb", "#006699", "#007766"],
  225: ["#6666dd", "#55aaee", "#660077", "#66bbdd", "#3322bb"],
  226: ["#110033", "#11aadd", "#cc2200", "#337733", "#cc00aa"],
  227: ["#cc3333", "#cc55dd", "#11ee66", "#447766", "#33bbaa"],
  228: ["#bbcccc", "#220077", "#771133", "#99cccc", "#aaaaaa"],
  229: ["#445500", "#4466ee", "#009955", "#665577", "#0099cc"],
  230: ["#669933", "#dd8888", "#3344aa", "#bb77dd", "#aadd33"],
  231: ["#332255", "#ccff55", "#33ccdd", "#33ffaa", "#0088dd"],
  232: ["#aa33ff", "#881144", "#551144", "#66ffbb", "#ee99aa"],
  233: ["#9911cc", "#ff2266", "#11bbee", "#9900dd", "#dd5511"],
  234: ["#55ff88", "#22dd44", "#331166", "#557711", "#44ee88"],
  235: ["#999922", "#440066", "#115511", "#bb5577", "#5555ff"],
  236: ["#eeff99", "#ccbb11", "#ccee22", "#669922", "#335566"],
  237: ["#5533ee", "#885500", "#3344cc", "#ee55dd", "#bbdd33"],
  238: ["#779988", "#001199", "#bb7744", "#336644", "#99bbdd"],
  239: ["#ffee77", "#667799", "#dd5533", "#881188", "#1166bb"],
  240: ["#995599", "#223333", "#669955", "#444400", "#99bb77"],
  241: ["#aa7766", "#666677", "#ff2211", "#6622cc", "#222288"],
  242: ["#aa99cc", "#cc7733", "#0033ee", "#dd00ff", "#778822"],
  243: ["#111166", "#443388", "#bb8877", "#8899aa", "#886677"],
  244: ["#44aa88", "#22ee77", "#ff4422", "#440022", "#dd6688"],
  245: ["#eeee88", "#cc6622", "#33ff66", "#114400", "#bb00ff"],
  246: ["#557722", "#119933", "#441188", "#5577ee", "#661144"],
  247: ["#004400", "#2255ee", "#445588", "#22dd44", "#eeff11"],
  248: ["#aa55ee", "#22ffdd", "#22dd44", "#99eeee", "#55aa33"],
  249: ["#cc5522", "#dd77aa", "#ee8877", "#2200cc", "#33cc11"],
  250: ["#ee8899", "#339900", "#ff6688", "#9933ee", "#eebbdd"],
  251: ["#9966ee", "#885555", "#660077", "#cceecc", "#ff6699"],
  252: ["#66ee44", "#228822", "#9966dd", "#ff0022", "#cc3377"],
  253: ["#440099", "#ff5544", "#88ffee", "#11aa55", "#bb9988"],
  254: ["#cc99ee", "#000000", "#884499", "#0033dd", "#33dd00"],
  255: ["#bb4499", "#ff7777", "#ff7744", "#bbdd44", "#2211ee"],
  256: ["#aa9999", "#7788cc", "#eecc11", "#ffcc77", "#eebb00"],
  257: ["#113355", "#99aaee", "#ee4455", "#66eebb", "#556633"],
  258: ["#aaaa66", "#dd9944", "#88dd66", "#55ffcc", "#aaff00"],
  259: ["#55ddff", "#11ffee", "#8800ee", "#99bb77", "#ccffbb"],
  260: ["#ff4499", "#bbff44", "#005544", "#ccaa44", "#bbddaa"],
  261: ["#ffbbee", "#cc3300", "#7799dd", "#991100", "#6600bb"],
  262: ["#bbdd88", "#9955cc", "#bbaa66", "#88ee88", "#990088"],
  263: ["#004488", "#7744aa", "#bb6622", "#ddffdd", "#eeddcc"],
  264: ["#ddff55", "#339900", "#77cc88", "#bb22bb", "#aa00ee"],
  265: ["#00cc33", "#ccaa88", "#113377", "#88bb22", "#ff4444"],
  266: ["#aa55ee", "#5533cc", "#bb44ff", "#ee33dd", "#bb55ee"],
  267: ["#11aa44", "#443344", "#aadd33", "#66bb00", "#cc1155"],
  268: ["#1199bb", "#dd4400", "#ee55ff", "#55cc88", "#5555aa"],
  269: ["#ee9977", "#0099bb", "#33cc33", "#dd3355", "#dd11ee"],
  270: ["#6611cc", "#77cc11", "#ffaa99", "#aaaa00", "#ee8811"],
  271: ["#00cc22", "#44ff11", "#668833", "#668855", "#550066"],
  272: ["#ee55bb", "#9933ee", "#2255cc", "#556677", "#cc1199"],
  273: ["#cc00dd", "#008877", "#11cc66", "#eecc00", "#77ddcc"],
  274: ["#bb1155", "#226633", "#77dd22", "#aaaa66", "#eebbdd"],
  275: ["#664466", "#004422", "#332266", "#22bb33", "#aa22bb"],
  276: ["#cc7733", "#22dd99", "#559966", "#339999", "#00ff55"],
  277: ["#bbffff", "#ffff44", "#440000", "#442233", "#dddd66"],
  278: ["#33ffbb", "#440077", "#440011", "#006600", "#881166"],
  279: ["#bb8844", "#ee88ee", "#ddff11", "#aaddbb", "#334422"],
  280: ["#554400", "#22cccc", "#884400", "#884422", "#11dd33"],
  281: ["#11ee99", "#dd9966", "#55dd00", "#ff8899", "#ff4411"],
  282: ["#998855", "#11ee88", "#007722", "#55eedd", "#888855"],
  283: ["#8800ff", "#66aa44", "#ff55aa", "#779988", "#aaccee"],
  284: ["#cc11dd", "#66ccbb", "#44cc44", "#0000bb", "#dd3377"],
  285: ["#cc55ee", "#99aa77", "#777722", "#11bb77", "#227722"],
  286: ["#77ffcc", "#0077cc", "#116600", "#9999bb", "#66dddd"],
  287: ["#8811ee", "#7722dd", "#aa5577", "#aaffbb", "#bbff44"],
  288: ["#994499", "#668811", "#cc1144", "#007777", "#44ffee"],
  289: ["#dd8822", "#00ccaa", "#3388bb", "#11cc00", "#ffddbb"],
  290: ["#6611ee", "#0088bb", "#66cc44", "#55bb66", "#8866ff"],
  291: ["#bb88bb", "#dd0088", "#112288", "#33bb11", "#553355"],
  292: ["#663333", "#ccffdd", "#33dd99", "#88ffff", "#00eeff"],
  293: ["#55aa77", "#442266", "#2288cc", "#44dd99", "#999911"],
  294: ["#001144", "#777755", "#bb2255", "#558888", "#666655"],
  295: ["#77ffdd", "#ee77aa", "#668844", "#334466", "#ffaabb"],
  296: ["#ee99ee", "#22ddbb", "#ee5544", "#334477", "#aa99ee"],
  297: ["#aa00dd", "#11cc99", "#0022cc", "#ccee11", "#cc8855"],
  298: ["#bb5544", "#44aa22", "#5588cc", "#554466", "#cc7744"],
  299: ["#aa66cc", "#dddd22", "#1133ee", "#444433", "#aa5522"],
  300: ["#ee7755", "#883311", "#337722", "#cc22ee", "#44bb99"],
  301: ["#aa6655", "#aa44cc", "#ee88bb", "#447799", "#5500ee"],
  302: ["#aa3333", "#4433ff", "#22ff00", "#6622bb", "#bbaacc"],
  303: ["#cc5577", "#116655", "#33aaaa", "#6666cc", "#ee3355"],
  304: ["#cc3311", "#ddcc99", "#ffddaa", "#cc8855", "#228844"],
  305: ["#11ff88", "#555511", "#99aa22", "#5544aa", "#4455aa"],
  306: ["#eebbff", "#ff66bb", "#66ddaa", "#22aa00", "#ee9977"],
  307: ["#009977", "#aa7733", "#662200", "#ee33ee", "#bb00ff"],
  308: ["#333377", "#aaeedd", "#00cc11", "#228822", "#ee77aa"],
  309: ["#aacc88", "#335511", "#442266", "#11ff99", "#221177"],
  310: ["#ddaa33", "#dd8888", "#3366cc", "#992233", "#116644"],
  311: ["#773344", "#cccc11", "#66dd66", "#aacccc", "#cc9944"],
  312: ["#66cc22", "#7788cc", "#33ffbb", "#aaeeee", "#66aadd"],
  313: ["#557777", "#ff99ee", "#2222ff", "#77dddd", "#dd11bb"],
  314: ["#55bbcc", "#aa2277", "#aaaabb", "#4455cc", "#aaaa77"],
  315: ["#cccc00", "#22aaff", "#6633cc", "#99ff00", "#885555"],
  316: ["#ee55bb", "#8866ee", "#aa2200", "#9966cc", "#446622"],
  317: ["#bb5555", "#553388", "#cc88cc", "#11ddee", "#99cc11"],
  318: ["#664411", "#8800bb", "#ff6633", "#bb66ff", "#3300ff"],
  319: ["#ff22dd", "#119966", "#66bb55", "#33ffbb", "#ee77cc"],
  320: ["#773377", "#99bbbb", "#221166", "#33dd00", "#666677"],
  321: ["#9955ee", "#11eebb", "#2266dd", "#3388dd", "#88bbee"],
  322: ["#33dd44", "#11ffee", "#99ffdd", "#ff2255", "#0088aa"],
  323: ["#005522", "#99cc00", "#7744bb", "#449944", "#2299dd"],
  324: ["#44cccc", "#882255", "#660055", "#0044ff", "#ccccaa"],
  325: ["#99aabb", "#118800", "#778844", "#6611cc", "#88ee55"],
  326: ["#8855ff", "#55eeff", "#eeaa22", "#dd6688", "#33aa00"],
  327: ["#660088", "#66dd33", "#22cc88", "#aa44cc", "#4422dd"],
  328: ["#aa5555", "#ccffaa", "#99ffaa", "#dd7799", "#88ee44"],
  329: ["#dd9911", "#66ffff", "#aa7700", "#66ee88", "#445522"],
  330: ["#11ee66", "#335544", "#4488cc", "#eeaa22", "#774477"],
  331: ["#77aadd", "#aa2255", "#552222", "#882299", "#88bbaa"],
  332: ["#441199", "#99cc66", "#22ff88", "#cc0077", "#778822"],
  333: ["#ffffff", "#558822", "#665544", "#ff2255", "#771166"],
  334: ["#eeee00", "#aa8899", "#33aaee", "#66bb99", "#5533ff"],
  335: ["#1122cc", "#880000", "#aaff77", "#880055", "#dd5544"],
  336: ["#bbaadd", "#bbaaaa", "#77eedd", "#dd1199", "#77ddbb"],
  337: ["#cc4455", "#aaddcc", "#550033", "#22bb77", "#8811dd"],
  338: ["#ff1166", "#5511ff", "#11dd66", "#334400", "#9955dd"],
  339: ["#22dd22", "#226622", "#3300aa", "#77dd33", "#66bbff"],
  340: ["#99eeff", "#225599", "#1144aa", "#ee9933", "#99bbff"],
  341: ["#9911ff", "#557700", "#ffcc55", "#55ee00", "#bb3300"],
  342: ["#aaeebb", "#668833", "#112200", "#555544", "#aacc66"],
  343: ["#aaff22", "#ee0044", "#ee33bb", "#888877", "#dd88bb"],
  344: ["#446666", "#3322ff", "#000099", "#44eebb", "#33dd77"],
  345: ["#111166", "#ee66ee", "#cc0066", "#447744", "#99ff66"],
  346: ["#22dd33", "#aadd33", "#770033", "#227766", "#ee22dd"],
  347: ["#bbcc55", "#994499", "#ffffdd", "#ffee99", "#0044bb"],
  348: ["#33aa44", "#00ee99", "#4466ee", "#66bb77", "#ccee44"],
  349: ["#dd9922", "#aa7744", "#ee00ff", "#775522", "#773311"],
  350: ["#338811", "#aabbee", "#bbbb99", "#227799", "#7799cc"],
  351: ["#cc0088", "#0066ee", "#bb8866", "#bb9911", "#556600"],
  352: ["#66bb00", "#9977dd", "#bbddaa", "#ff00dd", "#99ee11"],
  353: ["#993311", "#885544", "#00ccaa", "#773377", "#002277"],
  354: ["#aa3377", "#117722", "#dd0022", "#3333ff", "#ee7788"],
  355: ["#ee2200", "#dd5544", "#6655cc", "#99ddcc", "#335533"],
  356: ["#779922", "#88aabb", "#ee8855", "#22ffcc", "#ee6666"],
  357: ["#7799aa", "#776677", "#9944aa", "#663300", "#881111"],
  358: ["#117766", "#339922", "#ff7799", "#77ff66", "#66cc55"],
  359: ["#882277", "#aacc66", "#aaee66", "#ddffcc", "#663377"],
  360: ["#cc6688", "#bbaa00", "#8844ff", "#009988", "#ff8877"],
  361: ["#33ff22", "#882266", "#44ee33", "#ee8888", "#991133"],
  362: ["#9955cc", "#6644bb", "#447755", "#ff5500", "#99dddd"],
  363: ["#337744", "#884400", "#bbff33", "#ee00cc", "#667733"],
  364: ["#111199", "#aaee88", "#664400", "#550000", "#33ff00"],
  365: ["#ff2266", "#ffff99", "#ee11ee", "#cc4488", "#221144"],
  366: ["#66dd66", "#55bbaa", "#eeffaa", "#1188bb", "#99ee33"],
  367: ["#11ffbb", "#cc77ff", "#11aa88", "#dd7755", "#995522"],
  368: ["#4488dd", "#aa77ff", "#33cc11", "#664455", "#eedd22"],
  369: ["#bb8888", "#ee4444", "#bbffee", "#668822", "#884477"],
  370: ["#eedd99", "#cc8811", "#dd00bb", "#224466", "#88ee88"],
  371: ["#bb8866", "#331144", "#337744", "#aa3333", "#889988"],
  372: ["#3322ff", "#eedd88", "#773300", "#dd7755", "#99aaff"],
  373: ["#ff6688", "#ffccff", "#440077", "#11bbff", "#ee1122"],
  374: ["#00aa55", "#bbbbff", "#445522", "#ee1166", "#999999"],
  375: ["#bb9966", "#99ccbb", "#ddffcc", "#bb99dd", "#884499"],
  376: ["#550099", "#00ffff", "#00ff88", "#bb0055", "#aadd55"],
  377: ["#cc3333", "#dd66ff", "#eeff00", "#ee0011", "#22cc44"],
  378: ["#441122", "#88dd00", "#9922ff", "#66ccdd", "#225577"],
  379: ["#bbaa77", "#66ffff", "#331188", "#2244bb", "#66dd11"],
  380: ["#88ccaa", "#44ddee", "#ffff33", "#6611aa", "#ee8800"],
  381: ["#117744", "#778800", "#33ddbb", "#33aa99", "#4477ff"],
  382: ["#bb3388", "#9944dd", "#99aa44", "#00ff44", "#884422"],
  383: ["#ddaa11", "#77ddbb", "#771188", "#22ffbb", "#bbeeee"],
  384: ["#7755bb", "#bbbb88", "#9933aa", "#ee0022", "#ccffcc"],
  385: ["#224488", "#ddddbb", "#cc6600", "#bb77ee", "#442244"],
  386: ["#66aa00", "#664400", "#ee6622", "#660055", "#bbbbaa"],
  387: ["#cc3366", "#cc4466", "#9944dd", "#99aa99", "#992277"],
  388: ["#880044", "#1199cc", "#ee00cc", "#aa2244", "#229988"],
  389: ["#2233ff", "#dd88ee", "#ff8877", "#cc9966", "#335522"],
  390: ["#ccddcc", "#776699", "#ccff99", "#bb11ee", "#00dd00"],
  391: ["#556633", "#ffdd99", "#336600", "#77aaaa", "#99dd99"],
  392: ["#999977", "#55dd33", "#99eeaa", "#3355aa", "#559911"],
  393: ["#aa99ee", "#990022", "#661199", "#007722", "#77cc77"],
  394: ["#330022", "#7777bb", "#ddff99", "#77bbbb", "#cc44aa"],
  395: ["#998822", "#0033bb", "#77aa22", "#ffee55", "#111133"],
  396: ["#226633", "#bbaaaa", "#77ddcc", "#ee44bb", "#008866"],
  397: ["#112255", "#77eedd", "#ffccee", "#4499cc", "#5588ee"],
  398: ["#aa99ff", "#dd8888", "#443388", "#889955", "#774411"],
  399: ["#ff88ee", "#995588", "#2299aa", "#ffaa44", "#ee99dd"],
  400: ["#1166ff", "#4488ee", "#bb4444", "#668855", "#998899"],
  401: ["#556677", "#22dd44", "#00cc11", "#7777ff", "#669911"],
  402: ["#ffbb66", "#ddbbdd", "#eeaaff", "#224499", "#221111"],
  403: ["#662255", "#ff8888", "#33bbee", "#55bb11", "#11cc66"],
  404: ["#cc7777", "#3300dd", "#667766", "#ee4477", "#55aa77"],
  405: ["#ff11ff", "#116622", "#441155", "#668833", "#221111"],
  406: ["#4488bb", "#eecc00", "#7799ee", "#aa88cc", "#8811cc"],
  407: ["#bb88dd", "#7744bb", "#aa99ff", "#ddee11", "#331155"],
  408: ["#ccee66", "#55dd55", "#33aabb", "#00bb99", "#ff9955"],
  409: ["#ff22bb", "#222200", "#6622ee", "#4455bb", "#885577"],
  410: ["#334466", "#ff5599", "#33dd11", "#55bbbb", "#eeccaa"],
  411: ["#003300", "#ffbb77", "#88ee44", "#ffdddd", "#ddddbb"],
  412: ["#660066", "#ee7700", "#666666", "#dd22cc", "#223311"],
  413: ["#001177", "#000000", "#aabbdd", "#ffddcc", "#ee66cc"],
  414: ["#00bb44", "#665522", "#99ff77", "#9911aa", "#55cc22"],
  415: ["#227722", "#447788", "#aa44ff", "#cc7777", "#2200bb"],
  416: ["#44bb22", "#ff5599", "#ee0022", "#999922", "#22ddbb"],
  417: ["#cccc55", "#660022", "#00cc22", "#773333", "#bbbb55"],
  418: ["#aacc00", "#ffffff", "#4400ff", "#2233dd", "#bbee22"],
  419: ["#ee7777", "#aa3300", "#77ccaa", "#0011aa", "#22aa11"],
  420: ["#99aa99", "#66eeee", "#aa22ee", "#00ee77", "#1188aa"],
  421: ["#22ffbb", "#223333", "#aa5511", "#ccaa00", "#665533"],
  422: ["#99ff00", "#66bb99", "#8822dd", "#8899ff", "#ffbb66"],
  423: ["#cc8822", "#330099", "#ffaa22", "#bb4477", "#ccff99"],
  424: ["#ddcc22", "#8844dd", "#bb3377", "#1177aa", "#6699ff"],
  425: ["#220088", "#bbff77", "#662299", "#99ddcc", "#998866"],
  426: ["#ffaa22", "#ccee33", "#44bbff", "#11ddff", "#334477"],
  427: ["#11ccbb", "#33ff00", "#ddeeee", "#66aa22", "#882244"],
  428: ["#0000dd", "#99ffbb", "#44eeee", "#8811bb", "#55bb22"],
  429: ["#bb0099", "#5544ee", "#6699cc", "#eeaaee", "#7777bb"],
  430: ["#00cc55", "#1188aa", "#bb55ee", "#007777", "#118822"],
  431: ["#ee6655", "#224411", "#225588", "#445555", "#2211bb"],
  432: ["#44ccee", "#ffaa44", "#ddee55", "#aacc88", "#11aa33"],
  433: ["#996655", "#bb77cc", "#775599", "#7799ee", "#66cc55"],
  434: ["#4411ee", "#dd2222", "#11aa99", "#661133", "#997799"],
  435: ["#88ff11", "#331100", "#661111", "#99dd22", "#bbdd33"],
  436: ["#114400", "#9966ff", "#ee55aa", "#bb33bb", "#ee00bb"],
  437: ["#227733", "#009966", "#dd2200", "#55ff66", "#88ffdd"],
  438: ["#bbaa33", "#ff11cc", "#1144dd", "#444488", "#eeaaff"],
  439: ["#aaddaa", "#ff1111", "#008800", "#eecc55", "#ffccdd"],
  440: ["#88ff00", "#66cc77", "#4488bb", "#998822", "#cceedd"],
  441: ["#00eeee", "#440077", "#ff5533", "#ddaaff", "#dd66cc"],
  442: ["#ccccff", "#00bb22", "#332277", "#ff2277", "#bb6622"],
  443: ["#22ccff", "#11cccc", "#667788", "#ee8877", "#ccff55"],
  444: ["#559999", "#22dd88", "#229911", "#44bb77", "#ddcccc"],
  445: ["#aa00bb", "#001177", "#44bbee", "#ffaaee", "#ffffcc"],
  446: ["#55dddd", "#9988bb", "#557788", "#002288", "#ffdd22"],
  447: ["#0000cc", "#5511ff", "#7777cc", "#779944", "#22bb22"],
  448: ["#22dd22", "#ee9933", "#8899ff", "#ddcccc", "#778844"],
  449: ["#778800", "#66bb55", "#9900cc", "#aa77aa", "#0099bb"],
  450: ["#bbaaee", "#cc5522", "#aa5533", "#8866aa", "#99ccee"],
  451: ["#3399bb", "#0055ff", "#eebbee", "#cc9955", "#11eecc"],
  452: ["#5588aa", "#33dd11", "#00dd66", "#552211", "#aa1188"],
  453: ["#1188cc", "#77aa33", "#220000", "#77ddaa", "#ccaa77"],
  454: ["#eeff33", "#116633", "#bb8866", "#779933", "#666633"],
  455: ["#bb55cc", "#8866cc", "#666622", "#9999aa", "#335588"],
  456: ["#667744", "#ddaaff", "#229999", "#88eebb", "#ddcc00"],
  457: ["#cc8844", "#7799cc", "#778899", "#0066ff", "#4422cc"],
  458: ["#2255ff", "#007700", "#22bbff", "#ddbbff", "#7777bb"],
  459: ["#550022", "#22aa66", "#22bb77", "#333377", "#eeeeaa"],
  460: ["#aa88ee", "#3377cc", "#bb1155", "#001177", "#115588"],
  461: ["#ffbb66", "#ff9966", "#5500ee", "#ffaa11", "#5577bb"],
  462: ["#ee6644", "#bb88bb", "#992255", "#22eedd", "#dd4411"],
  463: ["#ff3355", "#66bbcc", "#226699", "#bbeeaa", "#118844"],
  464: ["#ff2244", "#1166ee", "#444422", "#00cc11", "#aaee77"],
  465: ["#ffbb88", "#bbcc00", "#55cc44", "#33ff11", "#775500"],
  466: ["#ffcc44", "#ffbbcc", "#aa44ee", "#5566aa", "#cc6699"],
  467: ["#bb7755", "#994477", "#777766", "#ffbb11", "#ff7744"],
  468: ["#337766", "#ee8855", "#0000aa", "#dd6600", "#bb5511"],
  469: ["#ee8833", "#448800", "#ffffcc", "#0000ee", "#aa4433"],
  470: ["#77cccc", "#11ff11", "#cc0022", "#1122cc", "#554499"],
  471: ["#44dd11", "#55ff22", "#886688", "#664499", "#113377"],
  472: ["#229911", "#889944", "#dd5555", "#4477bb", "#99bbcc"],
  473: ["#aa99aa", "#5577aa", "#bb8844", "#ff7766", "#ff77dd"],
  474: ["#aa1166", "#888899", "#7755dd", "#11ee66", "#1199dd"],
  475: ["#22cc00", "#2277ff", "#662211", "#aa3377", "#226699"],
  476: ["#aa3399", "#999944", "#33eeaa", "#77ffee", "#6666cc"],
  477: ["#eeaa77", "#44aa00", "#33cc66", "#553322", "#884433"],
  478: ["#6600bb", "#882244", "#99dd33", "#663300", "#66ff22"],
  479: ["#448866", "#5566bb", "#2277bb", "#774433", "#bb99ff"],
  480: ["#00eeaa", "#552288", "#00ffdd", "#33aa55", "#aa11ff"],
  481: ["#440088", "#bb6633", "#8822bb", "#8822bb", "#ccff88"],
  482: ["#ff0011", "#2255dd", "#ddaacc", "#cc5511", "#aa33ff"],
  483: ["#ee1111", "#0055bb", "#ff3322", "#aa0022", "#eeff44"],
  484: ["#998877", "#eeaa55", "#00ccee", "#cc1188", "#aa5577"],
  485: ["#ff77ff", "#3388aa", "#99cc33", "#5588cc", "#22aacc"],
  486: ["#55cc33", "#ddeecc", "#dd2299", "#ee7755", "#2211aa"],
  487: ["#334422", "#2299aa", "#335511", "#bb7799", "#11cc77"],
  488: ["#777722", "#bb6644", "#ff88cc", "#bb77bb", "#aacc00"],
  489: ["#0077aa", "#77aaaa", "#22ccff", "#44ee88", "#77dd00"],
  490: ["#9999ff", "#ff3300", "#ffdd00", "#ff1133", "#22eeff"],
  491: ["#ee5577", "#114433", "#0022ff", "#229911", "#dd6677"],
  492: ["#4422cc", "#ff4422", "#448888", "#eebb22", "#66ccbb"],
  493: ["#995566", "#338888", "#11dd66", "#aa4400", "#66aa99"],
  494: ["#990099", "#2211ff", "#dd11bb", "#8866cc", "#00ddee"],
  495: ["#11ffcc", "#cc88aa", "#447755", "#eecc00", "#11cc44"],
  496: ["#ff6622", "#cc0099", "#44aa55", "#bbee99", "#cc7788"],
  497: ["#aa1177", "#447744", "#7744cc", "#00ccee", "#55ee44"],
  498: ["#ccee55", "#333399", "#11ee00", "#006633", "#3399aa"],
  499: ["#7788ee", "#aaee11", "#00eeee", "#55eeaa", "#ddffee"],
  500: ["#bbddcc", "#bbeebb", "#ee00ff", "#2211cc", "#448833"],
  501: ["#883399", "#88ffff", "#ff5577", "#aa55bb", "#cccc00"],
  502: ["#6699ee", "#dd4411", "#ddff66", "#ff00ee", "#669933"],
  503: ["#440066", "#223388", "#ee6655", "#4411aa", "#666677"],
  504: ["#ddccaa", "#66ee22", "#11bbdd", "#880000", "#665599"],
  505: ["#11cc55", "#44ee66", "#cc55dd", "#cc3355", "#44eebb"],
  506: ["#ee22ff", "#ccdd55", "#ccbb44", "#00dd66", "#ff1166"],
  507: ["#221100", "#223377", "#6644cc", "#bb7788", "#66ee99"],
  508: ["#33aaee", "#ff3322", "#2255dd", "#ff2233", "#5522ee"],
  509: ["#ddaa00", "#00aaff", "#772222", "#2277dd", "#ddbbee"],
  510: ["#cc00aa", "#ccaa33", "#dd11aa", "#bbdd88", "#aacc77"],
  511: ["#11bbcc", "#66ee88", "#aaaa66", "#ee6655", "#bbffcc"],
  512: ["#aa88dd", "#55ee44", "#eecc11", "#ff11ff", "#339922"],
  513: ["#ccbb77", "#55dd77", "#22aa99", "#11eeff", "#ff4455"],
  514: ["#99bb99", "#4444ee", "#00aaee", "#6600ff", "#3333ee"],
  515: ["#446644", "#110066", "#ee6677", "#ffeedd", "#bbbb88"],
  516: ["#33ddbb", "#ddbb66", "#bb88ff", "#bbcc77", "#bbffbb"],
  517: ["#00ee99", "#22ff77", "#aa7733", "#9977ff", "#aa5599"],
  518: ["#6688cc", "#eeff44", "#99ddaa", "#ccaa99", "#cc77ee"],
  519: ["#22ddcc", "#33aa55", "#88eeee", "#cc7722", "#558866"],
  520: ["#8899dd", "#669999", "#eebbbb", "#bbbb11", "#0077dd"],
  521: ["#669977", "#88ddee", "#ff8833", "#22dd55", "#eeddcc"],
  522: ["#1100aa", "#99aa44", "#44ffee", "#6644dd", "#445522"],
  523: ["#33ff33", "#ee6666", "#666633", "#11aacc", "#77ccee"],
  524: ["#cc0088", "#66dd88", "#aa7788", "#ccffff", "#cc3344"],
  525: ["#00ccbb", "#66cc11", "#cc33ff", "#bb7799", "#88ee44"],
  526: ["#1155bb", "#ee3344", "#bbee11", "#77bbcc", "#1166bb"],
  527: ["#ee3399", "#dd44ff", "#77aacc", "#772233", "#11bbaa"],
  528: ["#112288", "#44aa55", "#00ffcc", "#bbee33", "#dd2222"],
  529: ["#00ff33", "#553366", "#eedd22", "#000088", "#996600"],
  530: ["#00ff22", "#443344", "#88aa11", "#77ccaa", "#6600dd"],
  531: ["#1166cc", "#992244", "#996644", "#337799", "#aa33ff"],
  532: ["#bbdd11", "#ee88bb", "#ee9988", "#2255dd", "#cc6611"],
  533: ["#aaeedd", "#6699aa", "#00ccaa", "#1188cc", "#bb77ee"],
  534: ["#663322", "#4411dd", "#77aa44", "#66cc44", "#00bbff"],
  535: ["#886666", "#55eeee", "#9933bb", "#1177ee", "#1100bb"],
  536: ["#ee8855", "#999977", "#77ff88", "#99aa44", "#44ffee"],
  537: ["#bbcc66", "#00aaee", "#226600", "#ff3322", "#22dd55"],
  538: ["#0088bb", "#9944aa", "#ff33dd", "#44ffaa", "#115588"],
  539: ["#aa3388", "#cc3366", "#bbee99", "#ee5511", "#eebb00"],
  540: ["#88bb66", "#dd1199", "#ddddff", "#99cccc", "#ddaa22"],
  541: ["#774433", "#cc4433", "#99ff22", "#331155", "#bb4499"],
  542: ["#99ffcc", "#dd1100", "#88bb55", "#eeccbb", "#ccbb44"],
  543: ["#1144ee", "#664411", "#ddbbcc", "#aa7755", "#333333"],
  544: ["#5566ee", "#11aa00", "#ee2211", "#cc22ee", "#11ffff"],
  545: ["#880055", "#00ffff", "#00ff99", "#7722aa", "#6699bb"],
  546: ["#eedd11", "#110066", "#ddcc00", "#ccdd00", "#eedd88"],
  547: ["#ffaa11", "#ccbb44", "#9922cc", "#2266cc", "#5566dd"],
  548: ["#bbcccc", "#cc3366", "#44ff00", "#44ee11", "#22ddee"],
  549: ["#11eebb", "#55ff77", "#77ee66", "#338888", "#11ee00"],
  550: ["#99bbdd", "#55cc11", "#4477aa", "#6644ee", "#77ff33"],
  551: ["#dd00dd", "#0066cc", "#0022cc", "#6611ff", "#9977ff"],
  552: ["#884499", "#00ffee", "#ccaabb", "#669977", "#443300"],
  553: ["#5500ee", "#77ffff", "#11dd55", "#002288", "#22dd33"],
  554: ["#333311", "#aa4400", "#99cc66", "#eeff66", "#ffffff"],
  555: ["#11aa00", "#22cc55", "#44ddff", "#5577cc", "#bb8899"],
  556: ["#dd1122", "#339966", "#ffffee", "#ccbbcc", "#cc88aa"],
  557: ["#557733", "#aa99ff", "#776622", "#33bb44", "#bb77dd"],
  558: ["#557722", "#bbbbbb", "#bb2266", "#777733", "#cc00dd"],
  559: ["#bb44ee", "#553355", "#bb5522", "#ffddaa", "#dd2255"],
  560: ["#11ee55", "#4433dd", "#88aadd", "#998899", "#776699"],
  561: ["#1100ff", "#228877", "#bb99dd", "#ff1188", "#77ddbb"],
  562: ["#77bb55", "#ffccee", "#aa77bb", "#cc0011", "#4499ee"],
  563: ["#2277cc", "#338811", "#77cc55", "#442299", "#551177"],
  564: ["#dd7755", "#ffddcc", "#ddaa11", "#aa99cc", "#1155ff"],
  565: ["#5588ee", "#3355dd", "#bbcc88", "#887744", "#44aa77"],
  566: ["#bb33dd", "#aabbdd", "#bb3333", "#1144aa", "#99cc11"],
  567: ["#66bb88", "#aa8899", "#337755", "#eeaa55", "#ffaa66"],
  568: ["#001111", "#66bb77", "#bbccff", "#11aa33", "#330099"],
  569: ["#882299", "#11ccff", "#1111dd", "#ccaa55", "#558899"],
  570: ["#00aadd", "#bb99cc", "#99aa77", "#8822bb", "#552266"],
  571: ["#330099", "#cc00ff", "#6622cc", "#cc77ee", "#55ff22"],
  572: ["#551144", "#8800aa", "#448899", "#008899", "#ccaabb"],
  573: ["#bb7777", "#55aa44", "#99bb55", "#117755", "#ff2233"],
  574: ["#bbbbdd", "#55cc11", "#557700", "#eebbcc", "#2200aa"],
  575: ["#cc3344", "#22bb66", "#aa2211", "#77bb66", "#11ffff"],
  576: ["#883311", "#bb4422", "#77ffcc", "#4400ff", "#88ddcc"],
  577: ["#009977", "#99dd44", "#996622", "#556633", "#99ff33"],
  578: ["#ddee11", "#55aa00", "#11dd00", "#00bbff", "#3388aa"],
  579: ["#aa99cc", "#00eecc", "#ddeedd", "#33cc00", "#cc4444"],
  580: ["#dd2222", "#bb8833", "#88eeaa", "#775566", "#11ee33"],
  581: ["#663322", "#bbcc88", "#eeff66", "#8899dd", "#6655ee"],
  582: ["#cc5544", "#44aaaa", "#442222", "#44ff11", "#55bb88"],
  583: ["#ddaabb", "#99eedd", "#228899", "#eebb44", "#00ee66"],
  584: ["#66ffcc", "#ddbb44", "#aa0033", "#ee9988", "#ee0099"],
  585: ["#444466", "#77dd22", "#88bb33", "#22aa11", "#ee3344"],
  586: ["#33bbff", "#998855", "#77bb88", "#331111", "#ee0055"],
  587: ["#aa66cc", "#9955dd", "#332255", "#440066", "#ddee66"],
  588: ["#55eebb", "#aa8800", "#228888", "#990044", "#55ee44"],
  589: ["#dd5588", "#ddddaa", "#bb7733", "#eeaa55", "#662277"],
  590: ["#44cc55", "#cc88aa", "#006644", "#330088", "#44dd88"],
  591: ["#99aaaa", "#224411", "#ee1111", "#ffeedd", "#664411"],
  592: ["#8844aa", "#77bbcc", "#006688", "#771166", "#ee8833"],
  593: ["#bb2233", "#991177", "#3311cc", "#ccaa11", "#aa44aa"],
  594: ["#4499ff", "#88ee66", "#44aa66", "#990033", "#111166"],
  595: ["#ff4433", "#cc4499", "#448866", "#aa66ff", "#333333"],
  596: ["#dd66cc", "#88ccdd", "#22aa22", "#554466", "#dd0022"],
  597: ["#ffffbb", "#ff6633", "#66eebb", "#669966", "#aa2233"],
  598: ["#66ee88", "#aa55cc", "#4455bb", "#662244", "#44eebb"],
  599: ["#993399", "#882222", "#00bbbb", "#449900", "#116611"],
  600: ["#2222ff", "#ee9966", "#334488", "#884444", "#666600"],
  601: ["#008800", "#bb4499", "#6655cc", "#444488", "#3311ff"],
  602: ["#ffddee", "#aabb77", "#99ffcc", "#661144", "#aaaa55"],
  603: ["#dd6622", "#ff1144", "#ffaa66", "#881122", "#66ffee"],
  604: ["#aa9911", "#ee6688", "#4422bb", "#9911dd", "#774466"],
  605: ["#1188cc", "#448888", "#22dd44", "#99dd99", "#55dd88"],
  606: ["#441177", "#dd5533", "#9911bb", "#bb5555", "#dd4411"],
  607: ["#aa6677", "#88ee88", "#eeee99", "#770000", "#6677aa"],
  608: ["#00ccaa", "#229911", "#2288cc", "#dd7766", "#004455"],
  609: ["#224400", "#22aaff", "#9988ee", "#ffddff", "#aa5555"],
  610: ["#2222ee", "#bb66ff", "#bbcc88", "#9977cc", "#bbaaaa"],
  611: ["#66ff00", "#226611", "#aaddcc", "#bbaa77", "#77bbcc"],
  612: ["#bb33ee", "#aa22aa", "#bb5500", "#880066", "#11ff44"],
  613: ["#995599", "#6655ee", "#dddd33", "#dd44dd", "#11aa11"],
  614: ["#335544", "#00aa88", "#dd0099", "#885544", "#997766"],
  615: ["#33bb55", "#991199", "#116688", "#ff3322", "#994411"],
  616: ["#7711bb", "#998822", "#66bb99", "#ee6666", "#aa44ee"],
  617: ["#4455ee", "#ccbb88", "#442277", "#bb1155", "#eeffaa"],
  618: ["#332299", "#992233", "#ff11ff", "#446688", "#44bb77"],
  619: ["#bbffff", "#77ee66", "#44ffff", "#ee7722", "#5588ee"],
  620: ["#4400dd", "#557700", "#227700", "#aa66bb", "#3377cc"],
  621: ["#008833", "#8844cc", "#ff00cc", "#77ff77", "#8855bb"],
  622: ["#bb33cc", "#ddee00", "#882255", "#660000", "#447711"],
  623: ["#220077", "#22ccee", "#22bb00", "#7755aa", "#9900ff"],
  624: ["#55bb77", "#cc6600", "#ddffee", "#aa9911", "#554411"],
  625: ["#cc6666", "#44ee66", "#66bbcc", "#114433", "#8855dd"],
  626: ["#662288", "#1166ee", "#33ee44", "#11cc00", "#1100ff"],
  627: ["#ff11ff", "#22ccdd", "#ccddcc", "#000000", "#ee77aa"],
  628: ["#222266", "#662222", "#bbcc66", "#443377", "#44bbcc"],
  629: ["#00dd22", "#22dd11", "#773333", "#eeaa00", "#661177"],
  630: ["#2233ee", "#113333", "#bbbbaa", "#cc9911", "#331122"],
  631: ["#aa0066", "#00dd55", "#4411bb", "#cc5588", "#440066"],
  632: ["#55ff66", "#bb6688", "#dd88ff", "#dddd11", "#33cc00"],
  633: ["#2255bb", "#006655", "#aacc77", "#55bb77", "#cc77dd"],
  634: ["#eecc11", "#55dd11", "#eecc33", "#aaeebb", "#cc3399"],
  635: ["#55ee77", "#bb0077", "#4433ff", "#9922ee", "#cc22ee"],
  636: ["#22ff33", "#bb66ff", "#bb33ee", "#0033aa", "#994466"],
  637: ["#ffaaee", "#002233", "#aadddd", "#991111", "#0088ee"],
  638: ["#cc00bb", "#774499", "#dd2255", "#110066", "#449999"],
  639: ["#bb6633", "#bb3333", "#bbdd99", "#ccccff", "#22bb44"],
  640: ["#bbbb88", "#cc7766", "#77aadd", "#1133aa", "#ccdd55"],
  641: ["#dd4400", "#8833ff", "#eeffee", "#dd4477", "#ff9966"],
  642: ["#ccaabb", "#ee22bb", "#558833", "#7777dd", "#eeee66"],
  643: ["#117722", "#88dd99", "#4499cc", "#ee2288", "#5577aa"],
  644: ["#cc11ee", "#5588ff", "#ee9999", "#ffdd99", "#ee00ff"],
  645: ["#dd9944", "#7766cc", "#661155", "#77ddff", "#22ee88"],
  646: ["#ff44ff", "#ccdd00", "#1133ee", "#770044", "#aaff55"],
  647: ["#770033", "#66bbdd", "#cc66ff", "#2211cc", "#0022ff"],
  648: ["#33bbff", "#bbaa11", "#0033cc", "#aaff88", "#66bb77"],
  649: ["#665533", "#ccbb00", "#cc6633", "#bbff33", "#ee5566"],
  650: ["#ffee22", "#2299ff", "#9955dd", "#220022", "#aa3399"],
  651: ["#44ff88", "#004477", "#ffaa22", "#ccbb11", "#aa55cc"],
  652: ["#bbbbee", "#ffff44", "#aa9955", "#7799bb", "#009955"],
  653: ["#992255", "#cc2211", "#662211", "#5555aa", "#cc8899"],
  654: ["#1122bb", "#bb9922", "#3333dd", "#113344", "#7744aa"],
  655: ["#ff8888", "#cc3377", "#33eeff", "#77dd55", "#99cc77"],
  656: ["#00ee99", "#7766ee", "#552200", "#116688", "#aa00dd"],
  657: ["#33cc11", "#44ffee", "#ddee77", "#bb2266", "#6622dd"],
  658: ["#55ccbb", "#88eeee", "#ee2211", "#5599ff", "#224499"],
  659: ["#881199", "#33ddaa", "#662244", "#aa11cc", "#22dd00"],
  660: ["#229988", "#ffeebb", "#2233dd", "#001100", "#11bb99"],
  661: ["#aa11ee", "#777744", "#336699", "#881111", "#22cccc"],
  662: ["#6644ff", "#667733", "#5577ff", "#55ccaa", "#dd66ee"],
  663: ["#44ddbb", "#99ee99", "#5566dd", "#cc0033", "#ffcc55"],
  664: ["#77cc00", "#22dd44", "#5522ff", "#33bb77", "#2211dd"],
  665: ["#006688", "#bb00aa", "#6677ff", "#bb3300", "#11dd66"],
  666: ["#7744ee", "#442211", "#888855", "#ee55ee", "#0077bb"],
  667: ["#001122", "#aabbdd", "#cc00ff", "#223399", "#3366aa"],
  668: ["#885544", "#558822", "#664444", "#00cc44", "#444499"],
  669: ["#668877", "#0044dd", "#2288dd", "#99bb00", "#1177ee"],
  670: ["#992222", "#7744ff", "#777700", "#8866cc", "#5577bb"],
  671: ["#882211", "#aa55aa", "#dd44ee", "#77bb55", "#aadd00"],
  672: ["#ff5599", "#44ee22", "#bbeebb", "#557733", "#00bb99"],
  673: ["#11ee22", "#11cc55", "#aaddff", "#dd3311", "#dd0033"],
  674: ["#dd9999", "#1188aa", "#66dd77", "#aa3377", "#cc11bb"],
  675: ["#7722bb", "#338899", "#33eebb", "#aa2288", "#3366aa"],
  676: ["#ee00ff", "#eeccbb", "#ee1155", "#aa44cc", "#33ffff"],
  677: ["#221199", "#bb3344", "#bb66dd", "#ddcc99", "#33ffbb"],
  678: ["#ddee88", "#ee22aa", "#aaaa99", "#aa3322", "#cc0022"],
  679: ["#ccdd00", "#aa1144", "#7744dd", "#bbbbaa", "#994477"],
  680: ["#bbff77", "#aa0088", "#cceeff", "#aa7766", "#dd9955"],
  681: ["#00ffee", "#88ff99", "#777733", "#cccc44", "#cc6688"],
  682: ["#99dd11", "#3333cc", "#44dd22", "#aacc44", "#ee3355"],
  683: ["#992255", "#33ee88", "#dd9977", "#338899", "#55ddff"],
  684: ["#00cc22", "#aa22ee", "#aa44aa", "#dd6600", "#11ffee"],
  685: ["#1199ff", "#aabb33", "#cc99ff", "#eecc66", "#cc11dd"],
  686: ["#ddeeee", "#117777", "#338855", "#ffdd44", "#33ff88"],
  687: ["#cc00cc", "#336600", "#887788", "#332211", "#99bb11"],
  688: ["#bb6688", "#dd6600", "#cc6611", "#cceeee", "#443399"],
  689: ["#bb4466", "#ff44ee", "#33bb33", "#2255cc", "#ff9977"],
  690: ["#dddd11", "#33aabb", "#22ee77", "#aa33cc", "#bbff88"],
  691: ["#330044", "#dd33aa", "#aa88ee", "#33ddaa", "#884422"],
  692: ["#ffee77", "#eedd77", "#227788", "#ccbb99", "#99dd00"],
  693: ["#cc11cc", "#3366dd", "#887766", "#ffff33", "#557711"],
  694: ["#dd4466", "#115566", "#558877", "#eeff44", "#660044"],
  695: ["#001133", "#0055aa", "#cc5566", "#662233", "#ee6666"],
  696: ["#110088", "#220033", "#22bbcc", "#ffff88", "#996655"],
  697: ["#116622", "#555588", "#7722aa", "#5500cc", "#22ee99"],
  698: ["#dd9999", "#bb4455", "#5533aa", "#44aabb", "#338866"],
  699: ["#2244bb", "#887711", "#33ee00", "#99bb66", "#00bbee"],
  700: ["#442211", "#00aa77", "#ee33cc", "#66ffcc", "#dd2299"],
  701: ["#ccee44", "#7744dd", "#004477", "#4433bb", "#00ddbb"],
  702: ["#aa44ee", "#00eedd", "#993366", "#44ccff", "#bb6622"],
  703: ["#33aa00", "#aaeeaa", "#eecc88", "#772211", "#556699"],
  704: ["#ff88cc", "#11ee88", "#11dd77", "#99cc22", "#9988ff"],
  705: ["#44ee44", "#775566", "#66dd00", "#22bb22", "#779977"],
  706: ["#226655", "#eedd44", "#5511cc", "#bbbb55", "#bb3311"],
  707: ["#ddaaaa", "#77bb44", "#2288bb", "#33cc99", "#77cc77"],
  708: ["#1188cc", "#331166", "#33bb55", "#ff9966", "#bbbbdd"],
  709: ["#11ddcc", "#77aa99", "#553366", "#dd99aa", "#003322"],
  710: ["#007722", "#ddccbb", "#114400", "#88dd44", "#aaaa88"],
  711: ["#000055", "#bb4488", "#bbcc33", "#774422", "#00aa11"],
  712: ["#aa9966", "#ee0077", "#00ccaa", "#0088bb", "#cc5599"],
  713: ["#225566", "#dd22ee", "#ee9911", "#663399", "#aa6655"],
  714: ["#aa5533", "#4422ff", "#88ddff", "#eecc00", "#ddbbdd"],
  715: ["#337788", "#9944cc", "#66dd77", "#ffdd44", "#772211"],
  716: ["#88ff88", "#ff66bb", "#5577dd", "#0077ff", "#333344"],
  717: ["#44cc55", "#ddff22", "#0022aa", "#bb2266", "#22ffee"],
  718: ["#117755", "#220044", "#cc1122", "#cc8877", "#bb77cc"],
  719: ["#aa1166", "#667711", "#66aa99", "#339988", "#aaccbb"],
  720: ["#0022cc", "#666633", "#005500", "#2266ff", "#444477"],
  721: ["#883377", "#bb11ee", "#2277aa", "#5522ee", "#55ee55"],
  722: ["#ccaa55", "#9900dd", "#22dd99", "#005533", "#6699bb"],
  723: ["#22dd44", "#776633", "#ff9944", "#996633", "#99aabb"],
  724: ["#aaaa00", "#77bb77", "#ee0055", "#882288", "#ee5533"],
  725: ["#ff1144", "#ffbbbb", "#221111", "#338822", "#883377"],
  726: ["#227700", "#119911", "#119988", "#dd8888", "#00ddcc"],
  727: ["#55eeaa", "#ccaa88", "#3311aa", "#aaeecc", "#44ee00"],
  728: ["#6677aa", "#001199", "#ff22cc", "#337722", "#88bb99"],
  729: ["#cc33ee", "#ccaaaa", "#ddffdd", "#11aacc", "#661111"],
  730: ["#1155aa", "#113344", "#ff1166", "#ee4466", "#dd5511"],
  731: ["#cc11bb", "#11ee44", "#bbee77", "#556622", "#ffcc00"],
  732: ["#77aa99", "#00cc88", "#112200", "#775588", "#882233"],
  733: ["#991133", "#ee1155", "#5533dd", "#008877", "#aaff88"],
  734: ["#ee22cc", "#cc3322", "#77eeee", "#7766aa", "#bb11dd"],
  735: ["#8811bb", "#77eeaa", "#77aa55", "#993311", "#7755dd"],
  736: ["#1122aa", "#ee0077", "#eeee77", "#227799", "#779966"],
  737: ["#55ff88", "#77eebb", "#1144ff", "#668899", "#99bb77"],
  738: ["#aa6666", "#66ffcc", "#991155", "#995599", "#444433"],
  739: ["#dd3300", "#aadd66", "#559988", "#ccee66", "#aaff44"],
  740: ["#ffbbee", "#2255cc", "#44ee33", "#cccc22", "#1199cc"],
  741: ["#1166bb", "#0099ff", "#55dd33", "#882244", "#ff44ee"],
  742: ["#117733", "#557799", "#77ccdd", "#aa6688", "#33dd66"],
  743: ["#bb00dd", "#11ccdd", "#77cc99", "#2255aa", "#33ffcc"],
  744: ["#ccbb77", "#ee8888", "#1199dd", "#5555ee", "#aa6622"],
  745: ["#5577dd", "#5511cc", "#cc3344", "#ccffee", "#55bb77"],
  746: ["#444400", "#cc6666", "#aa0000", "#cc8800", "#ee44ee"],
  747: ["#dd4499", "#111166", "#776611", "#0088ff", "#33cc66"],
  748: ["#ee66ff", "#dd0033", "#8833aa", "#55aa22", "#ffbb99"],
  749: ["#225500", "#ddbb22", "#559933", "#cc4444", "#997711"],
  750: ["#dd9966", "#ccbb55", "#aa6633", "#445500", "#116677"],
  751: ["#880033", "#ff3311", "#ccbb66", "#00dd88", "#00bbff"],
  752: ["#88aabb", "#558844", "#7700dd", "#992222", "#9999aa"],
  753: ["#000011", "#33aa88", "#ff66dd", "#ff1100", "#0055ff"],
  754: ["#11cc88", "#ee3322", "#cc4400", "#ffaaaa", "#7799ee"],
  755: ["#bb88ff", "#11aa33", "#2299ee", "#ffccdd", "#ffddff"],
  756: ["#0000ff", "#cccc77", "#4433ee", "#22cc77", "#eeee88"],
  757: ["#cc6666", "#440099", "#66ddaa", "#441188", "#226655"],
  758: ["#997722", "#99ddff", "#aaffee", "#cc7755", "#4411cc"],
  759: ["#dd2211", "#66bb55", "#aa3377", "#661188", "#ff0055"],
  760: ["#ff00dd", "#008822", "#004477", "#aadd55", "#ddddbb"],
  761: ["#ff3366", "#77bb55", "#55cc88", "#ffcc44", "#aa4422"],
  762: ["#99aaee", "#33ff44", "#88dd66", "#996688", "#eeddee"],
  763: ["#ff3311", "#bb7733", "#66aaff", "#7799ff", "#dd2277"],
  764: ["#221188", "#00cc55", "#885511", "#774400", "#44cc11"],
  765: ["#ff6644", "#666633", "#ffcc22", "#ee3388", "#000011"],
  766: ["#33ee77", "#cccc00", "#4477aa", "#990066", "#00cc11"],
  767: ["#bbaa99", "#335555", "#666611", "#2266bb", "#bbdd99"],
  768: ["#eeffcc", "#33bbcc", "#223333", "#667700", "#aa8877"],
  769: ["#8855bb", "#1199dd", "#00bb55", "#9900dd", "#772288"],
  770: ["#008855", "#331177", "#336677", "#9900bb", "#9944bb"],
  771: ["#eeaa11", "#3377cc", "#008822", "#332222", "#2244bb"],
  772: ["#ee2200", "#aa1188", "#bbcc00", "#66cc66", "#550011"],
  773: ["#0033cc", "#ddbb00", "#bb0055", "#33eeff", "#cc33cc"],
  774: ["#222288", "#117744", "#bbddee", "#331177", "#4455aa"],
  775: ["#667744", "#aa6644", "#bb5511", "#aabbcc", "#442200"],
  776: ["#0077dd", "#225588", "#664499", "#cc7700", "#2200ff"],
  777: ["#2277ee", "#77ee33", "#44bbaa", "#111188", "#33ee33"],
  778: ["#22ee55", "#dd77bb", "#5588cc", "#1188aa", "#aa8855"],
  779: ["#4444ff", "#227755", "#9944bb", "#11ee88", "#66ff77"],
  780: ["#ddcc11", "#77ddbb", "#cc3333", "#118866", "#22ff77"],
  781: ["#332200", "#333344", "#bb6666", "#aa77cc", "#66ee11"],
  782: ["#cc9911", "#aabbaa", "#ddaabb", "#44ff33", "#88cc00"],
  783: ["#eeff66", "#5500dd", "#ee6655", "#666688", "#bb11aa"],
  784: ["#dd99cc", "#bb1122", "#bbbb55", "#88ff55", "#33ff88"],
  785: ["#770011", "#990033", "#ff4422", "#dd77dd", "#44ffaa"],
  786: ["#77aaee", "#336611", "#661199", "#aaffcc", "#33ee00"],
  787: ["#996666", "#223366", "#99ffbb", "#4499bb", "#ee44bb"],
  788: ["#bbee99", "#dd9999", "#ee22aa", "#995500", "#669911"],
  789: ["#bbee88", "#113311", "#ee6666", "#cc11ee", "#44eeff"],
  790: ["#11eeaa", "#330066", "#ee5588", "#bbbb99", "#eecc66"],
  791: ["#223333", "#778811", "#6666ee", "#aaffee", "#bb8833"],
  792: ["#223322", "#1166cc", "#4444cc", "#118899", "#ccdd99"],
  793: ["#11ff99", "#881133", "#3355aa", "#eeaacc", "#cc2233"],
  794: ["#888833", "#55ee77", "#884455", "#44ddbb", "#1111cc"],
  795: ["#6655ee", "#cc2255", "#55dd22", "#cc77ee", "#dd0033"],
  796: ["#009911", "#7799aa", "#ee88dd", "#117766", "#3333aa"],
  797: ["#666600", "#11ffbb", "#bb4422", "#3377ff", "#8877bb"],
  798: ["#5599dd", "#bb00dd", "#dd22cc", "#883366", "#bbeecc"],
  799: ["#aa44cc", "#88eedd", "#22bbee", "#cc0033", "#887788"],
  800: ["#005599", "#1188cc", "#551199", "#00ffff", "#22bbff"],
  801: ["#33aa55", "#2211bb", "#33ccbb", "#88ff11", "#88cc99"],
  802: ["#666655", "#cc3344", "#ff7744", "#44cc00", "#22ee00"],
  803: ["#99bb11", "#aabbaa", "#77ff33", "#eeaadd", "#ddaa11"],
  804: ["#9922ff", "#44ccee", "#2233bb", "#ddffbb", "#88ee33"],
  805: ["#550022", "#9955cc", "#00dd22", "#ddaa66", "#cc3355"],
  806: ["#110055", "#2277aa", "#339977", "#11eedd", "#33eeee"],
  807: ["#ee77ee", "#8833dd", "#55dd99", "#33aadd", "#aa9988"],
  808: ["#11dd22", "#33ff11", "#ff7711", "#005500", "#ffaa77"],
  809: ["#009922", "#553377", "#bb6677", "#777744", "#dd3322"],
  810: ["#cc33bb", "#cc6600", "#ff7766", "#ffccaa", "#330077"],
  811: ["#bbeecc", "#ddbbff", "#aa0000", "#88dd33", "#bb77ff"],
  812: ["#558899", "#11eeaa", "#ee88aa", "#eeee33", "#ee4499"],
  813: ["#111144", "#aaee55", "#cc8899", "#334400", "#55ddbb"],
  814: ["#667788", "#aa2222", "#bb9999", "#2299ee", "#ff8855"],
  815: ["#ff1188", "#cc0022", "#ff2277", "#227711", "#bb2200"],
  816: ["#882233", "#ff1144", "#4455ee", "#33ff66", "#dd7722"],
  817: ["#6677ee", "#bbaa55", "#bb88dd", "#0088bb", "#667722"],
  818: ["#558899", "#bbbbcc", "#bb11dd", "#550011", "#ee4466"],
  819: ["#aa0077", "#991155", "#338800", "#8844ee", "#1122ff"],
  820: ["#bbccdd", "#004455", "#7700ff", "#cc9922", "#885588"],
  821: ["#110077", "#115566", "#ee0077", "#bb66ff", "#449988"],
  822: ["#00cc66", "#7777dd", "#bb33ee", "#11bbee", "#7766bb"],
  823: ["#aa9966", "#229955", "#aaaaaa", "#110088", "#cc77cc"],
  824: ["#ccffbb", "#7799ee", "#44aa00", "#ccdd44", "#bb99bb"],
  825: ["#3322aa", "#0044ff", "#116699", "#223366", "#aa88dd"],
  826: ["#2288bb", "#9911ff", "#118811", "#ddff66", "#3399cc"],
  827: ["#448888", "#6699aa", "#ffff11", "#77aaaa", "#bb55aa"],
  828: ["#66aa77", "#dd88cc", "#11cc88", "#ff5599", "#7744ff"],
  829: ["#eedd77", "#44bb44", "#440044", "#ff9900", "#004466"],
  830: ["#bbee66", "#eeccaa", "#5588aa", "#ffdd99", "#44aacc"],
  831: ["#005588", "#eeffaa", "#111166", "#444488", "#6633dd"],
  832: ["#aa0088", "#9933bb", "#00bb55", "#66cc77", "#ff2233"],
  833: ["#bb6633", "#772255", "#ff0077", "#22aa00", "#221199"],
  834: ["#dd11dd", "#aabb55", "#ee55ee", "#1177ee", "#772222"],
  835: ["#aa2288", "#eeaa99", "#bb2200", "#4400ee", "#111111"],
  836: ["#22bb44", "#33aa44", "#55aacc", "#887722", "#ddbbaa"],
  837: ["#223344", "#bb8899", "#55dd77", "#cc3355", "#446666"],
  838: ["#8899aa", "#996699", "#aadd33", "#aa44dd", "#3311dd"],
  839: ["#882277", "#bbbb22", "#dd8866", "#dd22bb", "#ff55ee"],
  840: ["#cc3366", "#dd7733", "#116699", "#bb7700", "#bbcc33"],
  841: ["#66bb22", "#ccbb88", "#440088", "#449988", "#8899ee"],
  842: ["#aaff33", "#77ff88", "#005599", "#3355cc", "#555511"],
  843: ["#667722", "#aabbee", "#3388bb", "#bb1177", "#665511"],
  844: ["#445522", "#66aa99", "#008833", "#001177", "#bb4466"],
  845: ["#ffee99", "#cc44ff", "#88ee44", "#667744", "#00ddcc"],
  846: ["#88cc22", "#ff22aa", "#55ee88", "#338822", "#aa6622"],
  847: ["#225500", "#33bb55", "#ddaaaa", "#22ff55", "#ee55ee"],
  848: ["#ff2266", "#776622", "#ff66ff", "#776633", "#aadd44"],
  849: ["#221188", "#1133dd", "#dd1199", "#88dd55", "#33cccc"],
  850: ["#6644ff", "#6666cc", "#55ee66", "#882255", "#889977"],
  851: ["#776666", "#5533ee", "#11ccaa", "#446611", "#ddee66"],
  852: ["#66bb99", "#998877", "#3333dd", "#2211dd", "#dd8866"],
  853: ["#997733", "#ddaa88", "#ddaa44", "#5544cc", "#000011"],
  854: ["#334400", "#33ee88", "#66dd66", "#aaeebb", "#77bb77"],
  855: ["#116600", "#bb66dd", "#bb33aa", "#ee22aa", "#ee00cc"],
  856: ["#557766", "#ee4411", "#eebb33", "#331122", "#55ff88"],
  857: ["#cc7755", "#550055", "#aa66aa", "#eeee99", "#112255"],
  858: ["#bb6611", "#557744", "#ddeebb", "#ddee55", "#77bb22"],
  859: ["#ee3377", "#eebbee", "#bb8833", "#446655", "#44bb44"],
  860: ["#bbaa44", "#44aa77", "#4433ee", "#00ddbb", "#cc33bb"],
  861: ["#11bb22", "#6666cc", "#ffff44", "#553333", "#aa55dd"],
  862: ["#ffff22", "#993322", "#aaeeee", "#ddeeaa", "#114444"],
  863: ["#661155", "#ffff33", "#bbaaff", "#55cc66", "#ffee77"],
  864: ["#9966aa", "#ff3366", "#bb9944", "#442288", "#1199aa"],
  865: ["#887755", "#5522cc", "#dd6699", "#ff77ff", "#556622"],
  866: ["#007799", "#ff99ff", "#660011", "#220044", "#883300"],
  867: ["#99ee99", "#994455", "#ccffbb", "#88bbcc", "#2233aa"],
  868: ["#550022", "#2233ee", "#bb11ff", "#ffff11", "#220088"],
  869: ["#1144ee", "#222222", "#66eeff", "#7733bb", "#3333ee"],
  870: ["#8811dd", "#dd1155", "#2244aa", "#8833bb", "#dd7733"],
  871: ["#551100", "#889900", "#ee5577", "#66dd99", "#ee4411"],
  872: ["#aa8800", "#aadd66", "#440033", "#ff4499", "#aaaa66"],
  873: ["#222233", "#55aacc", "#88ff55", "#881155", "#cc22ff"],
  874: ["#aa8800", "#00bb00", "#cc2200", "#eeff11", "#33bbaa"],
  875: ["#cc2299", "#ffcccc", "#bb0011", "#ff4477", "#cc8800"],
  876: ["#bbbb66", "#33ffdd", "#00aa44", "#884499", "#33ff22"],
  877: ["#99aaee", "#ff0000", "#33bbbb", "#22bbbb", "#440077"],
  878: ["#117766", "#666677", "#22bbee", "#eebb99", "#33eeaa"],
  879: ["#338866", "#002222", "#443322", "#2288bb", "#aa77cc"],
  880: ["#bb4411", "#881166", "#554400", "#00ccaa", "#66dd99"],
  881: ["#ff2244", "#663355", "#002244", "#4488dd", "#ffff77"],
  882: ["#bbcc77", "#cc9999", "#994477", "#ee99ee", "#33ff88"],
  883: ["#aa0022", "#001122", "#22aa66", "#ff00ff", "#001133"],
  884: ["#ff0077", "#997777", "#cc1188", "#447799", "#0033bb"],
  885: ["#ee2211", "#ee7744", "#88ffff", "#77bbcc", "#ff66cc"],
  886: ["#ee00aa", "#22eeee", "#661144", "#55dd44", "#bb5577"],
  887: ["#ffcc88", "#33bb33", "#dd2222", "#224411", "#888811"],
  888: ["#66ee77", "#440033", "#aaffcc", "#dddd22", "#00eecc"],
  889: ["#44ffcc", "#bb88cc", "#5522ee", "#00aa00", "#ee77aa"],
  890: ["#44cc00", "#880099", "#44ff77", "#1100ff", "#339933"],
  891: ["#77cc99", "#772277", "#662277", "#339933", "#11ddee"],
  892: ["#440055", "#00ffcc", "#007788", "#bb1177", "#aa22ee"],
  893: ["#ccff33", "#bb1100", "#5599ee", "#ee2211", "#bb5555"],
  894: ["#550088", "#551155", "#bbbb22", "#991155", "#6611aa"],
  895: ["#88ff11", "#aa2211", "#dd4433", "#bbbbcc", "#cc3333"],
  896: ["#111133", "#8822bb", "#44ee77", "#bb33aa", "#11aa44"],
  897: ["#449933", "#eeaa11", "#ff0088", "#bbbb99", "#bbffbb"],
  898: ["#0044dd", "#ffee00", "#226688", "#44aaff", "#005544"],
  899: ["#ff0055", "#4455bb", "#557700", "#88bb11", "#aacc88"],
  900: ["#669900", "#006655", "#cc22ee", "#99bbbb", "#880033"],
  901: ["#11dd99", "#77eebb", "#11dd33", "#ffffaa", "#ff99aa"],
  902: ["#aa2255", "#cc55bb", "#ffff33", "#5533dd", "#668811"],
  903: ["#6655ee", "#556666", "#ccaa22", "#cc3300", "#444488"],
  904: ["#0011ee", "#ffccff", "#cc9911", "#ff22cc", "#ffaacc"],
  905: ["#ffbb66", "#8844bb", "#668899", "#bb5511", "#bbbb88"],
  906: ["#88ccbb", "#ffdd99", "#445599", "#2277ee", "#ddee55"],
  907: ["#886644", "#cc6677", "#66aa99", "#dd6622", "#aa11ff"],
  908: ["#3355dd", "#ee77aa", "#77bb66", "#00aa99", "#bb9955"],
  909: ["#551100", "#5500cc", "#3399bb", "#9911aa", "#442222"],
  910: ["#772211", "#2211aa", "#ee44dd", "#66aabb", "#bb66ff"],
  911: ["#99aacc", "#ddaadd", "#dd7799", "#333333", "#ee1177"],
  912: ["#6622dd", "#ff1155", "#44ff00", "#ff9999", "#cc66aa"],
  913: ["#77ff11", "#ccee11", "#66dd44", "#4400bb", "#aa44bb"],
  914: ["#449900", "#aacc11", "#77ff77", "#332277", "#4411aa"],
  915: ["#aa6622", "#99aa99", "#999933", "#aa55cc", "#aadd55"],
  916: ["#7777cc", "#1188bb", "#cc99ee", "#77ffff", "#ccbb66"],
  917: ["#dd33cc", "#bb11bb", "#2288ff", "#11aa44", "#ffaa88"],
  918: ["#ee44ee", "#ee4455", "#1144ff", "#9988ff", "#cc6611"],
  919: ["#ddbb88", "#446600", "#ee0077", "#55eedd", "#66ddee"],
  920: ["#ee88dd", "#cc3344", "#66aa77", "#dd6699", "#5588aa"],
  921: ["#66bb22", "#442233", "#333311", "#007799", "#ffbb44"],
  922: ["#5544aa", "#775599", "#bbbb00", "#6666bb", "#338888"],
  923: ["#229999", "#994477", "#ddaa55", "#441199", "#ddcc22"],
  924: ["#33ddaa", "#66ffff", "#cc2222", "#aa44ee", "#777700"],
  925: ["#335599", "#9966ee", "#001111", "#009955", "#335522"],
  926: ["#44ccff", "#44ff33", "#668899", "#66cc88", "#3322ff"],
  927: ["#ee4499", "#ffffbb", "#11bbcc", "#aaee33", "#220011"],
  928: ["#331133", "#dd6666", "#336611", "#cc2211", "#dd4422"],
  929: ["#cc8899", "#bbccbb", "#777711", "#aa88bb", "#44cc55"],
  930: ["#ee66cc", "#9911ff", "#77bb66", "#889922", "#ddcc44"],
  931: ["#55ff44", "#cc5555", "#ee1144", "#ccdd99", "#2211ff"],
  932: ["#005522", "#aa22dd", "#992277", "#aaee44", "#33eeff"],
  933: ["#ff88dd", "#3300dd", "#bbaa55", "#119900", "#110022"],
  934: ["#00ee33", "#99bb44", "#bb88bb", "#9977dd", "#221166"],
  935: ["#55aaaa", "#dd8855", "#aa8811", "#88cc00", "#7788bb"],
  936: ["#99bb22", "#66ffff", "#556600", "#aaee44", "#dd22aa"],
  937: ["#2288dd", "#4488aa", "#bb88ee", "#aa6699", "#77ccbb"],
  938: ["#88bbbb", "#99ddaa", "#ff3322", "#113344", "#7733ee"],
  939: ["#cc11ff", "#ff3366", "#cc9955", "#992288", "#aabbff"],
  940: ["#66aaff", "#99aa11", "#554433", "#aa9999", "#00bb11"],
  941: ["#dd3322", "#99bbdd", "#009955", "#dddd99", "#00aaff"],
  942: ["#aaeebb", "#aa1133", "#666633", "#993366", "#1100bb"],
  943: ["#aa33ff", "#bb4400", "#dddd00", "#335577", "#bbcc66"],
  944: ["#995599", "#443377", "#bb66aa", "#2211aa", "#00ffbb"],
  945: ["#aaccaa", "#ff9999", "#99ff66", "#226688", "#774488"],
  946: ["#bb22bb", "#bbffdd", "#3311dd", "#11bbee", "#8822cc"],
  947: ["#2211cc", "#dd0011", "#22bb22", "#1177cc", "#bb44dd"],
  948: ["#336644", "#11ccaa", "#773399", "#881199", "#ffbb22"],
  949: ["#114455", "#9922bb", "#223300", "#2288ff", "#cc1177"],
  950: ["#77ff00", "#ddaaaa", "#330088", "#bb8811", "#bbddff"],
  951: ["#dd99cc", "#dd8844", "#22bbaa", "#11ff11", "#77cc99"],
  952: ["#44ee11", "#bbff22", "#aa66dd", "#11ee44", "#774499"],
  953: ["#443311", "#ddeeff", "#33bbee", "#889911", "#ddddaa"],
  954: ["#cc9944", "#77dd77", "#44ffdd", "#66bbdd", "#664411"],
  955: ["#33cc88", "#cc0044", "#22ff77", "#cc88cc", "#00ee44"],
  956: ["#ee11bb", "#ff2200", "#bb9988", "#ddccee", "#0099ff"],
  957: ["#1166aa", "#223344", "#00cc00", "#bb6655", "#99ccee"],
  958: ["#779944", "#559933", "#ff0066", "#cc8800", "#22bb44"],
  959: ["#aa8899", "#881122", "#88bbdd", "#8866ee", "#22cc55"],
  960: ["#99cc99", "#22bb77", "#eebbcc", "#99ccee", "#11cc33"],
  961: ["#995511", "#225533", "#aaddee", "#0055dd", "#cc22bb"],
  962: ["#44ccaa", "#5588dd", "#ccbbaa", "#223355", "#44ff00"],
  963: ["#55dd66", "#5599cc", "#0011dd", "#33dddd", "#11bb33"],
  964: ["#446633", "#bb11aa", "#bb44dd", "#ddbb22", "#116677"],
  965: ["#99aaee", "#ff33cc", "#ff4455", "#eeff22", "#bbee44"],
  966: ["#5500cc", "#775544", "#aaff66", "#1177ee", "#55aa55"],
  967: ["#668833", "#55aacc", "#bbff22", "#339955", "#66ccaa"],
  968: ["#bb88ff", "#006644", "#774400", "#773344", "#cc33ff"],
  969: ["#22aabb", "#1133aa", "#7799ff", "#666633", "#eeaa33"],
  970: ["#551122", "#889966", "#ee0000", "#33cccc", "#22ee44"],
  971: ["#bb0055", "#22ee00", "#11eedd", "#663355", "#77eedd"],
  972: ["#88dd88", "#334499", "#dd2233", "#4455bb", "#00aa11"],
  973: ["#77ddaa", "#226655", "#6622dd", "#997744", "#442222"],
  974: ["#8855ee", "#cc7711", "#cc11bb", "#aabbdd", "#776655"],
  975: ["#bb4488", "#eeee44", "#556699", "#221177", "#331166"],
  976: ["#dd5500", "#aaaa77", "#ffee33", "#cc55ee", "#331188"],
  977: ["#995588", "#dd1155", "#ee3388", "#bb88cc", "#002200"],
  978: ["#99ff66", "#aaff55", "#11aa11", "#55ccff", "#558888"],
  979: ["#aa5599", "#9911ff", "#ddffff", "#dddd55", "#662211"],
  980: ["#88ddbb", "#3366bb", "#44ccdd", "#ee8800", "#bb1177"],
  981: ["#44eeee", "#224422", "#772255", "#aa6655", "#99ccaa"],
  982: ["#1133cc", "#225500", "#bbbb99", "#881177", "#33aa11"],
  983: ["#ee44aa", "#665511", "#88aa88", "#00cc44", "#22aaee"],
  984: ["#77aabb", "#cceeaa", "#885588", "#ddbb33", "#994433"],
  985: ["#008811", "#3333aa", "#aaff33", "#ccaaaa", "#777700"],
  986: ["#ffff99", "#bb1188", "#cc77ff", "#22cc00", "#11aa22"],
  987: ["#0022bb", "#5577dd", "#aaee88", "#33cc88", "#887733"],
  988: ["#ff99ee", "#778877", "#550077", "#444499", "#bbeedd"],
  989: ["#aaffee", "#dd0055", "#7799ee", "#55cc00", "#991111"],
  990: ["#445533", "#eebb66", "#221144", "#990088", "#554488"],
  991: ["#ff9900", "#22eeaa", "#5544cc", "#443322", "#aa99cc"],
  992: ["#bb6622", "#66ff55", "#cc77aa", "#aa8877", "#222255"],
  993: ["#cc1188", "#33ccee", "#bb6688", "#bbee55", "#55cc99"],
  994: ["#118833", "#66ff66", "#445522", "#dd3399", "#553388"],
  995: ["#bb22ff", "#334422", "#33bb33", "#99ffcc", "#bb4499"],
  996: ["#ee2211", "#775588", "#ccee22", "#dd66ff", "#664466"],
  997: ["#33dd00", "#6666dd", "#99ee99", "#bbff66", "#4433cc"],
  998: ["#112244", "#009900", "#44bb22", "#ff4433", "#bbffaa"],
  999: ["#66cc11", "#33ee55", "#dd0066", "#ff2266", "#77bb11"],
  1000: ["#ddddcc", "#667766", "#cc88dd", "#44bb99", "#ddddee"],
  1001: ["#aa6633", "#006666", "#3355cc", "#ee66bb", "#6677ff"],
  1002: ["#991133", "#9988ee", "#ee5522", "#ff3355", "#885544"],
  1003: ["#dd6633", "#88cc22", "#7777dd", "#aa1166", "#aa33dd"],
  1004: ["#ccbbff", "#ee99dd", "#aa5588", "#11cc88", "#442255"],
  1005: ["#88ddcc", "#77aaff", "#99ffaa", "#bbaa33", "#11ccff"],
  1006: ["#aa66dd", "#ccddaa", "#887733", "#552211", "#ff1111"],
  1007: ["#44ddee", "#555544", "#eeccee", "#bb1144", "#dd5511"],
  1008: ["#ccee99", "#44ccee", "#ddaacc", "#44aa55", "#cc55aa"],
  1009: ["#bb1111", "#4433dd", "#aa00aa", "#ff66ee", "#118899"],
  1010: ["#55ff00", "#cc55ee", "#118877", "#33bbbb", "#99bb88"],
  1011: ["#22ff00", "#995511", "#110066", "#22ffaa", "#77ff88"],
  1012: ["#bb22bb", "#7722ff", "#44bb11", "#ff33bb", "#aa55ff"],
  1013: ["#880066", "#dd1166", "#555588", "#44dd22", "#556600"],
  1014: ["#7711cc", "#117733", "#9988bb", "#1166cc", "#ee00ff"],
  1015: ["#cc88ff", "#22aa88", "#88bbff", "#6699ee", "#779933"],
  1016: ["#cc99ee", "#99aa11", "#ffcc99", "#4444ff", "#5577dd"],
  1017: ["#ffee99", "#bb11dd", "#338833", "#228811", "#dd22ee"],
  1018: ["#44ee33", "#33ffdd", "#aacc33", "#ee44ee", "#55cc77"],
  1019: ["#aaaabb", "#ccaaaa", "#5588dd", "#aa88ff", "#aabbdd"],
  1020: ["#337711", "#22aa88", "#3333bb", "#550044", "#eeee77"],
  1021: ["#554400", "#115599", "#22ffdd", "#aa3366", "#dd22ff"],
  1022: ["#22bbbb", "#44bb33", "#ff9900", "#ccee99", "#22cc33"],
  1023: ["#bbddbb", "#00bbcc", "#aa1188", "#aacccc", "#ee8855"],
  1024: ["#339977", "#5588bb", "#55aa88", "#99ffee", "#66ff11"],
  1025: ["#99ddee", "#226622", "#221188", "#aa99bb", "#22cc22"],
  1026: ["#2277dd", "#dd6699", "#ff2288", "#ee22ee", "#9966aa"],
  1027: ["#5544aa", "#000055", "#bb0099", "#9900dd", "#66bbbb"],
  1028: ["#888877", "#6655ff", "#225533", "#bb7733", "#112255"],
  1029: ["#cc4400", "#6644aa", "#bb88ff", "#bbaacc", "#44bb55"],
  1030: ["#554433", "#55eebb", "#44cc22", "#33dd11", "#aa7744"],
  1031: ["#eeaadd", "#77ff33", "#ddaa55", "#77bb44", "#880022"],
  1032: ["#3355cc", "#dd6655", "#6633ee", "#22bb99", "#ddee77"],
  1033: ["#44ee33", "#776666", "#22cc11", "#22eecc", "#cc5511"],
  1034: ["#aa00aa", "#556666", "#bb1122", "#775511", "#0055dd"],
  1035: ["#ccbbbb", "#ff8866", "#2277aa", "#882244", "#ee0033"],
  1036: ["#223333", "#557733", "#dd4488", "#99aa00", "#44ff88"],
  1037: ["#9955ee", "#331144", "#113355", "#dd11bb", "#ff88aa"],
  1038: ["#008899", "#9955cc", "#555544", "#aabb22", "#551188"],
  1039: ["#2255ee", "#7744cc", "#cc22dd", "#ff33bb", "#11aa77"],
  1040: ["#1199dd", "#337799", "#3344aa", "#ff4455", "#440088"],
  1041: ["#44aa00", "#aaddff", "#99aaaa", "#bbee88", "#ff77cc"],
  1042: ["#774433", "#dd4488", "#221144", "#662244", "#1100aa"],
  1043: ["#995522", "#226688", "#dd11aa", "#5500dd", "#44ff99"],
  1044: ["#eeee55", "#99ccff", "#554466", "#00bb44", "#aa6677"],
  1045: ["#bb9977", "#4499dd", "#66ff11", "#33cc22", "#cc0066"],
  1046: ["#aa33ff", "#119966", "#ff4411", "#996688", "#55aaff"],
  1047: ["#bbffdd", "#2233bb", "#bbaa66", "#663388", "#3333ff"],
  1048: ["#3355bb", "#ee22ff", "#884433", "#883388", "#77cc11"],
  1049: ["#551155", "#551188", "#eeee22", "#2299aa", "#4444ff"],
  1050: ["#55dd33", "#dd1144", "#00ff88", "#ff22aa", "#774411"],
  1051: ["#eeccdd", "#33cc22", "#aa2299", "#008800", "#3377dd"],
  1052: ["#446644", "#0099ff", "#3322ff", "#11aa33", "#eeeebb"],
  1053: ["#eeee77", "#1122cc", "#bbdd44", "#cc7744", "#88aadd"],
  1054: ["#bb22ff", "#333344", "#333322", "#22ee77", "#666611"],
  1055: ["#00aa00", "#229944", "#99dd00", "#221199", "#ee9944"],
  1056: ["#dd99bb", "#006677", "#77ccee", "#aaff99", "#77dd66"],
  1057: ["#993300", "#882266", "#7766ee", "#888811", "#4400ee"],
  1058: ["#eeffee", "#3300aa", "#111155", "#66cc33", "#226622"],
  1059: ["#6600bb", "#99aa88", "#667777", "#ccee66", "#00aa11"],
  1060: ["#dd8811", "#224499", "#dd0066", "#ff66ff", "#ee1144"],
  1061: ["#11cc77", "#3344aa", "#55aadd", "#bb4400", "#99ee66"],
  1062: ["#ccffcc", "#33aa77", "#666677", "#2233bb", "#00bb00"],
  1063: ["#225577", "#cc44cc", "#7799ee", "#dd6677", "#99cc11"],
  1064: ["#bbbbee", "#ff11bb", "#778899", "#88bbbb", "#33bb88"],
  1065: ["#00aa55", "#aaaa00", "#ffee66", "#00dd88", "#dd5500"],
  1066: ["#cc44ff", "#bbdd44", "#22ddff", "#ee66ee", "#ee11ff"],
  1067: ["#44ff00", "#3366cc", "#22ddff", "#66bbdd", "#992277"],
  1068: ["#996644", "#aa7788", "#33eecc", "#66cccc", "#00aa22"],
  1069: ["#aaeeaa", "#ddbbdd", "#996677", "#bbcc77", "#559922"],
  1070: ["#4488bb", "#99dd00", "#aa8855", "#0033ff", "#336611"],
  1071: ["#22aa00", "#88ee33", "#77dd00", "#ee0022", "#9944aa"],
  1072: ["#88ff22", "#ff9911", "#776611", "#33ffaa", "#887744"],
  1073: ["#773399", "#330077", "#551166", "#ffdd77", "#991122"],
  1074: ["#88ff99", "#cc88cc", "#553355", "#666600", "#aaffcc"],
  1075: ["#33ee00", "#44ee33", "#007777", "#335500", "#00dd77"],
  1076: ["#1144dd", "#77ffdd", "#996600", "#339977", "#225555"],
  1077: ["#1155dd", "#33bbaa", "#ee1144", "#661155", "#117711"],
  1078: ["#9955cc", "#ccccaa", "#886633", "#6622bb", "#776655"],
  1079: ["#aadd11", "#aabb77", "#6633aa", "#88dd66", "#11eedd"],
  1080: ["#88cc55", "#ff0088", "#99aa33", "#000044", "#aa0099"],
  1081: ["#33cccc", "#44aa99", "#cccc77", "#cc7777", "#44dd55"],
  1082: ["#ee0033", "#00ff66", "#992200", "#33cc77", "#ffaa77"],
  1083: ["#cc4488", "#99aadd", "#22aaff", "#558866", "#996600"],
  1084: ["#77bb33", "#bb99ee", "#777700", "#ee99aa", "#996644"],
  1085: ["#88aaee", "#dd8877", "#aa99aa", "#bbff22", "#ff55dd"],
  1086: ["#aa66aa", "#888888", "#55ee88", "#ddccdd", "#cc33ee"],
  1087: ["#55aa66", "#44ee77", "#bbeeaa", "#6644ee", "#11aa77"],
  1088: ["#882211", "#aa7733", "#22ddee", "#cceeff", "#dd5588"],
  1089: ["#44aaaa", "#bbeeee", "#aaee11", "#77ee99", "#66ff88"],
  1090: ["#aaeebb", "#ff5522", "#66dd11", "#bb55aa", "#dd3388"],
  1091: ["#ee9944", "#ee2277", "#00dd22", "#994488", "#ee5555"],
  1092: ["#22ddff", "#ffffee", "#ee33aa", "#cc88bb", "#bbddcc"],
  1093: ["#11ee88", "#aa8844", "#0011dd", "#886655", "#33ee44"],
  1094: ["#cc9944", "#779900", "#11dd99", "#66ddbb", "#dd77ee"],
  1095: ["#dd88ee", "#11ee00", "#11ff77", "#5555bb", "#7733bb"],
  1096: ["#ddffbb", "#cc0077", "#aabb00", "#661100", "#ee11cc"],
  1097: ["#aa33ee", "#bb4488", "#aabbaa", "#77ffcc", "#660099"],
  1098: ["#dd3300", "#117788", "#9999aa", "#cc6677", "#4400bb"],
  1099: ["#bb22ee", "#ee77bb", "#11ccee", "#0044ff", "#779933"],
  1100: ["#dd7711", "#113399", "#aaff88", "#ff77bb", "#77dd33"],
  1101: ["#ee3311", "#bbdd77", "#88ccff", "#ffffaa", "#bb8855"],
  1102: ["#88aa66", "#ee55ee", "#338811", "#444477", "#22ee00"],
  1103: ["#4433ee", "#ff44aa", "#442277", "#aa55bb", "#337788"],
  1104: ["#dd9922", "#00bb22", "#994466", "#aa88dd", "#eeeeff"],
  1105: ["#ee2266", "#ff0000", "#88bb22", "#996688", "#446688"],
  1106: ["#bb8800", "#7711bb", "#224466", "#22ffdd", "#8899bb"],
  1107: ["#117700", "#aa8811", "#4477aa", "#aa55cc", "#ff7722"],
  1108: ["#2211ee", "#66bb33", "#99ee77", "#bbddaa", "#221144"],
  1109: ["#88eeaa", "#11ee55", "#22ee66", "#775511", "#00aa66"],
  1110: ["#7755aa", "#ff4477", "#6688cc", "#2277bb", "#aaaa11"],
  1111: ["#22dd44", "#cc1188", "#117700", "#dd99ff", "#ff66ff"],
  1112: ["#bbcc55", "#22aa44", "#ee88cc", "#8877cc", "#dd99aa"],
  1113: ["#882200", "#118855", "#001188", "#447722", "#3344bb"],
  1114: ["#99bb00", "#446666", "#001122", "#118877", "#660066"],
  1115: ["#446622", "#5599cc", "#662222", "#332200", "#559977"],
  1116: ["#220033", "#553377", "#996699", "#eeeebb", "#55bb11"],
  1117: ["#cc33dd", "#661199", "#33ffaa", "#99aacc", "#995544"],
  1118: ["#990022", "#6644bb", "#332255", "#00bbbb", "#ee7700"],
  1119: ["#1122bb", "#ee77bb", "#22cc88", "#aa9900", "#2233dd"],
  1120: ["#441177", "#446622", "#4433dd", "#229999", "#44aa99"],
  1121: ["#5577aa", "#ccaa33", "#33bb77", "#882299", "#116688"],
  1122: ["#ee0033", "#aaff66", "#446688", "#44ffee", "#dd9922"],
  1123: ["#11ee88", "#553344", "#772299", "#4433dd", "#77aadd"],
  1124: ["#3399cc", "#6655ee", "#4488cc", "#55aa44", "#cc5566"],
  1125: ["#dd4466", "#0000aa", "#336600", "#99ccdd", "#9944dd"],
  1126: ["#226688", "#ff6611", "#eeccff", "#006666", "#77aa55"],
  1127: ["#cc3377", "#dd5511", "#aa7700", "#ffeeee", "#9933bb"],
  1128: ["#4411bb", "#00cc99", "#999955", "#664400", "#ee55ee"],
  1129: ["#33ddff", "#dd1111", "#550044", "#449966", "#7788ff"],
  1130: ["#778877", "#bbcc44", "#5544dd", "#ee44aa", "#77ffaa"],
  1131: ["#aa66ee", "#663366", "#8899ee", "#aa5588", "#55ccaa"],
  1132: ["#99ddee", "#118800", "#99aa55", "#22ee99", "#663388"],
  1133: ["#ff9988", "#dd00bb", "#ddff22", "#eecc88", "#999944"],
  1134: ["#55ff22", "#ddffee", "#ddaa66", "#66bbff", "#ee7733"],
  1135: ["#220088", "#66cc11", "#228822", "#005577", "#aa1111"],
  1136: ["#995533", "#ff0077", "#885544", "#ccddbb", "#33ee22"],
  1137: ["#2288dd", "#bb66bb", "#665577", "#992222", "#556633"],
  1138: ["#44ddbb", "#bb7733", "#118877", "#776677", "#bb88cc"],
  1139: ["#aa99ff", "#ee8899", "#8888aa", "#11bb44", "#44eebb"],
  1140: ["#7744ff", "#99cc66", "#667755", "#008833", "#334466"],
  1141: ["#88bbff", "#66ff33", "#ee11aa", "#ff5544", "#115577"],
  1142: ["#00aacc", "#bb22ee", "#ee5500", "#aaee11", "#eebb44"],
  1143: ["#aa5599", "#3377cc", "#77ff22", "#11cc33", "#77bb44"],
  1144: ["#cccc66", "#ee1144", "#cc2288", "#6600aa", "#44aadd"],
  1145: ["#115555", "#eeaaff", "#ff6699", "#44bb77", "#44dd00"],
  1146: ["#0055dd", "#66ddcc", "#994400", "#007799", "#00bb55"],
  1147: ["#ee8899", "#551111", "#117799", "#6622bb", "#aa1122"],
  1148: ["#0022bb", "#2299bb", "#5533ff", "#aaffee", "#33ff99"],
  1149: ["#ffbbdd", "#990055", "#995522", "#222222", "#5555bb"],
  1150: ["#bbbbee", "#1188ff", "#885533", "#22dd33", "#66dd22"],
  1151: ["#33ffff", "#ee0055", "#ffbb44", "#dd8877", "#00dd55"],
  1152: ["#aa5522", "#4488cc", "#6600cc", "#eecc44", "#5577aa"],
  1153: ["#88aa88", "#22cc99", "#772233", "#44aa55", "#662266"],
  1154: ["#ee1188", "#55ddaa", "#66aa00", "#331166", "#ee77cc"],
  1155: ["#aaee88", "#777755", "#dd99ff", "#aa99ee", "#00ff33"],
  1156: ["#cc8844", "#88aa00", "#998844", "#9999cc", "#33aa11"],
  1157: ["#ee8855", "#775500", "#990066", "#11aa44", "#dd9922"],
  1158: ["#aaeeaa", "#22ff55", "#55bbff", "#dd6655", "#44aa88"],
  1159: ["#bb4499", "#dd1133", "#330066", "#11bbff", "#1111cc"],
  1160: ["#bbcc99", "#662299", "#ff33aa", "#ffddaa", "#bb55bb"],
  1161: ["#33ee77", "#88ee00", "#bb4400", "#446644", "#66ee77"],
  1162: ["#0088ee", "#332255", "#337799", "#8833ee", "#332222"],
  1163: ["#ff4411", "#00bb22", "#ff5522", "#ee4422", "#44cc77"],
  1164: ["#006677", "#aaccff", "#eeee11", "#ff00ee", "#225544"],
  1165: ["#118899", "#2233aa", "#779933", "#7799ff", "#cc33aa"],
  1166: ["#bbcc99", "#55ff22", "#ee00cc", "#dd7766", "#6611bb"],
  1167: ["#448855", "#ccddaa", "#77eedd", "#aa6633", "#bbddee"],
  1168: ["#ccff22", "#ffbbdd", "#dd8866", "#99ddaa", "#aa5555"],
  1169: ["#bb6644", "#77dd66", "#668811", "#77dd88", "#1100ff"],
  1170: ["#aaaa99", "#7788cc", "#666622", "#aa00bb", "#ccaa11"],
  1171: ["#553388", "#bbdd22", "#ee00ee", "#99dd88", "#332277"],
  1172: ["#ff22bb", "#11eeee", "#aa6633", "#887777", "#8855cc"],
  1173: ["#447700", "#55bb88", "#aabb11", "#dd33bb", "#1166ff"],
  1174: ["#44bb00", "#885599", "#ccaa44", "#552266", "#558833"],
  1175: ["#77aa33", "#00ccee", "#668877", "#00aa33", "#880088"],
  1176: ["#994422", "#00dd33", "#55ff66", "#ccdd77", "#ddaa55"],
  1177: ["#aaffbb", "#00dd88", "#ddcc33", "#8888ee", "#882299"],
  1178: ["#559900", "#557755", "#11cc99", "#2255dd", "#2200ee"],
  1179: ["#0011dd", "#11eeff", "#777755", "#ff22bb", "#99ddcc"],
  1180: ["#332233", "#007744", "#ee77ee", "#7788bb", "#88aa44"],
  1181: ["#66ee33", "#771144", "#bbff99", "#116666", "#cc3311"],
  1182: ["#ff2211", "#882277", "#bb99ff", "#ddcc11", "#bbff00"],
  1183: ["#668833", "#66cc66", "#ee5511", "#cc9944", "#ff44ee"],
  1184: ["#ff6600", "#bbaaee", "#ff7711", "#004433", "#44bb11"],
  1185: ["#66cc33", "#eecc33", "#bbdd44", "#aa22bb", "#ffdd66"],
  1186: ["#22aa99", "#00ddff", "#bbaa22", "#11dd99", "#661188"],
  1187: ["#ddbbdd", "#aa44cc", "#0099bb", "#cc7744", "#ffccbb"],
  1188: ["#22aaee", "#ff2211", "#ffee99", "#dd88ee", "#bb8866"],
  1189: ["#33ee00", "#aaeeff", "#bb4433", "#994444", "#770077"],
  1190: ["#eeaadd", "#99ff44", "#eeff44", "#336677", "#997744"],
  1191: ["#aa9988", "#1133bb", "#22ff00", "#66ee55", "#0099bb"],
  1192: ["#cc55dd", "#6611dd", "#11cc44", "#227788", "#aa0088"],
  1193: ["#6622ee", "#ddff77", "#dd99aa", "#884444", "#44cc00"],
  1194: ["#55dd88", "#2222cc", "#668811", "#7722ee", "#cc3300"],
  1195: ["#88ccff", "#00eeaa", "#00ee55", "#aa9977", "#559999"],
  1196: ["#1155aa", "#cc5544", "#ccff55", "#ffaa77", "#11bbff"],
  1197: ["#ccaadd", "#ee3377", "#ffccaa", "#777711", "#ffff77"],
  1198: ["#337766", "#2277ff", "#77bbff", "#bb6699", "#7755aa"],
  1199: ["#668800", "#44aadd", "#883311", "#bb2211", "#77ff55"],
  1200: ["#22bb77", "#662288", "#9911aa", "#ddee88", "#eedd99"],
  1201: ["#ddaa33", "#55aa44", "#dd5544", "#9922ee", "#dd7733"],
  1202: ["#44cc44", "#66bb44", "#88dd22", "#bb11cc", "#332222"],
  1203: ["#442288", "#ccff88", "#996655", "#11ffaa", "#881188"],
  1204: ["#448833", "#8844cc", "#11bb77", "#ff3311", "#ffccbb"],
  1205: ["#8866bb", "#773311", "#113377", "#77dd88", "#44ffee"],
  1206: ["#aa8877", "#99ffbb", "#8844aa", "#aaeedd", "#0011bb"],
  1207: ["#2277ff", "#442288", "#88ee44", "#9944ff", "#2255dd"],
  1208: ["#554455", "#cc0033", "#ff99bb", "#ee0011", "#1100dd"],
  1209: ["#332288", "#dddd22", "#66ee55", "#335599", "#556600"],
  1210: ["#ff22aa", "#118888", "#ddff66", "#333377", "#55eeaa"],
  1211: ["#1100ee", "#ff00ee", "#119922", "#5500cc", "#ddff77"],
  1212: ["#882244", "#ddbbaa", "#ff1144", "#8822ee", "#0033dd"],
  1213: ["#225599", "#77aa33", "#aa1166", "#ddff88", "#66bb44"],
  1214: ["#6699cc", "#111111", "#2211aa", "#ee0099", "#222299"],
  1215: ["#00bb44", "#bb9933", "#44cc22", "#664411", "#00ccbb"],
  1216: ["#662255", "#bb0022", "#11ccaa", "#55ee66", "#44aa66"],
  1217: ["#ffdd55", "#0066ff", "#dd44ff", "#eeddee", "#332277"],
  1218: ["#668833", "#55cc00", "#ddbbcc", "#55ddaa", "#225511"],
  1219: ["#aa1155", "#558800", "#00ff44", "#00bbff", "#00dd44"],
  1220: ["#bb1111", "#6633ff", "#9933dd", "#22dd77", "#1111ee"],
  1221: ["#ffccdd", "#bbff77", "#33ee55", "#7766ee", "#bbee11"],
  1222: ["#66cc99", "#8899cc", "#1166ee", "#ee33dd", "#009966"],
  1223: ["#668855", "#cc44aa", "#665566", "#ccbbff", "#77aa66"],
  1224: ["#006600", "#880000", "#66aa99", "#2222ff", "#88ddaa"],
  1225: ["#ddbbaa", "#ff1111", "#3333cc", "#bbff33", "#660077"],
  1226: ["#445555", "#002277", "#eeddcc", "#22cc55", "#2200aa"],
  1227: ["#220022", "#22dd33", "#ff99bb", "#885555", "#44ffaa"],
  1228: ["#55bb00", "#4444aa", "#771144", "#dd6655", "#cc0022"],
  1229: ["#449922", "#55ff33", "#1144bb", "#220099", "#00cc11"],
  1230: ["#bb00aa", "#99ccdd", "#88aa77", "#aaaa77", "#001188"],
  1231: ["#338800", "#cc33bb", "#55aa00", "#7777aa", "#33ddee"],
  1232: ["#1111bb", "#99ffbb", "#3333ee", "#44ff44", "#eecc55"],
  1233: ["#44aa33", "#ee8811", "#bbddee", "#8833cc", "#889911"],
  1234: ["#66bb88", "#00eecc", "#dd9977", "#aa55cc", "#ddaa88"],
  1235: ["#aa1100", "#bbbb55", "#ee55ff", "#cc22bb", "#dd5522"],
  1236: ["#77dddd", "#77dd44", "#88ffaa", "#1122cc", "#ff8866"],
  1237: ["#007711", "#880044", "#aa5588", "#88bb11", "#dddd66"],
  1238: ["#661133", "#4411cc", "#66bb22", "#cc77cc", "#dd7788"],
  1239: ["#99ee44", "#44cc55", "#99aa88", "#993377", "#ccddee"],
  1240: ["#dd44ee", "#557733", "#551133", "#994444", "#eeaadd"],
  1241: ["#cc22cc", "#660077", "#bbee88", "#119933", "#55ffdd"],
  1242: ["#dd3366", "#886633", "#ccaaff", "#558811", "#0033aa"],
  1243: ["#553311", "#003399", "#882277", "#3388dd", "#ff8877"],
  1244: ["#447711", "#33bb11", "#66ff00", "#1177aa", "#cceeff"],
  1245: ["#880088", "#ff9966", "#88aacc", "#cc8899", "#aaeebb"],
  1246: ["#dd66aa", "#3300dd", "#22cccc", "#880000", "#33eeff"],
  1247: ["#ee22ee", "#558811", "#11bbdd", "#aaddcc", "#00dd66"],
  1248: ["#ccee11", "#ddbb66", "#aa1199", "#2266cc", "#7799cc"],
  1249: ["#998877", "#5566aa", "#11bb77", "#dd6688", "#eeffbb"],
  1250: ["#33aa77", "#aaddaa", "#99cc77", "#22dddd", "#2244dd"],
  1251: ["#dd0077", "#eeaa66", "#4422ff", "#ccbbaa", "#00dd55"],
  1252: ["#5577dd", "#ff3300", "#11bb55", "#7711cc", "#bbeecc"],
  1253: ["#ee5566", "#aa4400", "#99aaaa", "#bbaabb", "#cc88dd"],
  1254: ["#ffee44", "#44ddee", "#77cc11", "#337722", "#88ee77"],
  1255: ["#225544", "#ccdddd", "#bbcc33", "#aacc33", "#88dd33"],
  1256: ["#555599", "#221155", "#ee99ff", "#dd88bb", "#ccccee"],
  1257: ["#006633", "#558888", "#994477", "#ff4466", "#cc9933"],
  1258: ["#885588", "#cc22bb", "#336600", "#3388bb", "#66bb55"],
  1259: ["#5577bb", "#ff0000", "#3322aa", "#2244cc", "#2233ff"],
  1260: ["#dd9911", "#bbccbb", "#55ee00", "#2277ee", "#ee2255"],
  1261: ["#88cc55", "#006644", "#1188ee", "#aa88bb", "#bbaaff"],
  1262: ["#99aa44", "#aabbdd", "#55ffaa", "#2244bb", "#4466ff"],
  1263: ["#66aa44", "#667777", "#22ee88", "#33bb33", "#44bb33"],
  1264: ["#4411ff", "#ddaa44", "#8844cc", "#dd4422", "#88ff55"],
  1265: ["#66ddee", "#ccff44", "#ff9944", "#eedd00", "#22ff11"],
  1266: ["#bb55bb", "#88ee66", "#9911dd", "#775544", "#6655aa"],
  1267: ["#bb11ff", "#ff7722", "#bb11ee", "#3333ff", "#cc99ee"],
  1268: ["#bbdd00", "#6600ff", "#668877", "#aaddee", "#cc9922"],
  1269: ["#bbccbb", "#aa7799", "#666611", "#dddd44", "#88aa11"],
  1270: ["#ff8855", "#cc11ee", "#88cc99", "#cc8899", "#bb66cc"],
  1271: ["#ffee66", "#4411bb", "#ccaaaa", "#5533bb", "#77cc44"],
  1272: ["#bb88bb", "#22ff22", "#1188bb", "#991199", "#55cc11"],
  1273: ["#22dd55", "#00aaee", "#330055", "#ff1166", "#ee33cc"],
  1274: ["#5588dd", "#9977ee", "#dd7777", "#bb00bb", "#33ffbb"],
  1275: ["#aaaa88", "#888899", "#ee2288", "#5544ff", "#dd8855"],
  1276: ["#7733cc", "#ee1133", "#bb0022", "#44ee88", "#881144"],
  1277: ["#cc33bb", "#444488", "#55bbaa", "#5500dd", "#bb11bb"],
  1278: ["#ccbb44", "#8877ff", "#6688bb", "#ff11dd", "#22cc88"],
  1279: ["#22ddee", "#77ffff", "#cc9922", "#99cc11", "#eebbff"],
  1280: ["#339900", "#118866", "#dd3300", "#333322", "#aabbdd"],
  1281: ["#0011ff", "#77ff55", "#ffcc55", "#66aa55", "#55dd11"],
  1282: ["#008822", "#99bb66", "#cc77cc", "#55ccee", "#0066cc"],
  1283: ["#994477", "#884455", "#44ccdd", "#bbcccc", "#eeee22"],
  1284: ["#223399", "#cc4433", "#bbbb77", "#5533aa", "#ffbb11"],
  1285: ["#00bb44", "#228877", "#444499", "#cc5566", "#aa11ee"],
  1286: ["#00eebb", "#0088cc", "#cc0066", "#3388aa", "#7722dd"],
  1287: ["#dd4477", "#88bbee", "#dd99cc", "#33ee11", "#ffffcc"],
  1288: ["#998822", "#5555ff", "#33aaee", "#009966", "#337777"],
  1289: ["#ffbbbb", "#994400", "#aa22cc", "#220011", "#aa4455"],
  1290: ["#6633ee", "#bbbb22", "#dd0099", "#00ee11", "#7733dd"],
  1291: ["#44aaaa", "#ee99ff", "#99ccee", "#773344", "#007777"],
  1292: ["#7744ee", "#332200", "#660044", "#eecc55", "#669944"],
  1293: ["#2266ee", "#eecc55", "#8888ff", "#00ee88", "#554433"],
  1294: ["#66cc22", "#dd1144", "#99aaee", "#bb2299", "#336677"],
  1295: ["#ee3311", "#dd3333", "#777777", "#6688aa", "#339999"],
  1296: ["#444400", "#66cc66", "#cc9900", "#006611", "#bbddaa"],
  1297: ["#330000", "#ee6633", "#3322dd", "#99ffaa", "#5555bb"],
  1298: ["#2288bb", "#886666", "#66bbcc", "#1133dd", "#335599"],
  1299: ["#77dd77", "#339977", "#bbccbb", "#9955aa", "#ff2233"],
  1300: ["#00aabb", "#9955dd", "#8866aa", "#7744ee", "#774499"],
  1301: ["#99ee11", "#660033", "#662211", "#77ffff", "#001111"],
  1302: ["#330099", "#666633", "#99bb11", "#66ee66", "#ddff88"],
  1303: ["#00bbff", "#3300ff", "#88ddbb", "#9911dd", "#66cc00"],
  1304: ["#ffdd66", "#44ccdd", "#dd77bb", "#44bbcc", "#ff88cc"],
  1305: ["#55dd77", "#ddbbdd", "#11ff33", "#330044", "#cceeff"],
  1306: ["#ddcc88", "#ff6633", "#33aa55", "#22ff77", "#66eeee"],
  1307: ["#44dd77", "#aa7733", "#77ffee", "#777711", "#aaeeaa"],
  1308: ["#dd9922", "#77cc11", "#99cc55", "#ffff77", "#88cc11"],
  1309: ["#005599", "#44bb55", "#33bbaa", "#442255", "#115544"],
  1310: ["#556688", "#99dd11", "#ff00dd", "#ddcc00", "#88cc33"],
  1311: ["#bb0033", "#006644", "#aadd11", "#cc0077", "#442233"],
  1312: ["#55bbdd", "#33ee44", "#33bb22", "#224400", "#1100ee"],
  1313: ["#dd7766", "#44cc00", "#889966", "#bbcc00", "#aa7755"],
  1314: ["#cc7766", "#88ff33", "#77aa55", "#110022", "#ff9933"],
  1315: ["#448888", "#338800", "#441122", "#ff7711", "#3322ff"],
  1316: ["#9977bb", "#ee77dd", "#bb11ee", "#ddff00", "#773344"],
  1317: ["#bb0055", "#dd9988", "#228822", "#332244", "#441188"],
  1318: ["#3377ff", "#441100", "#33eedd", "#bb6688", "#772233"],
  1319: ["#3311dd", "#667755", "#7722dd", "#ffcc66", "#9966ee"],
  1320: ["#9955cc", "#225577", "#cc7744", "#aa8833", "#aa55ff"],
  1321: ["#660066", "#11bbff", "#77dd99", "#223388", "#aa8888"],
  1322: ["#668844", "#bb9977", "#eeff33", "#4444aa", "#33aa11"],
  1323: ["#dd77ff", "#663344", "#33aadd", "#22cc22", "#ee44ff"],
  1324: ["#3355ff", "#9911ff", "#99bbaa", "#dd33ee", "#2299ff"],
  1325: ["#44bbaa", "#aabbee", "#aaaa33", "#6611cc", "#dd8800"],
  1326: ["#9966dd", "#bbccaa", "#ee44bb", "#3322ff", "#bbaa66"],
  1327: ["#77bb88", "#aadd55", "#ccaa88", "#449900", "#ee5544"],
  1328: ["#dd8866", "#77ccaa", "#ccbbbb", "#cc3399", "#007799"],
  1329: ["#332211", "#66ffff", "#cc66bb", "#dd8811", "#3377aa"],
  1330: ["#ddcc11", "#110088", "#229955", "#bbffff", "#bbeeee"],
  1331: ["#228866", "#eebb99", "#991111", "#5500ee", "#aa2244"],
  1332: ["#2233ee", "#99eeaa", "#33aabb", "#440000", "#771100"],
  1333: ["#bb99aa", "#4488ee", "#88dd66", "#77ff22", "#8899ee"],
  1334: ["#ee99dd", "#dd0088", "#2288ee", "#77cc99", "#eecccc"],
  1335: ["#44ffee", "#eeff88", "#33dd66", "#88aacc", "#771133"],
  1336: ["#551133", "#117733", "#88ff55", "#ffee33", "#663355"],
  1337: ["#11bb00", "#3366cc", "#0000cc", "#aaaaaa", "#662288"],
  1338: ["#66ee22", "#99dd44", "#668899", "#994466", "#cc88aa"],
  1339: ["#66aa55", "#007766", "#cc5599", "#663311", "#665588"],
  1340: ["#aaaabb", "#55ee88", "#66ff99", "#111122", "#cc11bb"],
  1341: ["#00ddbb", "#776600", "#224433", "#777788", "#aa1155"],
  1342: ["#115500", "#773355", "#bb9933", "#dddd00", "#33bbee"],
  1343: ["#55dd66", "#887755", "#aa00bb", "#884411", "#9911bb"],
  1344: ["#ddaa00", "#ccff88", "#88bb77", "#dddd44", "#88cc44"],
  1345: ["#88ddbb", "#3399ff", "#228877", "#dddd88", "#ccee55"],
  1346: ["#bbdd99", "#333344", "#33ff77", "#22ffcc", "#aa9977"],
  1347: ["#2288ee", "#00eedd", "#5577cc", "#996644", "#33aa66"],
  1348: ["#88aaaa", "#aa1144", "#3311dd", "#0000cc", "#ff7700"],
  1349: ["#995500", "#77cc44", "#bb77bb", "#778844", "#221155"],
  1350: ["#7722ee", "#eeffcc", "#ccff88", "#eeaadd", "#ff33cc"],
  1351: ["#7711cc", "#44ee22", "#5588cc", "#aa0088", "#999955"],
  1352: ["#55eedd", "#99bbbb", "#441199", "#7766ff", "#668855"],
  1353: ["#774455", "#220099", "#bb3355", "#dd0077", "#bbeeee"],
  1354: ["#ddbb22", "#7711ee", "#55ff44", "#55ee88", "#aa1122"],
  1355: ["#dd2288", "#ffee22", "#ffaa44", "#7711cc", "#aa55aa"],
  1356: ["#3388ee", "#663333", "#ee1188", "#6666ff", "#99dd22"],
  1357: ["#aa00ee", "#ff5566", "#66aa55", "#22bbaa", "#99bb55"],
  1358: ["#ffeeaa", "#660022", "#cc3300", "#009955", "#66ccff"],
  1359: ["#66dd99", "#335533", "#774477", "#2255ee", "#999911"],
  1360: ["#88ccee", "#11bb00", "#006688", "#ee1155", "#ff44cc"],
  1361: ["#8877dd", "#aa9900", "#bb6666", "#ff8800", "#7766ee"],
  1362: ["#99ee44", "#6666ff", "#ff77aa", "#444477", "#66aa77"],
  1363: ["#bbffff", "#33dd55", "#991111", "#cc8888", "#55dd44"],
  1364: ["#9944dd", "#ffff00", "#995599", "#ee5500", "#007711"],
  1365: ["#2233aa", "#cc9922", "#eeaa99", "#dd88dd", "#aaff11"],
  1366: ["#6677ff", "#99eeaa", "#ccdd66", "#ddee66", "#775566"],
  1367: ["#22cc44", "#3333bb", "#001111", "#778844", "#cceeaa"],
  1368: ["#33aa33", "#ff88cc", "#ee33aa", "#55bbbb", "#dd44aa"],
  1369: ["#aa4466", "#227711", "#774400", "#4444bb", "#bb7766"],
  1370: ["#0033bb", "#22ee55", "#eebb66", "#eedd77", "#449988"],
  1371: ["#99bb88", "#445511", "#cc0055", "#22eecc", "#bbff00"],
  1372: ["#001111", "#660011", "#11ccbb", "#444433", "#dd66cc"],
  1373: ["#bb9966", "#bb9999", "#cc7777", "#aaee11", "#ccaaff"],
  1374: ["#559900", "#bbffbb", "#ccee77", "#77bb77", "#88ee00"],
  1375: ["#7711aa", "#66ffff", "#4433aa", "#332211", "#aa7722"],
  1376: ["#88aa66", "#ddcc44", "#33ee00", "#448800", "#556622"],
  1377: ["#3366bb", "#aa4499", "#33cc99", "#88bb66", "#cc4422"],
  1378: ["#77ccee", "#66ee99", "#77eeff", "#dd55ff", "#00ff66"],
  1379: ["#55ee55", "#55ddcc", "#7788ff", "#440022", "#ff3344"],
  1380: ["#220022", "#ee33bb", "#551166", "#77aacc", "#88dd33"],
  1381: ["#9944aa", "#7777ee", "#22aa11", "#aa11ff", "#33dd44"],
  1382: ["#22aaaa", "#7777bb", "#11ccee", "#cc7766", "#ccbbbb"],
  1383: ["#4411bb", "#00cc44", "#44ffff", "#0055bb", "#ee3355"],
  1384: ["#44eedd", "#5577bb", "#ffaaff", "#22bb55", "#dd88ff"],
  1385: ["#334444", "#dd0088", "#886677", "#ffff55", "#115555"],
  1386: ["#cc66dd", "#aa88ee", "#55ee77", "#cc5500", "#66dd22"],
  1387: ["#cc9977", "#bbaaaa", "#998866", "#00ee22", "#88ff22"],
  1388: ["#9911bb", "#66aabb", "#aacc44", "#4455ff", "#ee66ff"],
  1389: ["#99ee55", "#33dd99", "#bbbbff", "#ccee22", "#dd1155"],
  1390: ["#887744", "#4400ee", "#996611", "#11ddcc", "#22ff55"],
  1391: ["#11dd44", "#cc3366", "#4411aa", "#11dd44", "#cc1177"],
  1392: ["#0077ff", "#ee22dd", "#999944", "#2299cc", "#33dddd"],
  1393: ["#bb1166", "#001188", "#dd6677", "#441166", "#aaccbb"],
  1394: ["#00dd99", "#22eecc", "#bbaaaa", "#ff7722", "#0022bb"],
  1395: ["#886666", "#ff22cc", "#ffaaff", "#33ff00", "#88dd88"],
  1396: ["#33ee77", "#aa33aa", "#000055", "#aadd33", "#332244"],
  1397: ["#11aa77", "#bb55ee", "#99ee99", "#00aacc", "#6611ff"],
  1398: ["#1133dd", "#66ff88", "#4422aa", "#ee4433", "#229999"],
  1399: ["#332222", "#99bbcc", "#99aa55", "#ccbb77", "#33bbcc"],
  1400: ["#7755aa", "#22ff99", "#33aa44", "#002222", "#338844"],
  1401: ["#88aa00", "#ff77dd", "#55cc77", "#221144", "#4422bb"],
  1402: ["#99ee88", "#66bb11", "#bb4455", "#44ffff", "#ff1100"],
  1403: ["#114488", "#ee8866", "#779999", "#ff66ff", "#8800ff"],
  1404: ["#bb6677", "#bbbbdd", "#bb1166", "#ee8888", "#1133bb"],
  1405: ["#004466", "#557788", "#5566ee", "#33cc99", "#223322"],
  1406: ["#3333ee", "#1177dd", "#ee8899", "#dddddd", "#dd4444"],
  1407: ["#3388cc", "#bb7799", "#337777", "#99aa77", "#994477"],
  1408: ["#118811", "#ff00dd", "#eeccff", "#cc33cc", "#115522"],
  1409: ["#44cc55", "#7700ee", "#bbddcc", "#cc22dd", "#ccaa66"],
  1410: ["#aa7722", "#991199", "#116688", "#8877ee", "#ee44ff"],
  1411: ["#ddcc99", "#00eedd", "#774433", "#eeaa66", "#ff33dd"],
  1412: ["#66bbaa", "#44eeaa", "#55eedd", "#99aa99", "#ff4422"],
  1413: ["#dd4422", "#dd2211", "#ddff22", "#bb88bb", "#22bb66"],
  1414: ["#99cc44", "#bb2288", "#002244", "#eeee99", "#bb5544"],
  1415: ["#bbbb77", "#004400", "#2244bb", "#006688", "#ee3333"],
  1416: ["#ee9955", "#44ee00", "#223366", "#bbccbb", "#ff6622"],
  1417: ["#2299ee", "#bb7766", "#44bbff", "#1177aa", "#5522aa"],
  1418: ["#bbff77", "#55aabb", "#aa77ee", "#0077ee", "#335500"],
  1419: ["#992299", "#33aa00", "#2211ee", "#aa1122", "#bbffff"],
  1420: ["#001155", "#551144", "#9988bb", "#55aacc", "#cc6666"],
  1421: ["#779944", "#ff9900", "#005577", "#55eeaa", "#dd9922"],
  1422: ["#7722bb", "#8855ee", "#ddddcc", "#aa22ff", "#88ff44"],
  1423: ["#881133", "#992255", "#33dd99", "#44ff55", "#88ee88"],
  1424: ["#aa7722", "#007799", "#00ff00", "#22bb99", "#dd1111"],
  1425: ["#ff6699", "#776655", "#885566", "#1155ee", "#ffccee"],
  1426: ["#884433", "#55bbaa", "#bb7722", "#557788", "#cc4422"],
  1427: ["#bb2288", "#55bbcc", "#bbff55", "#9900cc", "#55ccbb"],
  1428: ["#775566", "#6655aa", "#2255ff", "#33ff77", "#443399"],
  1429: ["#cc5588", "#00dd11", "#99bbee", "#99bb55", "#44ccee"],
  1430: ["#557711", "#ff22cc", "#ee9977", "#ff0044", "#bb8877"],
  1431: ["#dd6688", "#aa11cc", "#ee55ff", "#336611", "#77bb99"],
  1432: ["#3377dd", "#55bbdd", "#119988", "#220055", "#22ddaa"],
  1433: ["#0077ee", "#33ff99", "#ff77bb", "#dd7799", "#33ddff"],
  1434: ["#9944cc", "#ffee77", "#666655", "#4488dd", "#221155"],
  1435: ["#113366", "#110088", "#55ddee", "#aaccff", "#33eedd"],
  1436: ["#ddee66", "#ff9944", "#33bb44", "#aaee88", "#44bbdd"],
  1437: ["#66ee44", "#eeffdd", "#77ee77", "#bb5566", "#884488"],
  1438: ["#66ffff", "#22cc11", "#ff1166", "#661188", "#22bb88"],
  1439: ["#223366", "#ffffbb", "#ee00bb", "#99bb77", "#559944"],
  1440: ["#aaee00", "#555599", "#9955aa", "#ee9900", "#eedd77"],
  1441: ["#338899", "#66dddd", "#ff22aa", "#44bbcc", "#44bb44"],
  1442: ["#001155", "#11bbdd", "#ddbb77", "#ee3388", "#22aacc"],
  1443: ["#666677", "#ff8833", "#110022", "#ff4411", "#ccccbb"],
  1444: ["#99ee77", "#772266", "#88eebb", "#662244", "#8877bb"],
  1445: ["#559977", "#ff3333", "#443311", "#cc33ff", "#880022"],
  1446: ["#6622aa", "#99ffff", "#6611ee", "#33ff88", "#882288"],
  1447: ["#bb6655", "#ffaadd", "#9900ee", "#4433bb", "#ffccff"],
  1448: ["#bb55ff", "#ff3388", "#88bb55", "#442200", "#223377"],
  1449: ["#dd11dd", "#7777bb", "#bbee33", "#eedd22", "#66dd11"],
  1450: ["#889999", "#ee7722", "#77aa66", "#aa9955", "#bb3311"],
  1451: ["#88bbbb", "#ffffbb", "#88ff11", "#88bb88", "#bbbbdd"],
  1452: ["#33aacc", "#aaff99", "#226655", "#2288ee", "#11ccdd"],
  1453: ["#ee0022", "#eeffaa", "#cc2233", "#66aaff", "#0033aa"],
  1454: ["#665555", "#aa2277", "#00ee22", "#00bb44", "#4422aa"],
  1455: ["#226688", "#1177aa", "#ff1199", "#22ddee", "#ee2299"],
  1456: ["#331177", "#1177aa", "#005577", "#bb66cc", "#2244ff"],
  1457: ["#000066", "#117755", "#555599", "#550044", "#99aadd"],
  1458: ["#665500", "#22bb11", "#0055dd", "#998888", "#775566"],
  1459: ["#cc22bb", "#888888", "#33ffaa", "#661199", "#bbaa22"],
  1460: ["#8822aa", "#ee9933", "#bb9977", "#ee0088", "#ccaabb"],
  1461: ["#dd77ee", "#993344", "#669977", "#aa0033", "#ddcc66"],
  1462: ["#cc1100", "#ee88cc", "#00cc77", "#772200", "#ff4433"],
  1463: ["#666655", "#22bbbb", "#77dddd", "#666600", "#22bb33"],
  1464: ["#aa2200", "#777711", "#442200", "#112266", "#999999"],
  1465: ["#aaff00", "#ff00ff", "#8877dd", "#ff66bb", "#ccaa88"],
  1466: ["#ee9911", "#cc66bb", "#aabb77", "#66aa11", "#44ddff"],
  1467: ["#55dd11", "#bbdd44", "#7777ee", "#ddcc66", "#cc4400"],
  1468: ["#dd11bb", "#dd5533", "#dd8899", "#33ee99", "#883333"],
  1469: ["#ccaa33", "#77ff00", "#6688bb", "#00ccbb", "#cc77aa"],
  1470: ["#44bbff", "#dddd66", "#3322cc", "#dd9977", "#bbff55"],
  1471: ["#000000", "#eeffcc", "#4411bb", "#226611", "#660066"],
  1472: ["#0099aa", "#ddaa33", "#00aa66", "#dd6633", "#ddff55"],
  1473: ["#115566", "#7788aa", "#33ee44", "#22eecc", "#5588ff"],
  1474: ["#998888", "#cc1100", "#3333dd", "#11cc88", "#ffee55"],
  1475: ["#88ff88", "#ffaa66", "#11dd22", "#990088", "#002255"],
  1476: ["#cc33ee", "#66ffbb", "#ff6699", "#bbdd11", "#446666"],
  1477: ["#8800ee", "#665588", "#6677ff", "#cc66bb", "#11ffaa"],
  1478: ["#004400", "#115544", "#aadd66", "#ee7711", "#dd1166"],
  1479: ["#113300", "#88ffff", "#88dd88", "#ff88aa", "#881177"],
  1480: ["#aa00ee", "#5500dd", "#4400ee", "#00bb99", "#00ff11"],
  1481: ["#447799", "#dd4499", "#5599ff", "#dd55cc", "#8888ee"],
  1482: ["#5555cc", "#cc22aa", "#cc7755", "#22cc33", "#7744aa"],
  1483: ["#ee99ee", "#77cc66", "#994411", "#0055ff", "#00aa99"],
  1484: ["#ddff33", "#22aaee", "#9900cc", "#bb33aa", "#774444"],
  1485: ["#88eeaa", "#bbdd44", "#aaff44", "#55cc88", "#009911"],
  1486: ["#eedd77", "#66ff77", "#002222", "#4433bb", "#4466bb"],
  1487: ["#dd33ee", "#66aacc", "#dd66aa", "#cc4422", "#668899"],
  1488: ["#555511", "#dd6622", "#44ffcc", "#666611", "#6677dd"],
  1489: ["#77aadd", "#2266aa", "#cc22ee", "#aaddff", "#bb2277"],
  1490: ["#aaffbb", "#eecc66", "#117799", "#dd00aa", "#886644"],
  1491: ["#cc0088", "#eedd99", "#33bbbb", "#44dd44", "#9922cc"],
  1492: ["#44aaff", "#116666", "#ee2244", "#cc88bb", "#aadd33"],
  1493: ["#225522", "#77bb77", "#ee1188", "#770022", "#5511ff"],
  1494: ["#99ff55", "#335566", "#77dddd", "#00ee99", "#88aadd"],
  1495: ["#dd0000", "#113377", "#cc1155", "#339966", "#110033"],
  1496: ["#aa7766", "#00bbbb", "#ff33aa", "#669911", "#dd3355"],
  1497: ["#44dd55", "#bb55dd", "#664422", "#ee55dd", "#dd5599"],
  1498: ["#aa2266", "#006666", "#550022", "#33ddaa", "#ee1188"],
  1499: ["#dd0055", "#66dd88", "#99ffaa", "#117744", "#5533dd"],
  1500: ["#bb22dd", "#551177", "#ee8833", "#227777", "#3333bb"],
  1501: ["#ff44dd", "#bb2244", "#ffccaa", "#aabb66", "#ccee22"],
  1502: ["#336622", "#00ff33", "#00ee99", "#ee5533", "#338899"],
  1503: ["#444433", "#11cc11", "#225599", "#224466", "#8866cc"],
  1504: ["#668877", "#88dd88", "#55dd44", "#aa4444", "#00ee77"],
  1505: ["#dd00aa", "#22ddee", "#aaee33", "#9977ee", "#dd7733"],
  1506: ["#2288dd", "#331177", "#11ddff", "#8877cc", "#ffff00"],
  1507: ["#00ff66", "#ffddaa", "#ee2266", "#00ddff", "#dddd88"],
  1508: ["#332288", "#ccaa88", "#9955dd", "#22cc00", "#ffeeee"],
  1509: ["#223300", "#00ccee", "#ff6666", "#cc2200", "#bbdd11"],
  1510: ["#445588", "#aaeedd", "#115500", "#aaaabb", "#cc7777"],
  1511: ["#bb5588", "#ee4477", "#331199", "#449900", "#111177"],
  1512: ["#4411dd", "#00bbaa", "#11dd44", "#993322", "#6677aa"],
  1513: ["#9988ff", "#ccbb66", "#ffff66", "#0000ee", "#2200cc"],
  1514: ["#002299", "#558899", "#4422bb", "#44ccff", "#ff3311"],
  1515: ["#773366", "#dd4400", "#007711", "#7788ee", "#551155"],
  1516: ["#555599", "#11cc11", "#aa2299", "#ee44ee", "#996633"],
  1517: ["#bb4466", "#cc44ee", "#bb33aa", "#6655dd", "#115555"],
  1518: ["#77ddcc", "#ffee44", "#223333", "#ccee77", "#bbbbbb"],
  1519: ["#22ffcc", "#33ffdd", "#775599", "#55bbee", "#6655bb"],
  1520: ["#cc88cc", "#330000", "#445566", "#ddbb33", "#229988"],
  1521: ["#dd8899", "#5555ee", "#11bbbb", "#0022cc", "#883399"],
  1522: ["#223388", "#aa6611", "#99ffaa", "#ff2288", "#222211"],
  1523: ["#006633", "#99ddee", "#332288", "#bb1133", "#ff22ff"],
  1524: ["#66dd99", "#44ee22", "#66ff11", "#33aa44", "#cc9944"],
  1525: ["#4455aa", "#ccaaaa", "#55eeaa", "#444488", "#cc8811"],
  1526: ["#334477", "#aa33aa", "#cc0077", "#3311bb", "#aa4466"],
  1527: ["#110066", "#554422", "#ffcc88", "#ffaa77", "#771199"],
  1528: ["#cc8833", "#ffaa22", "#0066cc", "#ffffdd", "#11cc88"],
  1529: ["#881155", "#ee8833", "#ff3333", "#880044", "#dd88cc"],
  1530: ["#2244ee", "#44ffdd", "#aa3333", "#999933", "#008822"],
  1531: ["#bb11bb", "#22bbff", "#bb66ff", "#775555", "#ddddcc"],
  1532: ["#cc5599", "#2266dd", "#66eebb", "#777788", "#aa5511"],
  1533: ["#ffaa66", "#5588aa", "#665511", "#2233aa", "#88bbaa"],
  1534: ["#11aa77", "#ff22aa", "#998822", "#4466ee", "#33aabb"],
  1535: ["#dddd22", "#cc7733", "#99cc44", "#cc8833", "#992266"],
  1536: ["#667700", "#4455aa", "#ffcccc", "#5555cc", "#ffff77"],
  1537: ["#2222dd", "#994433", "#55dd44", "#ccdd11", "#55ccaa"],
  1538: ["#ccffff", "#ddccff", "#dd88cc", "#00dd55", "#77dddd"],
  1539: ["#44ee88", "#4488ff", "#bb44bb", "#ddcc22", "#eeeeaa"],
  1540: ["#77cc55", "#224400", "#bb1122", "#bb6655", "#00dd55"],
  1541: ["#cc9999", "#eeaa00", "#110000", "#664499", "#33ff22"],
  1542: ["#88ff11", "#777744", "#6655dd", "#11dd22", "#44ee55"],
  1543: ["#9955aa", "#aa8899", "#9900aa", "#66cc66", "#00cc00"],
  1544: ["#7788ee", "#3300ee", "#bb8822", "#bb66aa", "#99ffbb"],
  1545: ["#00aa99", "#559911", "#ccee88", "#99cc55", "#bb5599"],
  1546: ["#775599", "#ee99ee", "#aa22dd", "#992233", "#998855"],
  1547: ["#eeee00", "#55eeff", "#dd0099", "#44eedd", "#cc1166"],
  1548: ["#774433", "#9900ff", "#cccc22", "#7766cc", "#773300"],
  1549: ["#66cc55", "#dd8888", "#6611aa", "#661111", "#bbffff"],
  1550: ["#992288", "#77cc33", "#0099dd", "#aa6655", "#5500bb"],
  1551: ["#aaccff", "#007744", "#ff8877", "#333366", "#ccbb77"],
  1552: ["#226600", "#ff6677", "#bb00cc", "#88ff66", "#8833dd"],
  1553: ["#55bb55", "#55ff88", "#2233dd", "#3399cc", "#ee55ee"],
  1554: ["#88eeee", "#bbcc88", "#0000ff", "#ee2244", "#88ffaa"],
  1555: ["#ee44bb", "#331133", "#228877", "#bb00bb", "#eeee88"],
  1556: ["#dd99aa", "#55aa88", "#009911", "#3344dd", "#66ff22"],
  1557: ["#dd2277", "#77bb33", "#ee2255", "#aaffaa", "#118899"],
  1558: ["#225544", "#22dd22", "#446644", "#ee99dd", "#2211bb"],
  1559: ["#112288", "#111199", "#9933ee", "#11dd55", "#00aa88"],
  1560: ["#0011aa", "#775522", "#446688", "#668822", "#bb77dd"],
  1561: ["#11ffee", "#aaeeaa", "#ddbbee", "#ee8811", "#aaee44"],
  1562: ["#aaee33", "#ff9988", "#0022ee", "#4411cc", "#661177"],
  1563: ["#dd9988", "#773399", "#bb5533", "#aa1188", "#aa00dd"],
  1564: ["#99ee11", "#55cc77", "#888811", "#113311", "#aabbdd"],
  1565: ["#224444", "#cc0033", "#dd7799", "#229999", "#cccc33"],
  1566: ["#bb0077", "#11dd88", "#006644", "#6633ee", "#ee5511"],
  1567: ["#33bb66", "#665511", "#992222", "#aaaa66", "#ddff11"],
  1568: ["#550011", "#77dd44", "#9922aa", "#bbaa88", "#aa3300"],
  1569: ["#774488", "#bb00ee", "#bb4422", "#ee5588", "#338844"],
  1570: ["#33ccbb", "#aa5522", "#777733", "#ee00ee", "#ccbb22"],
  1571: ["#dd9999", "#7788dd", "#5588bb", "#110055", "#88ff99"],
  1572: ["#aa7788", "#ee44dd", "#33ee44", "#6699bb", "#552266"],
  1573: ["#22ffff", "#ee4400", "#999966", "#551100", "#bb8822"],
  1574: ["#7788bb", "#55aa44", "#bb3344", "#ee8844", "#7799bb"],
  1575: ["#44eeee", "#7744ee", "#aa0022", "#66dd66", "#112233"],
  1576: ["#886622", "#aa8877", "#11ffff", "#884422", "#55ddff"],
  1577: ["#2233bb", "#221188", "#33cc77", "#66bbee", "#77bb00"],
  1578: ["#eeeeaa", "#6688cc", "#667711", "#9988bb", "#22aa00"],
  1579: ["#1188ee", "#558833", "#776622", "#330088", "#dd9988"],
  1580: ["#ddbb33", "#885511", "#55eeee", "#22dd33", "#ddbb11"],
  1581: ["#55aa22", "#116677", "#77cc88", "#ffddaa", "#2255ee"],
  1582: ["#887799", "#5566bb", "#ee22bb", "#11aa22", "#337766"],
  1583: ["#4466ff", "#bbee66", "#9911ee", "#aa4400", "#aa66dd"],
  1584: ["#dd99dd", "#77ffcc", "#44bbbb", "#110099", "#4444aa"],
  1585: ["#5577aa", "#887766", "#77bbcc", "#ffcc77", "#ffaa00"],
  1586: ["#bbbbbb", "#bb2255", "#aa7766", "#dd6611", "#336633"],
  1587: ["#550000", "#55cc99", "#662244", "#44ffff", "#5522bb"],
  1588: ["#ff6622", "#cc22cc", "#ff22dd", "#ee2299", "#1155bb"],
  1589: ["#5544aa", "#ee66bb", "#224488", "#bb7788", "#ee6600"],
  1590: ["#3377ee", "#88bb99", "#11aa22", "#ff00aa", "#ee2233"],
  1591: ["#1177ee", "#44ffbb", "#ee5500", "#cceedd", "#88dd33"],
  1592: ["#880011", "#008844", "#bbdddd", "#88aaee", "#66ffff"],
  1593: ["#9999cc", "#dddd55", "#bb7755", "#444477", "#00bbaa"],
  1594: ["#ffee00", "#88ff66", "#0088ee", "#00dd00", "#22ff88"],
  1595: ["#66bb88", "#884422", "#aabb00", "#2211dd", "#66aa55"],
  1596: ["#1100cc", "#228899", "#227766", "#9944dd", "#dd4499"],
  1597: ["#66cc44", "#ddff55", "#ff6655", "#669900", "#bb77cc"],
  1598: ["#ee5533", "#ffee33", "#1166cc", "#77ccee", "#557788"],
  1599: ["#3322ff", "#771177", "#006666", "#bbdd44", "#bb66ee"],
  1600: ["#ff2244", "#bbbbee", "#446677", "#770055", "#66aa44"],
  1601: ["#2200cc", "#998800", "#66ddaa", "#66aa88", "#992255"],
  1602: ["#330044", "#884477", "#bb1155", "#aa77cc", "#eedd11"],
  1603: ["#889911", "#885566", "#223300", "#ee44ff", "#11aaaa"],
  1604: ["#665555", "#ff5599", "#9922ee", "#aa2288", "#119922"],
  1605: ["#5544aa", "#ff8899", "#665522", "#bb0000", "#22dd99"],
  1606: ["#779999", "#779988", "#aa6688", "#00ddaa", "#112200"],
  1607: ["#cc1166", "#665555", "#114477", "#44ff66", "#44ff88"],
  1608: ["#00dd33", "#11cc66", "#ff7755", "#ee5544", "#11ccaa"],
  1609: ["#bb2233", "#bb8888", "#eeddaa", "#dd5511", "#ff6666"],
  1610: ["#00bb77", "#66ffbb", "#cc7766", "#4477dd", "#aa9988"],
  1611: ["#eedd22", "#663377", "#116622", "#cc2277", "#ccee00"],
  1612: ["#eeaa66", "#55dd99", "#449900", "#dd55bb", "#ee4422"],
  1613: ["#664400", "#ccccff", "#00cc33", "#dd3399", "#dd6600"],
  1614: ["#bb44aa", "#aa5577", "#cc9999", "#00eecc", "#7799bb"],
  1615: ["#ee4411", "#bb66bb", "#dd99ff", "#55ddee", "#aa1188"],
  1616: ["#ddddaa", "#5555aa", "#bbbb22", "#ee44dd", "#eeee55"],
  1617: ["#9955dd", "#88ee99", "#7788aa", "#3355cc", "#224488"],
  1618: ["#888866", "#77cc77", "#6699bb", "#ff9933", "#aa7755"],
  1619: ["#4422ff", "#1111ff", "#bb1166", "#ddff22", "#224400"],
  1620: ["#225599", "#88ff44", "#66dd66", "#cc88cc", "#eecc44"],
  1621: ["#3355dd", "#aacc77", "#bb0055", "#44aabb", "#ee4433"],
  1622: ["#22bbdd", "#0000ff", "#333333", "#aadd22", "#55aa55"],
  1623: ["#dd6644", "#cc3355", "#331100", "#ff1166", "#886666"],
  1624: ["#33bb77", "#9933aa", "#1144ee", "#00ff22", "#7700bb"],
  1625: ["#ee44ff", "#44ccaa", "#ffddaa", "#994411", "#7700dd"],
  1626: ["#00ff99", "#4455bb", "#7788ee", "#ddaabb", "#22aacc"],
  1627: ["#6633dd", "#00ffee", "#dd44bb", "#993355", "#228888"],
  1628: ["#eebb66", "#33aa88", "#88ffee", "#ff0033", "#663366"],
  1629: ["#cc22dd", "#22ee44", "#cc1155", "#002211", "#449966"],
  1630: ["#ff11bb", "#eeee99", "#77dd22", "#bb33ee", "#ee88dd"],
  1631: ["#77ee11", "#44ddff", "#ccaabb", "#1166aa", "#cc2233"],
  1632: ["#ddddaa", "#777755", "#ccbb00", "#77cc44", "#993333"],
  1633: ["#8855cc", "#119999", "#6600ff", "#7700cc", "#55cc77"],
  1634: ["#aacccc", "#cc4444", "#44eeaa", "#44cccc", "#99cc22"],
  1635: ["#7799ff", "#226699", "#bb1111", "#662288", "#550088"],
  1636: ["#cc8844", "#ddbbaa", "#66dd22", "#117755", "#bbbbbb"],
  1637: ["#1122ff", "#99cc22", "#115500", "#ccbb33", "#221111"],
  1638: ["#66cc11", "#aa8822", "#226666", "#ee33ee", "#ccee66"],
  1639: ["#ff8866", "#cc33cc", "#aacc55", "#1111bb", "#1100ff"],
  1640: ["#11aa99", "#33bbcc", "#eebb44", "#000088", "#ff3366"],
  1641: ["#bbbb99", "#ee4400", "#661199", "#ff66dd", "#333355"],
  1642: ["#aa5599", "#ccee11", "#6699bb", "#cc6666", "#ee33ee"],
  1643: ["#55ff11", "#22cc22", "#113311", "#113399", "#9944bb"],
  1644: ["#3300dd", "#118866", "#cc88dd", "#331100", "#449944"],
  1645: ["#662255", "#cc9988", "#aaeedd", "#ccddaa", "#223300"],
  1646: ["#ee00bb", "#9966cc", "#117711", "#66ddaa", "#773322"],
  1647: ["#332211", "#3322ff", "#663333", "#11dd55", "#0000dd"],
  1648: ["#ccee55", "#77cc44", "#0055cc", "#11aaff", "#ccff99"],
  1649: ["#ff2200", "#aaffff", "#224477", "#00eedd", "#ffccdd"],
  1650: ["#886600", "#ff5555", "#ddffdd", "#dd99cc", "#991100"],
  1651: ["#002211", "#55dd77", "#dd44aa", "#441166", "#7799dd"],
  1652: ["#ccff00", "#448899", "#55ff33", "#ff1100", "#33aa88"],
  1653: ["#44ddaa", "#cc55ee", "#113311", "#7777ff", "#33dd33"],
  1654: ["#ee9966", "#99bb11", "#ddee22", "#ff11ff", "#22aa22"],
  1655: ["#ee7777", "#77bbdd", "#77aacc", "#44aa11", "#aa9944"],
  1656: ["#7711cc", "#228866", "#2299aa", "#339900", "#22dd33"],
  1657: ["#449944", "#ffdd44", "#0044cc", "#dd8866", "#4400aa"],
  1658: ["#3300aa", "#ee55cc", "#3333bb", "#779999", "#88dd88"],
  1659: ["#dd8811", "#00cc11", "#44ee11", "#664433", "#33ddee"],
  1660: ["#0099bb", "#33aa88", "#cc1122", "#ff77cc", "#77ccbb"],
  1661: ["#ccee44", "#885588", "#aaddbb", "#6600ee", "#0099dd"],
  1662: ["#4444aa", "#33aa00", "#aadd00", "#ddff00", "#11bb55"],
  1663: ["#bb4477", "#6600ee", "#33cc00", "#774411", "#ff66bb"],
  1664: ["#553333", "#77cc00", "#99bbcc", "#9999dd", "#ff2266"],
  1665: ["#668888", "#44bb11", "#55dd33", "#aa6600", "#553322"],
  1666: ["#440066", "#bb2222", "#882299", "#dd77ff", "#33aaaa"],
  1667: ["#bbee22", "#dd55ee", "#774477", "#44cc22", "#88bbcc"],
  1668: ["#2299aa", "#553355", "#55ee44", "#aa5555", "#cceeaa"],
  1669: ["#ee8822", "#662211", "#22ee66", "#55bb66", "#99dd77"],
  1670: ["#aa00cc", "#44cc88", "#ee66ee", "#333322", "#ee00ff"],
  1671: ["#773388", "#ffcc22", "#552200", "#112244", "#22ee66"],
  1672: ["#4455ee", "#ccee11", "#ccff88", "#cc6600", "#8844dd"],
  1673: ["#11ff88", "#11ddff", "#33ffaa", "#22ee77", "#aaff66"],
  1674: ["#bbffcc", "#444477", "#ccffff", "#11cc44", "#222233"],
  1675: ["#001111", "#cc66cc", "#99ee00", "#11aabb", "#9966ff"],
  1676: ["#11dd44", "#774477", "#1144dd", "#ddaacc", "#cc11ff"],
  1677: ["#113388", "#00dd77", "#33aadd", "#111166", "#8877cc"],
  1678: ["#77ee88", "#cc8877", "#00aa00", "#cc1166", "#66ff44"],
  1679: ["#99dd11", "#cc6655", "#dd8888", "#992299", "#bb11cc"],
  1680: ["#eeee99", "#77eeaa", "#cc6666", "#8844ee", "#00cc55"],
  1681: ["#224466", "#77cc11", "#ff6655", "#0099ff", "#22dd88"],
  1682: ["#eeddbb", "#ee66ff", "#444422", "#4444dd", "#449999"],
  1683: ["#ddccdd", "#aabb99", "#0033dd", "#bb4433", "#ee1133"],
  1684: ["#ee0099", "#551144", "#005577", "#66aa88", "#bb4466"],
  1685: ["#bbbb88", "#aabbcc", "#bb2266", "#111155", "#aaaa44"],
  1686: ["#661166", "#22ccee", "#aacc11", "#22dddd", "#cc88aa"],
  1687: ["#aa6644", "#aa6633", "#eeddcc", "#33eebb", "#bb3344"],
  1688: ["#ccccff", "#664422", "#dd44bb", "#00dd55", "#aabb88"],
  1689: ["#33ee33", "#aaccdd", "#eecc11", "#cc9966", "#5555dd"],
  1690: ["#ff00aa", "#88bb88", "#55ff11", "#445588", "#003399"],
  1691: ["#cc5522", "#88bb66", "#8855ee", "#bbee33", "#cc88bb"],
  1692: ["#33bbbb", "#337777", "#337788", "#771199", "#008877"],
  1693: ["#ff44bb", "#bb1100", "#ccccdd", "#aa7744", "#99ee99"],
  1694: ["#008800", "#ee33bb", "#88aa22", "#cc3344", "#557733"],
  1695: ["#aa77dd", "#2222cc", "#00dd11", "#ffddee", "#227733"],
  1696: ["#11ddee", "#33ee88", "#bb77bb", "#cceeee", "#665511"],
  1697: ["#5544cc", "#6644bb", "#ddaa33", "#22cc22", "#ffdddd"],
  1698: ["#7722ee", "#88cc33", "#8866dd", "#ffaa22", "#559988"],
  1699: ["#aa0033", "#88cc00", "#44dd55", "#4499ff", "#00cc88"],
  1700: ["#ff7788", "#dd1155", "#8844aa", "#0066dd", "#bb6633"],
  1701: ["#4400dd", "#aa8866", "#00ccaa", "#eeeecc", "#22eeff"],
  1702: ["#dd1155", "#004455", "#dd3399", "#cc55ee", "#ee9999"],
  1703: ["#aa6611", "#1199ff", "#ffeeee", "#338844", "#779988"],
  1704: ["#bbeeaa", "#0022cc", "#6600aa", "#3344bb", "#22dd00"],
  1705: ["#eeddbb", "#996633", "#dd8877", "#33cc77", "#66eeee"],
  1706: ["#993388", "#335500", "#550088", "#22bbff", "#55aabb"],
  1707: ["#445555", "#3388dd", "#884477", "#ff55ff", "#77ee88"],
  1708: ["#8888bb", "#00ff22", "#771155", "#004477", "#997799"],
  1709: ["#cc4444", "#dd5533", "#ee8833", "#ffbbee", "#11eedd"],
  1710: ["#aabbff", "#dd77aa", "#ffaa77", "#773355", "#ddee77"],
  1711: ["#227777", "#22aa00", "#11aa99", "#ee2255", "#1144ee"],
  1712: ["#77cc44", "#aa99dd", "#9999ee", "#aaff88", "#889966"],
  1713: ["#887755", "#ccff11", "#8833cc", "#ee00ff", "#ee77ee"],
  1714: ["#aabb66", "#88ddcc", "#cccc66", "#33aaaa", "#dd9999"],
  1715: ["#33ddee", "#228833", "#44ee77", "#008811", "#eeaa00"],
  1716: ["#222200", "#007788", "#77dd44", "#eeeeaa", "#eeff55"],
  1717: ["#cc77ff", "#bbaa66", "#11cc11", "#ee9933", "#449999"],
  1718: ["#ff1188", "#cc11cc", "#8877cc", "#8844dd", "#ee8844"],
  1719: ["#446633", "#aa55aa", "#bb4433", "#666677", "#442255"],
  1720: ["#bb44ff", "#7799cc", "#cc22dd", "#6655dd", "#77cc88"],
  1721: ["#bbdd11", "#0011cc", "#33ffaa", "#33ddcc", "#bb5544"],
  1722: ["#55bb22", "#995533", "#ff0011", "#cc4466", "#bbcc44"],
  1723: ["#00cc22", "#223311", "#33ff66", "#cccc00", "#66dd77"],
  1724: ["#bbee99", "#115599", "#cc6600", "#aa7777", "#aabb11"],
  1725: ["#ccffee", "#44aa88", "#777733", "#aa44dd", "#bb77ee"],
  1726: ["#990022", "#ff4466", "#33dd44", "#773355", "#55bb22"],
  1727: ["#aa55cc", "#ee9966", "#cc11bb", "#4400ff", "#1188dd"],
  1728: ["#22dd66", "#2277cc", "#55aa88", "#ddcc33", "#3399bb"],
  1729: ["#bb6644", "#aa55cc", "#225599", "#00ee44", "#bb22ff"],
  1730: ["#772200", "#115544", "#66ee44", "#bb7722", "#445588"],
  1731: ["#88eedd", "#44ff33", "#00ee55", "#cc77dd", "#009999"],
  1732: ["#eebb33", "#ee5566", "#ee99dd", "#ffaa77", "#eeee55"],
  1733: ["#11cccc", "#66ff11", "#11ffcc", "#447788", "#ee2222"],
  1734: ["#dd4477", "#996633", "#7744dd", "#44ff99", "#99ccbb"],
  1735: ["#11ddaa", "#11dd88", "#dd55cc", "#88ffff", "#ffaaee"],
  1736: ["#991133", "#ee00cc", "#ddee66", "#7777dd", "#33aa99"],
  1737: ["#6644cc", "#aa7799", "#bb2266", "#1177aa", "#44ff55"],
  1738: ["#8800ee", "#336677", "#66eecc", "#66ff22", "#22cc55"],
  1739: ["#5500dd", "#669977", "#aa66bb", "#aa5544", "#dd22aa"],
  1740: ["#113355", "#5566bb", "#dd4400", "#003344", "#44ffbb"],
  1741: ["#113377", "#eeeeee", "#8811bb", "#550099", "#11cc11"],
  1742: ["#44ee99", "#228866", "#4499bb", "#dddd11", "#00aabb"],
  1743: ["#eeaacc", "#dd44bb", "#bb33ee", "#ccffbb", "#cc99bb"],
  1744: ["#aa4444", "#4433ff", "#bbee77", "#00bb88", "#9922ff"],
  1745: ["#4477ff", "#7755dd", "#002244", "#eeff55", "#551133"],
  1746: ["#eebbaa", "#dd5522", "#ff5577", "#66dd22", "#666699"],
  1747: ["#776633", "#dd2255", "#ffeeff", "#111155", "#ccff55"],
  1748: ["#222233", "#995577", "#11eedd", "#00aaee", "#7755aa"],
  1749: ["#009900", "#bb44ff", "#ccee33", "#bbddaa", "#00ffdd"],
  1750: ["#bb11dd", "#55bbcc", "#6611ee", "#5511dd", "#550077"],
  1751: ["#cc4488", "#9988aa", "#995566", "#2288cc", "#dd2277"],
  1752: ["#1188bb", "#888877", "#44ccbb", "#559900", "#550088"],
  1753: ["#dd0099", "#dd6600", "#ee2211", "#aaff33", "#776677"],
  1754: ["#117777", "#889900", "#110077", "#3311aa", "#dd88cc"],
  1755: ["#22ff55", "#6633aa", "#559900", "#336688", "#77aabb"],
  1756: ["#5577bb", "#bbeeee", "#66ee33", "#aa66ff", "#7744cc"],
  1757: ["#11cc22", "#33cc66", "#11bb77", "#33ee00", "#2299bb"],
  1758: ["#cc8888", "#5555ee", "#9988ee", "#ccaa99", "#99aacc"],
  1759: ["#bbccdd", "#441100", "#448888", "#777700", "#772299"],
  1760: ["#bbbb88", "#2200aa", "#3344dd", "#66ee44", "#cc66ff"],
  1761: ["#aa1199", "#00aa00", "#cc5511", "#888899", "#995544"],
  1762: ["#eebbbb", "#22aa99", "#44aa66", "#00ff88", "#aa44aa"],
  1763: ["#4455ee", "#55ddbb", "#ffaa66", "#ddbb22", "#ff4477"],
  1764: ["#44bb00", "#4488ff", "#558888", "#ff99bb", "#ddbbcc"],
  1765: ["#eeee77", "#ee9944", "#bb0077", "#553333", "#440044"],
  1766: ["#999977", "#66dd11", "#dd1199", "#4488bb", "#442244"],
  1767: ["#22dd11", "#cc7700", "#66ee88", "#ddffff", "#44ee66"],
  1768: ["#2222bb", "#2255cc", "#bb0099", "#dd55dd", "#55aa88"],
  1769: ["#116633", "#cc5577", "#aa2266", "#5566ee", "#ff11cc"],
  1770: ["#77aa66", "#eedd55", "#ee1177", "#ccaacc", "#66ccff"],
  1771: ["#99ccaa", "#5511ee", "#aadd22", "#aaaa44", "#44ccff"],
  1772: ["#dd8855", "#dd0022", "#55aa55", "#5588ee", "#77dd77"],
  1773: ["#444411", "#6644aa", "#440066", "#88dd66", "#ff88ff"],
  1774: ["#00ee66", "#55ee33", "#6644aa", "#3388ee", "#ff00ff"],
  1775: ["#994433", "#2233ff", "#bb9988", "#66bb88", "#bb8822"],
  1776: ["#cc99dd", "#aa66cc", "#aa11aa", "#009988", "#00aaee"],
  1777: ["#444433", "#002277", "#aa66cc", "#ffee44", "#00ee88"],
  1778: ["#ffdd66", "#dd9933", "#99ccaa", "#88bb33", "#aa7744"],
  1779: ["#dd2299", "#559944", "#882266", "#bbbb22", "#007744"],
  1780: ["#0000dd", "#99ccff", "#aa33ff", "#8888ee", "#44ff22"],
  1781: ["#88bbff", "#ff55dd", "#666666", "#77ffff", "#337700"],
  1782: ["#eebb44", "#993366", "#00cc99", "#aa44bb", "#ee88ff"],
  1783: ["#ddaa44", "#ddbb66", "#cc1188", "#aaddcc", "#33cc33"],
  1784: ["#556688", "#bb99dd", "#aa22dd", "#111111", "#44ee88"],
  1785: ["#1199ee", "#775511", "#334477", "#881122", "#224466"],
  1786: ["#118855", "#ccffaa", "#aaff99", "#332222", "#009922"],
  1787: ["#88bb99", "#aa33dd", "#4477cc", "#ee0000", "#66eeff"],
  1788: ["#885522", "#77ee88", "#224444", "#77dd11", "#cc77ff"],
  1789: ["#eedd55", "#9933ff", "#994466", "#ddccdd", "#77bb44"],
  1790: ["#ee9933", "#334444", "#999955", "#0033bb", "#6611ee"],
  1791: ["#ee22dd", "#11ee22", "#227744", "#112277", "#44dd88"],
  1792: ["#dd33ee", "#44ff88", "#bb8822", "#229900", "#886633"],
  1793: ["#2222dd", "#bb77ff", "#449900", "#3344ff", "#bbaa44"],
  1794: ["#000066", "#556611", "#aa3355", "#99bb99", "#aa5522"],
  1795: ["#999988", "#ff1133", "#bb66bb", "#55ee99", "#ee4400"],
  1796: ["#88ffaa", "#55ddbb", "#448833", "#dd1111", "#00cc33"],
  1797: ["#2266cc", "#449944", "#77aa55", "#77cc11", "#dd22ee"],
  1798: ["#990088", "#aa33aa", "#ee8833", "#33aa88", "#2266dd"],
  1799: ["#88dd88", "#dd0000", "#bb7711", "#aa4444", "#11aa88"],
  1800: ["#4466bb", "#ccaa66", "#114444", "#eeeeff", "#00ee11"],
  1801: ["#99ffcc", "#dd8855", "#33eeff", "#2222ee", "#110099"],
  1802: ["#ccff66", "#55ddaa", "#bb6666", "#bb3399", "#ffcc55"],
  1803: ["#99ee22", "#cc5555", "#ee8811", "#885555", "#ff9966"],
  1804: ["#66ddbb", "#55eecc", "#885511", "#2200ee", "#224444"],
  1805: ["#66ff55", "#ff1144", "#bbdd00", "#77ee22", "#339955"],
  1806: ["#ee5599", "#996699", "#8833cc", "#ddbb22", "#55bb66"],
  1807: ["#110000", "#00cc55", "#998800", "#ffbb55", "#66bbee"],
  1808: ["#1155aa", "#dd3399", "#00aadd", "#55dd88", "#009977"],
  1809: ["#449977", "#887722", "#77ddcc", "#cc4466", "#44eeee"],
  1810: ["#cc0066", "#339911", "#227788", "#5533ff", "#33aa11"],
  1811: ["#44aa33", "#0077ee", "#3300dd", "#aa9922", "#665555"],
  1812: ["#336677", "#aacccc", "#bbbbee", "#882288", "#bbcc66"],
  1813: ["#4499ee", "#55ee99", "#5511bb", "#ff99dd", "#448833"],
  1814: ["#99ccff", "#9955ee", "#11dd22", "#ffaa55", "#44bb55"],
  1815: ["#ddddee", "#ff8822", "#33dd44", "#77ee66", "#33ddee"],
  1816: ["#cc33ee", "#995511", "#8844ff", "#55bb44", "#443300"],
  1817: ["#ee77cc", "#66eeff", "#443388", "#bb9911", "#0044dd"],
  1818: ["#111166", "#aa7700", "#aa2200", "#ee1199", "#ee9955"],
  1819: ["#33ff55", "#6600bb", "#88ee66", "#002277", "#116644"],
  1820: ["#4477ee", "#888822", "#0000ee", "#bb77cc", "#2277cc"],
  1821: ["#5533bb", "#dd6600", "#ffaa22", "#44bbbb", "#ddddbb"],
  1822: ["#994466", "#557777", "#99aaee", "#bb44dd", "#ee22aa"],
  1823: ["#44dd55", "#55ffaa", "#eeeebb", "#6644ff", "#004477"],
  1824: ["#ff99bb", "#99bb55", "#9966cc", "#33aacc", "#778800"],
  1825: ["#44dd88", "#22ff33", "#77aa99", "#8800bb", "#006666"],
  1826: ["#442255", "#887777", "#88cc55", "#441188", "#8833cc"],
  1827: ["#ffddaa", "#bb11cc", "#ccdddd", "#662288", "#ccddcc"],
  1828: ["#44ffcc", "#bb7722", "#77bbee", "#9988cc", "#ff5566"],
  1829: ["#ccdd11", "#55ffee", "#4466cc", "#667711", "#33dd77"],
  1830: ["#eeddcc", "#1155bb", "#dd1199", "#111177", "#88ff99"],
  1831: ["#bb2255", "#224455", "#8899cc", "#aa5500", "#66dddd"],
  1832: ["#9999ee", "#22ff33", "#ddee88", "#88ffff", "#22bb11"],
  1833: ["#88ee66", "#dd77cc", "#ff33ff", "#99ee55", "#2244ee"],
  1834: ["#ffccbb", "#332200", "#22aa33", "#dd5566", "#11bbbb"],
  1835: ["#cc22cc", "#1199dd", "#aa33bb", "#5555bb", "#993344"],
  1836: ["#ddbb88", "#bb00dd", "#dd11cc", "#ff11aa", "#6666bb"],
  1837: ["#cc3311", "#aaddcc", "#001100", "#ee3355", "#66cc44"],
  1838: ["#883333", "#443399", "#ff0077", "#556688", "#dddd22"],
  1839: ["#88dd77", "#dd0022", "#33ffee", "#3344cc", "#cc7722"],
  1840: ["#99cc44", "#55ccaa", "#111122", "#cc33ff", "#ffff22"],
  1841: ["#554455", "#cc4444", "#009988", "#224477", "#66ff88"],
  1842: ["#4422ff", "#660033", "#bb66dd", "#770099", "#11ff77"],
  1843: ["#222288", "#004455", "#ddaabb", "#229977", "#222288"],
  1844: ["#ddddbb", "#776622", "#44aa44", "#99aa33", "#ff4400"],
  1845: ["#cceedd", "#447733", "#557766", "#eedd77", "#0022aa"],
  1846: ["#222244", "#aacc66", "#aa5533", "#88aa66", "#009988"],
  1847: ["#aaaa55", "#22ee11", "#eedd77", "#ee44aa", "#0066ee"],
  1848: ["#2244dd", "#ee22ee", "#dd2255", "#aa3399", "#77ee77"],
  1849: ["#aa0011", "#6666dd", "#bb9977", "#55aa33", "#1177ff"],
  1850: ["#0088dd", "#ccccaa", "#aa1133", "#33ccaa", "#557722"],
  1851: ["#99dd22", "#99bb22", "#aaeeff", "#dd00aa", "#885599"],
  1852: ["#ffdd33", "#8866ee", "#dd8899", "#eeffdd", "#bb3333"],
  1853: ["#ffcc22", "#116688", "#1199ff", "#55bb11", "#1111aa"],
  1854: ["#cc55dd", "#bb11ee", "#99ff66", "#440066", "#99aa00"],
  1855: ["#442211", "#993333", "#55ff00", "#99ccaa", "#66aa33"],
  1856: ["#dd3377", "#2277ff", "#558844", "#00bb55", "#224400"],
  1857: ["#22cc88", "#77ee22", "#bb0088", "#55cc77", "#00cc44"],
  1858: ["#4422bb", "#998866", "#ddffee", "#aaee55", "#779900"],
  1859: ["#aa5577", "#ffcc66", "#bb11ff", "#777733", "#00aa11"],
  1860: ["#44cc88", "#ee4433", "#881100", "#1177cc", "#2266ee"],
  1861: ["#44cc22", "#77bbbb", "#bb11ff", "#995588", "#772244"],
  1862: ["#ee5588", "#99aadd", "#551166", "#227711", "#ff8866"],
  1863: ["#44ccaa", "#448877", "#aa0044", "#66cc44", "#dd00aa"],
  1864: ["#cc9955", "#bb8844", "#880011", "#554499", "#aa22dd"],
  1865: ["#115511", "#445566", "#889922", "#dd22bb", "#ff5555"],
  1866: ["#eedd44", "#557755", "#007788", "#991177", "#5577cc"],
  1867: ["#7722ee", "#aa6611", "#118888", "#ddddaa", "#bbaaaa"],
  1868: ["#dd55cc", "#88bb44", "#dd77aa", "#66ccaa", "#223388"],
  1869: ["#118811", "#447766", "#1100ff", "#bbdd33", "#00ffaa"],
  1870: ["#ff5577", "#99cc88", "#554455", "#55aa44", "#dd88cc"],
  1871: ["#88ee88", "#3300dd", "#ccee88", "#883322", "#ddbb66"],
  1872: ["#eeee11", "#882233", "#995533", "#ff55ee", "#dd4477"],
  1873: ["#44ccaa", "#00bb22", "#5588bb", "#5555ee", "#772288"],
  1874: ["#dd3333", "#550099", "#aa22ee", "#ee0000", "#eeaadd"],
  1875: ["#bb7722", "#ee8888", "#44eeff", "#77cc66", "#8866bb"],
  1876: ["#cccccc", "#99ccbb", "#ffaa99", "#99ee99", "#ddbbee"],
  1877: ["#aa0000", "#bb9988", "#112200", "#332255", "#119900"],
  1878: ["#221133", "#660044", "#9999ee", "#66dddd", "#55ff44"],
  1879: ["#dd99ff", "#006677", "#221166", "#339911", "#3300ff"],
  1880: ["#dd00aa", "#dd3300", "#eeffcc", "#dd8877", "#779999"],
  1881: ["#668811", "#5511ff", "#eedd11", "#bb5599", "#3366ee"],
  1882: ["#11cc88", "#ffaa88", "#bb55ff", "#88eeaa", "#775533"],
  1883: ["#dd88ee", "#ffaa22", "#eebb99", "#ee77ee", "#00dd88"],
  1884: ["#33bb11", "#0055ff", "#779999", "#bb1144", "#5522ee"],
  1885: ["#cceeff", "#004422", "#11aa33", "#ccccee", "#cc77aa"],
  1886: ["#55ddee", "#dd8844", "#ccaa88", "#0099dd", "#005522"],
  1887: ["#aa66ee", "#ddaa77", "#55cc44", "#aa33aa", "#eeaa55"],
  1888: ["#cc4466", "#00ee44", "#667722", "#ffbbdd", "#aaaa00"],
  1889: ["#ff6600", "#aaeedd", "#ffbb55", "#444499", "#44ddee"],
  1890: ["#ffeebb", "#4499cc", "#004499", "#ffcc00", "#664488"],
  1891: ["#669944", "#88ffbb", "#442222", "#9966ee", "#7722ff"],
  1892: ["#771122", "#66aa77", "#ccbbbb", "#ee33cc", "#334411"],
  1893: ["#446688", "#ffddbb", "#442299", "#eeaa88", "#006655"],
  1894: ["#004444", "#33eeff", "#bb8866", "#556688", "#00bbcc"],
  1895: ["#ff1199", "#ccccaa", "#7744cc", "#113377", "#11bbee"],
  1896: ["#cc7777", "#88ddee", "#4466bb", "#6611cc", "#335599"],
  1897: ["#88eebb", "#883311", "#bbff44", "#555500", "#ee7777"],
  1898: ["#007733", "#ddffee", "#bbaa77", "#22ccaa", "#cc5522"],
  1899: ["#eeff22", "#55aadd", "#88bb11", "#ee00dd", "#66ff11"],
  1900: ["#8855aa", "#bb77dd", "#5555bb", "#88aa22", "#ff7722"],
  1901: ["#119933", "#dd00dd", "#7777aa", "#7799ff", "#775511"],
  1902: ["#ffaa55", "#55eecc", "#441122", "#0000bb", "#99eecc"],
  1903: ["#55cc77", "#bb5511", "#66bb99", "#ccbbff", "#ff1144"],
  1904: ["#11cccc", "#448844", "#116633", "#003322", "#33bbcc"],
  1905: ["#cc2244", "#2288aa", "#226677", "#554411", "#448888"],
  1906: ["#ff9977", "#2288ee", "#22bbff", "#aaff22", "#6633aa"],
  1907: ["#55aa77", "#99cc99", "#ccddff", "#333344", "#554466"],
  1908: ["#22bbaa", "#cc7755", "#4400ee", "#5500bb", "#113333"],
  1909: ["#bb2266", "#11ff77", "#ee1100", "#ff1111", "#2211aa"],
  1910: ["#77cc66", "#ee88bb", "#aaff00", "#117722", "#777777"],
  1911: ["#0000ff", "#aa44ff", "#eecc88", "#6644aa", "#bb8800"],
  1912: ["#2277bb", "#ddffcc", "#88cc77", "#7788bb", "#bb3388"],
  1913: ["#223355", "#aa8800", "#664477", "#bb8800", "#778888"],
  1914: ["#3355dd", "#cceecc", "#3344ff", "#994466", "#ee11aa"],
  1915: ["#669966", "#88ee88", "#1188ff", "#88eeee", "#eeff77"],
  1916: ["#77ddee", "#99aabb", "#99ffbb", "#55ee99", "#66ffff"],
  1917: ["#11aaee", "#cc4488", "#33ff11", "#aaccff", "#eeee00"],
  1918: ["#33aa44", "#993322", "#aa55bb", "#eedd22", "#559999"],
  1919: ["#003355", "#443311", "#110022", "#44ee22", "#9900ff"],
  1920: ["#ff77dd", "#0011aa", "#8855ee", "#44bbee", "#776633"],
  1921: ["#bb9977", "#0000ee", "#118800", "#666633", "#115555"],
  1922: ["#dd6655", "#2299aa", "#880066", "#448833", "#ff5599"],
  1923: ["#771133", "#77aa55", "#1166bb", "#dd88dd", "#220066"],
  1924: ["#bb8899", "#55bb44", "#992222", "#11dd66", "#33ee11"],
  1925: ["#cc7755", "#88bb11", "#88ccee", "#cc8866", "#001155"],
  1926: ["#cc2200", "#aadd55", "#4444dd", "#777755", "#8899ff"],
  1927: ["#55ff00", "#ee9966", "#33ee11", "#88ee44", "#883333"],
  1928: ["#9977bb", "#99ffbb", "#ee7744", "#991188", "#9977cc"],
  1929: ["#444400", "#3322bb", "#22ddbb", "#77dd66", "#ee3399"],
  1930: ["#559933", "#991188", "#7799cc", "#990055", "#337733"],
  1931: ["#ddffee", "#cc77cc", "#55ee33", "#bb0011", "#22cc22"],
  1932: ["#1166dd", "#335555", "#55aa22", "#6633aa", "#8844aa"],
  1933: ["#117755", "#3344aa", "#dd1133", "#999988", "#ff22ff"],
  1934: ["#118800", "#5511ff", "#66dd00", "#1111bb", "#11dd11"],
  1935: ["#ddaa33", "#eeaa44", "#22bb99", "#22dd22", "#000044"],
  1936: ["#bb4466", "#6699dd", "#ee11dd", "#5533dd", "#66ffdd"],
  1937: ["#112299", "#6666bb", "#88ee55", "#11eeaa", "#221100"],
  1938: ["#336677", "#ff0066", "#665522", "#dddd22", "#dd0099"],
  1939: ["#55ddff", "#ddaa88", "#6666aa", "#11ff99", "#550088"],
  1940: ["#00cc66", "#dd3311", "#550099", "#ee88bb", "#ff3311"],
  1941: ["#6611ee", "#88eeff", "#885566", "#995500", "#44ff44"],
  1942: ["#aa77aa", "#22dd66", "#559933", "#33dd99", "#77ccaa"],
  1943: ["#aaaa55", "#8888bb", "#bb44ee", "#22aadd", "#55ddcc"],
  1944: ["#44ee99", "#881144", "#cc44cc", "#eeaabb", "#448888"],
  1945: ["#ff8855", "#335533", "#77ee00", "#99cc44", "#773300"],
  1946: ["#6644aa", "#00ff22", "#33bbff", "#7788bb", "#55bbff"],
  1947: ["#aa3366", "#cc55ff", "#eeeebb", "#551199", "#dd00ee"],
  1948: ["#447711", "#aacc77", "#00ff22", "#ddee66", "#223366"],
  1949: ["#770011", "#11cc22", "#66dd66", "#22dd33", "#eeff33"],
  1950: ["#ee3388", "#cc00aa", "#66ff77", "#66bb44", "#001100"],
  1951: ["#554411", "#552211", "#44aa99", "#1122bb", "#aa5577"],
  1952: ["#0088aa", "#2244ee", "#aa1111", "#55ff22", "#008855"],
  1953: ["#3344ff", "#6666dd", "#6622dd", "#99ddcc", "#cc1188"],
  1954: ["#117766", "#774400", "#7788ee", "#aa00ee", "#77bb11"],
  1955: ["#770099", "#9900aa", "#4400dd", "#dd2266", "#228899"],
  1956: ["#aa2200", "#77bbaa", "#ff3300", "#aa7755", "#552266"],
  1957: ["#bb33cc", "#aa00aa", "#ee44ff", "#bb4400", "#668800"],
  1958: ["#006633", "#dd55dd", "#11bb88", "#8822cc", "#55cc00"],
  1959: ["#99eeee", "#44ffdd", "#111166", "#225566", "#dd7733"],
  1960: ["#cc5533", "#ccbb44", "#996666", "#22aaaa", "#6655aa"],
  1961: ["#88cc11", "#993311", "#88ccee", "#557733", "#99ff00"],
  1962: ["#aaffbb", "#eeff00", "#ffddee", "#77aa66", "#99aa88"],
  1963: ["#227733", "#cc77dd", "#339977", "#00ddbb", "#cc7755"],
  1964: ["#dd5533", "#77ff88", "#220044", "#114488", "#5533cc"],
  1965: ["#7744bb", "#aa3355", "#5588bb", "#11ee88", "#ff9955"],
  1966: ["#33aa66", "#66bb22", "#ddaa22", "#1177dd", "#99ff99"],
  1967: ["#0088bb", "#aa22ee", "#bb2211", "#11ddcc", "#225566"],
  1968: ["#2255ee", "#668855", "#dd0000", "#449944", "#111166"],
  1969: ["#cc00ee", "#aa7711", "#995566", "#11bb55", "#009988"],
  1970: ["#116644", "#443300", "#22ee44", "#883399", "#336644"],
  1971: ["#22ffff", "#dddd22", "#001188", "#5577dd", "#00eeff"],
  1972: ["#ddeeee", "#11ccdd", "#ee4488", "#ff88cc", "#bb3322"],
  1973: ["#55ddee", "#cc7777", "#bbaa66", "#aa2222", "#111144"],
  1974: ["#ddffaa", "#ccbbaa", "#88aa11", "#006655", "#cccc33"],
  1975: ["#bbaa44", "#11cc55", "#669955", "#4400bb", "#dd6600"],
  1976: ["#77ffdd", "#ddaa99", "#bb6666", "#ffbb77", "#773377"],
  1977: ["#33cc44", "#77ccbb", "#331122", "#002288", "#77dd11"],
  1978: ["#ccbb22", "#eeee00", "#44ddff", "#aa1199", "#6644ee"],
  1979: ["#002255", "#ee7733", "#552277", "#77bbcc", "#1166ff"],
  1980: ["#3322ff", "#ee55ee", "#3300aa", "#aa1111", "#ee7755"],
  1981: ["#3300aa", "#228822", "#bb3322", "#223355", "#6633bb"],
  1982: ["#6622aa", "#77aa77", "#887733", "#dd0044", "#4422ff"],
  1983: ["#00aa33", "#8855dd", "#66bb55", "#ff2222", "#dd1133"],
  1984: ["#aa5500", "#111133", "#ccaa55", "#ddccaa", "#eeff77"],
  1985: ["#3300aa", "#dd9966", "#33ddbb", "#887788", "#33aaaa"],
  1986: ["#7733ff", "#6622bb", "#bb2266", "#cc3333", "#55aaaa"],
  1987: ["#3333ff", "#dd4433", "#ffdd33", "#4422aa", "#1122ff"],
  1988: ["#88dd88", "#dd1111", "#33dd00", "#bb9944", "#8822ee"],
  1989: ["#778866", "#cc88aa", "#dd3322", "#776655", "#33ccdd"],
  1990: ["#cc99dd", "#bb00dd", "#88cc11", "#dd3333", "#665555"],
  1991: ["#44ffbb", "#ffff33", "#5566ff", "#88ffbb", "#bb5599"],
  1992: ["#eecc11", "#ff33bb", "#ff1177", "#7755aa", "#775555"],
  1993: ["#6600cc", "#88dd77", "#ccccbb", "#cc44cc", "#aa3300"],
  1994: ["#118811", "#11ee00", "#221188", "#99cc44", "#aaaa44"],
  1995: ["#ddaaaa", "#11ee77", "#11aabb", "#99aaaa", "#ee9911"],
  1996: ["#ee8888", "#007799", "#1166ff", "#445511", "#ff33bb"],
  1997: ["#99dddd", "#7711dd", "#cc22dd", "#5599ee", "#bbbbbb"],
  1998: ["#ffcc88", "#77aabb", "#6633aa", "#ee22ff", "#ee9988"],
  1999: ["#330011", "#556622", "#aa3388", "#550066", "#7799ee"],
  2000: ["#220099", "#cc4422", "#336611", "#228877", "#99ccaa"],
  2001: ["#554400", "#ee22bb", "#8811aa", "#66dd55", "#dd6644"],
  2002: ["#220055", "#cc9955", "#448866", "#bb66ff", "#aa0000"],
  2003: ["#552288", "#007722", "#bb2233", "#cc22cc", "#22ff33"],
  2004: ["#cc7777", "#2200cc", "#440077", "#55eeaa", "#ffffbb"],
  2005: ["#553311", "#ddcc22", "#ee7722", "#ddcc22", "#aaeeff"],
  2006: ["#33dd66", "#eeaa22", "#1177ff", "#ffff99", "#ffddff"],
  2007: ["#9900cc", "#115588", "#00cc77", "#11ee77", "#ff2299"],
  2008: ["#0055ff", "#445511", "#66ee99", "#eeff55", "#00ccff"],
  2009: ["#779900", "#cc2266", "#991199", "#448822", "#4488bb"],
  2010: ["#aaffdd", "#4444ee", "#ccbb66", "#aaaadd", "#88cc55"],
  2011: ["#664400", "#99bbcc", "#0077bb", "#5533aa", "#dd55ee"],
  2012: ["#994499", "#9999ee", "#338899", "#8844bb", "#333322"],
  2013: ["#22ff88", "#bb77aa", "#445599", "#cc1155", "#aaee66"],
  2014: ["#3388bb", "#aaaa99", "#22ffbb", "#33cccc", "#995533"],
  2015: ["#330011", "#6677bb", "#227711", "#eeff77", "#6633dd"],
  2016: ["#7799aa", "#997711", "#bb0055", "#116688", "#dd3311"],
  2017: ["#dd33bb", "#0044ff", "#114477", "#666611", "#00dd11"],
  2018: ["#33dd99", "#112233", "#334433", "#22ff33", "#996666"],
  2019: ["#113333", "#eeddff", "#99cc99", "#332299", "#cc88ee"],
  2020: ["#ee8811", "#bbaadd", "#0044ff", "#995533", "#dd5522"],
  2021: ["#449977", "#993333", "#44ee66", "#22aadd", "#55bb11"],
  2022: ["#dd6633", "#774444", "#881177", "#ddff99", "#ee0066"],
  2023: ["#33aa44", "#ff2200", "#dd99aa", "#5522ee", "#779999"],
  2024: ["#bb66aa", "#0044ee", "#33aaff", "#00aa99", "#778866"],
  2025: ["#3300cc", "#dd5544", "#88ee44", "#44cc77", "#ffaadd"],
  2026: ["#997755", "#3355cc", "#ff0000", "#cc8888", "#dddd88"],
  2027: ["#111133", "#ccaa11", "#00aaee", "#aacc00", "#229922"],
  2028: ["#cc8833", "#55ff11", "#dd2200", "#ff0011", "#bb33cc"],
  2029: ["#339988", "#eeffbb", "#7799aa", "#ee0033", "#aaaadd"],
  2030: ["#00aaff", "#ee4411", "#6666aa", "#557722", "#773355"],
  2031: ["#ccddbb", "#336622", "#22dd99", "#88cccc", "#554433"],
  2032: ["#1133aa", "#7700bb", "#555511", "#cc8833", "#446633"],
  2033: ["#550099", "#aa7711", "#ee1199", "#ff11ee", "#77bb44"],
  2034: ["#118822", "#aacc00", "#dd9966", "#2222ee", "#44aaff"],
  2035: ["#ee5599", "#cc2288", "#004455", "#6666aa", "#446677"],
  2036: ["#99dd99", "#003311", "#116688", "#880088", "#4466ee"],
  2037: ["#88aa11", "#77dd77", "#559944", "#6699aa", "#aaccdd"],
  2038: ["#ffcc33", "#aa5511", "#bb0088", "#223322", "#66eeaa"],
  2039: ["#cccc77", "#aaddcc", "#bb88ff", "#bb77ff", "#99aa77"],
  2040: ["#2211aa", "#66aa88", "#55dd11", "#22bb00", "#558888"],
  2041: ["#116633", "#9977dd", "#22aa22", "#99bb33", "#ffaadd"],
  2042: ["#11bb11", "#33aa55", "#773399", "#88bb11", "#55bb99"],
  2043: ["#ff1133", "#4400cc", "#228822", "#993377", "#ffee88"],
  2044: ["#662255", "#aa44aa", "#66ee88", "#447744", "#996611"],
  2045: ["#aaff88", "#77aadd", "#eeddff", "#ffff44", "#557799"],
  2046: ["#1144ee", "#2266cc", "#aa9933", "#7777aa", "#aa1155"],
  2047: ["#ffee11", "#ddff66", "#ccaa22", "#770011", "#ff8844"],
  2048: ["#9944aa", "#77ff99", "#4422aa", "#77dd44", "#5500cc"],
  2049: ["#994444", "#55ffff", "#441133", "#ffbb22", "#8844bb"],
  2050: ["#dd2255", "#772277", "#aa3333", "#99ee66", "#556655"],
  2051: ["#333355", "#dd7744", "#11bbdd", "#991122", "#1155bb"],
  2052: ["#aacc33", "#33aa55", "#99bbcc", "#666666", "#223399"],
  2053: ["#cccc00", "#4433ff", "#7744cc", "#007777", "#339999"],
  2054: ["#0077ff", "#1177bb", "#7711cc", "#66ffaa", "#ee88cc"],
  2055: ["#ff6633", "#aa3322", "#cc33ff", "#11aaaa", "#55dddd"],
  2056: ["#ee88ff", "#11ff00", "#11ccff", "#887744", "#663322"],
  2057: ["#ddeebb", "#44dd33", "#ddffff", "#22dd99", "#ff8822"],
  2058: ["#ff4499", "#aabb44", "#669933", "#aa0077", "#4477ee"],
  2059: ["#1199ff", "#ccff00", "#11dd88", "#11dd99", "#998866"],
  2060: ["#00bb88", "#66aaff", "#441177", "#5500ee", "#bbddbb"],
  2061: ["#0011cc", "#88dd44", "#cc77cc", "#774499", "#ff6688"],
  2062: ["#bb2222", "#6611ff", "#009933", "#dd55ff", "#990066"],
  2063: ["#992255", "#2222bb", "#9955ee", "#7711ff", "#000033"],
  2064: ["#ccccdd", "#aacc88", "#11ffcc", "#7777bb", "#ffaa44"],
  2065: ["#883322", "#4477ff", "#dd8888", "#223399", "#aa99aa"],
  2066: ["#77bbcc", "#77ff33", "#220000", "#ee6633", "#0022ff"],
  2067: ["#993377", "#33aacc", "#ee55bb", "#ff0055", "#ee5522"],
  2068: ["#aa66cc", "#dd8822", "#3366ff", "#55cc55", "#bbdd22"],
  2069: ["#771188", "#22cc88", "#00cccc", "#8811ff", "#66ee22"],
  2070: ["#9944dd", "#8811cc", "#eeee44", "#7733dd", "#119999"],
  2071: ["#ff5599", "#ddaadd", "#55bbff", "#9955aa", "#cc33ee"],
  2072: ["#1188bb", "#66cc33", "#11ff00", "#77ddaa", "#66aa44"],
  2073: ["#116666", "#776666", "#cc3322", "#88cc99", "#88ee22"],
  2074: ["#dd77cc", "#7711ff", "#ee55aa", "#cc11ff", "#1155bb"],
  2075: ["#dd66ee", "#ffcc33", "#2211bb", "#44aa55", "#66aabb"],
  2076: ["#77dd77", "#ff88cc", "#eecc00", "#22aa33", "#ff55cc"],
  2077: ["#bb3311", "#2211cc", "#11eecc", "#44bbbb", "#3344bb"],
  2078: ["#556633", "#22cc77", "#44cc33", "#aaddee", "#ee5522"],
  2079: ["#99bbee", "#7766dd", "#99cc77", "#9922bb", "#00aa77"],
  2080: ["#ffeeff", "#005555", "#444444", "#33ff00", "#dd77cc"],
  2081: ["#115533", "#77ee66", "#111188", "#4455ee", "#ffbb66"],
  2082: ["#3300dd", "#22bb55", "#aa3377", "#33cc55", "#1188ff"],
  2083: ["#aa2211", "#0099bb", "#660033", "#ff4411", "#ee0099"],
  2084: ["#88ee99", "#ccbb88", "#8811ff", "#44ccbb", "#cc9999"],
  2085: ["#004433", "#551133", "#aaff22", "#aa77cc", "#222222"],
  2086: ["#11aa22", "#88cc33", "#dddd77", "#66bb11", "#eecc00"],
  2087: ["#220044", "#776633", "#886611", "#443300", "#ccdddd"],
  2088: ["#990000", "#33ddcc", "#66ddee", "#ddbbff", "#22ff55"],
  2089: ["#11ff99", "#66cc55", "#99ccee", "#cc4400", "#556600"],
  2090: ["#9999ee", "#887744", "#cceebb", "#cc22bb", "#dd9966"],
  2091: ["#443344", "#22ee55", "#668877", "#44ddaa", "#992200"],
  2092: ["#ee88aa", "#ff6699", "#66bbbb", "#bbcc22", "#666622"],
  2093: ["#88dd11", "#00bb44", "#ff22cc", "#bb2200", "#ddffbb"],
  2094: ["#66dd11", "#551199", "#9922dd", "#dd3399", "#223366"],
  2095: ["#ff6655", "#776666", "#dd9933", "#cc88cc", "#cceeee"],
  2096: ["#226666", "#55aa11", "#aa9922", "#001100", "#dddd77"],
  2097: ["#9922ee", "#00ee77", "#aa0000", "#bbbb33", "#115522"],
  2098: ["#221133", "#553333", "#3377ff", "#ccbbee", "#22bbee"],
  2099: ["#9922dd", "#88aa11", "#66ffee", "#88bb11", "#334488"],
  2100: ["#88ccdd", "#227799", "#ff4477", "#dd5522", "#99eeff"],
  2101: ["#00eedd", "#6699dd", "#8822cc", "#77aa33", "#554411"],
  2102: ["#9922aa", "#33eecc", "#7766ee", "#00ff55", "#ff9911"],
  2103: ["#22aaaa", "#884422", "#aa44ee", "#cc22cc", "#00ee77"],
  2104: ["#114455", "#ff11bb", "#aaaa66", "#007799", "#2255dd"],
  2105: ["#88cc33", "#aa9999", "#bb4488", "#002222", "#22bb33"],
  2106: ["#88dd44", "#dd9900", "#99dd55", "#33dd88", "#228800"],
  2107: ["#4444dd", "#bb66ee", "#aa5500", "#bb2299", "#9999ee"],
  2108: ["#448888", "#aa77ff", "#6622bb", "#dd9977", "#ee7788"],
  2109: ["#881155", "#aadd77", "#ee33cc", "#bb9933", "#ee22ff"],
  2110: ["#cc5588", "#114400", "#66eeee", "#55ee99", "#aa11ff"],
  2111: ["#2200ee", "#555599", "#cc66aa", "#ff99cc", "#ff2200"],
  2112: ["#445555", "#77ddff", "#0022ff", "#227788", "#11ffff"],
  2113: ["#55cc55", "#5566ee", "#885588", "#991144", "#3377ff"],
  2114: ["#aa6688", "#226688", "#00ccaa", "#996688", "#667700"],
  2115: ["#aa7744", "#668877", "#550066", "#cc00aa", "#eebb77"],
  2116: ["#bb66cc", "#111133", "#dd99dd", "#99ee55", "#66ee44"],
  2117: ["#ddcc00", "#33dd22", "#dd1166", "#558888", "#7799ff"],
  2118: ["#99ddff", "#447700", "#7744cc", "#00ff66", "#665544"],
  2119: ["#ff88aa", "#dd6666", "#ff2211", "#bb8888", "#aaffbb"],
  2120: ["#6688cc", "#bb33aa", "#dd4499", "#553366", "#ccffdd"],
  2121: ["#aa6622", "#0099dd", "#99cc22", "#3344aa", "#77aa33"],
  2122: ["#009988", "#cc9999", "#cc1100", "#ff3322", "#ee6699"],
  2123: ["#8822bb", "#55aa55", "#aa7799", "#22bbcc", "#223388"],
  2124: ["#cc55cc", "#eebb66", "#118899", "#aa4455", "#553311"],
  2125: ["#ff4422", "#ffaaaa", "#66aaaa", "#dd9922", "#553344"],
  2126: ["#ffcc55", "#99bb55", "#dd4411", "#559922", "#886655"],
  2127: ["#bb7777", "#66ffaa", "#ddaa88", "#3388ee", "#2288bb"],
  2128: ["#aaff55", "#881166", "#22dd22", "#22ffee", "#00ffcc"],
  2129: ["#778866", "#44ff00", "#88ff99", "#dd7722", "#bbbb44"],
  2130: ["#aabb00", "#ff3322", "#bbddee", "#332222", "#2244bb"],
  2131: ["#bb2211", "#ddaaaa", "#aaff99", "#221122", "#ccddff"],
  2132: ["#662211", "#cc55aa", "#aa55cc", "#cc77ee", "#553366"],
  2133: ["#ff1199", "#446666", "#ff8844", "#99ddee", "#6611dd"],
  2134: ["#8811ee", "#5522cc", "#9922dd", "#aa1133", "#88dd44"],
  2135: ["#bbee00", "#dd4422", "#ee8877", "#55bbcc", "#ddcc44"],
  2136: ["#003322", "#55ee33", "#11ffaa", "#338833", "#00ccaa"],
  2137: ["#33aabb", "#ff44ff", "#117799", "#bb1166", "#5577bb"],
  2138: ["#88ee44", "#449999", "#bb88dd", "#77aacc", "#003366"],
  2139: ["#660000", "#119977", "#33cc22", "#ff1166", "#ee1166"],
  2140: ["#446699", "#77eeaa", "#cccc77", "#9944bb", "#55aaff"],
  2141: ["#ff6677", "#22ee33", "#ddcc99", "#dd77aa", "#5500ff"],
  2142: ["#44ccff", "#33ff77", "#00ff55", "#9988aa", "#995566"],
  2143: ["#55ffaa", "#111166", "#1199dd", "#00dd77", "#5544dd"],
  2144: ["#33dd33", "#ff9933", "#99dd55", "#ddbb44", "#771144"],
  2145: ["#33bb88", "#aaee44", "#ee11aa", "#eeffff", "#664455"],
  2146: ["#99dd33", "#9988bb", "#22aa22", "#114433", "#008844"],
  2147: ["#aa55ff", "#00cc99", "#cc00dd", "#ddbbdd", "#336644"],
  2148: ["#7711cc", "#dd1144", "#88bb00", "#eeff77", "#ff22ee"],
  2149: ["#1122bb", "#ccaaff", "#44eebb", "#66ddee", "#8899aa"],
  2150: ["#004411", "#77cc33", "#22ffdd", "#334422", "#bbdd22"],
  2151: ["#11ee88", "#aa0011", "#aaaaee", "#770022", "#22dd11"],
  2152: ["#bbaa44", "#ccff88", "#663311", "#997777", "#999955"],
  2153: ["#2222ee", "#559999", "#22ddff", "#4455aa", "#eeee00"],
  2154: ["#ff1199", "#887733", "#992288", "#ee0022", "#bb9900"],
  2155: ["#3322bb", "#bb33aa", "#bb88aa", "#66dd55", "#44dd77"],
  2156: ["#7788cc", "#337700", "#1111ee", "#2266dd", "#11ff55"],
  2157: ["#aa3399", "#0077ee", "#6644ff", "#eeff88", "#77aaaa"],
  2158: ["#3399dd", "#aaee77", "#886633", "#55ff44", "#ccddaa"],
  2159: ["#009966", "#ee11cc", "#994400", "#9933cc", "#774455"],
  2160: ["#ff5500", "#88ddbb", "#ff5588", "#88aa33", "#5577ff"],
  2161: ["#442299", "#00ee77", "#dd1144", "#5544dd", "#bb77aa"],
  2162: ["#445555", "#778844", "#111166", "#991166", "#113355"],
  2163: ["#ff55aa", "#ddaabb", "#eeaaaa", "#22ffdd", "#eedddd"],
  2164: ["#889977", "#ffff11", "#4411dd", "#ccdd77", "#44bbff"],
  2165: ["#dd66cc", "#55ff77", "#445577", "#88ee00", "#2222dd"],
  2166: ["#7744cc", "#bbffbb", "#66eedd", "#bb99aa", "#ddbbee"],
  2167: ["#774455", "#110088", "#88ee22", "#66aaaa", "#dd1177"],
  2168: ["#cc7777", "#112211", "#22dd88", "#552288", "#552277"],
  2169: ["#eedd88", "#665544", "#ccbb88", "#1144bb", "#9933ee"],
  2170: ["#2233aa", "#778833", "#118866", "#11ff33", "#55ddff"],
  2171: ["#ff99cc", "#aaffee", "#228899", "#33ddaa", "#992200"],
  2172: ["#ee00aa", "#aabbff", "#9900dd", "#661133", "#4455bb"],
  2173: ["#00ccee", "#dd9988", "#22ccff", "#55eeaa", "#aaff22"],
  2174: ["#ee99ff", "#99cc44", "#aadd99", "#bbff88", "#bb0011"],
  2175: ["#223388", "#33dd33", "#116666", "#33dd77", "#aa33ee"],
  2176: ["#115566", "#9966ee", "#22cc55", "#99ff88", "#228866"],
  2177: ["#9922ff", "#88cc00", "#ee1177", "#bb9955", "#776622"],
  2178: ["#228888", "#aa4455", "#881111", "#6677bb", "#cc8800"],
  2179: ["#33dd00", "#ddffee", "#33aaaa", "#cc6699", "#88eeee"],
  2180: ["#55ee11", "#1111bb", "#eeddbb", "#661100", "#33ee44"],
  2181: ["#bb55aa", "#eeee11", "#553366", "#00ee55", "#668877"],
  2182: ["#88cc88", "#4499bb", "#886655", "#5566ff", "#881133"],
  2183: ["#aa55cc", "#992277", "#1155dd", "#aacc55", "#332288"],
  2184: ["#22ee11", "#cc4488", "#22ddbb", "#4444bb", "#440099"],
  2185: ["#66aaee", "#005566", "#ee33ff", "#bbff88", "#4455bb"],
  2186: ["#bb1177", "#99ee88", "#bb8833", "#11ddcc", "#224466"],
  2187: ["#dd3388", "#0011ff", "#ee44aa", "#554400", "#eedd55"],
  2188: ["#7788dd", "#cc9977", "#333388", "#bb7766", "#00dd66"],
  2189: ["#55aaee", "#880011", "#001111", "#22aacc", "#2200bb"],
  2190: ["#8811dd", "#dd44ff", "#99ff22", "#44cc55", "#77ddcc"],
  2191: ["#330088", "#22aa88", "#33cc22", "#88aa88", "#662288"],
  2192: ["#ff55ee", "#883333", "#5500cc", "#77dd22", "#006699"],
  2193: ["#dd4488", "#6699dd", "#ee2299", "#555599", "#22aadd"],
  2194: ["#ff9966", "#77eeaa", "#668822", "#ffeeff", "#ddbb77"],
  2195: ["#882288", "#aa3377", "#dddd00", "#77ee66", "#889955"],
  2196: ["#002266", "#ee9911", "#001133", "#88cc33", "#cceeff"],
  2197: ["#bb33aa", "#bbddff", "#551166", "#9977aa", "#aa55ee"],
  2198: ["#ff55ff", "#bbdd88", "#eecc33", "#22bb77", "#33cc11"],
  2199: ["#5544aa", "#bb3322", "#77bb88", "#aa4411", "#447711"],
  2200: ["#220011", "#660055", "#eeaa44", "#0077ee", "#ffaa77"],
  2201: ["#aaccee", "#881188", "#008833", "#0066dd", "#ddff33"],
  2202: ["#66bbcc", "#dddd66", "#44ff33", "#6611ee", "#778822"],
  2203: ["#11aaaa", "#224455", "#cc7744", "#bb0044", "#9911bb"],
  2204: ["#eebb55", "#3300cc", "#44aadd", "#332211", "#ff77dd"],
  2205: ["#eebb33", "#337788", "#22dd55", "#11ffff", "#339900"],
  2206: ["#33ee00", "#554411", "#2222ee", "#aa8877", "#8888aa"],
  2207: ["#0088ff", "#556622", "#1155cc", "#77dd88", "#eebb00"],
  2208: ["#cccc00", "#7744bb", "#ee6655", "#7744bb", "#dd66bb"],
  2209: ["#5522ff", "#77ff11", "#445588", "#7722aa", "#aa33dd"],
  2210: ["#dd7733", "#ff2200", "#00cc22", "#dd00dd", "#667788"],
  2211: ["#447777", "#55ff99", "#eeeebb", "#7777ee", "#2222bb"],
  2212: ["#cc55dd", "#ddbb99", "#11bbff", "#77cc11", "#008811"],
  2213: ["#bb77dd", "#44ffbb", "#44cccc", "#3388aa", "#eedd11"],
  2214: ["#4455aa", "#ff3388", "#aa2288", "#ff7733", "#229944"],
  2215: ["#445511", "#770077", "#116611", "#bbaa00", "#44ff88"],
  2216: ["#33ff77", "#33dd88", "#dd9911", "#aa6666", "#99cc33"],
  2217: ["#aa88dd", "#226677", "#66cc88", "#221111", "#ff4499"],
  2218: ["#116677", "#88bb88", "#772200", "#993355", "#aa5588"],
  2219: ["#bbddee", "#0011ee", "#aaee66", "#77bb44", "#668855"],
  2220: ["#225588", "#eeeecc", "#aa6655", "#666677", "#88ccee"],
  2221: ["#aadd77", "#335555", "#442255", "#77ccaa", "#99bb88"],
  2222: ["#112255", "#cc55cc", "#ee0022", "#99ddee", "#996633"],
  2223: ["#cc7733", "#770088", "#ff1122", "#119944", "#225588"],
  2224: ["#cc99ff", "#887799", "#aa8800", "#ff55bb", "#66bb00"],
  2225: ["#7722bb", "#55bb22", "#8877cc", "#ff22ee", "#66ffff"],
  2226: ["#44bb55", "#667700", "#cc3355", "#cc5522", "#445555"],
  2227: ["#bb5522", "#113355", "#aaff33", "#339955", "#aa9922"],
  2228: ["#ff44bb", "#bbcccc", "#0000aa", "#aa22bb", "#5588aa"],
  2229: ["#999955", "#4400ee", "#4400cc", "#dd0000", "#ccbb88"],
  2230: ["#44ffbb", "#2222dd", "#ee22ee", "#556666", "#11aa66"],
  2231: ["#bb7799", "#7766bb", "#ffcc66", "#bbdd55", "#66aa33"],
  2232: ["#bb3322", "#992288", "#bb5522", "#6633aa", "#449922"],
  2233: ["#ee66aa", "#551111", "#115588", "#112288", "#3388bb"],
  2234: ["#8866cc", "#7722ff", "#00ccff", "#22cc99", "#33dd88"],
  2235: ["#cc4433", "#99bbff", "#0000ff", "#44cc00", "#ff11aa"],
  2236: ["#3355ff", "#77ccdd", "#553311", "#9966bb", "#770022"],
  2237: ["#ee11aa", "#226644", "#ddbb11", "#eeee66", "#ddaa33"],
  2238: ["#55cc33", "#773311", "#446699", "#776655", "#66cc22"],
  2239: ["#eebbee", "#bb1199", "#11ddee", "#5511dd", "#556611"],
  2240: ["#bbcc22", "#5533bb", "#5599aa", "#99aa11", "#ff1122"],
  2241: ["#3388dd", "#66ddee", "#11dd66", "#0088aa", "#1111aa"],
  2242: ["#cc3388", "#bb3388", "#ddcc66", "#aaff99", "#aabb77"],
  2243: ["#aaff33", "#7733bb", "#44cc88", "#33bb55", "#664499"],
  2244: ["#ccee66", "#3344aa", "#33aa00", "#33ff44", "#7744ff"],
  2245: ["#996699", "#1188dd", "#55ccaa", "#aa0022", "#55ee77"],
  2246: ["#99ee44", "#77cc11", "#bb7722", "#775500", "#999900"],
  2247: ["#117788", "#11cccc", "#00ffff", "#1133ee", "#991166"],
  2248: ["#ee88ff", "#884400", "#ffff11", "#cc33cc", "#55aa66"],
  2249: ["#ffbbaa", "#449966", "#556699", "#aa1188", "#ddeedd"],
  2250: ["#ffff00", "#aa2299", "#eeee00", "#662244", "#998877"],
  2251: ["#007755", "#33eeee", "#66cc77", "#eeddee", "#bb1177"],
  2252: ["#77ff77", "#cc9966", "#ddbbaa", "#553344", "#11aa11"],
  2253: ["#6666ee", "#6655ff", "#008822", "#33ff00", "#995511"],
  2254: ["#55ffdd", "#bbffbb", "#110066", "#ee0055", "#00ffcc"],
  2255: ["#ccff99", "#33aa22", "#663344", "#4444ee", "#ee6622"],
  2256: ["#00ff22", "#aa9977", "#ddffdd", "#114488", "#ee3333"],
  2257: ["#99aa88", "#1155cc", "#664411", "#337711", "#5555ee"],
  2258: ["#99ffbb", "#cc0011", "#aaffff", "#778833", "#77ff00"],
  2259: ["#22dd00", "#559988", "#66ffff", "#aa3399", "#ee11bb"],
  2260: ["#330033", "#552288", "#447766", "#221144", "#7755cc"],
  2261: ["#dd44dd", "#5577ee", "#aa2266", "#55aaee", "#774411"],
  2262: ["#88bbdd", "#88dddd", "#eecc55", "#ff7733", "#668822"],
  2263: ["#dd88ee", "#dd4400", "#ddaaff", "#bbbbaa", "#99ccff"],
  2264: ["#eeffee", "#0055ee", "#ee9955", "#33dd11", "#995500"],
  2265: ["#5533bb", "#aa8877", "#33aa99", "#2233dd", "#cc00ff"],
  2266: ["#22aa66", "#337744", "#55ee44", "#99aa88", "#6600cc"],
  2267: ["#8844ee", "#cc5566", "#33bb22", "#22aa55", "#dd44bb"],
  2268: ["#99dd44", "#2233ee", "#118866", "#3322aa", "#dd6633"],
  2269: ["#aacc33", "#6644aa", "#bb9999", "#55bb66", "#7700ee"],
  2270: ["#66cc99", "#ff88dd", "#66ff33", "#aabbee", "#884455"],
  2271: ["#22eeee", "#3322ff", "#bbcc11", "#ccff00", "#ee6677"],
  2272: ["#777755", "#66dd77", "#22aa55", "#22ccee", "#996655"],
  2273: ["#dd4422", "#dd88ee", "#bbeecc", "#88bb88", "#2233ff"],
  2274: ["#ff00dd", "#66dd55", "#ccaa44", "#ff55bb", "#dd22cc"],
  2275: ["#bbeeaa", "#aa4422", "#cc5522", "#22ee44", "#8811aa"],
  2276: ["#cc3322", "#ff9988", "#ffaaee", "#7722ee", "#44aaaa"],
  2277: ["#22ff88", "#99eecc", "#66aa99", "#77ff99", "#22ee77"],
  2278: ["#8833ff", "#ff1111", "#00bb22", "#99aacc", "#2200dd"],
  2279: ["#00aa22", "#994411", "#aa6655", "#dd88aa", "#55aabb"],
  2280: ["#bb1144", "#aa99aa", "#445577", "#aa8877", "#ddaaee"],
  2281: ["#884400", "#9977dd", "#770099", "#dd8844", "#dd9999"],
  2282: ["#115533", "#ddaa77", "#22eebb", "#889933", "#ddee22"],
  2283: ["#ee6666", "#114444", "#ccbb00", "#cc11bb", "#226677"],
  2284: ["#336633", "#558800", "#4455aa", "#55dd77", "#552244"],
  2285: ["#99cc00", "#0055ee", "#aabb99", "#bbaa99", "#338888"],
  2286: ["#008822", "#00bbee", "#444433", "#449911", "#8866ff"],
  2287: ["#11bb66", "#0077cc", "#997777", "#11cc11", "#cc2299"],
  2288: ["#11eecc", "#339999", "#551177", "#66ee22", "#cc6666"],
  2289: ["#0099cc", "#ff77cc", "#990044", "#ffddcc", "#221177"],
  2290: ["#8855ee", "#9999ee", "#cc9955", "#88bb44", "#3377ff"],
  2291: ["#111188", "#99ddaa", "#dd0000", "#bbdd44", "#8822cc"],
  2292: ["#339911", "#6611aa", "#dd88ee", "#dd88aa", "#9966aa"],
  2293: ["#118866", "#886666", "#775511", "#aacc11", "#aa22ee"],
  2294: ["#1100dd", "#bbff55", "#88bbee", "#aaccff", "#1111aa"],
  2295: ["#dd9911", "#dd2244", "#bbee77", "#3300ee", "#7722aa"],
  2296: ["#5555aa", "#cc9922", "#00ff55", "#ffbbff", "#33ff33"],
  2297: ["#aa7722", "#447799", "#ee0088", "#eeddbb", "#cc4477"],
  2298: ["#ddaa77", "#aa00ee", "#111111", "#9988cc", "#1155dd"],
  2299: ["#88dd55", "#7700bb", "#447722", "#112233", "#44ff55"],
  2300: ["#ee0099", "#112288", "#995533", "#cc4466", "#883399"],
  2301: ["#aa0088", "#00dd99", "#ffee77", "#ee3333", "#dd66ee"],
  2302: ["#ccdd99", "#dd9999", "#771100", "#ee8833", "#339922"],
  2303: ["#66ddaa", "#cc7766", "#887799", "#33bb00", "#559966"],
  2304: ["#22cccc", "#aa99ff", "#44eebb", "#00ee44", "#668844"],
  2305: ["#eeeeff", "#882222", "#22aa99", "#88aa77", "#cc33bb"],
  2306: ["#ee0077", "#dd9911", "#cc66ff", "#334466", "#bb6633"],
  2307: ["#bb0022", "#aaaa33", "#114499", "#8844bb", "#7755dd"],
  2308: ["#225511", "#dd1199", "#dd33aa", "#aaff55", "#33ee44"],
  2309: ["#cc3300", "#220077", "#ff1155", "#ddaa33", "#ffeeee"],
  2310: ["#ff99dd", "#ccaa99", "#ee44dd", "#bb4455", "#33cc55"],
  2311: ["#337777", "#77ff99", "#998866", "#ee7755", "#aa9911"],
  2312: ["#dd77ee", "#ffffee", "#ee22ff", "#77eedd", "#7755ee"],
  2313: ["#ccffbb", "#000099", "#2299aa", "#dd88cc", "#bbffee"],
  2314: ["#cc8811", "#224455", "#004477", "#660033", "#99ccaa"],
  2315: ["#dd8877", "#0000aa", "#eeeecc", "#ff6655", "#eeeedd"],
  2316: ["#773344", "#555500", "#228888", "#994422", "#667711"],
  2317: ["#4455dd", "#9988ff", "#ffddcc", "#ffcc88", "#ff0022"],
  2318: ["#ccbb88", "#669977", "#7733bb", "#eeffdd", "#bb6644"],
  2319: ["#dd5566", "#bbcc22", "#006611", "#bbbbdd", "#779999"],
  2320: ["#cceedd", "#007744", "#bbffee", "#990099", "#44eeff"],
  2321: ["#3344ee", "#000033", "#444477", "#8866ff", "#339988"],
  2322: ["#221133", "#bb99ee", "#44ff55", "#223388", "#8822aa"],
  2323: ["#118877", "#dddddd", "#003366", "#9900ee", "#44ddee"],
  2324: ["#bbff22", "#ffddff", "#1144cc", "#77ff66", "#88ddbb"],
  2325: ["#772200", "#337766", "#aa6622", "#44ff99", "#dd00ee"],
  2326: ["#ee8811", "#2288cc", "#994477", "#110099", "#553322"],
  2327: ["#dd44dd", "#ccee11", "#770066", "#ddff33", "#3300ee"],
  2328: ["#ccaa99", "#4466dd", "#99eeee", "#22ff55", "#773355"],
  2329: ["#113366", "#ffddbb", "#bbeebb", "#ff7700", "#44ddaa"],
  2330: ["#557799", "#ff11cc", "#11ff44", "#5533bb", "#ccaa22"],
  2331: ["#ccdd11", "#ee6677", "#77ee77", "#990055", "#ee6600"],
  2332: ["#446611", "#779900", "#bbaa66", "#885588", "#6699ff"],
  2333: ["#ffccee", "#555533", "#3300ee", "#88aaaa", "#9999aa"],
  2334: ["#445500", "#88ee22", "#dd3399", "#ddccaa", "#882222"],
  2335: ["#7799ee", "#aaeecc", "#661122", "#aa33ee", "#668899"],
  2336: ["#11bb99", "#ffee88", "#ffff00", "#661188", "#dd8844"],
  2337: ["#aaccbb", "#99dd55", "#ffffbb", "#888822", "#9966cc"],
  2338: ["#77aa55", "#882288", "#bb2244", "#ffbb66", "#88dd00"],
  2339: ["#bbeecc", "#0033ff", "#227744", "#bb4488", "#55bb88"],
  2340: ["#337755", "#660066", "#44ccbb", "#22dd88", "#553322"],
  2341: ["#7711aa", "#3333cc", "#99ddbb", "#dd1111", "#77ee77"],
  2342: ["#443322", "#88bbaa", "#8877bb", "#224455", "#9933bb"],
  2343: ["#ddee00", "#99eedd", "#cc44ff", "#77aa44", "#aaccff"],
  2344: ["#880022", "#aa3311", "#eeee44", "#00ccdd", "#ff44cc"],
  2345: ["#aaddaa", "#cc7799", "#ff5500", "#ffcc99", "#99cc11"],
  2346: ["#22bbdd", "#bb77aa", "#331133", "#7788dd", "#ff2233"],
  2347: ["#cc4499", "#ddaaee", "#ddff33", "#aa88ff", "#006677"],
  2348: ["#22ee66", "#11ee55", "#eeffff", "#ff7711", "#885533"],
  2349: ["#dd99ff", "#bbbb11", "#dd0077", "#eeaaee", "#dd9911"],
  2350: ["#00aaff", "#778800", "#55ff00", "#bb33dd", "#229988"],
  2351: ["#ff8800", "#6688dd", "#ddeebb", "#aa9999", "#333388"],
  2352: ["#4466dd", "#0088ff", "#aa00aa", "#bb2299", "#ff77bb"],
  2353: ["#ee0055", "#774455", "#9900bb", "#ff3377", "#118888"],
  2354: ["#334455", "#33aabb", "#ff00ee", "#221133", "#661133"],
  2355: ["#1166aa", "#44aacc", "#33aa22", "#88cc00", "#668844"],
  2356: ["#88ccbb", "#eedd88", "#66bb66", "#ee9988", "#118855"],
  2357: ["#2244bb", "#55eebb", "#776644", "#334411", "#eecc22"],
  2358: ["#009955", "#bbaa66", "#7722bb", "#118800", "#dd0077"],
  2359: ["#ff22cc", "#9933cc", "#000066", "#ffbb88", "#662233"],
  2360: ["#887722", "#bbff11", "#88cc55", "#ffff66", "#eedd55"],
  2361: ["#66ffee", "#ff4466", "#bb9900", "#bb77ff", "#ee9988"],
  2362: ["#115533", "#885588", "#003355", "#bb6600", "#bb4455"],
  2363: ["#cc4466", "#eeaa66", "#22ff55", "#669933", "#bb77ee"],
  2364: ["#bbee11", "#331166", "#bbddee", "#338800", "#224411"],
  2365: ["#dd66aa", "#111100", "#226644", "#dd9955", "#aa1111"],
  2366: ["#44ff22", "#bb7799", "#aa7766", "#1144bb", "#ffbb55"],
  2367: ["#00ffcc", "#ffff66", "#994444", "#11bb00", "#00ff33"],
  2368: ["#778833", "#77ccbb", "#88ff88", "#001188", "#ee4400"],
  2369: ["#5588aa", "#cc22cc", "#ff55aa", "#ff66dd", "#776600"],
  2370: ["#99ffbb", "#ee2288", "#aaaa22", "#009911", "#ffcc22"],
  2371: ["#2244aa", "#771177", "#aa9922", "#3322ff", "#ffbb44"],
  2372: ["#4400ee", "#1177ff", "#bb5555", "#00aa00", "#33ff77"],
  2373: ["#117733", "#dd6666", "#884422", "#661144", "#3300aa"],
  2374: ["#dd9911", "#99bb11", "#ccff22", "#667711", "#bbee88"],
  2375: ["#334499", "#cc8844", "#667777", "#55cc33", "#4422cc"],
  2376: ["#116633", "#ccddee", "#55ee44", "#335522", "#ff66dd"],
  2377: ["#556666", "#11aaee", "#bb4400", "#002200", "#88aacc"],
  2378: ["#33ffff", "#bbee22", "#bbeeee", "#77bb33", "#551155"],
  2379: ["#220000", "#33aa11", "#8822cc", "#77ffee", "#55bbdd"],
  2380: ["#5511ee", "#cc8855", "#22aa11", "#ee55aa", "#33aa44"],
  2381: ["#77aa55", "#339900", "#336644", "#2200cc", "#eeaa33"],
  2382: ["#6622dd", "#88dd55", "#441199", "#3322bb", "#44aa88"],
  2383: ["#888811", "#660022", "#550000", "#774444", "#ee9977"],
  2384: ["#bb5544", "#449944", "#111111", "#11bb99", "#0000ee"],
  2385: ["#aaaa77", "#ddee22", "#aa3366", "#cc2233", "#552266"],
  2386: ["#ee0000", "#888899", "#66aa44", "#dd8822", "#bbbb44"],
  2387: ["#881111", "#55bbee", "#3333dd", "#66aa55", "#665599"],
  2388: ["#aadd99", "#ddee44", "#005544", "#225577", "#aadd99"],
  2389: ["#77cc22", "#550066", "#9999ee", "#557700", "#ffeedd"],
  2390: ["#33dd77", "#00bbee", "#44ff77", "#bb3366", "#336699"],
  2391: ["#ee00dd", "#22ee22", "#773344", "#5544ee", "#4455ff"],
  2392: ["#44ee44", "#22bbcc", "#1144ff", "#44bb00", "#ff9955"],
  2393: ["#ddee44", "#ffaaee", "#ee9999", "#9955cc", "#cc8888"],
  2394: ["#2255dd", "#44dd22", "#bb7777", "#bb2255", "#664411"],
  2395: ["#ffff77", "#229988", "#004488", "#bb5577", "#7700ee"],
  2396: ["#6600dd", "#887799", "#aa5555", "#55bb77", "#44aa66"],
  2397: ["#33ddbb", "#886633", "#ff33aa", "#44bbbb", "#cc88aa"],
  2398: ["#ddffcc", "#ee0099", "#557755", "#225599", "#88aa66"],
  2399: ["#ddcc33", "#ffdddd", "#ccbbee", "#2211ee", "#559988"],
  2400: ["#bbff77", "#99cc77", "#99ee00", "#002200", "#3388ff"],
  2401: ["#ccaa88", "#ddeeee", "#bb6677", "#aa0066", "#ff33ff"],
  2402: ["#88aa99", "#aacc55", "#66cc00", "#ff1111", "#881177"],
  2403: ["#224477", "#556644", "#bb7755", "#11ffee", "#bb7744"],
  2404: ["#bbaaff", "#99bb22", "#55dd88", "#ff44bb", "#005566"],
  2405: ["#11bb88", "#ee00cc", "#bbcc55", "#66aaaa", "#665599"],
  2406: ["#ddcc00", "#ff4422", "#779933", "#334466", "#559933"],
  2407: ["#ccdd77", "#665511", "#005555", "#558811", "#ff33ee"],
  2408: ["#00cc55", "#eebbff", "#4433ee", "#aa8800", "#ddccee"],
  2409: ["#55dd77", "#99ee88", "#ee6622", "#666666", "#bb0044"],
  2410: ["#4488aa", "#88aaaa", "#116699", "#554422", "#44aa55"],
  2411: ["#bb0088", "#ee3322", "#ee7777", "#ddffaa", "#66ddff"],
  2412: ["#221188", "#ff1133", "#886666", "#337755", "#bb1155"],
  2413: ["#cc77bb", "#662222", "#1188ff", "#ff55aa", "#2299ee"],
  2414: ["#aa4411", "#66aadd", "#00cc11", "#55aa33", "#5555aa"],
  2415: ["#337766", "#33dd11", "#778899", "#aaddcc", "#555555"],
  2416: ["#338855", "#cc22ee", "#ddaadd", "#11cc00", "#cc9999"],
  2417: ["#cc5544", "#552288", "#ff55ee", "#99bb99", "#778899"],
  2418: ["#770077", "#1199ee", "#44ddee", "#ee5599", "#aa8822"],
  2419: ["#66aaff", "#ddddff", "#556622", "#dd3399", "#dd11bb"],
  2420: ["#ee3388", "#ddddaa", "#dd0099", "#eeaacc", "#aabbcc"],
  2421: ["#44bb88", "#6677aa", "#114455", "#4477dd", "#bb11bb"],
  2422: ["#33aa00", "#222255", "#552288", "#338833", "#aabb55"],
  2423: ["#11cc99", "#dd1122", "#2255cc", "#cceeff", "#1199ee"],
  2424: ["#44bbdd", "#4411aa", "#66ccee", "#ddcc88", "#55aa66"],
  2425: ["#ddeecc", "#ddaaff", "#8811cc", "#00aa77", "#33bb99"],
  2426: ["#ee00bb", "#ddff22", "#6666dd", "#aa44bb", "#551100"],
  2427: ["#ddaaee", "#77ee33", "#eedd33", "#225555", "#2211cc"],
  2428: ["#8811cc", "#eeccbb", "#004433", "#444411", "#aa3322"],
  2429: ["#aa3388", "#ff8833", "#aaaa22", "#88ff44", "#66aa11"],
  2430: ["#00ddee", "#cc4477", "#44aa22", "#ffeebb", "#88aaff"],
  2431: ["#2211bb", "#1111bb", "#0066aa", "#330088", "#ff55aa"],
  2432: ["#55bbcc", "#665511", "#2288cc", "#aa2266", "#0077ff"],
  2433: ["#22aa44", "#ff7799", "#22bb33", "#3377aa", "#aa3388"],
  2434: ["#00ff33", "#66dddd", "#227766", "#ccccbb", "#eeaa55"],
  2435: ["#557700", "#3344aa", "#6611ff", "#66eeaa", "#998899"],
  2436: ["#2244ee", "#aa5555", "#ee22ff", "#116644", "#994411"],
  2437: ["#bbcc22", "#882200", "#880055", "#ff88ff", "#552222"],
  2438: ["#cc9944", "#111111", "#aa7766", "#0099aa", "#22cc11"],
  2439: ["#ff9933", "#00aa77", "#bb33ee", "#ffff00", "#44aacc"],
  2440: ["#66dd66", "#dd1188", "#336622", "#220033", "#88cc00"],
  2441: ["#ccaa00", "#776611", "#882244", "#44bb00", "#11aa33"],
  2442: ["#aabb66", "#00bb66", "#220044", "#888899", "#aaccee"],
  2443: ["#442255", "#33ddee", "#dd2200", "#ddccee", "#77bb55"],
  2444: ["#33ffee", "#661133", "#bb0055", "#55aa99", "#aa9966"],
  2445: ["#447799", "#ddaa11", "#993388", "#7700aa", "#3322ff"],
  2446: ["#44bbee", "#aa99ff", "#77ddaa", "#773333", "#223344"],
  2447: ["#44bbff", "#1111aa", "#ccbb77", "#11bbff", "#66cc99"],
  2448: ["#003377", "#668855", "#99aaee", "#44cc11", "#995544"],
  2449: ["#88ff88", "#33ffcc", "#ccbb66", "#99bbcc", "#ff8899"],
  2450: ["#7766aa", "#665577", "#881155", "#990099", "#aaaadd"],
  2451: ["#bb0022", "#99bb55", "#5566dd", "#889900", "#aa8833"],
  2452: ["#118866", "#33cc22", "#00dd99", "#1100cc", "#778866"],
  2453: ["#33aa00", "#3300ee", "#662200", "#22bb44", "#7777ee"],
  2454: ["#dd6699", "#11ffff", "#11cc00", "#bb99dd", "#33ff11"],
  2455: ["#556600", "#cc7722", "#22aacc", "#003388", "#3344cc"],
  2456: ["#88ccdd", "#aadd33", "#bb2288", "#88aa44", "#aaffee"],
  2457: ["#668800", "#44bbcc", "#337700", "#99bbee", "#3399ee"],
  2458: ["#bb0000", "#8844bb", "#eeee99", "#eeff11", "#4499dd"],
  2459: ["#ee3399", "#2200cc", "#66cc33", "#44ee55", "#dd8866"],
  2460: ["#33ccaa", "#66ddbb", "#aabb55", "#556677", "#aadd88"],
  2461: ["#ff9911", "#44bbee", "#662255", "#558822", "#882222"],
  2462: ["#ccbb44", "#339933", "#00dd33", "#00cc55", "#aa2288"],
  2463: ["#992244", "#cc8888", "#cc55cc", "#aa5588", "#99eebb"],
  2464: ["#ff00bb", "#332211", "#661155", "#009999", "#110000"],
  2465: ["#227755", "#11dd00", "#00ee55", "#44ccbb", "#aa11ff"],
  2466: ["#00cc55", "#9922ee", "#2277bb", "#667722", "#773333"],
  2467: ["#001144", "#ee44dd", "#ffbbcc", "#bb9966", "#aa8822"],
  2468: ["#ff33bb", "#88ff44", "#eeff77", "#bb6611", "#7799dd"],
  2469: ["#777700", "#55dd77", "#996655", "#66dd66", "#cc4444"],
  2470: ["#44ee66", "#441111", "#77bbcc", "#113311", "#88dd66"],
  2471: ["#44bbcc", "#66aaff", "#55bbcc", "#ddaa33", "#aadd44"],
  2472: ["#222200", "#ddbbcc", "#ddcc00", "#cc1155", "#113377"],
  2473: ["#33aaee", "#66ee22", "#eeccff", "#ccddcc", "#cc9966"],
  2474: ["#669966", "#990055", "#dddd00", "#aa3377", "#556611"],
  2475: ["#33aa11", "#4488cc", "#bb77ee", "#5500cc", "#33cc33"],
  2476: ["#22ffff", "#999966", "#ff77ee", "#660077", "#eebb66"],
  2477: ["#774488", "#bbffff", "#ff9933", "#22ee88", "#116655"],
  2478: ["#dd4400", "#115566", "#77ccff", "#bbaa99", "#cccc11"],
  2479: ["#77dd77", "#bb4422", "#bbff88", "#8800ee", "#ff2233"],
  2480: ["#339988", "#ffeedd", "#556666", "#992266", "#eebb99"],
  2481: ["#bb0099", "#eecc44", "#4466ee", "#99ff22", "#dd66ff"],
  2482: ["#22ffff", "#55bb33", "#448822", "#889911", "#dd6622"],
  2483: ["#ff77cc", "#00bb88", "#998866", "#44ff99", "#99ee77"],
  2484: ["#22ee00", "#226644", "#cccc00", "#88ccff", "#888855"],
  2485: ["#3377ff", "#ddbb44", "#662277", "#0011bb", "#aacc88"],
  2486: ["#448844", "#55ddee", "#22ff77", "#9988ff", "#cc4466"],
  2487: ["#113388", "#bb1155", "#33aaee", "#ee2299", "#aa9999"],
  2488: ["#cc55ee", "#66cc44", "#885522", "#6655cc", "#99ee00"],
  2489: ["#882244", "#aa55ee", "#885566", "#bb8855", "#442266"],
  2490: ["#552277", "#ddbbff", "#009988", "#ff4444", "#ee00bb"],
  2491: ["#99ddcc", "#8899dd", "#00aa55", "#669944", "#aa11dd"],
  2492: ["#aa7799", "#bbeebb", "#44bbee", "#bbcc66", "#99ccbb"],
  2493: ["#88bbee", "#880066", "#dd88ee", "#aadddd", "#9999bb"],
  2494: ["#666677", "#6699bb", "#11cc99", "#66cc77", "#3388ff"],
  2495: ["#ee3366", "#ffee00", "#cc4444", "#88aa66", "#cc55aa"],
  2496: ["#ddcc77", "#3399dd", "#aaffbb", "#33aa77", "#5500ee"],
  2497: ["#77cc77", "#eeeecc", "#ddaa88", "#aa2211", "#55ddbb"],
  2498: ["#7777dd", "#99ff44", "#33bb44", "#ddee22", "#55dd88"],
  2499: ["#1144aa", "#6633bb", "#cceecc", "#ff11dd", "#aa33dd"],
  2500: ["#331177", "#11dd77", "#22ddaa", "#66bb77", "#110077"],
  2501: ["#882277", "#884466", "#221111", "#33dd44", "#664422"],
  2502: ["#55ccee", "#223355", "#9933bb", "#776666", "#ff2266"],
  2503: ["#5511dd", "#337777", "#aaffdd", "#ffdd44", "#00ccaa"],
  2504: ["#4455ff", "#ff1155", "#9977bb", "#bbdd77", "#cc88cc"],
  2505: ["#1155bb", "#bbee33", "#11ffff", "#442233", "#88ddaa"],
  2506: ["#556666", "#88aa33", "#7777dd", "#cc99ee", "#6655aa"],
  2507: ["#6666ee", "#bbff66", "#3322dd", "#aaeeee", "#88bb66"],
  2508: ["#cc88cc", "#881166", "#77cc66", "#9966cc", "#11cc44"],
  2509: ["#ee44ee", "#eecc33", "#bb11dd", "#cc6677", "#55bb99"],
  2510: ["#ffcc22", "#2233dd", "#ff88ff", "#bb0044", "#dd00dd"],
  2511: ["#ff4433", "#88ddaa", "#aa5577", "#112200", "#ffcc99"],
  2512: ["#dd1177", "#cc55dd", "#22eedd", "#775566", "#0066cc"],
  2513: ["#eebb22", "#333322", "#aa11ee", "#dd6655", "#3311cc"],
  2514: ["#dd55aa", "#66ddee", "#009922", "#11ccdd", "#bb5544"],
  2515: ["#993344", "#88ddcc", "#552266", "#993399", "#ee99ff"],
  2516: ["#4444aa", "#66ccee", "#44ddee", "#11eeaa", "#88aa00"],
  2517: ["#ffff22", "#9944aa", "#336644", "#995588", "#bb6644"],
  2518: ["#331100", "#5522aa", "#22aa11", "#330066", "#4400ff"],
  2519: ["#880055", "#2244aa", "#bb2233", "#bb11dd", "#1133dd"],
  2520: ["#550077", "#ddffff", "#55ee77", "#aa2255", "#eeaabb"],
  2521: ["#ff2233", "#ccee33", "#22aaee", "#ddccbb", "#991122"],
  2522: ["#1177bb", "#bb4466", "#aaee11", "#aabb00", "#4433dd"],
  2523: ["#223300", "#77cccc", "#9977ff", "#dd88bb", "#ee8833"],
  2524: ["#cc0077", "#66bbff", "#884455", "#ee7700", "#aa1133"],
  2525: ["#2222ee", "#2244dd", "#66bb88", "#991133", "#aaee77"],
  2526: ["#bbaaee", "#6699cc", "#9977ff", "#77ffee", "#2222ee"],
  2527: ["#445511", "#551111", "#bb99aa", "#aa55aa", "#dd00ff"],
  2528: ["#aaaa88", "#1144ee", "#77aa44", "#88ccdd", "#aabbff"],
  2529: ["#3388ff", "#11bbbb", "#665511", "#ccee66", "#ee6600"],
  2530: ["#3399dd", "#887733", "#000088", "#220099", "#dd00bb"],
  2531: ["#dd5588", "#33cc77", "#ccaa99", "#66ee99", "#228888"],
  2532: ["#ffeecc", "#4444cc", "#11ccff", "#77aaaa", "#dd0088"],
  2533: ["#ddaaaa", "#1166dd", "#5544dd", "#ff5500", "#33eecc"],
  2534: ["#55bb11", "#335511", "#aa11ff", "#aa6600", "#557777"],
  2535: ["#779977", "#ee2222", "#00ff00", "#006633", "#77aa11"],
  2536: ["#661133", "#116600", "#ee7755", "#006688", "#77ee33"],
  2537: ["#550055", "#992266", "#667777", "#4400ff", "#cc6666"],
  2538: ["#ffbbee", "#dd55aa", "#4433ee", "#4499dd", "#99eeee"],
  2539: ["#aa8811", "#990077", "#990044", "#ccff22", "#aa5577"],
  2540: ["#8811ee", "#7733bb", "#668833", "#44ccaa", "#dddd77"],
  2541: ["#8899ee", "#ccee33", "#7777ee", "#669977", "#221188"],
  2542: ["#3333aa", "#aa0000", "#9966ee", "#00ff55", "#77ee66"],
  2543: ["#ffee44", "#bbee00", "#993311", "#ff22bb", "#ee0077"],
  2544: ["#cc99bb", "#555544", "#ee4433", "#dd5577", "#aaaa33"],
  2545: ["#996611", "#aa99ee", "#ee7744", "#88aadd", "#33cc11"],
  2546: ["#225522", "#77ff00", "#885544", "#776633", "#442244"],
  2547: ["#aabb55", "#8877aa", "#88cc88", "#cc4444", "#aa33ee"],
  2548: ["#229988", "#882200", "#aabbee", "#554455", "#ffaa44"],
  2549: ["#55ee99", "#eeee88", "#3333dd", "#dd9999", "#77ff22"],
  2550: ["#11aa00", "#1122aa", "#00ffcc", "#447799", "#33ffff"],
  2551: ["#339933", "#ff1199", "#dd3344", "#883311", "#22ddbb"],
  2552: ["#bbff11", "#990066", "#334433", "#99bb66", "#66bb99"],
  2553: ["#8822ff", "#118877", "#9922aa", "#771133", "#1177ff"],
  2554: ["#8822ee", "#331144", "#cc0088", "#ddff44", "#5544cc"],
  2555: ["#6611aa", "#003399", "#dd00aa", "#557744", "#6655ff"],
  2556: ["#ff7700", "#ff5555", "#775588", "#11bbbb", "#bb88aa"],
  2557: ["#11ccee", "#666633", "#dd1155", "#ccaa11", "#aaeecc"],
  2558: ["#4488ee", "#33aaee", "#ee8877", "#4477aa", "#66bb55"],
  2559: ["#55eeaa", "#553311", "#33ff77", "#554455", "#7788aa"],
  2560: ["#88bb66", "#116622", "#55eedd", "#557700", "#bb9911"],
  2561: ["#aaaaaa", "#44aacc", "#ccff33", "#44cccc", "#aacc44"],
  2562: ["#55cc66", "#335522", "#ddee77", "#552200", "#00ff77"],
  2563: ["#887766", "#88dd11", "#995533", "#ccbb44", "#aaee77"],
  2564: ["#dd7788", "#99ff77", "#550066", "#88aa33", "#883333"],
  2565: ["#997799", "#dd9977", "#ee33aa", "#7755cc", "#008822"],
  2566: ["#44dddd", "#3322cc", "#999922", "#aaff44", "#223388"],
  2567: ["#7700dd", "#ff55dd", "#ee0055", "#44ccbb", "#ffaa00"],
  2568: ["#880033", "#aa0077", "#ff9900", "#66ffcc", "#22dddd"],
  2569: ["#227788", "#66aa00", "#88bb22", "#aaccbb", "#99bb99"],
  2570: ["#dd3333", "#bb7777", "#779933", "#664466", "#99dd66"],
  2571: ["#dddd44", "#11bb66", "#446688", "#4488cc", "#5522dd"],
  2572: ["#99bbcc", "#6688bb", "#88dd66", "#ff3322", "#001155"],
  2573: ["#999977", "#22ff11", "#ff4400", "#77aadd", "#22bb00"],
  2574: ["#aabbcc", "#0022cc", "#ffbbcc", "#bb4488", "#330011"],
  2575: ["#116688", "#9922ee", "#22cc33", "#226655", "#553300"],
  2576: ["#ee55ee", "#dd7733", "#22ccbb", "#ffdd11", "#00cc77"],
  2577: ["#ee5566", "#442299", "#ff1199", "#447788", "#7711cc"],
  2578: ["#11bb88", "#ddbb55", "#cc1188", "#113311", "#883300"],
  2579: ["#8888aa", "#449922", "#8844ff", "#1100dd", "#8844cc"],
  2580: ["#eeeecc", "#ddaaee", "#bb2277", "#cc1177", "#5555bb"],
  2581: ["#0088aa", "#bbee00", "#aa77bb", "#ff3344", "#66dd00"],
  2582: ["#44ff77", "#dddd44", "#00ddff", "#00aaaa", "#44ccbb"],
  2583: ["#66bbdd", "#eecc44", "#66ffdd", "#eeaa11", "#bb0088"],
  2584: ["#aadd88", "#22bb33", "#bbee99", "#ccffcc", "#dd44ee"],
  2585: ["#66ffee", "#5555ee", "#0099ff", "#ff8811", "#664499"],
  2586: ["#ddbb66", "#bbeedd", "#886688", "#bb55ff", "#dd1100"],
  2587: ["#cc6611", "#cc1111", "#119911", "#5555aa", "#ffeeff"],
  2588: ["#88cc77", "#007777", "#bbff11", "#00ddff", "#bb9966"],
  2589: ["#999999", "#00bbbb", "#11aa66", "#55bbff", "#cc4466"],
  2590: ["#bbdd66", "#cc5577", "#ee3333", "#0033aa", "#889944"],
  2591: ["#6699aa", "#ee1133", "#33ddff", "#ff4411", "#557766"],
  2592: ["#aa3300", "#bb0033", "#3366ee", "#117799", "#554499"],
  2593: ["#331177", "#22dddd", "#0066ee", "#77dd66", "#2255bb"],
  2594: ["#aa9922", "#cc0033", "#88cccc", "#77ffdd", "#cccc22"],
  2595: ["#aa11aa", "#11aa22", "#bb33bb", "#ee8800", "#883311"],
  2596: ["#888899", "#445500", "#3377ff", "#bb3388", "#ddeecc"],
  2597: ["#ee8855", "#44aa55", "#88aa44", "#99cc55", "#448888"],
  2598: ["#aa2233", "#cc22cc", "#881177", "#66ddff", "#444411"],
  2599: ["#117755", "#330055", "#99ffdd", "#88ccee", "#22aa55"],
  2600: ["#ccdddd", "#880088", "#228800", "#88eebb", "#6611ee"],
  2601: ["#6611bb", "#ee6644", "#aacc00", "#7788ee", "#bbaa11"],
  2602: ["#8844bb", "#66ff55", "#aa3366", "#bb22ff", "#ffaacc"],
  2603: ["#22ffee", "#55eeee", "#662255", "#aa77cc", "#99cc55"],
  2604: ["#ff7722", "#eeaabb", "#228800", "#bb5555", "#771133"],
  2605: ["#ddcc55", "#aa7788", "#887777", "#6600dd", "#dd00ee"],
  2606: ["#ee4444", "#00aacc", "#77bb55", "#66bbaa", "#664433"],
  2607: ["#aaff33", "#779933", "#8811ff", "#bbffdd", "#334411"],
  2608: ["#ee00ff", "#66ee00", "#00bbaa", "#8833cc", "#221155"],
  2609: ["#99bbee", "#bb8833", "#118833", "#99ff55", "#5566cc"],
  2610: ["#116699", "#ddbb88", "#aa9955", "#00dd99", "#aa77ee"],
  2611: ["#558855", "#cc7777", "#8888bb", "#33bb44", "#99ffdd"],
  2612: ["#77cc00", "#ccee99", "#551144", "#ddff55", "#11ee11"],
  2613: ["#44ee33", "#6699dd", "#88ff77", "#22ff11", "#998822"],
  2614: ["#bb3377", "#aa9988", "#66aa99", "#8866aa", "#dd77ff"],
  2615: ["#44dd33", "#7722bb", "#99ccbb", "#4422cc", "#bbff00"],
  2616: ["#dd11aa", "#8811ee", "#ddee66", "#22ddaa", "#00aa11"],
  2617: ["#221122", "#99dd33", "#885533", "#448866", "#ff88ff"],
  2618: ["#88aa77", "#ff55cc", "#335500", "#444499", "#ee77bb"],
  2619: ["#11aaff", "#11aadd", "#336677", "#ffcc44", "#44ccaa"],
  2620: ["#aaaa44", "#aaaa99", "#448899", "#99aabb", "#cc2222"],
  2621: ["#88aa22", "#33aa77", "#227700", "#117777", "#ccffdd"],
  2622: ["#9966aa", "#99ccee", "#009966", "#ff9911", "#ff88cc"],
  2623: ["#ddaaaa", "#33dd77", "#ddffbb", "#55aa11", "#2288ff"],
  2624: ["#666633", "#dd0088", "#aa5500", "#887744", "#ee22ee"],
  2625: ["#2222aa", "#ccbbff", "#ccaa66", "#22eebb", "#005566"],
  2626: ["#ccdd22", "#1144aa", "#ddffcc", "#ee2211", "#2211aa"],
  2627: ["#550033", "#88bb88", "#00ffdd", "#aa8855", "#eecc33"],
  2628: ["#22eeaa", "#66ff33", "#ffcc66", "#bb66dd", "#ffeebb"],
  2629: ["#66dd22", "#33ccee", "#000011", "#996688", "#880088"],
  2630: ["#11dd22", "#dd00bb", "#33dd66", "#4455bb", "#11dd11"],
  2631: ["#bbff11", "#22dd22", "#993355", "#77ccdd", "#997744"],
  2632: ["#22dd88", "#ee1122", "#aa88dd", "#994455", "#bb2277"],
  2633: ["#22eeff", "#66dd22", "#8822bb", "#559944", "#3377cc"],
  2634: ["#7722dd", "#11eeaa", "#bb66cc", "#bb1199", "#559999"],
  2635: ["#226688", "#998811", "#ffccee", "#ff2233", "#5533cc"],
  2636: ["#55dd99", "#991166", "#11cc88", "#dd8833", "#ee1144"],
  2637: ["#ccffbb", "#cccc88", "#7711ff", "#337755", "#66bbff"],
  2638: ["#0022dd", "#aa4488", "#55ee22", "#11ccff", "#33ccee"],
  2639: ["#773300", "#ee6655", "#33cc77", "#ff8866", "#7700dd"],
  2640: ["#00dd55", "#88bbbb", "#ee3300", "#1111aa", "#1177ee"],
  2641: ["#883355", "#99aa22", "#229955", "#889955", "#dddd11"],
  2642: ["#55cc99", "#dd44ee", "#00eedd", "#225555", "#aa66dd"],
  2643: ["#553344", "#66ff55", "#111133", "#662200", "#223399"],
  2644: ["#445577", "#77ff77", "#ffeeee", "#662200", "#4411bb"],
  2645: ["#112211", "#aa66bb", "#880044", "#aa4499", "#886644"],
  2646: ["#dd1177", "#5544cc", "#994422", "#bbaabb", "#bbbb00"],
  2647: ["#dd0033", "#7744ff", "#8877ee", "#228877", "#5522bb"],
  2648: ["#885511", "#44ff66", "#bbbb33", "#dd99ff", "#cc2200"],
  2649: ["#449911", "#dd66ee", "#332200", "#bb4422", "#00dd66"],
  2650: ["#7766ff", "#ccff44", "#77ee44", "#33ee55", "#ff6633"],
  2651: ["#33eebb", "#dd44cc", "#7711cc", "#990099", "#11bb22"],
  2652: ["#991100", "#22bb44", "#00ee44", "#bbcc88", "#99bbaa"],
  2653: ["#775588", "#22eeaa", "#11dd88", "#77ffff", "#1133aa"],
  2654: ["#bb3333", "#2211cc", "#77ee99", "#4466ff", "#002200"],
  2655: ["#ffbb66", "#bb88aa", "#332277", "#112299", "#aaff44"],
  2656: ["#99ee55", "#881166", "#44ee44", "#44cc66", "#55eeaa"],
  2657: ["#3388aa", "#44dd11", "#660022", "#00ffdd", "#009966"],
  2658: ["#dd4411", "#bb77dd", "#22bbee", "#77bbcc", "#aa7799"],
  2659: ["#cccc44", "#442277", "#663399", "#99aa88", "#ddccee"],
  2660: ["#5577ee", "#bbaa00", "#22dd99", "#004488", "#444422"],
  2661: ["#00ee44", "#0066bb", "#dd55ff", "#774477", "#227711"],
  2662: ["#669911", "#aacccc", "#33ddcc", "#227799", "#77ddee"],
  2663: ["#aa1100", "#5588ee", "#2288cc", "#774411", "#556655"],
  2664: ["#ddaaee", "#559900", "#7700cc", "#8800ee", "#77bb88"],
  2665: ["#11aa77", "#997700", "#ffccff", "#eebb22", "#889955"],
  2666: ["#bbee77", "#5511ff", "#77aadd", "#00ee55", "#dd11ff"],
  2667: ["#558844", "#eeff55", "#ff8866", "#99bb55", "#8833aa"],
  2668: ["#00eecc", "#990022", "#5533bb", "#663311", "#443377"],
  2669: ["#556699", "#ccddee", "#aaaa99", "#dd5511", "#ff9944"],
  2670: ["#eeaa11", "#dd99cc", "#ee3355", "#44eecc", "#220066"],
  2671: ["#88aa11", "#0099ff", "#ee22cc", "#776666", "#6644ff"],
  2672: ["#44bbaa", "#339988", "#cc2299", "#338888", "#22bb22"],
  2673: ["#ee7777", "#11bb66", "#1100dd", "#779922", "#bbcc77"],
  2674: ["#22ff22", "#dddd55", "#007799", "#bbffcc", "#006699"],
  2675: ["#3344cc", "#ff2255", "#ff11bb", "#bb33dd", "#773388"],
  2676: ["#229966", "#9900cc", "#88aaee", "#55cc11", "#99cc77"],
  2677: ["#66aacc", "#44ccaa", "#3399cc", "#aa77aa", "#008833"],
  2678: ["#ee6644", "#aaee22", "#bb77aa", "#eeccaa", "#44eebb"],
  2679: ["#1155dd", "#449922", "#bb0055", "#88aa77", "#11aa55"],
  2680: ["#ff0055", "#5500ee", "#664444", "#44dd44", "#99bb22"],
  2681: ["#00bbaa", "#9911cc", "#1144bb", "#66cc11", "#aa66bb"],
  2682: ["#221100", "#003355", "#bbeebb", "#5500ff", "#ee11aa"],
  2683: ["#cc3388", "#ffeebb", "#223399", "#11cc55", "#889988"],
  2684: ["#ddaa66", "#bb1177", "#55dd00", "#669922", "#22dd66"],
  2685: ["#99ee11", "#ee66ff", "#664411", "#44cc55", "#993311"],
  2686: ["#332288", "#bbbbee", "#cc7700", "#00dd44", "#1155aa"],
  2687: ["#cc33ee", "#447799", "#66ff99", "#ee55aa", "#2211ee"],
  2688: ["#aa77ff", "#889955", "#9922bb", "#bb7788", "#333388"],
  2689: ["#dd8822", "#aacc11", "#550055", "#9922dd", "#ee7711"],
  2690: ["#225588", "#00bbbb", "#7722ee", "#cc6666", "#883355"],
  2691: ["#000099", "#dd1199", "#33ee55", "#dddd00", "#775511"],
  2692: ["#bb22bb", "#55ddcc", "#ee11cc", "#666699", "#442299"],
  2693: ["#667755", "#663355", "#88ff00", "#88bb77", "#2244ee"],
  2694: ["#664444", "#221100", "#ee0022", "#776633", "#ddaa00"],
  2695: ["#bbffbb", "#88bb00", "#112255", "#7744bb", "#cc8833"],
  2696: ["#885599", "#55ddcc", "#88bb44", "#ffbb22", "#3388ff"],
  2697: ["#aa2299", "#bbaa00", "#dd88dd", "#77bbff", "#0033aa"],
  2698: ["#00ee66", "#99ee88", "#33cccc", "#664422", "#3333ff"],
  2699: ["#338822", "#33ffaa", "#eebb11", "#118800", "#cc55bb"],
  2700: ["#000044", "#aacc00", "#110077", "#994477", "#eeee77"],
  2701: ["#116600", "#44bb77", "#22ff77", "#44aacc", "#aa0000"],
  2702: ["#881133", "#669999", "#bb6633", "#2233aa", "#cc3322"],
  2703: ["#eeee22", "#882299", "#777766", "#dd99bb", "#dd9922"],
  2704: ["#441166", "#ff5555", "#22ee44", "#336622", "#113344"],
  2705: ["#ee4499", "#ff77aa", "#ff4477", "#55ff22", "#ffaa77"],
  2706: ["#663399", "#224499", "#aa1188", "#770011", "#441166"],
  2707: ["#ee33bb", "#aaeecc", "#bb6600", "#88bb22", "#dd2233"],
  2708: ["#ee3311", "#66aa99", "#771144", "#110033", "#cc2255"],
  2709: ["#ff44bb", "#889988", "#dd88dd", "#ee33dd", "#4444aa"],
  2710: ["#9922ff", "#772299", "#005544", "#55aadd", "#ffddee"],
  2711: ["#cc6622", "#00aa33", "#00ffbb", "#7700ff", "#00cc11"],
  2712: ["#88bbbb", "#ff6600", "#775544", "#009988", "#bb66ff"],
  2713: ["#bbeedd", "#222288", "#aabb00", "#777744", "#11ff55"],
  2714: ["#229955", "#337766", "#9933ee", "#332277", "#0099aa"],
  2715: ["#88eeaa", "#774400", "#3355bb", "#449944", "#224466"],
  2716: ["#6699bb", "#55eeaa", "#cc9922", "#cc22aa", "#44cc22"],
  2717: ["#99aa33", "#330044", "#77ccaa", "#4433dd", "#ff11aa"],
  2718: ["#557744", "#99cc00", "#cc1144", "#00aabb", "#00aa22"],
  2719: ["#224488", "#00ff22", "#220044", "#bb88cc", "#ff7777"],
  2720: ["#22aabb", "#ee2222", "#00ee55", "#002211", "#bb2288"],
  2721: ["#22bb55", "#229988", "#bbee00", "#9922ff", "#ddee88"],
  2722: ["#7766cc", "#115533", "#7733cc", "#ee5511", "#2222ff"],
  2723: ["#bbff22", "#eeeeff", "#7788dd", "#aa22dd", "#445533"],
  2724: ["#443366", "#22ee00", "#aa0088", "#cc0077", "#55ee66"],
  2725: ["#ffbbdd", "#88dd88", "#224477", "#bbaa22", "#99bbbb"],
  2726: ["#ff6633", "#77ee33", "#eebbee", "#5511bb", "#557777"],
  2727: ["#bb22ee", "#667799", "#eebb77", "#5511dd", "#8844ff"],
  2728: ["#446633", "#888833", "#ee2288", "#aaffaa", "#dd1188"],
  2729: ["#ff77ee", "#aa8855", "#aaaa33", "#dd6688", "#3355ff"],
  2730: ["#11ee00", "#0011aa", "#7799ee", "#ff44ee", "#aa9900"],
  2731: ["#77ccdd", "#dd9933", "#dd9999", "#dd5555", "#88ff33"],
  2732: ["#779933", "#776622", "#99ddcc", "#ffffcc", "#2200dd"],
  2733: ["#22cc88", "#772277", "#6677bb", "#445511", "#117777"],
  2734: ["#88ff99", "#9988aa", "#002244", "#ff5544", "#775500"],
  2735: ["#77ffff", "#bb3388", "#4444ff", "#bb77dd", "#8811ff"],
  2736: ["#1122dd", "#ddee11", "#993355", "#7799dd", "#77cc55"],
  2737: ["#aaffcc", "#225555", "#cc7744", "#cc44dd", "#661133"],
  2738: ["#ddbb33", "#bbbb22", "#115555", "#88bb11", "#aaaadd"],
  2739: ["#11aaff", "#776611", "#ccccaa", "#ddaa88", "#229966"],
  2740: ["#dd6633", "#446600", "#99ee99", "#aaaa44", "#dd5577"],
  2741: ["#227799", "#11eebb", "#88ee99", "#0022dd", "#00ee99"],
  2742: ["#55cc44", "#bbaa99", "#99dd11", "#99ff00", "#bb3366"],
  2743: ["#cc55cc", "#774499", "#44bb99", "#ccaa66", "#3355ee"],
  2744: ["#ff1144", "#99cccc", "#bb8866", "#00cc55", "#dd5599"],
  2745: ["#11ff99", "#22ee77", "#9977ff", "#22cc11", "#ff99aa"],
  2746: ["#ffffaa", "#88bbff", "#aadd22", "#55ff77", "#884400"],
  2747: ["#66aa00", "#9911dd", "#bbff00", "#bb2255", "#556655"],
  2748: ["#ee0033", "#dd9977", "#3399ff", "#4411dd", "#aa9999"],
  2749: ["#bbccee", "#441166", "#eeeedd", "#3300bb", "#ff33cc"],
  2750: ["#ccbb00", "#33cc99", "#1122aa", "#66ccbb", "#eeccff"],
  2751: ["#1166dd", "#bbff22", "#00ccbb", "#bbbb00", "#ccccff"],
  2752: ["#eeddff", "#bb77ff", "#77bb22", "#dd4444", "#000033"],
  2753: ["#992277", "#008899", "#44ff88", "#77cc99", "#558822"],
  2754: ["#9955bb", "#2244dd", "#4455aa", "#4433dd", "#2244dd"],
  2755: ["#1111dd", "#77ccbb", "#33ddbb", "#ffaa33", "#77ffdd"],
  2756: ["#33aa55", "#335566", "#55ee55", "#001199", "#22bb44"],
  2757: ["#88ff22", "#00bbee", "#ff3322", "#6633cc", "#eeaaff"],
  2758: ["#8800aa", "#aaaa77", "#556633", "#77ffcc", "#66cc11"],
  2759: ["#cc88aa", "#33aa55", "#ff66bb", "#55eeee", "#337733"],
  2760: ["#ff9966", "#dd4455", "#ff66cc", "#99ffaa", "#000077"],
  2761: ["#ff5522", "#aa6611", "#55bb22", "#660099", "#ffff77"],
  2762: ["#4499cc", "#8877cc", "#771144", "#1122bb", "#222277"],
  2763: ["#55eebb", "#cc5566", "#cc77ee", "#440022", "#66dd11"],
  2764: ["#cc7777", "#2299dd", "#8822bb", "#22dd55", "#00ee66"],
  2765: ["#336677", "#ff6622", "#1188aa", "#66bbdd", "#557766"],
  2766: ["#99aa99", "#bb2233", "#ee44ee", "#00bb66", "#dd6611"],
  2767: ["#cc00ff", "#2299ee", "#660077", "#229922", "#cc5511"],
  2768: ["#33aa44", "#6699ff", "#7788aa", "#aa8844", "#2299bb"],
  2769: ["#888855", "#ffaa99", "#ee77cc", "#88ffdd", "#eeaa22"],
  2770: ["#2244bb", "#ffdd22", "#ff6666", "#ffcc22", "#554477"],
  2771: ["#aa55cc", "#665511", "#333399", "#ee0000", "#ee4499"],
  2772: ["#77ddaa", "#ff3388", "#99ddbb", "#7733bb", "#556622"],
  2773: ["#11eedd", "#338800", "#44ee88", "#7711ee", "#aaaacc"],
  2774: ["#1155aa", "#cc4477", "#4411ee", "#2277bb", "#22ccee"],
  2775: ["#eedd11", "#bbee33", "#cc11ee", "#cc99ff", "#44dddd"],
  2776: ["#eecc99", "#44ff22", "#9922cc", "#ff0088", "#44ffee"],
  2777: ["#7711ff", "#22aa55", "#ee3344", "#eeaa99", "#66ff88"],
  2778: ["#4455ff", "#447799", "#883322", "#22ee33", "#9933cc"],
  2779: ["#33aa99", "#3311cc", "#117733", "#ff0011", "#992244"],
  2780: ["#bbaaff", "#ff8811", "#88ee00", "#ff00ff", "#88cc88"],
  2781: ["#881100", "#0088dd", "#55aa33", "#11ffee", "#5500bb"],
  2782: ["#884477", "#ccbb33", "#8877dd", "#8877ff", "#ee0055"],
  2783: ["#777799", "#cccc33", "#77bb00", "#dd4466", "#77aa88"],
  2784: ["#665500", "#0088cc", "#ff11cc", "#228866", "#55ddcc"],
  2785: ["#6611dd", "#9988ee", "#11bb66", "#dd3366", "#ee3399"],
  2786: ["#66ddff", "#44eebb", "#7733aa", "#aabbee", "#dd66dd"],
  2787: ["#cc7733", "#5566ff", "#55ccee", "#331111", "#117788"],
  2788: ["#333366", "#aa55cc", "#dd9966", "#666666", "#ee11ff"],
  2789: ["#ee00ff", "#bbcc11", "#aa33dd", "#7788ee", "#ee99aa"],
  2790: ["#aabbbb", "#dd1111", "#88aa55", "#2244bb", "#5533ff"],
  2791: ["#887711", "#00ddcc", "#ee77ee", "#ff3399", "#441100"],
  2792: ["#bbaa33", "#bb99cc", "#998855", "#44ff77", "#889988"],
  2793: ["#1144cc", "#7799dd", "#885566", "#ddcc88", "#ffdd55"],
  2794: ["#001166", "#ddaa99", "#997744", "#007777", "#aa99cc"],
  2795: ["#55aa88", "#dd5533", "#aaccaa", "#337722", "#ff5500"],
  2796: ["#663399", "#99cc88", "#66aa66", "#113366", "#77ff66"],
  2797: ["#666666", "#bb99aa", "#ffffee", "#00ee66", "#ee6688"],
  2798: ["#111177", "#aa4466", "#ee44ff", "#66ee88", "#882211"],
  2799: ["#11ee88", "#3311bb", "#55dd33", "#883300", "#33ffff"],
  2800: ["#6655bb", "#aaffdd", "#ccaa77", "#1122dd", "#660066"],
  2801: ["#ddaa33", "#11ee88", "#66ccff", "#ee3377", "#dd22dd"],
  2802: ["#aacc44", "#ee0066", "#aaaa88", "#996644", "#993322"],
  2803: ["#ddeecc", "#44aa22", "#22bb55", "#0022aa", "#222244"],
  2804: ["#3344dd", "#ccddaa", "#cc6644", "#bbdd77", "#22aa99"],
  2805: ["#dddd99", "#33ff55", "#881122", "#cc7766", "#3355bb"],
  2806: ["#00aa11", "#778899", "#aa88ff", "#221122", "#007700"],
  2807: ["#dd3399", "#33ee33", "#337722", "#ee3344", "#2211ff"],
  2808: ["#ff99ff", "#aaaacc", "#2244cc", "#33aacc", "#dd44aa"],
  2809: ["#ee33ff", "#77cc55", "#0099cc", "#bbff00", "#7733bb"],
  2810: ["#77dd77", "#990000", "#5511aa", "#aa6655", "#ee5588"],
  2811: ["#881111", "#dd5555", "#aa8888", "#88bbee", "#cc4444"],
  2812: ["#664477", "#ff7766", "#eedd88", "#339966", "#5522cc"],
  2813: ["#aa00ee", "#aabbcc", "#cc7755", "#cc7733", "#bbffdd"],
  2814: ["#dd6633", "#eedd00", "#ffffbb", "#99dd33", "#880055"],
  2815: ["#aa4433", "#bb7755", "#558855", "#ffccff", "#8822ee"],
  2816: ["#006633", "#ccccee", "#cc9944", "#bb9977", "#001188"],
  2817: ["#5500bb", "#ccbb33", "#ccccff", "#6666ff", "#aa2266"],
  2818: ["#ee44ee", "#bbdd88", "#ffaadd", "#445511", "#aaee77"],
  2819: ["#ee8866", "#aa22ff", "#00aacc", "#117788", "#777799"],
  2820: ["#5599ff", "#ff00ee", "#77dd22", "#ddee88", "#dd00cc"],
  2821: ["#ee5511", "#ff6644", "#444455", "#002299", "#88aa77"],
  2822: ["#00ee66", "#0044ee", "#66dd88", "#221199", "#eeccee"],
  2823: ["#cc33aa", "#220066", "#ee8877", "#883355", "#ee3377"],
  2824: ["#ee0011", "#aa3388", "#cc5588", "#225500", "#5544aa"],
  2825: ["#223333", "#00ff66", "#aa4422", "#ee55cc", "#bb2244"],
  2826: ["#bb33bb", "#11dd22", "#6699cc", "#008866", "#ffdd66"],
  2827: ["#558844", "#55aaee", "#cc3300", "#ffcc99", "#ff0022"],
  2828: ["#33ff11", "#22eeee", "#3333ee", "#ccff11", "#ff8822"],
  2829: ["#99bb77", "#aabb88", "#ee55bb", "#998888", "#99bb33"],
  2830: ["#4433ee", "#cceecc", "#99cccc", "#2299ee", "#99aaaa"],
  2831: ["#ccbb55", "#220022", "#5577dd", "#558899", "#2288dd"],
  2832: ["#6633bb", "#dd3366", "#ddbbaa", "#99eeaa", "#dd5500"],
  2833: ["#ccbbff", "#5566aa", "#5555ff", "#3388bb", "#220066"],
  2834: ["#aa22bb", "#33ff55", "#cc33dd", "#aa2200", "#88aaaa"],
  2835: ["#0077ff", "#99ccee", "#0099ee", "#44ee44", "#eebb88"],
  2836: ["#ee8899", "#440066", "#331100", "#9922dd", "#99ff44"],
  2837: ["#55dd11", "#ee9900", "#cccc33", "#77ee00", "#11bbdd"],
  2838: ["#882222", "#55ee88", "#002233", "#ccccdd", "#448833"],
  2839: ["#330022", "#44ff11", "#dd3366", "#bb7711", "#33ee00"],
  2840: ["#446644", "#55eebb", "#ddeeee", "#44eebb", "#66aa99"],
  2841: ["#669955", "#aabb66", "#77aaee", "#996622", "#aa6655"],
  2842: ["#2200bb", "#66ee88", "#11ee77", "#dd3399", "#cc22ee"],
  2843: ["#3311dd", "#6600dd", "#ff6677", "#6688aa", "#00ffbb"],
  2844: ["#22dd66", "#33ee33", "#ccffcc", "#bb6622", "#bb22dd"],
  2845: ["#bbbb77", "#77ee22", "#888833", "#3399cc", "#22cccc"],
  2846: ["#229977", "#224466", "#668899", "#667755", "#662266"],
  2847: ["#110044", "#22ddcc", "#668822", "#994444", "#bb88cc"],
  2848: ["#228888", "#aa4488", "#66bb88", "#aa44ff", "#ddffbb"],
  2849: ["#ddbb55", "#3355aa", "#669999", "#003399", "#440000"],
  2850: ["#11ccee", "#550088", "#cc33ee", "#eeddbb", "#aa0022"],
  2851: ["#3355ee", "#88aa77", "#99dd77", "#bbff22", "#cc0088"],
  2852: ["#99ddaa", "#008866", "#33ee11", "#995555", "#557744"],
  2853: ["#662255", "#aaccbb", "#4499dd", "#550033", "#662211"],
  2854: ["#11dd00", "#ee33dd", "#ddcccc", "#55aa00", "#445511"],
  2855: ["#449900", "#99aa22", "#5533dd", "#ccbbaa", "#33ffff"],
  2856: ["#775588", "#cccc88", "#7755bb", "#333322", "#aa1111"],
  2857: ["#11ee55", "#aaaaaa", "#119966", "#66ddcc", "#442200"],
  2858: ["#66aadd", "#3366ee", "#7788aa", "#88aa22", "#ddbb33"],
  2859: ["#ff5566", "#11bb00", "#66ff22", "#aa7777", "#44ffbb"],
  2860: ["#ccaa22", "#884422", "#3399bb", "#11bbbb", "#dd5500"],
  2861: ["#cc3399", "#5544ee", "#113311", "#889988", "#eeeecc"],
  2862: ["#00ffaa", "#6600ff", "#55aa22", "#aacccc", "#994455"],
  2863: ["#888877", "#447700", "#444411", "#eeaa44", "#ccdd33"],
  2864: ["#11ee66", "#771166", "#bbcc44", "#11aaee", "#aa99cc"],
  2865: ["#2200bb", "#33ccaa", "#2222ee", "#cccc11", "#22cc11"],
  2866: ["#5533cc", "#88dd66", "#881111", "#ee11aa", "#003355"],
  2867: ["#11eedd", "#990044", "#1155cc", "#555577", "#dddd11"],
  2868: ["#556611", "#0066dd", "#999944", "#1100ff", "#bb9944"],
  2869: ["#7766cc", "#dd00bb", "#1111dd", "#aa2200", "#ffdd22"],
  2870: ["#bb5599", "#11ffbb", "#888822", "#ee7755", "#0044ff"],
  2871: ["#11ccee", "#aa88dd", "#44cc33", "#00ee88", "#dd9922"],
  2872: ["#eebbbb", "#2255dd", "#339988", "#bb8899", "#990099"],
  2873: ["#00bbaa", "#8877bb", "#33cc66", "#993333", "#7722ee"],
  2874: ["#eeff77", "#ee8855", "#ff8833", "#00bbcc", "#996600"],
  2875: ["#334444", "#aabbaa", "#33aadd", "#228822", "#aa7788"],
  2876: ["#7700ee", "#ee4488", "#ee55aa", "#bbffcc", "#44ee77"],
  2877: ["#115511", "#dd8866", "#22bb99", "#22cc22", "#6622dd"],
  2878: ["#cc9955", "#ff22aa", "#77aa88", "#448899", "#ee3333"],
  2879: ["#115588", "#cc4433", "#ee44ff", "#8888cc", "#ff00ff"],
  2880: ["#aa4411", "#11cc88", "#55aa77", "#5500aa", "#aa0044"],
  2881: ["#ee6677", "#55ee33", "#229944", "#33eeee", "#66ff11"],
  2882: ["#dd3399", "#000033", "#33ee11", "#ff9900", "#447755"],
  2883: ["#7733aa", "#335500", "#ccff66", "#551166", "#998800"],
  2884: ["#337755", "#99aadd", "#6655bb", "#115577", "#dd4466"],
  2885: ["#aa22ff", "#99eeee", "#772299", "#ee8855", "#cccc66"],
  2886: ["#eeccee", "#668833", "#ee3355", "#ffcc33", "#002266"],
  2887: ["#ffaacc", "#000011", "#4444dd", "#559933", "#3377bb"],
  2888: ["#ffffbb", "#88bb33", "#33eedd", "#227755", "#225522"],
  2889: ["#99bbdd", "#88aa66", "#aa77dd", "#4411aa", "#339933"],
  2890: ["#bb88cc", "#ffee66", "#cc22dd", "#22ccbb", "#339988"],
  2891: ["#993366", "#ff6688", "#5588ee", "#ff44ee", "#114422"],
  2892: ["#aaffcc", "#6633dd", "#889977", "#557733", "#885500"],
  2893: ["#ffbb00", "#ee2266", "#22ffbb", "#ee2255", "#665566"],
  2894: ["#2244cc", "#4444dd", "#11ff99", "#ee1188", "#8899ee"],
  2895: ["#ffff66", "#551122", "#55ccff", "#cccc99", "#ddee99"],
  2896: ["#991144", "#ffdd77", "#007733", "#bb0022", "#8811aa"],
  2897: ["#ff3300", "#44ee22", "#33dd11", "#3344bb", "#22dd55"],
  2898: ["#dd99cc", "#9922aa", "#dd66bb", "#55dddd", "#ee0011"],
  2899: ["#ffaa00", "#aaaacc", "#99bbbb", "#8877aa", "#cccc33"],
  2900: ["#ffff00", "#22aa66", "#ffaaff", "#118855", "#ddddbb"],
  2901: ["#55ff88", "#778877", "#ccaaaa", "#55cc44", "#3333ff"],
  2902: ["#bbbbee", "#00cc99", "#ddffff", "#ff77ee", "#bb8800"],
  2903: ["#cc77bb", "#aa4444", "#774400", "#0066cc", "#ee7733"],
  2904: ["#ff11ee", "#ffcc66", "#33cc77", "#669911", "#117733"],
  2905: ["#ffaa11", "#dd11dd", "#bbbbee", "#ccaa11", "#aaff55"],
  2906: ["#88ccee", "#ee1100", "#99ccff", "#8855ff", "#ff66dd"],
  2907: ["#eeee77", "#116666", "#993333", "#8855bb", "#229922"],
  2908: ["#1122dd", "#bb11ee", "#ccbbaa", "#885588", "#4444bb"],
  2909: ["#994499", "#cc5544", "#99bb00", "#11ddaa", "#88aabb"],
  2910: ["#11ee11", "#dd8822", "#bb6644", "#447711", "#8899ff"],
  2911: ["#005577", "#55bb00", "#77bb77", "#66aa66", "#22bb11"],
  2912: ["#ffbb55", "#338811", "#229977", "#778800", "#cc77dd"],
  2913: ["#4422bb", "#88bbff", "#44ffcc", "#aa9911", "#22cc77"],
  2914: ["#dd5588", "#ee7733", "#77ff00", "#dd66ee", "#bb0088"],
  2915: ["#ff9955", "#5500ff", "#77bb66", "#77ddee", "#330022"],
  2916: ["#ee4466", "#993333", "#3399ff", "#1100bb", "#557744"],
  2917: ["#773322", "#555522", "#445511", "#775566", "#995544"],
  2918: ["#998844", "#44bb22", "#663322", "#cc4400", "#66ccbb"],
  2919: ["#9911aa", "#665522", "#771144", "#eebb55", "#116622"],
  2920: ["#118800", "#9911bb", "#ff9955", "#77cc22", "#dd8833"],
  2921: ["#ccdd44", "#77eebb", "#77dd77", "#336699", "#bb5500"],
  2922: ["#8844bb", "#336699", "#774444", "#669999", "#ccff44"],
  2923: ["#669988", "#aa9988", "#ddbb55", "#eeccee", "#ddaa00"],
  2924: ["#885566", "#4466aa", "#009999", "#aa88bb", "#dddd55"],
  2925: ["#eeccaa", "#0055ff", "#558877", "#aa66bb", "#77ee33"],
  2926: ["#99ee11", "#cceecc", "#882299", "#991100", "#44cc88"],
  2927: ["#cc88cc", "#2266cc", "#aa99bb", "#aa22cc", "#ee3333"],
  2928: ["#660055", "#aa3377", "#dd6655", "#7711ff", "#dd5555"],
  2929: ["#3333dd", "#3322bb", "#009955", "#11aa44", "#22bbbb"],
  2930: ["#669900", "#22bb44", "#ffcc00", "#dd8877", "#880077"],
  2931: ["#55ff22", "#bb99dd", "#99cc11", "#aa99bb", "#999933"],
  2932: ["#33aa22", "#aaccff", "#ff2255", "#33ee22", "#aa9955"],
  2933: ["#6699bb", "#880033", "#aa2244", "#eedd00", "#88ccee"],
  2934: ["#bb1100", "#aaaa44", "#aacc44", "#ffbb11", "#ee2233"],
  2935: ["#889900", "#ee2222", "#eeaaff", "#99cc99", "#551100"],
  2936: ["#8811dd", "#cc66ee", "#99dd00", "#99ff66", "#4422ee"],
  2937: ["#888877", "#1177cc", "#55ccdd", "#ee9988", "#883377"],
  2938: ["#22cc77", "#7711dd", "#bbee99", "#aaeecc", "#ee2277"],
  2939: ["#331188", "#007700", "#882255", "#ffaadd", "#00dd33"],
  2940: ["#440077", "#dd1144", "#8811ee", "#7744aa", "#8899ff"],
  2941: ["#1199bb", "#ffff55", "#112277", "#559944", "#33ee77"],
  2942: ["#22aadd", "#11cc55", "#8866ff", "#66bb22", "#55bbbb"],
  2943: ["#551155", "#ff5544", "#cc1188", "#001166", "#ff6644"],
  2944: ["#eeaacc", "#ff0033", "#774411", "#440066", "#1188cc"],
  2945: ["#33ff66", "#77aa66", "#ee44cc", "#888844", "#aacc11"],
  2946: ["#aa00ff", "#222222", "#22aadd", "#66dd99", "#331199"],
  2947: ["#227744", "#550033", "#bb9922", "#77aa77", "#00cc00"],
  2948: ["#2299bb", "#ee1144", "#ffcc99", "#991188", "#003388"],
  2949: ["#99bb88", "#118800", "#550077", "#11aa22", "#ee8822"],
  2950: ["#ee5533", "#ddaa44", "#779933", "#226644", "#4477ee"],
  2951: ["#44ccff", "#44cccc", "#11ff66", "#aa7700", "#226655"],
  2952: ["#448866", "#114488", "#11ee88", "#dddddd", "#885500"],
  2953: ["#ccaaff", "#66ddee", "#008800", "#99cc11", "#9999ee"],
  2954: ["#66dd11", "#3344ff", "#223311", "#442222", "#ee9955"],
  2955: ["#551188", "#997766", "#7722bb", "#ff2277", "#223344"],
  2956: ["#22ddaa", "#bb22ff", "#ddffff", "#3399cc", "#ee11ff"],
  2957: ["#4433ee", "#aa5511", "#3399bb", "#44aaaa", "#ddee99"],
  2958: ["#aacc11", "#00ffff", "#337777", "#dd0099", "#331177"],
  2959: ["#0000cc", "#cceeff", "#aa2211", "#ff4400", "#ffcc00"],
  2960: ["#bb33dd", "#eeff99", "#7788cc", "#557788", "#118888"],
  2961: ["#cc8811", "#88aabb", "#44ffbb", "#0044cc", "#999922"],
  2962: ["#11ffbb", "#ddbbee", "#88ee99", "#66cc44", "#33eecc"],
  2963: ["#8811cc", "#223311", "#770022", "#cc99aa", "#116699"],
  2964: ["#00ee00", "#771199", "#118855", "#33dd33", "#003388"],
  2965: ["#881166", "#dd11bb", "#77dd88", "#88ee55", "#2211aa"],
  2966: ["#99aa00", "#559977", "#11bbff", "#bbdd33", "#334411"],
  2967: ["#00eecc", "#ee7722", "#4455aa", "#88ccaa", "#559955"],
  2968: ["#ddbb66", "#4433cc", "#ee44ff", "#cc2266", "#aa3322"],
  2969: ["#55dd11", "#aa5555", "#cc2233", "#778877", "#33cc66"],
  2970: ["#66aa55", "#9944ee", "#bbdd66", "#888844", "#778833"],
  2971: ["#ffbb00", "#6655dd", "#aa33bb", "#668866", "#55eebb"],
  2972: ["#2266ff", "#11aacc", "#ffbbcc", "#448844", "#114400"],
  2973: ["#336655", "#ddcc33", "#dd7799", "#3344aa", "#449933"],
  2974: ["#770000", "#bbeebb", "#331111", "#ee1133", "#771166"],
  2975: ["#222299", "#eeffff", "#dd88ff", "#ffaacc", "#aa0088"],
  2976: ["#ff8899", "#dd4499", "#cc22bb", "#228811", "#ee0011"],
  2977: ["#4400dd", "#bb2222", "#992266", "#ddee99", "#220088"],
  2978: ["#99ee77", "#777777", "#992277", "#88ddcc", "#aa7711"],
  2979: ["#bb33bb", "#ee3322", "#55bbee", "#223322", "#aa5588"],
  2980: ["#ff7744", "#33ddff", "#00aacc", "#eecc33", "#ff5555"],
  2981: ["#3388dd", "#66ccff", "#3399ee", "#665533", "#00cc77"],
  2982: ["#cc9933", "#ccaa33", "#dd7755", "#77bb88", "#bbffff"],
  2983: ["#7788ee", "#bb2233", "#99cc55", "#1111dd", "#552299"],
  2984: ["#bbee00", "#778833", "#cc9955", "#00bb11", "#eeee77"],
  2985: ["#eebbdd", "#8811ee", "#bb8877", "#008888", "#aaffbb"],
  2986: ["#2288ee", "#77cccc", "#88ff55", "#22aaee", "#00bb55"],
  2987: ["#77cc77", "#331100", "#1133aa", "#cc3355", "#995555"],
  2988: ["#ddbbaa", "#7744aa", "#bb66ee", "#dd7799", "#119977"],
  2989: ["#22ddcc", "#003377", "#aa2244", "#77ee88", "#99ff44"],
  2990: ["#ee8833", "#002288", "#ddff11", "#ccee99", "#dd9988"],
  2991: ["#22dd77", "#ddeebb", "#6666dd", "#99bb11", "#00bb11"],
  2992: ["#889955", "#cc8888", "#220000", "#00dd33", "#6644cc"],
  2993: ["#eecccc", "#aa5533", "#dd4444", "#bb8855", "#ff55cc"],
  2994: ["#00ee77", "#88aa55", "#331166", "#ffaa11", "#008844"],
  2995: ["#ee22aa", "#339999", "#ff99dd", "#116666", "#11aacc"],
  2996: ["#ccffff", "#aa11aa", "#221199", "#aaeeff", "#5588bb"],
  2997: ["#ddaa88", "#aa2233", "#dd77aa", "#00eeff", "#22aa66"],
  2998: ["#22bb11", "#559999", "#ffeeee", "#9911dd", "#bbddff"],
  2999: ["#996622", "#66bbee", "#9933bb", "#22dd44", "#eedd99"],
  3000: ["#115511", "#1122ff", "#33ff99", "#66ee99", "#449933"],
  3001: ["#6633ee", "#991144", "#7744ee", "#cc2211", "#ee55aa"],
  3002: ["#cc8822", "#333366", "#ff7744", "#338800", "#ff5555"],
  3003: ["#00ff00", "#4422cc", "#ff3300", "#6600ff", "#ee1122"],
  3004: ["#aabbbb", "#ff5599", "#4422ee", "#226666", "#bbdd66"],
  3005: ["#aa9966", "#aa9944", "#221122", "#aa99bb", "#ee4411"],
  3006: ["#bb9988", "#993377", "#ff6688", "#88ee44", "#dd77ff"],
  3007: ["#cc4488", "#33ccaa", "#3311aa", "#881144", "#005555"],
  3008: ["#bb0099", "#886600", "#4455bb", "#559944", "#887744"],
  3009: ["#8855dd", "#ddff99", "#ee44cc", "#6677bb", "#bbccff"],
  3010: ["#66ee11", "#9922ff", "#bbaaff", "#6677ee", "#88ee11"],
  3011: ["#ffcccc", "#bb44cc", "#aaddbb", "#8800dd", "#ff8899"],
  3012: ["#7700cc", "#77dd99", "#00ffbb", "#2266aa", "#882266"],
  3013: ["#336633", "#446611", "#bb9900", "#44ee77", "#998855"],
  3014: ["#663322", "#00cccc", "#7799bb", "#668844", "#557722"],
  3015: ["#bb0066", "#cc66ff", "#ccbb44", "#00ff00", "#4477bb"],
  3016: ["#7700ff", "#005522", "#3311bb", "#55ee00", "#ffddaa"],
  3017: ["#ffaa33", "#558800", "#6622bb", "#eeddbb", "#aa33aa"],
  3018: ["#bb6600", "#ddddaa", "#99aa22", "#00aabb", "#22ee11"],
  3019: ["#5522dd", "#66aabb", "#99ffee", "#1111aa", "#3355ee"],
  3020: ["#dd5599", "#ccaabb", "#aabbdd", "#22bb22", "#990088"],
  3021: ["#11ddee", "#113366", "#ccbbff", "#ccaa99", "#888822"],
  3022: ["#994455", "#ccee00", "#bb33ee", "#22dd55", "#ccbb11"],
  3023: ["#337777", "#cc2299", "#55aacc", "#110055", "#ff7799"],
  3024: ["#9999ff", "#7755dd", "#7733bb", "#3388ff", "#dd8822"],
  3025: ["#aaee33", "#eeaadd", "#cc66ee", "#bbee88", "#7700aa"],
  3026: ["#ffaa11", "#449977", "#bb0066", "#ff1111", "#ee55cc"],
  3027: ["#66ff99", "#bb7744", "#ff00cc", "#bbff22", "#116677"],
  3028: ["#6677dd", "#447722", "#aaddff", "#55aa66", "#11aa22"],
  3029: ["#55cc11", "#552255", "#9900ff", "#11ee77", "#9911aa"],
  3030: ["#aa6622", "#aaaa22", "#009911", "#99ee77", "#ff8811"],
  3031: ["#bb4477", "#aa1177", "#cc9966", "#448800", "#66cc44"],
  3032: ["#11aabb", "#22ff00", "#445511", "#ff6699", "#4422aa"],
  3033: ["#aaccee", "#777755", "#00cc11", "#44cc99", "#6655ff"],
  3034: ["#449933", "#ccee11", "#ddffbb", "#44aa99", "#ff5599"],
  3035: ["#777722", "#117711", "#7755dd", "#223333", "#9955cc"],
  3036: ["#bb77ff", "#995555", "#9933dd", "#ddffee", "#bb22ee"],
  3037: ["#6666ee", "#8800bb", "#4411cc", "#aadd55", "#44ee22"],
  3038: ["#ff22bb", "#ddff33", "#44cc66", "#cc9900", "#8877ff"],
  3039: ["#333366", "#bb99ee", "#dd3322", "#ee99ee", "#335500"],
  3040: ["#99ff99", "#88bb77", "#44bbff", "#ffaadd", "#aa00ee"],
  3041: ["#aaee88", "#004477", "#992222", "#33ffcc", "#ddee00"],
  3042: ["#339922", "#aa7755", "#3300ff", "#bbccdd", "#002266"],
  3043: ["#7722cc", "#558844", "#2277dd", "#008800", "#bbff88"],
  3044: ["#dd5500", "#6655ff", "#772299", "#774466", "#cc2266"],
  3045: ["#440000", "#bb55aa", "#99cc00", "#00ddcc", "#eeccff"],
  3046: ["#ccff77", "#773366", "#ffcccc", "#bb6633", "#551199"],
  3047: ["#22ff22", "#225566", "#aa3355", "#333300", "#666600"],
  3048: ["#44aa99", "#ee4411", "#77ff99", "#886666", "#666699"],
  3049: ["#997799", "#dd9966", "#dd0033", "#223333", "#ffccaa"],
  3050: ["#339922", "#bb6677", "#aa77aa", "#7733aa", "#bbeebb"],
  3051: ["#224488", "#cc8800", "#4433ff", "#bbaa88", "#ddff33"],
  3052: ["#aaaa11", "#5522ee", "#0077aa", "#442255", "#3311bb"],
  3053: ["#bbcc55", "#6611dd", "#666666", "#004466", "#6677ee"],
  3054: ["#6644ee", "#66ccdd", "#22aaee", "#22bb44", "#bbcc33"],
  3055: ["#6633dd", "#aa7722", "#66ffdd", "#8811ff", "#118822"],
  3056: ["#eeff77", "#0033cc", "#22bb33", "#996699", "#dd77aa"],
  3057: ["#aa9999", "#00aa44", "#ddcc44", "#bb1166", "#55bb22"],
  3058: ["#66ff66", "#cc00cc", "#44aa66", "#66ffbb", "#6644dd"],
  3059: ["#aaffff", "#cc0022", "#77cc00", "#aa0055", "#0022cc"],
  3060: ["#6622dd", "#559911", "#aa9944", "#ffff22", "#9922ff"],
  3061: ["#556622", "#ffee33", "#dddd11", "#553388", "#ddff11"],
  3062: ["#662299", "#991155", "#335544", "#ccddff", "#88bb99"],
  3063: ["#001122", "#66ff22", "#66aa22", "#7766aa", "#6688dd"],
  3064: ["#dd3333", "#ccdd44", "#77bbaa", "#eeddff", "#bb88bb"],
  3065: ["#333311", "#aa4466", "#888888", "#772288", "#6699ee"],
  3066: ["#dd00aa", "#aabbdd", "#55ddcc", "#88bbee", "#778844"],
  3067: ["#4400bb", "#9922bb", "#997711", "#00cc66", "#2266bb"],
  3068: ["#bb4499", "#558855", "#663300", "#99bb33", "#eecc22"],
  3069: ["#dd8800", "#ee1144", "#33ddee", "#666633", "#330055"],
  3070: ["#ffcc66", "#4422aa", "#773322", "#ff11dd", "#bb77ff"],
  3071: ["#99aa33", "#aa3377", "#331100", "#99ccdd", "#3399cc"],
  3072: ["#559933", "#ddff11", "#2277ff", "#992244", "#77ddee"],
  3073: ["#338800", "#55dd77", "#33ee55", "#ffffaa", "#ddee77"],
  3074: ["#99ff44", "#44ddee", "#6699aa", "#44cc00", "#777766"],
  3075: ["#77cc88", "#eeff00", "#dd22cc", "#eeaaee", "#11ee11"],
  3076: ["#003388", "#6633bb", "#2288dd", "#ee0000", "#772233"],
  3077: ["#bbee00", "#bb4444", "#bb4488", "#6666bb", "#77bb88"],
  3078: ["#7755aa", "#bb6611", "#cc77bb", "#220044", "#bb0066"],
  3079: ["#aa5566", "#6600bb", "#111111", "#ffeedd", "#88ff88"],
  3080: ["#885588", "#22ccee", "#00dd77", "#776622", "#44ffdd"],
  3081: ["#2299aa", "#bb3355", "#666688", "#bb8822", "#112233"],
  3082: ["#66dd77", "#4466cc", "#6611cc", "#88bb00", "#aa33dd"],
  3083: ["#ee33bb", "#aa2255", "#7711ee", "#cc6655", "#bb5555"],
  3084: ["#dd88dd", "#779900", "#33aabb", "#227733", "#005500"],
  3085: ["#ee3377", "#4422aa", "#00dd33", "#44bb77", "#551188"],
  3086: ["#6633bb", "#3300ee", "#0044cc", "#aaee66", "#1199bb"],
  3087: ["#cc5555", "#33ff66", "#aa6655", "#339944", "#115533"],
  3088: ["#aa5544", "#11cc44", "#22ee22", "#ff44bb", "#99eeff"],
  3089: ["#66ee22", "#6611ee", "#eeff33", "#993377", "#33dd00"],
  3090: ["#cc0011", "#7755aa", "#441122", "#330000", "#ee4411"],
  3091: ["#ffffdd", "#440099", "#aadd77", "#558822", "#eeee22"],
  3092: ["#bb3311", "#dd5577", "#7799bb", "#ee1144", "#77ee33"],
  3093: ["#55aa77", "#22cc44", "#001144", "#0055dd", "#aaaa55"],
  3094: ["#449966", "#ffcccc", "#5577bb", "#dd5555", "#335577"],
  3095: ["#221144", "#bb7777", "#5500bb", "#778888", "#772222"],
  3096: ["#330088", "#aaee11", "#66dd11", "#bbcc66", "#66bbdd"],
  3097: ["#11bb88", "#33ccee", "#33ffaa", "#00dd22", "#00cc44"],
  3098: ["#11bb00", "#ff4466", "#00ddcc", "#44bb55", "#8866ee"],
  3099: ["#55aa77", "#2288ee", "#22aa99", "#22ddaa", "#66bbdd"],
  3100: ["#99cc88", "#aaee66", "#88ff00", "#885522", "#006699"],
  3101: ["#ff55cc", "#cc2200", "#eecc88", "#eeaa55", "#7755ff"],
  3102: ["#666633", "#ffbbff", "#bbaaee", "#9911aa", "#000033"],
  3103: ["#44dd00", "#3333ff", "#dd8811", "#6644bb", "#22ddbb"],
  3104: ["#2266ff", "#ffbbbb", "#ff1133", "#eeee44", "#11cccc"],
  3105: ["#771166", "#aa22ff", "#66cc99", "#aa66aa", "#333355"],
  3106: ["#ffccff", "#ee6611", "#669999", "#bb8811", "#8800ff"],
  3107: ["#ccbb88", "#ee99ee", "#ff66aa", "#88ff33", "#aa1188"],
  3108: ["#551199", "#7755ee", "#ff00bb", "#ffee55", "#cc55cc"],
  3109: ["#dd55aa", "#22ddcc", "#aa00bb", "#8844ff", "#22aa66"],
  3110: ["#113377", "#cc1188", "#11ccbb", "#885522", "#ff1188"],
  3111: ["#3355aa", "#4488cc", "#22ee99", "#22ff55", "#22aacc"],
  3112: ["#cccc88", "#cc5566", "#556688", "#4433dd", "#ee66ff"],
  3113: ["#111144", "#22dd33", "#aa11cc", "#ee5577", "#6677ff"],
  3114: ["#88aadd", "#44ffdd", "#556677", "#115566", "#bb77ff"],
  3115: ["#884444", "#995566", "#aa11dd", "#bb4466", "#66ee11"],
  3116: ["#ff00ee", "#cc1199", "#88aacc", "#999933", "#bb2222"],
  3117: ["#44ccff", "#88bbdd", "#441166", "#bb7722", "#eebbcc"],
  3118: ["#0000ee", "#44aa11", "#bb66aa", "#226600", "#44dd55"],
  3119: ["#aaee55", "#bb11bb", "#7722aa", "#dd55bb", "#552299"],
  3120: ["#999900", "#44ee22", "#771199", "#66bb33", "#aa33ee"],
  3121: ["#bb7777", "#229955", "#88aa77", "#667777", "#00bb99"],
  3122: ["#ff5500", "#11ddcc", "#882266", "#33dd77", "#cc2200"],
  3123: ["#ddcc99", "#331155", "#554422", "#bb55dd", "#33ee22"],
  3124: ["#4444dd", "#bb6622", "#dd77dd", "#99ee33", "#77ffee"],
  3125: ["#ccaa33", "#00aa22", "#ff44cc", "#88dd33", "#117744"],
  3126: ["#776633", "#11ff88", "#3311ee", "#22dd44", "#4488aa"],
  3127: ["#44ccff", "#ee7777", "#333344", "#66ee99", "#99ff00"],
  3128: ["#4466dd", "#88bb33", "#88ffcc", "#dd11ff", "#ff2266"],
  3129: ["#117722", "#775588", "#33ffcc", "#55bb55", "#440033"],
  3130: ["#ffeeaa", "#3377ff", "#7777ee", "#ee8855", "#22eeff"],
  3131: ["#ff77dd", "#eeff00", "#558899", "#ee2266", "#0033cc"],
  3132: ["#662200", "#667788", "#aa1122", "#221166", "#dd7744"],
  3133: ["#44eecc", "#6622bb", "#440033", "#bb4433", "#eeff77"],
  3134: ["#22bb00", "#6677dd", "#ee66bb", "#ff3322", "#bbbb00"],
  3135: ["#00aa33", "#227799", "#111166", "#882266", "#dd1133"],
  3136: ["#007755", "#bb5500", "#cccc88", "#9988dd", "#ffccff"],
  3137: ["#11bb11", "#442255", "#7733ee", "#5522bb", "#889977"],
  3138: ["#6666dd", "#66ffaa", "#0066bb", "#aa55aa", "#665533"],
  3139: ["#66aa00", "#cccc77", "#77eeaa", "#00aaaa", "#6688ff"],
  3140: ["#77ff99", "#44ddcc", "#114411", "#668844", "#9944ee"],
  3141: ["#662277", "#885577", "#77bb00", "#33eeff", "#881144"],
  3142: ["#aa66dd", "#992244", "#88ee66", "#bb1155", "#1111aa"],
  3143: ["#cccccc", "#8833aa", "#bb55ff", "#ff4411", "#9911bb"],
  3144: ["#dd7766", "#aaaa33", "#bbdd44", "#774455", "#00eeaa"],
  3145: ["#44aa88", "#5500ee", "#11bb66", "#220000", "#ffffdd"],
  3146: ["#3388bb", "#aaaa77", "#ee6655", "#1133aa", "#776622"],
  3147: ["#bbcc00", "#22bb99", "#7777cc", "#553322", "#cccc44"],
  3148: ["#11ee66", "#bb9933", "#88ee33", "#ff6644", "#44eeee"],
  3149: ["#dd00aa", "#ccdddd", "#ffcc22", "#558811", "#223333"],
  3150: ["#777755", "#ff22dd", "#99dd99", "#dd66bb", "#ffee44"],
  3151: ["#33bb66", "#ffdd77", "#99ee99", "#117755", "#887755"],
  3152: ["#ff6655", "#5555dd", "#eeeebb", "#dd66ee", "#668855"],
  3153: ["#446688", "#11aa44", "#77cc88", "#ff6633", "#990055"],
  3154: ["#2277cc", "#bb00dd", "#55ee00", "#774433", "#669922"],
  3155: ["#bb4433", "#118833", "#cc44bb", "#77eecc", "#999922"],
  3156: ["#ddffcc", "#cc11cc", "#22aabb", "#22ff88", "#dd44dd"],
  3157: ["#00cc66", "#00aa88", "#aa1177", "#aa8855", "#ee3344"],
  3158: ["#ee1133", "#0088aa", "#cc6699", "#9955ff", "#ffff55"],
  3159: ["#331144", "#66aaaa", "#66ddee", "#887744", "#225577"],
  3160: ["#446655", "#dd5555", "#aaaa44", "#aa33dd", "#dd33bb"],
  3161: ["#775555", "#cc66ee", "#889900", "#884411", "#889955"],
  3162: ["#cccc88", "#cc3388", "#0044aa", "#bbee77", "#dd7766"],
  3163: ["#000055", "#227777", "#22aa33", "#99bbcc", "#bb4422"],
  3164: ["#6666cc", "#773311", "#663366", "#0033cc", "#99ee11"],
  3165: ["#dd8811", "#88ff11", "#bb4499", "#ee4411", "#aa7733"],
  3166: ["#228888", "#55ffcc", "#9977aa", "#6611ff", "#77eedd"],
  3167: ["#99cc44", "#dd88dd", "#11ff33", "#112299", "#99ff88"],
  3168: ["#cc0055", "#bb22ee", "#ff6655", "#6611cc", "#cc7733"],
  3169: ["#662299", "#772266", "#99ddff", "#dd1122", "#aa77dd"],
  3170: ["#224488", "#8899ee", "#77cc77", "#bb22bb", "#11dd33"],
  3171: ["#9933ff", "#223399", "#221100", "#663355", "#33dd55"],
  3172: ["#228833", "#22aabb", "#33cc44", "#aa7744", "#cc0044"],
  3173: ["#cc00ee", "#66cc11", "#aaaa66", "#11bb88", "#ee2288"],
  3174: ["#993366", "#111100", "#ff00cc", "#88ddcc", "#7744bb"],
  3175: ["#dd2266", "#11cc44", "#cc44bb", "#eeaaee", "#2244cc"],
  3176: ["#ee3399", "#991122", "#aa2288", "#33ddaa", "#7700ee"],
  3177: ["#dd6644", "#99bbaa", "#4488bb", "#dd6699", "#1188bb"],
  3178: ["#bbbb22", "#888822", "#ffddcc", "#44ffdd", "#ff22dd"],
  3179: ["#aabb66", "#5577aa", "#66dd44", "#bb33cc", "#00dddd"],
  3180: ["#667766", "#885555", "#eeaa00", "#7766bb", "#55ffaa"],
  3181: ["#990099", "#7799cc", "#6688ff", "#cc33bb", "#2255ff"],
  3182: ["#0066aa", "#665566", "#6611bb", "#0077bb", "#44aa77"],
  3183: ["#773322", "#99bb77", "#773388", "#99ee11", "#6600ee"],
  3184: ["#aaeebb", "#ddbb99", "#99ee88", "#aaffdd", "#330099"],
  3185: ["#88aa88", "#5577bb", "#11bb11", "#ee6622", "#44dd44"],
  3186: ["#aaaa99", "#ddddee", "#eeddcc", "#99bb00", "#ffff55"],
  3187: ["#cc00ff", "#ee1111", "#dd3366", "#993388", "#22ff00"],
  3188: ["#dd44ff", "#1111dd", "#55ddbb", "#226677", "#bb1122"],
  3189: ["#339933", "#889966", "#ee99bb", "#773311", "#bbeebb"],
  3190: ["#1144aa", "#228877", "#2266bb", "#44ee88", "#110000"],
  3191: ["#550077", "#330000", "#9988bb", "#aaee22", "#779977"],
  3192: ["#8833dd", "#66ee11", "#339911", "#3355ff", "#ee8866"],
  3193: ["#772200", "#bbbb77", "#9999bb", "#aa11cc", "#666699"],
  3194: ["#dd5533", "#553366", "#771166", "#cc11aa", "#1133ff"],
  3195: ["#337700", "#ddee44", "#11ff77", "#7722ff", "#662200"],
  3196: ["#ddbb44", "#881111", "#777744", "#888855", "#22bb00"],
  3197: ["#1188cc", "#332255", "#77ffff", "#339933", "#dd0033"],
  3198: ["#ffff44", "#223300", "#888822", "#99dd99", "#eeaa66"],
  3199: ["#bb33dd", "#00bbcc", "#ee4411", "#775577", "#aa66dd"],
  3200: ["#00ffee", "#dd1177", "#559999", "#6655aa", "#bbff66"],
  3201: ["#77dd22", "#bbaaee", "#999933", "#dd44aa", "#889999"],
  3202: ["#ccccdd", "#22ffdd", "#ddffee", "#555544", "#55ee99"],
  3203: ["#77ffff", "#33aabb", "#112200", "#66bbff", "#660066"],
  3204: ["#00ee11", "#aa22aa", "#0099aa", "#446666", "#005533"],
  3205: ["#88aaff", "#7799dd", "#cc55ee", "#1188cc", "#ffccaa"],
  3206: ["#ff6699", "#bbeeee", "#ee6666", "#551199", "#11bb99"],
  3207: ["#77ff33", "#99ee44", "#991111", "#11ee33", "#551111"],
  3208: ["#772288", "#551155", "#00eecc", "#115533", "#55dd55"],
  3209: ["#222255", "#77ee55", "#aa0077", "#88cc66", "#666633"],
  3210: ["#ccee33", "#775511", "#77ff33", "#bbffff", "#336600"],
  3211: ["#4488bb", "#0044dd", "#999999", "#ffdd33", "#995588"],
  3212: ["#8844cc", "#442288", "#22eebb", "#eeaa99", "#dd9922"],
  3213: ["#ff2211", "#441133", "#99aa55", "#bb5511", "#eeccdd"],
  3214: ["#0077dd", "#bb11cc", "#aa3333", "#ddcc22", "#aaaaff"],
  3215: ["#dd3300", "#eeaabb", "#22aa77", "#001155", "#bb6600"],
  3216: ["#aa88ff", "#22cc22", "#ee6633", "#ffee11", "#cc1166"],
  3217: ["#ffaa33", "#99ffbb", "#ffccdd", "#662277", "#00ee77"],
  3218: ["#dd33ee", "#227777", "#884411", "#774455", "#9977aa"],
  3219: ["#66aa88", "#ddee11", "#eeddee", "#66eedd", "#44bb00"],
  3220: ["#222211", "#ee22dd", "#aaaaee", "#1144dd", "#ee66ee"],
  3221: ["#8833dd", "#337700", "#11dd99", "#bb3388", "#ee44ee"],
  3222: ["#2200dd", "#cc0055", "#6622cc", "#bbeeaa", "#aa11ee"],
  3223: ["#66cc11", "#005566", "#99bb77", "#001122", "#225599"],
  3224: ["#118866", "#ccee11", "#aa5555", "#661111", "#55aa77"],
  3225: ["#cc5577", "#007733", "#8822bb", "#7777dd", "#0011cc"],
  3226: ["#eedd88", "#33ee55", "#7799ee", "#4499dd", "#bb2255"],
  3227: ["#445566", "#771188", "#8811ee", "#dd5588", "#776699"],
  3228: ["#778855", "#66ddaa", "#99aa88", "#6677bb", "#11aa66"],
  3229: ["#11eedd", "#777744", "#2244ff", "#ee9955", "#aa9977"],
  3230: ["#7777aa", "#111166", "#1166dd", "#55cc44", "#33ccee"],
  3231: ["#22aa22", "#55ffcc", "#7711aa", "#aa99dd", "#cc0088"],
  3232: ["#4444cc", "#cc44dd", "#ffbb00", "#dd99dd", "#2211dd"],
  3233: ["#2244bb", "#aa8899", "#88dd33", "#66bbbb", "#ff9966"],
  3234: ["#ccff44", "#bbbbdd", "#eecc77", "#224400", "#1155dd"],
  3235: ["#2299cc", "#00aa22", "#99bb55", "#000000", "#ff6699"],
  3236: ["#999977", "#cc44cc", "#55ffcc", "#55cc88", "#ffcc33"],
  3237: ["#ff8899", "#3366ff", "#446666", "#883311", "#bbaa11"],
  3238: ["#007700", "#775533", "#ff11aa", "#6688bb", "#77cc55"],
  3239: ["#44ffaa", "#bb00bb", "#dd99ff", "#ee22aa", "#77ee99"],
  3240: ["#339955", "#448888", "#bb8800", "#00aa33", "#444499"],
  3241: ["#bbdd55", "#88ff11", "#bbaaff", "#33ccff", "#33aadd"],
  3242: ["#44ff99", "#bb3399", "#bb22aa", "#ee0066", "#44cc77"],
  3243: ["#ccdd99", "#559911", "#eecc00", "#22aa00", "#88ffbb"],
  3244: ["#aa44dd", "#aa1166", "#557744", "#4411bb", "#004488"],
  3245: ["#0000aa", "#220066", "#2299dd", "#00bb11", "#6688ff"],
  3246: ["#110000", "#9955bb", "#cc1199", "#6666dd", "#22ff99"],
  3247: ["#113355", "#118855", "#22ee77", "#00bbdd", "#77bb99"],
  3248: ["#dd2222", "#ee5599", "#bb00aa", "#66bbee", "#44eeaa"],
  3249: ["#33aaaa", "#ee66cc", "#222255", "#1133bb", "#ee1111"],
  3250: ["#aa6655", "#ccffbb", "#bbdd00", "#dd44aa", "#8877bb"],
  3251: ["#111188", "#66bb66", "#aaddbb", "#4466cc", "#ff99bb"],
  3252: ["#cc4411", "#44ffff", "#22ff44", "#00aa88", "#ffffff"],
  3253: ["#660000", "#880099", "#556644", "#0055bb", "#3355ee"],
  3254: ["#ee1133", "#77dd22", "#5577bb", "#77ddaa", "#aa7722"],
  3255: ["#551199", "#eeff77", "#ff00cc", "#99bb00", "#7788ee"],
  3256: ["#eecc88", "#8800dd", "#1133ee", "#661199", "#226611"],
  3257: ["#22dd11", "#994411", "#ff33dd", "#1199bb", "#994499"],
  3258: ["#cccccc", "#ccbb00", "#bb6633", "#cc4477", "#1133aa"],
  3259: ["#9955ff", "#ffbbee", "#44bb22", "#4411cc", "#bb8855"],
  3260: ["#00aaff", "#77dd99", "#11cc44", "#4433ee", "#ddaaaa"],
  3261: ["#bb0099", "#2299dd", "#bb6611", "#55aa66", "#5533bb"],
  3262: ["#66ee99", "#4433cc", "#cceebb", "#33dd33", "#bb6611"],
  3263: ["#33cc22", "#dd6644", "#779966", "#88bb00", "#00aa00"],
  3264: ["#11ee88", "#ee22aa", "#334499", "#dd8822", "#7700cc"],
  3265: ["#992277", "#11bb66", "#1166cc", "#ff1111", "#9933ff"],
  3266: ["#ddaaaa", "#9900cc", "#33ccee", "#33ee66", "#00aaaa"],
  3267: ["#9999ff", "#ddccff", "#774499", "#bb7777", "#22bb11"],
  3268: ["#11eeaa", "#11ff44", "#bbff33", "#337711", "#dd22dd"],
  3269: ["#0055ff", "#993355", "#ccffcc", "#dd44bb", "#11aaaa"],
  3270: ["#444433", "#004488", "#55ccff", "#aa6644", "#66ffcc"],
  3271: ["#4488ee", "#6677ee", "#993311", "#00ff88", "#dd8855"],
  3272: ["#77aaaa", "#3311ff", "#cc0099", "#aa5544", "#bb9966"],
  3273: ["#eeff55", "#bb8800", "#773388", "#bb44aa", "#0066ee"],
  3274: ["#66eebb", "#ee6655", "#22bbff", "#665588", "#003377"],
  3275: ["#44ccee", "#55bb77", "#558800", "#998844", "#ccee22"],
  3276: ["#00aa88", "#dd6611", "#ee2299", "#001111", "#998833"],
  3277: ["#2288aa", "#ccccff", "#773355", "#44aa88", "#3311ff"],
  3278: ["#ff99ee", "#554411", "#77ee77", "#0044aa", "#dd5533"],
  3279: ["#8844cc", "#228822", "#aa6655", "#2233ee", "#002255"],
  3280: ["#ff9988", "#554466", "#883399", "#bb9999", "#aa44bb"],
  3281: ["#ff5511", "#4499dd", "#669999", "#22ffee", "#660055"],
  3282: ["#bb00aa", "#77eeff", "#225500", "#ee8822", "#ccbb22"],
  3283: ["#9944aa", "#999922", "#ee4477", "#dd9911", "#1188cc"],
  3284: ["#550099", "#664433", "#00aacc", "#3377cc", "#11dd55"],
  3285: ["#778822", "#1122dd", "#9933dd", "#6622aa", "#22ccaa"],
  3286: ["#449977", "#772222", "#00ddaa", "#223399", "#886655"],
  3287: ["#337766", "#77ff22", "#eeee66", "#ff99cc", "#88aaaa"],
  3288: ["#cc3311", "#aaaacc", "#11ee55", "#bb99bb", "#334455"],
  3289: ["#2211dd", "#5555ee", "#55eecc", "#66ff00", "#ffbbff"],
  3290: ["#ffdd00", "#118822", "#3377ee", "#cc2211", "#ff55cc"],
  3291: ["#556611", "#44ff66", "#771166", "#5500aa", "#22aacc"],
  3292: ["#554455", "#ddee22", "#11aa44", "#229966", "#bbdd11"],
  3293: ["#66aa44", "#ff1199", "#ee44ee", "#2211ff", "#77dd22"],
  3294: ["#88aa88", "#444444", "#883355", "#9911ff", "#77aa99"],
  3295: ["#005544", "#662288", "#00ff22", "#555500", "#dd0099"],
  3296: ["#ffee11", "#ffff66", "#bbee33", "#aa11ee", "#dd88ee"],
  3297: ["#ee5544", "#aa2244", "#444466", "#881100", "#bb6644"],
  3298: ["#ddccff", "#001199", "#bb4455", "#ffaa11", "#118844"],
  3299: ["#668899", "#bbaaff", "#660022", "#00ff00", "#440099"],
  3300: ["#ff44bb", "#ff2222", "#0088aa", "#772255", "#006600"],
  3301: ["#44ddff", "#8899ee", "#7700bb", "#cc5533", "#557744"],
  3302: ["#009977", "#333311", "#6666dd", "#bbee00", "#44bb22"],
  3303: ["#7788aa", "#88ee99", "#440044", "#336644", "#664455"],
  3304: ["#9977dd", "#557700", "#cccc55", "#bbee44", "#22cc33"],
  3305: ["#5599cc", "#ffcccc", "#004400", "#33ddee", "#777733"],
  3306: ["#4444ff", "#445588", "#bbccaa", "#aa33cc", "#004422"],
  3307: ["#996699", "#22eeee", "#22dd11", "#ddcc99", "#116677"],
  3308: ["#00dd88", "#7788ee", "#0022ee", "#bbddee", "#dd8833"],
  3309: ["#7777bb", "#bbaa44", "#00ddcc", "#dd88bb", "#ccbbee"],
  3310: ["#990088", "#119911", "#8888bb", "#779911", "#776688"],
  3311: ["#2233bb", "#bbff00", "#449911", "#bb55bb", "#1199ff"],
  3312: ["#cc1199", "#8866ff", "#eedd33", "#559922", "#88ddbb"],
  3313: ["#227755", "#882266", "#55bbaa", "#ff33cc", "#ccaa00"],
  3314: ["#00aa99", "#997722", "#9988bb", "#664499", "#ff44ff"],
  3315: ["#225588", "#ff22dd", "#ff7766", "#5577aa", "#ee0055"],
  3316: ["#99aa00", "#00aa88", "#774411", "#cc44aa", "#4455aa"],
  3317: ["#22bb88", "#66ff77", "#4455ee", "#338811", "#aacc99"],
  3318: ["#33cc99", "#116699", "#00bb00", "#aaffcc", "#88bb66"],
  3319: ["#ddee11", "#661122", "#0000ee", "#9944bb", "#008855"],
  3320: ["#ff2211", "#33ccee", "#880033", "#bbaa77", "#33ff44"],
  3321: ["#004400", "#8811aa", "#0055ff", "#336644", "#22ccee"],
  3322: ["#eeff33", "#99bb77", "#113322", "#ddaa66", "#bbff66"],
  3323: ["#ee1166", "#44aaff", "#ff4422", "#cc4455", "#331144"],
  3324: ["#00cc66", "#22bb66", "#eebb55", "#222222", "#ccddee"],
  3325: ["#9922aa", "#ff55aa", "#ddee22", "#ee99aa", "#22aa22"],
  3326: ["#ddaa66", "#999966", "#88bbdd", "#cc5533", "#33cc22"],
  3327: ["#ff3333", "#00ee33", "#dd99cc", "#443388", "#aa00ee"],
  3328: ["#aaff88", "#771100", "#990022", "#11eeaa", "#115500"],
  3329: ["#116655", "#cccc33", "#66aa00", "#33eeff", "#ff1166"],
  3330: ["#552288", "#11bb44", "#ccaabb", "#111199", "#11aa77"],
  3331: ["#11ee99", "#7755cc", "#dddd44", "#4455ff", "#cc00ee"],
  3332: ["#776699", "#ff88ff", "#ff88ee", "#33aa00", "#660000"],
  3333: ["#11dd66", "#00ffdd", "#aa4477", "#4433ff", "#887799"],
  3334: ["#ffff88", "#220055", "#bbeeaa", "#eeaaaa", "#dd66ee"],
  3335: ["#77ff88", "#aa99dd", "#668844", "#dd9966", "#4411ff"],
  3336: ["#88cc88", "#22dd11", "#ffbb77", "#331133", "#771166"],
  3337: ["#884422", "#88aa99", "#bbdd66", "#6600aa", "#22ee99"],
  3338: ["#ddccaa", "#118822", "#88ff33", "#bb4411", "#884422"],
  3339: ["#ee6666", "#443377", "#aacc77", "#118811", "#dd2288"],
  3340: ["#4411ee", "#88eeff", "#228833", "#cc3322", "#22bbaa"],
  3341: ["#aa44ff", "#cc66ff", "#44aa55", "#6644ee", "#dd66dd"],
  3342: ["#88ff99", "#ff6644", "#bb88dd", "#22cccc", "#2244ff"],
  3343: ["#aaffff", "#ee8866", "#66ccff", "#668844", "#ffaa88"],
  3344: ["#eeaacc", "#aa3311", "#aaddee", "#448877", "#eedd11"],
  3345: ["#bb6644", "#aa6633", "#000077", "#bb88bb", "#44cc99"],
  3346: ["#5566cc", "#88ff00", "#5522aa", "#88ee66", "#aa4400"],
  3347: ["#cc3366", "#882299", "#dd7733", "#88ff99", "#997777"],
  3348: ["#cc9911", "#44ff22", "#dd77dd", "#ee8866", "#ddbb00"],
  3349: ["#bb22ff", "#008822", "#44ff55", "#bbcc99", "#33bb11"],
  3350: ["#445500", "#998899", "#550055", "#663366", "#993388"],
  3351: ["#1100cc", "#229922", "#bb1100", "#ff7733", "#884400"],
  3352: ["#776611", "#334477", "#6600cc", "#5566bb", "#66aaaa"],
  3353: ["#cc3377", "#55dd66", "#009966", "#447799", "#bbff99"],
  3354: ["#ccbb99", "#99ee66", "#aaee22", "#449922", "#dd88ee"],
  3355: ["#cc2266", "#eecc66", "#cc33ee", "#7733dd", "#5522dd"],
  3356: ["#55aabb", "#99ff22", "#2255aa", "#997777", "#eeaadd"],
  3357: ["#bbbb33", "#ff44bb", "#ddffcc", "#1111aa", "#667722"],
  3358: ["#22aabb", "#11ffff", "#aa9922", "#ee3344", "#5566cc"],
  3359: ["#dd5511", "#ff2233", "#ff99ee", "#4433cc", "#bb2233"],
  3360: ["#0033aa", "#11cc33", "#99dd00", "#88bbcc", "#ee1144"],
  3361: ["#4455ff", "#998888", "#4499cc", "#33ee33", "#dd77cc"],
  3362: ["#3366cc", "#222266", "#ccbb33", "#ffffbb", "#00dd00"],
  3363: ["#776688", "#446688", "#88cc33", "#441177", "#11dd11"],
  3364: ["#666644", "#554400", "#ffcccc", "#992277", "#bbdd33"],
  3365: ["#ff4400", "#998800", "#440033", "#8800dd", "#66ee99"],
  3366: ["#11eeff", "#116699", "#9922ee", "#ee22bb", "#ddbb44"],
  3367: ["#88aaaa", "#cc77cc", "#9988bb", "#aaee00", "#cc55cc"],
  3368: ["#2288dd", "#66eeff", "#4499cc", "#bb2277", "#cc0055"],
  3369: ["#009999", "#aa7766", "#aaddbb", "#992266", "#664433"],
  3370: ["#aaee33", "#444477", "#ccbb44", "#88bb77", "#3333ff"],
  3371: ["#ee8855", "#ddee33", "#ddaaee", "#eeff33", "#44eeaa"],
  3372: ["#cc3300", "#44aa66", "#bb3333", "#66bb66", "#aa44bb"],
  3373: ["#cc0099", "#99ccdd", "#eebbee", "#441111", "#5533aa"],
  3374: ["#ccbb77", "#993333", "#223399", "#bbffff", "#bb3399"],
  3375: ["#00dd88", "#00ccaa", "#88cccc", "#1111dd", "#223344"],
  3376: ["#88cc44", "#2222ff", "#22ddbb", "#88ff88", "#995533"],
  3377: ["#9922ff", "#774422", "#6644dd", "#778822", "#ee55bb"],
  3378: ["#eeff66", "#1111aa", "#cc88dd", "#448822", "#aa33bb"],
  3379: ["#009999", "#ee2277", "#cc0000", "#4499aa", "#117766"],
  3380: ["#00aa33", "#22aa44", "#223399", "#559911", "#00cc99"],
  3381: ["#ffaa44", "#003344", "#9999dd", "#bb6633", "#1155ff"],
  3382: ["#118833", "#4477ff", "#eecc11", "#8866ee", "#6688bb"],
  3383: ["#bbaa11", "#006622", "#662200", "#997722", "#dddddd"],
  3384: ["#ee4466", "#997799", "#22ddcc", "#1144dd", "#ffbbdd"],
  3385: ["#ee5544", "#2299ff", "#dd66ff", "#77ee77", "#ff3322"],
  3386: ["#cc6633", "#445599", "#ddbbcc", "#229988", "#331144"],
  3387: ["#555588", "#776600", "#cc7700", "#55cc55", "#4411dd"],
  3388: ["#99bb55", "#667755", "#552266", "#ff6633", "#cccc55"],
  3389: ["#77ffdd", "#eebb77", "#6655aa", "#dd5522", "#446622"],
  3390: ["#331166", "#990066", "#22ee99", "#cc22dd", "#11eebb"],
  3391: ["#ff44cc", "#6677aa", "#aaddee", "#ee3366", "#ddcc99"],
  3392: ["#bb5522", "#11cc44", "#bbdd66", "#2233ee", "#8833aa"],
  3393: ["#229944", "#9977ff", "#8822cc", "#ff1100", "#dd00bb"],
  3394: ["#000022", "#bb9955", "#88aadd", "#6655cc", "#8855ee"],
  3395: ["#bbff00", "#9933dd", "#dd2222", "#bb3388", "#ddaa88"],
  3396: ["#aaaa55", "#665511", "#559922", "#99cc55", "#dd99ff"],
  3397: ["#bbccdd", "#ff1166", "#00ee66", "#55bbff", "#6699bb"],
  3398: ["#bb8800", "#11eeee", "#22ff00", "#7733ee", "#aa8811"],
  3399: ["#cc9977", "#eeaa77", "#00aa22", "#eeff77", "#bb88aa"],
  3400: ["#2211aa", "#66aa33", "#dd7711", "#aa0011", "#4455dd"],
  3401: ["#5555ee", "#cc8866", "#334400", "#ff8888", "#991144"],
  3402: ["#22ddbb", "#887722", "#eecc00", "#33bb55", "#aa00ee"],
  3403: ["#11bbcc", "#cc0044", "#6699aa", "#66cc22", "#332244"],
  3404: ["#ffff77", "#77dd00", "#0022ee", "#118844", "#ffaa22"],
  3405: ["#bbaa88", "#ff2200", "#886633", "#44dd00", "#334400"],
  3406: ["#eedddd", "#007733", "#2288aa", "#88ee66", "#113388"],
  3407: ["#aaaaaa", "#ff22dd", "#001144", "#554422", "#77dd11"],
  3408: ["#11cc22", "#cc7766", "#dddd55", "#8833cc", "#eeff44"],
  3409: ["#338833", "#ee1188", "#ee6633", "#ccee11", "#448899"],
  3410: ["#99ff44", "#771122", "#44cc33", "#11ee99", "#aa3366"],
  3411: ["#11ee77", "#ddaa11", "#ff0055", "#99bbaa", "#99cc33"],
  3412: ["#ee4411", "#ff6677", "#9900aa", "#3366ee", "#0077ee"],
  3413: ["#4455ee", "#00eeaa", "#44cc99", "#556655", "#0099bb"],
  3414: ["#445599", "#aadd55", "#777722", "#bbcc00", "#338888"],
  3415: ["#ff6600", "#ee22cc", "#775588", "#883322", "#ddffaa"],
  3416: ["#5599dd", "#885511", "#33aa33", "#ffff99", "#556600"],
  3417: ["#6666cc", "#33aaff", "#770033", "#778811", "#1100bb"],
  3418: ["#aa3355", "#8800aa", "#11ff22", "#ee55dd", "#aaff55"],
  3419: ["#778844", "#aa44bb", "#ee6688", "#ccee66", "#bb11ee"],
  3420: ["#99bb22", "#66aaaa", "#005599", "#ccbb77", "#8811cc"],
  3421: ["#dd33ee", "#dd11bb", "#ee7799", "#99aa22", "#ddaa33"],
  3422: ["#2266ff", "#552233", "#2277bb", "#aaee33", "#44ff00"],
  3423: ["#2200cc", "#441177", "#995511", "#ccaa55", "#22bb33"],
  3424: ["#ccff55", "#227700", "#774444", "#001122", "#ff3377"],
  3425: ["#ffaa00", "#004400", "#005566", "#110099", "#002255"],
  3426: ["#eecc33", "#995533", "#008888", "#335500", "#44ff77"],
  3427: ["#3344cc", "#447766", "#ccaa55", "#448888", "#339977"],
  3428: ["#559900", "#dd5511", "#88bbff", "#8844aa", "#ff99ee"],
  3429: ["#aaddaa", "#cc33ff", "#118800", "#440033", "#66ddaa"],
  3430: ["#442266", "#99cc99", "#11dd00", "#bb99ff", "#eecc33"],
  3431: ["#ff33dd", "#ff22aa", "#22aa00", "#ff2266", "#dd4455"],
  3432: ["#004411", "#229966", "#ee9966", "#11cc77", "#ccee44"],
  3433: ["#5533dd", "#5599cc", "#99ffaa", "#ddbbff", "#9999bb"],
  3434: ["#33ee66", "#77dd44", "#ffff55", "#bb44ee", "#3366dd"],
  3435: ["#001111", "#eeffcc", "#ffcc99", "#3388aa", "#33ff22"],
  3436: ["#999955", "#aa9988", "#bbff00", "#ff6611", "#443355"],
  3437: ["#aa3399", "#bb9977", "#3333ee", "#11ffcc", "#001122"],
  3438: ["#bbaaff", "#55dd22", "#ffaa55", "#44ffbb", "#33aa00"],
  3439: ["#550000", "#ccbb99", "#ee5544", "#cc0033", "#ee3344"],
  3440: ["#8866cc", "#bbeeff", "#cc0011", "#eebbbb", "#aa44ee"],
  3441: ["#99ee44", "#6677aa", "#88ee11", "#aa9988", "#aa7766"],
  3442: ["#776644", "#885500", "#ddbb55", "#995500", "#cccc55"],
  3443: ["#4411aa", "#555544", "#99ddcc", "#44ddee", "#11ccaa"],
  3444: ["#443322", "#553300", "#55ccee", "#55aa66", "#cc8844"],
  3445: ["#bb11cc", "#ff44ff", "#222299", "#8899cc", "#dd9977"],
  3446: ["#aa2255", "#bb7744", "#ee3311", "#775533", "#99bbbb"],
  3447: ["#cc55bb", "#00aa66", "#9933ee", "#778844", "#bbaaee"],
  3448: ["#ccdd11", "#88ee33", "#883399", "#ee6655", "#aacc77"],
  3449: ["#bbccee", "#99ff22", "#4422bb", "#112233", "#bb8800"],
  3450: ["#cc0022", "#889911", "#ee4422", "#11cc33", "#003322"],
  3451: ["#446644", "#bbff11", "#44eeee", "#dd99aa", "#aabb77"],
  3452: ["#ccaadd", "#776633", "#bbaa88", "#88ee55", "#88bbcc"],
  3453: ["#eeee99", "#44bbbb", "#aabbbb", "#663377", "#bb6699"],
  3454: ["#2288aa", "#dddd22", "#886655", "#0099bb", "#665522"],
  3455: ["#110033", "#1166dd", "#bb3355", "#bb99bb", "#66bbbb"],
  3456: ["#ee7766", "#22bbcc", "#8800cc", "#77ccdd", "#ee7744"],
  3457: ["#55cc99", "#aabbbb", "#00ee77", "#00ff66", "#cc2244"],
  3458: ["#cc33ff", "#662222", "#66cc88", "#bbbbcc", "#22bb00"],
  3459: ["#55ee11", "#5599ee", "#cc3344", "#00eebb", "#664499"],
  3460: ["#ff11bb", "#88bbff", "#77aacc", "#005500", "#117722"],
  3461: ["#888811", "#33cc33", "#bb2288", "#9944cc", "#cc6655"],
  3462: ["#99ffbb", "#998877", "#ffcc88", "#ff9977", "#aa44ee"],
  3463: ["#88ccff", "#88cc44", "#ccee00", "#00bb88", "#99cc88"],
  3464: ["#883322", "#00cc66", "#9999dd", "#dddd88", "#66cc11"],
  3465: ["#559977", "#aadd55", "#0022dd", "#0044ee", "#33ff11"],
  3466: ["#331155", "#996600", "#ccbb00", "#4433ff", "#997799"],
  3467: ["#77ffaa", "#336688", "#44cc44", "#aa4400", "#9955ff"],
  3468: ["#7777aa", "#11aa11", "#4455ff", "#aacc66", "#cc66cc"],
  3469: ["#119955", "#992288", "#ffee00", "#338888", "#227733"],
  3470: ["#ddbb22", "#001133", "#776677", "#dd0044", "#dd0077"],
  3471: ["#ff7711", "#334477", "#aa8833", "#bb1177", "#eeffee"],
  3472: ["#007722", "#ccbbaa", "#443344", "#7788bb", "#aadd77"],
  3473: ["#443322", "#aa3355", "#ee2299", "#55cc11", "#22dd55"],
  3474: ["#ff33ee", "#99aa11", "#2211cc", "#334444", "#ff66dd"],
  3475: ["#667799", "#225588", "#cc2277", "#887755", "#889922"],
  3476: ["#44dddd", "#5533bb", "#330077", "#44ee55", "#660088"],
  3477: ["#2244bb", "#bbaa11", "#00dd77", "#88bbdd", "#ff8866"],
  3478: ["#55bb22", "#332200", "#11aa33", "#44bb77", "#77bbff"],
  3479: ["#88aa33", "#ee5522", "#dddd44", "#665533", "#ee0055"],
  3480: ["#55bb55", "#aabb88", "#00ee99", "#883300", "#ff7788"],
  3481: ["#ccff77", "#ff9977", "#008822", "#33dd44", "#bb1155"],
  3482: ["#990011", "#ddffee", "#88ddcc", "#55bb11", "#aa1166"],
  3483: ["#66dd33", "#5533dd", "#ffcc00", "#88ee11", "#446611"],
  3484: ["#cccccc", "#662288", "#77ee33", "#222233", "#eeaadd"],
  3485: ["#6633dd", "#99dd88", "#ffff44", "#ee9966", "#8844cc"],
  3486: ["#bbffff", "#881133", "#881144", "#ff2288", "#664400"],
  3487: ["#ee8855", "#0033aa", "#aaaabb", "#11bb88", "#8855aa"],
  3488: ["#22aa44", "#993399", "#aa1155", "#ff4466", "#77ddaa"],
  3489: ["#77ee44", "#554433", "#ddbbaa", "#aaaa33", "#113344"],
  3490: ["#66bbaa", "#33cc55", "#881133", "#7700ff", "#ff7799"],
  3491: ["#bb11dd", "#66ffbb", "#5544bb", "#33bb11", "#ee7788"],
  3492: ["#bb3311", "#dd88ff", "#00aa33", "#664499", "#11bb66"],
  3493: ["#ffcc00", "#44ff66", "#bb99dd", "#cc2266", "#cc5511"],
  3494: ["#11ccaa", "#9977bb", "#559988", "#660044", "#00dd11"],
  3495: ["#aa55bb", "#889944", "#8888bb", "#447733", "#661122"],
  3496: ["#8822ff", "#bb7733", "#99dd22", "#ff3344", "#bbddcc"],
  3497: ["#ff55ff", "#66ffaa", "#99cc66", "#ee4400", "#0077cc"],
  3498: ["#55dd33", "#cc5566", "#442299", "#00ff88", "#cc33cc"],
  3499: ["#88bb33", "#883333", "#00bb00", "#99ddee", "#448899"],
  3500: ["#111133", "#ee77bb", "#333366", "#99ff11", "#cc66aa"],
  3501: ["#11aa44", "#881177", "#88ddff", "#332211", "#339988"],
  3502: ["#aacc88", "#9944aa", "#bbee66", "#88cc88", "#8844ee"],
  3503: ["#cc1188", "#dd77ee", "#ee1155", "#882277", "#aa1199"],
  3504: ["#dd55ff", "#77aaaa", "#aa3322", "#114466", "#881199"],
  3505: ["#3388aa", "#559944", "#334477", "#dd2200", "#ee5555"],
  3506: ["#9966dd", "#445500", "#44dd11", "#ffaa22", "#993322"],
  3507: ["#556677", "#337766", "#ee44bb", "#88bbff", "#3344dd"],
  3508: ["#22aabb", "#ddff66", "#55ffff", "#00cc44", "#44ff00"],
  3509: ["#55dd99", "#44cc22", "#55eedd", "#ee1122", "#ee55bb"],
  3510: ["#1133ee", "#33aadd", "#ff88bb", "#2200aa", "#22cc66"],
  3511: ["#22bb00", "#cc0088", "#88ffaa", "#99aa11", "#aa0066"],
  3512: ["#224488", "#1188aa", "#ee7788", "#3366dd", "#887722"],
  3513: ["#aacc22", "#00bb11", "#dd8822", "#11aa66", "#11bbff"],
  3514: ["#119944", "#aa2222", "#cc3311", "#889922", "#88eecc"],
  3515: ["#bb2222", "#3311ee", "#881177", "#00dd22", "#ffee11"],
  3516: ["#11bb22", "#00ee66", "#11bb33", "#bb0044", "#aaaa22"],
  3517: ["#440099", "#aa3355", "#9911dd", "#bb5577", "#4455ee"],
  3518: ["#66ccee", "#2266ee", "#333366", "#444499", "#994466"],
  3519: ["#55ddaa", "#445533", "#994444", "#9933cc", "#000011"],
  3520: ["#880011", "#337777", "#995500", "#883311", "#552277"],
  3521: ["#33cc33", "#ffee88", "#bbff11", "#ee2266", "#009955"],
  3522: ["#bb55cc", "#338800", "#444499", "#88bbee", "#990099"],
  3523: ["#00bb22", "#cc66ee", "#9933bb", "#ee00dd", "#996655"],
  3524: ["#ccccbb", "#ff5544", "#998811", "#bb2233", "#22ff88"],
  3525: ["#cc77dd", "#dd8877", "#008888", "#eeffbb", "#ff6688"],
  3526: ["#11bbdd", "#6666ff", "#cc55ee", "#226644", "#88dd22"],
  3527: ["#eeeeee", "#ffdd77", "#66ddee", "#ccbbee", "#ff33ff"],
  3528: ["#11bb88", "#ccee22", "#4433dd", "#00dd88", "#112255"],
  3529: ["#665511", "#ee9977", "#77bbbb", "#ccddee", "#ffeebb"],
  3530: ["#00cc11", "#99cc00", "#bb33aa", "#11ddee", "#22aa44"],
  3531: ["#eedd88", "#eeaa88", "#ffbb66", "#bb7777", "#ddbbff"],
  3532: ["#0011ff", "#ccbbff", "#77bb88", "#aa3333", "#bb8844"],
  3533: ["#ee99aa", "#22ffcc", "#777722", "#dd33aa", "#dd5588"],
  3534: ["#44ff44", "#9922aa", "#bbaa00", "#dd2299", "#dd44cc"],
  3535: ["#bbdd88", "#66ff66", "#ee88cc", "#88ff22", "#bb22cc"],
  3536: ["#7788bb", "#888888", "#ffccee", "#8866dd", "#00ffdd"],
  3537: ["#1166aa", "#ee9900", "#9999ee", "#11aa55", "#2255ff"],
  3538: ["#8833dd", "#99ee33", "#772299", "#7755aa", "#11dd55"],
  3539: ["#113344", "#ccdd22", "#77aa77", "#886600", "#0022ee"],
  3540: ["#11aaee", "#cc33ee", "#889955", "#33ddff", "#2200ee"],
  3541: ["#885500", "#22aabb", "#dd33aa", "#11ee55", "#331166"],
  3542: ["#222244", "#eebb00", "#ee2299", "#ccffcc", "#0088ff"],
  3543: ["#4488ee", "#558888", "#aaeeff", "#8866dd", "#ccddcc"],
  3544: ["#4433bb", "#bb8877", "#ffeecc", "#0099dd", "#ffee99"],
  3545: ["#0000cc", "#11aa11", "#88ff44", "#cc8855", "#000000"],
  3546: ["#ff2277", "#cc2244", "#880088", "#336655", "#ffcc22"],
  3547: ["#558833", "#ee4499", "#3399cc", "#225522", "#ee8822"],
  3548: ["#9944cc", "#7755aa", "#3333ff", "#ee2288", "#1199cc"],
  3549: ["#cc66cc", "#bb1155", "#555555", "#662288", "#cc2266"],
  3550: ["#11bb66", "#aaccee", "#889922", "#998833", "#cc8855"],
  3551: ["#996655", "#335511", "#88ff99", "#776600", "#ccccff"],
  3552: ["#115577", "#aacc33", "#00ccee", "#ffcc22", "#5544aa"],
  3553: ["#667700", "#ee11ff", "#885500", "#ff7733", "#66ddee"],
  3554: ["#ddddff", "#aaaabb", "#4444bb", "#ee99ee", "#bb0033"],
  3555: ["#2288ff", "#22aa66", "#55ee77", "#338877", "#99ffaa"],
  3556: ["#6600dd", "#8811ff", "#221111", "#5544aa", "#4488ff"],
  3557: ["#66ffaa", "#aaaa44", "#4422ee", "#0000ff", "#ff4466"],
  3558: ["#ff6633", "#77bb55", "#cc3333", "#33dd88", "#22aadd"],
  3559: ["#aa1188", "#99dd66", "#bbdd11", "#4422aa", "#558833"],
  3560: ["#1177ff", "#7744dd", "#550000", "#55eecc", "#00dd77"],
  3561: ["#aaff88", "#cc77cc", "#22dd00", "#aadd33", "#aabb99"],
  3562: ["#aa3388", "#77ee55", "#aaee00", "#00ff77", "#ff9944"],
  3563: ["#00cc11", "#ccff11", "#eebbdd", "#880000", "#ee7744"],
  3564: ["#0011bb", "#330022", "#9922ff", "#ddee88", "#44ee66"],
  3565: ["#88ff99", "#ffffcc", "#66eedd", "#11ff33", "#cc3333"],
  3566: ["#aa8888", "#ff44ee", "#885522", "#112244", "#8844ff"],
  3567: ["#88ff88", "#77ddff", "#dd1100", "#ff88cc", "#446699"],
  3568: ["#556633", "#551188", "#88aa00", "#66ccdd", "#ee5599"],
  3569: ["#77aaff", "#8822ff", "#ff8899", "#66ddaa", "#55cc99"],
  3570: ["#337700", "#55cc33", "#99ccee", "#551122", "#ffeeee"],
  3571: ["#33aa22", "#cc9922", "#eeee99", "#ff7722", "#ff88dd"],
  3572: ["#aa9911", "#8800dd", "#776666", "#ee22dd", "#0011dd"],
  3573: ["#44ddaa", "#77bb77", "#2222ff", "#995500", "#883355"],
  3574: ["#114433", "#dddd55", "#445566", "#bbeeee", "#334444"],
  3575: ["#bb6644", "#1166ff", "#220022", "#ee00bb", "#888811"],
  3576: ["#cc7755", "#886677", "#aa33aa", "#eedd77", "#22bb22"],
  3577: ["#aaff77", "#8811ee", "#44aacc", "#558888", "#8877cc"],
  3578: ["#aaff77", "#aa11ff", "#ff9988", "#118844", "#8811ee"],
  3579: ["#ffccaa", "#4400cc", "#ff0000", "#dd33bb", "#ddaa44"],
  3580: ["#77bbaa", "#cc4444", "#3355aa", "#331122", "#4488ff"],
  3581: ["#bb7799", "#889911", "#555522", "#ccddbb", "#ee4433"],
  3582: ["#882299", "#33ddee", "#000033", "#5500dd", "#110022"],
  3583: ["#5577bb", "#889900", "#ff88cc", "#cc4422", "#3355dd"],
  3584: ["#99dd55", "#0066bb", "#66dd33", "#662200", "#66ff77"],
  3585: ["#aa9900", "#ee4488", "#aaaa99", "#7700ee", "#55cc66"],
  3586: ["#ffffdd", "#442222", "#99cc22", "#99ff66", "#cc66dd"],
  3587: ["#7777dd", "#664422", "#ee8866", "#cc1100", "#ee11ff"],
  3588: ["#440022", "#7799aa", "#77cc99", "#557733", "#bbffaa"],
  3589: ["#225555", "#ff33cc", "#ddddff", "#dd11aa", "#aa4422"],
  3590: ["#bbeeff", "#44ddbb", "#9977dd", "#99ee44", "#aaee33"],
  3591: ["#776688", "#11aaff", "#990011", "#559988", "#aa88ff"],
  3592: ["#880022", "#6622cc", "#9900dd", "#889999", "#bb5544"],
  3593: ["#cc4488", "#33ee33", "#bb44aa", "#44bb88", "#ee2200"],
  3594: ["#009977", "#445555", "#dd5500", "#cceedd", "#66aaff"],
  3595: ["#aacc22", "#cc0011", "#443300", "#aa8855", "#7755ee"],
  3596: ["#6666cc", "#445599", "#1122dd", "#996600", "#22ddaa"],
  3597: ["#7755bb", "#bb99aa", "#dd7788", "#0033ee", "#dd8811"],
  3598: ["#77cc00", "#11dd33", "#5533dd", "#ff8822", "#002244"],
  3599: ["#ee11ff", "#111144", "#eeaa55", "#666611", "#99ff00"],
  3600: ["#ffddff", "#997777", "#880077", "#dd44bb", "#ee88cc"],
  3601: ["#eeddbb", "#ff9933", "#cc7700", "#aaaa11", "#000000"],
  3602: ["#aa9933", "#333300", "#666666", "#11cc99", "#ffaadd"],
  3603: ["#ff3322", "#110000", "#aa5577", "#220055", "#55aa55"],
  3604: ["#88bbcc", "#1122cc", "#115533", "#8833ff", "#663300"],
  3605: ["#ee0044", "#442266", "#5522bb", "#55bbcc", "#aaff44"],
  3606: ["#6611cc", "#11ff66", "#aa5522", "#0011ee", "#77ee66"],
  3607: ["#2277ee", "#ccdd00", "#33ffff", "#221188", "#880088"],
  3608: ["#cceeff", "#bb0066", "#ff99cc", "#33ff88", "#ccff99"],
  3609: ["#33cc11", "#aaaa11", "#bbaacc", "#bb6644", "#443377"],
  3610: ["#eeee66", "#773377", "#aaff33", "#cc0077", "#ffbb55"],
  3611: ["#eeaaff", "#44ccff", "#aa4466", "#ee7755", "#99dd55"],
  3612: ["#dd44ff", "#887744", "#3366ee", "#bb2222", "#2233dd"],
  3613: ["#55eecc", "#663355", "#3333ff", "#bb88ff", "#9922dd"],
  3614: ["#00cc55", "#aadddd", "#11dd11", "#99dd66", "#aa22cc"],
  3615: ["#eeccbb", "#7733aa", "#6600dd", "#4422bb", "#775577"],
  3616: ["#4455ee", "#8833dd", "#ff9999", "#ff2255", "#881188"],
  3617: ["#6644aa", "#88ffaa", "#228888", "#7755ee", "#ccaa44"],
  3618: ["#22aaee", "#440088", "#aa77dd", "#dd5522", "#006666"],
  3619: ["#776600", "#993399", "#eecc99", "#ff2255", "#22ff66"],
  3620: ["#ff44cc", "#3355cc", "#99cccc", "#9999aa", "#bb4444"],
  3621: ["#999922", "#66dd66", "#cc5588", "#991188", "#3322dd"],
  3622: ["#aaccee", "#dd99dd", "#ff6622", "#5511bb", "#aaddaa"],
  3623: ["#aaff55", "#5533dd", "#557700", "#ff11ee", "#ee99ff"],
  3624: ["#aa3388", "#ff6666", "#aa7755", "#ffaaaa", "#9933bb"],
  3625: ["#bbcc77", "#990077", "#55dd00", "#0088dd", "#bb0011"],
  3626: ["#aa33aa", "#bb66bb", "#002288", "#ccdddd", "#ff6644"],
  3627: ["#2266ee", "#cc6600", "#9988ee", "#778866", "#dd77bb"],
  3628: ["#4444dd", "#dd9988", "#77aa77", "#4411ee", "#22dd22"],
  3629: ["#4411aa", "#99bb44", "#333311", "#66ee00", "#ffaaee"],
  3630: ["#11ffee", "#6644ff", "#114400", "#ee2233", "#88ccff"],
  3631: ["#bb11ff", "#222266", "#bbbbcc", "#7733bb", "#99bbaa"],
  3632: ["#aaaa77", "#bbddaa", "#ff4466", "#1155ff", "#bb6699"],
  3633: ["#ee9911", "#11ccaa", "#440044", "#aacc44", "#77ff88"],
  3634: ["#779900", "#cceeee", "#449966", "#551155", "#440066"],
  3635: ["#887733", "#ccaa00", "#445577", "#ff4444", "#77aa99"],
  3636: ["#cc8800", "#44ddff", "#ffdddd", "#22ffdd", "#33eedd"],
  3637: ["#22ff88", "#dd4422", "#663344", "#dd88aa", "#885566"],
  3638: ["#7744cc", "#220099", "#2222dd", "#ff22aa", "#33ff55"],
  3639: ["#779988", "#ffeeaa", "#113399", "#dd6633", "#aa7733"],
  3640: ["#008811", "#228800", "#555522", "#22aa88", "#ffdd00"],
  3641: ["#1111dd", "#dd55bb", "#77eecc", "#44aa22", "#aa44bb"],
  3642: ["#44cc44", "#2211ff", "#77dd55", "#3366dd", "#005566"],
  3643: ["#ffdd88", "#001122", "#883333", "#ee1122", "#335500"],
  3644: ["#118811", "#44cc99", "#1166ff", "#cc88bb", "#dd1155"],
  3645: ["#cc1177", "#666611", "#44aa99", "#226677", "#eeccff"],
  3646: ["#dd66aa", "#aa9977", "#ffffdd", "#44ff00", "#000033"],
  3647: ["#11ee44", "#eeffdd", "#55bb88", "#aa66cc", "#aa9977"],
  3648: ["#339999", "#99ee00", "#556600", "#33eebb", "#5577cc"],
  3649: ["#ccbb99", "#bb1155", "#4422cc", "#887788", "#bb1155"],
  3650: ["#001199", "#999966", "#eebb22", "#0099bb", "#992299"],
  3651: ["#bbee11", "#cc2233", "#8888cc", "#3322aa", "#11bb00"],
  3652: ["#bbee77", "#7788ee", "#77dd33", "#55ee77", "#44cc00"],
  3653: ["#775588", "#00ff44", "#22ddcc", "#2299bb", "#dd0011"],
  3654: ["#99dd11", "#ff88cc", "#bb9955", "#668844", "#aa88aa"],
  3655: ["#335511", "#aa44aa", "#884477", "#eeddcc", "#445577"],
  3656: ["#ee6699", "#88aa77", "#88ff44", "#338877", "#22eedd"],
  3657: ["#6699dd", "#bb99cc", "#ddbbdd", "#ee1144", "#221144"],
  3658: ["#5511ee", "#9911bb", "#99bbee", "#ff1133", "#aaeeaa"],
  3659: ["#555511", "#cc22cc", "#ff3344", "#aa7766", "#992211"],
  3660: ["#330022", "#11cc00", "#44bb88", "#eeffee", "#ccaa22"],
  3661: ["#aa00dd", "#3300aa", "#aa7788", "#88ff44", "#669977"],
  3662: ["#7766cc", "#33bbcc", "#664499", "#bbccdd", "#dd2277"],
  3663: ["#44ccaa", "#33ee88", "#33ddaa", "#11dd66", "#bb4411"],
  3664: ["#55aacc", "#1100aa", "#002200", "#ee7766", "#88cc11"],
  3665: ["#ee77aa", "#aaff44", "#22ee77", "#99dd66", "#bb0077"],
  3666: ["#1100ee", "#eeff33", "#eeeeee", "#ee11dd", "#88ccee"],
  3667: ["#44bbff", "#77dddd", "#0022cc", "#ffccbb", "#cc1155"],
  3668: ["#009999", "#4433dd", "#11cccc", "#88dd66", "#225588"],
  3669: ["#aa33ff", "#ee66ee", "#ff11ff", "#33ddcc", "#338888"],
  3670: ["#339955", "#aa6644", "#bb11dd", "#ffbb77", "#aaff66"],
  3671: ["#ff5500", "#cc22cc", "#aa3311", "#993333", "#ccaabb"],
  3672: ["#11dd44", "#6633cc", "#775500", "#bb2211", "#110000"],
  3673: ["#334455", "#aa2255", "#227744", "#994488", "#99ffbb"],
  3674: ["#bb88bb", "#77dd11", "#ffbb11", "#773322", "#77bb22"],
  3675: ["#eeaa00", "#ccbb66", "#66bb00", "#228833", "#ee9900"],
  3676: ["#114422", "#cc7766", "#dd1166", "#eeaa66", "#ddeedd"],
  3677: ["#5566bb", "#998833", "#008844", "#1155aa", "#441144"],
  3678: ["#993322", "#882211", "#ddddff", "#ddbb88", "#996644"],
  3679: ["#dd2222", "#ee1177", "#11cccc", "#445599", "#9944ee"],
  3680: ["#bbbb11", "#7700ff", "#ccbbff", "#117700", "#00cc55"],
  3681: ["#ddeedd", "#bbaadd", "#ccff55", "#11ff33", "#88bb33"],
  3682: ["#bb2211", "#22ffcc", "#ee8866", "#888833", "#5522dd"],
  3683: ["#994455", "#cc99ff", "#cc9977", "#5566aa", "#22ffdd"],
  3684: ["#ff4422", "#0099dd", "#004411", "#882255", "#44ffdd"],
  3685: ["#0044bb", "#55ee33", "#5599cc", "#aaaa00", "#bbff99"],
  3686: ["#ff3377", "#dd4477", "#888833", "#885577", "#00ddee"],
  3687: ["#225533", "#ddffdd", "#66aa00", "#ee88ff", "#3355dd"],
  3688: ["#771144", "#44aa00", "#bb7744", "#44dddd", "#ee2299"],
  3689: ["#bb4422", "#880011", "#aa3388", "#eeff00", "#334499"],
  3690: ["#666688", "#665500", "#33ddee", "#66aaee", "#99aaff"],
  3691: ["#aa5566", "#554477", "#ccaa99", "#4499ee", "#ccbb66"],
  3692: ["#dd7799", "#444411", "#cc1166", "#dd7733", "#55ff33"],
  3693: ["#11bb55", "#cc9944", "#0033ff", "#2277dd", "#448855"],
  3694: ["#bb3311", "#8855ee", "#aa22dd", "#8833ee", "#dd3322"],
  3695: ["#5588ee", "#5599cc", "#ff88ff", "#aa2299", "#6611cc"],
  3696: ["#553311", "#555599", "#88ffcc", "#bbcc88", "#ff44ff"],
  3697: ["#ffccdd", "#664400", "#22dd44", "#9977aa", "#66ccbb"],
  3698: ["#55cc55", "#8877bb", "#225566", "#aabb77", "#ddcc55"],
  3699: ["#33eeff", "#9922aa", "#bb3322", "#bb66ee", "#11cc44"],
  3700: ["#cc7788", "#00ee77", "#8844dd", "#5544aa", "#77ccaa"],
  3701: ["#00ff88", "#550099", "#aa0022", "#ffbb11", "#cccc11"],
  3702: ["#1100dd", "#88ff22", "#22ee44", "#ffccee", "#006666"],
  3703: ["#77cc99", "#ff0088", "#775522", "#449966", "#cc33cc"],
  3704: ["#ff6666", "#99aa77", "#11bbcc", "#bb3322", "#773344"],
  3705: ["#2222ee", "#665555", "#118899", "#ddaa11", "#22dd00"],
  3706: ["#886622", "#66cc00", "#11dd88", "#4499aa", "#ffff44"],
  3707: ["#334444", "#6633ff", "#6644ff", "#8811ee", "#ffaa00"],
  3708: ["#bb7733", "#33aa66", "#00ccff", "#888877", "#aa5577"],
  3709: ["#9999dd", "#aa4499", "#ff0066", "#9911dd", "#22aadd"],
  3710: ["#ee11dd", "#ddff11", "#6644ee", "#ee44bb", "#eeccff"],
  3711: ["#00aaff", "#66bbcc", "#668833", "#4488cc", "#bb9911"],
  3712: ["#ffaadd", "#aa99cc", "#7722aa", "#4422dd", "#775599"],
  3713: ["#77bbaa", "#aa55bb", "#6644ff", "#aa6677", "#221199"],
  3714: ["#55aa11", "#333344", "#66bb55", "#667744", "#7788ff"],
  3715: ["#335555", "#cc2211", "#aa8844", "#99ee99", "#ff33aa"],
  3716: ["#11ee88", "#dd1144", "#3366cc", "#aaccbb", "#bb66dd"],
  3717: ["#44ff33", "#ee7744", "#dd7722", "#aa11cc", "#eeaa88"],
  3718: ["#bbaacc", "#6633bb", "#aaee88", "#dd7733", "#ff33bb"],
  3719: ["#cc9911", "#99aacc", "#0022aa", "#559922", "#bbeeee"],
  3720: ["#115577", "#2200aa", "#ccbb44", "#117744", "#66ffaa"],
  3721: ["#448888", "#11bbdd", "#3344aa", "#550088", "#77bbdd"],
  3722: ["#eecc11", "#99bbbb", "#66dd00", "#22aa44", "#00dddd"],
  3723: ["#00bb66", "#776655", "#99ff77", "#0066dd", "#5599aa"],
  3724: ["#bbddee", "#00aa11", "#1199bb", "#997722", "#3366ee"],
  3725: ["#bb6622", "#888811", "#ffee99", "#88ff33", "#22ddbb"],
  3726: ["#4433bb", "#bbffaa", "#aa8888", "#881144", "#77eeaa"],
  3727: ["#aa99ff", "#550099", "#99ccdd", "#0033cc", "#bb5588"],
  3728: ["#338844", "#cc77aa", "#22aa33", "#55bb88", "#55bb22"],
  3729: ["#55bb00", "#00dd00", "#cc2244", "#cceeee", "#88cc55"],
  3730: ["#ee22dd", "#99cc66", "#1199aa", "#aaff33", "#33ee99"],
  3731: ["#bb44ff", "#ff5500", "#ffdd77", "#448800", "#449966"],
  3732: ["#ee2255", "#1111ff", "#660066", "#cc77dd", "#222299"],
  3733: ["#aa0077", "#559900", "#997700", "#336688", "#77eeaa"],
  3734: ["#2255ff", "#770055", "#770011", "#ccbbee", "#22bb11"],
  3735: ["#ff88ff", "#55eeee", "#bbaabb", "#00ff77", "#220011"],
  3736: ["#994477", "#773300", "#114488", "#003344", "#881133"],
  3737: ["#aa99dd", "#66bbcc", "#55bb77", "#002233", "#aa0033"],
  3738: ["#11aa22", "#224433", "#dd44bb", "#77ee66", "#1122dd"],
  3739: ["#995577", "#bbccff", "#0066cc", "#668844", "#ddff44"],
  3740: ["#bb66cc", "#99ddaa", "#cc88ff", "#aa4488", "#44cc99"],
  3741: ["#88aaaa", "#993399", "#cc5566", "#11ffbb", "#667700"],
  3742: ["#661177", "#aaff22", "#99aacc", "#668833", "#22ccee"],
  3743: ["#113311", "#aa6644", "#ccaa11", "#ee4411", "#33dd00"],
  3744: ["#005511", "#55ee99", "#aaff99", "#22ffee", "#44ff99"],
  3745: ["#774488", "#ff4488", "#222222", "#44ff44", "#99ee99"],
  3746: ["#1177bb", "#1144bb", "#ee9955", "#cc2299", "#22bb77"],
  3747: ["#1122aa", "#aa7700", "#22ff11", "#bb9955", "#116699"],
  3748: ["#227744", "#777711", "#331111", "#66cccc", "#773366"],
  3749: ["#007777", "#665544", "#448855", "#99aa22", "#995566"],
  3750: ["#999966", "#5588aa", "#22cc44", "#33ccff", "#aa33cc"],
  3751: ["#2222ee", "#110077", "#44dd77", "#1111aa", "#dd5522"],
  3752: ["#002255", "#550055", "#441199", "#66bbcc", "#557711"],
  3753: ["#551111", "#9955dd", "#dd6666", "#aa7722", "#9977bb"],
  3754: ["#ccbb66", "#6611ff", "#7711bb", "#00dddd", "#663311"],
  3755: ["#996611", "#000000", "#ee55cc", "#0022aa", "#eeee44"],
  3756: ["#00aaaa", "#ee7799", "#0066cc", "#446611", "#0099ff"],
  3757: ["#4444cc", "#aaccaa", "#ddff00", "#aadd88", "#ffaa22"],
  3758: ["#bbddee", "#ccdd33", "#00ff99", "#44dd22", "#bb33bb"],
  3759: ["#ffeedd", "#bb2244", "#998855", "#4466cc", "#773377"],
  3760: ["#bb9977", "#bb22cc", "#229955", "#aa6644", "#cc9988"],
  3761: ["#0022cc", "#552211", "#111144", "#aa9922", "#7788ee"],
  3762: ["#6600bb", "#eeccdd", "#0055ff", "#22bbee", "#eeeebb"],
  3763: ["#881100", "#881144", "#337700", "#bb0066", "#772299"],
  3764: ["#9922ff", "#bb22dd", "#003333", "#66ee77", "#cc6622"],
  3765: ["#4400bb", "#aa9955", "#ffaa11", "#008800", "#aa66dd"],
  3766: ["#ccee22", "#774488", "#77cc11", "#eeff22", "#9977ee"],
  3767: ["#22ddbb", "#5555ff", "#dd3333", "#99aa77", "#ffff44"],
  3768: ["#ccbb44", "#ee1133", "#44ee22", "#7722ff", "#009933"],
  3769: ["#cc5566", "#33dd88", "#006699", "#77aa11", "#dd2244"],
  3770: ["#ddaa55", "#3311ee", "#445511", "#dd1177", "#99aa44"],
  3771: ["#aa7777", "#ccdd11", "#eebb33", "#992233", "#dd2277"],
  3772: ["#779911", "#66bb99", "#4422dd", "#ee11ff", "#ffee88"],
  3773: ["#55ffff", "#aa11ee", "#339933", "#aaff44", "#bbff22"],
  3774: ["#55bbee", "#440088", "#7733cc", "#88bbee", "#226666"],
  3775: ["#88cc77", "#ee8899", "#118899", "#eeeeff", "#55eedd"],
  3776: ["#11cc66", "#441199", "#776699", "#44dd00", "#ff99ff"],
  3777: ["#779900", "#44cc88", "#aaee44", "#cceeaa", "#001188"],
  3778: ["#ff1133", "#55cc88", "#66bb44", "#55eebb", "#ff6611"],
  3779: ["#ff8811", "#33bb00", "#99ff77", "#ccdd66", "#22ccff"],
  3780: ["#33aaaa", "#ddee55", "#aa7744", "#ff66cc", "#0011ee"],
  3781: ["#00ffff", "#557766", "#7799dd", "#22ddbb", "#bb4488"],
  3782: ["#00ee99", "#44aa00", "#449911", "#330022", "#ee4499"],
  3783: ["#ee8877", "#bbbb55", "#bbff44", "#666600", "#4477bb"],
  3784: ["#2266ff", "#dd99aa", "#0044dd", "#00aa33", "#224400"],
  3785: ["#110033", "#88ddbb", "#eeaa33", "#ff6688", "#eeff77"],
  3786: ["#00ddff", "#0011bb", "#00ccdd", "#ee99ee", "#cc4455"],
  3787: ["#778877", "#ff00aa", "#aa55bb", "#884488", "#dd5555"],
  3788: ["#119933", "#66cc44", "#229977", "#0055ee", "#220055"],
  3789: ["#bbff55", "#229933", "#33ee99", "#662222", "#77aa22"],
  3790: ["#774400", "#aa7733", "#8800bb", "#ee22dd", "#6655aa"],
  3791: ["#dd11dd", "#3355cc", "#aaddee", "#aa99cc", "#aadd22"],
  3792: ["#cc4466", "#11dd55", "#1166bb", "#44aa77", "#8822ee"],
  3793: ["#eeaa11", "#22aa55", "#aaaa44", "#5500ff", "#663399"],
  3794: ["#11bb00", "#44dd77", "#0033ff", "#44ff11", "#881166"],
  3795: ["#8800ff", "#ddddaa", "#551100", "#336677", "#dd1144"],
  3796: ["#dd7711", "#99aa66", "#441199", "#33ff11", "#ee4433"],
  3797: ["#2288cc", "#bb9922", "#88bb55", "#110022", "#3377dd"],
  3798: ["#22bbcc", "#22dd44", "#226633", "#aa2211", "#bb7766"],
  3799: ["#ff44dd", "#22ee33", "#0088dd", "#55eeaa", "#bbaa44"],
  3800: ["#11bb33", "#44ee00", "#55dd77", "#44bb88", "#ee7744"],
  3801: ["#dd3377", "#ccff00", "#33dd22", "#ff9922", "#7777bb"],
  3802: ["#cc6622", "#ff8899", "#776600", "#666633", "#662266"],
  3803: ["#225588", "#7744aa", "#0022aa", "#6644cc", "#cceedd"],
  3804: ["#11cc88", "#cccc66", "#44aa55", "#5522cc", "#9900dd"],
  3805: ["#cc33dd", "#22cc33", "#000033", "#cc8855", "#558866"],
  3806: ["#ccffff", "#9977cc", "#bbbbdd", "#1111dd", "#ff99cc"],
  3807: ["#227788", "#33ffaa", "#55bb11", "#aadd11", "#444466"],
  3808: ["#331166", "#7766cc", "#eeddee", "#dd2244", "#3333bb"],
  3809: ["#0088ee", "#ccbb55", "#77dd66", "#ddbb33", "#114499"],
  3810: ["#77eeaa", "#33bbaa", "#22aa99", "#00dd99", "#88bb00"],
  3811: ["#996688", "#ccccbb", "#00ff99", "#44aa66", "#ffaabb"],
  3812: ["#33ee77", "#22ff88", "#666666", "#663355", "#889955"],
  3813: ["#ddaa88", "#3344dd", "#77aaaa", "#1133dd", "#008855"],
  3814: ["#663311", "#11dd44", "#44cc00", "#ccaa11", "#33ff33"],
  3815: ["#224411", "#889911", "#33bbbb", "#883377", "#225533"],
  3816: ["#aa5599", "#88dddd", "#aabb33", "#0044cc", "#ee4400"],
  3817: ["#7700dd", "#88ff44", "#ee4444", "#aa3388", "#ffff88"],
  3818: ["#cc4488", "#11dd00", "#115544", "#cceecc", "#1111aa"],
  3819: ["#ddff99", "#cc3355", "#cc1166", "#5577aa", "#ddbb33"],
  3820: ["#aaaaaa", "#669922", "#aa0077", "#4422aa", "#22eeff"],
  3821: ["#887722", "#882244", "#221144", "#331199", "#ff4488"],
  3822: ["#889966", "#2266cc", "#552266", "#66aa33", "#cc55ff"],
  3823: ["#aadd00", "#bb6600", "#7733aa", "#cceedd", "#aa6622"],
  3824: ["#9955ff", "#ccdd22", "#1144ff", "#99dd22", "#ee66aa"],
  3825: ["#009944", "#771199", "#88aa77", "#bb2299", "#882211"],
  3826: ["#331177", "#cc2222", "#330099", "#55bb00", "#3377ff"],
  3827: ["#ccccaa", "#22bb44", "#112255", "#aa8855", "#aaffff"],
  3828: ["#557744", "#eedd55", "#668899", "#55bb88", "#110066"],
  3829: ["#664444", "#77cc66", "#ffbb66", "#66dd33", "#bb1199"],
  3830: ["#ffcc22", "#eeaadd", "#aaaa22", "#991133", "#dddd00"],
  3831: ["#dd0044", "#773344", "#4411dd", "#cc77dd", "#99ff22"],
  3832: ["#aa8899", "#775511", "#99dd33", "#ee5555", "#558855"],
  3833: ["#0011cc", "#6666cc", "#1133ff", "#77ddcc", "#11cc88"],
  3834: ["#ff1144", "#9955dd", "#999955", "#aaaaaa", "#ffffbb"],
  3835: ["#2277ee", "#ee66cc", "#557799", "#554499", "#ccbb66"],
  3836: ["#883333", "#663355", "#ee3300", "#ff5544", "#99aa11"],
  3837: ["#cc3388", "#00aaff", "#7711ee", "#558833", "#dd6622"],
  3838: ["#22ddee", "#ee55aa", "#cc8822", "#5511bb", "#00dd11"],
  3839: ["#55dd44", "#dddd00", "#eeeecc", "#ffbbbb", "#221155"],
  3840: ["#cc99ff", "#22eeaa", "#99dd77", "#332211", "#ddddcc"],
  3841: ["#ee00ee", "#445577", "#ee1100", "#116600", "#556622"],
  3842: ["#44ee00", "#ccffee", "#55cc99", "#117744", "#336666"],
  3843: ["#227766", "#333311", "#ee5555", "#ccaacc", "#ff7700"],
  3844: ["#338800", "#446633", "#ee00aa", "#33ffcc", "#7722dd"],
  3845: ["#443300", "#11aabb", "#aaddee", "#ee66aa", "#11bb99"],
  3846: ["#dd8855", "#3399cc", "#8899dd", "#ccaa11", "#99aa99"],
  3847: ["#ff5511", "#00ddaa", "#1100aa", "#991155", "#44bbcc"],
  3848: ["#11eecc", "#ddaa11", "#991199", "#ff7722", "#bb2211"],
  3849: ["#66ff22", "#11bb66", "#55dd88", "#55bbcc", "#eeee44"],
  3850: ["#99ee33", "#cc4433", "#ddaa00", "#0011bb", "#773377"],
  3851: ["#77bbbb", "#dd6655", "#5511ee", "#8800cc", "#220011"],
  3852: ["#223344", "#ff6600", "#66ccee", "#ff55aa", "#116666"],
  3853: ["#99aa33", "#4488bb", "#77ee77", "#221166", "#115577"],
  3854: ["#ff6611", "#22ee88", "#33aaff", "#779933", "#dd0022"],
  3855: ["#007700", "#dd22ff", "#11dd88", "#7788cc", "#5566aa"],
  3856: ["#ccccdd", "#661155", "#446611", "#ccff00", "#bbaacc"],
  3857: ["#22ddee", "#00ee44", "#550011", "#440000", "#bbee00"],
  3858: ["#33ee11", "#dd22ee", "#88bb99", "#dd1144", "#ee66dd"],
  3859: ["#bbccff", "#cc2255", "#33eebb", "#ffbb44", "#22ffcc"],
  3860: ["#666655", "#77bbee", "#996699", "#559911", "#aa5511"],
  3861: ["#88cc66", "#553333", "#2244bb", "#445577", "#11bb55"],
  3862: ["#eeaacc", "#11bb99", "#ff6655", "#9988ff", "#888899"],
  3863: ["#4433dd", "#22ddff", "#aaaa99", "#1100cc", "#dd4422"],
  3864: ["#ee22bb", "#cc33ee", "#556622", "#dd00dd", "#88ddcc"],
  3865: ["#9988dd", "#bbccdd", "#ff11bb", "#ffaabb", "#33aa99"],
  3866: ["#00ccee", "#cc33ee", "#772200", "#994499", "#996600"],
  3867: ["#ee1177", "#5566bb", "#eeeeee", "#442222", "#0033cc"],
  3868: ["#993388", "#22aa44", "#338811", "#ff77dd", "#bb2222"],
  3869: ["#dd0055", "#44aadd", "#bb5588", "#558800", "#dd33ff"],
  3870: ["#cc11ff", "#00ee00", "#44aaaa", "#ff99cc", "#bbdd33"],
  3871: ["#55cccc", "#cc33ee", "#00ccaa", "#001100", "#334444"],
  3872: ["#aaee00", "#dddddd", "#ddcc22", "#114499", "#44ccff"],
  3873: ["#bb8822", "#33ffdd", "#66ddcc", "#ddaa99", "#bbbb66"],
  3874: ["#dd0099", "#1177ff", "#cc0000", "#dd4488", "#cc66dd"],
  3875: ["#cc22cc", "#bb1155", "#ddff11", "#bbdd66", "#ffee77"],
  3876: ["#aa4455", "#4433dd", "#88ffff", "#cc33dd", "#cc6688"],
  3877: ["#777733", "#cc5533", "#2266ee", "#66cc22", "#cc8899"],
  3878: ["#664444", "#ee88bb", "#3311aa", "#ccffdd", "#22aaff"],
  3879: ["#119999", "#2277aa", "#447733", "#77aabb", "#009911"],
  3880: ["#aa9922", "#ff00ee", "#7711ff", "#448899", "#aa7766"],
  3881: ["#ff0044", "#bbee77", "#778855", "#66eedd", "#88eeff"],
  3882: ["#443366", "#ddff22", "#aaffee", "#8811bb", "#ff8899"],
  3883: ["#bb2255", "#44aabb", "#115588", "#dd1100", "#ff2200"],
  3884: ["#eeff11", "#bb1155", "#aa11bb", "#bb5555", "#ff7799"],
  3885: ["#6611dd", "#004477", "#cc88dd", "#bb8800", "#ee3322"],
  3886: ["#ff55cc", "#cc11aa", "#7755cc", "#774455", "#bb5588"],
  3887: ["#885599", "#880055", "#9944ee", "#9977aa", "#eeee99"],
  3888: ["#aaee99", "#55dd77", "#ff33ff", "#998833", "#11dd11"],
  3889: ["#aa11aa", "#ee8800", "#ff6633", "#3311ee", "#22cc66"],
  3890: ["#22aa55", "#77dd77", "#997722", "#33cc22", "#445511"],
  3891: ["#551111", "#3322cc", "#337733", "#ddccee", "#661199"],
  3892: ["#dd5577", "#ddbbee", "#7766ff", "#0033ee", "#ffccee"],
  3893: ["#cc8844", "#11bb33", "#dd8811", "#44cc66", "#000077"],
  3894: ["#44bb33", "#22eeff", "#77cc99", "#555555", "#996655"],
  3895: ["#66aa88", "#eeee00", "#337788", "#1188cc", "#667755"],
  3896: ["#ddbb88", "#551166", "#aa88bb", "#dd6611", "#773388"],
  3897: ["#7766ff", "#00dd55", "#220022", "#11cc99", "#aa1111"],
  3898: ["#bb7711", "#bb11aa", "#99ee55", "#aaddee", "#99ff55"],
  3899: ["#77ffaa", "#bb0088", "#889966", "#dd2266", "#bbee00"],
  3900: ["#5566bb", "#881199", "#448877", "#bb8800", "#559911"],
  3901: ["#00bbcc", "#77bb44", "#cc7799", "#225588", "#eeff88"],
  3902: ["#11bbee", "#668877", "#cc5566", "#33dd55", "#4422aa"],
  3903: ["#11ee11", "#bbeedd", "#ddeeaa", "#3300bb", "#ddbbdd"],
  3904: ["#558822", "#992211", "#bbdd00", "#660077", "#11aadd"],
  3905: ["#33cc55", "#aacc99", "#11eebb", "#88bb11", "#ccddcc"],
  3906: ["#bb4422", "#77bbcc", "#8888ee", "#334488", "#552222"],
  3907: ["#9922ee", "#bbbbaa", "#ff4455", "#3377ee", "#bb88dd"],
  3908: ["#55dd44", "#991122", "#5522cc", "#991188", "#bbee77"],
  3909: ["#66bbff", "#ee7799", "#118844", "#440077", "#991122"],
  3910: ["#99aaee", "#bb0011", "#66bb88", "#223300", "#3388ff"],
  3911: ["#339988", "#ff6699", "#dd6622", "#aa5566", "#22ee11"],
  3912: ["#332299", "#ffaa44", "#113399", "#994488", "#bbeeee"],
  3913: ["#33aa33", "#996655", "#885522", "#33bb11", "#4433ee"],
  3914: ["#1177cc", "#00cc88", "#44dd44", "#ff99cc", "#770033"],
  3915: ["#99aa77", "#7766ee", "#331122", "#ff33dd", "#772277"],
  3916: ["#99ffcc", "#8877cc", "#773399", "#008833", "#ccbb44"],
  3917: ["#00ccff", "#ddccff", "#ff11ee", "#3333bb", "#9999dd"],
  3918: ["#ffcc44", "#2244bb", "#11cc11", "#eebb55", "#99dddd"],
  3919: ["#ff33bb", "#22cc22", "#6622aa", "#221144", "#66aa66"],
  3920: ["#aaffaa", "#337755", "#cc6633", "#995555", "#228833"],
  3921: ["#66cc44", "#0033bb", "#667777", "#bbffbb", "#22aa77"],
  3922: ["#aa00dd", "#5500bb", "#00bb88", "#2255dd", "#ccbb77"],
  3923: ["#88dd66", "#aacc88", "#dd77aa", "#99ee22", "#446655"],
  3924: ["#66bbaa", "#eeee11", "#aaaa44", "#ee0000", "#dd0000"],
  3925: ["#ee7766", "#99ee99", "#77cc99", "#1100ff", "#bb55bb"],
  3926: ["#2244dd", "#aaff11", "#22ddee", "#55eedd", "#00aaaa"],
  3927: ["#009955", "#dd8877", "#5599bb", "#ff1177", "#008866"],
  3928: ["#ddffcc", "#ee11ff", "#ee6655", "#0033cc", "#dd5599"],
  3929: ["#00aa99", "#4422bb", "#cc00aa", "#88eeaa", "#33cc66"],
  3930: ["#66ff00", "#aa88dd", "#aa9966", "#9977ff", "#dd8811"],
  3931: ["#bb2277", "#221199", "#110011", "#ff99aa", "#ff44ff"],
  3932: ["#1111cc", "#cc55ff", "#119955", "#bb22cc", "#994477"],
  3933: ["#33cc66", "#99eeee", "#997744", "#8800ee", "#7722ff"],
  3934: ["#0077aa", "#bb4455", "#997799", "#4466aa", "#44ddee"],
  3935: ["#bbffff", "#115522", "#884499", "#887722", "#33ff66"],
  3936: ["#dddd22", "#552233", "#1188cc", "#992255", "#440088"],
  3937: ["#11dddd", "#99dddd", "#ffee00", "#66bbbb", "#77ffaa"],
  3938: ["#22ff77", "#aabb22", "#66ff55", "#0066ee", "#00dd55"],
  3939: ["#ff2233", "#774444", "#9966cc", "#ee22dd", "#9922cc"],
  3940: ["#3333dd", "#338833", "#66ddee", "#991155", "#001133"],
  3941: ["#770011", "#bbddcc", "#dd55ff", "#bbdd22", "#bbdd00"],
  3942: ["#dd88ff", "#553344", "#dd6644", "#229955", "#6644ff"],
  3943: ["#44ddcc", "#bb99aa", "#99ee33", "#771133", "#442211"],
  3944: ["#ddff88", "#7799bb", "#ccdd00", "#99dd00", "#55aa44"],
  3945: ["#44cc22", "#44bb44", "#333366", "#117722", "#11bb44"],
  3946: ["#4477ff", "#559999", "#11bb11", "#44cc99", "#aa88cc"],
  3947: ["#11aa00", "#447755", "#ff8877", "#886644", "#77bb77"],
  3948: ["#441199", "#88bb33", "#113388", "#446688", "#aa1155"],
  3949: ["#778888", "#bbaa66", "#ff33bb", "#6644cc", "#2288bb"],
  3950: ["#33ddcc", "#88ff99", "#99ffff", "#66ee99", "#7700cc"],
  3951: ["#ff1133", "#dd5599", "#aa2266", "#4499ee", "#77cc66"],
  3952: ["#669922", "#009933", "#eeaaee", "#668833", "#bb1188"],
  3953: ["#cccc55", "#aa9977", "#cc3377", "#66bbee", "#0011bb"],
  3954: ["#1122bb", "#774455", "#99ee66", "#ee88ff", "#009944"],
  3955: ["#33bb11", "#774466", "#773399", "#bbaa99", "#775577"],
  3956: ["#ddcc44", "#444499", "#dd66cc", "#9900aa", "#aaff77"],
  3957: ["#ddaa99", "#cccc44", "#5588aa", "#99dd33", "#aa4422"],
  3958: ["#44cc33", "#88ee77", "#bb0022", "#999999", "#22bb88"],
  3959: ["#44bbdd", "#55dd66", "#77cc55", "#44bb00", "#3300ee"],
  3960: ["#7700aa", "#55ffaa", "#22ddee", "#88ccbb", "#113388"],
  3961: ["#66ff77", "#ee6677", "#cc8855", "#eeccbb", "#5566bb"],
  3962: ["#22ff00", "#556699", "#778899", "#cc0044", "#00eedd"],
  3963: ["#aa44ff", "#ffee99", "#557700", "#557777", "#22cc88"],
  3964: ["#44bb11", "#444499", "#3311dd", "#aaeeaa", "#ccaacc"],
  3965: ["#44cc99", "#880099", "#66dd66", "#332200", "#77ff22"],
  3966: ["#333377", "#dd2255", "#ff4466", "#ccffee", "#995566"],
  3967: ["#77dd11", "#bb5522", "#aa55ee", "#664477", "#aacc99"],
  3968: ["#000077", "#55ccff", "#ff9911", "#ee99dd", "#00ccdd"],
  3969: ["#55ee44", "#aa8822", "#ddcc88", "#bbff77", "#8866cc"],
  3970: ["#001166", "#ccbbdd", "#4400aa", "#006688", "#77ddee"],
  3971: ["#bbbb55", "#ff6655", "#997711", "#22ccaa", "#554444"],
  3972: ["#553333", "#553322", "#33dd44", "#441133", "#ccaa11"],
  3973: ["#119999", "#ff5566", "#bb88bb", "#226622", "#8822ee"],
  3974: ["#1177ff", "#55bb11", "#ee66ff", "#334477", "#444411"],
  3975: ["#336611", "#aa44ff", "#88ccaa", "#4499ff", "#116699"],
  3976: ["#bb8866", "#ccffcc", "#77ddee", "#88aadd", "#ddee00"],
  3977: ["#ccccaa", "#00cc55", "#4422ff", "#113311", "#440011"],
  3978: ["#dd7788", "#1199aa", "#bbffdd", "#9966aa", "#bb7766"],
  3979: ["#55ff99", "#339933", "#ccaa66", "#cc4433", "#118811"],
  3980: ["#66cccc", "#00cccc", "#00bbdd", "#55aa99", "#5588cc"],
  3981: ["#77cc44", "#ee55ee", "#00ccaa", "#884477", "#113377"],
  3982: ["#992244", "#33bb77", "#99ee66", "#ff7700", "#222255"],
  3983: ["#8877aa", "#33dd22", "#dd9911", "#661100", "#00dd77"],
  3984: ["#55ccff", "#ddbb22", "#aa88ff", "#00ff99", "#441166"],
  3985: ["#7766ff", "#2255aa", "#3344cc", "#994444", "#99dd00"],
  3986: ["#ccccaa", "#ee1122", "#776644", "#2200cc", "#9966cc"],
  3987: ["#bb44aa", "#22dd11", "#88ccee", "#009900", "#ff4499"],
  3988: ["#882233", "#55bb11", "#ddbbdd", "#3399bb", "#ffeeff"],
  3989: ["#770055", "#3322cc", "#22dd33", "#ff1122", "#778811"],
  3990: ["#77ff66", "#5599cc", "#440066", "#ee8811", "#55aaff"],
  3991: ["#22eeee", "#aabbee", "#778844", "#55bb44", "#aa3366"],
  3992: ["#77bb11", "#666611", "#ccaa66", "#667733", "#ee00aa"],
  3993: ["#cc3333", "#337722", "#22bb77", "#77cccc", "#0022aa"],
  3994: ["#bbaadd", "#ddee22", "#664433", "#994499", "#22ff88"],
  3995: ["#55ff44", "#22ccff", "#aaee11", "#11ffee", "#ddee77"],
  3996: ["#22aaee", "#992222", "#cc2222", "#dd2266", "#ff2244"],
  3997: ["#550077", "#114466", "#eeeebb", "#aa9900", "#2200aa"],
  3998: ["#55ccbb", "#5588ff", "#55ee88", "#332255", "#ffaaff"],
  3999: ["#ddee33", "#dd7722", "#113344", "#0011cc", "#770088"],
  4000: ["#ee6677", "#779999", "#ff0099", "#ffdd55", "#99bb55"],
  4001: ["#cc55bb", "#88cc77", "#222277", "#553344", "#556688"],
  4002: ["#9966bb", "#0011ff", "#33eeff", "#cc22bb", "#779955"],
  4003: ["#449900", "#cc1177", "#555555", "#442288", "#ee44ff"],
  4004: ["#2233ff", "#664466", "#ff33ff", "#ee2277", "#bb4400"],
  4005: ["#332255", "#005588", "#11bb22", "#889988", "#aa4411"],
  4006: ["#ddff00", "#22dd88", "#eeffee", "#337744", "#889922"],
  4007: ["#661188", "#66ff22", "#aa11cc", "#118811", "#993377"],
  4008: ["#555511", "#cc55bb", "#11bb00", "#bbff11", "#000077"],
  4009: ["#bb1166", "#77dd77", "#bbcc11", "#5588aa", "#77eedd"],
  4010: ["#77dd22", "#558899", "#eeee11", "#bb9955", "#dd6655"],
  4011: ["#994455", "#eeeeaa", "#77bb77", "#2211cc", "#99cc55"],
  4012: ["#9922ff", "#6600ff", "#882200", "#9911ff", "#4433ff"],
  4013: ["#8822cc", "#aa1100", "#88ccee", "#aacc55", "#228833"],
  4014: ["#11aabb", "#2288bb", "#aa1155", "#5511ee", "#33cccc"],
  4015: ["#332222", "#6655bb", "#33cc44", "#991100", "#885533"],
  4016: ["#996699", "#ff6699", "#eebbff", "#113300", "#9999cc"],
  4017: ["#441133", "#004433", "#55ff00", "#336655", "#55cc99"],
  4018: ["#55bbbb", "#99eecc", "#9900ff", "#88ff55", "#ff4400"],
  4019: ["#99ddee", "#77bb66", "#99aa66", "#992277", "#2299cc"],
  4020: ["#11aa55", "#772255", "#ee22aa", "#00dd88", "#aa33dd"],
  4021: ["#441144", "#22ee66", "#770044", "#337788", "#552277"],
  4022: ["#8855bb", "#00eebb", "#aaaacc", "#ddcc55", "#aaaaaa"],
  4023: ["#332222", "#ff33bb", "#cc0000", "#22ff55", "#ff11cc"],
  4024: ["#5500aa", "#cc7755", "#ff55aa", "#99bbbb", "#ee0099"],
  4025: ["#771122", "#554422", "#ccbbff", "#559955", "#aa9977"],
  4026: ["#cc33aa", "#bb9966", "#ccaabb", "#99ff33", "#771144"],
  4027: ["#ff4444", "#bb2255", "#aaaacc", "#bbddee", "#eedd55"],
  4028: ["#11cc88", "#55ff00", "#dd9955", "#ee7766", "#bb4455"],
  4029: ["#aa7722", "#444499", "#770099", "#bb9900", "#00ee66"],
  4030: ["#112233", "#997799", "#aa2277", "#44cccc", "#88cc33"],
  4031: ["#889977", "#cc77cc", "#99ee00", "#ff88bb", "#662277"],
  4032: ["#557711", "#882255", "#338811", "#448844", "#dd5511"],
  4033: ["#88ff88", "#dd7766", "#7777ff", "#aa7799", "#bbaa11"],
  4034: ["#993355", "#776600", "#ee77dd", "#bbccbb", "#4444aa"],
  4035: ["#22ee33", "#ff3300", "#002211", "#880044", "#55aaaa"],
  4036: ["#9977ff", "#997733", "#ccbb33", "#227733", "#99bb55"],
  4037: ["#dd55ff", "#22eeff", "#77ddbb", "#448811", "#ffffdd"],
  4038: ["#776644", "#557799", "#882211", "#22ffee", "#77aa99"],
  4039: ["#cc77cc", "#aa6611", "#66cc44", "#cc11cc", "#00eecc"],
  4040: ["#66ff00", "#9988cc", "#331177", "#ffeecc", "#8844ff"],
  4041: ["#ccaa00", "#8800bb", "#770066", "#778800", "#881122"],
  4042: ["#886633", "#227733", "#dd6666", "#449955", "#66eeee"],
  4043: ["#bb7777", "#22aa33", "#4477bb", "#88bbdd", "#99aa88"],
  4044: ["#ee22cc", "#aa3377", "#442222", "#66bbbb", "#99bb11"],
  4045: ["#ffff33", "#ff7700", "#cc00ff", "#551155", "#332255"],
  4046: ["#884422", "#77ffbb", "#22ff33", "#009977", "#22ee00"],
  4047: ["#111133", "#dd5500", "#44ee88", "#aa5533", "#665511"],
  4048: ["#cc8844", "#ffaa22", "#dd00aa", "#5533dd", "#228866"],
  4049: ["#ffaa77", "#ff4411", "#22ee88", "#99bb55", "#227744"],
  4050: ["#99bb00", "#66ee00", "#00ddbb", "#778866", "#88dd00"],
  4051: ["#4411ee", "#88ccff", "#11eeaa", "#668844", "#44cc33"],
  4052: ["#882277", "#0044aa", "#ee88aa", "#dd5500", "#5566ff"],
  4053: ["#5577ff", "#1155ee", "#442244", "#558888", "#228800"],
  4054: ["#ff99ee", "#aacc88", "#558866", "#66aaaa", "#ee33dd"],
  4055: ["#11bbcc", "#aa00ee", "#55bbcc", "#ccdd88", "#dd7799"],
  4056: ["#ddddff", "#9966dd", "#335500", "#33dd77", "#2288cc"],
  4057: ["#66ee55", "#11aa99", "#773388", "#223399", "#77ff44"],
  4058: ["#dd4499", "#22bb11", "#004444", "#22ffdd", "#0066cc"],
  4059: ["#9933ff", "#22bbff", "#664400", "#88aadd", "#11ee22"],
  4060: ["#5566dd", "#44eebb", "#33cc99", "#aacc22", "#99ddee"],
  4061: ["#663366", "#444488", "#ee00ee", "#334488", "#4433cc"],
  4062: ["#1133aa", "#009944", "#ccff00", "#223388", "#aaffdd"],
  4063: ["#ddee66", "#eecc55", "#1177bb", "#ccbbcc", "#99dd00"],
  4064: ["#ff1111", "#335555", "#555544", "#88bb00", "#116655"],
  4065: ["#884488", "#99aa66", "#00aa33", "#88dd22", "#4444bb"],
  4066: ["#ddff44", "#00dd11", "#668888", "#2299bb", "#66aabb"],
  4067: ["#66cc11", "#ff66dd", "#ccdd11", "#11eeaa", "#cc88bb"],
  4068: ["#11eeff", "#994455", "#77cc66", "#cc88dd", "#eeaacc"],
  4069: ["#116611", "#44dd88", "#227777", "#11cccc", "#441133"],
  4070: ["#444499", "#bbcc44", "#99cc77", "#66ff55", "#bb2233"],
  4071: ["#7777ff", "#ddffcc", "#333322", "#993388", "#bb2288"],
  4072: ["#226611", "#aaaadd", "#880088", "#88ff88", "#331133"],
  4073: ["#3399aa", "#bb6633", "#dd44cc", "#ee9955", "#ffff66"],
  4074: ["#3300aa", "#aaff66", "#cc88ff", "#ddff44", "#3300ee"],
  4075: ["#bb2200", "#2211cc", "#44cc77", "#ff4499", "#8877bb"],
  4076: ["#442288", "#9911dd", "#991100", "#55ffcc", "#222255"],
  4077: ["#ccdd00", "#ff1155", "#002277", "#115544", "#bbff00"],
  4078: ["#8811aa", "#8888dd", "#555599", "#66cc22", "#66dd99"],
  4079: ["#668822", "#55ddee", "#ff9955", "#bb1133", "#ddff88"],
  4080: ["#443311", "#119922", "#8800bb", "#003355", "#ff99aa"],
  4081: ["#3344ee", "#4422aa", "#aa55bb", "#9922cc", "#dd22ff"],
  4082: ["#66aa22", "#bbdddd", "#22bb55", "#66bbbb", "#44cc44"],
  4083: ["#bbff33", "#11ee22", "#aabbdd", "#dd7711", "#66ff99"],
  4084: ["#334455", "#661111", "#446699", "#bbffee", "#dd3399"],
  4085: ["#aa33ee", "#ff9933", "#4488ee", "#112222", "#112288"],
  4086: ["#bbdd99", "#bb9911", "#114499", "#992244", "#77cc33"],
  4087: ["#2277ff", "#3377ff", "#7744ff", "#4422cc", "#bbcc22"],
  4088: ["#2299dd", "#55bb11", "#44bbff", "#ff77aa", "#115511"],
  4089: ["#dd6633", "#44ee00", "#00bb99", "#115544", "#99dd22"],
  4090: ["#aaddff", "#dd66bb", "#004411", "#33ffcc", "#55aa66"],
  4091: ["#332233", "#bb77dd", "#2288ff", "#ff0011", "#aa9933"],
  4092: ["#bb99aa", "#117744", "#eeaa22", "#ff5522", "#44ff22"],
  4093: ["#1111dd", "#ee99aa", "#994411", "#66eeaa", "#5577ff"],
  4094: ["#ff99aa", "#553344", "#cceebb", "#aa11ee", "#2277cc"],
  4095: ["#99ee00", "#998822", "#0055ee", "#bbcc44", "#661144"],
  4096: ["#cc3388", "#99ee66", "#6666cc", "#66cc77", "#eeaa11"],
  4097: ["#cc55dd", "#eeddcc", "#ee2200", "#aa1166", "#dd77cc"],
  4098: ["#ff00cc", "#665500", "#446611", "#222288", "#883344"],
  4099: ["#335500", "#aabb44", "#662200", "#33ddcc", "#99bb55"],
  4100: ["#ccbbcc", "#ff3366", "#008866", "#bbff66", "#225522"],
  4101: ["#ee4400", "#eebb88", "#44ff88", "#337722", "#886677"],
  4102: ["#225577", "#998822", "#886677", "#22eecc", "#998888"],
  4103: ["#22aa99", "#220011", "#55ee22", "#77cc22", "#44aa88"],
  4104: ["#bb2233", "#eebb00", "#882277", "#99eecc", "#aaff11"],
  4105: ["#449900", "#66dd44", "#cc0033", "#22ff22", "#0077ff"],
  4106: ["#9944ff", "#220066", "#447700", "#111100", "#dd1199"],
  4107: ["#881100", "#441144", "#00bb22", "#dd88ff", "#55cc44"],
  4108: ["#11bb88", "#7777dd", "#8833cc", "#cc4499", "#99bbaa"],
  4109: ["#77aa77", "#005599", "#aa7722", "#8844aa", "#009922"],
  4110: ["#bb8833", "#bbaacc", "#ff2299", "#0011dd", "#9988dd"],
  4111: ["#eebb00", "#aa5555", "#bb99ff", "#aabb00", "#9977bb"],
  4112: ["#9988bb", "#ddff88", "#2266ff", "#77aa77", "#77cc77"],
  4113: ["#995577", "#99ff00", "#4466ff", "#aa6655", "#dd33ff"],
  4114: ["#99ff66", "#bb5566", "#eeaaff", "#000077", "#11eebb"],
  4115: ["#6688cc", "#44ccee", "#442244", "#cc00bb", "#660022"],
  4116: ["#dddddd", "#dd77ee", "#557744", "#55dd11", "#88dd11"],
  4117: ["#99cccc", "#cc66aa", "#2200ff", "#77eeff", "#cc33ff"],
  4118: ["#ddee11", "#bbee44", "#001177", "#9966ff", "#777722"],
  4119: ["#cc4411", "#44dd77", "#55ccff", "#ff33ff", "#779933"],
  4120: ["#77ff11", "#ee9999", "#4466aa", "#bb6666", "#6622cc"],
  4121: ["#bb66dd", "#44bb11", "#aabb99", "#886677", "#cc1133"],
  4122: ["#88dd00", "#777788", "#338800", "#88dd88", "#88bb22"],
  4123: ["#aa00dd", "#441155", "#dd1144", "#00ffbb", "#0088aa"],
  4124: ["#aa3300", "#669999", "#112211", "#66bbbb", "#117777"],
  4125: ["#224433", "#007700", "#eecc77", "#bb66bb", "#bb4444"],
  4126: ["#883399", "#110033", "#ff11aa", "#441111", "#883333"],
  4127: ["#ddcc33", "#aa99ee", "#cccccc", "#99ee22", "#55ee00"],
  4128: ["#557733", "#ee88ee", "#6644aa", "#cc3388", "#6644aa"],
  4129: ["#5566cc", "#882200", "#778888", "#bb88bb", "#0088cc"],
  4130: ["#77cc99", "#449922", "#ff1133", "#4477ff", "#44bb11"],
  4131: ["#00dd77", "#66ddee", "#882288", "#99ffaa", "#cccc66"],
  4132: ["#55ccbb", "#77cc11", "#994466", "#112200", "#885555"],
  4133: ["#ff9977", "#443322", "#0099ee", "#441155", "#ee0044"],
  4134: ["#55ff99", "#44ee44", "#00cc66", "#ff00ff", "#88ee33"],
  4135: ["#5533dd", "#cc55ff", "#aa9977", "#119911", "#11bb33"],
  4136: ["#334411", "#ee1144", "#669955", "#11bbbb", "#77ffbb"],
  4137: ["#555500", "#ee1166", "#448811", "#cc7733", "#cc9933"],
  4138: ["#dd44dd", "#559900", "#22aaee", "#0033aa", "#ccaa11"],
  4139: ["#bb4422", "#333322", "#ff8844", "#995566", "#2211ee"],
  4140: ["#661177", "#44eebb", "#aaddee", "#bb77ee", "#5577ee"],
  4141: ["#66aa11", "#cc88cc", "#cc55ee", "#ccbb22", "#226677"],
  4142: ["#99dddd", "#889933", "#886644", "#bbee99", "#002211"],
  4143: ["#881199", "#772277", "#44aa99", "#003366", "#66ddee"],
  4144: ["#88ccbb", "#22ddee", "#2222aa", "#8899aa", "#33aacc"],
  4145: ["#0022ff", "#bbee11", "#77eebb", "#ff99dd", "#0022cc"],
  4146: ["#11bb77", "#223333", "#33ee44", "#dd1177", "#661177"],
  4147: ["#ee11aa", "#1122bb", "#337766", "#bb6622", "#3311aa"],
  4148: ["#777733", "#aadd55", "#667766", "#aa3344", "#44cc00"],
  4149: ["#880088", "#223300", "#881133", "#001199", "#6699ff"],
  4150: ["#227711", "#777766", "#6600ff", "#55ccbb", "#88aa33"],
  4151: ["#66ff22", "#003388", "#331133", "#bb4411", "#ff8899"],
  4152: ["#884411", "#886666", "#ccaa00", "#11bb33", "#44cc44"],
  4153: ["#77ee33", "#bb5522", "#ee88ff", "#99bb77", "#00cc99"],
  4154: ["#cc9966", "#337799", "#ee4488", "#4466ee", "#cc8888"],
  4155: ["#eecc11", "#884477", "#00ffee", "#88ddbb", "#ccbb33"],
  4156: ["#2266dd", "#009933", "#003366", "#ccbb00", "#ff00dd"],
  4157: ["#3333cc", "#cc00dd", "#22ccdd", "#0077ee", "#eebb22"],
  4158: ["#99ff99", "#119944", "#ee7755", "#119966", "#66ddbb"],
  4159: ["#22ee77", "#00ffff", "#77ee33", "#1144ff", "#998800"],
  4160: ["#22ccbb", "#dd5577", "#66ff88", "#44bbaa", "#cc4455"],
  4161: ["#22ccee", "#225577", "#eeee88", "#992222", "#006699"],
  4162: ["#6633ee", "#ff77dd", "#776633", "#55cc99", "#cc5566"],
  4163: ["#880077", "#991188", "#55dd55", "#55bb55", "#ff99ff"],
  4164: ["#990011", "#bbbbcc", "#aaeecc", "#aa8800", "#aabb99"],
  4165: ["#bb22bb", "#44ee11", "#998844", "#88bb88", "#7755dd"],
  4166: ["#11bbee", "#0011aa", "#662266", "#ffdd88", "#00aaaa"],
  4167: ["#aa7755", "#aa88ee", "#88ff66", "#bb3355", "#00eebb"],
  4168: ["#7744dd", "#33cccc", "#cc5566", "#55ee88", "#bb2222"],
  4169: ["#00ffee", "#8877cc", "#dd22dd", "#bbeecc", "#6688aa"],
  4170: ["#559900", "#aa22cc", "#ff2211", "#66aa33", "#bb3333"],
  4171: ["#bbaabb", "#447788", "#22ccee", "#119944", "#aa0011"],
  4172: ["#009933", "#008844", "#dd66cc", "#ee00dd", "#88ee55"],
  4173: ["#99cc22", "#ee00cc", "#0033bb", "#771133", "#aaee44"],
  4174: ["#bbaa77", "#ee4477", "#bbaaff", "#006699", "#667722"],
  4175: ["#aabb33", "#662255", "#44dd44", "#991144", "#ccdd11"],
  4176: ["#22ffaa", "#444455", "#ff99dd", "#bb4499", "#66bb33"],
  4177: ["#00ffaa", "#33ddff", "#77ff99", "#22ffbb", "#ffddaa"],
  4178: ["#ee22dd", "#333300", "#8866dd", "#777766", "#77dd77"],
  4179: ["#aa00ee", "#66bb44", "#669955", "#007755", "#661199"],
  4180: ["#00ddee", "#8833ee", "#bb2211", "#6666cc", "#4499aa"],
  4181: ["#440055", "#eebb33", "#ffdd77", "#557744", "#9977ee"],
  4182: ["#ddffbb", "#3333ee", "#778811", "#55aaff", "#6677bb"],
  4183: ["#224422", "#778899", "#88ddff", "#006699", "#44bbff"],
  4184: ["#333311", "#333344", "#7755dd", "#5500cc", "#dd99bb"],
  4185: ["#883377", "#227722", "#00dddd", "#77ddaa", "#eeee11"],
  4186: ["#44ccdd", "#11cc33", "#554466", "#cc33ee", "#3311aa"],
  4187: ["#11bb00", "#663311", "#ddcc55", "#ffee66", "#331133"],
  4188: ["#66ee99", "#aa99dd", "#44dd77", "#6677ee", "#0055aa"],
  4189: ["#ddbbcc", "#11dd66", "#9977cc", "#00dd88", "#88bbee"],
  4190: ["#77ff88", "#ee9933", "#bb9988", "#aa1188", "#aa66ee"],
  4191: ["#99dd11", "#77bb77", "#8833bb", "#664444", "#00ff99"],
  4192: ["#ee2255", "#003344", "#55aa11", "#66aa11", "#ffcc44"],
  4193: ["#2222bb", "#ee0044", "#ee0044", "#99ee33", "#aa0088"],
  4194: ["#229966", "#55cc99", "#ee0066", "#dd44dd", "#aa55ee"],
  4195: ["#77bb55", "#ee0022", "#0088aa", "#bb8822", "#ffccdd"],
  4196: ["#99ffff", "#ee77ee", "#22ff66", "#3377dd", "#cc66ee"],
  4197: ["#aa7788", "#5599ff", "#554433", "#ddff22", "#44ff88"],
  4198: ["#886633", "#cc44ff", "#22ddff", "#aa11aa", "#44ee11"],
  4199: ["#552211", "#ff1133", "#ff1122", "#001111", "#aa33ee"],
  4200: ["#777711", "#dd4466", "#44bbff", "#dd00ff", "#110055"],
  4201: ["#770044", "#223311", "#44dd00", "#777744", "#ee1188"],
  4202: ["#11dd33", "#33ddcc", "#116633", "#88dddd", "#66ee44"],
  4203: ["#ff7788", "#9977ee", "#cc8855", "#99cc66", "#44bb55"],
  4204: ["#aa44cc", "#ee8855", "#ee00aa", "#33bbcc", "#227777"],
  4205: ["#9966dd", "#335533", "#cc2222", "#11dd00", "#aa6655"],
  4206: ["#11dd00", "#88ff88", "#22aa66", "#cc3311", "#88ff66"],
  4207: ["#11ddee", "#77aa44", "#33cc00", "#ee3355", "#cc33bb"],
  4208: ["#ccdd88", "#770055", "#66bb00", "#44ff22", "#554433"],
  4209: ["#88aa66", "#ff1144", "#664499", "#77cc11", "#99ffff"],
  4210: ["#440077", "#cc3333", "#eeaa22", "#441177", "#aa0066"],
  4211: ["#ccaa33", "#ee99ff", "#77ff88", "#3355ff", "#aaee00"],
  4212: ["#778866", "#668888", "#446666", "#ffcc88", "#5577bb"],
  4213: ["#33dd22", "#225522", "#dd7711", "#999911", "#ffee00"],
  4214: ["#0044ee", "#ddbbbb", "#8855ee", "#006699", "#665588"],
  4215: ["#115588", "#008888", "#11ff44", "#bb44cc", "#ee1122"],
  4216: ["#11bb00", "#663377", "#ffbbee", "#aa55ee", "#228855"],
  4217: ["#448800", "#77ddee", "#dd11bb", "#eeee88", "#339966"],
  4218: ["#445566", "#552244", "#dd8888", "#008811", "#aaffff"],
  4219: ["#00ffdd", "#55bbdd", "#666688", "#99bbcc", "#ff00bb"],
  4220: ["#550088", "#bb2277", "#7799dd", "#778866", "#bb44aa"],
  4221: ["#aa2244", "#ff2288", "#88dd33", "#99eeff", "#99bb99"],
  4222: ["#6666bb", "#77ffbb", "#660022", "#88bbbb", "#aaffbb"],
  4223: ["#11ff88", "#ff11ff", "#003322", "#88bb22", "#550099"],
  4224: ["#886699", "#eecc22", "#eebbaa", "#4488ff", "#557700"],
  4225: ["#cc99ff", "#99ccff", "#aa9977", "#aa2288", "#1166ff"],
  4226: ["#118866", "#444488", "#ccaa00", "#11aa99", "#6699ee"],
  4227: ["#cc4477", "#66ccaa", "#4488ee", "#cc1188", "#22ee00"],
  4228: ["#aaffcc", "#7711bb", "#443355", "#bb0099", "#00ffdd"],
  4229: ["#bb4422", "#883344", "#cc0088", "#00ee33", "#557711"],
  4230: ["#aa8822", "#8877cc", "#889922", "#660044", "#bb55cc"],
  4231: ["#ddccff", "#555533", "#ccaa55", "#eebb66", "#11ee11"],
  4232: ["#77dd77", "#aa9955", "#cc4477", "#11ff99", "#33aadd"],
  4233: ["#993333", "#dd7722", "#667777", "#66ffcc", "#eeee00"],
  4234: ["#887766", "#aa44ee", "#00bb11", "#aa1144", "#33aabb"],
  4235: ["#448844", "#ddeedd", "#ee6600", "#44ff77", "#eeee99"],
  4236: ["#22ccdd", "#22ccaa", "#111155", "#773311", "#991166"],
  4237: ["#bb7711", "#99aadd", "#bb99bb", "#009922", "#44ee66"],
  4238: ["#668844", "#44aa44", "#33ffcc", "#33aaff", "#ddaa00"],
  4239: ["#ff4433", "#aadd77", "#ff6644", "#6633bb", "#cc6666"],
  4240: ["#ff00aa", "#aa7722", "#cc6699", "#883300", "#ee1166"],
  4241: ["#ee77dd", "#77aa88", "#449944", "#eeeeff", "#7799bb"],
  4242: ["#ccee55", "#cc1144", "#444444", "#443388", "#66ffdd"],
  4243: ["#cc66ff", "#553355", "#9944dd", "#66ee77", "#8844dd"],
  4244: ["#442255", "#33bb11", "#ddbbdd", "#666677", "#007744"],
  4245: ["#aaee88", "#ccff77", "#cc5566", "#ee6666", "#aaff44"],
  4246: ["#3322bb", "#554444", "#aa2222", "#663311", "#bb99cc"],
  4247: ["#008811", "#aa2277", "#dd7722", "#dd9977", "#555566"],
  4248: ["#8833bb", "#ccddcc", "#ffee44", "#5566bb", "#88ee99"],
  4249: ["#eeff99", "#00ee66", "#449999", "#112211", "#dd8822"],
  4250: ["#007733", "#770000", "#668866", "#bb2222", "#bbaa55"],
  4251: ["#7722bb", "#dd44ff", "#cc4488", "#3311aa", "#998877"],
  4252: ["#4400bb", "#3377cc", "#aaff00", "#00cc99", "#aabbff"],
  4253: ["#668800", "#8877bb", "#dd0022", "#55aabb", "#ffee44"],
  4254: ["#115566", "#994400", "#eedd77", "#99bb33", "#007777"],
  4255: ["#aadd33", "#006633", "#44ffdd", "#66ff77", "#55ff99"],
  4256: ["#cc2288", "#bb0088", "#11aa00", "#00aaaa", "#4488bb"],
  4257: ["#bb7711", "#ddbbaa", "#66bbaa", "#8833cc", "#669977"],
  4258: ["#8822aa", "#77cc22", "#443366", "#ee33cc", "#774444"],
  4259: ["#eedd33", "#5544ee", "#bbdd77", "#44cc66", "#11ff44"],
  4260: ["#228899", "#336611", "#552244", "#66cc55", "#aa9955"],
  4261: ["#44ff00", "#dd1133", "#99dd44", "#33cc33", "#1166aa"],
  4262: ["#ff33bb", "#226600", "#229977", "#ccffcc", "#cc3311"],
  4263: ["#ddbb66", "#771133", "#bb3366", "#ffaa77", "#aa2277"],
  4264: ["#88ff77", "#aa6655", "#ff2233", "#eecc99", "#ffcc44"],
  4265: ["#663366", "#447700", "#00aa00", "#ff6688", "#cc1188"],
  4266: ["#1133ee", "#009944", "#ff7788", "#ee77ee", "#bb8877"],
  4267: ["#ccbb33", "#11bbee", "#ff8866", "#6622ff", "#ff4411"],
  4268: ["#006611", "#dddd99", "#887711", "#7700ee", "#3344aa"],
  4269: ["#553399", "#777788", "#11dd00", "#0000ff", "#77cc00"],
  4270: ["#66ee66", "#220033", "#ddbb99", "#336622", "#6666ff"],
  4271: ["#005588", "#77ff22", "#cc33bb", "#558855", "#eeeedd"],
  4272: ["#ff9966", "#22ff55", "#dd22bb", "#bb9922", "#884444"],
  4273: ["#dd9966", "#887777", "#554444", "#22bb66", "#661188"],
  4274: ["#ee3322", "#227700", "#77eeee", "#66aa77", "#777755"],
  4275: ["#22bbcc", "#221144", "#aa9999", "#777788", "#ffdddd"],
  4276: ["#88bbbb", "#665544", "#aaee11", "#ff6699", "#33cc44"],
  4277: ["#5555bb", "#00aa99", "#7733dd", "#44cc55", "#88eedd"],
  4278: ["#22aacc", "#aa22ff", "#7766ff", "#aa77dd", "#77cc55"],
  4279: ["#88bb33", "#77dd77", "#33ee77", "#22ccff", "#2244ee"],
  4280: ["#559900", "#665500", "#7777cc", "#dd11cc", "#aa88bb"],
  4281: ["#113366", "#ff22ee", "#772211", "#dd8844", "#883344"],
  4282: ["#55ff88", "#bb3355", "#3377ff", "#ff33aa", "#ee4433"],
  4283: ["#447777", "#dd11aa", "#550088", "#ee00bb", "#33cc88"],
  4284: ["#6677cc", "#772211", "#556677", "#aacc33", "#22cc55"],
  4285: ["#aa4422", "#55ccaa", "#7799cc", "#22bbee", "#99aacc"],
  4286: ["#cc11aa", "#00ee22", "#ddbb22", "#00ff00", "#ddee77"],
  4287: ["#00aaff", "#444499", "#66aabb", "#225577", "#886600"],
  4288: ["#eeffbb", "#88cc11", "#1122ff", "#aaee66", "#00cc99"],
  4289: ["#ffdd77", "#118866", "#ddbb66", "#883377", "#227722"],
  4290: ["#88cccc", "#001188", "#ff1144", "#00ccbb", "#999944"],
  4291: ["#3377aa", "#55ffdd", "#779977", "#aaeecc", "#2255dd"],
  4292: ["#bb0099", "#2200cc", "#ee6677", "#bb3311", "#66eeee"],
  4293: ["#22dd99", "#ff7799", "#1111ee", "#ccddcc", "#885555"],
  4294: ["#883377", "#0066aa", "#33ff00", "#ffbb33", "#ddbbaa"],
  4295: ["#00ee88", "#0088aa", "#881144", "#aa9977", "#aa7733"],
  4296: ["#ee9977", "#22bb88", "#ffbbbb", "#0022ee", "#99ff88"],
  4297: ["#662211", "#778833", "#884422", "#33ff55", "#aa22cc"],
  4298: ["#ddaadd", "#ff5555", "#445599", "#aa55ff", "#889966"],
  4299: ["#ee7733", "#773322", "#880077", "#33ccee", "#ddddee"],
  4300: ["#bbddff", "#ffdd44", "#889900", "#443333", "#559977"],
  4301: ["#3388ee", "#001188", "#8855bb", "#005588", "#66cc88"],
  4302: ["#dd7799", "#1111dd", "#776600", "#33bb44", "#ccff11"],
  4303: ["#cc5522", "#3311bb", "#00ccdd", "#334499", "#44ccbb"],
  4304: ["#771188", "#776644", "#770099", "#33cc33", "#992266"],
  4305: ["#ffee22", "#00ffcc", "#77cc66", "#448833", "#776611"],
  4306: ["#8811aa", "#334444", "#bbff99", "#aa6655", "#bbccee"],
  4307: ["#aabb00", "#550011", "#ff8866", "#116611", "#66eeff"],
  4308: ["#ee77aa", "#ee4488", "#ccffff", "#aa2200", "#ee7733"],
  4309: ["#224499", "#882200", "#cc8844", "#44aaaa", "#cc22ff"],
  4310: ["#44bb99", "#dd5522", "#ee88dd", "#229933", "#22aa00"],
  4311: ["#0066bb", "#ff00cc", "#99aaff", "#bb22bb", "#22cccc"],
  4312: ["#ddff33", "#ee7788", "#11ff00", "#111166", "#88eeee"],
  4313: ["#dd4477", "#77dd55", "#5566ff", "#cc2200", "#9966cc"],
  4314: ["#cc22ee", "#77ee00", "#aa66ff", "#ff4411", "#aa0055"],
  4315: ["#117711", "#226699", "#bbbb33", "#005522", "#002255"],
  4316: ["#776688", "#559944", "#112244", "#006622", "#dd1100"],
  4317: ["#338899", "#4499dd", "#88bbdd", "#55dd77", "#77bb99"],
  4318: ["#aacc00", "#772244", "#4466dd", "#4477ee", "#0088ee"],
  4319: ["#dd8811", "#663300", "#dd33dd", "#ccaa33", "#550033"],
  4320: ["#007722", "#223322", "#ff5566", "#5599aa", "#6655ff"],
  4321: ["#00ff22", "#ee1144", "#55cc11", "#005544", "#7733dd"],
  4322: ["#3355cc", "#33ff11", "#559999", "#884422", "#ee33aa"],
  4323: ["#bb44aa", "#88ccaa", "#ff6644", "#bb66ff", "#66dd33"],
  4324: ["#00dd11", "#665588", "#8855bb", "#8866ff", "#8888ff"],
  4325: ["#dd00ee", "#227722", "#ff8855", "#dd2277", "#111155"],
  4326: ["#cc11ee", "#7755aa", "#22dd44", "#ff22aa", "#cc9922"],
  4327: ["#dd66ff", "#66cc55", "#ccbbcc", "#55aa55", "#bbccaa"],
  4328: ["#551199", "#aa2222", "#ddddbb", "#55aa77", "#11ddaa"],
  4329: ["#ccddcc", "#4444ff", "#ee7788", "#220099", "#bb6655"],
  4330: ["#887711", "#777722", "#bb5522", "#22ee77", "#ccbbee"],
  4331: ["#22ff33", "#882233", "#222288", "#22ddbb", "#881144"],
  4332: ["#ff6644", "#cc5577", "#88ee88", "#66bb11", "#ddaa66"],
  4333: ["#00dd88", "#bbeebb", "#992200", "#1177ee", "#88cc77"],
  4334: ["#225533", "#cc7744", "#bb77bb", "#aaaa88", "#ff3333"],
  4335: ["#ee00ff", "#991155", "#223300", "#aa77dd", "#cc6677"],
  4336: ["#cc4400", "#ddddbb", "#ffeedd", "#dd8844", "#cc2200"],
  4337: ["#ee2255", "#77bbee", "#aa4433", "#44aaff", "#eecc11"],
  4338: ["#ddaaaa", "#dddddd", "#ee99ff", "#dd1188", "#004488"],
  4339: ["#ee22dd", "#cc2255", "#556655", "#cc99bb", "#ffeecc"],
  4340: ["#eedd55", "#221122", "#5533dd", "#4455dd", "#ee7711"],
  4341: ["#22ff66", "#ffbbdd", "#44ee66", "#8899dd", "#66ccbb"],
  4342: ["#ee6611", "#77aabb", "#ee6666", "#aa7744", "#aaee99"],
  4343: ["#33bbff", "#aacc88", "#aa7700", "#11ccff", "#5577ff"],
  4344: ["#ee3366", "#aa9988", "#bb6677", "#6611ff", "#bb2288"],
  4345: ["#22dddd", "#55ccaa", "#eeaacc", "#33bb33", "#11bb77"],
  4346: ["#cc6677", "#dd77ff", "#4400bb", "#55bb44", "#eebbee"],
  4347: ["#11cc33", "#ddbbee", "#11bb99", "#bb6666", "#774488"],
  4348: ["#11cc88", "#aabb66", "#22dd00", "#88dd88", "#aadd55"],
  4349: ["#66ee44", "#dd11dd", "#664455", "#ee1166", "#cc2233"],
  4350: ["#8822aa", "#5533ee", "#ff5500", "#bb5555", "#cccc77"],
  4351: ["#551133", "#bbff88", "#4444ff", "#dd33ee", "#8855aa"],
  4352: ["#55aaee", "#665500", "#22bb00", "#6699dd", "#99dd44"],
  4353: ["#77ff77", "#226633", "#997755", "#99aa77", "#bbaa22"],
  4354: ["#335555", "#11ff99", "#aa88ee", "#11aa66", "#11bbcc"],
  4355: ["#222299", "#771133", "#33ee55", "#aa2244", "#bb2200"],
  4356: ["#110077", "#ee44ff", "#bbee55", "#eeffcc", "#550055"],
  4357: ["#aaaa44", "#999999", "#33cc55", "#55ff11", "#66ff22"],
  4358: ["#dd00aa", "#ccff66", "#777722", "#bb4422", "#226633"],
  4359: ["#225577", "#6644cc", "#dd44ee", "#1111ee", "#cc11dd"],
  4360: ["#ff4400", "#66dd33", "#55aa88", "#884477", "#3311dd"],
  4361: ["#22bb11", "#eeccaa", "#229966", "#552277", "#dd88bb"],
  4362: ["#880055", "#aa88dd", "#444433", "#887799", "#446688"],
  4363: ["#3300ee", "#ee6666", "#bbcc00", "#33cc66", "#7755ff"],
  4364: ["#eedd22", "#88ee66", "#2255aa", "#2277dd", "#cc2233"],
  4365: ["#55ee88", "#445566", "#88ff99", "#774466", "#770088"],
  4366: ["#88ff88", "#33dd11", "#00aaaa", "#55ee55", "#aa8822"],
  4367: ["#cc8888", "#2211aa", "#ffeecc", "#229999", "#dd55cc"],
  4368: ["#6655cc", "#ee44dd", "#bb66ff", "#227788", "#ddaa66"],
  4369: ["#bb33cc", "#ff6699", "#ee4466", "#22ddcc", "#99ffbb"],
  4370: ["#114433", "#bb3377", "#666677", "#aaeeff", "#338822"],
  4371: ["#559911", "#00ddaa", "#55bb55", "#eeaa66", "#667733"],
  4372: ["#ff55aa", "#ee4488", "#ff7733", "#008811", "#336655"],
  4373: ["#bb5533", "#ff3388", "#55cccc", "#554466", "#ffdd99"],
  4374: ["#aaffee", "#008822", "#112200", "#cc5544", "#779999"],
  4375: ["#990011", "#bb1188", "#99cc99", "#779900", "#aaaa22"],
  4376: ["#668855", "#ee11bb", "#55cc77", "#ff99cc", "#222244"],
  4377: ["#cccc55", "#aa4411", "#22ffff", "#ffdd77", "#4466ff"],
  4378: ["#996666", "#55aa66", "#88bb11", "#447777", "#66ff11"],
  4379: ["#448844", "#ddcc88", "#ff7744", "#9977ee", "#008866"],
  4380: ["#11ee11", "#ff4411", "#887733", "#bb5555", "#77ccee"],
  4381: ["#ddeedd", "#7744bb", "#3388ff", "#aa0044", "#2233cc"],
  4382: ["#22dd66", "#1199ee", "#2299bb", "#5599cc", "#997722"],
  4383: ["#559922", "#11aa00", "#9999cc", "#bbff66", "#cc9922"],
  4384: ["#55ffff", "#aa5544", "#cc9911", "#3366aa", "#44aaff"],
  4385: ["#3355cc", "#442222", "#77aabb", "#88bb66", "#778833"],
  4386: ["#334455", "#bb3344", "#bb1133", "#ddaa66", "#ee0000"],
  4387: ["#4499cc", "#aa5533", "#22dd11", "#996699", "#ccff22"],
  4388: ["#bbff77", "#882233", "#556677", "#ffdd99", "#ccffcc"],
  4389: ["#eeddaa", "#ff3377", "#bb88ee", "#889988", "#1122ee"],
  4390: ["#dd5588", "#ffdd88", "#bb1111", "#ffaa22", "#33eeff"],
  4391: ["#ff4444", "#44ffee", "#dd22ff", "#eedddd", "#ee8833"],
  4392: ["#993344", "#55ff99", "#cc9933", "#ff99aa", "#3366cc"],
  4393: ["#ff1122", "#662200", "#889999", "#bb44cc", "#559988"],
  4394: ["#ee5500", "#ee88ff", "#002299", "#999933", "#661122"],
  4395: ["#00dd00", "#bb8899", "#2211cc", "#bb1177", "#99ff55"],
  4396: ["#00dd55", "#6699bb", "#99ff55", "#99ccee", "#778888"],
  4397: ["#330055", "#44ff66", "#880066", "#889933", "#336600"],
  4398: ["#774466", "#229900", "#77cc44", "#77cc11", "#dd66ff"],
  4399: ["#ff33aa", "#00ee88", "#226611", "#77dd99", "#7755cc"],
  4400: ["#00aa11", "#ccbbff", "#228844", "#bb8833", "#009933"],
  4401: ["#bbdd33", "#88aa11", "#cc8877", "#772266", "#eecc88"],
  4402: ["#663355", "#55aa77", "#00ff66", "#550000", "#11bbdd"],
  4403: ["#cc77ff", "#55ffcc", "#aa00ff", "#11bb22", "#ff7788"],
  4404: ["#ff3388", "#bb1188", "#776611", "#dd7777", "#335500"],
  4405: ["#887777", "#aaaaaa", "#334411", "#4433ff", "#7799dd"],
  4406: ["#448855", "#aa7755", "#551122", "#eebb11", "#66dd66"],
  4407: ["#ff7799", "#bb3377", "#aa8877", "#ddeeee", "#dd33dd"],
  4408: ["#11eecc", "#bb2299", "#778866", "#ccff55", "#22bb99"],
  4409: ["#ff6655", "#4488aa", "#8811cc", "#00dd99", "#aaaa55"],
  4410: ["#ffffff", "#0077ee", "#556644", "#55aa99", "#cc11ee"],
  4411: ["#880077", "#eeff88", "#9977ff", "#33bbff", "#993388"],
  4412: ["#bb2277", "#222277", "#bb0033", "#446688", "#99aa33"],
  4413: ["#ccaaff", "#aa1111", "#773377", "#88ddaa", "#6655bb"],
  4414: ["#5544dd", "#ff2233", "#446611", "#3388ff", "#77aa11"],
  4415: ["#aa7755", "#22bbbb", "#1122cc", "#118899", "#9944aa"],
  4416: ["#bbcc44", "#bbffee", "#ffcc22", "#991144", "#0000ff"],
  4417: ["#886699", "#337700", "#44eeaa", "#3366ff", "#8811ff"],
  4418: ["#00dd22", "#33aaaa", "#cc7777", "#77aa55", "#dd55cc"],
  4419: ["#009900", "#bb44ff", "#aa1100", "#332266", "#663377"],
  4420: ["#1144dd", "#cc55cc", "#cc44bb", "#aa99ee", "#4422aa"],
  4421: ["#8800aa", "#ee2299", "#5566bb", "#dd88dd", "#5588ee"],
  4422: ["#663333", "#55dd99", "#bb8833", "#22dd44", "#ee1155"],
  4423: ["#ffbb22", "#886633", "#cc33dd", "#dd1122", "#ee3333"],
  4424: ["#ee3388", "#11ff33", "#7744cc", "#330055", "#00dd77"],
  4425: ["#bbcc44", "#338844", "#22aaee", "#66eeff", "#1122cc"],
  4426: ["#aaaa55", "#55dddd", "#339977", "#aacccc", "#5566cc"],
  4427: ["#5588aa", "#ffeeee", "#ddaa88", "#1144ee", "#448866"],
  4428: ["#553333", "#2255cc", "#779944", "#5555ff", "#ffccbb"],
  4429: ["#11dd11", "#bb33cc", "#ddbb77", "#cc00ee", "#88ee88"],
  4430: ["#558877", "#11ee99", "#3344ff", "#8844bb", "#bb22ee"],
  4431: ["#119922", "#ddaa55", "#7722aa", "#55ff66", "#ff55ee"],
  4432: ["#113300", "#66cc22", "#0033cc", "#66cc88", "#aaeebb"],
  4433: ["#552222", "#99eebb", "#dddd44", "#22dd88", "#ee2222"],
  4434: ["#66eecc", "#ee1188", "#8833ff", "#00ff22", "#3322cc"],
  4435: ["#004455", "#bb44aa", "#ddaa22", "#bbbb99", "#7755bb"],
  4436: ["#0011ee", "#aaccdd", "#22ee99", "#33eeee", "#5588bb"],
  4437: ["#ddaa55", "#ff5500", "#dd1199", "#4466aa", "#aa0022"],
  4438: ["#ddcc77", "#2211bb", "#ff8844", "#552211", "#223355"],
  4439: ["#ccaa00", "#1144bb", "#dd99dd", "#aa44cc", "#0088ee"],
  4440: ["#5599ff", "#ee5533", "#ffaa66", "#446622", "#55cc11"],
  4441: ["#cceebb", "#663311", "#66aa99", "#ee3344", "#55cc88"],
  4442: ["#00dd44", "#bb9944", "#ffdd44", "#dd99bb", "#88dd66"],
  4443: ["#dddd66", "#660077", "#aa33ff", "#2222aa", "#bb4444"],
  4444: ["#332266", "#ddff33", "#44ee22", "#aa33bb", "#bb3377"],
  4445: ["#77ee99", "#99cc00", "#bb6611", "#112211", "#33ffaa"],
  4446: ["#bbbbbb", "#222200", "#33ff55", "#ff6611", "#ddbb33"],
  4447: ["#aa9911", "#ff5522", "#88ee33", "#aaff00", "#77ff66"],
  4448: ["#11bb00", "#7722cc", "#ff4433", "#bbeeee", "#ee9933"],
  4449: ["#55ff11", "#2200cc", "#11ffbb", "#0033bb", "#00aa99"],
  4450: ["#ff0066", "#112266", "#998833", "#7711ff", "#4466ee"],
  4451: ["#33dd55", "#6699dd", "#4422ee", "#554466", "#55bb22"],
  4452: ["#552299", "#ddee44", "#ddff11", "#cc8855", "#dd5511"],
  4453: ["#aaff77", "#ffee44", "#cccccc", "#ee6622", "#335544"],
  4454: ["#559977", "#442266", "#ddcc88", "#bb66bb", "#ff11ee"],
  4455: ["#33aa44", "#006655", "#88eeff", "#99ccee", "#ff9966"],
  4456: ["#335533", "#eebbee", "#1144cc", "#dd4499", "#992211"],
  4457: ["#aa6688", "#eeaa00", "#5533dd", "#7722ff", "#001144"],
  4458: ["#7700ee", "#00eeee", "#66ee11", "#44cc33", "#aa2222"],
  4459: ["#118899", "#997744", "#ccff33", "#cc7766", "#ddeeee"],
  4460: ["#33ddbb", "#0055bb", "#11ddaa", "#337755", "#eebbbb"],
  4461: ["#996699", "#eeaacc", "#ccff66", "#ee1144", "#77aa88"],
  4462: ["#55cc88", "#446611", "#0088aa", "#00dd33", "#33ccff"],
  4463: ["#ffffcc", "#0011cc", "#11eeee", "#dd22cc", "#11ddff"],
  4464: ["#ddaabb", "#3333ee", "#bb5500", "#dd4444", "#11aabb"],
  4465: ["#004477", "#9900dd", "#77eebb", "#886633", "#44ffbb"],
  4466: ["#eeddbb", "#66bb22", "#9922bb", "#665566", "#aa5500"],
  4467: ["#4466cc", "#22ccff", "#77bb55", "#55bb00", "#66cc44"],
  4468: ["#bb11ff", "#99ee44", "#99aa66", "#ee44bb", "#5500cc"],
  4469: ["#bb66bb", "#222211", "#9977bb", "#770088", "#2277bb"],
  4470: ["#ee9922", "#882200", "#889988", "#554444", "#cc99aa"],
  4471: ["#4433aa", "#99bb66", "#ee7766", "#11eebb", "#ee6600"],
  4472: ["#ff77ff", "#7744ff", "#66cccc", "#9966dd", "#11ee44"],
  4473: ["#ee0099", "#77ffdd", "#55cc00", "#995588", "#ffcccc"],
  4474: ["#ff44dd", "#770066", "#ee5566", "#eebb33", "#dd1166"],
  4475: ["#5599dd", "#000011", "#eeccaa", "#99ff66", "#cc0000"],
  4476: ["#992255", "#ccdddd", "#6600ff", "#ffffbb", "#66dd11"],
  4477: ["#336677", "#ff7755", "#77bb77", "#aadddd", "#994488"],
  4478: ["#ee22ff", "#88eeee", "#aa55cc", "#aa00aa", "#117799"],
  4479: ["#aabb22", "#005544", "#bb7744", "#33eeff", "#eeaa33"],
  4480: ["#0066cc", "#99bbcc", "#bb8855", "#33ccbb", "#ddaaaa"],
  4481: ["#887799", "#11dd44", "#447744", "#bb55cc", "#111111"],
  4482: ["#ff8833", "#1144bb", "#dd4488", "#997755", "#dd00cc"],
  4483: ["#8899cc", "#55bb77", "#66aa66", "#44ccff", "#ee9900"],
  4484: ["#bbffbb", "#2233dd", "#223311", "#99ddcc", "#ddbbcc"],
  4485: ["#dd1166", "#dd5522", "#ccffdd", "#008844", "#881199"],
  4486: ["#552200", "#2299dd", "#00bbcc", "#999966", "#cc1144"],
  4487: ["#550055", "#ff6633", "#5599ee", "#99bb33", "#cc2233"],
  4488: ["#dddd11", "#8811cc", "#443355", "#44ff99", "#ee1111"],
  4489: ["#ee9988", "#bb99cc", "#22cc11", "#5566bb", "#55ee11"],
  4490: ["#ff66cc", "#55ccff", "#dd6622", "#99ee66", "#ccaa33"],
  4491: ["#77bbcc", "#ff88cc", "#444433", "#00ffbb", "#aa1166"],
  4492: ["#ee0055", "#4411ee", "#bbbb55", "#7777aa", "#44bbcc"],
  4493: ["#55ff11", "#2211bb", "#7755aa", "#88bbdd", "#882266"],
  4494: ["#aacc22", "#dd77ee", "#77dd33", "#2211aa", "#ee55cc"],
  4495: ["#330044", "#dd7722", "#225500", "#9977bb", "#669966"],
  4496: ["#aadd22", "#994422", "#eebbcc", "#99ddee", "#11dd00"],
  4497: ["#bb6688", "#449999", "#111100", "#770011", "#55cc55"],
  4498: ["#446688", "#22ccdd", "#cccc99", "#ffbb33", "#aa33ff"],
  4499: ["#aa7788", "#2233ee", "#2200dd", "#ffffcc", "#99bbdd"],
  4500: ["#555511", "#66dd11", "#77aa33", "#334455", "#116600"],
  4501: ["#aa9944", "#aa99ff", "#776655", "#44ff11", "#6644cc"],
  4502: ["#333300", "#00dd44", "#aa9944", "#77ff55", "#008822"],
  4503: ["#cc1144", "#66ff00", "#66cccc", "#002244", "#557733"],
  4504: ["#11ee66", "#ff7744", "#1122ee", "#227777", "#dd6644"],
  4505: ["#882233", "#cc0022", "#1111aa", "#33ddee", "#776688"],
  4506: ["#994499", "#225533", "#aa5522", "#aa2299", "#eeaa77"],
  4507: ["#ee55ee", "#eedd00", "#110088", "#4400ff", "#66cc99"],
  4508: ["#77ee22", "#44dd22", "#aaccee", "#888822", "#8866dd"],
  4509: ["#1133ee", "#000055", "#119955", "#22cc44", "#44ff44"],
  4510: ["#88ff88", "#eeee77", "#664477", "#440055", "#dd22cc"],
  4511: ["#665533", "#ff2244", "#443366", "#663322", "#dd2233"],
  4512: ["#334422", "#aa2288", "#002288", "#bb22ff", "#0044cc"],
  4513: ["#aaff22", "#660055", "#774455", "#114455", "#cc7744"],
  4514: ["#dd00cc", "#aa2277", "#eedd99", "#dd0011", "#883333"],
  4515: ["#4400aa", "#cc9911", "#444488", "#113311", "#117711"],
  4516: ["#aa66cc", "#7733cc", "#334488", "#dd5522", "#cc33aa"],
  4517: ["#dd4422", "#4455aa", "#ee3311", "#4400bb", "#33bb88"],
  4518: ["#2266cc", "#772233", "#11cc88", "#ee1166", "#33aa77"],
  4519: ["#114455", "#99bb55", "#77dd33", "#669900", "#88bb66"],
  4520: ["#cc66cc", "#4422cc", "#3333dd", "#448855", "#bb3344"],
  4521: ["#6688dd", "#225599", "#77cc44", "#227799", "#225500"],
  4522: ["#ff6666", "#884422", "#2200ee", "#7744bb", "#55bb22"],
  4523: ["#9911aa", "#33cc11", "#775599", "#66aaaa", "#774422"],
  4524: ["#1155ff", "#aabb77", "#bbee77", "#229999", "#881199"],
  4525: ["#99dddd", "#773366", "#8844dd", "#44dd00", "#11dd11"],
  4526: ["#3322aa", "#eeccff", "#55ee22", "#bb99aa", "#773322"],
  4527: ["#2277bb", "#996699", "#ff44bb", "#dd4488", "#2266ee"],
  4528: ["#223366", "#660022", "#558833", "#aa0077", "#ee8844"],
  4529: ["#22ddaa", "#8811aa", "#22ff33", "#eebb00", "#ee6600"],
  4530: ["#ff55ff", "#554433", "#00ee22", "#665500", "#9955aa"],
  4531: ["#55aabb", "#bbaa33", "#aa9999", "#116699", "#669977"],
  4532: ["#eecc55", "#aa0022", "#999966", "#ee1111", "#66ccbb"],
  4533: ["#ffffee", "#114433", "#ff88ee", "#669933", "#8888aa"],
  4534: ["#3388dd", "#117799", "#dd44bb", "#8833cc", "#772222"],
  4535: ["#664411", "#33bbbb", "#440088", "#aa6611", "#4400ee"],
  4536: ["#115599", "#9911aa", "#6688bb", "#55bbdd", "#dd0099"],
  4537: ["#eeee22", "#440033", "#aa00ee", "#eeaa00", "#dd8888"],
  4538: ["#7722dd", "#bb44cc", "#002299", "#99dd33", "#2211cc"],
  4539: ["#bb55aa", "#0055dd", "#ccaa88", "#223377", "#ee4400"],
  4540: ["#aabb99", "#33ccee", "#ccee22", "#dd5588", "#1122cc"],
  4541: ["#004477", "#cc6677", "#992277", "#77bbee", "#3333dd"],
  4542: ["#88ccee", "#bb9977", "#77ccff", "#4400dd", "#33aa11"],
  4543: ["#44aaaa", "#667733", "#003355", "#111177", "#774455"],
  4544: ["#000044", "#001144", "#99ccaa", "#ffcc22", "#22eeaa"],
  4545: ["#cc22dd", "#2200dd", "#662222", "#eeee77", "#dd9977"],
  4546: ["#dd8822", "#66ee44", "#ff6611", "#5511ee", "#cc99aa"],
  4547: ["#cc7722", "#bb99dd", "#ffbb55", "#77dd44", "#aa33cc"],
  4548: ["#8844dd", "#ff77bb", "#5588cc", "#88ee99", "#bb0000"],
  4549: ["#779900", "#77eebb", "#226666", "#ff00dd", "#6633ff"],
  4550: ["#bb77aa", "#cc22aa", "#220077", "#ee11cc", "#00bb11"],
  4551: ["#66eeee", "#66bb77", "#bbeeee", "#7722ee", "#88dddd"],
  4552: ["#777733", "#229933", "#772244", "#555522", "#eeddee"],
  4553: ["#002222", "#00ff99", "#995500", "#33eeaa", "#4499bb"],
  4554: ["#ddddbb", "#9955ff", "#00dd66", "#110099", "#88cc77"],
  4555: ["#99cc77", "#eedd55", "#55dd11", "#112244", "#ee0022"],
  4556: ["#5522ff", "#5500cc", "#3300ff", "#bb5566", "#dd8833"],
  4557: ["#116622", "#55ee44", "#110066", "#884499", "#dd9999"],
  4558: ["#dd8899", "#dd7744", "#dd0066", "#55ddcc", "#eeff00"],
  4559: ["#77ee11", "#33bb55", "#ff7711", "#1188bb", "#dd1133"],
  4560: ["#dd33ee", "#0077dd", "#0088aa", "#ee88ff", "#aa11ee"],
  4561: ["#7733aa", "#004433", "#ccdd88", "#aa6666", "#77ddaa"],
  4562: ["#ccddaa", "#aaddcc", "#1199dd", "#33eeaa", "#ffcccc"],
  4563: ["#2288ee", "#5522aa", "#dd55dd", "#bbddaa", "#559966"],
  4564: ["#bb8833", "#dd77ff", "#335566", "#bbffee", "#5500cc"],
  4565: ["#dd7722", "#77aa11", "#bb9933", "#4400cc", "#ff9966"],
  4566: ["#551155", "#ffffbb", "#eeddbb", "#66bbcc", "#ffff99"],
  4567: ["#995511", "#33ccdd", "#2255dd", "#1100ee", "#cc5566"],
  4568: ["#998899", "#1133bb", "#bbddee", "#aa66bb", "#ffaacc"],
  4569: ["#774477", "#001177", "#11ff22", "#66bbaa", "#2222aa"],
  4570: ["#aa55ff", "#335544", "#ff00cc", "#8877aa", "#cc11bb"],
  4571: ["#99bb22", "#1100cc", "#aa9900", "#8833cc", "#6600bb"],
  4572: ["#aa00ff", "#22eebb", "#dd2233", "#88bbaa", "#2233bb"],
  4573: ["#bb11ff", "#226655", "#0088aa", "#ff5544", "#009977"],
  4574: ["#665500", "#441166", "#88ee99", "#1155cc", "#bbddaa"],
  4575: ["#994400", "#dd99aa", "#aadd99", "#ccaaee", "#aa88bb"],
  4576: ["#995599", "#229988", "#0000ff", "#552288", "#aa8833"],
  4577: ["#aa1177", "#dd4433", "#33aa55", "#ccbb00", "#00ddbb"],
  4578: ["#aaee99", "#4488ff", "#99ff99", "#bbcc22", "#ccdd44"],
  4579: ["#cc7744", "#445544", "#00bb55", "#223333", "#ccaa77"],
  4580: ["#bb2200", "#114488", "#00dd33", "#ccffff", "#aa99ff"],
  4581: ["#668877", "#dd3388", "#aabbdd", "#ff33bb", "#99ee33"],
  4582: ["#4499ee", "#ff6699", "#220077", "#eeff55", "#00ddee"],
  4583: ["#ffeedd", "#ffffcc", "#2222aa", "#994433", "#449933"],
  4584: ["#bbff33", "#77ff77", "#bbbbaa", "#554499", "#774499"],
  4585: ["#3355ff", "#cc99ff", "#668888", "#aaeecc", "#669933"],
  4586: ["#cc99dd", "#ddcc33", "#ff6644", "#dd6699", "#1122bb"],
  4587: ["#555511", "#99ddcc", "#115500", "#4477aa", "#446699"],
  4588: ["#222222", "#0088ff", "#cc0066", "#1133aa", "#5566dd"],
  4589: ["#0033dd", "#884444", "#008899", "#bb11bb", "#9988bb"],
  4590: ["#1144aa", "#116699", "#66ff00", "#221177", "#332299"],
  4591: ["#667777", "#22bb22", "#226666", "#3355cc", "#aa5588"],
  4592: ["#2266aa", "#77ddcc", "#bb3311", "#aa3344", "#eecc88"],
  4593: ["#aa88dd", "#3399aa", "#5588cc", "#aa1155", "#aa9955"],
  4594: ["#bbee11", "#119922", "#445555", "#4499ff", "#333355"],
  4595: ["#669966", "#cc88ee", "#7766ee", "#99cc22", "#887799"],
  4596: ["#eedd99", "#00cc77", "#ff0066", "#ffeedd", "#dd1188"],
  4597: ["#ddffdd", "#cc0077", "#ddaa88", "#009999", "#55dd33"],
  4598: ["#bb7788", "#dd9977", "#99aa00", "#3300ff", "#9944dd"],
  4599: ["#994400", "#ee88aa", "#bbbbaa", "#aaee77", "#bb77bb"],
  4600: ["#bbdd22", "#998811", "#997755", "#551199", "#bb6622"],
  4601: ["#336666", "#7755bb", "#00ff00", "#7700ff", "#ddee33"],
  4602: ["#99eebb", "#ff8800", "#3366ee", "#eeeebb", "#bbcccc"],
  4603: ["#aaee77", "#112233", "#ee66aa", "#44ddcc", "#110088"],
  4604: ["#224411", "#0055aa", "#cc6622", "#eedd44", "#55eebb"],
  4605: ["#66bb22", "#7722ff", "#66dddd", "#ff7788", "#ff66bb"],
  4606: ["#bbbbff", "#bb5522", "#33ddaa", "#9933aa", "#2266ff"],
  4607: ["#ff55bb", "#335599", "#118833", "#dd8866", "#556688"],
  4608: ["#cc9988", "#222244", "#dd33bb", "#4422ee", "#2288ee"],
  4609: ["#ff8899", "#ff1177", "#4433cc", "#449933", "#668899"],
  4610: ["#3366aa", "#ddaaee", "#112211", "#556688", "#777799"],
  4611: ["#55eedd", "#774477", "#551188", "#cc0066", "#552233"],
  4612: ["#ff33ff", "#aa33bb", "#eecc66", "#4477ee", "#662233"],
  4613: ["#ddffbb", "#993322", "#11eecc", "#ee7722", "#dd8877"],
  4614: ["#ff7711", "#334455", "#00dd55", "#883366", "#998866"],
  4615: ["#00eeff", "#55bbdd", "#11ddbb", "#33ffcc", "#6622cc"],
  4616: ["#557700", "#77aabb", "#003388", "#bb99cc", "#33dd66"],
  4617: ["#1100ff", "#eeccff", "#aaffdd", "#aabb66", "#2222bb"],
  4618: ["#ffaa66", "#331155", "#996622", "#33ccff", "#8866ff"],
  4619: ["#ddcc99", "#119977", "#663300", "#bb55aa", "#cc0011"],
  4620: ["#7777cc", "#882244", "#66dd22", "#44ff11", "#eebb00"],
  4621: ["#116644", "#dd2244", "#dd00bb", "#440055", "#bb9900"],
  4622: ["#443399", "#88ff77", "#446688", "#bb88aa", "#ee2211"],
  4623: ["#550099", "#bb4433", "#99bb77", "#116655", "#77ddff"],
  4624: ["#99ee44", "#dddddd", "#002288", "#ddaadd", "#8800ee"],
  4625: ["#0044cc", "#33ff77", "#aaffaa", "#88dd99", "#882200"],
  4626: ["#6633cc", "#557722", "#aa33ff", "#662299", "#9933bb"],
  4627: ["#cc8811", "#ee9955", "#ddbb88", "#44bbcc", "#882288"],
  4628: ["#1100bb", "#66ff11", "#aa1133", "#ff7777", "#22aa99"],
  4629: ["#5544bb", "#880033", "#555500", "#11aaff", "#66dd88"],
  4630: ["#aa6655", "#cc77aa", "#224455", "#664477", "#223333"],
  4631: ["#33ff22", "#bbeecc", "#886666", "#66ffff", "#99dd88"],
  4632: ["#116655", "#995577", "#aa7700", "#eebbdd", "#889999"],
  4633: ["#99ffaa", "#bbcc88", "#559999", "#dd88ee", "#550099"],
  4634: ["#aa0033", "#66ffbb", "#884444", "#cc1111", "#ccff33"],
  4635: ["#33dddd", "#223300", "#6622aa", "#779911", "#88ffee"],
  4636: ["#88ccff", "#ee4400", "#660066", "#99bbaa", "#dd9944"],
  4637: ["#22ff88", "#aa1133", "#99cc00", "#cc66cc", "#99aa66"],
  4638: ["#bbcc88", "#335577", "#ddbb66", "#1155cc", "#8899dd"],
  4639: ["#007744", "#66ccaa", "#3399dd", "#bbddbb", "#4411bb"],
  4640: ["#88ee77", "#3388cc", "#dd77dd", "#993355", "#9911ee"],
  4641: ["#ff1177", "#ff00ff", "#7755bb", "#6644aa", "#00bb44"],
  4642: ["#112288", "#88dd77", "#ccbb11", "#ff8811", "#bb2222"],
  4643: ["#ee4444", "#11ddaa", "#887722", "#44ddee", "#226666"],
  4644: ["#aa44dd", "#66ffaa", "#225511", "#8866cc", "#6611cc"],
  4645: ["#2200bb", "#0088ee", "#77dd99", "#994477", "#55aa66"],
  4646: ["#447700", "#55aaff", "#337777", "#ccdd55", "#9977aa"],
  4647: ["#aa44bb", "#99cc44", "#aacc00", "#7722ee", "#330077"],
  4648: ["#0099bb", "#116600", "#003300", "#7722dd", "#441144"],
  4649: ["#ccff99", "#eeaa99", "#77ff00", "#ff66bb", "#44ffcc"],
  4650: ["#ff6655", "#88eebb", "#887799", "#66ee77", "#55bb33"],
  4651: ["#cccc66", "#99ff55", "#aa00bb", "#0099ff", "#ddbbdd"],
  4652: ["#996622", "#aaff00", "#00aaaa", "#889911", "#3322bb"],
  4653: ["#55ffbb", "#8833ff", "#226611", "#6666ff", "#ff44aa"],
  4654: ["#eeffee", "#22eebb", "#ffbbff", "#001100", "#bbff44"],
  4655: ["#337766", "#55ee66", "#778899", "#8800ee", "#33bb00"],
  4656: ["#88cc55", "#22cc44", "#aa2200", "#bbdd44", "#ff6677"],
  4657: ["#bbdd44", "#9977ff", "#bbaa44", "#664466", "#cc00ee"],
  4658: ["#bb5500", "#eeaadd", "#ccdd66", "#bb4400", "#119911"],
  4659: ["#dd4499", "#ff1122", "#ccff55", "#00dd55", "#ee00cc"],
  4660: ["#338866", "#55ff44", "#553333", "#00ddcc", "#88cc33"],
  4661: ["#dd00dd", "#bbbbbb", "#aaee66", "#1188ee", "#2222bb"],
  4662: ["#cc6688", "#338855", "#dd9900", "#441133", "#88eeee"],
  4663: ["#55ee77", "#bb0022", "#4488dd", "#11dd99", "#dd8800"],
  4664: ["#ee33ff", "#227700", "#ff9911", "#1122aa", "#55eebb"],
  4665: ["#884488", "#ee3399", "#ddcc66", "#8888dd", "#448833"],
  4666: ["#ddcc11", "#5533bb", "#cceecc", "#ff7733", "#0000bb"],
  4667: ["#bb2277", "#dd00ff", "#445588", "#664422", "#449911"],
  4668: ["#ffeeaa", "#22dd44", "#ee00aa", "#ee33cc", "#88bbdd"],
  4669: ["#dd66aa", "#bbff66", "#cc1177", "#ddff44", "#44ddee"],
  4670: ["#bbee88", "#33dd88", "#99bb22", "#cc3377", "#ccee88"],
  4671: ["#ffff22", "#6666cc", "#77ddff", "#ff55aa", "#aa9900"],
  4672: ["#aa8833", "#223344", "#447799", "#88ff44", "#338844"],
  4673: ["#aa4433", "#aaee44", "#222266", "#44ccdd", "#aa44aa"],
  4674: ["#dd44cc", "#dd11bb", "#aabbee", "#dd11ee", "#559966"],
  4675: ["#bb0088", "#dd3300", "#441188", "#11bbbb", "#33dd99"],
  4676: ["#007711", "#99eebb", "#888899", "#8877aa", "#221144"],
  4677: ["#330000", "#4444cc", "#998888", "#4400dd", "#00cc99"],
  4678: ["#2299ff", "#ccbb88", "#55aaff", "#4422ee", "#111199"],
  4679: ["#bbeeff", "#ff9988", "#557733", "#aaeecc", "#7744bb"],
  4680: ["#bb6611", "#44ff77", "#ee6655", "#33dd55", "#ffddff"],
  4681: ["#ff6622", "#44cc55", "#dddd11", "#aa22aa", "#337700"],
  4682: ["#dd2277", "#bb9988", "#bbcc00", "#bbbb99", "#ffff00"],
  4683: ["#bb8899", "#6600bb", "#00dd11", "#eeee00", "#559966"],
  4684: ["#bb5577", "#5555ff", "#1155bb", "#116677", "#77aa11"],
  4685: ["#44dd44", "#aaaadd", "#ee8822", "#cc4488", "#66ffcc"],
  4686: ["#003366", "#ee0022", "#bb3377", "#99ff99", "#118844"],
  4687: ["#aabbcc", "#eeaa00", "#cc3322", "#559933", "#009922"],
  4688: ["#55dd22", "#aaaa77", "#ee4477", "#11aaaa", "#551155"],
  4689: ["#55aa33", "#77dd55", "#cc4411", "#44aa77", "#11ddff"],
  4690: ["#224499", "#ccdd99", "#77bb88", "#5544bb", "#ff8833"],
  4691: ["#aadd44", "#77ff22", "#55eecc", "#ee9966", "#11ccbb"],
  4692: ["#bb4466", "#8877aa", "#112211", "#44ddaa", "#bb3377"],
  4693: ["#cc7766", "#44bb99", "#11bb77", "#11cc00", "#bb5544"],
  4694: ["#880088", "#882200", "#2233cc", "#3366dd", "#881144"],
  4695: ["#668800", "#8855dd", "#11ccdd", "#ee11aa", "#110044"],
  4696: ["#ff99ff", "#558877", "#33bb55", "#338877", "#009933"],
  4697: ["#55bb33", "#dd5555", "#55cc99", "#ccaaaa", "#99ff11"],
  4698: ["#77bbaa", "#7722aa", "#ccbb33", "#441100", "#dd66ee"],
  4699: ["#227777", "#44ccff", "#6622dd", "#ccaaaa", "#88ddbb"],
  4700: ["#887755", "#bb6666", "#6699cc", "#44ddff", "#dd55bb"],
  4701: ["#554455", "#bb6688", "#118844", "#ddaaaa", "#888899"],
  4702: ["#440022", "#dd2211", "#bb9911", "#332299", "#006699"],
  4703: ["#99ffdd", "#119999", "#22ff55", "#113377", "#aa4499"],
  4704: ["#00bbdd", "#116677", "#bbcc44", "#66ee99", "#11eebb"],
  4705: ["#449966", "#0055ff", "#445544", "#770011", "#223366"],
  4706: ["#1199ee", "#11bb66", "#00eeaa", "#666666", "#11ddbb"],
  4707: ["#eeccaa", "#ff00bb", "#9922cc", "#117722", "#111100"],
  4708: ["#11eebb", "#559933", "#779900", "#11aa11", "#000099"],
  4709: ["#ee4499", "#ee5544", "#00dd33", "#33bbee", "#eeddbb"],
  4710: ["#ddee55", "#22bb99", "#115511", "#77dd55", "#227711"],
  4711: ["#110033", "#6666ee", "#442288", "#2299dd", "#993300"],
  4712: ["#cc1122", "#00ffff", "#77ddff", "#bbdd77", "#6644ff"],
  4713: ["#5522aa", "#1122ee", "#ddccee", "#557722", "#66ee88"],
  4714: ["#33ccbb", "#112244", "#eeffee", "#33dd88", "#66aaaa"],
  4715: ["#ddbb44", "#775555", "#dd4444", "#7744ff", "#ccff66"],
  4716: ["#bbdd66", "#0044bb", "#ff0011", "#331177", "#ddbbaa"],
  4717: ["#334400", "#ccff88", "#00aaff", "#55ffff", "#ff7755"],
  4718: ["#00bb11", "#ccffdd", "#119977", "#aa22ee", "#0088aa"],
  4719: ["#333377", "#00bb22", "#ff6633", "#666666", "#5544ff"],
  4720: ["#ffee77", "#66cc55", "#99ffbb", "#00aadd", "#001111"],
  4721: ["#bbcc88", "#779977", "#aa5511", "#aaffaa", "#bbeedd"],
  4722: ["#ccbb44", "#885577", "#55bb11", "#007799", "#ffee11"],
  4723: ["#bb5566", "#88aacc", "#2211ee", "#2266cc", "#44ffdd"],
  4724: ["#eeccee", "#5577bb", "#775500", "#996611", "#335588"],
  4725: ["#bb7733", "#55cc66", "#22bbee", "#ff1199", "#556600"],
  4726: ["#88ffaa", "#ddbb99", "#cc66aa", "#664488", "#bb9977"],
  4727: ["#664444", "#dddd44", "#883388", "#1166ff", "#ffff77"],
  4728: ["#44cc11", "#aacc66", "#ffbb99", "#11ccff", "#bbbb11"],
  4729: ["#112200", "#cc5533", "#4466cc", "#44bb22", "#ddccbb"],
  4730: ["#aabbdd", "#00ee11", "#22bb77", "#66ff55", "#006677"],
  4731: ["#8833ff", "#4444ee", "#bbff11", "#ccbb22", "#ee7755"],
  4732: ["#cc88cc", "#cc66dd", "#66eedd", "#1122dd", "#444499"],
  4733: ["#ff3377", "#ffbb11", "#44ccee", "#cc5577", "#8833ee"],
  4734: ["#cc8800", "#ff55dd", "#aa7700", "#77aa00", "#ff3333"],
  4735: ["#773333", "#aa4499", "#ffee22", "#eeff22", "#ee4422"],
  4736: ["#dddd77", "#006600", "#ccbbdd", "#00bb00", "#66ff44"],
  4737: ["#2299dd", "#77aaee", "#33ffbb", "#cc2277", "#ddee11"],
  4738: ["#ccbb22", "#ee6644", "#008877", "#ddff11", "#8888bb"],
  4739: ["#229999", "#99ccff", "#44ddaa", "#cc9988", "#222277"],
  4740: ["#55bb00", "#5500ee", "#888855", "#223300", "#bbcccc"],
  4741: ["#ee9988", "#aabbee", "#ffdd88", "#00bb66", "#66bb00"],
  4742: ["#dd11cc", "#2222aa", "#449911", "#ee2266", "#bb88ff"],
  4743: ["#662211", "#ee0088", "#3333ee", "#77ddcc", "#11aa88"],
  4744: ["#883322", "#aadd44", "#bb9999", "#7799aa", "#6666cc"],
  4745: ["#880011", "#112233", "#44aa33", "#99ccbb", "#bbaabb"],
  4746: ["#662200", "#ff55ff", "#ff2288", "#bbdd88", "#ffcc55"],
  4747: ["#ee33ee", "#55ee33", "#ffbb88", "#0055dd", "#ccff99"],
  4748: ["#ff7700", "#55eebb", "#ddee66", "#991199", "#00aa55"],
  4749: ["#cc11dd", "#bb11ee", "#77ff11", "#112244", "#663333"],
  4750: ["#885577", "#8811bb", "#88ccdd", "#221199", "#aa8877"],
  4751: ["#229922", "#446688", "#aaff77", "#7788cc", "#444400"],
  4752: ["#ffffdd", "#00aacc", "#eeddbb", "#ccbb55", "#338855"],
  4753: ["#6666bb", "#00cccc", "#1100ff", "#99bb55", "#4433bb"],
  4754: ["#00ccff", "#bbdd33", "#996688", "#ff6666", "#88bb55"],
  4755: ["#44ccdd", "#dd6688", "#331166", "#cc0066", "#229966"],
  4756: ["#cc77cc", "#887700", "#4499ff", "#2211ff", "#cc55ee"],
  4757: ["#22dd88", "#66cc77", "#ee0022", "#77bb66", "#77ff44"],
  4758: ["#ffff44", "#002277", "#44ddaa", "#441100", "#cc88ff"],
  4759: ["#ffbb55", "#bbcc11", "#bbffee", "#44ccbb", "#5555aa"],
  4760: ["#88ff88", "#770033", "#991100", "#dd22dd", "#ff99bb"],
  4761: ["#aa3399", "#bbdd00", "#ff33ff", "#ee3333", "#aa1122"],
  4762: ["#22bb00", "#bb22aa", "#779933", "#dd00ee", "#118844"],
  4763: ["#aa22bb", "#dd7777", "#77eedd", "#eeee99", "#ff8877"],
  4764: ["#66ee88", "#55cccc", "#bb0055", "#22eebb", "#bb11ff"],
  4765: ["#556677", "#33ddaa", "#99cc99", "#997744", "#994499"],
  4766: ["#33ee66", "#99bb88", "#aa9977", "#ee7755", "#1199cc"],
  4767: ["#ff77ee", "#bbdd77", "#3399cc", "#888888", "#99bb55"],
  4768: ["#bb5533", "#2211aa", "#669911", "#662200", "#bb11dd"],
  4769: ["#772233", "#44ccbb", "#eeff11", "#cc8811", "#55cc55"],
  4770: ["#1155dd", "#88bbff", "#cc55ee", "#6622bb", "#662233"],
  4771: ["#eeaaaa", "#000033", "#bbff55", "#0011ee", "#ffee11"],
  4772: ["#8844ff", "#ee9966", "#bbeeaa", "#778866", "#ee5511"],
  4773: ["#bb66aa", "#1111bb", "#00ffee", "#ee33cc", "#ee1177"],
  4774: ["#dd11ee", "#cc99aa", "#ff6633", "#9977bb", "#22ff88"],
  4775: ["#22ccff", "#7799aa", "#00cc99", "#5577cc", "#ee4400"],
  4776: ["#ff77aa", "#887744", "#0000ff", "#225511", "#661144"],
  4777: ["#220022", "#55bb44", "#22bbff", "#bb2211", "#33eecc"],
  4778: ["#eeeeaa", "#bbcccc", "#cc7700", "#2222aa", "#ee1133"],
  4779: ["#998877", "#eebbcc", "#669988", "#0044ff", "#3333cc"],
  4780: ["#448833", "#1177cc", "#dd1177", "#77ee33", "#ccffaa"],
  4781: ["#bb22bb", "#330011", "#66eeff", "#aa3344", "#66dd33"],
  4782: ["#8833dd", "#bbbb77", "#aa44dd", "#bb88cc", "#777733"],
  4783: ["#33ee33", "#88ddee", "#8844cc", "#55aa22", "#447744"],
  4784: ["#ee22dd", "#113388", "#dd9999", "#aa1100", "#cc7755"],
  4785: ["#882277", "#554400", "#ddcc77", "#66aaaa", "#88ffee"],
  4786: ["#cc9911", "#dd5555", "#cc6633", "#667799", "#1144bb"],
  4787: ["#554433", "#77aaff", "#9933ff", "#443377", "#aa66dd"],
  4788: ["#4466bb", "#11bb66", "#113388", "#bbcccc", "#aaaa33"],
  4789: ["#33dd33", "#4499ee", "#661166", "#3311dd", "#ccee22"],
  4790: ["#0011cc", "#1177cc", "#aaffff", "#997755", "#6688dd"],
  4791: ["#aa22dd", "#993388", "#774466", "#663344", "#11aa55"],
  4792: ["#eebb44", "#ff0066", "#8844dd", "#444477", "#eeee00"],
  4793: ["#444477", "#776677", "#997722", "#2288bb", "#77bbff"],
  4794: ["#4466cc", "#335599", "#774400", "#ffaa11", "#33ee55"],
  4795: ["#6611ff", "#55ccbb", "#33bb11", "#1111cc", "#bb7799"],
  4796: ["#ddff66", "#664400", "#ff5577", "#bb99ee", "#33aa88"],
  4797: ["#ccffcc", "#eeccaa", "#445566", "#66ffbb", "#bb2244"],
  4798: ["#dd55aa", "#997788", "#007799", "#eecc11", "#aa33aa"],
  4799: ["#0099ff", "#cc9900", "#554455", "#7744ff", "#ffcc55"],
  4800: ["#ddcc66", "#5544aa", "#220022", "#33ee22", "#aaaa88"],
  4801: ["#1111cc", "#11ddee", "#66ff44", "#887722", "#339922"],
  4802: ["#00aa11", "#222200", "#cc4400", "#bbbb33", "#44ff55"],
  4803: ["#1166aa", "#22ccbb", "#441100", "#ffee00", "#7700dd"],
  4804: ["#11ff11", "#ddcc55", "#aa6688", "#7733ee", "#eeeeff"],
  4805: ["#779999", "#554422", "#ee0033", "#00ddcc", "#bb88bb"],
  4806: ["#1133ee", "#66ccee", "#cc55bb", "#22ddbb", "#003366"],
  4807: ["#661155", "#44bb88", "#aa9966", "#66aa00", "#5555bb"],
  4808: ["#bb3322", "#9988dd", "#116666", "#88bb66", "#eebb00"],
  4809: ["#8822bb", "#6600cc", "#22aa88", "#6633bb", "#889999"],
  4810: ["#eeaa66", "#44bb55", "#bb0000", "#002211", "#336666"],
  4811: ["#884466", "#bb8899", "#55aa55", "#332277", "#3399dd"],
  4812: ["#bbaa77", "#dd6600", "#ffddee", "#77ff22", "#228822"],
  4813: ["#ee0022", "#aaddff", "#ddff11", "#334422", "#ffcccc"],
  4814: ["#66bb88", "#55cc33", "#228888", "#ddffaa", "#888899"],
  4815: ["#0022ff", "#ff22ee", "#554488", "#774455", "#333399"],
  4816: ["#339900", "#77ccaa", "#6611dd", "#cc77dd", "#11bb77"],
  4817: ["#ddaa33", "#eeff33", "#999944", "#22ee66", "#bbcc55"],
  4818: ["#cceebb", "#222299", "#33ffff", "#77ff77", "#9933ff"],
  4819: ["#4499aa", "#cc8899", "#dd0011", "#11bbff", "#9966ee"],
  4820: ["#ccaa00", "#ff88aa", "#11cccc", "#aadd55", "#990099"],
  4821: ["#ddff00", "#aa5522", "#ddff44", "#445588", "#eeccbb"],
  4822: ["#44ffdd", "#330099", "#ff33cc", "#8899ee", "#1122aa"],
  4823: ["#222244", "#ee11bb", "#ff1111", "#0044ee", "#003377"],
  4824: ["#331111", "#7799aa", "#110077", "#229988", "#44ddee"],
  4825: ["#aa99cc", "#55eebb", "#110077", "#99bb77", "#aaffbb"],
  4826: ["#77ee00", "#774466", "#dd55ff", "#00ff55", "#00ffdd"],
  4827: ["#77bb88", "#554444", "#dddd88", "#888833", "#0099aa"],
  4828: ["#88bb44", "#111100", "#99ff00", "#99aadd", "#997766"],
  4829: ["#aabb99", "#880077", "#6600bb", "#8822aa", "#77ffff"],
  4830: ["#22cc11", "#5599bb", "#ffdd44", "#00aa77", "#bb77cc"],
  4831: ["#4499cc", "#aa1177", "#aa0066", "#003300", "#dd2288"],
  4832: ["#ffcc55", "#115588", "#dd5533", "#5511ee", "#ee55bb"],
  4833: ["#991122", "#ee4499", "#4400aa", "#bb4499", "#aaff44"],
  4834: ["#99ee55", "#00bbdd", "#ccffee", "#224400", "#5511bb"],
  4835: ["#55ee33", "#6677bb", "#661199", "#ffbbee", "#33ffff"],
  4836: ["#ee5599", "#ee22cc", "#116699", "#ffaa11", "#00bb11"],
  4837: ["#aa00bb", "#22bbee", "#bbbb22", "#0033cc", "#220022"],
  4838: ["#7799cc", "#11cc00", "#ee00dd", "#ffaaee", "#bbddbb"],
  4839: ["#00bb44", "#eeee22", "#661166", "#33aaff", "#88dd44"],
  4840: ["#bbddff", "#cc4433", "#332244", "#ee44ff", "#662211"],
  4841: ["#880011", "#cc00cc", "#77aa99", "#bbcc44", "#00ddff"],
  4842: ["#5566cc", "#11ee22", "#aa8800", "#33aa55", "#ff8855"],
  4843: ["#770077", "#33cc88", "#330011", "#ccbb55", "#dd99aa"],
  4844: ["#8877bb", "#33bb66", "#55ccee", "#5533cc", "#ccddaa"],
  4845: ["#443355", "#1133cc", "#7733ff", "#0022bb", "#993300"],
  4846: ["#bb66ee", "#3399dd", "#00cc77", "#44cc22", "#ffcccc"],
  4847: ["#1155ff", "#ee00ee", "#66cc33", "#441100", "#aa3300"],
  4848: ["#77ccbb", "#446666", "#3399ff", "#ee1100", "#6699cc"],
  4849: ["#8866ff", "#dd6611", "#222266", "#3322bb", "#77ccaa"],
  4850: ["#ff0011", "#eecc22", "#ff2299", "#11ddbb", "#ff0055"],
  4851: ["#ff99cc", "#ee00ff", "#ee8855", "#ddee77", "#ff7755"],
  4852: ["#11cc22", "#88bb99", "#ee6644", "#662211", "#66cccc"],
  4853: ["#229955", "#ff8888", "#770088", "#7766aa", "#88dd66"],
  4854: ["#33cc55", "#eecc22", "#cc9933", "#ccee77", "#1155bb"],
  4855: ["#22dd11", "#aa0077", "#ff4455", "#ff4477", "#ccff88"],
  4856: ["#447777", "#6600ee", "#eeeeee", "#88ff00", "#554455"],
  4857: ["#88cc66", "#ffbbee", "#7700bb", "#449988", "#99cc77"],
  4858: ["#77dd55", "#aa11bb", "#ff9911", "#bbaa55", "#114466"],
  4859: ["#eeee00", "#66bbbb", "#77cc88", "#44eeaa", "#774433"],
  4860: ["#4477aa", "#88ff44", "#0066ee", "#66ff55", "#cc4422"],
  4861: ["#ccff55", "#000055", "#7700aa", "#22cc22", "#55aaaa"],
  4862: ["#eeff88", "#5566ff", "#7722cc", "#99ccee", "#22ff22"],
  4863: ["#227766", "#11aa55", "#ddbbdd", "#77ff99", "#ff77aa"],
  4864: ["#ee6688", "#33aa77", "#eecc99", "#bb0066", "#99ff33"],
  4865: ["#ddbb66", "#007788", "#ee11ee", "#11bbbb", "#440022"],
  4866: ["#cc22aa", "#996699", "#22ff44", "#88aa77", "#ee6688"],
  4867: ["#99aa44", "#bb1144", "#ffaa44", "#44aa22", "#dd5599"],
  4868: ["#11dd55", "#ffffdd", "#99ee66", "#770044", "#eeee00"],
  4869: ["#cccc00", "#1199ff", "#558844", "#6655aa", "#aa8844"],
  4870: ["#119988", "#778800", "#ff4499", "#33ee33", "#cc88dd"],
  4871: ["#44ddbb", "#cc66ee", "#770088", "#8811dd", "#44cc88"],
  4872: ["#226644", "#556677", "#11bbbb", "#dd22ff", "#887766"],
  4873: ["#44ff44", "#dd0033", "#99aa99", "#77eedd", "#aa44ff"],
  4874: ["#00bb66", "#aa0066", "#551177", "#00aa77", "#aa33dd"],
  4875: ["#dd5511", "#ee22ee", "#1133cc", "#0033ee", "#cc66ff"],
  4876: ["#77ee55", "#7733ff", "#88ddcc", "#224477", "#aa4422"],
  4877: ["#ff3333", "#33ee55", "#771188", "#6611ee", "#7788aa"],
  4878: ["#ff00cc", "#44dd33", "#88dd88", "#99dd00", "#110011"],
  4879: ["#bb99aa", "#33bbbb", "#eeffaa", "#dd2277", "#118844"],
  4880: ["#eebbbb", "#aa7766", "#55aacc", "#11aa44", "#eeff77"],
  4881: ["#ccff55", "#779933", "#ff22ff", "#001100", "#44dd77"],
  4882: ["#887788", "#8877bb", "#cccc55", "#ffee55", "#dd22dd"],
  4883: ["#dd0011", "#55ff66", "#bb0066", "#eeaa99", "#330044"],
  4884: ["#7700ff", "#9966aa", "#eeff99", "#990088", "#4455bb"],
  4885: ["#ccff66", "#33dd33", "#557722", "#bbaa77", "#9911bb"],
  4886: ["#001122", "#00ff77", "#665522", "#994466", "#bb66bb"],
  4887: ["#eeee77", "#881166", "#11ee88", "#33cc00", "#444477"],
  4888: ["#aadd55", "#cceebb", "#66aadd", "#0099dd", "#44eeee"],
  4889: ["#8877aa", "#cc44bb", "#8877cc", "#8877ff", "#5511aa"],
  4890: ["#ff9977", "#bb7788", "#ee8888", "#aa0044", "#119911"],
  4891: ["#550022", "#992299", "#bb7733", "#2277dd", "#1155ff"],
  4892: ["#bbcc66", "#ff33dd", "#eecccc", "#88aabb", "#ccbbdd"],
  4893: ["#44ff77", "#bbbb44", "#997799", "#eedd44", "#bb66dd"],
  4894: ["#ee4411", "#11bb88", "#dd7788", "#6644ff", "#552233"],
  4895: ["#ff2277", "#aacc77", "#88ddff", "#bb0055", "#55bb88"],
  4896: ["#55ee33", "#66ffff", "#dd3399", "#00aa66", "#776644"],
  4897: ["#55ff00", "#eeee99", "#55ffbb", "#2211cc", "#4499ee"],
  4898: ["#228866", "#aa99dd", "#dd6600", "#1122dd", "#6655aa"],
  4899: ["#000099", "#77eeee", "#99ee77", "#44cc44", "#006677"],
  4900: ["#4499aa", "#221155", "#4411aa", "#ffff11", "#8855aa"],
  4901: ["#ee44ee", "#ee99dd", "#ddaadd", "#eeccee", "#113322"],
  4902: ["#33ee77", "#9999aa", "#4422cc", "#aa6655", "#990088"],
  4903: ["#11cc00", "#4444ee", "#665533", "#440000", "#aabb55"],
  4904: ["#665577", "#77dd77", "#aaaa11", "#111188", "#ff77bb"],
  4905: ["#bb9900", "#1144ff", "#114411", "#994433", "#3355aa"],
  4906: ["#ff2266", "#33aa66", "#774499", "#bbff88", "#ddffee"],
  4907: ["#662299", "#117777", "#cccc33", "#8811dd", "#221144"],
  4908: ["#77ddcc", "#884466", "#ffee77", "#88ccaa", "#ee44cc"],
  4909: ["#66aa00", "#9966aa", "#ff55cc", "#4466aa", "#1188ff"],
  4910: ["#ee8866", "#99ddaa", "#3322ee", "#225577", "#8899cc"],
  4911: ["#ff44aa", "#66ffcc", "#aaddbb", "#771177", "#0044ee"],
  4912: ["#44ccdd", "#eecc99", "#66ff99", "#007733", "#666699"],
  4913: ["#8855bb", "#ff5555", "#cccc77", "#114477", "#ddbbcc"],
  4914: ["#888811", "#aa7799", "#00aaff", "#668888", "#eeaa66"],
  4915: ["#22dd11", "#cc7788", "#55ff88", "#bbcc55", "#11eeaa"],
  4916: ["#99ddbb", "#99dd77", "#00dd22", "#00dd00", "#dd66dd"],
  4917: ["#660033", "#336633", "#777755", "#44ccee", "#33ccdd"],
  4918: ["#bb8888", "#22bb11", "#8866bb", "#ccaa00", "#443399"],
  4919: ["#ccbb99", "#229977", "#9900ff", "#ee7766", "#7722aa"],
  4920: ["#88aa22", "#6633ff", "#77dd44", "#4411aa", "#aa6677"],
  4921: ["#00ee00", "#226622", "#cc99dd", "#ee66bb", "#4499aa"],
  4922: ["#9977ee", "#dd2233", "#8866cc", "#aaeedd", "#ff99aa"],
  4923: ["#00cc88", "#336622", "#552288", "#cc1188", "#221122"],
  4924: ["#3377bb", "#ccaa11", "#778899", "#dd6611", "#ccaaaa"],
  4925: ["#991144", "#ee9900", "#116666", "#7700bb", "#778800"],
  4926: ["#ddff33", "#55bb55", "#0055bb", "#3366ee", "#44ee66"],
  4927: ["#5522aa", "#3399ff", "#004411", "#ffaa11", "#eeee33"],
  4928: ["#4488ee", "#00ee66", "#66ffcc", "#22dd55", "#bbaaaa"],
  4929: ["#11ffff", "#cc1199", "#ffccaa", "#331188", "#aa4444"],
  4930: ["#88aa44", "#ccaabb", "#33ee77", "#aabb00", "#dddd22"],
  4931: ["#118811", "#8844ff", "#668877", "#99ee99", "#114422"],
  4932: ["#dd1122", "#88aadd", "#ee0000", "#554400", "#88aa55"],
  4933: ["#aa66ee", "#ffddbb", "#883311", "#116688", "#aaaa11"],
  4934: ["#2233ff", "#5555dd", "#88ddbb", "#55ffee", "#662244"],
  4935: ["#77ee99", "#118822", "#eebb22", "#ee5533", "#4455aa"],
  4936: ["#99bbaa", "#4444ff", "#44bb88", "#dd9911", "#554488"],
  4937: ["#ff5566", "#ff33dd", "#77dd99", "#cc8811", "#225533"],
  4938: ["#aa9900", "#cc66dd", "#22bb22", "#9900ff", "#ee7744"],
  4939: ["#1188ff", "#3355dd", "#aa66ff", "#772255", "#ddaaaa"],
  4940: ["#33aa44", "#8877ff", "#ff22aa", "#441155", "#335522"],
  4941: ["#ee88ee", "#ff55bb", "#66cc66", "#665588", "#66ccbb"],
  4942: ["#888844", "#990066", "#cc8899", "#11cc11", "#ddffcc"],
  4943: ["#ee7744", "#dd1188", "#996633", "#99bb44", "#000099"],
  4944: ["#ccff88", "#114477", "#ffaa44", "#88bb11", "#ffaaff"],
  4945: ["#11ddbb", "#7788aa", "#335577", "#332222", "#885566"],
  4946: ["#ffff77", "#cc3322", "#dd22ff", "#882211", "#7722ff"],
  4947: ["#88cc44", "#882277", "#ff6666", "#aa88ff", "#00ee99"],
  4948: ["#99ff44", "#dd1100", "#ee22ff", "#55cc55", "#ffbbbb"],
  4949: ["#cc3333", "#bb9933", "#dd5555", "#333366", "#554455"],
  4950: ["#775577", "#880099", "#441111", "#55aa88", "#669922"],
  4951: ["#661166", "#aa99aa", "#22aaee", "#772200", "#992200"],
  4952: ["#6644aa", "#44ee33", "#7700dd", "#cc8800", "#1133ff"],
  4953: ["#77ff55", "#55aa11", "#44eebb", "#992222", "#ff33dd"],
  4954: ["#ffaa99", "#aaaabb", "#dd5577", "#22ffcc", "#00ee88"],
  4955: ["#008855", "#aacc99", "#ee7711", "#009988", "#ee77ff"],
  4956: ["#ddbb22", "#77ddaa", "#3344ee", "#cc1144", "#ff5577"],
  4957: ["#dd9988", "#116600", "#665544", "#22aadd", "#330055"],
  4958: ["#112255", "#00cc88", "#886622", "#336666", "#110088"],
  4959: ["#eeeeaa", "#ddeeaa", "#88aa22", "#773366", "#883366"],
  4960: ["#00ee77", "#772255", "#ee22aa", "#ee3399", "#99cc99"],
  4961: ["#ff1100", "#ee00ff", "#00dd88", "#888866", "#882244"],
  4962: ["#44eecc", "#881155", "#66ee77", "#5577dd", "#336611"],
  4963: ["#77aaaa", "#ddddff", "#ee6666", "#8888ff", "#bb7799"],
  4964: ["#ff6622", "#bbbb11", "#771133", "#44ccbb", "#005566"],
  4965: ["#883388", "#009900", "#7788ff", "#33dd55", "#1133ee"],
  4966: ["#88bb88", "#441122", "#22ccdd", "#eeee22", "#3399ee"],
  4967: ["#77cccc", "#bbee00", "#88ee44", "#557711", "#ff11dd"],
  4968: ["#ffaa77", "#88bb88", "#664499", "#2200cc", "#aacc99"],
  4969: ["#002222", "#558811", "#dd55ee", "#ff44aa", "#4488ee"],
  4970: ["#aa1177", "#22bbff", "#ddff66", "#bbff88", "#7766dd"],
  4971: ["#ee3344", "#8800bb", "#aa00ee", "#3344bb", "#7744cc"],
  4972: ["#665555", "#aa3366", "#44bb11", "#5544dd", "#441100"],
  4973: ["#9922aa", "#ee3333", "#66cc99", "#dddd33", "#77cccc"],
  4974: ["#33ff00", "#330011", "#00aa33", "#8822ee", "#114422"],
  4975: ["#eeff88", "#aaff66", "#331122", "#3355cc", "#55cc22"],
  4976: ["#770033", "#bbaa22", "#6644dd", "#008844", "#88bbee"],
  4977: ["#ddbb33", "#556644", "#6633ff", "#bb9955", "#111188"],
  4978: ["#9966ff", "#991177", "#66aa55", "#66ddee", "#bb33bb"],
  4979: ["#aaaa55", "#55ccff", "#ee22aa", "#770099", "#88bb44"],
  4980: ["#ff2211", "#dd5511", "#446666", "#444400", "#443388"],
  4981: ["#008899", "#8855ee", "#0088ff", "#338888", "#cc3388"],
  4982: ["#44ddee", "#667722", "#22bb00", "#11bbcc", "#aa99aa"],
  4983: ["#9955cc", "#221199", "#66ff55", "#993300", "#446611"],
  4984: ["#008877", "#22ddaa", "#ff3300", "#aa3399", "#bb4466"],
  4985: ["#ff8822", "#ee4488", "#557755", "#001177", "#775533"],
  4986: ["#dd7711", "#cc5566", "#44aa22", "#889955", "#dd99bb"],
  4987: ["#00ee99", "#33dd99", "#00aadd", "#bb7777", "#11ff11"],
  4988: ["#005522", "#ee4411", "#dd77ff", "#226600", "#884433"],
  4989: ["#551111", "#ff2200", "#998899", "#6699dd", "#dd99ee"],
  4990: ["#bb55cc", "#8877ee", "#88cc55", "#66ffaa", "#0077ff"],
  4991: ["#66cc88", "#ee4411", "#99aadd", "#ee7766", "#993355"],
  4992: ["#22bbff", "#110022", "#22bb55", "#9988bb", "#449900"],
  4993: ["#998800", "#66ffcc", "#334477", "#66aa00", "#ee8899"],
  4994: ["#33bb44", "#66aaee", "#bb2255", "#11ccee", "#553399"],
  4995: ["#ee6666", "#cc00ee", "#aa7733", "#99bb55", "#991122"],
  4996: ["#4433cc", "#776699", "#aa22dd", "#337733", "#332255"],
  4997: ["#bb11dd", "#448822", "#88aa99", "#aa44aa", "#aaff44"],
  4998: ["#44ff66", "#5577ee", "#1177ff", "#1144ee", "#ff22ee"],
  4999: ["#99ccbb", "#aa0044", "#ffcc88", "#002244", "#22aa44"],
  5000: ["#889977", "#bbcc44", "#88eeaa", "#5555ff", "#552255"],
  5001: ["#113377", "#2255ff", "#bb8877", "#88bbdd", "#662211"],
  5002: ["#ff5544", "#11ccff", "#bb4499", "#00ee77", "#448800"],
  5003: ["#004499", "#bb4455", "#dd6622", "#ee66cc", "#bbbb44"],
  5004: ["#cc8844", "#99aa55", "#bbaa44", "#ff88cc", "#667777"],
  5005: ["#aa11cc", "#dd00ff", "#336699", "#ff55dd", "#aa1166"],
  5006: ["#664477", "#774411", "#66aa11", "#77ddbb", "#ee5544"],
  5007: ["#995566", "#7722ff", "#99ffff", "#bbddbb", "#225522"],
  5008: ["#6600ee", "#8888ff", "#770033", "#001100", "#8899dd"],
  5009: ["#334499", "#882200", "#aacc88", "#aaee44", "#116688"],
  5010: ["#eeeedd", "#ff00ee", "#888822", "#bb1166", "#22aa88"],
  5011: ["#cc8866", "#ee8844", "#dd6633", "#eecc77", "#ddaaee"],
  5012: ["#cc77ff", "#ff7733", "#aa55bb", "#cc1144", "#881111"],
  5013: ["#55ff55", "#33dd88", "#993388", "#cc9988", "#666699"],
  5014: ["#cc44bb", "#440011", "#3300ff", "#669966", "#4411ee"],
  5015: ["#11eeff", "#11dd22", "#99aacc", "#4499cc", "#6633cc"],
  5016: ["#777733", "#55ee88", "#ddcc33", "#cc1188", "#99ff33"],
  5017: ["#eecc55", "#dd1188", "#77dd55", "#22cc66", "#ff55cc"],
  5018: ["#447799", "#559988", "#cc9922", "#881155", "#4477bb"],
  5019: ["#bb0033", "#aaaa99", "#aaee55", "#aaee66", "#449977"],
  5020: ["#668833", "#ee44ee", "#0066cc", "#889900", "#bbaa66"],
  5021: ["#0099dd", "#66ff22", "#771166", "#bb00ff", "#9933aa"],
  5022: ["#336622", "#66ff99", "#88bb33", "#773366", "#ffdd55"],
  5023: ["#dd33bb", "#994400", "#bb2277", "#003322", "#ddee88"],
  5024: ["#6677ff", "#bb22bb", "#aabb88", "#ff99cc", "#ccbb22"],
  5025: ["#339900", "#bb66bb", "#5500aa", "#99bbdd", "#113322"],
  5026: ["#aa9933", "#442266", "#bb0011", "#999955", "#bbeebb"],
  5027: ["#66ddee", "#66ffcc", "#bb66aa", "#774444", "#2299aa"],
  5028: ["#2244dd", "#7777cc", "#995500", "#556655", "#ddeedd"],
  5029: ["#113322", "#11eebb", "#ccffcc", "#773388", "#bbbb99"],
  5030: ["#aa9966", "#aa6699", "#008811", "#55ffff", "#dd9933"],
  5031: ["#22bbee", "#aa66dd", "#5544bb", "#66ffbb", "#ffcc88"],
  5032: ["#ccaa99", "#66bbff", "#aaff00", "#22cc44", "#ffcc44"],
  5033: ["#22ccbb", "#ddff22", "#6666cc", "#77ddbb", "#22ee00"],
  5034: ["#2244ee", "#3366bb", "#1188cc", "#77ffcc", "#00ee77"],
  5035: ["#226633", "#77eebb", "#333311", "#882211", "#ddee66"],
  5036: ["#ffaa88", "#22cc55", "#33eeaa", "#bb9911", "#bb4411"],
  5037: ["#44dd22", "#0033bb", "#4455dd", "#bbaa99", "#dd88ee"],
  5038: ["#55bb66", "#ff66cc", "#dd11cc", "#cc6666", "#00ff77"],
  5039: ["#ff0088", "#447733", "#33dd00", "#4455cc", "#559933"],
  5040: ["#cc8888", "#aa1155", "#44ccaa", "#77ccaa", "#eebb00"],
  5041: ["#aa9944", "#444444", "#aaaa88", "#00bb00", "#55bbee"],
  5042: ["#440066", "#99dd55", "#44aa88", "#ccaa33", "#33cc88"],
  5043: ["#8855cc", "#5511ee", "#332266", "#55aaaa", "#2266aa"],
  5044: ["#bb88ff", "#eeaa66", "#ee55ee", "#bb4499", "#770088"],
  5045: ["#66ffee", "#aa00ee", "#22ffcc", "#77bb33", "#777733"],
  5046: ["#cc88aa", "#aabb77", "#11cc55", "#446644", "#dd2277"],
  5047: ["#ccff99", "#ff2277", "#3388dd", "#66bbbb", "#22cc44"],
  5048: ["#5566bb", "#ddffbb", "#88dd22", "#aa9922", "#8899ee"],
  5049: ["#11aa55", "#667744", "#33cccc", "#226644", "#00dd22"],
  5050: ["#44aa33", "#ff77bb", "#00aa44", "#88ff00", "#1177cc"],
  5051: ["#ff1166", "#88bbdd", "#880088", "#117722", "#dd7755"],
  5052: ["#557777", "#ccaa00", "#cc33aa", "#997744", "#aa11ff"],
  5053: ["#667722", "#77cc33", "#331177", "#88bbee", "#eedd99"],
  5054: ["#aaaa22", "#77aa99", "#7788ff", "#6688dd", "#bbee33"],
  5055: ["#1111dd", "#3399ee", "#331155", "#7733ee", "#bb33ee"],
  5056: ["#112200", "#22bb00", "#3311aa", "#ccbbdd", "#aacc33"],
  5057: ["#776666", "#22ddcc", "#112222", "#00ee55", "#dd44cc"],
  5058: ["#bb1133", "#33ff88", "#772222", "#227733", "#889966"],
  5059: ["#443333", "#88dd00", "#44ddbb", "#aaffbb", "#88ddbb"],
  5060: ["#66bb11", "#ff2200", "#ee7711", "#004444", "#665500"],
  5061: ["#443344", "#0044aa", "#88ddee", "#669900", "#ee77dd"],
  5062: ["#0011bb", "#ccbbff", "#0044ee", "#6666aa", "#88ccaa"],
  5063: ["#88ffbb", "#cccc66", "#ddbb99", "#6666cc", "#3322dd"],
  5064: ["#55dddd", "#3300ee", "#994488", "#441166", "#114422"],
  5065: ["#1144aa", "#997755", "#119955", "#66dd66", "#446677"],
  5066: ["#9911cc", "#553322", "#aa44cc", "#55dd55", "#ddaa33"],
  5067: ["#44ee22", "#77ee66", "#8866ee", "#77bbbb", "#554400"],
  5068: ["#ee1111", "#2244ee", "#ff0022", "#5544cc", "#33dddd"],
  5069: ["#aa8811", "#dd3377", "#2266ee", "#aa2244", "#bbaa66"],
  5070: ["#88ddcc", "#66aa66", "#00eecc", "#336688", "#006600"],
  5071: ["#552244", "#1100ee", "#66ee44", "#4411bb", "#ee6666"],
  5072: ["#eeccdd", "#88dd55", "#993366", "#88bb66", "#dd1133"],
  5073: ["#992244", "#88bbee", "#ffdd00", "#2299cc", "#226699"],
  5074: ["#bb22bb", "#bb9944", "#889988", "#1144aa", "#11ee11"],
  5075: ["#22dd55", "#88ccaa", "#dddddd", "#ffbb77", "#aa55bb"],
  5076: ["#aa0055", "#44dd22", "#eebb55", "#dd5544", "#88eeaa"],
  5077: ["#ffaa77", "#bbee55", "#aa2222", "#880099", "#ccbbcc"],
  5078: ["#bb8877", "#aa33bb", "#ffee77", "#cc55dd", "#ffbbee"],
  5079: ["#775555", "#224466", "#cceedd", "#55ee44", "#8811ff"],
  5080: ["#4422ee", "#55ff99", "#66eebb", "#ffdd22", "#33ddff"],
  5081: ["#88ffbb", "#cc0000", "#6611dd", "#222255", "#772244"],
  5082: ["#dd5577", "#662288", "#dd22ee", "#dd7777", "#004411"],
  5083: ["#eeaa99", "#bbdd88", "#ddcc66", "#ff8877", "#7700ff"],
  5084: ["#667777", "#881144", "#ff2233", "#1177dd", "#88ccbb"],
  5085: ["#6622aa", "#ddeecc", "#779922", "#77ffbb", "#11aaff"],
  5086: ["#2266bb", "#884422", "#885588", "#330000", "#55ffcc"],
  5087: ["#aa44ee", "#3366aa", "#118855", "#aa9977", "#11ff99"],
  5088: ["#2288dd", "#338800", "#331177", "#aa7711", "#664477"],
  5089: ["#ddccee", "#002266", "#ee66dd", "#7788ee", "#ffffee"],
  5090: ["#99bbee", "#eeaa66", "#ddbb11", "#5511aa", "#99aa77"],
  5091: ["#88ff99", "#ee6633", "#88ee55", "#dddd99", "#aa11aa"],
  5092: ["#33ff00", "#88aa22", "#55ff44", "#778877", "#7766ee"],
  5093: ["#66cc77", "#dd11ee", "#8800cc", "#55ccff", "#ffee77"],
  5094: ["#ee8866", "#66eecc", "#5555cc", "#006655", "#dd8800"],
  5095: ["#992288", "#4477cc", "#99ff99", "#770022", "#aa99dd"],
  5096: ["#008877", "#000066", "#ff9988", "#774433", "#eeaa88"],
  5097: ["#997700", "#dd5511", "#aaaadd", "#994444", "#aa22aa"],
  5098: ["#dd33dd", "#5511bb", "#888899", "#cceecc", "#778899"],
  5099: ["#221155", "#338866", "#8855dd", "#00ff55", "#aa55ee"],
  5100: ["#77ff44", "#44cc88", "#000033", "#dd7722", "#005533"],
  5101: ["#008844", "#ee88cc", "#88dd00", "#aaee22", "#dd6644"],
  5102: ["#7733ee", "#9911dd", "#cc88dd", "#66ffff", "#aaaadd"],
  5103: ["#aa9933", "#aa2299", "#228899", "#3355cc", "#33cccc"],
  5104: ["#ff3333", "#ffffbb", "#22ffee", "#cc7700", "#779977"],
  5105: ["#447744", "#ff5588", "#662299", "#11cccc", "#664499"],
  5106: ["#44eebb", "#110066", "#cc2277", "#0033aa", "#441122"],
  5107: ["#333333", "#ffbbdd", "#9999ee", "#bb66cc", "#11aa99"],
  5108: ["#558866", "#aaeeee", "#112211", "#cc11ee", "#bbcc77"],
  5109: ["#008888", "#ffff66", "#88cccc", "#1122aa", "#ff66dd"],
  5110: ["#991177", "#7711aa", "#aa9911", "#ff22ee", "#ccdd99"],
  5111: ["#aaddee", "#cc7777", "#ee5544", "#44aabb", "#cc8888"],
  5112: ["#ddee33", "#aa2233", "#772244", "#cc8844", "#bbaa55"],
  5113: ["#000033", "#ff7788", "#eeff66", "#113399", "#cc1166"],
  5114: ["#001122", "#eebb00", "#00cc66", "#0088ff", "#666611"],
  5115: ["#cc44dd", "#770055", "#55ff44", "#99bb00", "#bb5566"],
  5116: ["#aabbaa", "#883399", "#bb2244", "#66bb99", "#33ccaa"],
  5117: ["#66dd99", "#77ccff", "#44ffee", "#007722", "#11ccee"],
  5118: ["#226655", "#111111", "#33ee22", "#550033", "#119999"],
  5119: ["#ffff55", "#ee7777", "#dd9977", "#6644ff", "#11aa99"],
  5120: ["#5566dd", "#ee00ee", "#eeccee", "#55aadd", "#88ee77"],
  5121: ["#5500cc", "#cc6600", "#5588bb", "#77ddaa", "#0044ee"],
  5122: ["#22ee55", "#5500aa", "#77bb55", "#448888", "#aa6633"],
  5123: ["#5599aa", "#ee33ee", "#11ffff", "#00aa66", "#bb7722"],
  5124: ["#ff33aa", "#ccdd55", "#2299aa", "#2266cc", "#ccee66"],
  5125: ["#330099", "#220044", "#118822", "#996600", "#551100"],
  5126: ["#4499dd", "#cc2255", "#4411aa", "#119933", "#55ffff"],
  5127: ["#554400", "#cc0000", "#667733", "#668822", "#55dd55"],
  5128: ["#bb33dd", "#22ddbb", "#885566", "#55cc55", "#aadd11"],
  5129: ["#ffbb99", "#22bbbb", "#ccdd22", "#11bb99", "#44ffaa"],
  5130: ["#99bbff", "#ff7711", "#110077", "#0088ee", "#116699"],
  5131: ["#11ee33", "#ee88ff", "#00dd88", "#88bbcc", "#88ee33"],
  5132: ["#dd00aa", "#661155", "#556622", "#ddffee", "#aa6699"],
  5133: ["#ff7722", "#ccaa77", "#cc66cc", "#77dd33", "#330011"],
  5134: ["#33aa33", "#0066aa", "#9977dd", "#ddbb99", "#8844ff"],
  5135: ["#663300", "#ffaaee", "#dd0066", "#7733ee", "#66dd44"],
  5136: ["#ff99ff", "#556633", "#771144", "#bb1177", "#ddccaa"],
  5137: ["#003399", "#773377", "#ffaa33", "#ddaadd", "#bb77cc"],
  5138: ["#66cccc", "#337744", "#116622", "#bbcc55", "#22ee00"],
  5139: ["#cc6622", "#555533", "#aaff33", "#7766ff", "#dd8855"],
  5140: ["#550088", "#55dd44", "#bb11bb", "#660055", "#8822bb"],
  5141: ["#cc9966", "#005522", "#004422", "#55bbee", "#559944"],
  5142: ["#449900", "#ee4466", "#9977aa", "#99aacc", "#cc2244"],
  5143: ["#77eeee", "#55dd99", "#ddeedd", "#dd9966", "#ddbb44"],
  5144: ["#44ee44", "#228855", "#3311cc", "#dd0033", "#ff88ee"],
  5145: ["#114444", "#dd7799", "#4477aa", "#00bbdd", "#88cc66"],
  5146: ["#ff5588", "#aabbdd", "#776633", "#224444", "#ee4466"],
  5147: ["#ffee66", "#992266", "#66bb22", "#ccff00", "#667799"],
  5148: ["#00dd22", "#33ffbb", "#8877ee", "#cc22aa", "#772222"],
  5149: ["#eebb55", "#7722ee", "#6622bb", "#11ff11", "#772244"],
  5150: ["#bb33cc", "#7711aa", "#559988", "#ccee44", "#dd5511"],
  5151: ["#00dd33", "#7766ee", "#bb99ff", "#aa00ee", "#664466"],
  5152: ["#556633", "#bbddbb", "#66dd44", "#ee4422", "#66cc55"],
  5153: ["#3300ee", "#115566", "#221133", "#9922bb", "#55ccaa"],
  5154: ["#448833", "#88dd55", "#ff7777", "#ee22aa", "#dd8877"],
  5155: ["#aa2211", "#0033bb", "#ccee99", "#22ddbb", "#ffff55"],
  5156: ["#ddff11", "#220044", "#55ee77", "#55ff22", "#999933"],
  5157: ["#99eeaa", "#332222", "#331144", "#ddee99", "#9911bb"],
  5158: ["#330055", "#8855ff", "#ff2244", "#ddffdd", "#aa77ee"],
  5159: ["#113377", "#dd2200", "#778811", "#aa55ff", "#772244"],
  5160: ["#558800", "#cc6611", "#22cc88", "#776699", "#00bb11"],
  5161: ["#667799", "#aacc99", "#9933dd", "#aa99ee", "#000055"],
  5162: ["#dddd11", "#33ccdd", "#cc33bb", "#ccbb88", "#ff2233"],
  5163: ["#77ffaa", "#0022aa", "#77bbff", "#aabbcc", "#22eebb"],
  5164: ["#772255", "#ff9911", "#99dd44", "#66ee66", "#77ccdd"],
  5165: ["#bb66aa", "#cc5511", "#996622", "#ffff00", "#ffeeff"],
  5166: ["#88bb00", "#883311", "#113344", "#aa7722", "#118877"],
  5167: ["#22ddcc", "#bbff77", "#3399ff", "#bbaa99", "#005588"],
  5168: ["#cc44bb", "#445522", "#ffbb55", "#0088bb", "#550011"],
  5169: ["#000011", "#22cc66", "#ee2200", "#ccffdd", "#0099aa"],
  5170: ["#bb2211", "#bb2222", "#ddff99", "#ee9922", "#aa77cc"],
  5171: ["#bb6611", "#00cc55", "#bbff55", "#223322", "#2222cc"],
  5172: ["#ddbb22", "#88ff99", "#ff44ee", "#2233ff", "#77bbcc"],
  5173: ["#001199", "#bb1133", "#ddee99", "#aa1155", "#33ee66"],
  5174: ["#bbaa44", "#555544", "#33ffdd", "#bbff88", "#dd99ee"],
  5175: ["#990066", "#22ff55", "#00ffaa", "#bbcc55", "#881111"],
  5176: ["#00ee77", "#bb22aa", "#559977", "#99ff77", "#dd5588"],
  5177: ["#9911cc", "#66ffee", "#11aa22", "#ddffcc", "#115511"],
  5178: ["#88ee99", "#227711", "#224488", "#33ff99", "#aa2266"],
  5179: ["#4400aa", "#ddbb99", "#ddccee", "#779999", "#00ee99"],
  5180: ["#6677aa", "#dd0044", "#77bb44", "#ffbb11", "#dd5533"],
  5181: ["#55aa11", "#0000cc", "#008877", "#88ddbb", "#bb44ee"],
  5182: ["#ff44dd", "#ff6633", "#99aacc", "#ccbbff", "#99bb22"],
  5183: ["#8899aa", "#664444", "#11aa00", "#227711", "#aa5533"],
  5184: ["#eeee11", "#88aa11", "#bbbbee", "#7733cc", "#eedd00"],
  5185: ["#3377bb", "#66ddff", "#88bb55", "#aa5577", "#bb77cc"],
  5186: ["#552299", "#bb22bb", "#33eecc", "#aaff44", "#ff22ee"],
  5187: ["#eebbff", "#77ddcc", "#336600", "#9999cc", "#5588aa"],
  5188: ["#eebb44", "#660077", "#ff4433", "#220000", "#cc55ff"],
  5189: ["#aa11ee", "#88ee22", "#bbffbb", "#55eecc", "#77dd99"],
  5190: ["#2277dd", "#33cc44", "#886600", "#44ffee", "#dd7777"],
  5191: ["#dd2244", "#111122", "#227799", "#66ee22", "#337722"],
  5192: ["#ff5522", "#88ee11", "#eeccff", "#ccffbb", "#ffccbb"],
  5193: ["#11ffdd", "#998888", "#ff4477", "#33ccbb", "#11aa55"],
  5194: ["#114455", "#11ffff", "#bb77cc", "#99bb99", "#ee00dd"],
  5195: ["#884433", "#3355cc", "#998822", "#55ffff", "#ee2299"],
  5196: ["#998899", "#cc44aa", "#bbaa55", "#9922ff", "#004422"],
  5197: ["#446611", "#774400", "#003388", "#bb22dd", "#ffbb55"],
  5198: ["#cc8844", "#003399", "#449977", "#995566", "#886699"],
  5199: ["#ee1122", "#eebb77", "#331122", "#cc88bb", "#ee5588"],
  5200: ["#664433", "#112266", "#ff11bb", "#44eeff", "#cc11ff"],
  5201: ["#bb88cc", "#3399cc", "#cc77cc", "#ff99aa", "#440044"],
  5202: ["#448888", "#88bbbb", "#6600cc", "#77dd00", "#dd3333"],
  5203: ["#33dd66", "#11cc44", "#886644", "#8899dd", "#1188bb"],
  5204: ["#aaaa33", "#88bbbb", "#eeeeee", "#bb3311", "#cc0033"],
  5205: ["#eeccbb", "#aa5588", "#662299", "#4411aa", "#668833"],
  5206: ["#117700", "#884477", "#eeddbb", "#446644", "#33ee11"],
  5207: ["#ee22dd", "#33ffdd", "#ee11bb", "#ffccaa", "#2266bb"],
  5208: ["#dddd66", "#1155ff", "#ffbb33", "#ffbbee", "#00bbbb"],
  5209: ["#bb77bb", "#444444", "#ccff00", "#ff66ee", "#33bbff"],
  5210: ["#cc44cc", "#226633", "#995511", "#55ddbb", "#229933"],
  5211: ["#002277", "#88ee44", "#33dd66", "#aa1166", "#113344"],
  5212: ["#22bb55", "#666666", "#886622", "#772299", "#cc8811"],
  5213: ["#22ffff", "#889922", "#ff8811", "#ff77aa", "#774433"],
  5214: ["#888822", "#ff44aa", "#000011", "#11bbff", "#ccaa55"],
  5215: ["#7733ee", "#6622cc", "#8877ff", "#aaccee", "#dd6655"],
  5216: ["#55ff11", "#bb77dd", "#00ff99", "#ff2255", "#44cc99"],
  5217: ["#cc88bb", "#33bb44", "#ff33bb", "#aaaabb", "#dd8855"],
  5218: ["#dd4444", "#ccff88", "#550088", "#441111", "#66cc11"],
  5219: ["#cc9977", "#881133", "#001100", "#224477", "#cc2266"],
  5220: ["#990044", "#22bb00", "#55ffcc", "#eeeeff", "#22cc44"],
  5221: ["#8833aa", "#88cc33", "#dd11aa", "#664455", "#ffccdd"],
  5222: ["#5511ff", "#bb44ee", "#885511", "#00bb66", "#aaee00"],
  5223: ["#bb11aa", "#8811bb", "#8800aa", "#88ddff", "#33ddbb"],
  5224: ["#664422", "#99ff88", "#8877dd", "#660000", "#77ee22"],
  5225: ["#88ee44", "#771188", "#77ddbb", "#aa44bb", "#ccdd00"],
  5226: ["#aaffee", "#446655", "#bb6666", "#eeaa22", "#bb33cc"],
  5227: ["#449977", "#444477", "#cc00aa", "#dd1177", "#112299"],
  5228: ["#aaee33", "#dd77ee", "#ff0044", "#993333", "#882288"],
  5229: ["#ee55dd", "#aa00cc", "#558833", "#557733", "#779922"],
  5230: ["#3399bb", "#226611", "#aa77dd", "#115577", "#5577cc"],
  5231: ["#55bbcc", "#bb33aa", "#88ff88", "#ff33dd", "#3377dd"],
  5232: ["#33ff55", "#556622", "#ccdd00", "#008811", "#66aaaa"],
  5233: ["#aaaa55", "#bb2277", "#3366aa", "#ee4422", "#99bb33"],
  5234: ["#aaee00", "#bb99cc", "#aaffcc", "#33cccc", "#000033"],
  5235: ["#bb8844", "#dd8800", "#ff8844", "#44ffcc", "#aa66dd"],
  5236: ["#337722", "#ff5588", "#994466", "#55aa33", "#443355"],
  5237: ["#11dd22", "#44aa77", "#aacc77", "#448877", "#666677"],
  5238: ["#eeeecc", "#227755", "#aa2299", "#448811", "#22aa99"],
  5239: ["#88eeff", "#008822", "#550088", "#889900", "#eecccc"],
  5240: ["#ddee22", "#ee00dd", "#55ee66", "#ee2211", "#66cc44"],
  5241: ["#bb22dd", "#77ffcc", "#66cc99", "#66aabb", "#ffbb99"],
  5242: ["#882200", "#aa44ff", "#ffeeaa", "#ccaa22", "#aa4488"],
  5243: ["#ff1166", "#bb11dd", "#88bbff", "#ddbb66", "#aa6600"],
  5244: ["#aaff66", "#000022", "#6611ee", "#bbddcc", "#99ee11"],
  5245: ["#ffaa44", "#22ffbb", "#33cc11", "#aaee66", "#ee8811"],
  5246: ["#66aa22", "#882277", "#9977cc", "#ffdd33", "#8899dd"],
  5247: ["#55bbee", "#ddbb22", "#119999", "#cc4488", "#88ee88"],
  5248: ["#559955", "#aa33ff", "#227700", "#6600cc", "#11ccdd"],
  5249: ["#44eeaa", "#bb8811", "#8811aa", "#2233bb", "#889999"],
  5250: ["#cc8811", "#aaaaaa", "#ee99dd", "#dd7722", "#55dd88"],
  5251: ["#ee8822", "#22ff22", "#bb3344", "#3399dd", "#aaaa22"],
  5252: ["#6655ff", "#dd44cc", "#332288", "#55bb99", "#ff2266"],
  5253: ["#bbeedd", "#00dd99", "#dd33bb", "#330099", "#ff6655"],
  5254: ["#ccff66", "#55dd88", "#bb0077", "#889933", "#bbcc00"],
  5255: ["#3355bb", "#77bbaa", "#7722ee", "#77ccaa", "#11ee77"],
  5256: ["#ff4488", "#771199", "#bb7799", "#993399", "#bb55bb"],
  5257: ["#0088cc", "#77ddee", "#773399", "#22bb11", "#001166"],
  5258: ["#aa1122", "#335588", "#224499", "#9988ff", "#aaee44"],
  5259: ["#77dd22", "#997755", "#dd55bb", "#993311", "#8888dd"],
  5260: ["#221144", "#11bb22", "#88ee77", "#9988ff", "#33bbaa"],
  5261: ["#1177bb", "#113388", "#001144", "#55bb99", "#1199ee"],
  5262: ["#cc7744", "#44aaff", "#7788dd", "#cc99ee", "#88ff55"],
  5263: ["#ee22aa", "#998822", "#aa88ff", "#cc2244", "#5533aa"],
  5264: ["#aacc33", "#aa1133", "#55dd11", "#228844", "#aa3366"],
  5265: ["#aa44cc", "#cc55aa", "#338833", "#99dd66", "#5566dd"],
  5266: ["#7799aa", "#bb99dd", "#dd4477", "#ff6655", "#bb44aa"],
  5267: ["#bb66cc", "#6677aa", "#44bb99", "#113344", "#cc33ee"],
  5268: ["#88ff33", "#88cc55", "#4488dd", "#bbccff", "#33aa00"],
  5269: ["#ee5588", "#22bb00", "#77ffff", "#000066", "#11aa66"],
  5270: ["#445555", "#ff44dd", "#99cc55", "#cc6611", "#ddbb66"],
  5271: ["#0099aa", "#cc2266", "#8866ff", "#444411", "#00ddee"],
  5272: ["#22bbdd", "#dd3399", "#eebbee", "#55aa00", "#33ccbb"],
  5273: ["#776644", "#668866", "#661122", "#ff22cc", "#dd00cc"],
  5274: ["#aa9933", "#335588", "#00bb66", "#22ff33", "#5511dd"],
  5275: ["#66aa11", "#220000", "#cc44bb", "#776688", "#990055"],
  5276: ["#7766ff", "#77ee99", "#55eedd", "#bbbb11", "#eeddbb"],
  5277: ["#33ff88", "#665533", "#dd11ff", "#ccee66", "#ee8888"],
  5278: ["#2222ff", "#aa3311", "#7722ff", "#0077dd", "#334466"],
  5279: ["#ff1122", "#885522", "#005544", "#eedd22", "#999944"],
  5280: ["#330011", "#991177", "#88bbcc", "#ff00cc", "#ddcc00"],
  5281: ["#005544", "#eebb66", "#aa3322", "#442288", "#bb1155"],
  5282: ["#8822cc", "#aaccaa", "#aaee66", "#00ccee", "#55ffcc"],
  5283: ["#5544aa", "#3377aa", "#553344", "#7733dd", "#77ccaa"],
  5284: ["#11bb77", "#cc3300", "#ff9955", "#9999ee", "#cc00bb"],
  5285: ["#558866", "#77cc22", "#4488aa", "#2299aa", "#8822dd"],
  5286: ["#449900", "#aa99ff", "#11ff66", "#662266", "#00ffbb"],
  5287: ["#997722", "#ff8855", "#aabb44", "#bb8866", "#ddaa99"],
  5288: ["#661100", "#ee1144", "#55eedd", "#444422", "#2222bb"],
  5289: ["#ddeecc", "#ee99aa", "#5533cc", "#669988", "#4411bb"],
  5290: ["#00dd66", "#00ff88", "#eebb00", "#994488", "#662299"],
  5291: ["#aabb88", "#449977", "#881133", "#ee33ee", "#005555"],
  5292: ["#aaddff", "#111144", "#ddddbb", "#aaee66", "#dd44aa"],
  5293: ["#77ff33", "#eeaaaa", "#ccbbcc", "#ff00cc", "#77aa77"],
  5294: ["#bb9922", "#11aa00", "#33dd22", "#22ff55", "#440033"],
  5295: ["#663311", "#5544ff", "#1144aa", "#ddaacc", "#1199aa"],
  5296: ["#eeee77", "#77dd55", "#cccc22", "#ff9977", "#8833cc"],
  5297: ["#aaff22", "#ffaaff", "#9900cc", "#ee0011", "#8888ff"],
  5298: ["#005511", "#44ff55", "#bbee44", "#445500", "#ee5588"],
  5299: ["#11bbff", "#77ee11", "#cc4433", "#1122cc", "#eecc00"],
  5300: ["#ff2222", "#225500", "#bb11aa", "#77ee66", "#cc66aa"],
  5301: ["#664444", "#77dd77", "#aabb88", "#ffee11", "#880000"],
  5302: ["#8811aa", "#dd7711", "#ff3366", "#770022", "#117700"],
  5303: ["#99aa66", "#dd4488", "#bb22dd", "#33cc22", "#ff1100"],
  5304: ["#3388bb", "#77ddbb", "#cc44dd", "#996622", "#cc5555"],
  5305: ["#22cc77", "#ffcc77", "#dd66bb", "#885511", "#dd4411"],
  5306: ["#cc66cc", "#550099", "#ff3366", "#335555", "#cc7700"],
  5307: ["#ddaaff", "#449933", "#66bbff", "#22ee55", "#88aa33"],
  5308: ["#1100ee", "#88ff44", "#eeddcc", "#ccffbb", "#8800aa"],
  5309: ["#00bb66", "#ccddbb", "#552244", "#dd4411", "#cc99bb"],
  5310: ["#004499", "#339900", "#cc9988", "#aa00ff", "#227755"],
  5311: ["#5500ff", "#110011", "#226655", "#77aa77", "#228833"],
  5312: ["#cc22ee", "#3311cc", "#443300", "#8833cc", "#bb00dd"],
  5313: ["#118899", "#ee1155", "#003388", "#aaeecc", "#990044"],
  5314: ["#7766bb", "#99dd44", "#55eeee", "#8833cc", "#0088ee"],
  5315: ["#ee99aa", "#44cc33", "#aaee99", "#1155aa", "#777733"],
  5316: ["#446655", "#114422", "#554499", "#ff44ff", "#5588ee"],
  5317: ["#8811aa", "#cc0088", "#dd66aa", "#cc99ff", "#88ffee"],
  5318: ["#446622", "#bb77ee", "#443344", "#440011", "#7755bb"],
  5319: ["#118833", "#ee5511", "#225588", "#99ee88", "#eeffff"],
  5320: ["#88dd55", "#ffccbb", "#997755", "#66bb33", "#bb8800"],
  5321: ["#555588", "#8833dd", "#bb1144", "#990033", "#44bbdd"],
  5322: ["#55ccaa", "#556699", "#dd1122", "#bb11ee", "#008811"],
  5323: ["#aaffdd", "#33cc99", "#aaffee", "#0000bb", "#88ff88"],
  5324: ["#ff66ff", "#ffee66", "#ee6699", "#33ccdd", "#44cc99"],
  5325: ["#aa3322", "#ffbbee", "#447700", "#551155", "#448833"],
  5326: ["#6633cc", "#77bb22", "#77ee33", "#ee11dd", "#999933"],
  5327: ["#77ff22", "#66ddff", "#552288", "#bb3311", "#664444"],
  5328: ["#88bb66", "#cccc55", "#5500aa", "#88bbaa", "#cc22ff"],
  5329: ["#22cc33", "#22aaff", "#ff55bb", "#99dddd", "#eeeeff"],
  5330: ["#ff9900", "#1111ff", "#660055", "#885500", "#ccdd00"],
  5331: ["#8800dd", "#aa3322", "#bb0099", "#99aa66", "#44bb00"],
  5332: ["#11bb44", "#ee3322", "#779900", "#996622", "#aacc55"],
  5333: ["#bb55dd", "#aa0044", "#dd7766", "#cc77cc", "#9900cc"],
  5334: ["#114488", "#44ddff", "#229900", "#ffdd33", "#ffddaa"],
  5335: ["#11aa77", "#551111", "#117766", "#444488", "#55bb88"],
  5336: ["#dd8855", "#44aaaa", "#ccbb22", "#ee11aa", "#55bb77"],
  5337: ["#22cc44", "#44aaee", "#aa0066", "#ff5544", "#44cc11"],
  5338: ["#ff77ff", "#ddee44", "#558833", "#22bb11", "#bb9944"],
  5339: ["#bb0088", "#6611ee", "#bb0044", "#22bb11", "#772299"],
  5340: ["#443322", "#007755", "#44aa55", "#9944aa", "#666644"],
  5341: ["#ddaa00", "#444477", "#005566", "#cc00dd", "#cc7700"],
  5342: ["#772299", "#446655", "#bb6611", "#66eeaa", "#00eebb"],
  5343: ["#33cccc", "#8822bb", "#99ccbb", "#4400dd", "#3322aa"],
  5344: ["#884411", "#ff6611", "#cc11ee", "#ddffdd", "#7755bb"],
  5345: ["#77aabb", "#776688", "#66bb88", "#ff11aa", "#3311bb"],
  5346: ["#448899", "#77ccee", "#aa2222", "#003300", "#aa7722"],
  5347: ["#001199", "#66dd33", "#eeff99", "#22cc11", "#99cc77"],
  5348: ["#118855", "#449944", "#ee44aa", "#442211", "#88ff11"],
  5349: ["#88ffbb", "#992299", "#6644ff", "#bbccaa", "#dddd00"],
  5350: ["#8855dd", "#116611", "#55bb44", "#aa99ee", "#ff22bb"],
  5351: ["#4455ee", "#553388", "#778844", "#ffff33", "#885544"],
  5352: ["#00cc55", "#dd7711", "#cc3355", "#3311dd", "#ee22ff"],
  5353: ["#5566ff", "#22dd11", "#44bb33", "#bbbbee", "#225577"],
  5354: ["#990022", "#22ee33", "#11ffaa", "#bbff55", "#44aacc"],
  5355: ["#ddff00", "#ddffff", "#444411", "#226666", "#771177"],
  5356: ["#dd8855", "#0022ee", "#550033", "#883300", "#ffaa00"],
  5357: ["#336600", "#ccaa44", "#88cc11", "#772211", "#770099"],
  5358: ["#bb0022", "#66ee11", "#662288", "#dd0011", "#ee0044"],
  5359: ["#117777", "#668877", "#114499", "#2277ff", "#44bbff"],
  5360: ["#00bb77", "#997755", "#ff44aa", "#ee3322", "#33bb33"],
  5361: ["#1133cc", "#118866", "#775544", "#00bb22", "#eeaa22"],
  5362: ["#2277bb", "#cc9999", "#dd66ee", "#77ee11", "#662266"],
  5363: ["#888811", "#22ff66", "#dddd99", "#339966", "#881122"],
  5364: ["#55ff55", "#dddddd", "#330066", "#bb33ee", "#99aa55"],
  5365: ["#8833ff", "#4477dd", "#998866", "#88aaee", "#aa33cc"],
  5366: ["#11aa33", "#dd5511", "#bb3399", "#2233ee", "#0022ee"],
  5367: ["#aa7777", "#3322aa", "#22bb33", "#dd6688", "#bb5500"],
  5368: ["#ff3355", "#ff8844", "#77ccee", "#bb99cc", "#ff7711"],
  5369: ["#ee55ee", "#aa11aa", "#ff0011", "#888844", "#7711cc"],
  5370: ["#44bbff", "#44aaff", "#44ccdd", "#11bb22", "#556611"],
  5371: ["#ffddaa", "#9966dd", "#223300", "#aa5522", "#ddcc88"],
  5372: ["#5566bb", "#556688", "#880044", "#664477", "#11bb00"],
  5373: ["#1111ee", "#ee0033", "#ffee55", "#1188ff", "#ee77ff"],
  5374: ["#889988", "#55ffee", "#aacc77", "#dd7733", "#aa11ff"],
  5375: ["#ee2222", "#999955", "#cc00ee", "#995566", "#dd4444"],
  5376: ["#110033", "#99ff11", "#6600dd", "#bbdd55", "#ffeeff"],
  5377: ["#44cc77", "#88ffcc", "#2288bb", "#889966", "#555511"],
  5378: ["#7733dd", "#ee9955", "#aabbbb", "#ff5566", "#333377"],
  5379: ["#ff44ee", "#eeaa11", "#663322", "#99bb00", "#ccee11"],
  5380: ["#330033", "#bb8811", "#9988aa", "#ffff33", "#998899"],
  5381: ["#ee88dd", "#4466bb", "#bb5555", "#888811", "#66ff11"],
  5382: ["#991199", "#ff9955", "#33ff55", "#33ffee", "#440011"],
  5383: ["#66ddff", "#ff2266", "#ee8866", "#66dd66", "#88ccdd"],
  5384: ["#7766aa", "#11ff33", "#cc5511", "#6699ff", "#99ee11"],
  5385: ["#ee11aa", "#774400", "#ff66aa", "#cceeff", "#ee77dd"],
  5386: ["#66ffff", "#dd11aa", "#9933ee", "#cc00ff", "#00dddd"],
  5387: ["#880066", "#55ddbb", "#aadd99", "#ee88ff", "#8822ee"],
  5388: ["#993311", "#447722", "#442288", "#88ee88", "#eeccaa"],
  5389: ["#11ddee", "#1133bb", "#1166aa", "#661155", "#55ddee"],
  5390: ["#44bb77", "#44ff77", "#33ffee", "#225599", "#ff0000"],
  5391: ["#553399", "#dd1122", "#3377bb", "#88ff22", "#33eedd"],
  5392: ["#bbcc33", "#7755cc", "#44ff33", "#330033", "#667722"],
  5393: ["#66bbdd", "#115511", "#aa1199", "#ddaa55", "#11dd77"],
  5394: ["#ffddaa", "#55cc77", "#771133", "#dddddd", "#885500"],
  5395: ["#4455bb", "#2266dd", "#00bb33", "#55ffff", "#ffdddd"],
  5396: ["#7744bb", "#cc88ee", "#cc4400", "#eedd66", "#eeddaa"],
  5397: ["#665500", "#2266bb", "#8899cc", "#447744", "#8844ee"],
  5398: ["#88ee99", "#ccee00", "#ff55ee", "#55ff88", "#00ccff"],
  5399: ["#ffbb22", "#cc66dd", "#aabb33", "#dd5588", "#66ff66"],
  5400: ["#bb0077", "#77ee88", "#ee3344", "#ccaa55", "#554433"],
  5401: ["#bb00bb", "#ff6633", "#ee44aa", "#110011", "#990066"],
  5402: ["#aaee00", "#ff66bb", "#bbcc88", "#55aacc", "#44bb99"],
  5403: ["#aaff33", "#cc55dd", "#dd1100", "#2222bb", "#ddff77"],
  5404: ["#4422bb", "#33ff88", "#556633", "#880000", "#99ffee"],
  5405: ["#33eedd", "#bb33bb", "#77cc00", "#442266", "#66aa55"],
  5406: ["#116644", "#11bb77", "#3399bb", "#221199", "#44ff44"],
  5407: ["#aacccc", "#991166", "#9933dd", "#aa77ff", "#66bbcc"],
  5408: ["#226677", "#bb8800", "#ddbb00", "#ffbbcc", "#eebb55"],
  5409: ["#dd1166", "#554422", "#111133", "#bb8811", "#00ee88"],
  5410: ["#00ccbb", "#ee9988", "#aa1111", "#ff9944", "#aa99ee"],
  5411: ["#7766ff", "#445511", "#33aa88", "#44ddbb", "#ffff00"],
  5412: ["#aa00ee", "#77bbcc", "#999944", "#eecc88", "#33bbff"],
  5413: ["#ccddff", "#663388", "#dd66ee", "#6622ff", "#ee7744"],
  5414: ["#cc0055", "#33bb33", "#663322", "#8844ee", "#007755"],
  5415: ["#22cc11", "#cc5500", "#22ff33", "#993333", "#2255ee"],
  5416: ["#ddaa88", "#440077", "#0055bb", "#3399ee", "#dd00dd"],
  5417: ["#bbbb55", "#dd3300", "#dd88dd", "#aa0055", "#ffbb33"],
  5418: ["#227711", "#4400aa", "#33eebb", "#44ff88", "#77ccee"],
  5419: ["#886611", "#bb0022", "#1177aa", "#8833ff", "#99dd99"],
  5420: ["#2255cc", "#ddbbbb", "#aa3344", "#ddaa11", "#ff8822"],
  5421: ["#334477", "#aa3300", "#33ffee", "#ffbb11", "#cc8888"],
  5422: ["#227744", "#228899", "#bb00aa", "#cc11bb", "#dd00aa"],
  5423: ["#774466", "#1177cc", "#ee99ee", "#22aa33", "#88ff55"],
  5424: ["#88bb22", "#aaee22", "#aadd99", "#ee2222", "#440011"],
  5425: ["#9944dd", "#118866", "#773388", "#991155", "#556677"],
  5426: ["#555588", "#4455dd", "#1155bb", "#77ee55", "#00bbdd"],
  5427: ["#77dd00", "#330000", "#44ff11", "#331155", "#99aaff"],
  5428: ["#bb66aa", "#ee3344", "#1111bb", "#bbffdd", "#ccdd55"],
  5429: ["#668899", "#00aa88", "#bb11aa", "#00ddff", "#886677"],
  5430: ["#77bbaa", "#bb99aa", "#0022dd", "#eebb66", "#22bb88"],
  5431: ["#221122", "#0066aa", "#99aa66", "#bbff55", "#11eeff"],
  5432: ["#ee4488", "#aabbbb", "#00cc55", "#4499dd", "#55bbbb"],
  5433: ["#339911", "#aacc44", "#994477", "#99ddbb", "#aacc77"],
  5434: ["#aaee00", "#33dd77", "#ddff00", "#ccaabb", "#bbaabb"],
  5435: ["#557788", "#bb5511", "#cc33bb", "#119955", "#bb0000"],
  5436: ["#ee77cc", "#66ee44", "#008844", "#bbaa99", "#558800"],
  5437: ["#cc6611", "#99bb88", "#44aacc", "#aaaa11", "#005588"],
  5438: ["#ccee55", "#22cc66", "#aa7722", "#1188ee", "#9900ee"],
  5439: ["#888833", "#33ff88", "#004466", "#ff8833", "#dd8844"],
  5440: ["#22cc77", "#99ccaa", "#3377cc", "#99ddaa", "#3322ff"],
  5441: ["#ee3344", "#dd4433", "#99bb11", "#2211dd", "#33ee55"],
  5442: ["#338888", "#ffaa55", "#bbaa66", "#ddcc66", "#1188bb"],
  5443: ["#cc6677", "#bbff22", "#cc77ff", "#002211", "#880022"],
  5444: ["#998822", "#ffcc99", "#aa00cc", "#664411", "#3344aa"],
  5445: ["#ee33dd", "#ccddff", "#33ff44", "#ee22ff", "#22cc66"],
  5446: ["#553300", "#22ff33", "#22dd22", "#443344", "#113344"],
  5447: ["#99ddbb", "#ee6633", "#229933", "#bbee00", "#55dd77"],
  5448: ["#5588aa", "#8800ff", "#aa55cc", "#666666", "#443388"],
  5449: ["#dd1122", "#eebbee", "#ff22dd", "#44dd00", "#ff22ee"],
  5450: ["#55ccaa", "#559900", "#cccc33", "#cc1144", "#002222"],
  5451: ["#dd9944", "#dd4466", "#330055", "#dd99ee", "#ccee00"],
  5452: ["#7711aa", "#77ffdd", "#003366", "#cc9977", "#66bbcc"],
  5453: ["#ff3388", "#885511", "#3300aa", "#aa7722", "#55aaaa"],
  5454: ["#6622cc", "#33aadd", "#1122dd", "#55ff77", "#ee3311"],
  5455: ["#ee9900", "#770044", "#bbff88", "#993344", "#dd11ff"],
  5456: ["#7744ee", "#9977bb", "#332200", "#1188ff", "#11dd55"],
  5457: ["#bb2266", "#22ff22", "#66bbff", "#88cc88", "#bb0022"],
  5458: ["#aaccbb", "#777700", "#009966", "#55ee00", "#5555cc"],
  5459: ["#aa6633", "#ffcc77", "#bb77cc", "#99ee00", "#443311"],
  5460: ["#66dd44", "#55ffff", "#cc9911", "#ee1155", "#eedd99"],
  5461: ["#ffcc00", "#116611", "#228855", "#6611dd", "#886611"],
  5462: ["#bb7788", "#55ff77", "#ff2233", "#bb0044", "#113322"],
  5463: ["#443333", "#bbdd88", "#661111", "#4444cc", "#cc3377"],
  5464: ["#ee3355", "#ff0044", "#33cc22", "#1111cc", "#446633"],
  5465: ["#99bb22", "#447755", "#884422", "#009977", "#6688dd"],
  5466: ["#eeddbb", "#117722", "#bb4444", "#33bb55", "#1100ff"],
  5467: ["#dd66cc", "#00aaaa", "#bb9933", "#cc4433", "#1133bb"],
  5468: ["#669955", "#ffbbcc", "#bb9988", "#55cc22", "#55ee33"],
  5469: ["#5599ff", "#ccaa33", "#330099", "#994488", "#bb6688"],
  5470: ["#ff0099", "#884400", "#dd0077", "#662255", "#66eeee"],
  5471: ["#ff44ff", "#99dd22", "#668822", "#447744", "#55cccc"],
  5472: ["#aaff11", "#ddaa66", "#993311", "#334422", "#00aa11"],
  5473: ["#4455dd", "#eedd55", "#77aa66", "#ff77ff", "#ddeeaa"],
  5474: ["#5555aa", "#55bbff", "#bb22ee", "#bbff77", "#11ccff"],
  5475: ["#88ff77", "#882222", "#6699ff", "#dddd66", "#dd4477"],
  5476: ["#663366", "#660077", "#ccff44", "#9900cc", "#6655cc"],
  5477: ["#0066cc", "#11bb55", "#ff5500", "#66cc99", "#22aa22"],
  5478: ["#eeddbb", "#cc66cc", "#660099", "#ff00dd", "#99ff88"],
  5479: ["#3333cc", "#55aa77", "#9999cc", "#77ee22", "#ddff44"],
  5480: ["#dd3300", "#ffaa99", "#0066ee", "#cc0055", "#bb2233"],
  5481: ["#660077", "#88aa11", "#11eedd", "#00cc44", "#4444aa"],
  5482: ["#aa3388", "#440000", "#118899", "#ff55ee", "#ccffcc"],
  5483: ["#cc9988", "#00cc88", "#112233", "#0022bb", "#aaaa44"],
  5484: ["#55ffdd", "#66bbdd", "#ee00ee", "#772244", "#00aaff"],
  5485: ["#338800", "#2299dd", "#dd6666", "#33ee44", "#ddbb22"],
  5486: ["#9900bb", "#333377", "#6644dd", "#ffee00", "#ccaa66"],
  5487: ["#ffee11", "#998811", "#bb2233", "#aaee44", "#88ccaa"],
  5488: ["#22aa11", "#22aaff", "#aa9977", "#778855", "#33ccdd"],
  5489: ["#22eeaa", "#558866", "#eeddee", "#ff77ee", "#ff2288"],
  5490: ["#bb3311", "#990077", "#dd8833", "#88dd66", "#5511ff"],
  5491: ["#887755", "#11ff77", "#aa9977", "#bb00cc", "#ff1199"],
  5492: ["#aaff44", "#996699", "#bb22cc", "#aa6677", "#00eeee"],
  5493: ["#11ee11", "#552266", "#2211ff", "#bb6633", "#111100"],
  5494: ["#ff66bb", "#113399", "#cc5566", "#2277aa", "#6611ee"],
  5495: ["#992211", "#996688", "#bb8844", "#88ddaa", "#0066ee"],
  5496: ["#ddaacc", "#aabb00", "#33cc99", "#bbbb44", "#ccaaee"],
  5497: ["#111133", "#ffeecc", "#ccee99", "#005522", "#7722cc"],
  5498: ["#dd55dd", "#ff7711", "#aa7733", "#44aa66", "#eeee66"],
  5499: ["#bb44bb", "#33bb22", "#bb11cc", "#ee9988", "#ff00dd"],
  5500: ["#cc8822", "#bbdd11", "#446633", "#88aa11", "#bbeebb"],
  5501: ["#33cc66", "#55dd99", "#dddd44", "#dd99cc", "#66ffff"],
  5502: ["#006633", "#dd6677", "#99bb33", "#99ee55", "#ff7755"],
  5503: ["#ffddee", "#44ee33", "#ff66bb", "#aa3377", "#66ddbb"],
  5504: ["#330011", "#dd4477", "#ffee44", "#006699", "#3311bb"],
  5505: ["#66ddcc", "#7777cc", "#332244", "#110044", "#bb77cc"],
  5506: ["#778811", "#aa00ee", "#aa33aa", "#110000", "#cc44ee"],
  5507: ["#550066", "#7766cc", "#ee0077", "#ff00dd", "#331199"],
  5508: ["#88ddbb", "#887799", "#aaff11", "#88ee99", "#22cc11"],
  5509: ["#335566", "#33ff00", "#22ee99", "#1100ee", "#00dd99"],
  5510: ["#11aa11", "#223355", "#33aa55", "#771155", "#227744"],
  5511: ["#eedd11", "#7744cc", "#dd1166", "#ee7755", "#77ddaa"],
  5512: ["#444433", "#3399aa", "#55ee22", "#bbbb66", "#888833"],
  5513: ["#ee7788", "#bb2277", "#11ff88", "#119988", "#cccc88"],
  5514: ["#bb7799", "#aa11dd", "#1188ee", "#bbcc11", "#ffff11"],
  5515: ["#111177", "#dd11ff", "#225588", "#cc8811", "#334488"],
  5516: ["#552200", "#ddffee", "#33aa22", "#ccdd22", "#551144"],
  5517: ["#3366dd", "#ee5500", "#3311bb", "#00ee77", "#aa5555"],
  5518: ["#1144aa", "#2200aa", "#ee77ee", "#88ff88", "#eeaaee"],
  5519: ["#11eeaa", "#cc66aa", "#aaaabb", "#11bbaa", "#cc4455"],
  5520: ["#664433", "#2233bb", "#3388dd", "#aa9966", "#77ff99"],
  5521: ["#99dd77", "#eeff44", "#cc88dd", "#ee3355", "#bbbbbb"],
  5522: ["#aa5588", "#ee5511", "#aa66bb", "#996688", "#ee2222"],
  5523: ["#ddffff", "#77aa22", "#bb66aa", "#666688", "#ee44ee"],
  5524: ["#1166bb", "#006644", "#776655", "#ddffdd", "#bb77ff"],
  5525: ["#443366", "#7700aa", "#88aa66", "#553322", "#7722ee"],
  5526: ["#aaff44", "#663399", "#00bb77", "#cc11bb", "#443344"],
  5527: ["#77aa55", "#6655ee", "#88eeee", "#ffee88", "#bbbbee"],
  5528: ["#cc44ff", "#556699", "#44aa88", "#9955ff", "#aa0099"],
  5529: ["#bb7777", "#11ee00", "#44ffee", "#330000", "#dd0077"],
  5530: ["#eeccaa", "#33aaee", "#885522", "#aa0011", "#dd33bb"],
  5531: ["#00dd22", "#446633", "#1100cc", "#aaffff", "#007700"],
  5532: ["#66aa22", "#003399", "#ff2288", "#ff77ee", "#9911ff"],
  5533: ["#1199aa", "#777755", "#337700", "#332277", "#993399"],
  5534: ["#995599", "#228877", "#cc9911", "#887722", "#007700"],
  5535: ["#ff2233", "#00aa99", "#ee6622", "#224499", "#77cc33"],
  5536: ["#22aadd", "#1166ee", "#aacc77", "#8811ee", "#00ddbb"],
  5537: ["#bb9900", "#ee4477", "#4488cc", "#ffbbcc", "#ddeebb"],
  5538: ["#0011aa", "#553333", "#aacc66", "#66ff88", "#eeee00"],
  5539: ["#dd44aa", "#335544", "#993311", "#556699", "#554422"],
  5540: ["#88dd66", "#7711bb", "#77dd77", "#bbff33", "#cc4466"],
  5541: ["#cc3300", "#667799", "#55ddbb", "#bb66ff", "#bbbbcc"],
  5542: ["#66ff00", "#110011", "#771166", "#22dd99", "#4433aa"],
  5543: ["#1199cc", "#003366", "#0055dd", "#aa6655", "#442244"],
  5544: ["#44ee33", "#995522", "#113377", "#5500cc", "#771144"],
  5545: ["#004444", "#667777", "#330099", "#448833", "#55cc00"],
  5546: ["#cc2299", "#992233", "#884499", "#3388aa", "#5522aa"],
  5547: ["#00ccff", "#558877", "#55aa00", "#110099", "#1100bb"],
  5548: ["#003388", "#0022aa", "#aa1155", "#bb22ee", "#9911dd"],
  5549: ["#33ee00", "#aaddaa", "#bb4422", "#776600", "#00ee99"],
  5550: ["#cc0088", "#224411", "#994433", "#cc0022", "#66ff66"],
  5551: ["#552255", "#ccccdd", "#aacc77", "#110033", "#77ffbb"],
  5552: ["#996655", "#eecc22", "#557777", "#887799", "#55cccc"],
  5553: ["#224488", "#ffddff", "#3322ee", "#44cc22", "#bb33ff"],
  5554: ["#445599", "#666655", "#22ee99", "#ee3322", "#aaddaa"],
  5555: ["#aa2299", "#aaee99", "#446666", "#ff4422", "#cc4488"],
  5556: ["#ee22aa", "#dd1122", "#000000", "#ddcc99", "#1155ff"],
  5557: ["#55cc88", "#22ee33", "#888888", "#88dd88", "#ee3377"],
  5558: ["#3344ee", "#eebbee", "#22ee88", "#ff3377", "#bbaa77"],
  5559: ["#55ff77", "#ff0011", "#dd5533", "#3388ee", "#223355"],
  5560: ["#667788", "#dd3311", "#556699", "#449966", "#660022"],
  5561: ["#ff5522", "#aa2200", "#aaff11", "#66ff00", "#338855"],
  5562: ["#554422", "#669955", "#66ffaa", "#dd99ff", "#99aa11"],
  5563: ["#332288", "#ffff88", "#77ff99", "#554499", "#cc66ee"],
  5564: ["#ff8888", "#66bb33", "#2288cc", "#332211", "#5533ff"],
  5565: ["#113322", "#cc9988", "#11aa55", "#446699", "#ee22dd"],
  5566: ["#bbbbee", "#dd44ee", "#996666", "#aa7722", "#555533"],
  5567: ["#66ccff", "#55ccee", "#88ee33", "#5577aa", "#889988"],
  5568: ["#11aabb", "#bbcc11", "#99dd66", "#6611cc", "#ee44aa"],
  5569: ["#aa0022", "#55dd55", "#cc66ff", "#0066ff", "#229900"],
  5570: ["#88ddcc", "#cc9966", "#99bbee", "#008833", "#ee88bb"],
  5571: ["#11ffaa", "#eeaa88", "#ccaa77", "#2200ff", "#ddcc55"],
  5572: ["#11bb99", "#dd9944", "#44dd00", "#dddd22", "#99ff44"],
  5573: ["#ff66ee", "#ddddcc", "#aaddee", "#990044", "#cc22ff"],
  5574: ["#aa3300", "#2288dd", "#ff88aa", "#332200", "#ccff66"],
  5575: ["#22ff88", "#998888", "#ee0099", "#aa66aa", "#8877ee"],
  5576: ["#667799", "#bbeeee", "#228866", "#221133", "#999977"],
  5577: ["#aa77cc", "#aa2244", "#ccbb77", "#dd4488", "#bb7744"],
  5578: ["#dd3366", "#3366ee", "#bb44aa", "#dd4400", "#22dd77"],
  5579: ["#887722", "#334499", "#4422dd", "#ee2211", "#cc8811"],
  5580: ["#5533dd", "#bbbb22", "#dd4433", "#116633", "#aa77ee"],
  5581: ["#cc44dd", "#ff8877", "#ff44dd", "#00bb00", "#ee00ff"],
  5582: ["#222277", "#77bb77", "#664422", "#66dd11", "#dd8800"],
  5583: ["#ffaa55", "#cc88bb", "#00ff11", "#443333", "#669955"],
  5584: ["#aaaa22", "#aacc22", "#77aadd", "#113377", "#bb66dd"],
  5585: ["#33cc88", "#660033", "#0055ff", "#ff44ee", "#55ff11"],
  5586: ["#ffddaa", "#7788ee", "#00ff77", "#115555", "#9966ee"],
  5587: ["#ee9955", "#550055", "#dd8855", "#22bb55", "#886688"],
  5588: ["#ff22ee", "#aaddee", "#990044", "#88aaff", "#bbbb55"],
  5589: ["#00cc77", "#117722", "#11ff22", "#8855aa", "#778833"],
  5590: ["#aadd00", "#1166dd", "#338811", "#22bb11", "#99eeaa"],
  5591: ["#aa11cc", "#aa99dd", "#666633", "#ddff44", "#551166"],
  5592: ["#222277", "#996622", "#ddeeee", "#aacc00", "#00aadd"],
  5593: ["#88ee11", "#9988bb", "#11aa77", "#66aa00", "#7799bb"],
  5594: ["#442211", "#112200", "#334466", "#eeffbb", "#dd2288"],
  5595: ["#663399", "#9911cc", "#dd7755", "#3300dd", "#cc66bb"],
  5596: ["#444455", "#cc4422", "#33ff88", "#9944bb", "#55cc44"],
  5597: ["#ccff44", "#552211", "#7744ee", "#dd4488", "#7788cc"],
  5598: ["#8877aa", "#11aaee", "#9955dd", "#0066dd", "#005577"],
  5599: ["#ffccee", "#9999dd", "#99dd44", "#ee2244", "#44ffdd"],
  5600: ["#0099ff", "#aaffff", "#ccaa66", "#cccc99", "#33aacc"],
  5601: ["#552244", "#2244bb", "#448899", "#aaaa22", "#ffee11"],
  5602: ["#aacc22", "#ddaaee", "#22ff88", "#8855cc", "#00bb88"],
  5603: ["#0099cc", "#ffdd33", "#4499dd", "#dd5599", "#ccdd00"],
  5604: ["#884422", "#ffcc11", "#aaaadd", "#22ddff", "#88dd00"],
  5605: ["#aa22dd", "#55ddee", "#55dddd", "#558833", "#ccffaa"],
  5606: ["#aabb88", "#66aa33", "#bb33cc", "#444422", "#88cc11"],
  5607: ["#997722", "#998866", "#33ff22", "#77eeee", "#00ff44"],
  5608: ["#aaff55", "#cc4488", "#bb77cc", "#dd44dd", "#997777"],
  5609: ["#11bb99", "#887799", "#2244cc", "#2277cc", "#44eeff"],
  5610: ["#eeee00", "#ee77ee", "#559955", "#551188", "#001133"],
  5611: ["#66cc22", "#004488", "#aa22ee", "#88bb00", "#ffee00"],
  5612: ["#9911ff", "#6644dd", "#cc0044", "#118822", "#888800"],
  5613: ["#88ee44", "#dd6633", "#ff5555", "#220077", "#cc4400"],
  5614: ["#ccbbff", "#00ddff", "#111111", "#ff3300", "#cc9966"],
  5615: ["#bbccee", "#1155ee", "#bbee66", "#ff7744", "#117711"],
  5616: ["#99ff44", "#ff4400", "#883344", "#2233aa", "#44ddbb"],
  5617: ["#778833", "#7733ee", "#ee7766", "#cc8866", "#8822aa"],
  5618: ["#aa9977", "#888855", "#bbccdd", "#cc1177", "#0077aa"],
  5619: ["#88ff66", "#aacc11", "#ddeedd", "#55bbbb", "#aa33ee"],
  5620: ["#dd3399", "#ff77dd", "#eecc11", "#dd33bb", "#556600"],
  5621: ["#0000dd", "#bbff77", "#0077aa", "#aa6633", "#009999"],
  5622: ["#aa00aa", "#3399cc", "#aaddee", "#006611", "#cc1199"],
  5623: ["#eeeeff", "#66bb88", "#eedd55", "#bb00dd", "#113355"],
  5624: ["#ffbb99", "#008833", "#88ffdd", "#338866", "#33eeff"],
  5625: ["#aabbdd", "#3322bb", "#ff7755", "#445533", "#770011"],
  5626: ["#aa00bb", "#33dd44", "#662211", "#77cc66", "#bbaaff"],
  5627: ["#550055", "#ddaa66", "#33dd22", "#6666ff", "#883366"],
  5628: ["#eeee55", "#665555", "#bb00cc", "#ff11cc", "#66ee88"],
  5629: ["#ddddcc", "#116611", "#bb1155", "#666699", "#cc0077"],
  5630: ["#00dd88", "#00ff88", "#ff1177", "#335577", "#bb6600"],
  5631: ["#331100", "#ee1155", "#44cc00", "#888833", "#cc8833"],
  5632: ["#8822ee", "#1177ee", "#ccaadd", "#cc66ee", "#ff7766"],
  5633: ["#ddee33", "#449977", "#ff77cc", "#774433", "#558822"],
  5634: ["#88dd66", "#11ddee", "#1144ee", "#3388cc", "#ffccbb"],
  5635: ["#334400", "#dd6633", "#ee8833", "#88ee55", "#bb3377"],
  5636: ["#1188aa", "#114400", "#5555bb", "#77cc66", "#bb77bb"],
  5637: ["#bbdd77", "#773355", "#ffeecc", "#bbdd44", "#44ff88"],
  5638: ["#dd7700", "#5522ff", "#115522", "#99bb44", "#00dddd"],
  5639: ["#cc0000", "#55ff33", "#55bbaa", "#ee0099", "#1155cc"],
  5640: ["#77aadd", "#884422", "#00ff22", "#ccee00", "#553377"],
  5641: ["#334455", "#cc7711", "#551177", "#999966", "#dd2233"],
  5642: ["#1111ff", "#1111cc", "#66ddee", "#337777", "#8822aa"],
  5643: ["#33ddaa", "#9944cc", "#ee1166", "#cc3333", "#aa11aa"],
  5644: ["#009933", "#ccbb33", "#ddff55", "#9911cc", "#119988"],
  5645: ["#3322ee", "#99eecc", "#224411", "#ee5566", "#1111ff"],
  5646: ["#9922dd", "#aa33dd", "#88ee66", "#ddeeaa", "#330099"],
  5647: ["#dd5555", "#66ee00", "#772244", "#ff66bb", "#0011cc"],
  5648: ["#aa3355", "#6688ee", "#5533aa", "#11eeaa", "#cc33dd"],
  5649: ["#eebb99", "#559911", "#cc9933", "#dd2255", "#66ee55"],
  5650: ["#ffaa22", "#8844bb", "#22dd44", "#33aa55", "#cc0066"],
  5651: ["#bb88aa", "#bbee99", "#cc4400", "#7799bb", "#005522"],
  5652: ["#7722aa", "#0011aa", "#dd4477", "#11ff44", "#998800"],
  5653: ["#ff11bb", "#223300", "#665500", "#995511", "#996600"],
  5654: ["#33aa77", "#997777", "#997799", "#bb99dd", "#993366"],
  5655: ["#44ee66", "#337799", "#11bbff", "#33ff22", "#11ff33"],
  5656: ["#7722dd", "#ff1188", "#bbdd77", "#bbaa55", "#331111"],
  5657: ["#6611bb", "#229977", "#66ee88", "#001199", "#22ccaa"],
  5658: ["#550055", "#ee8844", "#bb7711", "#443355", "#113311"],
  5659: ["#009900", "#11eeee", "#004400", "#995522", "#55ccaa"],
  5660: ["#dd3377", "#ff7766", "#ff9922", "#55ccdd", "#005588"],
  5661: ["#ff8800", "#ffeeff", "#776699", "#448877", "#22aadd"],
  5662: ["#002299", "#bbaa44", "#bbee99", "#ee8877", "#8888bb"],
  5663: ["#44eeff", "#bb00bb", "#006611", "#bbee11", "#11bbcc"],
  5664: ["#8855dd", "#dd00dd", "#88aa33", "#775555", "#551133"],
  5665: ["#994433", "#bb44dd", "#77ffbb", "#221177", "#ddbbee"],
  5666: ["#00cccc", "#11ff44", "#335522", "#22eedd", "#eeaaee"],
  5667: ["#bbff33", "#990099", "#bbbb99", "#44ee99", "#ff4488"],
  5668: ["#9922bb", "#bbaa22", "#2200dd", "#ff4499", "#ccbbaa"],
  5669: ["#9900aa", "#66ddbb", "#bbbb66", "#eeaaee", "#0088dd"],
  5670: ["#8844bb", "#ee33ee", "#ccee44", "#772277", "#bb4444"],
  5671: ["#aa2266", "#8833bb", "#aa0099", "#ff00ee", "#22bb99"],
  5672: ["#444433", "#774444", "#ff77ee", "#117722", "#cc9966"],
  5673: ["#ccff66", "#887722", "#aa3344", "#1166bb", "#006611"],
  5674: ["#aa7700", "#bbbb66", "#ffaa00", "#ffffaa", "#772200"],
  5675: ["#ccffff", "#44aa99", "#88ff33", "#bbdd00", "#88ee11"],
  5676: ["#662200", "#99ee88", "#dd2288", "#33ff33", "#6688bb"],
  5677: ["#441122", "#442200", "#9988dd", "#ff6611", "#442200"],
  5678: ["#33ffff", "#bb8811", "#88aa66", "#dd66aa", "#55aaff"],
  5679: ["#ddff66", "#779955", "#ee9911", "#882222", "#77dd55"],
  5680: ["#5566bb", "#dd5599", "#ff7788", "#4477ff", "#ddbb22"],
  5681: ["#99ffbb", "#bb2200", "#bb22dd", "#aa0077", "#bb5544"],
  5682: ["#aa44bb", "#00aa22", "#dd6633", "#110088", "#884444"],
  5683: ["#9922bb", "#dd3311", "#77ee99", "#bb88dd", "#55ccff"],
  5684: ["#22dd99", "#88cc88", "#ccaa44", "#aa0077", "#66eeee"],
  5685: ["#eedd00", "#ee88ff", "#0000bb", "#cc4433", "#ff4411"],
  5686: ["#ffaa11", "#55cc11", "#113366", "#aabb00", "#998800"],
  5687: ["#885566", "#aadd77", "#0066dd", "#11cc66", "#ccbb88"],
  5688: ["#228833", "#55dd55", "#bbaacc", "#66dddd", "#cc44bb"],
  5689: ["#0011cc", "#ee11bb", "#bb2299", "#55ff77", "#99ddcc"],
  5690: ["#3355bb", "#337700", "#7766ff", "#332233", "#8822cc"],
  5691: ["#770055", "#77aaff", "#33aa33", "#11ccdd", "#44ff22"],
  5692: ["#4477ff", "#cc6655", "#885511", "#8844cc", "#668800"],
  5693: ["#eeff33", "#22ffdd", "#557755", "#cceebb", "#446622"],
  5694: ["#ee8866", "#331111", "#eeaa88", "#aa44ee", "#556688"],
  5695: ["#330055", "#bbff66", "#449966", "#aaaa00", "#aaaa33"],
  5696: ["#ff88ee", "#aaee99", "#995577", "#aa22aa", "#550044"],
  5697: ["#558888", "#dd66bb", "#3366cc", "#aaff33", "#cc55aa"],
  5698: ["#bb3300", "#6655dd", "#eeaacc", "#66ddaa", "#ff66bb"],
  5699: ["#225577", "#5544dd", "#dd33ff", "#44aa22", "#22ff66"],
  5700: ["#33aa11", "#119977", "#bbcc66", "#99dd55", "#dddd33"],
  5701: ["#ffdd88", "#ccddaa", "#cccc44", "#884444", "#ddeecc"],
  5702: ["#9955bb", "#554422", "#ee5577", "#eebbbb", "#aadd00"],
  5703: ["#4488ff", "#991177", "#005566", "#55bb55", "#ddaa44"],
  5704: ["#bbbb77", "#5544bb", "#8833cc", "#ddbb00", "#330077"],
  5705: ["#885566", "#001122", "#4411dd", "#5511bb", "#88ddaa"],
  5706: ["#ee2222", "#66ff44", "#558800", "#bb2211", "#bbee66"],
  5707: ["#00ffcc", "#bb44bb", "#ee11bb", "#772299", "#115544"],
  5708: ["#227799", "#cc66ee", "#66ee55", "#0022aa", "#dd0055"],
  5709: ["#991122", "#446699", "#ffee22", "#11bbaa", "#1177aa"],
  5710: ["#77aaff", "#dd1144", "#ff7788", "#884477", "#11ee33"],
  5711: ["#667755", "#66ffaa", "#994477", "#2233bb", "#6666bb"],
  5712: ["#22ccaa", "#dd1144", "#11dd44", "#ee8822", "#1177bb"],
  5713: ["#ccddee", "#aa2211", "#775533", "#0022cc", "#99dd88"],
  5714: ["#11ff66", "#cc33dd", "#bb7799", "#22ccee", "#8811bb"],
  5715: ["#227755", "#ddaa22", "#1155ee", "#1144ff", "#338822"],
  5716: ["#ddffcc", "#1122aa", "#5544bb", "#333344", "#bb88cc"],
  5717: ["#aa6699", "#bb00aa", "#bb5500", "#115533", "#882277"],
  5718: ["#ffdd66", "#999988", "#cceeff", "#44aa55", "#ffccbb"],
  5719: ["#eeaa66", "#ff33aa", "#77ee33", "#116633", "#8844dd"],
  5720: ["#55ddee", "#33ff44", "#338877", "#9966ff", "#1111ee"],
  5721: ["#dd5588", "#dd44aa", "#8855dd", "#cceedd", "#334466"],
  5722: ["#113377", "#bbddaa", "#333322", "#bb99bb", "#2255cc"],
  5723: ["#55aaff", "#eecc33", "#aaaabb", "#880044", "#3344ee"],
  5724: ["#44ccaa", "#cc3388", "#337777", "#668855", "#6633aa"],
  5725: ["#bbeedd", "#00ee55", "#aaee44", "#bb8811", "#559944"],
  5726: ["#3366ff", "#ffccbb", "#555511", "#880077", "#4477ff"],
  5727: ["#33aadd", "#1122ff", "#889933", "#33ee55", "#ee11ff"],
  5728: ["#005511", "#33bbff", "#55aa55", "#11aabb", "#667722"],
  5729: ["#aaff66", "#bb9999", "#119911", "#113355", "#005533"],
  5730: ["#2266dd", "#bb66ff", "#99ff77", "#9900cc", "#4411cc"],
  5731: ["#bb00ff", "#77ff22", "#99aa77", "#77ee99", "#11aaff"],
  5732: ["#aabb44", "#112222", "#dd2288", "#11cc88", "#00eedd"],
  5733: ["#00bb88", "#dd5599", "#eebbaa", "#77ddee", "#55aaaa"],
  5734: ["#881133", "#225544", "#22ffee", "#22eebb", "#ee7733"],
  5735: ["#aa3355", "#11aa00", "#ee3333", "#883322", "#555500"],
  5736: ["#2277ee", "#1122bb", "#33ff66", "#11aaaa", "#77ffaa"],
  5737: ["#663366", "#008888", "#9944bb", "#2277cc", "#bbffcc"],
  5738: ["#775511", "#220099", "#bb44dd", "#884411", "#88ddbb"],
  5739: ["#ff3322", "#11aaff", "#00ff33", "#eecc33", "#117755"],
  5740: ["#ffff55", "#dd66bb", "#ccbb22", "#7788ee", "#888800"],
  5741: ["#001111", "#9944ff", "#335544", "#ccaacc", "#000000"],
  5742: ["#11eeee", "#55bbee", "#aaff55", "#cc6633", "#aa9966"],
  5743: ["#3366dd", "#775533", "#bbcc22", "#114466", "#669977"],
  5744: ["#bbbb55", "#22cc88", "#ee7777", "#44bbcc", "#ff0088"],
  5745: ["#dd3333", "#55bb33", "#55aaff", "#aaffdd", "#118877"],
  5746: ["#884400", "#0077ee", "#999933", "#3333bb", "#ffaaaa"],
  5747: ["#99eebb", "#dd9922", "#66ee88", "#cc7733", "#4444ff"],
  5748: ["#338855", "#ffeecc", "#22ee77", "#ff9933", "#0077dd"],
  5749: ["#00ffbb", "#dd6611", "#ee00aa", "#eeaa33", "#77ff11"],
  5750: ["#eeee44", "#11dd33", "#dd77dd", "#ff6677", "#ff66ee"],
  5751: ["#dd7766", "#7744aa", "#bb00ee", "#443333", "#3300bb"],
  5752: ["#aa99dd", "#ffee00", "#008855", "#336633", "#66bb55"],
  5753: ["#995555", "#ff11bb", "#44dd00", "#ee88bb", "#002211"],
  5754: ["#bb0011", "#dd5522", "#cc66cc", "#cc55aa", "#44aacc"],
  5755: ["#555599", "#bb7711", "#88ffdd", "#115522", "#33eebb"],
  5756: ["#7788ff", "#ffffcc", "#1199ff", "#ee3366", "#995577"],
  5757: ["#661144", "#333366", "#220088", "#ccbb66", "#8833ff"],
  5758: ["#333366", "#995511", "#884499", "#7777aa", "#335577"],
  5759: ["#225500", "#33bbaa", "#11cc11", "#bb99aa", "#22ffdd"],
  5760: ["#bbdd00", "#115500", "#008833", "#cc3399", "#4455ff"],
  5761: ["#66ccee", "#ee11ff", "#6644bb", "#4400ee", "#dd88ee"],
  5762: ["#eeff66", "#5522ff", "#0022aa", "#cc88dd", "#99ffcc"],
  5763: ["#228811", "#0011aa", "#009966", "#6611ee", "#cc66bb"],
  5764: ["#77aabb", "#0099ee", "#7700cc", "#aabbaa", "#99ee22"],
  5765: ["#88aabb", "#55dd88", "#cc33ee", "#77aa88", "#113300"],
  5766: ["#886611", "#774411", "#dd66ff", "#2211aa", "#aa0000"],
  5767: ["#cc5555", "#7799ee", "#66aa00", "#8844aa", "#aa22aa"],
  5768: ["#44aa33", "#00ffbb", "#773311", "#cc7799", "#22ffee"],
  5769: ["#226622", "#55ee44", "#009944", "#335588", "#cc3399"],
  5770: ["#ee66dd", "#1144ee", "#33bbdd", "#4455aa", "#9966dd"],
  5771: ["#cc55bb", "#5511bb", "#8822cc", "#ff55dd", "#ccaa22"],
  5772: ["#3377ff", "#880011", "#9955cc", "#882244", "#996666"],
  5773: ["#6688ff", "#770044", "#66cc66", "#ee44cc", "#cc11ff"],
  5774: ["#dddd77", "#bbff55", "#11bbcc", "#ffaa88", "#ddcc77"],
  5775: ["#ee9999", "#4444cc", "#0033bb", "#bbeebb", "#2244aa"],
  5776: ["#77bbbb", "#cceebb", "#114411", "#6666aa", "#bb0088"],
  5777: ["#ddcc55", "#eeee44", "#bb2266", "#11ee77", "#33ddaa"],
  5778: ["#888800", "#009933", "#1177bb", "#115544", "#ff4422"],
  5779: ["#eeff99", "#00ffff", "#22aa00", "#cccccc", "#117733"],
  5780: ["#ddaa22", "#3333bb", "#bbdd33", "#cc0099", "#11bbdd"],
  5781: ["#eedd44", "#77ee00", "#cc55bb", "#aabbff", "#ff6677"],
  5782: ["#006655", "#cc2222", "#ff9911", "#99ff77", "#003399"],
  5783: ["#88eeaa", "#eeff99", "#dd33aa", "#cc6666", "#6655dd"],
  5784: ["#88ff22", "#66bb77", "#667700", "#4466cc", "#110055"],
  5785: ["#8899bb", "#cccc88", "#aa99bb", "#66aacc", "#331166"],
  5786: ["#aaffee", "#88dd55", "#ddbb22", "#774422", "#33ddcc"],
  5787: ["#119966", "#eedd88", "#3377cc", "#6633aa", "#bb9955"],
  5788: ["#2266bb", "#eecccc", "#bb99bb", "#444400", "#ff22aa"],
  5789: ["#441155", "#55ee77", "#ee9999", "#ffddee", "#bb6699"],
  5790: ["#8822ff", "#1122ee", "#cc99cc", "#8811ee", "#ffdd22"],
  5791: ["#ff11ee", "#44dd33", "#dd1122", "#00ffaa", "#ee3399"],
  5792: ["#33ee77", "#99ee00", "#11bb88", "#bbee44", "#11bb77"],
  5793: ["#77eedd", "#99aa88", "#bbff88", "#bb4411", "#bbaaee"],
  5794: ["#ee8877", "#115500", "#cc00aa", "#558855", "#ffdd44"],
  5795: ["#00dd33", "#002233", "#33ee33", "#9933dd", "#0011ee"],
  5796: ["#ddee99", "#ffdd55", "#ffcc99", "#7700ee", "#4433bb"],
  5797: ["#99bbcc", "#77bbff", "#44eedd", "#ddff33", "#44cc99"],
  5798: ["#cc66cc", "#55eeee", "#11ff22", "#667733", "#11cc33"],
  5799: ["#99ddbb", "#77ccee", "#bb9955", "#dd22ff", "#661155"],
  5800: ["#8888ee", "#7777ee", "#2244aa", "#88bbdd", "#447722"],
  5801: ["#11dd88", "#223377", "#66aa55", "#1166ee", "#aa2288"],
  5802: ["#003300", "#8866cc", "#441144", "#005577", "#77aa11"],
  5803: ["#99dd88", "#aa00aa", "#cc8811", "#44dd77", "#aa5555"],
  5804: ["#772266", "#0066dd", "#4499ff", "#77aaee", "#992200"],
  5805: ["#ffdd44", "#669955", "#77ffcc", "#3322ee", "#880077"],
  5806: ["#1166aa", "#aa55dd", "#22eeee", "#99ccbb", "#001199"],
  5807: ["#229999", "#77ffdd", "#339922", "#6600ff", "#9944ee"],
  5808: ["#882222", "#ffff11", "#ffaa22", "#cc5577", "#55dd22"],
  5809: ["#33dd33", "#0022cc", "#ddaa44", "#44cccc", "#77dd00"],
  5810: ["#7799ff", "#dd9900", "#99bb88", "#dd6677", "#997744"],
  5811: ["#8833ff", "#ccddcc", "#ee5511", "#dd7799", "#dddd11"],
  5812: ["#44bbdd", "#66cccc", "#aaaa00", "#77ffff", "#00ff33"],
  5813: ["#ee0099", "#220099", "#66ee99", "#6655dd", "#aa7766"],
  5814: ["#999911", "#88eecc", "#ccffcc", "#22cc11", "#00aaff"],
  5815: ["#0011ee", "#559977", "#cc88dd", "#cc1111", "#6655dd"],
  5816: ["#22ddff", "#116688", "#ff8811", "#cccc77", "#ee77aa"],
  5817: ["#bb6611", "#55aaff", "#ee5577", "#aa22ee", "#1133dd"],
  5818: ["#668866", "#667711", "#00ee99", "#1144cc", "#335588"],
  5819: ["#9933aa", "#99dd44", "#774422", "#ffff66", "#bbccaa"],
  5820: ["#889922", "#22bb66", "#3333bb", "#00cc33", "#cc00cc"],
  5821: ["#99ff00", "#77bb55", "#66ee44", "#0033dd", "#aa8833"],
  5822: ["#00eebb", "#0011ee", "#2277aa", "#8855bb", "#880055"],
  5823: ["#222222", "#ddaa22", "#00ff00", "#337711", "#2233bb"],
  5824: ["#995533", "#ddccff", "#bb77ee", "#445533", "#77ee11"],
  5825: ["#aa0077", "#99aa33", "#bb99aa", "#ee3377", "#ee7777"],
  5826: ["#aa11dd", "#aa99aa", "#cc2288", "#ee8855", "#88cc44"],
  5827: ["#9988ff", "#7744ee", "#aadd55", "#449944", "#2266dd"],
  5828: ["#77ddcc", "#445577", "#0055aa", "#4411aa", "#aacc77"],
  5829: ["#aa22ff", "#55cc88", "#eeaa44", "#994488", "#eedd22"],
  5830: ["#88eeee", "#336688", "#ffccbb", "#5566dd", "#88aa99"],
  5831: ["#2299ee", "#bb2244", "#99ddcc", "#665577", "#eecccc"],
  5832: ["#9933ff", "#44ddbb", "#66ff44", "#660077", "#662222"],
  5833: ["#992299", "#332200", "#55bbee", "#88ff44", "#cc5588"],
  5834: ["#88ff22", "#00dd22", "#1122bb", "#8822bb", "#ff77bb"],
  5835: ["#227788", "#223322", "#8844aa", "#99eebb", "#66ddcc"],
  5836: ["#335500", "#99ee66", "#aa6600", "#11cccc", "#cc6655"],
  5837: ["#221199", "#88aa44", "#bb55bb", "#dd9977", "#55bb00"],
  5838: ["#668855", "#332211", "#5566dd", "#4422aa", "#66ee88"],
  5839: ["#00cc22", "#4455aa", "#88ff11", "#ffbb55", "#220066"],
  5840: ["#776600", "#553366", "#221100", "#66aa33", "#33eeff"],
  5841: ["#aa99ff", "#33ddaa", "#888800", "#bb7744", "#228866"],
  5842: ["#005577", "#3388ff", "#110099", "#ff4433", "#77eeee"],
  5843: ["#44bb44", "#dd1155", "#55bb33", "#8899ee", "#6622ff"],
  5844: ["#ffff66", "#ee88aa", "#aa00dd", "#229900", "#66aaee"],
  5845: ["#668811", "#88ff99", "#cc00bb", "#883344", "#00aadd"],
  5846: ["#cc3355", "#66ee77", "#ccbbbb", "#997744", "#dd77bb"],
  5847: ["#bb3366", "#669922", "#551166", "#cc99ff", "#776655"],
  5848: ["#8844ee", "#775566", "#1166ff", "#ccddff", "#ffeebb"],
  5849: ["#cc8855", "#004477", "#22ff11", "#445500", "#cc5500"],
  5850: ["#220033", "#7733ee", "#442277", "#aa3355", "#33ddbb"],
  5851: ["#0022bb", "#11dd33", "#996699", "#ff2200", "#ee22cc"],
  5852: ["#ccff99", "#229922", "#448822", "#ff7755", "#66ddee"],
  5853: ["#997733", "#669999", "#11eebb", "#99bb99", "#cc4477"],
  5854: ["#77ddbb", "#7711bb", "#2222dd", "#cc7777", "#1177dd"],
  5855: ["#eeff11", "#552222", "#66bb00", "#770022", "#44bb33"],
  5856: ["#88dd99", "#aa4433", "#bb0088", "#224444", "#ddaa66"],
  5857: ["#aa00dd", "#ffeecc", "#ffee77", "#bb8822", "#66ee22"],
  5858: ["#ff5533", "#aa3344", "#114411", "#ee4455", "#bb6611"],
  5859: ["#22dd11", "#5522aa", "#553333", "#ccaa77", "#3344cc"],
  5860: ["#aa8855", "#99ddff", "#3344bb", "#bbffee", "#dd55dd"],
  5861: ["#33bb11", "#0066ee", "#cc55bb", "#aa6622", "#550077"],
  5862: ["#bb6655", "#ddbb55", "#8877cc", "#ccff44", "#990044"],
  5863: ["#dd0000", "#ee55aa", "#446644", "#44aacc", "#66bb88"],
  5864: ["#ffffbb", "#1177aa", "#111166", "#ee7711", "#994455"],
  5865: ["#7733cc", "#3322ff", "#77cccc", "#334466", "#ccbb88"],
  5866: ["#dd22ee", "#aa1199", "#994433", "#ff88aa", "#99ee44"],
  5867: ["#552277", "#0055ff", "#99cc88", "#dd8855", "#22ffaa"],
  5868: ["#889944", "#3366bb", "#009977", "#883366", "#66aacc"],
  5869: ["#0088ff", "#554477", "#cc88cc", "#116644", "#55aa88"],
  5870: ["#aaff22", "#ee2288", "#ddaa33", "#66bb11", "#4444dd"],
  5871: ["#4444cc", "#bb88aa", "#99cc66", "#112222", "#22bb22"],
  5872: ["#dd44bb", "#997766", "#dd77ff", "#bb22dd", "#bb1199"],
  5873: ["#ff6666", "#884499", "#449911", "#cc8822", "#0077bb"],
  5874: ["#55bb11", "#ffaabb", "#77dd66", "#aaeedd", "#11aa99"],
  5875: ["#1133dd", "#9911cc", "#88ddee", "#bbaa55", "#888855"],
  5876: ["#dd1177", "#cc11bb", "#997700", "#770044", "#77bbaa"],
  5877: ["#bbcc99", "#00aa99", "#991144", "#cccc55", "#00ff55"],
  5878: ["#0011ff", "#ffbb66", "#442211", "#554466", "#ff77dd"],
  5879: ["#ee2244", "#66ff55", "#661111", "#00ff77", "#5500bb"],
  5880: ["#ffbb55", "#888899", "#aa3311", "#ff9988", "#bb2255"],
  5881: ["#ccaadd", "#668866", "#11ffdd", "#662211", "#113344"],
  5882: ["#7711ff", "#66bb77", "#776622", "#8899ff", "#ffccee"],
  5883: ["#33bb00", "#00dd99", "#5588ee", "#11cc33", "#aa11dd"],
  5884: ["#bb88cc", "#331177", "#eecc33", "#007700", "#ffdd55"],
  5885: ["#77bb11", "#3311bb", "#ffdd00", "#eeeedd", "#1144ff"],
  5886: ["#884455", "#cc88bb", "#778855", "#446655", "#ddff99"],
  5887: ["#ffee88", "#dd4477", "#aa9922", "#dd44cc", "#aa6633"],
  5888: ["#666688", "#ccffee", "#996666", "#55aadd", "#555566"],
  5889: ["#775577", "#ccdddd", "#0022bb", "#aa00dd", "#006699"],
  5890: ["#ccaaee", "#7700bb", "#33aa44", "#4422bb", "#11ee22"],
  5891: ["#9911aa", "#77ff33", "#ff22ff", "#66bb66", "#dd4411"],
  5892: ["#880022", "#000033", "#4455bb", "#ff7733", "#118822"],
  5893: ["#44aa99", "#552288", "#554477", "#cc7733", "#ee2211"],
  5894: ["#444488", "#dd99ee", "#4466cc", "#dd99ff", "#77ccee"],
  5895: ["#112222", "#448822", "#aa2222", "#aaff88", "#55bb88"],
  5896: ["#8866ee", "#5555bb", "#aa4488", "#66dddd", "#884477"],
  5897: ["#001155", "#ccbb66", "#333322", "#0011aa", "#eeee22"],
  5898: ["#335500", "#998811", "#77aa00", "#003300", "#77bb44"],
  5899: ["#336666", "#112288", "#aa9955", "#eedddd", "#660099"],
  5900: ["#77aa66", "#55ff22", "#33bb99", "#33ee22", "#ff4422"],
  5901: ["#779977", "#ccaaaa", "#229966", "#773399", "#330066"],
  5902: ["#551122", "#77ccdd", "#9911ee", "#55aa33", "#44dd44"],
  5903: ["#009922", "#993388", "#77eedd", "#6622aa", "#116600"],
  5904: ["#44cc55", "#997733", "#bbaa44", "#ffdddd", "#7799ee"],
  5905: ["#11cc22", "#22ff33", "#dddd99", "#558877", "#445544"],
  5906: ["#bb1155", "#3399cc", "#002244", "#336655", "#229911"],
  5907: ["#33dd00", "#88ff33", "#11ccaa", "#1166bb", "#447788"],
  5908: ["#bb66ff", "#8888dd", "#66ddff", "#dd55ee", "#22bb88"],
  5909: ["#99ccee", "#9911ee", "#3300bb", "#eeccaa", "#7788ff"],
  5910: ["#eecc55", "#110088", "#3344cc", "#117733", "#22ee22"],
  5911: ["#cccc33", "#aa00aa", "#77ff66", "#bb00aa", "#008866"],
  5912: ["#ddccee", "#aaeeaa", "#dd2299", "#ffee22", "#556600"],
  5913: ["#ffbb66", "#776688", "#bb3399", "#55ff22", "#eeaaff"],
  5914: ["#110088", "#994455", "#aaddee", "#441144", "#226633"],
  5915: ["#440055", "#bb0066", "#33cc22", "#77aadd", "#cc77cc"],
  5916: ["#115588", "#ddaa66", "#9999ff", "#7766ee", "#dd11dd"],
  5917: ["#cc6633", "#44ffaa", "#aa8888", "#005599", "#dd7711"],
  5918: ["#dd77ee", "#5566dd", "#cccc88", "#aaaa77", "#777766"],
  5919: ["#9944cc", "#0011bb", "#cc5544", "#331177", "#cc8877"],
  5920: ["#11ddcc", "#eeccee", "#881188", "#44ff22", "#6699ee"],
  5921: ["#bb00dd", "#889966", "#4455ff", "#3344ee", "#44bbee"],
  5922: ["#bb00cc", "#33bbcc", "#bbbb44", "#22bb77", "#44aaee"],
  5923: ["#99aa66", "#7766aa", "#22aaaa", "#44ddcc", "#cc2255"],
  5924: ["#114455", "#008877", "#22cc55", "#55aa66", "#55cc44"],
  5925: ["#aa77bb", "#77dd33", "#119999", "#99ffaa", "#eecc00"],
  5926: ["#227766", "#ccdd11", "#446622", "#337744", "#550011"],
  5927: ["#ff8899", "#229922", "#aa88bb", "#227788", "#ccaa77"],
  5928: ["#ee6666", "#225533", "#337744", "#6688ee", "#55ffdd"],
  5929: ["#ddaa33", "#4455bb", "#664499", "#aa11aa", "#116666"],
  5930: ["#eeffbb", "#9911dd", "#ccee44", "#cc4411", "#88cc66"],
  5931: ["#aabbdd", "#441144", "#eeaadd", "#66aa44", "#dd8888"],
  5932: ["#aa9900", "#ff8866", "#eeeecc", "#449900", "#77ff33"],
  5933: ["#557777", "#5533bb", "#229955", "#77dd22", "#77bbff"],
  5934: ["#ddbb11", "#ff11bb", "#4455dd", "#44bbaa", "#66bbee"],
  5935: ["#5511ee", "#ff4455", "#2277ee", "#557777", "#bb1166"],
  5936: ["#cc66dd", "#bb99dd", "#006600", "#666655", "#ffddcc"],
  5937: ["#44dddd", "#113344", "#993311", "#5599dd", "#116699"],
  5938: ["#772200", "#446666", "#ccbb88", "#221144", "#221177"],
  5939: ["#ffddaa", "#7755ee", "#888877", "#221144", "#ff3355"],
  5940: ["#22aa55", "#dd44bb", "#bb11aa", "#882244", "#44ee66"],
  5941: ["#ddaaff", "#6655ee", "#22bb99", "#44cc77", "#8811bb"],
  5942: ["#44ccff", "#9944bb", "#9999dd", "#ff22ff", "#332244"],
  5943: ["#002233", "#33bbaa", "#ffbb00", "#4422ee", "#66eeaa"],
  5944: ["#ff0022", "#114466", "#661199", "#3322ff", "#44ff44"],
  5945: ["#dd8833", "#ff88ff", "#116622", "#ff3333", "#7711dd"],
  5946: ["#11ffaa", "#99bb33", "#55bb44", "#aaaa88", "#bb2299"],
  5947: ["#2255ff", "#ccee55", "#779933", "#990077", "#66dd11"],
  5948: ["#ff6688", "#eeff66", "#aa11aa", "#776644", "#ddaa33"],
  5949: ["#ff22cc", "#55ee55", "#4455aa", "#ffffcc", "#ee33ee"],
  5950: ["#ff9944", "#111111", "#ffdd99", "#ff00ff", "#aadd77"],
  5951: ["#bb77cc", "#ffdd44", "#771188", "#ee5533", "#dd9988"],
  5952: ["#992211", "#004477", "#77cc66", "#dd33cc", "#eeaabb"],
  5953: ["#ddee88", "#ffeeee", "#9955ff", "#33dddd", "#114499"],
  5954: ["#555577", "#ee22dd", "#77ff88", "#7711cc", "#220077"],
  5955: ["#559999", "#eeaabb", "#ccbb33", "#1111aa", "#554488"],
  5956: ["#99bb33", "#559955", "#7733bb", "#bb6633", "#ccbb55"],
  5957: ["#0055ff", "#5500cc", "#44eedd", "#3344cc", "#22ee44"],
  5958: ["#005533", "#eeee55", "#bb00ee", "#336611", "#11aa11"],
  5959: ["#1122bb", "#5566bb", "#448899", "#77ee99", "#dddd22"],
  5960: ["#aa4444", "#00ccee", "#669999", "#004488", "#bbcc00"],
  5961: ["#9922aa", "#558866", "#bb44aa", "#cc6666", "#665511"],
  5962: ["#994400", "#cc3377", "#ee22cc", "#4411aa", "#007766"],
  5963: ["#bb99ee", "#cc1144", "#663388", "#ee5599", "#004466"],
  5964: ["#33dd44", "#ffcc11", "#aaff66", "#cc44ff", "#cc33ee"],
  5965: ["#bb8822", "#44cc11", "#ffccbb", "#774422", "#5577ee"],
  5966: ["#776677", "#008866", "#ddcccc", "#00ee22", "#aaaa55"],
  5967: ["#ee11dd", "#ff55dd", "#999955", "#6644cc", "#99eecc"],
  5968: ["#11cc11", "#3399aa", "#44eecc", "#dd88aa", "#dd11cc"],
  5969: ["#44aa77", "#55ee22", "#88ee00", "#dd4466", "#88ee66"],
  5970: ["#ffaa44", "#22ccbb", "#885577", "#0033bb", "#11ffdd"],
  5971: ["#ee2299", "#dd5522", "#2211ee", "#22ffbb", "#448866"],
  5972: ["#33dd11", "#44aa11", "#666622", "#1199dd", "#ccddaa"],
  5973: ["#666633", "#ffccee", "#ff55cc", "#44ffbb", "#dd0022"],
  5974: ["#662277", "#ddaa44", "#333311", "#aa2277", "#226655"],
  5975: ["#777733", "#bbee66", "#aa55ee", "#ee0077", "#ff77bb"],
  5976: ["#7777ee", "#ccddaa", "#33aa33", "#0077ff", "#ff4444"],
  5977: ["#eeddcc", "#33ff00", "#55ddee", "#555511", "#ee00dd"],
  5978: ["#886600", "#1188aa", "#ee11ff", "#77ee44", "#66aa33"],
  5979: ["#cccc44", "#006677", "#000011", "#555522", "#ff5533"],
  5980: ["#992266", "#004466", "#ff55cc", "#dd77ff", "#665522"],
  5981: ["#ee22ee", "#7744dd", "#6622ee", "#003388", "#22ee44"],
  5982: ["#ff88ee", "#887722", "#225533", "#77bb99", "#55ee00"],
  5983: ["#ee4488", "#882233", "#44ff44", "#5522aa", "#22bb99"],
  5984: ["#448822", "#77ff77", "#446644", "#aa99cc", "#88ffee"],
  5985: ["#ee88bb", "#22aa11", "#8877aa", "#22aa99", "#77ff55"],
  5986: ["#11dd55", "#ee1111", "#bbdd44", "#11aa00", "#22bb88"],
  5987: ["#ee7788", "#669900", "#ffaa66", "#554433", "#ee2277"],
  5988: ["#1199bb", "#66dd66", "#66ccff", "#002244", "#7722bb"],
  5989: ["#00cc44", "#556644", "#cc00dd", "#771155", "#cc5599"],
  5990: ["#9911bb", "#000088", "#bb2233", "#444400", "#9911cc"],
  5991: ["#223311", "#449955", "#ee6666", "#ffdd77", "#444444"],
  5992: ["#ffffdd", "#99ee77", "#ccffaa", "#55aadd", "#bb9955"],
  5993: ["#3377cc", "#559955", "#559999", "#6688dd", "#44ee66"],
  5994: ["#bb66ff", "#8855bb", "#77ff11", "#0088bb", "#bbaabb"],
  5995: ["#551144", "#66dd77", "#eeaadd", "#3366ff", "#88ff66"],
  5996: ["#883311", "#66ee44", "#66cc33", "#88ff55", "#6688cc"],
  5997: ["#66ee22", "#aa1144", "#3333aa", "#55aa44", "#889999"],
  5998: ["#00dd55", "#338899", "#ccff11", "#ff4411", "#110066"],
  5999: ["#776666", "#dd6666", "#3322dd", "#33dd22", "#eecc00"],
  6000: ["#884400", "#cccc33", "#bb6611", "#aabb44", "#eeff66"],
  6001: ["#ddbbff", "#ffeecc", "#bb33aa", "#55eedd", "#bb33dd"],
  6002: ["#ff88aa", "#77bbdd", "#88aacc", "#1133bb", "#33eecc"],
  6003: ["#66aa99", "#aadd88", "#ffdd11", "#3355cc", "#ddbb44"],
  6004: ["#990088", "#cc3388", "#dd1177", "#11ff66", "#00bb44"],
  6005: ["#1122aa", "#770077", "#662255", "#889933", "#aaee77"],
  6006: ["#aa8888", "#bb1133", "#11bb33", "#44bbee", "#008855"],
  6007: ["#ee9977", "#1144aa", "#004411", "#338844", "#6666aa"],
  6008: ["#bb88ee", "#330066", "#22ffcc", "#11aa00", "#ff2299"],
  6009: ["#ee44cc", "#dd3322", "#bb4455", "#ddaacc", "#aaff66"],
  6010: ["#005566", "#117766", "#225522", "#9955ff", "#bbbbdd"],
  6011: ["#dd3300", "#884433", "#33bbaa", "#11eeff", "#77dd88"],
  6012: ["#554455", "#99ccff", "#6633cc", "#eeaa44", "#77dd88"],
  6013: ["#55aa33", "#66cc66", "#4400aa", "#ccddaa", "#8899dd"],
  6014: ["#11bb11", "#772255", "#8800aa", "#994444", "#dddddd"],
  6015: ["#ffee11", "#eedd22", "#554488", "#3344aa", "#6688dd"],
  6016: ["#dddd00", "#aa7700", "#eeff77", "#ffdd00", "#1122ee"],
  6017: ["#cceeee", "#11eecc", "#dd7788", "#11ffee", "#dd77ee"],
  6018: ["#ccaa33", "#11aaee", "#cc8877", "#88aa99", "#aaffcc"],
  6019: ["#225544", "#ee33cc", "#aadd88", "#33bb55", "#ffbb44"],
  6020: ["#994477", "#111155", "#885533", "#aa99ee", "#ffcc55"],
  6021: ["#cc1177", "#2233bb", "#dd9955", "#33ee00", "#bbdddd"],
  6022: ["#5577ee", "#ee7799", "#88dd44", "#22cc88", "#99ee55"],
  6023: ["#aa11bb", "#22cc77", "#ffdd11", "#77eecc", "#aa00cc"],
  6024: ["#993300", "#440022", "#cccc00", "#441111", "#669999"],
  6025: ["#00aa55", "#3399ee", "#55dddd", "#2233ee", "#668888"],
  6026: ["#7755aa", "#116655", "#eebb33", "#118800", "#22eebb"],
  6027: ["#33ff88", "#66ff22", "#ee0055", "#5599aa", "#556633"],
  6028: ["#002277", "#66dd77", "#886699", "#88aabb", "#7733aa"],
  6029: ["#886655", "#111122", "#11ddaa", "#dd2277", "#aabbdd"],
  6030: ["#aa11ff", "#447722", "#55ff77", "#33aaee", "#ff1111"],
  6031: ["#22ffcc", "#cc88aa", "#cc11aa", "#aacc88", "#77dd77"],
  6032: ["#ee4411", "#ffffdd", "#44ee22", "#118899", "#110055"],
  6033: ["#333355", "#dd1199", "#44ccff", "#221133", "#2222aa"],
  6034: ["#1166aa", "#779922", "#cc22bb", "#bbaa22", "#22eeff"],
  6035: ["#aabbee", "#553377", "#ddee88", "#88bb88", "#008800"],
  6036: ["#bb1155", "#11cc00", "#eeffdd", "#880000", "#55aa33"],
  6037: ["#77cc99", "#111122", "#008833", "#0088cc", "#113366"],
  6038: ["#3311aa", "#5555bb", "#5555aa", "#cc00aa", "#ff0055"],
  6039: ["#776600", "#ffcccc", "#339911", "#cc0077", "#55ee88"],
  6040: ["#aa00aa", "#ff99ee", "#eeccdd", "#66bb55", "#bb1166"],
  6041: ["#dd33dd", "#2211dd", "#ff2255", "#330066", "#33dd88"],
  6042: ["#22ff66", "#bb2288", "#ccccee", "#2200bb", "#2222ff"],
  6043: ["#eeeecc", "#ee1199", "#dd77aa", "#116600", "#eeff44"],
  6044: ["#44ee22", "#33ee77", "#ff77dd", "#cc99cc", "#993344"],
  6045: ["#ddee55", "#44aa00", "#556611", "#ee88bb", "#00ee55"],
  6046: ["#ccaadd", "#ffbbff", "#ddee11", "#eecc55", "#334411"],
  6047: ["#772299", "#665566", "#cc7700", "#22aadd", "#dd7722"],
  6048: ["#cc77ee", "#eeffee", "#dd66dd", "#66aa88", "#ee4466"],
  6049: ["#445577", "#dd11dd", "#4488bb", "#aadd00", "#77cc88"],
  6050: ["#225522", "#227788", "#bb4444", "#33ee33", "#11aaff"],
  6051: ["#998855", "#3311dd", "#224444", "#113399", "#888888"],
  6052: ["#ff33ee", "#663311", "#116600", "#660099", "#339933"],
  6053: ["#ee4477", "#aacc55", "#ee88dd", "#664455", "#559999"],
  6054: ["#88dd33", "#aaee88", "#aa2266", "#ff4422", "#cc8811"],
  6055: ["#7777bb", "#112200", "#0099ff", "#338811", "#33cc00"],
  6056: ["#3322ff", "#884477", "#ee00cc", "#1188dd", "#ffeeff"],
  6057: ["#00dd66", "#33bb00", "#99ee55", "#22aa66", "#55bbff"],
  6058: ["#eeff99", "#cc66ee", "#4444ff", "#6644cc", "#332211"],
  6059: ["#4422bb", "#eecc88", "#ffdddd", "#ffcc00", "#1144cc"],
  6060: ["#998800", "#004455", "#5511dd", "#6666aa", "#441144"],
  6061: ["#ee3399", "#bb55dd", "#665544", "#cc99bb", "#ff44dd"],
  6062: ["#55ff77", "#ccee88", "#660099", "#336677", "#3300ff"],
  6063: ["#1122ff", "#ee0033", "#885533", "#11ddcc", "#88dd22"],
  6064: ["#aacc33", "#663344", "#bb4444", "#eeff66", "#66bb11"],
  6065: ["#cc3355", "#9911ee", "#0066cc", "#00bb00", "#00eedd"],
  6066: ["#dd11bb", "#887722", "#551111", "#ee4444", "#bbee55"],
  6067: ["#bbdd77", "#eedd99", "#887711", "#bb4466", "#33ff77"],
  6068: ["#ee6644", "#aa2266", "#aacc66", "#665511", "#11aa77"],
  6069: ["#3399dd", "#99aa00", "#44bb99", "#229977", "#002200"],
  6070: ["#0066aa", "#11dd66", "#779988", "#eedd88", "#227733"],
  6071: ["#ffdddd", "#99cc22", "#229933", "#ff11aa", "#000044"],
  6072: ["#bb5577", "#9944ee", "#113300", "#6677ff", "#ccffcc"],
  6073: ["#22ff77", "#ff88bb", "#eeaadd", "#775533", "#225566"],
  6074: ["#ff66cc", "#ff77aa", "#22bbbb", "#ff5555", "#bb00dd"],
  6075: ["#559955", "#bb2200", "#883377", "#9966dd", "#666622"],
  6076: ["#553366", "#44aaff", "#66aa22", "#998833", "#9911ee"],
  6077: ["#ee9922", "#ff4477", "#bb9911", "#77bb11", "#55ffcc"],
  6078: ["#885566", "#009933", "#cc3300", "#11dd11", "#44cccc"],
  6079: ["#ddbbdd", "#5566ff", "#0022dd", "#ff7700", "#440022"],
  6080: ["#bbee44", "#998844", "#ee7788", "#44ff77", "#229900"],
  6081: ["#cc1100", "#aacc66", "#7799dd", "#009933", "#aabbaa"],
  6082: ["#eecc33", "#ee9911", "#ddaa88", "#ee1122", "#bb55bb"],
  6083: ["#5511ee", "#bb4400", "#44aaee", "#eebb88", "#4477ee"],
  6084: ["#22ccff", "#ff55ee", "#7722ff", "#ddccee", "#0033cc"],
  6085: ["#ddbb88", "#66cc22", "#6633dd", "#88dd55", "#ccdddd"],
  6086: ["#11cc22", "#2299bb", "#11ddee", "#aaffbb", "#11dd11"],
  6087: ["#66cc11", "#22bb99", "#aa66ee", "#00bbff", "#44bb77"],
  6088: ["#ffcc99", "#5555ff", "#0022ff", "#bbdd99", "#44dd55"],
  6089: ["#999944", "#6600ff", "#11ff55", "#66ff44", "#aaffcc"],
  6090: ["#77dd11", "#1100cc", "#1111dd", "#88ee44", "#55bbaa"],
  6091: ["#2244bb", "#222277", "#66cc11", "#dd44cc", "#ee6666"],
  6092: ["#ff8866", "#cc00dd", "#443311", "#228800", "#009944"],
  6093: ["#44ffaa", "#88ccaa", "#66ff44", "#443333", "#ffaa99"],
  6094: ["#ee66dd", "#bbee77", "#774411", "#bbdd11", "#aa4433"],
  6095: ["#66aaaa", "#dd6611", "#4466dd", "#00cc77", "#66dd44"],
  6096: ["#ddee22", "#22aa55", "#116688", "#55eeee", "#ee7700"],
  6097: ["#88ee66", "#ff0055", "#008844", "#663366", "#226655"],
  6098: ["#339977", "#550033", "#ff2277", "#aa5555", "#bb1166"],
  6099: ["#dd6666", "#664400", "#ee2222", "#aabb55", "#88ee11"],
  6100: ["#663311", "#ff9966", "#aadd77", "#555511", "#77ffdd"],
  6101: ["#8833bb", "#22aa11", "#4499ee", "#ff66ee", "#332299"],
  6102: ["#222299", "#eeccaa", "#dd11ff", "#99cc77", "#00ff55"],
  6103: ["#66ccee", "#55aa22", "#aa8811", "#990055", "#ee2244"],
  6104: ["#551166", "#22aaff", "#bb5522", "#dd7766", "#1188cc"],
  6105: ["#770099", "#eeaabb", "#dd6644", "#ee66dd", "#11bbdd"],
  6106: ["#ee6644", "#cccc66", "#88bb44", "#880033", "#1144ee"],
  6107: ["#bb00ee", "#005500", "#776633", "#77aa00", "#cc77aa"],
  6108: ["#cc55ee", "#7711dd", "#bbee55", "#bb5500", "#0088ee"],
  6109: ["#445522", "#441122", "#33ccbb", "#11aabb", "#ee99ee"],
  6110: ["#dd88bb", "#aaaa22", "#ccaa22", "#990055", "#88aa88"],
  6111: ["#550022", "#99ddcc", "#aadd55", "#5588dd", "#1100bb"],
  6112: ["#99ee66", "#556600", "#8822ee", "#99bb44", "#99cc66"],
  6113: ["#3344dd", "#44ee66", "#ee00aa", "#ccbb22", "#001188"],
  6114: ["#ff9966", "#cc2255", "#33ff11", "#662222", "#bb2200"],
  6115: ["#eeff44", "#6655ee", "#22ccdd", "#99dd00", "#aa33cc"],
  6116: ["#5599bb", "#ddeedd", "#cc9933", "#eeaa55", "#778844"],
  6117: ["#aa8822", "#22ddcc", "#777755", "#44bb99", "#bbdd22"],
  6118: ["#664400", "#ee5544", "#33ffff", "#aaff99", "#ff33cc"],
  6119: ["#ee7722", "#8844aa", "#aa1155", "#2299ee", "#cc8811"],
  6120: ["#aaffbb", "#447700", "#aaff99", "#eeff99", "#ddccbb"],
  6121: ["#eeee77", "#665544", "#99ee00", "#33aacc", "#00ee99"],
  6122: ["#2299cc", "#11ee44", "#cc6600", "#55ffdd", "#446655"],
  6123: ["#ccee55", "#118800", "#ee2255", "#eedd00", "#4422ee"],
  6124: ["#dd2299", "#440099", "#77dd00", "#116699", "#77bbdd"],
  6125: ["#555555", "#dd6611", "#884422", "#9999aa", "#bb00ff"],
  6126: ["#666611", "#00ee77", "#88ff55", "#88eeff", "#885522"],
  6127: ["#ff00ee", "#33cc11", "#aaaabb", "#9911dd", "#ee8888"],
  6128: ["#0099cc", "#000077", "#88aa44", "#33cc11", "#225500"],
  6129: ["#bb4400", "#cc22cc", "#bb6677", "#6633aa", "#ffcc33"],
  6130: ["#882244", "#2211ee", "#009922", "#77ff77", "#771133"],
  6131: ["#dd6600", "#ff6655", "#557777", "#bb00dd", "#1144cc"],
  6132: ["#cc9922", "#6600aa", "#aa6611", "#ffcc66", "#aa0033"],
  6133: ["#0055cc", "#00bb44", "#00aa99", "#667733", "#00dd99"],
  6134: ["#775566", "#9955aa", "#227777", "#cccc77", "#99bb11"],
  6135: ["#881122", "#8866aa", "#ee5533", "#44aaee", "#bb7766"],
  6136: ["#117777", "#44ff00", "#bbcc22", "#006677", "#ff1133"],
  6137: ["#2266ee", "#119955", "#22ee55", "#77dd22", "#229922"],
  6138: ["#88cc33", "#66eedd", "#cc33cc", "#eeff55", "#44bb88"],
  6139: ["#99ff33", "#99ee33", "#99ee99", "#002277", "#4499ff"],
  6140: ["#441111", "#888833", "#aaccaa", "#ff2288", "#8844cc"],
  6141: ["#ddaa77", "#ddaaff", "#ffcc33", "#995533", "#22cc22"],
  6142: ["#220088", "#665522", "#555566", "#441166", "#003377"],
  6143: ["#99bb22", "#4400ee", "#1188dd", "#666655", "#eeee88"],
  6144: ["#99ff44", "#0088ff", "#cc0077", "#88aa88", "#4422bb"],
  6145: ["#55ee77", "#001177", "#dd0011", "#99bb99", "#6699dd"],
  6146: ["#2255bb", "#ee3333", "#cccc11", "#441122", "#008833"],
  6147: ["#cc6655", "#6666aa", "#9955bb", "#770077", "#113388"],
  6148: ["#ffff11", "#3322ff", "#5599ee", "#aabbbb", "#cc11ee"],
  6149: ["#11bb22", "#aa55ee", "#553344", "#ff9988", "#eecc33"],
  6150: ["#9955aa", "#553344", "#55ffaa", "#66ee88", "#22ddbb"],
  6151: ["#990066", "#44eeaa", "#ff5566", "#0077ee", "#1177ff"],
  6152: ["#3333ee", "#66dd55", "#eeee88", "#dd33bb", "#ddcc22"],
  6153: ["#004400", "#0088aa", "#11ee88", "#ee3333", "#eeeebb"],
  6154: ["#7788ff", "#22eebb", "#ff7766", "#bb22ee", "#669900"],
  6155: ["#336666", "#553322", "#bb2244", "#5588ff", "#664422"],
  6156: ["#dd7777", "#4455dd", "#11ddff", "#660044", "#ccbb88"],
  6157: ["#bb9922", "#cc88aa", "#117700", "#2255dd", "#445555"],
  6158: ["#112266", "#66ffdd", "#119966", "#997766", "#0044cc"],
  6159: ["#cceeaa", "#3300aa", "#6688ff", "#00dd44", "#44aa22"],
  6160: ["#445599", "#0088bb", "#ffddee", "#22ddbb", "#5511dd"],
  6161: ["#222255", "#ccccaa", "#55bb11", "#ff66dd", "#aa8877"],
  6162: ["#cc0033", "#665566", "#ee99ee", "#cc66bb", "#224499"],
  6163: ["#ffaa11", "#110055", "#ff3355", "#ccdd99", "#aa00cc"],
  6164: ["#555533", "#cc5500", "#11aa66", "#55ee22", "#11ee11"],
  6165: ["#663300", "#00cc11", "#6611dd", "#22aa77", "#770055"],
  6166: ["#22dd99", "#223377", "#aa44bb", "#00ff66", "#337788"],
  6167: ["#552299", "#996688", "#1188aa", "#bbee66", "#2244cc"],
  6168: ["#ee88bb", "#6666aa", "#99cc88", "#dd8822", "#aacc22"],
  6169: ["#223355", "#9999ff", "#bb11bb", "#6622dd", "#112255"],
  6170: ["#66bb88", "#ff00bb", "#eedd11", "#cc8855", "#eecc77"],
  6171: ["#aa55cc", "#1111cc", "#115566", "#aa3344", "#66dd44"],
  6172: ["#7766ff", "#cc9900", "#551144", "#22dd22", "#cc44bb"],
  6173: ["#bbcc11", "#00ccaa", "#ff44aa", "#bbeedd", "#33aa88"],
  6174: ["#0066ff", "#dddd55", "#55ee77", "#4400cc", "#ffbb99"],
  6175: ["#66bbee", "#bb7700", "#bb55bb", "#11aa00", "#444488"],
  6176: ["#cc99cc", "#44dd00", "#006699", "#005500", "#bbbb11"],
  6177: ["#ff7766", "#00dd88", "#dd99cc", "#ff88ee", "#99dd66"],
  6178: ["#cc6666", "#55ff66", "#aaff11", "#8888bb", "#77ee33"],
  6179: ["#55bb99", "#11bbcc", "#ee77bb", "#884499", "#88aa44"],
  6180: ["#225544", "#44aa88", "#99ccdd", "#cc88dd", "#554444"],
  6181: ["#8899aa", "#aadd00", "#11ee22", "#1188ff", "#bb4477"],
  6182: ["#116699", "#22ffdd", "#2299aa", "#994400", "#bbdd11"],
  6183: ["#ff3344", "#aa5599", "#997777", "#3366bb", "#2288aa"],
  6184: ["#bb99dd", "#4444ff", "#44ee55", "#886688", "#444444"],
  6185: ["#aaaadd", "#662277", "#9900ff", "#88ddff", "#ff7722"],
  6186: ["#eeff11", "#88eecc", "#dd1166", "#2200bb", "#331177"],
  6187: ["#6699cc", "#aa44aa", "#0088cc", "#11aa11", "#11cccc"],
  6188: ["#4400dd", "#66ffbb", "#ff1122", "#223366", "#cc7733"],
  6189: ["#ee44dd", "#bbff22", "#77aabb", "#cc4455", "#99cc44"],
  6190: ["#00cc11", "#0022aa", "#446677", "#113399", "#ff88ee"],
  6191: ["#44ee77", "#552255", "#556622", "#331188", "#aa7733"],
  6192: ["#bbff11", "#66ccdd", "#aa22aa", "#4422dd", "#554400"],
  6193: ["#00ffee", "#4499bb", "#2211cc", "#33ff11", "#554499"],
  6194: ["#dd11ff", "#0055ff", "#55aa00", "#110099", "#119900"],
  6195: ["#88ee55", "#66ff77", "#1122ff", "#aa00ee", "#6622aa"],
  6196: ["#55ee22", "#ff2266", "#ff77aa", "#ee0099", "#ee4477"],
  6197: ["#33dd44", "#118800", "#bb9922", "#554499", "#557744"],
  6198: ["#551111", "#66dd66", "#668811", "#11ffaa", "#00aa55"],
  6199: ["#aa00dd", "#002266", "#00aa00", "#1155aa", "#4466aa"],
  6200: ["#22bb33", "#55ee22", "#66ddbb", "#ff8833", "#779999"],
  6201: ["#771188", "#118844", "#221177", "#ee11dd", "#11ffee"],
  6202: ["#0044cc", "#77ee99", "#772277", "#bb6611", "#559955"],
  6203: ["#44cc77", "#442288", "#ddff44", "#664411", "#336677"],
  6204: ["#ddddff", "#7700dd", "#ddee55", "#4466ee", "#eeee11"],
  6205: ["#aaaa88", "#33ee44", "#ff99aa", "#33bbee", "#337766"],
  6206: ["#ee3344", "#00dd00", "#4477cc", "#9900cc", "#cc55ee"],
  6207: ["#2200ff", "#77aa99", "#44cc44", "#bb1144", "#557722"],
  6208: ["#3311dd", "#aa77bb", "#77dd99", "#11ccaa", "#22cc77"],
  6209: ["#889900", "#887766", "#669933", "#ee77ff", "#22eebb"],
  6210: ["#dd3344", "#5588aa", "#33ffaa", "#ee9911", "#44ee88"],
  6211: ["#1100aa", "#ffeecc", "#666622", "#bbff44", "#bb11aa"],
  6212: ["#5511aa", "#99ee55", "#bb55dd", "#2211ff", "#557766"],
  6213: ["#ccee00", "#1155cc", "#664477", "#4433ff", "#5511dd"],
  6214: ["#ccddcc", "#dd6699", "#bb5511", "#bb0099", "#335555"],
  6215: ["#bb1199", "#338811", "#668822", "#332255", "#5555ee"],
  6216: ["#0099ee", "#ff9900", "#bb3322", "#ffcc11", "#ee11ee"],
  6217: ["#ff4433", "#ee6677", "#3311bb", "#cc8877", "#bb3322"],
  6218: ["#11ee00", "#44aa77", "#cc22ff", "#885555", "#22bbdd"],
  6219: ["#ccdd99", "#996677", "#bb6622", "#660022", "#eecc11"],
  6220: ["#ff9988", "#337777", "#113333", "#22bb44", "#4444ff"],
  6221: ["#ee1122", "#113322", "#7722cc", "#cc44ff", "#119900"],
  6222: ["#006655", "#7766aa", "#11bb99", "#883344", "#cc0066"],
  6223: ["#444499", "#77ff00", "#dd7722", "#44ee55", "#220044"],
  6224: ["#770099", "#88aa22", "#5555aa", "#ee8888", "#66ffaa"],
  6225: ["#5511bb", "#ccaa33", "#66ddbb", "#33ff77", "#1188bb"],
  6226: ["#cc2288", "#77ccaa", "#ccdd44", "#55ee77", "#77ffaa"],
  6227: ["#ddcc55", "#995599", "#99bbbb", "#115599", "#557755"],
  6228: ["#0033cc", "#99dd99", "#7744aa", "#8899dd", "#aa88ee"],
  6229: ["#444400", "#5577cc", "#1144dd", "#bb0077", "#992277"],
  6230: ["#771177", "#bb55aa", "#ccff99", "#1188aa", "#cc55aa"],
  6231: ["#669922", "#557733", "#557722", "#002277", "#777711"],
  6232: ["#dd2244", "#ff2244", "#5522bb", "#227766", "#6622ee"],
  6233: ["#222244", "#2211aa", "#cc2211", "#ddcc33", "#116633"],
  6234: ["#3300dd", "#22dd88", "#992299", "#99ffff", "#2266cc"],
  6235: ["#5577bb", "#339999", "#990044", "#2266ee", "#66ddbb"],
  6236: ["#448866", "#3388bb", "#ff2233", "#88cc88", "#33bb55"],
  6237: ["#ff7755", "#eeeeff", "#ccddbb", "#665555", "#ff2211"],
  6238: ["#ee88ff", "#55eeaa", "#227788", "#77cc66", "#aabbbb"],
  6239: ["#00ee33", "#55cccc", "#111199", "#ff9944", "#00ff55"],
  6240: ["#33bb99", "#dd5599", "#88ff22", "#66ee00", "#00cc11"],
  6241: ["#aabb11", "#881177", "#aa6633", "#0000ee", "#cc11ee"],
  6242: ["#88dd44", "#cc88ee", "#ff00bb", "#440055", "#882244"],
  6243: ["#55ffdd", "#ee44cc", "#445533", "#dd2266", "#557722"],
  6244: ["#00cc00", "#bb8866", "#ccdd66", "#550088", "#ee7755"],
  6245: ["#99ee66", "#771155", "#aa22cc", "#88aa00", "#00cccc"],
  6246: ["#88aa66", "#44ddbb", "#55ff66", "#aa5599", "#bb77cc"],
  6247: ["#ffcc99", "#1133aa", "#bb2266", "#770077", "#66ccff"],
  6248: ["#227777", "#55dddd", "#11ee11", "#ff9933", "#0033ff"],
  6249: ["#ee8899", "#0099cc", "#bb6666", "#ffaa00", "#44ee11"],
  6250: ["#55ccaa", "#776677", "#333322", "#448877", "#11dd99"],
  6251: ["#3300aa", "#00ff33", "#ddcc22", "#558811", "#ccffee"],
  6252: ["#11eedd", "#9944bb", "#bb1177", "#bb1177", "#ddeeaa"],
  6253: ["#aa77dd", "#ee6655", "#ee11aa", "#004477", "#44bb66"],
  6254: ["#22cc66", "#99bb00", "#11ffee", "#88cc55", "#ff8822"],
  6255: ["#00bb99", "#886622", "#1199dd", "#224422", "#ff7788"],
  6256: ["#1177ff", "#dd44dd", "#1100aa", "#22ffee", "#ff9977"],
  6257: ["#ddbbaa", "#22aa99", "#bbffbb", "#bbcccc", "#0011bb"],
  6258: ["#ffffcc", "#991177", "#7744ee", "#88eeff", "#2299dd"],
  6259: ["#bb0033", "#887722", "#664400", "#ee33dd", "#1133dd"],
  6260: ["#008899", "#ffaaaa", "#770088", "#cc0044", "#3333bb"],
  6261: ["#9922dd", "#8866cc", "#223355", "#4400ff", "#886633"],
  6262: ["#ff6644", "#bbddee", "#dd5511", "#55bb55", "#6688ee"],
  6263: ["#224411", "#ccdd22", "#ee7733", "#7711cc", "#bbbb11"],
  6264: ["#99bbbb", "#ee99cc", "#55dd11", "#009988", "#552244"],
  6265: ["#ee9933", "#11ddbb", "#883366", "#00aa88", "#881111"],
  6266: ["#77ff22", "#ff9922", "#0077aa", "#88ddaa", "#448899"],
  6267: ["#772211", "#cc88ee", "#ff6622", "#44aa66", "#55ffaa"],
  6268: ["#774466", "#ee22bb", "#773366", "#ff2200", "#eebb22"],
  6269: ["#ddff88", "#bbee11", "#ddaabb", "#88ffdd", "#33bbaa"],
  6270: ["#441144", "#001100", "#441188", "#bb2222", "#ee77ee"],
  6271: ["#333311", "#773399", "#dd66dd", "#44ffbb", "#004466"],
  6272: ["#99ddaa", "#004499", "#22dd00", "#ee0000", "#bb33dd"],
  6273: ["#ee6633", "#cc2266", "#336688", "#998899", "#1144ff"],
  6274: ["#44cc88", "#775577", "#33ee66", "#bb7733", "#bb5522"],
  6275: ["#66cc00", "#445577", "#1155dd", "#ccdd77", "#cc00aa"],
  6276: ["#66dd22", "#aaaa33", "#4444bb", "#8877ee", "#55dd66"],
  6277: ["#551199", "#aabbff", "#11bb11", "#ddffbb", "#2255ee"],
  6278: ["#bb7733", "#11cc33", "#ddee44", "#44eecc", "#4411aa"],
  6279: ["#3333bb", "#aa7700", "#11ffee", "#eebb11", "#330055"],
  6280: ["#220055", "#5555ff", "#116633", "#0011ff", "#cceeee"],
  6281: ["#774411", "#7700cc", "#773388", "#9999dd", "#8866dd"],
  6282: ["#88cc88", "#995544", "#55dd22", "#ff33aa", "#aa66ee"],
  6283: ["#116666", "#ee5599", "#cc88dd", "#774499", "#99dd55"],
  6284: ["#2211ee", "#5555ff", "#4488ff", "#aadd66", "#44ff66"],
  6285: ["#3388bb", "#55ffcc", "#9911dd", "#115566", "#eeeeff"],
  6286: ["#cc55aa", "#44dd33", "#ee22cc", "#bb2266", "#bb0011"],
  6287: ["#99dd77", "#aa66aa", "#995511", "#ff7744", "#99aaaa"],
  6288: ["#aa77ff", "#5577aa", "#77ff00", "#eebb55", "#224444"],
  6289: ["#001144", "#dd8899", "#bb2277", "#66ccdd", "#dd44bb"],
  6290: ["#7777ff", "#ff3333", "#bbcc44", "#55ee33", "#11ffbb"],
  6291: ["#00bb11", "#555522", "#cccc33", "#22ccff", "#7711dd"],
  6292: ["#888811", "#ee33bb", "#888833", "#dd33cc", "#44ffbb"],
  6293: ["#888833", "#887755", "#cc6688", "#aa55bb", "#00dd11"],
  6294: ["#4433ee", "#ddaa88", "#aa6633", "#33bbaa", "#bb88aa"],
  6295: ["#885522", "#ffddee", "#33ee77", "#66eeee", "#ee0077"],
  6296: ["#aaaa00", "#3377aa", "#6633bb", "#ee11aa", "#332277"],
  6297: ["#cc66bb", "#442233", "#ff99dd", "#8899ee", "#3355ff"],
  6298: ["#aa0088", "#330077", "#4455ff", "#11dd99", "#22bb00"],
  6299: ["#bb7755", "#bbffaa", "#aaff11", "#00ee77", "#3355bb"],
  6300: ["#ccdd66", "#001111", "#ddff33", "#778899", "#ee8877"],
  6301: ["#66ff11", "#55ffaa", "#772255", "#ccffff", "#cc88dd"],
  6302: ["#eeaa11", "#bbdd22", "#ee5555", "#2233aa", "#33ff88"],
  6303: ["#ff00aa", "#330077", "#221177", "#ff55dd", "#eebbdd"],
  6304: ["#66bb33", "#bb2277", "#552277", "#0000ee", "#cc6633"],
  6305: ["#eecc44", "#4488cc", "#002211", "#77dd33", "#aacccc"],
  6306: ["#ee55ee", "#994477", "#44ee33", "#33dd00", "#0044cc"],
  6307: ["#dd1188", "#eeaa11", "#999977", "#ee4433", "#aacc33"],
  6308: ["#77dd55", "#ff4466", "#7733aa", "#dd4433", "#221188"],
  6309: ["#55aadd", "#bbccaa", "#55ccdd", "#ee00aa", "#aaff11"],
  6310: ["#aa7766", "#ffff44", "#22aa44", "#220055", "#44dd99"],
  6311: ["#5577aa", "#77dd22", "#eeccdd", "#113355", "#aa3311"],
  6312: ["#553311", "#ffdd22", "#664466", "#888855", "#554433"],
  6313: ["#332299", "#bb2244", "#88aaee", "#22ccee", "#1122aa"],
  6314: ["#ff4477", "#44bbdd", "#661133", "#ddcc99", "#444433"],
  6315: ["#771188", "#ee2277", "#449955", "#5511dd", "#995522"],
  6316: ["#11ff55", "#ee44ff", "#77cc11", "#885544", "#eecc66"],
  6317: ["#88ccdd", "#1155cc", "#33dddd", "#99aa33", "#ccaabb"],
  6318: ["#555599", "#112211", "#8811ff", "#aaaa00", "#bbbbcc"],
  6319: ["#11ccaa", "#cc3333", "#7799aa", "#88cccc", "#dd2255"],
  6320: ["#ee22cc", "#7755dd", "#66cc11", "#11ccbb", "#33cc99"],
  6321: ["#00eeaa", "#4477ff", "#dd33dd", "#9988ee", "#5588dd"],
  6322: ["#5511ee", "#aaccaa", "#ffbbff", "#dd44aa", "#22cccc"],
  6323: ["#cc5555", "#dd3399", "#11ccaa", "#55ee33", "#6644dd"],
  6324: ["#55ff88", "#887777", "#448800", "#ff00dd", "#00ee66"],
  6325: ["#993311", "#5588aa", "#cc7700", "#ee11dd", "#3311aa"],
  6326: ["#55cc44", "#22cccc", "#008899", "#99ffff", "#ddccbb"],
  6327: ["#663377", "#665555", "#000099", "#22bbee", "#551100"],
  6328: ["#ddffbb", "#6677bb", "#00bbbb", "#ee11ee", "#5533ee"],
  6329: ["#66bbaa", "#884488", "#ddffff", "#bbdddd", "#11dd00"],
  6330: ["#4499ee", "#114477", "#2288ff", "#88cc55", "#771122"],
  6331: ["#aaaa22", "#44ee55", "#443366", "#118866", "#00aa44"],
  6332: ["#774488", "#2233dd", "#bbbb66", "#449955", "#ddeeee"],
  6333: ["#556611", "#ffdd22", "#00ff22", "#66ffcc", "#dd2211"],
  6334: ["#339911", "#ddbbbb", "#0044ff", "#ddee11", "#ddffff"],
  6335: ["#eeee99", "#ff3322", "#bbaaff", "#ffccee", "#886600"],
  6336: ["#ee11ff", "#cc55aa", "#33eeee", "#7744cc", "#22bb99"],
  6337: ["#aa2255", "#8866ff", "#aa7755", "#aa77cc", "#55ff99"],
  6338: ["#33dd00", "#99aacc", "#557766", "#eeaa22", "#66cc11"],
  6339: ["#886600", "#ee6666", "#4499bb", "#55bb99", "#88cc11"],
  6340: ["#dd6677", "#3311ff", "#8844dd", "#88ee77", "#ff44dd"],
  6341: ["#994488", "#227755", "#bbeebb", "#223399", "#333388"],
  6342: ["#339900", "#4455ff", "#cc6677", "#00eeee", "#9955aa"],
  6343: ["#00bb22", "#666655", "#dd55dd", "#1122ee", "#cc99aa"],
  6344: ["#772255", "#5544bb", "#119911", "#ddccee", "#ddeedd"],
  6345: ["#bb1111", "#8800aa", "#118888", "#11ddbb", "#aa3333"],
  6346: ["#ff2200", "#773344", "#ffbb99", "#33ff00", "#880055"],
  6347: ["#aaeedd", "#ddaadd", "#2244aa", "#dd3377", "#007711"],
  6348: ["#5588aa", "#ddee11", "#4400bb", "#113355", "#77eecc"],
  6349: ["#225588", "#4422aa", "#880000", "#eeaabb", "#779900"],
  6350: ["#3344dd", "#aaddcc", "#dddd66", "#554488", "#003366"],
  6351: ["#0066dd", "#55ff88", "#6655ff", "#bb7788", "#11aaaa"],
  6352: ["#88ccff", "#336633", "#114466", "#33cc66", "#447733"],
  6353: ["#ff77dd", "#6677aa", "#bb0011", "#aabbbb", "#3388bb"],
  6354: ["#66ffaa", "#cc22ff", "#cccc66", "#aaee11", "#aa22bb"],
  6355: ["#ff4499", "#aa77bb", "#aaaa22", "#cc2288", "#9900bb"],
  6356: ["#2222cc", "#cccc55", "#ffbbbb", "#663344", "#771155"],
  6357: ["#ff99aa", "#99dd11", "#11ee11", "#ee55ee", "#bbeebb"],
  6358: ["#cc88ff", "#99eecc", "#00ffbb", "#772299", "#44ff11"],
  6359: ["#11ffee", "#bbdd33", "#cc9977", "#22aaee", "#8877ff"],
  6360: ["#770000", "#33ddaa", "#ee0022", "#7700bb", "#ddbb66"],
  6361: ["#441133", "#226666", "#88cc44", "#22aaff", "#5500bb"],
  6362: ["#22cc55", "#0033ee", "#559977", "#dd88dd", "#22bb66"],
  6363: ["#eeff00", "#ee88aa", "#665500", "#ee4488", "#66dd66"],
  6364: ["#77eebb", "#44cc99", "#5566cc", "#ee77bb", "#ffaabb"],
  6365: ["#3311aa", "#228855", "#22ffff", "#aaee77", "#ff7744"],
  6366: ["#eeee88", "#66aabb", "#aa2255", "#551100", "#440088"],
  6367: ["#77cc33", "#77bbaa", "#aa66aa", "#994422", "#33ff55"],
  6368: ["#cccc33", "#331122", "#44ee33", "#119933", "#dd9922"],
  6369: ["#009900", "#bbcc11", "#1199dd", "#5577ff", "#ee8833"],
  6370: ["#77ff00", "#88aa00", "#4444ff", "#33bbbb", "#aa6644"],
  6371: ["#6677ee", "#cc33cc", "#441188", "#3311bb", "#332233"],
  6372: ["#ddddaa", "#448844", "#0099ff", "#99ddff", "#aaff99"],
  6373: ["#55aa88", "#221111", "#ffaacc", "#00dd99", "#ee5599"],
  6374: ["#113344", "#778800", "#774422", "#ccee77", "#998800"],
  6375: ["#eeeeff", "#9922ff", "#0066ee", "#bb11bb", "#553366"],
  6376: ["#77dd99", "#ff3322", "#3344dd", "#ff99bb", "#dd77cc"],
  6377: ["#11bb66", "#113388", "#dd7711", "#884400", "#2200dd"],
  6378: ["#8899ee", "#bb77cc", "#999911", "#228866", "#00ff66"],
  6379: ["#33aa77", "#33ee66", "#1133cc", "#dd00dd", "#11ee00"],
  6380: ["#ddcc77", "#2211ff", "#bbaa33", "#77ff77", "#996666"],
  6381: ["#ff44ff", "#dd99ee", "#5555bb", "#cceeaa", "#88dd22"],
  6382: ["#22ccbb", "#7700aa", "#2288dd", "#44ff88", "#1188cc"],
  6383: ["#55bbff", "#11cc55", "#8822ff", "#dd5511", "#774466"],
  6384: ["#77aaff", "#229911", "#ffaabb", "#5577dd", "#552299"],
  6385: ["#774433", "#77ccdd", "#113355", "#33ddcc", "#11ccdd"],
  6386: ["#aadddd", "#bbffee", "#ff0077", "#22ccbb", "#99ddaa"],
  6387: ["#55eeff", "#aacccc", "#0022cc", "#3322ff", "#aa00ff"],
  6388: ["#558844", "#ff2211", "#557711", "#dd33ee", "#777733"],
  6389: ["#3311cc", "#ff11aa", "#ff5544", "#880022", "#bbaacc"],
  6390: ["#33aa66", "#99ffcc", "#3322ee", "#aaee11", "#224422"],
  6391: ["#ee99dd", "#663355", "#33cc11", "#ff1199", "#9955cc"],
  6392: ["#ccff55", "#551188", "#441111", "#005511", "#dd9900"],
  6393: ["#7766bb", "#006699", "#dd3377", "#bb88ff", "#7700ff"],
  6394: ["#bb6611", "#882299", "#0000cc", "#0066bb", "#77dd44"],
  6395: ["#bb88cc", "#0011ff", "#ee4466", "#117788", "#55cc55"],
  6396: ["#223322", "#11ff77", "#227700", "#880044", "#cc4411"],
  6397: ["#332200", "#dd88ee", "#111100", "#111166", "#cc2255"],
  6398: ["#cc77ff", "#33bbcc", "#554477", "#33bb55", "#aadd88"],
  6399: ["#aa1177", "#22aadd", "#ff7744", "#ddbb44", "#55dd44"],
  6400: ["#cc7711", "#eeee99", "#bbeeaa", "#55bb22", "#8822cc"],
  6401: ["#ddbb11", "#99dd00", "#aa9955", "#cccc99", "#777766"],
  6402: ["#227766", "#aa33aa", "#ff2266", "#ffdd11", "#887766"],
  6403: ["#00dd99", "#117733", "#ee99ff", "#bb3333", "#1100cc"],
  6404: ["#33aa77", "#118844", "#ddcc00", "#aa55aa", "#33dd00"],
  6405: ["#77eeaa", "#6677dd", "#553388", "#aa00cc", "#aa66bb"],
  6406: ["#0044dd", "#667744", "#dd9955", "#882222", "#77ee99"],
  6407: ["#cc4422", "#1111dd", "#4466dd", "#884422", "#002200"],
  6408: ["#ffbb00", "#557711", "#ccffff", "#77bb88", "#bb2211"],
  6409: ["#ff3344", "#11bb77", "#eeff66", "#cc66aa", "#ddaa66"],
  6410: ["#88dd77", "#99bb55", "#339944", "#bb4499", "#ccaa44"],
  6411: ["#4499ff", "#ff1166", "#778844", "#22ffaa", "#552277"],
  6412: ["#ee1155", "#ff9955", "#aa9988", "#ccaa66", "#2200bb"],
  6413: ["#33bb55", "#bb66cc", "#ddcc00", "#3399bb", "#665533"],
  6414: ["#ccddff", "#552288", "#ffbb11", "#5566ee", "#4411dd"],
  6415: ["#bb66bb", "#885533", "#aa5522", "#55ddbb", "#3322aa"],
  6416: ["#ffcc55", "#bb99ff", "#cc9977", "#55ff11", "#22aa99"],
  6417: ["#bb8899", "#11ddff", "#ddcc88", "#ff66ff", "#77ffff"],
  6418: ["#44ffff", "#995522", "#66ff66", "#aa2211", "#2277cc"],
  6419: ["#553355", "#dd4444", "#ff33bb", "#ff0022", "#5522ee"],
  6420: ["#8844bb", "#00aa11", "#228800", "#bb3355", "#225588"],
  6421: ["#dd9977", "#999966", "#337744", "#008888", "#ff5588"],
  6422: ["#ffdd55", "#dd3333", "#337799", "#ff0022", "#cc00aa"],
  6423: ["#66bbdd", "#88aa88", "#aa2288", "#33bb33", "#0055bb"],
  6424: ["#ff33cc", "#111111", "#0033cc", "#11ee88", "#778877"],
  6425: ["#110022", "#aaee66", "#ee4477", "#775533", "#99bb44"],
  6426: ["#775599", "#002222", "#000077", "#5522ff", "#225511"],
  6427: ["#dd00ee", "#669966", "#778844", "#660044", "#aabb66"],
  6428: ["#cc3344", "#22aadd", "#ccaa99", "#3322ff", "#229988"],
  6429: ["#aaff00", "#aaffaa", "#dd3344", "#1111aa", "#226666"],
  6430: ["#3300cc", "#0033ee", "#22ee88", "#ee4400", "#88bbaa"],
  6431: ["#ddaaaa", "#113355", "#aa6655", "#77dd88", "#ccee99"],
  6432: ["#6666bb", "#99ee11", "#33ff99", "#ee0022", "#aa9911"],
  6433: ["#cc8811", "#cccc11", "#663311", "#118855", "#ffaa66"],
  6434: ["#cc3311", "#887777", "#77bb11", "#dd44ee", "#33ff00"],
  6435: ["#cc6622", "#550088", "#cc11ff", "#997700", "#dd3344"],
  6436: ["#886611", "#88bb33", "#22dd44", "#ffbb33", "#77aadd"],
  6437: ["#5533ff", "#ddee33", "#88cc77", "#9944bb", "#44dd33"],
  6438: ["#66ccbb", "#eeaa77", "#66dd55", "#007777", "#1166ee"],
  6439: ["#886666", "#7744dd", "#6655aa", "#114499", "#ee6600"],
  6440: ["#11bb77", "#3300aa", "#cc11ff", "#00ee88", "#cc8844"],
  6441: ["#cc5522", "#557711", "#dd22ee", "#666677", "#8844bb"],
  6442: ["#ddaa77", "#6622dd", "#99cc22", "#6622aa", "#dd77ee"],
  6443: ["#99eedd", "#ffee66", "#88ffcc", "#225511", "#004499"],
  6444: ["#cc00ff", "#77ccaa", "#ee00ee", "#5522bb", "#223388"],
  6445: ["#666688", "#5577dd", "#eedd11", "#44ff44", "#779933"],
  6446: ["#44aabb", "#44aa88", "#33dd88", "#113311", "#ee88dd"],
  6447: ["#6666ff", "#994488", "#330011", "#33ff11", "#dd0055"],
  6448: ["#bbaa88", "#88ff44", "#eeff88", "#3311dd", "#eeaa55"],
  6449: ["#ee2211", "#ff33bb", "#ddeecc", "#aa9911", "#11ff33"],
  6450: ["#aa0088", "#77bb44", "#eeaa77", "#aaaa55", "#ff0033"],
  6451: ["#cc2255", "#77ee22", "#9955ee", "#88cc55", "#003377"],
  6452: ["#ccff44", "#dd55ff", "#99aabb", "#004400", "#aa4499"],
  6453: ["#88cc44", "#994411", "#884422", "#bbbbbb", "#ff11ee"],
  6454: ["#ee9911", "#99aa44", "#2277ff", "#334411", "#ee8855"],
  6455: ["#338811", "#00ff88", "#ddcc22", "#ee55bb", "#117799"],
  6456: ["#dd3377", "#5511cc", "#887777", "#666666", "#1122ff"],
  6457: ["#0011cc", "#ee44dd", "#995544", "#aa77ff", "#88ff33"],
  6458: ["#dd99cc", "#9933dd", "#660033", "#1188bb", "#99aa33"],
  6459: ["#ff5500", "#ee33ee", "#dd8844", "#332299", "#22bb88"],
  6460: ["#006688", "#00ee77", "#227700", "#88aabb", "#44cc66"],
  6461: ["#990055", "#ee33ee", "#6600bb", "#775500", "#66aa00"],
  6462: ["#22aa33", "#00aabb", "#11aaff", "#22ee11", "#ee9944"],
  6463: ["#bbbb22", "#aacc00", "#008888", "#55bbbb", "#77ddbb"],
  6464: ["#ddffaa", "#eecccc", "#223377", "#ff5544", "#ffbbff"],
  6465: ["#552222", "#99ff66", "#ddaa33", "#88aa00", "#dd0077"],
  6466: ["#6666bb", "#009977", "#aadd88", "#334466", "#9966cc"],
  6467: ["#ffcc22", "#99aaee", "#445599", "#337722", "#7744dd"],
  6468: ["#44bb44", "#ffaa33", "#44aadd", "#55ddbb", "#88cc22"],
  6469: ["#55cccc", "#112277", "#cceebb", "#bb88ff", "#cc11aa"],
  6470: ["#aa3377", "#7733ff", "#ee0066", "#002200", "#884422"],
  6471: ["#ee8833", "#44bb44", "#aacc44", "#33ccbb", "#eedd44"],
  6472: ["#1188ee", "#662244", "#ddcc55", "#334466", "#8888aa"],
  6473: ["#995544", "#77dd66", "#cc8888", "#006688", "#ffff44"],
  6474: ["#ee7777", "#bb1122", "#77ee11", "#ddccff", "#99ee77"],
  6475: ["#aacc22", "#0099dd", "#ff7700", "#ff33ee", "#ccee77"],
  6476: ["#ff6699", "#883355", "#cc33cc", "#11aa88", "#886655"],
  6477: ["#99ff33", "#bb6677", "#bbffff", "#77dd44", "#11cc33"],
  6478: ["#660077", "#003322", "#448800", "#55aa99", "#11dd88"],
  6479: ["#553300", "#3300cc", "#661111", "#dd1100", "#bb1177"],
  6480: ["#228822", "#ffee22", "#ff88aa", "#44bbff", "#22eebb"],
  6481: ["#cc44bb", "#33ee33", "#cccc77", "#ff9977", "#44bb00"],
  6482: ["#6666ee", "#88cccc", "#66ff88", "#448844", "#33bb55"],
  6483: ["#aa00ff", "#008844", "#bb7711", "#66dd33", "#1100bb"],
  6484: ["#0033bb", "#22aa00", "#004455", "#aa5533", "#001144"],
  6485: ["#00ff00", "#6644cc", "#aa11cc", "#66bb44", "#441111"],
  6486: ["#bbccaa", "#8800aa", "#220022", "#33ffff", "#00cc99"],
  6487: ["#dd0077", "#5599dd", "#aa44dd", "#cc44cc", "#11ee55"],
  6488: ["#cc2288", "#449911", "#77dddd", "#bb4411", "#77aa55"],
  6489: ["#aaaadd", "#9955aa", "#dd11cc", "#cc5500", "#883399"],
  6490: ["#ffaa99", "#6633bb", "#ccccaa", "#11ee77", "#ff4488"],
  6491: ["#ff9966", "#0000aa", "#33dd22", "#994466", "#557700"],
  6492: ["#55bb88", "#eebbee", "#dd88bb", "#113399", "#22ccff"],
  6493: ["#bbff33", "#4488dd", "#339944", "#003366", "#cc9955"],
  6494: ["#112266", "#66bb33", "#ee2233", "#33dd44", "#dd11dd"],
  6495: ["#dd6699", "#aa55aa", "#aaeebb", "#ee8844", "#229922"],
  6496: ["#bb55bb", "#44cc00", "#ee99cc", "#66ee00", "#667744"],
  6497: ["#335511", "#ffdd22", "#cc00aa", "#cc0033", "#6622dd"],
  6498: ["#009955", "#22ffbb", "#ff11ff", "#112288", "#0088ff"],
  6499: ["#99bb11", "#3333cc", "#11ee99", "#77ccee", "#aacc55"],
  6500: ["#44ccaa", "#aaddcc", "#774400", "#1155cc", "#ffaaff"],
  6501: ["#0099ee", "#779999", "#dd3366", "#111144", "#559966"],
  6502: ["#887700", "#442277", "#225566", "#ccee33", "#8866dd"],
  6503: ["#ff88dd", "#aa9977", "#dd3311", "#aa9966", "#1155bb"],
  6504: ["#116655", "#ddeedd", "#ee9933", "#aa55ff", "#9988ff"],
  6505: ["#cc99ff", "#ffbb22", "#aaff11", "#99eecc", "#ff66ee"],
  6506: ["#22eeee", "#66ee55", "#66bbbb", "#9944dd", "#6666cc"],
  6507: ["#556600", "#ee6699", "#663333", "#ccdd77", "#ffeeff"],
  6508: ["#00aabb", "#bb6622", "#aacc22", "#dd3300", "#33ccff"],
  6509: ["#667700", "#770033", "#ccaa00", "#55ff66", "#557733"],
  6510: ["#aa99aa", "#9988cc", "#1122ee", "#332211", "#668844"],
  6511: ["#aa6677", "#550088", "#eeeecc", "#224466", "#bb8811"],
  6512: ["#669999", "#ddbb77", "#33bb00", "#66ff11", "#777799"],
  6513: ["#dd66bb", "#0011cc", "#dd8844", "#889911", "#ddccdd"],
  6514: ["#992277", "#ddff22", "#226666", "#8833dd", "#ee22ee"],
  6515: ["#bb1177", "#77bbaa", "#227777", "#22eeaa", "#1100bb"],
  6516: ["#aa99cc", "#0099ee", "#aa22ff", "#66ee44", "#dd0088"],
  6517: ["#33bbee", "#cccc55", "#ccbbbb", "#ddff55", "#aa0011"],
  6518: ["#66ddcc", "#ee0066", "#aaff22", "#00bb22", "#2244dd"],
  6519: ["#aa99bb", "#bb44aa", "#77aaff", "#226600", "#aa0022"],
  6520: ["#22bb88", "#ddff55", "#555500", "#aacc99", "#aadd66"],
  6521: ["#dd88dd", "#66dd00", "#bb66aa", "#eeaa66", "#aa88cc"],
  6522: ["#118844", "#3355dd", "#99aa33", "#00ee00", "#6677ff"],
  6523: ["#339955", "#558866", "#77bb33", "#aaff99", "#003366"],
  6524: ["#66ddee", "#ee66aa", "#66ee88", "#112299", "#00ee33"],
  6525: ["#ffffcc", "#88ff33", "#aaff44", "#bb44cc", "#22aabb"],
  6526: ["#ff9955", "#22cc33", "#8800dd", "#cccccc", "#55bb00"],
  6527: ["#1111cc", "#aa1188", "#ee66ff", "#6677cc", "#9900ee"],
  6528: ["#aa77dd", "#eeeeaa", "#664499", "#aaee77", "#007711"],
  6529: ["#22ee11", "#66ddbb", "#3366ee", "#5533bb", "#1144aa"],
  6530: ["#ff44bb", "#aa8811", "#442299", "#ddccdd", "#dd2255"],
  6531: ["#66ffff", "#8855cc", "#bb6699", "#dd00dd", "#aadd77"],
  6532: ["#114433", "#dd6622", "#ddcc66", "#77bbaa", "#0044bb"],
  6533: ["#994466", "#44eebb", "#22ffff", "#002200", "#bb5588"],
  6534: ["#777799", "#ddeedd", "#ffbbee", "#ee9966", "#44aa22"],
  6535: ["#8822cc", "#006600", "#665555", "#44ff44", "#ee4477"],
  6536: ["#336611", "#7733aa", "#ff8822", "#33bb88", "#66ff99"],
  6537: ["#bb11dd", "#113355", "#ffee33", "#77dd66", "#55ee33"],
  6538: ["#ee8844", "#667766", "#bbcccc", "#ddeeaa", "#bb9999"],
  6539: ["#1177cc", "#223322", "#77bbaa", "#aadd55", "#aa9922"],
  6540: ["#bb9900", "#991111", "#ee5511", "#553322", "#113355"],
  6541: ["#eeffaa", "#22bbee", "#ddcc99", "#dd7766", "#224400"],
  6542: ["#9944ee", "#773322", "#221177", "#003388", "#ee3311"],
  6543: ["#6688ff", "#112288", "#880066", "#00ffcc", "#55ee99"],
  6544: ["#ffaacc", "#bbff00", "#77ffbb", "#445533", "#775566"],
  6545: ["#aa88bb", "#99ffcc", "#ffbb33", "#11aabb", "#337788"],
  6546: ["#00cc55", "#00aa77", "#eeaa77", "#9999cc", "#33cc22"],
  6547: ["#33cc11", "#dd6688", "#889900", "#33bb55", "#bb7777"],
  6548: ["#aaffee", "#7766cc", "#117766", "#6655cc", "#6622bb"],
  6549: ["#dd4433", "#000099", "#33eeff", "#00bbee", "#ee55dd"],
  6550: ["#bb8844", "#2233dd", "#ee3344", "#ee66ff", "#ff7733"],
  6551: ["#0055aa", "#554411", "#bbcc33", "#996633", "#ee7744"],
  6552: ["#bb22ff", "#331166", "#4400ff", "#11cc33", "#8855bb"],
  6553: ["#aa00ff", "#00ff77", "#55ff55", "#772277", "#887744"],
  6554: ["#aa7700", "#99dd00", "#44ff33", "#222266", "#6677bb"],
  6555: ["#00ee77", "#5533aa", "#0011bb", "#7722cc", "#ccdd88"],
  6556: ["#dd1155", "#ff44ff", "#ff2266", "#448855", "#884466"],
  6557: ["#449922", "#dd44dd", "#cc9977", "#338800", "#990044"],
  6558: ["#aa3355", "#33ffdd", "#666622", "#eeaa44", "#aa88ee"],
  6559: ["#77bbff", "#998844", "#ddffaa", "#4477aa", "#1100ff"],
  6560: ["#ff1155", "#8822ff", "#bbeeaa", "#88ff22", "#ff9955"],
  6561: ["#2211ff", "#eedd88", "#22eebb", "#0077aa", "#442244"],
  6562: ["#ff9922", "#cc33dd", "#1188aa", "#00aaff", "#773344"],
  6563: ["#99ee44", "#bbee00", "#77ee00", "#2200aa", "#dd99cc"],
  6564: ["#88ff22", "#aa4433", "#ff1177", "#444477", "#ee00bb"],
  6565: ["#440022", "#dd7766", "#559900", "#eeff88", "#0000bb"],
  6566: ["#ee77ff", "#aaff22", "#55aa77", "#113366", "#224499"],
  6567: ["#33cc00", "#33dd88", "#aa1122", "#6655dd", "#eeff00"],
  6568: ["#33cc66", "#eeddcc", "#7766aa", "#ff5500", "#774444"],
  6569: ["#88ffdd", "#bbff44", "#dd22ee", "#00bbdd", "#ddffcc"],
  6570: ["#00aa88", "#cc5544", "#aa44aa", "#cccc77", "#9900ee"],
  6571: ["#cc11cc", "#778800", "#4444cc", "#66dd77", "#337711"],
  6572: ["#dd5511", "#ee2222", "#dd1177", "#00bb00", "#88eedd"],
  6573: ["#663300", "#225511", "#553344", "#555533", "#44ffbb"],
  6574: ["#ccaa00", "#00ee11", "#ee4488", "#22ff55", "#dd00ee"],
  6575: ["#bbff66", "#ee77ee", "#4433cc", "#dd8833", "#88cc11"],
  6576: ["#002277", "#ddbbdd", "#dddd11", "#8833cc", "#008888"],
  6577: ["#aa1188", "#77eedd", "#bb0077", "#bbff88", "#bbccee"],
  6578: ["#66dd33", "#ccee11", "#229922", "#ff7744", "#3377ff"],
  6579: ["#cc3399", "#551155", "#bb6622", "#8822ee", "#1188ee"],
  6580: ["#aa00cc", "#33eecc", "#22eeaa", "#bbbbff", "#aabbff"],
  6581: ["#005533", "#779988", "#222255", "#ffee77", "#333344"],
  6582: ["#220077", "#007777", "#003300", "#880022", "#bb6688"],
  6583: ["#551199", "#55cc00", "#669988", "#00dd11", "#aa2244"],
  6584: ["#66ffff", "#aaee88", "#44cc99", "#11bb44", "#5555dd"],
  6585: ["#440033", "#cc3300", "#1100cc", "#2299ee", "#445511"],
  6586: ["#ff55ff", "#bb2266", "#332222", "#1188ee", "#88cc88"],
  6587: ["#448811", "#4444cc", "#cc55bb", "#335522", "#bbaa88"],
  6588: ["#554411", "#0077cc", "#770022", "#bb9911", "#44ee22"],
  6589: ["#22ccaa", "#3399dd", "#44ffdd", "#8833bb", "#eebb33"],
  6590: ["#00aa11", "#3388bb", "#cc8800", "#aabb33", "#223311"],
  6591: ["#aadd77", "#ffccbb", "#4400ff", "#bbeeff", "#ddaabb"],
  6592: ["#222288", "#8855ff", "#ffeeff", "#4400dd", "#3388cc"],
  6593: ["#ee7722", "#995522", "#ddff33", "#1188ff", "#ff44cc"],
  6594: ["#3300dd", "#aaccff", "#0022aa", "#aacc11", "#77cc55"],
  6595: ["#ff2211", "#77aa44", "#ee44aa", "#88cc00", "#cc3344"],
  6596: ["#cc88bb", "#44bb22", "#ff22ee", "#00bb55", "#ffeedd"],
  6597: ["#bbccdd", "#110011", "#8899cc", "#005533", "#331122"],
  6598: ["#33ccbb", "#bb1144", "#aa7766", "#bb00aa", "#88cccc"],
  6599: ["#77dd00", "#003366", "#7777aa", "#ee44ff", "#ddddcc"],
  6600: ["#2222cc", "#2222dd", "#ccaaff", "#336633", "#33bb11"],
  6601: ["#00ffdd", "#997700", "#33ff88", "#bb3322", "#7700aa"],
  6602: ["#ff88ee", "#223344", "#88aa77", "#55bb55", "#cceedd"],
  6603: ["#440055", "#4444dd", "#bb1199", "#eebb00", "#117766"],
  6604: ["#77cc22", "#229988", "#cc6633", "#0011ff", "#88aa99"],
  6605: ["#1111ee", "#77aaaa", "#ccffff", "#ee2277", "#33cc77"],
  6606: ["#228844", "#bb00ff", "#aaddff", "#8811dd", "#008833"],
  6607: ["#666633", "#0088aa", "#77dddd", "#dd9955", "#770099"],
  6608: ["#221122", "#cc0055", "#773377", "#aa4488", "#dd44bb"],
  6609: ["#dd5522", "#447766", "#001111", "#ccaaff", "#337744"],
  6610: ["#33bb33", "#77aadd", "#7788cc", "#eedd00", "#aa88ee"],
  6611: ["#772211", "#88dd00", "#ee4433", "#44bb11", "#55eeff"],
  6612: ["#ffaa11", "#5577ee", "#22ccee", "#00ddcc", "#22ff88"],
  6613: ["#33bb88", "#22ee11", "#2200dd", "#cc33aa", "#115555"],
  6614: ["#bb33cc", "#dd9999", "#882255", "#554433", "#8811aa"],
  6615: ["#118899", "#aa6688", "#661199", "#44ffaa", "#880022"],
  6616: ["#223388", "#2233aa", "#447755", "#775566", "#66bb22"],
  6617: ["#336688", "#88ddaa", "#8888cc", "#22ee55", "#2222dd"],
  6618: ["#aa0044", "#442200", "#000022", "#ccffdd", "#008833"],
  6619: ["#441133", "#aa2244", "#ccee66", "#ff2277", "#1122bb"],
  6620: ["#555533", "#aa55ee", "#33bbff", "#ff11bb", "#bbee00"],
  6621: ["#559999", "#5544ee", "#992200", "#7711ee", "#aaaaaa"],
  6622: ["#bb2211", "#eeaa33", "#dd4422", "#77ffff", "#dd1144"],
  6623: ["#2288aa", "#555544", "#1188dd", "#44aa99", "#113333"],
  6624: ["#22dd99", "#ccffff", "#2277bb", "#ee6699", "#eebbaa"],
  6625: ["#2255aa", "#3311cc", "#55ccff", "#aa7711", "#aa0011"],
  6626: ["#dd3399", "#aaccdd", "#dd4477", "#55bb55", "#eeeedd"],
  6627: ["#334422", "#66ccff", "#5500ee", "#110055", "#4400cc"],
  6628: ["#dd66dd", "#449966", "#66bbff", "#cceedd", "#6622ff"],
  6629: ["#88ddaa", "#cc9911", "#ccdd33", "#ee99aa", "#aa66dd"],
  6630: ["#ffff44", "#008855", "#0055cc", "#66cc77", "#449966"],
  6631: ["#aa9911", "#77ff99", "#aa6622", "#eeaa11", "#22dd88"],
  6632: ["#990055", "#0011ff", "#11bb66", "#770099", "#bbeecc"],
  6633: ["#0022dd", "#771188", "#bbbbee", "#ddddaa", "#558800"],
  6634: ["#bbcc44", "#ff1100", "#dd77aa", "#880033", "#334400"],
  6635: ["#aa99bb", "#eebbdd", "#669999", "#222222", "#88aa22"],
  6636: ["#dd2233", "#6666cc", "#bb4488", "#33eeaa", "#ff77cc"],
  6637: ["#ddff22", "#ee7799", "#66ee44", "#ddbbee", "#88eecc"],
  6638: ["#77bbff", "#88ccdd", "#1166ee", "#99cc88", "#ee00bb"],
  6639: ["#dd99ff", "#ee1133", "#cceecc", "#1122aa", "#994433"],
  6640: ["#888822", "#cc0033", "#884444", "#001166", "#aa1144"],
  6641: ["#99bbee", "#cc8844", "#aa1166", "#229988", "#ffaabb"],
  6642: ["#cc4411", "#ffee00", "#eeff88", "#883311", "#11aa00"],
  6643: ["#cc1144", "#dd2233", "#dd88cc", "#445500", "#ee4466"],
  6644: ["#44ff44", "#003377", "#335511", "#cc7788", "#aacc88"],
  6645: ["#660044", "#882200", "#338866", "#669911", "#cc44cc"],
  6646: ["#eecc55", "#33ddcc", "#55ccbb", "#55ff77", "#cc3388"],
  6647: ["#aa0088", "#7755bb", "#0033dd", "#33cc77", "#bb55bb"],
  6648: ["#eeffaa", "#557711", "#bbaa44", "#aa6644", "#8899aa"],
  6649: ["#77ddaa", "#dd77aa", "#993355", "#553377", "#aa2200"],
  6650: ["#55ffdd", "#cc6699", "#114444", "#ccbb44", "#aaeeee"],
  6651: ["#aa22cc", "#773377", "#33aa66", "#bbdd00", "#aaee88"],
  6652: ["#11aaff", "#885599", "#dd1122", "#ff88bb", "#33bbdd"],
  6653: ["#11bb66", "#ddaa00", "#778888", "#664455", "#66ee11"],
  6654: ["#447777", "#888822", "#aaff66", "#4488ff", "#ffee33"],
  6655: ["#aa88cc", "#ee7777", "#117799", "#227799", "#ff9922"],
  6656: ["#cc7755", "#aaaa33", "#7766bb", "#aa88dd", "#22cc44"],
  6657: ["#eebb22", "#33ff22", "#221122", "#aadd33", "#445599"],
  6658: ["#7744dd", "#6655aa", "#33eecc", "#22cc33", "#55bb77"],
  6659: ["#ee22cc", "#008800", "#995533", "#aa3322", "#eeaabb"],
  6660: ["#4499bb", "#996677", "#7733ff", "#665588", "#11ee44"],
  6661: ["#bb5566", "#ff0055", "#bbbb33", "#666655", "#5566aa"],
  6662: ["#cc55cc", "#557700", "#dd2200", "#dd8822", "#ff88ee"],
  6663: ["#aa00cc", "#11aa99", "#11bbdd", "#4400bb", "#11ee22"],
  6664: ["#8888bb", "#ee6611", "#ddcc66", "#aaee44", "#8899bb"],
  6665: ["#664455", "#448877", "#116688", "#338811", "#004499"],
  6666: ["#662222", "#2255cc", "#339922", "#8811dd", "#8833ee"],
  6667: ["#00aa77", "#ddbb99", "#eeffdd", "#1177aa", "#44eebb"],
  6668: ["#77ddcc", "#ffaa99", "#335599", "#ffcc00", "#ee00dd"],
  6669: ["#007722", "#997766", "#ff11dd", "#55ffbb", "#44cc00"],
  6670: ["#ccee55", "#114433", "#bb55aa", "#ffcc77", "#ff0000"],
  6671: ["#5588dd", "#ccbb22", "#4488ee", "#bb2288", "#ee11ee"],
  6672: ["#886600", "#eeddff", "#dd2255", "#bbee99", "#ff4455"],
  6673: ["#ff4488", "#ccdd55", "#77bbff", "#990077", "#cccc11"],
  6674: ["#773366", "#aa55bb", "#ee7766", "#ee1188", "#559911"],
  6675: ["#4411cc", "#99bb33", "#eeeeaa", "#aa6677", "#99ffdd"],
  6676: ["#1133dd", "#5588dd", "#1122bb", "#33aacc", "#55eeaa"],
  6677: ["#aadd55", "#ee9977", "#554422", "#aa8811", "#2233bb"],
  6678: ["#11cc00", "#aabbbb", "#6600ff", "#77ddee", "#777700"],
  6679: ["#88aa66", "#ccee22", "#998800", "#1133bb", "#664422"],
  6680: ["#9966dd", "#00aa22", "#aa9900", "#77bbbb", "#bb11dd"],
  6681: ["#228877", "#5511aa", "#2233aa", "#9933cc", "#44ccaa"],
  6682: ["#3377ee", "#777722", "#11aabb", "#ccccaa", "#44cc55"],
  6683: ["#00eecc", "#cceedd", "#551100", "#ff0066", "#9900cc"],
  6684: ["#3388ff", "#eeddff", "#ff7777", "#1122bb", "#aa99aa"],
  6685: ["#bb9977", "#ee44ee", "#dd8855", "#ff33dd", "#ccdd44"],
  6686: ["#dd4499", "#441177", "#3344dd", "#11ff00", "#aa5500"],
  6687: ["#55eeff", "#11ff00", "#eeee88", "#ffbbbb", "#aa2266"],
  6688: ["#11cc88", "#dd5522", "#ee9944", "#2211ee", "#99ddee"],
  6689: ["#1155dd", "#eecc11", "#1100dd", "#ffbb55", "#dd55ee"],
  6690: ["#447788", "#6688bb", "#ee9955", "#99ffcc", "#991100"],
  6691: ["#3399bb", "#5555aa", "#ccddee", "#338866", "#aa1122"],
  6692: ["#dd9933", "#339966", "#0011aa", "#bb66ee", "#770022"],
  6693: ["#005500", "#aa7711", "#7744aa", "#008866", "#ff8888"],
  6694: ["#669999", "#ffaa77", "#999900", "#cc44ff", "#ee2211"],
  6695: ["#ffcc22", "#bb22ff", "#6699aa", "#11ccee", "#aaff00"],
  6696: ["#22bb66", "#447755", "#88dd77", "#bb9944", "#11ff77"],
  6697: ["#aaaa00", "#114466", "#ccbbcc", "#442288", "#ddcc22"],
  6698: ["#ffaaaa", "#bb44ee", "#550099", "#229988", "#0088ee"],
  6699: ["#881166", "#0099bb", "#554433", "#ffcc77", "#dd9944"],
  6700: ["#eebbee", "#8844cc", "#336644", "#ff99ff", "#6699aa"],
  6701: ["#00ee11", "#aa3355", "#0044aa", "#ee33aa", "#552299"],
  6702: ["#bb5544", "#dd6655", "#33ee00", "#991144", "#662244"],
  6703: ["#ee9955", "#5511dd", "#bb99bb", "#5588ee", "#dd5544"],
  6704: ["#119900", "#221100", "#55cc11", "#996644", "#445566"],
  6705: ["#1155dd", "#ff6611", "#ee2222", "#88ee33", "#001111"],
  6706: ["#2244ff", "#ffdd11", "#bbbbee", "#55bb11", "#441122"],
  6707: ["#444411", "#cc8899", "#bb6699", "#55ddee", "#336677"],
  6708: ["#88aa11", "#227755", "#4455cc", "#88aa44", "#aa11ff"],
  6709: ["#ff3311", "#ddaa99", "#eecc77", "#6600ff", "#bb44aa"],
  6710: ["#3333cc", "#aa0088", "#9911ff", "#9944ff", "#cc66ff"],
  6711: ["#ffaa77", "#337766", "#5533ee", "#336677", "#ff33aa"],
  6712: ["#225577", "#22ffaa", "#bb88bb", "#ffddee", "#119944"],
  6713: ["#bb33dd", "#aa22dd", "#55ffdd", "#ff22ff", "#eeaa55"],
  6714: ["#11eeff", "#99cccc", "#44ddaa", "#223344", "#117777"],
  6715: ["#11ddee", "#99bbdd", "#5544ff", "#113344", "#bb88ff"],
  6716: ["#443300", "#997777", "#7766ee", "#eecc77", "#ccff66"],
  6717: ["#ee0077", "#88bbdd", "#221166", "#aa0022", "#88ddaa"],
  6718: ["#00ee55", "#661166", "#bbeedd", "#ff5522", "#446633"],
  6719: ["#ee4444", "#88aaee", "#551199", "#ff8899", "#880000"],
  6720: ["#886677", "#004444", "#007799", "#99eeaa", "#ccee66"],
  6721: ["#22ddbb", "#dd55ff", "#ff8844", "#8877cc", "#1100cc"],
  6722: ["#332233", "#0011ff", "#ddbb44", "#88dd44", "#22ee88"],
  6723: ["#ccbb66", "#00ff77", "#bb5566", "#44ee77", "#ff2266"],
  6724: ["#883355", "#bb88ff", "#22ee22", "#7733ff", "#ffdd77"],
  6725: ["#77ddcc", "#888822", "#ff8811", "#8866ee", "#88eecc"],
  6726: ["#556600", "#cc88dd", "#330055", "#4444bb", "#ee5599"],
  6727: ["#772299", "#00ee77", "#227700", "#119999", "#44ccbb"],
  6728: ["#9999dd", "#996699", "#11ff22", "#775555", "#77cc44"],
  6729: ["#889977", "#bb66dd", "#66cc55", "#552255", "#bb8866"],
  6730: ["#003377", "#6644ff", "#005511", "#883300", "#0000cc"],
  6731: ["#333300", "#993344", "#778866", "#88dd44", "#447788"],
  6732: ["#ddaa88", "#332200", "#1155bb", "#551111", "#cc99bb"],
  6733: ["#ccbb88", "#669988", "#aa7766", "#11aa99", "#2233ff"],
  6734: ["#bbffcc", "#cc3388", "#333311", "#3322ff", "#99ffff"],
  6735: ["#99ff88", "#887766", "#dd8866", "#335599", "#cc11aa"],
  6736: ["#cc88aa", "#886666", "#6677aa", "#22aa22", "#ee66cc"],
  6737: ["#eeffff", "#559922", "#ffaa88", "#005511", "#dd00ee"],
  6738: ["#226655", "#aaeecc", "#555544", "#889999", "#bb55ff"],
  6739: ["#6655aa", "#44bb22", "#ee99ee", "#0066cc", "#220055"],
  6740: ["#77aaff", "#1111cc", "#66bb11", "#99aa33", "#77dd77"],
  6741: ["#ee3366", "#33bbaa", "#442222", "#110088", "#003355"],
  6742: ["#885566", "#001155", "#ff6677", "#ee9900", "#993300"],
  6743: ["#aaee22", "#aa0022", "#cc8877", "#8855ff", "#66cc55"],
  6744: ["#889966", "#44ff00", "#776622", "#887733", "#6633ff"],
  6745: ["#ee22ff", "#5599cc", "#cc8866", "#881199", "#889944"],
  6746: ["#55dd77", "#991133", "#cc6677", "#11ff22", "#22ff99"],
  6747: ["#bb4400", "#77aa00", "#ff2255", "#22aa55", "#55bbee"],
  6748: ["#44dd99", "#66ee55", "#bb1155", "#11aacc", "#11ffee"],
  6749: ["#77ff11", "#bb00cc", "#aa5533", "#226655", "#11dd11"],
  6750: ["#22ddee", "#669922", "#aabb22", "#77ff44", "#88ccaa"],
  6751: ["#2266ff", "#cc6600", "#ffdd22", "#44ee22", "#dd9911"],
  6752: ["#dd4433", "#aadd00", "#bbff11", "#5566ee", "#cceebb"],
  6753: ["#88aacc", "#aa6655", "#6655cc", "#2277ff", "#88bbaa"],
  6754: ["#115566", "#0088ee", "#99dd33", "#223366", "#88ddee"],
  6755: ["#5566bb", "#993399", "#cc2222", "#77ff66", "#aabb33"],
  6756: ["#111166", "#dd3388", "#882233", "#66ccee", "#663344"],
  6757: ["#993399", "#9944bb", "#88ee11", "#5544dd", "#991166"],
  6758: ["#ddee22", "#aa0099", "#eeaa22", "#662299", "#0044dd"],
  6759: ["#77ee22", "#1199ff", "#447722", "#77dd88", "#55ddcc"],
  6760: ["#bb00ff", "#994455", "#eeff44", "#00aa88", "#117766"],
  6761: ["#aaddee", "#aa00bb", "#883300", "#66ee44", "#1177bb"],
  6762: ["#44dd00", "#220022", "#ee5544", "#0066bb", "#883355"],
  6763: ["#667766", "#cc11bb", "#005566", "#aa5588", "#661133"],
  6764: ["#dd5577", "#2288ee", "#00bb99", "#111122", "#99ee44"],
  6765: ["#665566", "#44dd88", "#228877", "#ddbb33", "#ccaa88"],
  6766: ["#008877", "#ddbb00", "#eeaaaa", "#bb2244", "#779955"],
  6767: ["#aaaaee", "#443377", "#446677", "#aa8866", "#1111cc"],
  6768: ["#550066", "#cc99ee", "#1133dd", "#2299bb", "#bbcc88"],
  6769: ["#bb22bb", "#cc77bb", "#eeaa77", "#884466", "#112277"],
  6770: ["#005566", "#dd00cc", "#4488cc", "#dd66aa", "#ff6644"],
  6771: ["#660066", "#cc22ff", "#880066", "#bbcccc", "#aa33ff"],
  6772: ["#77bbdd", "#1144ff", "#771122", "#662233", "#0011ee"],
  6773: ["#ee8844", "#88bb88", "#004422", "#dd6611", "#cceeff"],
  6774: ["#ddbb11", "#44bb77", "#ff7755", "#9977ee", "#ee33ff"],
  6775: ["#008866", "#ee5522", "#662288", "#88bbff", "#88dd44"],
  6776: ["#ff5511", "#bb0000", "#ee33cc", "#99ff66", "#ddcc33"],
  6777: ["#cc8800", "#996655", "#ff6644", "#88bb33", "#008811"],
  6778: ["#4400aa", "#dd8844", "#aaff66", "#77ff33", "#ee6666"],
  6779: ["#334411", "#bb4477", "#8844bb", "#aa77ee", "#99bb55"],
  6780: ["#ddccbb", "#2200ff", "#22bb66", "#66cc66", "#cc2299"],
  6781: ["#113388", "#446622", "#77ee00", "#3300cc", "#4433dd"],
  6782: ["#5588ff", "#44ffaa", "#eebbee", "#662277", "#88bbff"],
  6783: ["#aacc55", "#00bbdd", "#bbaa99", "#77ddaa", "#002211"],
  6784: ["#8899ff", "#776655", "#eebb33", "#aa5566", "#ee2200"],
  6785: ["#22bb55", "#9988dd", "#3300ee", "#bbaaff", "#2255dd"],
  6786: ["#aa99bb", "#eeaa66", "#6699aa", "#aa2244", "#886622"],
  6787: ["#558811", "#665500", "#887711", "#445522", "#118822"],
  6788: ["#7755dd", "#ee00dd", "#99aa11", "#ff22aa", "#227777"],
  6789: ["#bb1155", "#ddee55", "#006666", "#003333", "#33ff88"],
  6790: ["#ff9988", "#771166", "#991199", "#0044cc", "#cc7744"],
  6791: ["#33ff77", "#336600", "#bbaa44", "#991199", "#22cc99"],
  6792: ["#eeffbb", "#eecc99", "#aa4422", "#ccff99", "#9900bb"],
  6793: ["#bb22cc", "#668800", "#55ddbb", "#ff11aa", "#779955"],
  6794: ["#ddaa88", "#77cc55", "#ee88cc", "#dd77ff", "#5511ff"],
  6795: ["#667755", "#ff55bb", "#bb3333", "#aa2277", "#ffee33"],
  6796: ["#776688", "#993366", "#bb7788", "#ffaa44", "#2266dd"],
  6797: ["#0088dd", "#44bb88", "#ff77bb", "#003366", "#5577bb"],
  6798: ["#bb2211", "#3355aa", "#cc2288", "#bb4455", "#99ee99"],
  6799: ["#6611dd", "#aa7722", "#cc66cc", "#ddaabb", "#779933"],
  6800: ["#cc33dd", "#1100aa", "#ff4455", "#55eeaa", "#115544"],
  6801: ["#cc2200", "#bb7777", "#1188dd", "#003300", "#88dd00"],
  6802: ["#4455ee", "#77aa88", "#99cc33", "#ee88dd", "#eebbcc"],
  6803: ["#ffee33", "#44cc88", "#77dd11", "#66dd22", "#11ccff"],
  6804: ["#77bb33", "#116633", "#33ffff", "#ffee00", "#aaaaee"],
  6805: ["#66dd00", "#cc99ee", "#bbdd44", "#aa11dd", "#779966"],
  6806: ["#330066", "#88bb33", "#008877", "#77ff77", "#55bbee"],
  6807: ["#aa0088", "#aa5533", "#bbcc33", "#5533dd", "#77ee55"],
  6808: ["#223355", "#0099bb", "#22dd22", "#dd22bb", "#889900"],
  6809: ["#aadddd", "#7744cc", "#ccbb77", "#33aa44", "#3333bb"],
  6810: ["#77ff44", "#2266dd", "#cc77ee", "#ccbb33", "#0088ff"],
  6811: ["#cc3377", "#44dd99", "#eeff77", "#880044", "#aa88dd"],
  6812: ["#9988bb", "#cceeaa", "#cc9999", "#551177", "#228899"],
  6813: ["#aa3399", "#001122", "#33aaff", "#44ccbb", "#dd77dd"],
  6814: ["#ddbbdd", "#ff5566", "#44ddee", "#bb1188", "#aaee44"],
  6815: ["#bb00bb", "#99cc77", "#221144", "#88ccee", "#225522"],
  6816: ["#228844", "#993366", "#ffeecc", "#aa3344", "#11aa00"],
  6817: ["#dd6633", "#dd22dd", "#66ee00", "#88eecc", "#1188ee"],
  6818: ["#22cc99", "#33bbbb", "#ffdd00", "#2200dd", "#5511dd"],
  6819: ["#22ff11", "#330044", "#4433dd", "#006666", "#aadd00"],
  6820: ["#88eedd", "#33ff88", "#dd9900", "#cc9922", "#440000"],
  6821: ["#5566dd", "#77bb11", "#6600aa", "#3399dd", "#66eeee"],
  6822: ["#ee8899", "#66ff55", "#bb99ee", "#22aaff", "#aa99aa"],
  6823: ["#bb88dd", "#77ff11", "#eeaa77", "#ee66bb", "#ee6600"],
  6824: ["#660099", "#bb22ff", "#ccaa22", "#aa1111", "#8844aa"],
  6825: ["#44cc11", "#7799ee", "#aaeebb", "#eeee44", "#9944ee"],
  6826: ["#3322ff", "#cc22aa", "#441199", "#5555ff", "#22bb44"],
  6827: ["#bbbb77", "#8800bb", "#661144", "#445511", "#dd44ff"],
  6828: ["#779944", "#55aacc", "#2288ee", "#cc00ff", "#eeaa66"],
  6829: ["#2200dd", "#887722", "#cc33ee", "#aa55ff", "#667722"],
  6830: ["#882244", "#aa99dd", "#bb55dd", "#00ddaa", "#665544"],
  6831: ["#aa44ff", "#22ee00", "#330022", "#cc3388", "#dd99dd"],
  6832: ["#00bb99", "#dd0077", "#bbeeee", "#222222", "#881133"],
  6833: ["#ccdd66", "#cc5522", "#dd77ee", "#3311ee", "#ffee33"],
  6834: ["#ff3399", "#332200", "#11ee33", "#88eebb", "#ee2244"],
  6835: ["#990099", "#ff66ee", "#cc55ee", "#bbdd33", "#443300"],
  6836: ["#117755", "#ddee99", "#885577", "#447788", "#662222"],
  6837: ["#ee33bb", "#aadd88", "#00ee00", "#445577", "#88ee22"],
  6838: ["#223377", "#442211", "#ffddaa", "#009922", "#77ee33"],
  6839: ["#226677", "#2299ff", "#003311", "#330000", "#44dddd"],
  6840: ["#cc7777", "#cc44ff", "#ff1100", "#448899", "#228888"],
  6841: ["#228877", "#7733ff", "#11dddd", "#55ff99", "#220099"],
  6842: ["#eeaaaa", "#882288", "#dd4488", "#ee55cc", "#665588"],
  6843: ["#993377", "#5555aa", "#1100dd", "#ee4455", "#44ee33"],
  6844: ["#336677", "#663322", "#115588", "#8822ee", "#33ccaa"],
  6845: ["#331188", "#bbee44", "#ff2288", "#bbeeaa", "#551133"],
  6846: ["#ee5544", "#331155", "#3344ff", "#cc7777", "#66eecc"],
  6847: ["#aa2244", "#3355cc", "#ff9966", "#ffff88", "#88dd00"],
  6848: ["#33eeaa", "#00ee88", "#112233", "#0088ee", "#00cc11"],
  6849: ["#dd33cc", "#44eeff", "#11ffee", "#dd1155", "#22aa66"],
  6850: ["#22ff55", "#aaaa55", "#006688", "#66bbcc", "#cc7700"],
  6851: ["#6688ff", "#ee33aa", "#dd33dd", "#ffdd33", "#119966"],
  6852: ["#aaddbb", "#bb9944", "#bb33cc", "#1177dd", "#8833bb"],
  6853: ["#336688", "#77ffaa", "#66ccaa", "#22ff00", "#111199"],
  6854: ["#ff11dd", "#4411cc", "#ccee00", "#aa3344", "#77bbaa"],
  6855: ["#882200", "#771166", "#2233aa", "#881188", "#9988dd"],
  6856: ["#ff9922", "#33eedd", "#33aa11", "#66ff55", "#ee00ff"],
  6857: ["#998811", "#cceedd", "#99ccdd", "#bb22ff", "#115500"],
  6858: ["#2244dd", "#991122", "#ee44bb", "#002277", "#44cc33"],
  6859: ["#ee6677", "#9988cc", "#bb4488", "#5599ff", "#ff9944"],
  6860: ["#9922ee", "#551188", "#eeaacc", "#bb6699", "#ddbb55"],
  6861: ["#66aadd", "#774400", "#33ff33", "#55ffcc", "#ffee44"],
  6862: ["#66dd77", "#8800bb", "#990022", "#ddeebb", "#bb8877"],
  6863: ["#887755", "#00ff99", "#ccee44", "#667722", "#eeaaff"],
  6864: ["#ccdd33", "#aa2299", "#cc00bb", "#223399", "#772255"],
  6865: ["#999944", "#22ddbb", "#335599", "#ff1100", "#663311"],
  6866: ["#dd4433", "#bbee33", "#ff88ff", "#bb1144", "#22cc22"],
  6867: ["#33dd00", "#001122", "#7799aa", "#ff3377", "#556600"],
  6868: ["#dd1111", "#ee44ff", "#aaee33", "#ff1155", "#8888ee"],
  6869: ["#ffffee", "#882288", "#ff8800", "#6666ff", "#dd1155"],
  6870: ["#22ee00", "#aa0011", "#33ee11", "#00aaee", "#22cc77"],
  6871: ["#33dd55", "#ee00ff", "#3355aa", "#bbaa22", "#dd0055"],
  6872: ["#22bbff", "#223311", "#cccccc", "#887799", "#ffff33"],
  6873: ["#cc5500", "#99ee00", "#334422", "#55cc00", "#9966ff"],
  6874: ["#4400cc", "#ddaa11", "#229966", "#775544", "#00cc99"],
  6875: ["#8800ee", "#7799ee", "#4444bb", "#22dd33", "#eeee33"],
  6876: ["#66ff22", "#ffccaa", "#3344ee", "#ee2288", "#55ee88"],
  6877: ["#99cc77", "#884499", "#889977", "#aa5588", "#ccdd22"],
  6878: ["#880088", "#882244", "#bbdd99", "#0077dd", "#dd3366"],
  6879: ["#221188", "#991166", "#cc55aa", "#dd1122", "#1199bb"],
  6880: ["#99cc66", "#55bb33", "#008877", "#99ddbb", "#aaff22"],
  6881: ["#0022aa", "#ffee22", "#aaaaee", "#6688cc", "#001188"],
  6882: ["#aa77dd", "#bb7799", "#bb3333", "#55ff33", "#115500"],
  6883: ["#bb00bb", "#bbff55", "#ee8877", "#aa7799", "#770044"],
  6884: ["#55dd22", "#88ee77", "#22ee22", "#666666", "#118800"],
  6885: ["#440088", "#6611ff", "#aa88ff", "#775511", "#88ccee"],
  6886: ["#cc4477", "#664422", "#ffeeaa", "#eedd55", "#996655"],
  6887: ["#dd3311", "#00ffdd", "#55ffff", "#cceeff", "#5588bb"],
  6888: ["#22cc11", "#229922", "#cccccc", "#44ff66", "#22cc99"],
  6889: ["#ddffaa", "#22bb88", "#770033", "#7733bb", "#aa1199"],
  6890: ["#55ccaa", "#115577", "#cc22ee", "#22aa33", "#5555ee"],
  6891: ["#ccffcc", "#4400cc", "#0055bb", "#ee44cc", "#66bb88"],
  6892: ["#8800aa", "#0055aa", "#111100", "#22ee11", "#7744ee"],
  6893: ["#113377", "#ccbbcc", "#22ccaa", "#0044dd", "#00dd00"],
  6894: ["#bb4499", "#995566", "#bb33ee", "#ddffff", "#882211"],
  6895: ["#110033", "#882200", "#aa4400", "#aa7777", "#22bb33"],
  6896: ["#7711dd", "#aabb22", "#22dd99", "#ee4411", "#993311"],
  6897: ["#440011", "#bb33dd", "#11cc00", "#998866", "#77ccaa"],
  6898: ["#993333", "#999988", "#dd2211", "#ff3333", "#22ee99"],
  6899: ["#999955", "#99cc88", "#ddbb77", "#bb55bb", "#cc4499"],
  6900: ["#ffbbdd", "#2277ff", "#33eeff", "#ff4433", "#33aa66"],
  6901: ["#3322bb", "#aaaa55", "#ee6622", "#bb00cc", "#9977dd"],
  6902: ["#bb0077", "#44cc44", "#5588bb", "#cccc44", "#dd0000"],
  6903: ["#331122", "#774488", "#884455", "#330088", "#cc1155"],
  6904: ["#dd11ee", "#000066", "#eebb22", "#7799dd", "#44dd99"],
  6905: ["#bbee33", "#44cc33", "#bb66dd", "#88ffee", "#22ddaa"],
  6906: ["#66bbdd", "#88cc00", "#664422", "#5511cc", "#ee1199"],
  6907: ["#669988", "#ccbb77", "#ff9955", "#886611", "#ee9944"],
  6908: ["#117799", "#ffff55", "#669955", "#44ccbb", "#aa8866"],
  6909: ["#bbaadd", "#3344cc", "#5511cc", "#88ddcc", "#ffff88"],
  6910: ["#9911ff", "#aa00dd", "#ff5566", "#883344", "#cc6688"],
  6911: ["#dd33ee", "#77cc00", "#44aa88", "#00dd22", "#aabbcc"],
  6912: ["#cc00bb", "#001111", "#ffbbbb", "#772200", "#7733ee"],
  6913: ["#bbff66", "#44ff22", "#ffffee", "#aa4466", "#998855"],
  6914: ["#3366bb", "#110088", "#aaaa11", "#995511", "#993333"],
  6915: ["#ff9933", "#cc8877", "#aa0022", "#2288cc", "#dd9966"],
  6916: ["#44bb99", "#cc6600", "#99ee33", "#77aacc", "#cc6611"],
  6917: ["#66bb77", "#dd2233", "#ee88cc", "#bb99bb", "#000055"],
  6918: ["#882288", "#ff0044", "#bb4411", "#008866", "#cc1166"],
  6919: ["#ffdd55", "#aaaaff", "#33dd22", "#888888", "#bb6655"],
  6920: ["#4422aa", "#aa3388", "#bb55ff", "#116677", "#ee8844"],
  6921: ["#556699", "#99bb11", "#887744", "#ddccee", "#2299cc"],
  6922: ["#8855dd", "#5511cc", "#1122dd", "#aa66cc", "#7711aa"],
  6923: ["#66ffaa", "#bb7722", "#bbaaff", "#99ffff", "#006644"],
  6924: ["#993399", "#33aa00", "#9944dd", "#88dd22", "#772299"],
  6925: ["#003333", "#22bb99", "#555577", "#334499", "#2277dd"],
  6926: ["#6611ee", "#0033dd", "#113377", "#77bb22", "#99cc11"],
  6927: ["#00ee88", "#88ff11", "#ffff44", "#00bb00", "#6666bb"],
  6928: ["#11ee66", "#ccaa99", "#00bb44", "#2222ee", "#aa11bb"],
  6929: ["#221111", "#225588", "#77bb77", "#44ccdd", "#aa9955"],
  6930: ["#4488ff", "#1100aa", "#00cc11", "#3388aa", "#ee3333"],
  6931: ["#cc0055", "#cc55dd", "#116677", "#22ff00", "#9977bb"],
  6932: ["#229933", "#221166", "#dd5555", "#0055dd", "#00bb33"],
  6933: ["#333322", "#8866ee", "#221199", "#6655ee", "#ffdd44"],
  6934: ["#ccdd77", "#eeaa88", "#773355", "#ffff99", "#22aacc"],
  6935: ["#ff0044", "#3333bb", "#992211", "#4466bb", "#00ff11"],
  6936: ["#9911cc", "#8844ee", "#dd1188", "#9933bb", "#bb22aa"],
  6937: ["#9900ff", "#eebb00", "#441122", "#ff3377", "#7799bb"],
  6938: ["#116633", "#ddaa33", "#882299", "#882255", "#dd7722"],
  6939: ["#440000", "#00cc22", "#ff7755", "#33eecc", "#66bb00"],
  6940: ["#55bb88", "#ccff33", "#8822cc", "#1155cc", "#0066aa"],
  6941: ["#ee0000", "#779955", "#2211aa", "#aaeebb", "#dd9944"],
  6942: ["#33aacc", "#dd5544", "#eeaaee", "#55bb99", "#113322"],
  6943: ["#00ee33", "#dd9966", "#aa7711", "#cc77cc", "#6644bb"],
  6944: ["#dd44ee", "#bbccee", "#cc33cc", "#ddee44", "#5500bb"],
  6945: ["#99dd88", "#3388aa", "#99ffaa", "#228855", "#ffff88"],
  6946: ["#dd6666", "#334444", "#0077cc", "#6611ff", "#447777"],
  6947: ["#99aa44", "#444488", "#881199", "#bbcc11", "#00dd11"],
  6948: ["#883322", "#88eeaa", "#1133ff", "#119911", "#33ff99"],
  6949: ["#bb0077", "#ff3344", "#cccc11", "#ff33dd", "#bb4444"],
  6950: ["#667700", "#117799", "#2211bb", "#bb77dd", "#9944ff"],
  6951: ["#332244", "#3366cc", "#1177bb", "#88dd44", "#5511cc"],
  6952: ["#66bbcc", "#4477dd", "#ddbb44", "#00aacc", "#ff77bb"],
  6953: ["#4455cc", "#2233bb", "#44ffaa", "#eedd99", "#cc44dd"],
  6954: ["#99dddd", "#ff1188", "#33aabb", "#225599", "#00ffff"],
  6955: ["#ee1100", "#bb1199", "#dd4433", "#5522cc", "#ff5599"],
  6956: ["#bb6622", "#66dd99", "#330077", "#22dd55", "#ddaa99"],
  6957: ["#663377", "#883355", "#ee6644", "#aa1100", "#99ff66"],
  6958: ["#ee2233", "#cc2288", "#88ee11", "#2200ee", "#44bbee"],
  6959: ["#553399", "#880000", "#cc77ee", "#99aa66", "#7700dd"],
  6960: ["#7700bb", "#dd6611", "#666688", "#222233", "#225544"],
  6961: ["#55ccee", "#66dd77", "#778866", "#66ff55", "#339966"],
  6962: ["#4477ff", "#993311", "#113344", "#4444ff", "#aa77cc"],
  6963: ["#33bb22", "#cccc33", "#bbddff", "#22aa77", "#bbffaa"],
  6964: ["#cc11ff", "#ff5599", "#117722", "#666600", "#ccddff"],
  6965: ["#cc3333", "#cc2222", "#995599", "#bb9955", "#bb00aa"],
  6966: ["#cc00cc", "#bb5522", "#44ee22", "#559922", "#22dd77"],
  6967: ["#1155bb", "#dd5599", "#665577", "#222299", "#ff2200"],
  6968: ["#cceedd", "#1111ee", "#774499", "#cccccc", "#ff7700"],
  6969: ["#552255", "#111166", "#777766", "#ff5522", "#cceedd"],
  6970: ["#ddee44", "#ddeeee", "#eedddd", "#551188", "#226622"],
  6971: ["#aabbbb", "#ddee66", "#4422ff", "#228888", "#eecc33"],
  6972: ["#112277", "#0033ff", "#1111aa", "#dd8866", "#3344cc"],
  6973: ["#66bb00", "#554477", "#8833cc", "#331100", "#44aa33"],
  6974: ["#aadd88", "#bb1199", "#114477", "#dd66ff", "#ff7722"],
  6975: ["#772299", "#77ee33", "#99ee11", "#ee33cc", "#000033"],
  6976: ["#aa99ee", "#223377", "#886611", "#66ff99", "#1122ff"],
  6977: ["#44ff22", "#88aaaa", "#dd1199", "#88ffcc", "#88bb77"],
  6978: ["#882233", "#aa0033", "#bb9999", "#888800", "#cc6666"],
  6979: ["#448877", "#44aa55", "#ffeebb", "#6622dd", "#4499aa"],
  6980: ["#77bb55", "#bb8822", "#ffaadd", "#44dd22", "#337722"],
  6981: ["#5599ff", "#ff44bb", "#44ff88", "#aaeedd", "#aaee88"],
  6982: ["#ffcc99", "#44dddd", "#dd99aa", "#ccbb99", "#004411"],
  6983: ["#6699cc", "#bb1177", "#ccbb66", "#119955", "#88cc11"],
  6984: ["#33ffdd", "#449999", "#bb5577", "#eebb99", "#00bb22"],
  6985: ["#337788", "#99ee88", "#0033ff", "#99dd00", "#442277"],
  6986: ["#ff7755", "#aa0055", "#771177", "#dd77bb", "#dd1144"],
  6987: ["#eecc88", "#ffdd99", "#ff4466", "#334422", "#554444"],
  6988: ["#11ffaa", "#5544ff", "#77ee11", "#aa9933", "#2211dd"],
  6989: ["#66bbaa", "#dddd55", "#003388", "#22ff99", "#22bbee"],
  6990: ["#ffbb33", "#ddcc99", "#0022bb", "#1111dd", "#886655"],
  6991: ["#777755", "#bbdd11", "#009977", "#55cc11", "#bb99dd"],
  6992: ["#ffbb66", "#7700ff", "#ee6666", "#22ee99", "#558811"],
  6993: ["#bb8888", "#22ddbb", "#668811", "#11bb11", "#99ff33"],
  6994: ["#bb4444", "#cc1166", "#1199cc", "#eecc55", "#44ccbb"],
  6995: ["#aa4444", "#ee66aa", "#55cc33", "#33aa00", "#aa22dd"],
  6996: ["#00ff22", "#66ee44", "#888877", "#773366", "#ee00ff"],
  6997: ["#ccffbb", "#113333", "#88dd44", "#0022ff", "#22ff66"],
  6998: ["#3399bb", "#aaffff", "#3322aa", "#9999bb", "#aa9955"],
  6999: ["#557722", "#bb55ee", "#eeaacc", "#220000", "#886699"],
  7000: ["#5588ff", "#990011", "#77ff00", "#ccee44", "#dd4411"],
  7001: ["#44cc11", "#991199", "#33eeee", "#ff99ff", "#dd88bb"],
  7002: ["#dd77ee", "#1199ee", "#00bbff", "#bb00dd", "#8811ee"],
  7003: ["#66aa33", "#dd4400", "#ff2266", "#11bb44", "#880022"],
  7004: ["#dd9900", "#ff11aa", "#aa9911", "#00aa11", "#5511ff"],
  7005: ["#ffbbcc", "#dd9944", "#6644ff", "#22ff44", "#22cc99"],
  7006: ["#55ccbb", "#885555", "#11bbee", "#665577", "#bbff44"],
  7007: ["#443366", "#33cccc", "#eeeecc", "#5522ff", "#118855"],
  7008: ["#eebbdd", "#ffffaa", "#ccdd66", "#bb5500", "#22bb77"],
  7009: ["#ffbb44", "#99ff11", "#3355dd", "#777722", "#998833"],
  7010: ["#cceeff", "#ddff88", "#cc8822", "#222200", "#ffff99"],
  7011: ["#aa4400", "#55ffbb", "#447755", "#bbffdd", "#111133"],
  7012: ["#aaaa55", "#998844", "#ff7711", "#664433", "#aa7788"],
  7013: ["#dd4499", "#0088bb", "#bb4455", "#99dd99", "#cc44ff"],
  7014: ["#00bb77", "#22ccee", "#ff1111", "#00dd22", "#33ddbb"],
  7015: ["#000022", "#11ccff", "#88bbbb", "#55ff77", "#bb6611"],
  7016: ["#339933", "#dd66dd", "#335522", "#ffee44", "#88ff11"],
  7017: ["#554422", "#00bb88", "#44ccbb", "#9977dd", "#dd4488"],
  7018: ["#cc00cc", "#2244aa", "#99cc44", "#88bb55", "#aacc33"],
  7019: ["#331166", "#0055bb", "#7700cc", "#66cc33", "#ddff66"],
  7020: ["#339988", "#cc8877", "#1166cc", "#662288", "#ddaa33"],
  7021: ["#cc66ee", "#99dd44", "#cc4400", "#ee2233", "#331188"],
  7022: ["#11ccdd", "#224433", "#3344aa", "#44aa88", "#447733"],
  7023: ["#8844aa", "#4411ee", "#66aaaa", "#aaee77", "#33bb22"],
  7024: ["#bb00ee", "#66aadd", "#554400", "#ffee33", "#222211"],
  7025: ["#eeee88", "#11cc00", "#00ff33", "#66bb66", "#5544bb"],
  7026: ["#6633cc", "#aa11ee", "#eeccaa", "#888822", "#669977"],
  7027: ["#ff0011", "#eeaa00", "#cc55cc", "#770044", "#55aaee"],
  7028: ["#ee5599", "#2299bb", "#ccaa33", "#9999dd", "#339922"],
  7029: ["#1166aa", "#bb7711", "#999933", "#bb99aa", "#99ff55"],
  7030: ["#6611cc", "#33bbff", "#00ffaa", "#bb11ee", "#992200"],
  7031: ["#77ddbb", "#aaaa99", "#aadd66", "#33ff11", "#887788"],
  7032: ["#11aa99", "#aabb88", "#ff5544", "#ddaa44", "#990011"],
  7033: ["#ee6600", "#449900", "#225522", "#ff7766", "#88ff77"],
  7034: ["#002266", "#660099", "#99aa77", "#335577", "#bb1166"],
  7035: ["#ee99ee", "#001122", "#ddffff", "#33ee11", "#334433"],
  7036: ["#6699ff", "#cccc22", "#eeaa66", "#77ddff", "#221166"],
  7037: ["#bb11dd", "#66ddbb", "#cc77cc", "#002266", "#eeaadd"],
  7038: ["#22ff55", "#4400cc", "#559966", "#bb8822", "#ee99aa"],
  7039: ["#44bb55", "#00dd44", "#ff1199", "#aa5588", "#998888"],
  7040: ["#dd77ee", "#33bb99", "#aa3377", "#cc88ff", "#eebb55"],
  7041: ["#88ee00", "#ff1133", "#1122dd", "#33ffbb", "#aa88bb"],
  7042: ["#7766ff", "#cc9933", "#33ee99", "#99cc11", "#ddaa00"],
  7043: ["#dd2200", "#00eeff", "#6644cc", "#aaaa22", "#994400"],
  7044: ["#00aa66", "#bb1144", "#2255aa", "#88aabb", "#778866"],
  7045: ["#229933", "#cc22dd", "#550088", "#449911", "#11eebb"],
  7046: ["#332233", "#ddbbff", "#333399", "#5544dd", "#4499aa"],
  7047: ["#bbaa11", "#88ddbb", "#774444", "#44aabb", "#660055"],
  7048: ["#dd33ff", "#aabb55", "#446633", "#225511", "#774411"],
  7049: ["#445500", "#88aa11", "#cc1199", "#2277dd", "#88eedd"],
  7050: ["#997744", "#bb5577", "#1133cc", "#110077", "#11bb00"],
  7051: ["#662288", "#557777", "#bb6622", "#006677", "#aa33dd"],
  7052: ["#9944cc", "#2244aa", "#bb3322", "#22aa55", "#778899"],
  7053: ["#991177", "#884444", "#aa2244", "#ffffdd", "#11ddcc"],
  7054: ["#dd2299", "#55ffcc", "#99bbee", "#ddff11", "#dd88ee"],
  7055: ["#661177", "#111111", "#6622dd", "#55cc55", "#118866"],
  7056: ["#1188bb", "#88aa00", "#991155", "#772233", "#cc00cc"],
  7057: ["#ff7777", "#44cccc", "#996644", "#eebb44", "#ddff33"],
  7058: ["#00cc88", "#ffeebb", "#bbaa66", "#660099", "#dd7755"],
  7059: ["#aadd55", "#aabb22", "#cc0000", "#ff5500", "#9911dd"],
  7060: ["#22bbbb", "#ff9977", "#6688aa", "#77ff44", "#bb88cc"],
  7061: ["#ccddaa", "#ffbb55", "#66eebb", "#0088cc", "#aacc11"],
  7062: ["#2255bb", "#bb11dd", "#8822ee", "#ddffaa", "#88cc99"],
  7063: ["#bb99ee", "#993399", "#cc00ff", "#ddccff", "#111155"],
  7064: ["#bb9922", "#0099ff", "#554400", "#eecc11", "#ee99ee"],
  7065: ["#bbdd22", "#bb2200", "#773344", "#dd33dd", "#eeff11"],
  7066: ["#44ee33", "#44dd55", "#ff0000", "#ccaa00", "#bbccbb"],
  7067: ["#4400cc", "#11ddcc", "#227777", "#229977", "#55ffdd"],
  7068: ["#007799", "#44cc22", "#ddffff", "#55ee11", "#5511ff"],
  7069: ["#bb5522", "#aa7733", "#220088", "#44cc22", "#776699"],
  7070: ["#cc22cc", "#cc00bb", "#ee1111", "#aa22dd", "#aadd33"],
  7071: ["#ff00aa", "#aaaaff", "#cc33ee", "#3333dd", "#eeddbb"],
  7072: ["#661111", "#cc3377", "#118844", "#225555", "#55eeee"],
  7073: ["#004444", "#33bbaa", "#cc0088", "#aa11dd", "#44eeff"],
  7074: ["#bb99ff", "#667766", "#9933dd", "#99aa44", "#550044"],
  7075: ["#ee2266", "#1166cc", "#ee8844", "#995588", "#ffbb88"],
  7076: ["#4433ff", "#6600ee", "#223311", "#11ff22", "#5544ee"],
  7077: ["#775500", "#888833", "#002244", "#aa88dd", "#002200"],
  7078: ["#00ddbb", "#774455", "#99cc77", "#ccaa88", "#aa5522"],
  7079: ["#11bbff", "#2277ff", "#5533dd", "#888811", "#11aa66"],
  7080: ["#cc22ee", "#00bbff", "#0044ff", "#110011", "#226655"],
  7081: ["#22ccbb", "#ffaa77", "#55aa66", "#bb1155", "#55bb11"],
  7082: ["#66bbee", "#5588bb", "#996688", "#8800aa", "#110000"],
  7083: ["#bb99dd", "#009966", "#77bbdd", "#336699", "#bb00bb"],
  7084: ["#bb55ff", "#660077", "#0055ee", "#66dd66", "#229966"],
  7085: ["#bbdd55", "#8855ee", "#7700cc", "#ffccbb", "#aabb00"],
  7086: ["#662299", "#aaffff", "#9955ee", "#00bb33", "#99ddff"],
  7087: ["#dd11dd", "#ddcc88", "#003355", "#0088cc", "#cc0099"],
  7088: ["#66aaff", "#118833", "#ee2299", "#66cc33", "#66ff22"],
  7089: ["#8866ff", "#9955dd", "#aa8844", "#00bb88", "#ff9922"],
  7090: ["#77ffbb", "#ee8811", "#442244", "#3388cc", "#994455"],
  7091: ["#dd9977", "#445544", "#553344", "#77ee99", "#11aaaa"],
  7092: ["#ddaa00", "#ff11ee", "#445577", "#882244", "#335566"],
  7093: ["#1133dd", "#bbdd66", "#994422", "#77ff99", "#551111"],
  7094: ["#330088", "#556677", "#1155cc", "#226611", "#33aaaa"],
  7095: ["#88eedd", "#ff44ee", "#ddbbdd", "#ffff77", "#aaaa88"],
  7096: ["#ddee11", "#cc44cc", "#8833cc", "#444422", "#aa1177"],
  7097: ["#aa0077", "#66ff66", "#00bbcc", "#77bbbb", "#33dd22"],
  7098: ["#008877", "#334400", "#ffdd33", "#88bb11", "#220022"],
  7099: ["#99cccc", "#00ee33", "#11aa66", "#770000", "#002277"],
  7100: ["#cccc22", "#5544aa", "#ff7799", "#22bb11", "#002244"],
  7101: ["#114455", "#2255dd", "#aa6600", "#334455", "#ffffbb"],
  7102: ["#22bb66", "#ccddff", "#bb00ee", "#bbcc44", "#994411"],
  7103: ["#3333aa", "#cc55ff", "#995544", "#ddaa77", "#aaeebb"],
  7104: ["#330066", "#ee9933", "#77eedd", "#dd7744", "#55aa77"],
  7105: ["#cc22bb", "#aaddee", "#8844ff", "#88cc99", "#aa44ee"],
  7106: ["#115544", "#77dd77", "#9944ee", "#33ee77", "#229977"],
  7107: ["#aaee11", "#3300cc", "#66ccdd", "#8899ff", "#3344ee"],
  7108: ["#33dddd", "#ccffee", "#66ee55", "#ffddee", "#443322"],
  7109: ["#bbaabb", "#775555", "#4488aa", "#002211", "#0055bb"],
  7110: ["#11aadd", "#117755", "#995533", "#dd7799", "#eeeecc"],
  7111: ["#bb7700", "#1199aa", "#668833", "#88aa88", "#1199aa"],
  7112: ["#bb7733", "#aa66aa", "#0066dd", "#aaaa88", "#ccffee"],
  7113: ["#44ff88", "#dd8811", "#110055", "#eebb88", "#0022ff"],
  7114: ["#000088", "#bbddee", "#ff5500", "#7755ee", "#668800"],
  7115: ["#5599bb", "#bbff11", "#ddcc99", "#aaee00", "#114488"],
  7116: ["#6699ee", "#bb6666", "#0099aa", "#22ee55", "#884400"],
  7117: ["#77eeaa", "#55ee11", "#33ffdd", "#990033", "#4433ff"],
  7118: ["#ccdd00", "#00ccff", "#226611", "#ff0066", "#aa3344"],
  7119: ["#448877", "#227744", "#3300dd", "#ff11ee", "#eeaa44"],
  7120: ["#ddcc33", "#ffeebb", "#22dd00", "#eeddff", "#ff4411"],
  7121: ["#00aa33", "#bbaa44", "#bb7799", "#ee99ff", "#66ee33"],
  7122: ["#cc7744", "#337722", "#339900", "#ee8855", "#00eeaa"],
  7123: ["#ee7755", "#88aabb", "#770033", "#3333ff", "#00bb77"],
  7124: ["#eeff88", "#ff3355", "#eebb88", "#336655", "#99dd11"],
  7125: ["#eeddbb", "#aa0011", "#110099", "#991177", "#668822"],
  7126: ["#55bbcc", "#bb4477", "#77eeee", "#226677", "#44ee33"],
  7127: ["#aaff33", "#cc6666", "#44ccff", "#00dd99", "#ddeeaa"],
  7128: ["#11ffaa", "#99aabb", "#337766", "#ff9922", "#22ccff"],
  7129: ["#bb9911", "#77cccc", "#ee8855", "#cc2299", "#ddccff"],
  7130: ["#00aa00", "#333366", "#446633", "#003300", "#5555cc"],
  7131: ["#000088", "#aaaacc", "#77ff11", "#773311", "#8822ee"],
  7132: ["#ee44dd", "#887766", "#ee33cc", "#11ee11", "#4444dd"],
  7133: ["#996699", "#77ffaa", "#004466", "#885577", "#ccdd44"],
  7134: ["#cc8855", "#22ffff", "#bbcc44", "#4455ee", "#2200ee"],
  7135: ["#eedd99", "#994433", "#99dd55", "#aacc77", "#443322"],
  7136: ["#1111aa", "#886699", "#bb11ff", "#ddbb99", "#00dd33"],
  7137: ["#dd7799", "#66cc66", "#661144", "#1199ff", "#00ccdd"],
  7138: ["#88ccaa", "#aa5577", "#aa00bb", "#008844", "#1177ee"],
  7139: ["#88aa99", "#33dddd", "#33ee99", "#2222ff", "#dd66ee"],
  7140: ["#3344ee", "#8888dd", "#338800", "#aa7799", "#223388"],
  7141: ["#bbcc66", "#004433", "#22ccbb", "#33aa11", "#8888bb"],
  7142: ["#8866cc", "#337733", "#997799", "#aacc44", "#669922"],
  7143: ["#cc66ff", "#dd9911", "#442255", "#449922", "#77ddbb"],
  7144: ["#113322", "#bbff33", "#88ee11", "#776622", "#bb0088"],
  7145: ["#9999ee", "#11ccaa", "#aa55aa", "#dd9988", "#225566"],
  7146: ["#006633", "#5500bb", "#114455", "#447755", "#553399"],
  7147: ["#ee00bb", "#77bb66", "#33bb88", "#881100", "#77cc22"],
  7148: ["#ff8866", "#99aa00", "#ee4422", "#2299ff", "#ddddee"],
  7149: ["#eebbcc", "#77bb11", "#ff77dd", "#887788", "#9999ff"],
  7150: ["#225577", "#ff2255", "#11ff00", "#77aa55", "#9966bb"],
  7151: ["#cc9933", "#ffee33", "#dd3366", "#5599bb", "#7700ee"],
  7152: ["#5566ee", "#77ffbb", "#779988", "#55ffaa", "#ee2266"],
  7153: ["#554400", "#77aa88", "#ee0000", "#8844ff", "#9988dd"],
  7154: ["#aa7744", "#ff11bb", "#559999", "#008833", "#ff2244"],
  7155: ["#ccaa55", "#222244", "#1188dd", "#007700", "#116600"],
  7156: ["#110033", "#44eeaa", "#2233cc", "#2222dd", "#77cc77"],
  7157: ["#226688", "#2266aa", "#eeaa00", "#bb6622", "#bbeeff"],
  7158: ["#00cc00", "#224455", "#118888", "#cc11ee", "#666666"],
  7159: ["#ff3322", "#aa5588", "#cc77aa", "#eebbdd", "#66aabb"],
  7160: ["#33eedd", "#cc1155", "#881177", "#00ee55", "#11ccaa"],
  7161: ["#ffaa77", "#dd2211", "#eedd00", "#331155", "#cccc33"],
  7162: ["#337722", "#99ee77", "#ddcc77", "#447777", "#6666ff"],
  7163: ["#885544", "#cc1155", "#dd5522", "#771133", "#559966"],
  7164: ["#997700", "#ee8811", "#116699", "#6600aa", "#661155"],
  7165: ["#ffdd22", "#007799", "#11ddbb", "#775533", "#771111"],
  7166: ["#ee66ff", "#ff5522", "#bb0055", "#77dd11", "#bb00aa"],
  7167: ["#cc11aa", "#998822", "#aa4422", "#cc88bb", "#0022bb"],
  7168: ["#5555bb", "#7788ee", "#cc6600", "#22eeff", "#551155"],
  7169: ["#bb1144", "#226666", "#cc77ff", "#bbaa55", "#66ddcc"],
  7170: ["#55dd66", "#331188", "#ccffaa", "#dd22aa", "#5577cc"],
  7171: ["#887788", "#5511bb", "#99ddcc", "#ddddff", "#ddee44"],
  7172: ["#eeff33", "#dd9922", "#77dd22", "#990000", "#3344dd"],
  7173: ["#ff1133", "#002211", "#119966", "#99ff88", "#115533"],
  7174: ["#118899", "#884466", "#22dd77", "#77ee77", "#4400dd"],
  7175: ["#55dd55", "#3333cc", "#ff55ee", "#886699", "#22cc55"],
  7176: ["#33cc44", "#003388", "#88eeff", "#884466", "#443333"],
  7177: ["#33bb88", "#11cc33", "#6666cc", "#3355cc", "#777788"],
  7178: ["#226622", "#4499aa", "#008811", "#9911dd", "#550088"],
  7179: ["#00ee55", "#997766", "#cc0077", "#99dd33", "#8899bb"],
  7180: ["#55bb22", "#66dd44", "#884466", "#33aa66", "#447722"],
  7181: ["#22cc44", "#774400", "#113311", "#6644aa", "#dd4400"],
  7182: ["#884444", "#dd1155", "#33ccaa", "#5533cc", "#1100ee"],
  7183: ["#dd6688", "#338833", "#ff44aa", "#cc6633", "#88cc66"],
  7184: ["#229988", "#bb3399", "#3388dd", "#22dd55", "#ddbbaa"],
  7185: ["#1177dd", "#777777", "#66ccbb", "#cc3388", "#ee9999"],
  7186: ["#00aadd", "#ee44ff", "#6622dd", "#ddee55", "#770022"],
  7187: ["#bb0077", "#77cc77", "#77ff77", "#6699ee", "#ee00bb"],
  7188: ["#221122", "#dd1199", "#00ee44", "#0088bb", "#550055"],
  7189: ["#88cc55", "#ee1111", "#3300aa", "#ffbb44", "#99aa66"],
  7190: ["#884444", "#66dd33", "#dd55cc", "#440077", "#9977dd"],
  7191: ["#cc6633", "#770044", "#bbee22", "#ccee11", "#337788"],
  7192: ["#8877bb", "#bb44cc", "#ff2266", "#bb1144", "#99eeff"],
  7193: ["#336655", "#7711ff", "#66ff99", "#884422", "#aa44ee"],
  7194: ["#dddd77", "#cc33aa", "#226655", "#dd1188", "#ff1188"],
  7195: ["#668877", "#33ee22", "#447788", "#887744", "#883399"],
  7196: ["#8822cc", "#ffcc77", "#aacc00", "#1155cc", "#3355bb"],
  7197: ["#443399", "#77ff55", "#11ddcc", "#004422", "#bbaa22"],
  7198: ["#ddff33", "#44ccbb", "#dd00ff", "#7744dd", "#00dd55"],
  7199: ["#771166", "#ff0000", "#00eeff", "#dd5511", "#88ccdd"],
  7200: ["#cc66cc", "#77ee55", "#ff4488", "#7744aa", "#773333"],
  7201: ["#ff6644", "#662277", "#66aa11", "#dd00ff", "#11eebb"],
  7202: ["#aa0099", "#dd33ee", "#3311dd", "#33ccaa", "#cc5533"],
  7203: ["#112299", "#11dd88", "#228855", "#113311", "#ccdd11"],
  7204: ["#eecc77", "#55aa66", "#997755", "#dd6688", "#44dd88"],
  7205: ["#66ee44", "#ddee88", "#5588cc", "#eeaa99", "#4455bb"],
  7206: ["#228822", "#88aaff", "#8877bb", "#227722", "#ccaa00"],
  7207: ["#dd7744", "#449900", "#bbccdd", "#11cc33", "#5544aa"],
  7208: ["#442211", "#225566", "#cc9955", "#445533", "#ff44aa"],
  7209: ["#11aa66", "#669977", "#eeaa66", "#99dd11", "#cc44ff"],
  7210: ["#bbccaa", "#4477bb", "#2200aa", "#eecc22", "#3355cc"],
  7211: ["#ff0055", "#aaeebb", "#8833dd", "#ddcc99", "#5588bb"],
  7212: ["#6633dd", "#aaaaff", "#33ee44", "#3366ff", "#cc88bb"],
  7213: ["#1111cc", "#99aa22", "#cc7744", "#66aadd", "#223300"],
  7214: ["#dd9933", "#88bbbb", "#7711cc", "#ee5555", "#331188"],
  7215: ["#3300bb", "#664444", "#009933", "#dd8855", "#ccff99"],
  7216: ["#662255", "#aa55dd", "#1199bb", "#33aa55", "#ffcc00"],
  7217: ["#77bb33", "#ffccdd", "#775555", "#ee77aa", "#552200"],
  7218: ["#775577", "#88ee77", "#dddd66", "#4499aa", "#99ffbb"],
  7219: ["#6633aa", "#ff1122", "#ffbbcc", "#44ee00", "#99ff99"],
  7220: ["#88bb99", "#0077dd", "#55ff00", "#9944aa", "#ee77cc"],
  7221: ["#ff3377", "#ddcc99", "#33aa66", "#ee6622", "#dddd00"],
  7222: ["#bb5599", "#11ff44", "#668899", "#ffffaa", "#ccff66"],
  7223: ["#dd5522", "#ccff44", "#9944aa", "#22ccaa", "#882244"],
  7224: ["#008855", "#eeffcc", "#dd77aa", "#99ee66", "#662277"],
  7225: ["#ccee66", "#aadd88", "#bb6611", "#77dd66", "#00ff22"],
  7226: ["#dd00bb", "#336655", "#881122", "#6677dd", "#77dd22"],
  7227: ["#991155", "#ccff66", "#aabbdd", "#aa8888", "#1144aa"],
  7228: ["#99ee77", "#22ffff", "#5544bb", "#bb5577", "#eeee11"],
  7229: ["#99cc00", "#1100dd", "#cc8822", "#33bbee", "#55ddee"],
  7230: ["#55cc55", "#ff0033", "#225588", "#009988", "#ff4455"],
  7231: ["#66bb55", "#3300ff", "#bbbb00", "#448877", "#ccee88"],
  7232: ["#44aa22", "#aa33dd", "#bb4499", "#99cc00", "#772200"],
  7233: ["#556677", "#6699ee", "#aa1177", "#44ff33", "#221166"],
  7234: ["#559922", "#77dddd", "#ee5566", "#eeee00", "#883300"],
  7235: ["#114455", "#ffccbb", "#99bb55", "#9955dd", "#556600"],
  7236: ["#cc4499", "#66dd77", "#ee0000", "#ff33aa", "#559999"],
  7237: ["#44bb00", "#dd6633", "#dd0066", "#7733ff", "#aa99cc"],
  7238: ["#554499", "#33ddee", "#00dd22", "#7722aa", "#661100"],
  7239: ["#008855", "#0088ff", "#ff9911", "#444477", "#eeaa11"],
  7240: ["#aa8866", "#6688ff", "#446622", "#66dddd", "#11aa99"],
  7241: ["#99dd11", "#dd9955", "#aa1122", "#ddddee", "#882288"],
  7242: ["#aa6688", "#ddddcc", "#882288", "#885522", "#dd33aa"],
  7243: ["#001155", "#aa0088", "#dd4488", "#116633", "#6699cc"],
  7244: ["#992211", "#44bb99", "#1111ff", "#33cc22", "#665588"],
  7245: ["#44aa00", "#44ff88", "#dd11ff", "#4400dd", "#aa22ff"],
  7246: ["#22cc00", "#11ff99", "#ee33ff", "#77aaee", "#cc5566"],
  7247: ["#aabb77", "#557711", "#998811", "#bb3311", "#aa3344"],
  7248: ["#ff3366", "#55eedd", "#ff0000", "#ff2200", "#77aa33"],
  7249: ["#224488", "#88bbdd", "#335555", "#dd2299", "#551155"],
  7250: ["#886600", "#cc1144", "#77ff77", "#ff33ff", "#333300"],
  7251: ["#779999", "#aa88dd", "#229999", "#116633", "#33aa77"],
  7252: ["#00eedd", "#ff33ff", "#11ff44", "#cc3333", "#bbaadd"],
  7253: ["#bbff55", "#cc11cc", "#998855", "#ffbb66", "#997799"],
  7254: ["#77ff66", "#6655cc", "#aa8822", "#22cc99", "#cccc44"],
  7255: ["#99ee11", "#77ff55", "#001133", "#7799ff", "#cc5599"],
  7256: ["#117700", "#bb88ff", "#cc9922", "#3377ff", "#11ff00"],
  7257: ["#77eeaa", "#4488ee", "#33bbdd", "#dd33aa", "#cc3311"],
  7258: ["#001133", "#004477", "#44cc00", "#8811aa", "#004499"],
  7259: ["#66ddff", "#550077", "#2277ee", "#3300ee", "#aa1133"],
  7260: ["#99ddaa", "#ee88ee", "#ee55ee", "#11aaaa", "#992233"],
  7261: ["#770011", "#8811cc", "#9900bb", "#7788ff", "#882211"],
  7262: ["#2288dd", "#ff1166", "#dd55aa", "#dd5533", "#cc8899"],
  7263: ["#aa4466", "#dd3300", "#ff33aa", "#cc11aa", "#dd4433"],
  7264: ["#440055", "#ee9999", "#ff6600", "#eeff88", "#88ddff"],
  7265: ["#336611", "#44bbff", "#ff9988", "#aa55bb", "#33bb44"],
  7266: ["#223388", "#11ff88", "#aa77ee", "#55bb66", "#2288ff"],
  7267: ["#dd5588", "#44ccbb", "#0077ee", "#881111", "#aa0066"],
  7268: ["#66dd22", "#110088", "#4499ee", "#77dd44", "#eeeeee"],
  7269: ["#dd6688", "#eeffee", "#ee2222", "#77bb88", "#4433ff"],
  7270: ["#aa88ee", "#dd44bb", "#334422", "#aa7744", "#007788"],
  7271: ["#dd77ee", "#55ccee", "#dd3333", "#55ff11", "#6699ff"],
  7272: ["#773399", "#449988", "#22ff44", "#dd8888", "#eecc00"],
  7273: ["#eedd44", "#11bb11", "#998844", "#bb4433", "#ccdd99"],
  7274: ["#339977", "#8877dd", "#ee22aa", "#aaccff", "#002222"],
  7275: ["#ff44dd", "#8833ee", "#000022", "#773322", "#cc8800"],
  7276: ["#ff9955", "#ee0066", "#55aaaa", "#993388", "#776688"],
  7277: ["#aa1144", "#449955", "#8822dd", "#11bb00", "#1155bb"],
  7278: ["#ee33cc", "#6688ff", "#ee99dd", "#cccc11", "#cc55ee"],
  7279: ["#bb6688", "#44aa77", "#bb33ff", "#6622ee", "#7700ff"],
  7280: ["#bbdd77", "#2244cc", "#222211", "#ccff00", "#7722ff"],
  7281: ["#88ff11", "#111199", "#aa9999", "#441111", "#666699"],
  7282: ["#bb6655", "#668833", "#55ffff", "#3300aa", "#00bbee"],
  7283: ["#aa3311", "#44eecc", "#aa6699", "#cc44bb", "#3377cc"],
  7284: ["#77bbaa", "#dd2222", "#44bb22", "#2244dd", "#bb77aa"],
  7285: ["#11bb00", "#ee3333", "#ffee33", "#887766", "#8800aa"],
  7286: ["#ddbb11", "#77bb88", "#cccc00", "#2211cc", "#ff99bb"],
  7287: ["#990077", "#881111", "#eeee22", "#003388", "#00ee88"],
  7288: ["#00aadd", "#ee99cc", "#bbddcc", "#11eeaa", "#bb1199"],
  7289: ["#bbff77", "#446666", "#2288bb", "#6600dd", "#002233"],
  7290: ["#992211", "#1100cc", "#115522", "#dd9911", "#9966bb"],
  7291: ["#558833", "#668822", "#99ccee", "#ddeeff", "#00cccc"],
  7292: ["#009900", "#1177cc", "#4433ee", "#ddbb11", "#55cc22"],
  7293: ["#335500", "#4433dd", "#00bbaa", "#339933", "#aa00bb"],
  7294: ["#cc66ee", "#665577", "#662266", "#008888", "#880000"],
  7295: ["#0077ff", "#ccffbb", "#99eedd", "#8833aa", "#440022"],
  7296: ["#4400cc", "#11ffaa", "#443355", "#ee9944", "#881100"],
  7297: ["#4488ff", "#44bb55", "#cc8811", "#bbee77", "#6622ee"],
  7298: ["#ee99aa", "#cc22ff", "#eecc77", "#bb22cc", "#ee77bb"],
  7299: ["#556677", "#448888", "#ccff00", "#8800cc", "#992222"],
  7300: ["#6611ee", "#ff33ee", "#4488ee", "#66aa33", "#dd99bb"],
  7301: ["#99eeff", "#55aacc", "#00ff88", "#aa44cc", "#443366"],
  7302: ["#cc1166", "#004477", "#559933", "#773333", "#884400"],
  7303: ["#0099aa", "#ddee33", "#ccff66", "#eeccee", "#0099cc"],
  7304: ["#ee11bb", "#775533", "#99ff55", "#cc8800", "#22ddaa"],
  7305: ["#883355", "#bbbbcc", "#aaddee", "#bb4433", "#aa33bb"],
  7306: ["#eeccbb", "#661133", "#88bb22", "#55dd99", "#ccee88"],
  7307: ["#992222", "#338811", "#ff77aa", "#44aaff", "#11dd88"],
  7308: ["#118888", "#554422", "#338877", "#11ee22", "#dd33bb"],
  7309: ["#ee6699", "#00cc88", "#88dd77", "#7711dd", "#55ff77"],
  7310: ["#bbccbb", "#227788", "#cc8888", "#ee2222", "#cc2299"],
  7311: ["#11ccff", "#aa66cc", "#446666", "#11cc44", "#44ffdd"],
  7312: ["#00ffdd", "#ccff22", "#cc00dd", "#dd1133", "#77dd55"],
  7313: ["#aaee33", "#228888", "#33cc44", "#ccbb11", "#446688"],
  7314: ["#bb2211", "#ddaaff", "#5588dd", "#004477", "#dd3322"],
  7315: ["#776622", "#00cc11", "#ee9922", "#88ee55", "#cc3399"],
  7316: ["#2299aa", "#aaff88", "#ff6677", "#0044aa", "#aaffdd"],
  7317: ["#bb22bb", "#eeaaaa", "#002222", "#881133", "#ccdd33"],
  7318: ["#660055", "#222288", "#ee99ee", "#003366", "#99ee33"],
  7319: ["#bb8899", "#004433", "#2255bb", "#cceebb", "#77ccaa"],
  7320: ["#66ccee", "#cceecc", "#11bbcc", "#332233", "#44ff44"],
  7321: ["#66cc99", "#224455", "#ee3377", "#665533", "#99dd77"],
  7322: ["#dd11dd", "#ffee66", "#448822", "#dd8800", "#dddd55"],
  7323: ["#00aa66", "#77aa77", "#11ffff", "#0055bb", "#44cc55"],
  7324: ["#4422bb", "#332277", "#442233", "#ee4400", "#9900bb"],
  7325: ["#aa6699", "#003366", "#116688", "#aa9988", "#7777bb"],
  7326: ["#22dd00", "#33dddd", "#5577dd", "#ccbb88", "#bb00dd"],
  7327: ["#99dd00", "#ee0077", "#77dd55", "#cccc00", "#223388"],
  7328: ["#5599ee", "#cc1166", "#8800bb", "#dd44ff", "#00ffee"],
  7329: ["#337766", "#11cc44", "#117733", "#1166bb", "#773355"],
  7330: ["#1100bb", "#331122", "#2277dd", "#88dd44", "#77cccc"],
  7331: ["#9977ee", "#44bb11", "#ee00aa", "#dd00bb", "#bb55ff"],
  7332: ["#774433", "#dd66ff", "#bb77aa", "#22ff55", "#008899"],
  7333: ["#ff5555", "#55ccff", "#66ddbb", "#00bb99", "#22eeaa"],
  7334: ["#11ff66", "#eeaa66", "#336655", "#11aadd", "#aabb66"],
  7335: ["#33ff44", "#22cc99", "#bb3388", "#339911", "#55cc88"],
  7336: ["#1111cc", "#99ee11", "#cccccc", "#aaccaa", "#6644ee"],
  7337: ["#002299", "#4477dd", "#99ffbb", "#3377ff", "#88ffff"],
  7338: ["#ccffcc", "#1111ee", "#224444", "#338866", "#7733aa"],
  7339: ["#3366aa", "#338899", "#55aacc", "#aa88bb", "#ee5500"],
  7340: ["#cc88ff", "#991177", "#3377dd", "#55aa99", "#226699"],
  7341: ["#664422", "#994411", "#cc9966", "#22aa77", "#11ff44"],
  7342: ["#002277", "#dd1177", "#ccaacc", "#cc99dd", "#889900"],
  7343: ["#aa2288", "#ff3355", "#dd0022", "#ee77dd", "#ffbb88"],
  7344: ["#55dd66", "#bb55dd", "#cc3322", "#bbeebb", "#cc1188"],
  7345: ["#00ffdd", "#1111ff", "#bbffbb", "#44ee44", "#cc33cc"],
  7346: ["#ff7766", "#11aa00", "#5577ff", "#44ee11", "#771199"],
  7347: ["#ccffcc", "#ee55dd", "#886611", "#22ff00", "#00ee88"],
  7348: ["#88aa77", "#eebb55", "#ffddff", "#bb8833", "#66cc88"],
  7349: ["#22ee55", "#33bb33", "#669966", "#ee55aa", "#448822"],
  7350: ["#8899dd", "#00dd99", "#00bb77", "#ff44bb", "#5544bb"],
  7351: ["#55cc99", "#669922", "#ff2288", "#dd1199", "#eeaaaa"],
  7352: ["#aaff00", "#eebbdd", "#ff9933", "#aa22dd", "#880022"],
  7353: ["#000022", "#5544ee", "#116622", "#8855dd", "#440044"],
  7354: ["#bb11bb", "#889944", "#3366cc", "#bb55bb", "#cc77bb"],
  7355: ["#0033bb", "#dd5544", "#5544dd", "#7722dd", "#006622"],
  7356: ["#998800", "#6699ff", "#11bb99", "#009988", "#8811ee"],
  7357: ["#5588ff", "#8822aa", "#3322cc", "#007799", "#6666aa"],
  7358: ["#3355ff", "#44ffdd", "#55eebb", "#77cc33", "#ff8844"],
  7359: ["#ee9966", "#4444aa", "#66ff00", "#33eecc", "#cccc66"],
  7360: ["#cc88ee", "#0088bb", "#882288", "#668844", "#1144bb"],
  7361: ["#550088", "#006611", "#66ccee", "#ee44aa", "#6655bb"],
  7362: ["#bbcc88", "#77aa11", "#112266", "#006611", "#3322bb"],
  7363: ["#777766", "#ddcc99", "#22eecc", "#330044", "#6688bb"],
  7364: ["#9988ee", "#667766", "#dd22bb", "#55ee66", "#2222cc"],
  7365: ["#88bb33", "#eeddaa", "#004411", "#11bb88", "#aa4444"],
  7366: ["#5533ee", "#44aa00", "#dd3355", "#44aa44", "#ee2266"],
  7367: ["#eeee11", "#336677", "#eebb99", "#77eecc", "#55ddaa"],
  7368: ["#88cc55", "#ff5588", "#88dd77", "#33ffaa", "#cc00aa"],
  7369: ["#bb77ff", "#227733", "#cc5566", "#1111cc", "#aaaa77"],
  7370: ["#887766", "#88aaee", "#005511", "#bb55cc", "#44dd77"],
  7371: ["#cc1133", "#ee33aa", "#552233", "#22aacc", "#99ddcc"],
  7372: ["#003322", "#dddd55", "#bb66aa", "#ee22bb", "#11bb00"],
  7373: ["#dd33ff", "#8877ee", "#ff88ee", "#881199", "#ccddbb"],
  7374: ["#221100", "#55dd55", "#bbaa22", "#6600ff", "#eeff44"],
  7375: ["#22ffee", "#cc5588", "#991188", "#554444", "#ee88aa"],
  7376: ["#ddff11", "#bb8866", "#1155ee", "#2244ee", "#88ff11"],
  7377: ["#dd00cc", "#007722", "#009933", "#44ccdd", "#9955aa"],
  7378: ["#11cc33", "#ffccbb", "#009944", "#9900dd", "#dd77cc"],
  7379: ["#008833", "#55ff22", "#7722dd", "#aa1133", "#aa99cc"],
  7380: ["#443333", "#666699", "#bbdd55", "#aa66ff", "#1177cc"],
  7381: ["#662233", "#338844", "#ffffcc", "#4400aa", "#00ddee"],
  7382: ["#aa1166", "#99aa99", "#449933", "#ffcc99", "#88aaee"],
  7383: ["#8811ff", "#ee2222", "#667733", "#aaaaee", "#88eedd"],
  7384: ["#bb2288", "#117788", "#bb5577", "#bb55bb", "#ff99ee"],
  7385: ["#0088ee", "#dd66dd", "#99eedd", "#3388ff", "#ccffee"],
  7386: ["#ffaa99", "#33cc55", "#00dd22", "#9999dd", "#992222"],
  7387: ["#332266", "#994477", "#11aa77", "#55ff11", "#cc6688"],
  7388: ["#33bb00", "#bb2222", "#661122", "#1133cc", "#ff8833"],
  7389: ["#9988dd", "#00ff11", "#ccddaa", "#88aa66", "#ee2277"],
  7390: ["#ccbb77", "#dd6644", "#dd5511", "#aa2211", "#cccccc"],
  7391: ["#9900aa", "#ee99dd", "#ddcc99", "#778800", "#dd6699"],
  7392: ["#113322", "#447733", "#00bbaa", "#aa7777", "#dd4422"],
  7393: ["#444477", "#ffaa33", "#bb88cc", "#ee2244", "#55aa44"],
  7394: ["#ff8888", "#119977", "#6677bb", "#773344", "#ff1166"],
  7395: ["#aaaa33", "#ccaabb", "#0055ff", "#dd5599", "#88aa33"],
  7396: ["#77ff44", "#22bb00", "#dd8899", "#446655", "#002277"],
  7397: ["#007700", "#ddbbdd", "#ee4433", "#336622", "#eeaadd"],
  7398: ["#8822aa", "#440088", "#aa0000", "#2222dd", "#bbee44"],
  7399: ["#ffcc00", "#ccffcc", "#ffcc77", "#ee6600", "#aa66bb"],
  7400: ["#4488cc", "#ccbb66", "#6677dd", "#1188aa", "#00eecc"],
  7401: ["#115566", "#ff55aa", "#6688cc", "#bb1155", "#aa11ee"],
  7402: ["#33bb66", "#ffbbff", "#ccaabb", "#4433bb", "#aaddaa"],
  7403: ["#ccbbcc", "#2233cc", "#66ee88", "#0022cc", "#ff8888"],
  7404: ["#bbeeff", "#5511ff", "#ee44aa", "#553344", "#2244ff"],
  7405: ["#002288", "#00bbaa", "#662255", "#779922", "#bbdd77"],
  7406: ["#aaee77", "#aa88ee", "#551111", "#3355bb", "#776666"],
  7407: ["#eeaa11", "#00ddee", "#448833", "#777799", "#ff00ee"],
  7408: ["#222288", "#00dd55", "#55cc11", "#00aa55", "#00bbcc"],
  7409: ["#22cc55", "#ee66ee", "#3322bb", "#8866bb", "#bbcc11"],
  7410: ["#cc0088", "#003388", "#007766", "#ffdd00", "#dd4488"],
  7411: ["#bb55ff", "#22cccc", "#11eeee", "#3300ff", "#6677ff"],
  7412: ["#1166aa", "#1133dd", "#11bb44", "#5500ee", "#bbccee"],
  7413: ["#990022", "#aa8866", "#cc5544", "#446699", "#667788"],
  7414: ["#cc0066", "#447700", "#881188", "#3355ee", "#9922dd"],
  7415: ["#ccaa55", "#9900aa", "#77ff00", "#bb9966", "#aaff22"],
  7416: ["#443300", "#993322", "#772288", "#118899", "#ee99cc"],
  7417: ["#7711ff", "#551155", "#ee2233", "#cc1144", "#dd5511"],
  7418: ["#6644dd", "#77ddff", "#aaddaa", "#22ee55", "#33aa55"],
  7419: ["#0022ff", "#0000ee", "#cc1166", "#eeeeee", "#bb6677"],
  7420: ["#557700", "#332233", "#443322", "#77ff77", "#dd88aa"],
  7421: ["#ee7788", "#118877", "#11ee22", "#3344dd", "#88ff55"],
  7422: ["#aaff33", "#ee44ee", "#442299", "#44bb44", "#00ddbb"],
  7423: ["#223355", "#77cc44", "#bb66cc", "#887711", "#ddff22"],
  7424: ["#88cc88", "#336677", "#77ee55", "#dd5555", "#bbffee"],
  7425: ["#99dd88", "#33aaff", "#336622", "#116633", "#11aadd"],
  7426: ["#22bb44", "#11cc77", "#0033ff", "#55ff33", "#887766"],
  7427: ["#ffddaa", "#aa8811", "#22ddaa", "#1199ee", "#cc9988"],
  7428: ["#aaee77", "#ff7755", "#ffeeee", "#112244", "#666677"],
  7429: ["#77ddff", "#8800ff", "#00aa11", "#ee0000", "#ffeeaa"],
  7430: ["#aaaa77", "#cc2288", "#770055", "#2299dd", "#eeaa66"],
  7431: ["#1111cc", "#11bb00", "#4466bb", "#ffeedd", "#66dddd"],
  7432: ["#9944bb", "#447755", "#557777", "#22bb77", "#668822"],
  7433: ["#ee99ee", "#ffffee", "#887799", "#ff77dd", "#2299cc"],
  7434: ["#1188ee", "#9944bb", "#992222", "#88dd44", "#ccddbb"],
  7435: ["#1177ff", "#cc7799", "#88dd66", "#ee88bb", "#bb77bb"],
  7436: ["#224411", "#dd88dd", "#0000cc", "#99bb33", "#3366dd"],
  7437: ["#5511dd", "#225544", "#ccff77", "#669922", "#aa33aa"],
  7438: ["#dd4455", "#ee99ff", "#66ee11", "#7755cc", "#ee77bb"],
  7439: ["#ee4444", "#bb00ff", "#774477", "#997777", "#ff4422"],
  7440: ["#44aaff", "#bbdd44", "#886600", "#553388", "#bbddcc"],
  7441: ["#00bb22", "#cc77aa", "#eeee44", "#1111bb", "#336622"],
  7442: ["#222233", "#11ee66", "#00aa77", "#449922", "#9933ff"],
  7443: ["#77ccdd", "#aaccdd", "#22ff99", "#dd77aa", "#ccccff"],
  7444: ["#1188dd", "#1144dd", "#dd1144", "#ee6699", "#00cc33"],
  7445: ["#3388ff", "#1111aa", "#eedd22", "#eeeedd", "#113322"],
  7446: ["#dd55ee", "#99aa11", "#eeff77", "#22eebb", "#ee22bb"],
  7447: ["#ddff11", "#cc2222", "#ff22ee", "#6622cc", "#77ddaa"],
  7448: ["#77ddff", "#bb5555", "#aa44ff", "#66cc44", "#bbddcc"],
  7449: ["#660011", "#aa3333", "#aa8833", "#112200", "#99ddcc"],
  7450: ["#ee22ff", "#777711", "#77aa88", "#55dd33", "#ff77bb"],
  7451: ["#332299", "#ee9988", "#9988dd", "#440088", "#aabbee"],
  7452: ["#eecc11", "#776600", "#7744cc", "#007755", "#4411ee"],
  7453: ["#009988", "#4477bb", "#aaff77", "#ccbb55", "#44ddcc"],
  7454: ["#ffdd22", "#2299bb", "#dd5511", "#00dd33", "#ee0000"],
  7455: ["#66ff99", "#dd9933", "#998866", "#88bb22", "#ee4455"],
  7456: ["#114411", "#444444", "#4444cc", "#bbbb55", "#ffccff"],
  7457: ["#007733", "#9966bb", "#bb4411", "#2255ee", "#dd7788"],
  7458: ["#7711aa", "#44ffcc", "#aaaa44", "#bbbb66", "#22ddff"],
  7459: ["#7777ff", "#668877", "#555511", "#00ddaa", "#114400"],
  7460: ["#666622", "#66aacc", "#88ee99", "#556644", "#ee2244"],
  7461: ["#4499dd", "#552233", "#eeaaff", "#ff3300", "#444444"],
  7462: ["#33aa88", "#cc6633", "#77cc99", "#771111", "#66ee11"],
  7463: ["#88ee55", "#2244ee", "#558800", "#446611", "#eedd00"],
  7464: ["#ee99aa", "#777733", "#442222", "#4400aa", "#ee88ff"],
  7465: ["#777766", "#eecc99", "#bb66ff", "#110088", "#ee77cc"],
  7466: ["#77dd77", "#cc4400", "#00cc44", "#22ee33", "#885500"],
  7467: ["#7700aa", "#ffaa00", "#3300dd", "#ee3311", "#ccaa55"],
  7468: ["#eeee00", "#9999cc", "#bb8822", "#338866", "#8844ff"],
  7469: ["#66bb44", "#ffdd55", "#11ee00", "#ee00aa", "#5555ee"],
  7470: ["#55aabb", "#44ffaa", "#cc6633", "#bbffcc", "#5533ee"],
  7471: ["#aa1133", "#005533", "#991111", "#99aacc", "#ee88cc"],
  7472: ["#11aa00", "#dd55ff", "#55ee99", "#88bb22", "#ddff99"],
  7473: ["#aa77bb", "#337700", "#4477ff", "#22ee00", "#ff5566"],
  7474: ["#bb8844", "#33cc22", "#aa55bb", "#dd2211", "#bb55cc"],
  7475: ["#ee4444", "#554400", "#992299", "#ff99bb", "#1199dd"],
  7476: ["#004433", "#aa11cc", "#441166", "#886633", "#ee8800"],
  7477: ["#8833bb", "#aa11cc", "#dd3388", "#88aa22", "#664499"],
  7478: ["#ff33bb", "#7777bb", "#dd6699", "#99aa55", "#3333aa"],
  7479: ["#223399", "#ff3377", "#995577", "#ff1144", "#3377ee"],
  7480: ["#ff7799", "#55eeff", "#883311", "#994499", "#44dd11"],
  7481: ["#aa88cc", "#11aabb", "#ccddff", "#ffaa11", "#bb6699"],
  7482: ["#00ff33", "#111100", "#44bb11", "#115577", "#ccbb99"],
  7483: ["#44dd77", "#eecc99", "#ff3355", "#3344cc", "#11aacc"],
  7484: ["#ccaa99", "#cc6688", "#3388dd", "#bbffcc", "#889944"],
  7485: ["#992200", "#eeccee", "#557744", "#aa3344", "#00aa99"],
  7486: ["#663311", "#aaddbb", "#ccccaa", "#33ffee", "#55ff44"],
  7487: ["#4422cc", "#1177dd", "#662288", "#667700", "#3366ee"],
  7488: ["#5522bb", "#44aaaa", "#3344ee", "#ff9933", "#116622"],
  7489: ["#997799", "#773388", "#eeff11", "#337700", "#ee9966"],
  7490: ["#aa7766", "#bbaa11", "#77cccc", "#bb9900", "#ee3311"],
  7491: ["#44ddbb", "#bb0099", "#aa7777", "#cc6666", "#44ee66"],
  7492: ["#11ccff", "#77ff66", "#11bbee", "#44ee55", "#ee00bb"],
  7493: ["#ff8844", "#9999ee", "#8866ff", "#444433", "#225588"],
  7494: ["#55dd22", "#ff4444", "#ff66bb", "#cceecc", "#3344ee"],
  7495: ["#1155cc", "#7722bb", "#5577bb", "#ffcccc", "#0099aa"],
  7496: ["#ffff55", "#eecc44", "#77ff22", "#667755", "#ddcc44"],
  7497: ["#5555ff", "#774411", "#bb2288", "#33aa66", "#44bbbb"],
  7498: ["#bb3333", "#447799", "#cc55cc", "#ff1188", "#447766"],
  7499: ["#3322bb", "#8822aa", "#443300", "#dd0033", "#110055"],
  7500: ["#11ffaa", "#ddeedd", "#bbaaaa", "#aaffbb", "#88cc77"],
  7501: ["#dd9999", "#00aa55", "#99bbaa", "#333333", "#ee44bb"],
  7502: ["#ccee99", "#331166", "#cccccc", "#0055cc", "#11dd44"],
  7503: ["#44ccaa", "#dd7766", "#aa2211", "#114422", "#ccee88"],
  7504: ["#00eecc", "#667733", "#229966", "#2255ff", "#4433bb"],
  7505: ["#88eedd", "#664477", "#dd4455", "#aa55bb", "#887722"],
  7506: ["#2200dd", "#88ddaa", "#111155", "#aa1111", "#ddff88"],
  7507: ["#dd00aa", "#11bb55", "#cc66aa", "#bbffee", "#33ccbb"],
  7508: ["#bbdd55", "#3377aa", "#001111", "#ee44aa", "#44eebb"],
  7509: ["#dd33bb", "#4466aa", "#ee8888", "#ee8899", "#66dd55"],
  7510: ["#11ff88", "#999922", "#ccbb99", "#44ee88", "#772244"],
  7511: ["#449977", "#bb0000", "#669966", "#ee2200", "#ff88bb"],
  7512: ["#ffeeaa", "#445511", "#4466dd", "#44dd22", "#eecc88"],
  7513: ["#662288", "#550000", "#ff3333", "#cc77ee", "#998800"],
  7514: ["#338811", "#ddcc33", "#999999", "#22bbbb", "#7766aa"],
  7515: ["#ff0077", "#cc5577", "#dddd11", "#ccaa22", "#aaeecc"],
  7516: ["#1166aa", "#660022", "#556688", "#eeaacc", "#88bb11"],
  7517: ["#004455", "#114444", "#661144", "#bbaadd", "#cc11bb"],
  7518: ["#aa2222", "#5555dd", "#11cc99", "#ee99ff", "#bb6677"],
  7519: ["#ff77cc", "#dd55aa", "#bb5588", "#779911", "#44dd11"],
  7520: ["#ccddcc", "#cc11ff", "#cc7722", "#887711", "#551177"],
  7521: ["#22bb55", "#224455", "#ffffbb", "#bbbb22", "#00aa77"],
  7522: ["#331177", "#999922", "#66ee55", "#447700", "#77cc33"],
  7523: ["#aa3311", "#cccc33", "#aaaaaa", "#8866dd", "#666688"],
  7524: ["#550099", "#cc77dd", "#66aa55", "#339966", "#ffaa22"],
  7525: ["#ee0055", "#667766", "#ff9955", "#99dd99", "#330055"],
  7526: ["#9955aa", "#44aa55", "#ffcc11", "#ee3399", "#eeee44"],
  7527: ["#003388", "#55aacc", "#ff4400", "#66eeee", "#cc0077"],
  7528: ["#885588", "#223322", "#55dd11", "#1111aa", "#4455ee"],
  7529: ["#773333", "#ff7766", "#55bb88", "#22bbcc", "#dd5566"],
  7530: ["#ee33aa", "#7711dd", "#880044", "#446611", "#663399"],
  7531: ["#4455ee", "#22cc77", "#ee2277", "#aabbff", "#aaee00"],
  7532: ["#44cc99", "#556666", "#229955", "#aa88bb", "#770011"],
  7533: ["#991144", "#00aa99", "#99aa66", "#663322", "#ddbb88"],
  7534: ["#55bb66", "#220088", "#99dd99", "#77eecc", "#66ffbb"],
  7535: ["#884411", "#008877", "#337755", "#ccddbb", "#7744ff"],
  7536: ["#3300aa", "#cc0011", "#99aaee", "#bb11cc", "#999955"],
  7537: ["#114455", "#22ffdd", "#bb4466", "#227711", "#2288ee"],
  7538: ["#336688", "#99ddee", "#8877bb", "#55dd88", "#996666"],
  7539: ["#aaff00", "#bbcc77", "#bb1111", "#bb7788", "#774433"],
  7540: ["#88aadd", "#99cc22", "#cccc11", "#dd2255", "#88ee99"],
  7541: ["#aabb22", "#ff33ee", "#448877", "#bb2255", "#773300"],
  7542: ["#999911", "#ff66ee", "#88ff88", "#aa22aa", "#ff55aa"],
  7543: ["#ddddcc", "#55ff66", "#eebb99", "#663311", "#889955"],
  7544: ["#aa6677", "#2255ee", "#5599bb", "#5533cc", "#eeddbb"],
  7545: ["#11bb77", "#005599", "#335588", "#6699dd", "#88bb44"],
  7546: ["#ccaadd", "#cc6622", "#bb5533", "#996666", "#11dd11"],
  7547: ["#ff5577", "#ee55bb", "#55ff11", "#ee4433", "#4477aa"],
  7548: ["#aa00bb", "#4444ee", "#440033", "#aa9955", "#aa00dd"],
  7549: ["#3377cc", "#1122aa", "#22ff88", "#aaee22", "#660011"],
  7550: ["#66dd88", "#334477", "#6699dd", "#99aa99", "#441122"],
  7551: ["#bb22ee", "#bb2277", "#5555dd", "#ff6644", "#77dd77"],
  7552: ["#eeaa00", "#9999bb", "#44cc00", "#44eeaa", "#775599"],
  7553: ["#2222dd", "#55ffdd", "#ee4499", "#ddbb55", "#6666ff"],
  7554: ["#66ee33", "#dd66ff", "#ddbb22", "#2266aa", "#dd4411"],
  7555: ["#227788", "#ddbb66", "#aa22bb", "#eeee88", "#996666"],
  7556: ["#8833cc", "#22ee33", "#eedd99", "#33bb33", "#6622ff"],
  7557: ["#cc0022", "#bb2222", "#ff9988", "#337755", "#ee00dd"],
  7558: ["#55cc66", "#1111aa", "#005566", "#998855", "#00aaee"],
  7559: ["#448855", "#112266", "#2266cc", "#ee1166", "#337722"],
  7560: ["#ffddee", "#339900", "#551144", "#cc0066", "#5533cc"],
  7561: ["#886633", "#ffee33", "#cc2266", "#55ddcc", "#aaff66"],
  7562: ["#887755", "#cc6666", "#77aa77", "#004477", "#1188aa"],
  7563: ["#004488", "#ee4433", "#3333cc", "#991111", "#2277bb"],
  7564: ["#118833", "#bb2277", "#cccc33", "#333300", "#cc9944"],
  7565: ["#229944", "#11ee44", "#ff11cc", "#ffdd11", "#994499"],
  7566: ["#338811", "#008877", "#663388", "#22ffff", "#005555"],
  7567: ["#6699cc", "#ffee99", "#dd5533", "#551133", "#88aa99"],
  7568: ["#bbffee", "#aaaaaa", "#0022cc", "#88ccaa", "#aa9977"],
  7569: ["#440077", "#ddcc11", "#cc2233", "#33cc00", "#bb6644"],
  7570: ["#bb4488", "#2222ff", "#33cc11", "#000099", "#ee55dd"],
  7571: ["#aa33ff", "#663322", "#bbbb99", "#990055", "#bbaa88"],
  7572: ["#1144aa", "#ffaa44", "#aa1177", "#99bbcc", "#223300"],
  7573: ["#11cc33", "#ee2233", "#dd7700", "#991111", "#ff6666"],
  7574: ["#aa4455", "#11dd33", "#bb00cc", "#dd4411", "#88cc33"],
  7575: ["#0088ee", "#554411", "#226699", "#ccaa33", "#000088"],
  7576: ["#ee55aa", "#1177cc", "#eeee55", "#aaff44", "#33bbee"],
  7577: ["#44ffee", "#dd55aa", "#334411", "#dd66cc", "#ee88ee"],
  7578: ["#cc3333", "#bb6600", "#aa99ff", "#55ff99", "#5533cc"],
  7579: ["#771100", "#4466ff", "#115511", "#22ee44", "#bbeecc"],
  7580: ["#777755", "#bbaa33", "#2266aa", "#55aaaa", "#ddff33"],
  7581: ["#776633", "#55dd77", "#8899ff", "#4499ee", "#114499"],
  7582: ["#1122bb", "#661111", "#222299", "#5577ff", "#11bb00"],
  7583: ["#bb88ff", "#9977aa", "#887788", "#ffccff", "#7766dd"],
  7584: ["#aabb88", "#7799cc", "#ffff33", "#77dd77", "#88ee66"],
  7585: ["#11aa77", "#ff11aa", "#bb3399", "#bb9944", "#55cc66"],
  7586: ["#776655", "#ccaa66", "#996633", "#44aaee", "#9922dd"],
  7587: ["#eecc44", "#553344", "#44bbcc", "#aa33ff", "#dd8888"],
  7588: ["#4455ee", "#99aacc", "#6677aa", "#22cc77", "#ff1111"],
  7589: ["#dd8844", "#338833", "#661144", "#00aacc", "#aaddbb"],
  7590: ["#99aa99", "#779944", "#33aa44", "#1100ee", "#ddbbee"],
  7591: ["#77aa55", "#44cc33", "#999966", "#778888", "#7766bb"],
  7592: ["#442299", "#110088", "#66bb22", "#2266ee", "#eebbee"],
  7593: ["#99dd00", "#882233", "#7788cc", "#bbdd77", "#442244"],
  7594: ["#8888ff", "#66ddff", "#ffddaa", "#ccff99", "#555533"],
  7595: ["#9922bb", "#88bb66", "#5588aa", "#99bb88", "#1144aa"],
  7596: ["#007788", "#aa6633", "#dd33ff", "#ccee44", "#ffaa22"],
  7597: ["#ffdd99", "#99eeaa", "#115555", "#555555", "#ff9944"],
  7598: ["#aa88aa", "#66bb11", "#ffaa55", "#ee00dd", "#cceebb"],
  7599: ["#eeee99", "#88ccdd", "#995544", "#6688ff", "#ffff77"],
  7600: ["#4444bb", "#77ccee", "#99ff66", "#0066ee", "#3366ff"],
  7601: ["#3311dd", "#992244", "#cc22bb", "#222288", "#990077"],
  7602: ["#aadddd", "#ee33bb", "#664422", "#3344bb", "#661100"],
  7603: ["#4400bb", "#33ff88", "#11ff00", "#33ccee", "#880044"],
  7604: ["#660066", "#775599", "#558822", "#663311", "#dd22cc"],
  7605: ["#55cc66", "#aaee99", "#4488bb", "#667788", "#cc1199"],
  7606: ["#9977aa", "#99ccee", "#557700", "#99ffdd", "#bb99cc"],
  7607: ["#0055bb", "#22cc11", "#dd77dd", "#ffdd55", "#bb5500"],
  7608: ["#1188cc", "#aaccdd", "#aadd22", "#cc7711", "#8855aa"],
  7609: ["#5544aa", "#ee7799", "#cc5599", "#aa1199", "#008811"],
  7610: ["#773311", "#99dd99", "#eebb88", "#dd33cc", "#338822"],
  7611: ["#ffee22", "#554477", "#22ee55", "#dd3333", "#113355"],
  7612: ["#88ff44", "#447766", "#ff11bb", "#99dd11", "#4466ff"],
  7613: ["#55aa33", "#ccdd77", "#88dd88", "#ee1166", "#669999"],
  7614: ["#00bbbb", "#22dddd", "#443355", "#ffbb33", "#ee6655"],
  7615: ["#7711dd", "#1166dd", "#55bb00", "#998899", "#771122"],
  7616: ["#226644", "#33ffaa", "#228855", "#7755ff", "#00ff55"],
  7617: ["#3333dd", "#cc88ff", "#aaee44", "#227799", "#44ee88"],
  7618: ["#00ffcc", "#8899dd", "#77ddff", "#aa77ff", "#bb4455"],
  7619: ["#5511ee", "#8822aa", "#113366", "#aa2299", "#cc66dd"],
  7620: ["#77ddaa", "#0000aa", "#5555ee", "#8844aa", "#bb3322"],
  7621: ["#11ffbb", "#aabb33", "#994422", "#11ddaa", "#8822dd"],
  7622: ["#997733", "#661100", "#00dd44", "#6644aa", "#66ffcc"],
  7623: ["#447744", "#aadddd", "#339922", "#99ee77", "#eeccdd"],
  7624: ["#22bb77", "#8888cc", "#ffdd22", "#776633", "#222255"],
  7625: ["#eeee00", "#550099", "#22bb44", "#dd88dd", "#cc8877"],
  7626: ["#9933ee", "#ccdddd", "#bb0000", "#eedd66", "#22ee66"],
  7627: ["#cc3388", "#992288", "#77dd00", "#dd0077", "#00dd66"],
  7628: ["#aaddff", "#dd99bb", "#ddbb99", "#aabb88", "#66ff11"],
  7629: ["#bbff77", "#55aacc", "#441133", "#119966", "#55bb11"],
  7630: ["#66aa22", "#aa55aa", "#666600", "#443399", "#dd5544"],
  7631: ["#33dd00", "#441155", "#888822", "#dd2266", "#bb9922"],
  7632: ["#4488aa", "#11bbee", "#0088bb", "#88dd44", "#778833"],
  7633: ["#7711aa", "#bbddbb", "#0055dd", "#113344", "#9999bb"],
  7634: ["#ff7733", "#4411bb", "#330077", "#8899dd", "#aa00ee"],
  7635: ["#558811", "#22ffee", "#9922ff", "#77cccc", "#ee6699"],
  7636: ["#444433", "#cc55bb", "#ff2299", "#aaeeee", "#00ffbb"],
  7637: ["#3300cc", "#22bbbb", "#660000", "#8855ff", "#7733bb"],
  7638: ["#aadd55", "#883311", "#44dd55", "#2299dd", "#33ff88"],
  7639: ["#dd3333", "#6600cc", "#00aadd", "#1122ee", "#cccc66"],
  7640: ["#992211", "#bb9977", "#11ccdd", "#885599", "#ffee44"],
  7641: ["#bbbbff", "#332211", "#bb4466", "#ccee99", "#aa99ff"],
  7642: ["#118800", "#bb9911", "#550088", "#66ff55", "#668800"],
  7643: ["#ff77bb", "#00aa77", "#775588", "#44bb77", "#bbffcc"],
  7644: ["#775533", "#88ffff", "#33ee11", "#cc5588", "#66ff99"],
  7645: ["#ff9933", "#ffddbb", "#777722", "#001188", "#442277"],
  7646: ["#eeccaa", "#eeeeff", "#dd5544", "#119977", "#005566"],
  7647: ["#ddaa66", "#ee5588", "#44ccff", "#44cccc", "#221199"],
  7648: ["#00dd77", "#884444", "#eeffaa", "#dd5566", "#99bbaa"],
  7649: ["#779911", "#005588", "#4488bb", "#8822bb", "#337722"],
  7650: ["#ddcc55", "#8822cc", "#997788", "#3322cc", "#8877ff"],
  7651: ["#aa2233", "#ccee44", "#11eeaa", "#33bbbb", "#dd7777"],
  7652: ["#ff3311", "#33aadd", "#6699bb", "#11bbff", "#882266"],
  7653: ["#885555", "#66eebb", "#771188", "#223377", "#006644"],
  7654: ["#cccc88", "#338855", "#11dddd", "#335522", "#ff9944"],
  7655: ["#558811", "#aa66ff", "#dd1188", "#00ccdd", "#ee44bb"],
  7656: ["#996633", "#ffee66", "#774499", "#cc22cc", "#ffee77"],
  7657: ["#3300aa", "#ee1100", "#119933", "#669922", "#880022"],
  7658: ["#cc22ee", "#ff7722", "#996600", "#cccccc", "#0066ee"],
  7659: ["#ffee66", "#ff2211", "#334411", "#005500", "#99dd99"],
  7660: ["#1188ff", "#cc1144", "#0055ee", "#cc0077", "#553388"],
  7661: ["#bb11cc", "#557788", "#6699bb", "#0000dd", "#dd0022"],
  7662: ["#88ee22", "#6688dd", "#ff00bb", "#337777", "#eeeeff"],
  7663: ["#dd77ee", "#cc5533", "#66ffcc", "#bbff99", "#3355dd"],
  7664: ["#4411ff", "#66dd77", "#ccff00", "#eeeebb", "#aa6699"],
  7665: ["#8866aa", "#4433aa", "#cc5588", "#883333", "#ff4411"],
  7666: ["#229966", "#66ffcc", "#ee8811", "#992200", "#889977"],
  7667: ["#226633", "#bb7755", "#eeff77", "#111177", "#cc2222"],
  7668: ["#ff99ee", "#1100ee", "#ff66cc", "#aa6666", "#eedd22"],
  7669: ["#998844", "#11ee77", "#eeaacc", "#aadd55", "#ffdddd"],
  7670: ["#11cc99", "#3300cc", "#77bb88", "#775544", "#6688ff"],
  7671: ["#ff33ee", "#9900aa", "#998877", "#44cc00", "#2299ee"],
  7672: ["#44ffcc", "#ff6644", "#11aa55", "#66ee77", "#88ddbb"],
  7673: ["#111177", "#550044", "#bbffff", "#ccbb33", "#bb4422"],
  7674: ["#ccbb99", "#335511", "#33aa66", "#446688", "#ee88ff"],
  7675: ["#88aaee", "#77ddaa", "#995577", "#7711cc", "#8811ff"],
  7676: ["#1188bb", "#448844", "#002244", "#113300", "#558833"],
  7677: ["#aa3300", "#aa2244", "#dd66bb", "#dd99dd", "#22dd55"],
  7678: ["#1166dd", "#880055", "#113355", "#2299dd", "#2288ff"],
  7679: ["#bb3322", "#6600aa", "#dd44cc", "#cc44cc", "#eeaa99"],
  7680: ["#bbff22", "#66bb55", "#ff33ff", "#667733", "#6666dd"],
  7681: ["#338844", "#ccddbb", "#ccee88", "#00cc44", "#888877"],
  7682: ["#66ee55", "#55bb66", "#447788", "#99eecc", "#2200bb"],
  7683: ["#dd66bb", "#77bbbb", "#eeeeee", "#4422cc", "#225577"],
  7684: ["#ffcccc", "#aa5599", "#6677dd", "#eebb00", "#cccc88"],
  7685: ["#ccaa44", "#88dd99", "#dd55dd", "#66cc44", "#5500bb"],
  7686: ["#ccbb22", "#77aa55", "#ee88aa", "#554400", "#ffff55"],
  7687: ["#66ee55", "#66dd33", "#55cc22", "#dd33dd", "#6644dd"],
  7688: ["#ccffee", "#3344ff", "#55bb99", "#aaff44", "#66ccdd"],
  7689: ["#cc6644", "#119955", "#dd0077", "#44ddaa", "#9966bb"],
  7690: ["#4400dd", "#557733", "#55ddaa", "#441177", "#44aa44"],
  7691: ["#22ddee", "#99bbdd", "#ff9999", "#005511", "#00ff44"],
  7692: ["#44cc66", "#33aa44", "#9988dd", "#99aa33", "#55aaee"],
  7693: ["#aa33aa", "#cc0088", "#eeeebb", "#55ddbb", "#0066cc"],
  7694: ["#eedd88", "#aabb00", "#1111ff", "#11bb00", "#ccaa77"],
  7695: ["#557744", "#113366", "#002255", "#88bb22", "#dd22aa"],
  7696: ["#eeee66", "#22ffee", "#77eebb", "#66cc55", "#cc1122"],
  7697: ["#22ff66", "#ffeeff", "#dd44cc", "#774488", "#00cc33"],
  7698: ["#aa55ff", "#667700", "#ccee88", "#bbccff", "#556633"],
  7699: ["#66aa11", "#aabb22", "#22dd44", "#ee0088", "#dd1122"],
  7700: ["#00ff33", "#0033ee", "#cc3377", "#00cccc", "#bbaa55"],
  7701: ["#11ffdd", "#dd8877", "#aacc88", "#ffbbff", "#aa44dd"],
  7702: ["#77aa33", "#ee5566", "#227722", "#dd0033", "#00bbdd"],
  7703: ["#bb55ee", "#bb1144", "#2200dd", "#ddcccc", "#4499ee"],
  7704: ["#002244", "#eecc33", "#ee6677", "#88aa99", "#8877cc"],
  7705: ["#332233", "#998833", "#aa7744", "#eedd00", "#330022"],
  7706: ["#cc55cc", "#9966dd", "#222211", "#cc66aa", "#ff33cc"],
  7707: ["#225511", "#110044", "#cc88aa", "#aa44dd", "#559900"],
  7708: ["#66eeee", "#33aaaa", "#225566", "#ee77aa", "#553366"],
  7709: ["#ffffcc", "#88eeaa", "#dd9955", "#bb8888", "#22cc88"],
  7710: ["#ff6688", "#66cc00", "#3388ff", "#223322", "#aa44bb"],
  7711: ["#22cccc", "#cc3333", "#447700", "#2266cc", "#009966"],
  7712: ["#22bb33", "#4466bb", "#88ffdd", "#aa55bb", "#441100"],
  7713: ["#ff2299", "#331177", "#3322ff", "#ccaa88", "#bb66ee"],
  7714: ["#ee6600", "#44ee77", "#3399ee", "#77ffbb", "#33bbcc"],
  7715: ["#4400aa", "#ff22bb", "#dd5522", "#bb3344", "#66cc33"],
  7716: ["#7722ff", "#333311", "#992200", "#ff4466", "#66ffdd"],
  7717: ["#11aa99", "#99ddbb", "#440055", "#dd66aa", "#22ddcc"],
  7718: ["#662233", "#dd4466", "#ee3366", "#995522", "#aaddcc"],
  7719: ["#aa7755", "#ee22dd", "#335544", "#dd7755", "#226655"],
  7720: ["#bbccbb", "#bb66ff", "#99aa99", "#55dd44", "#eedd77"],
  7721: ["#bb9955", "#887766", "#55ee22", "#ddaa11", "#aa5577"],
  7722: ["#3388dd", "#008866", "#ff3322", "#66ee55", "#5544aa"],
  7723: ["#88ee66", "#33aa44", "#881166", "#77aaee", "#bb1166"],
  7724: ["#dd2222", "#99ccdd", "#224400", "#11aa00", "#88ffff"],
  7725: ["#aaaa66", "#006655", "#557777", "#ee00aa", "#881144"],
  7726: ["#eeccee", "#33aaaa", "#bb8866", "#006688", "#22eeee"],
  7727: ["#227766", "#ffffee", "#aa33dd", "#558800", "#66dd11"],
  7728: ["#228877", "#cc00aa", "#66dd55", "#44aa66", "#0011aa"],
  7729: ["#22ff11", "#22cc44", "#aa5577", "#bb2266", "#556622"],
  7730: ["#88ff66", "#22dd77", "#662211", "#33ee00", "#11ffff"],
  7731: ["#cc0055", "#2288dd", "#bbdddd", "#aa88ff", "#22aacc"],
  7732: ["#5555ff", "#22ff44", "#551177", "#556622", "#998877"],
  7733: ["#9900bb", "#ffaa99", "#222255", "#008833", "#991188"],
  7734: ["#aa11dd", "#77dd44", "#99ee77", "#332200", "#773399"],
  7735: ["#330099", "#88ddcc", "#999999", "#5588dd", "#ffbb22"],
  7736: ["#4488cc", "#2222ff", "#dd6600", "#77ee99", "#447711"],
  7737: ["#552233", "#228811", "#6655ff", "#55ddee", "#ffffaa"],
  7738: ["#00bb88", "#cc99ff", "#aaeebb", "#ffeeee", "#22bbee"],
  7739: ["#774444", "#110099", "#ee9977", "#443399", "#887744"],
  7740: ["#ee77ff", "#ff2233", "#dd11bb", "#ccaa88", "#8822bb"],
  7741: ["#006688", "#88ff00", "#dd5599", "#77cc55", "#22bb99"],
  7742: ["#6644ee", "#33aa55", "#9977cc", "#ee00dd", "#22bb22"],
  7743: ["#88dd00", "#ee2233", "#1188cc", "#cc1133", "#aadd33"],
  7744: ["#224400", "#66cc44", "#bbbb99", "#bbee11", "#9922aa"],
  7745: ["#333355", "#ffaa22", "#aa99bb", "#0088cc", "#44cc11"],
  7746: ["#779988", "#774411", "#55cc88", "#ddbbaa", "#334455"],
  7747: ["#11aacc", "#aaccdd", "#3322ff", "#aa1188", "#dd4422"],
  7748: ["#ffee00", "#448888", "#ffffaa", "#99ff00", "#55bb22"],
  7749: ["#99bb99", "#660044", "#1133dd", "#ff3388", "#4466dd"],
  7750: ["#117788", "#dd5577", "#ee6633", "#22ccee", "#228833"],
  7751: ["#ff11aa", "#44dd00", "#990055", "#ee1144", "#55cc33"],
  7752: ["#887744", "#ddaaff", "#ff7711", "#00ddcc", "#446688"],
  7753: ["#6699ff", "#1122ff", "#33ee11", "#ccffee", "#dd5533"],
  7754: ["#cc11ee", "#00aaee", "#dd0099", "#884444", "#6600aa"],
  7755: ["#4477bb", "#dd9944", "#cc9955", "#8899dd", "#5599ee"],
  7756: ["#77ee66", "#114477", "#55cc00", "#ff99ff", "#1188aa"],
  7757: ["#6633bb", "#33ee88", "#bbee66", "#55cc00", "#66dd66"],
  7758: ["#bbee44", "#bbddcc", "#0077ff", "#eecc11", "#dd00cc"],
  7759: ["#ddddbb", "#bbbbaa", "#9911dd", "#2288dd", "#554455"],
  7760: ["#cc33dd", "#3399ff", "#0077aa", "#1155ff", "#11bbaa"],
  7761: ["#ee4422", "#aacc66", "#44ee33", "#331111", "#ffccdd"],
  7762: ["#00aadd", "#0077ff", "#aabb99", "#11ccdd", "#005555"],
  7763: ["#5599dd", "#aacc77", "#7733bb", "#cc66cc", "#aa3366"],
  7764: ["#11cc11", "#aaffee", "#2244bb", "#bb9911", "#eeff88"],
  7765: ["#774411", "#00dd44", "#dd7766", "#77bb77", "#8888dd"],
  7766: ["#ff77ee", "#ee44bb", "#eecc44", "#11cc77", "#cc7711"],
  7767: ["#bb2233", "#2211cc", "#44ddee", "#33bbbb", "#ddeeee"],
  7768: ["#ee3333", "#44ffaa", "#ccff77", "#2200aa", "#009933"],
  7769: ["#558800", "#33bbdd", "#334499", "#115522", "#9911dd"],
  7770: ["#4400ff", "#994433", "#1177bb", "#bb44dd", "#bb99ee"],
  7771: ["#ee2288", "#aa4488", "#cc1155", "#9944dd", "#661133"],
  7772: ["#77ddaa", "#ee1177", "#bbeebb", "#665522", "#22ff11"],
  7773: ["#224400", "#bb0033", "#44aa66", "#221133", "#772266"],
  7774: ["#bbffaa", "#ee44ff", "#3377cc", "#99aaff", "#114488"],
  7775: ["#dd77aa", "#00bb66", "#66ff66", "#996622", "#ee88bb"],
  7776: ["#11ee66", "#cc7755", "#bbbb33", "#99bb55", "#ff7722"],
  7777: ["#cc22dd", "#00bb00", "#bb7777", "#880044", "#992200"],
  7778: ["#cc7788", "#553377", "#66aa55", "#22dd88", "#9988cc"],
  7779: ["#eeaaff", "#dd11aa", "#993322", "#eebb88", "#aadd77"],
  7780: ["#880011", "#22ff44", "#337744", "#558899", "#339944"],
  7781: ["#776677", "#331111", "#ee8844", "#00ffdd", "#eeffaa"],
  7782: ["#5566ff", "#aa2233", "#4444ee", "#66ddaa", "#8877aa"],
  7783: ["#225566", "#777744", "#229900", "#aa8899", "#551177"],
  7784: ["#aa2222", "#ee6600", "#ff4422", "#2288ee", "#99aa88"],
  7785: ["#dd44dd", "#77eedd", "#0022bb", "#668822", "#33eeee"],
  7786: ["#118844", "#334422", "#77dd33", "#bb22ff", "#bb7766"],
  7787: ["#448844", "#4444cc", "#ff1111", "#ccaa77", "#ff1122"],
  7788: ["#44cc22", "#cc44aa", "#99cc00", "#006622", "#ff44dd"],
  7789: ["#ff8899", "#992222", "#223333", "#5522bb", "#4400bb"],
  7790: ["#33ff00", "#005522", "#0011bb", "#dd3399", "#bbddee"],
  7791: ["#003355", "#eecc11", "#335599", "#aaaa88", "#00cc55"],
  7792: ["#3344ee", "#dd22dd", "#22ffbb", "#ddee22", "#00ee44"],
  7793: ["#775599", "#cccc55", "#eeee99", "#dd3399", "#33ee99"],
  7794: ["#ffcc66", "#ee8855", "#22cc11", "#aa33ee", "#ee00ee"],
  7795: ["#115599", "#aa44bb", "#bb11ee", "#88ccee", "#662244"],
  7796: ["#992266", "#cceeaa", "#22ccff", "#11ffbb", "#eeff33"],
  7797: ["#ddcc99", "#eedd99", "#ee3366", "#ffffbb", "#dddd22"],
  7798: ["#228866", "#cc6644", "#11cc99", "#cc5577", "#442266"],
  7799: ["#cc44ee", "#ff88dd", "#999900", "#558811", "#33ffaa"],
  7800: ["#33cc44", "#552266", "#771144", "#005566", "#88bb88"],
  7801: ["#330033", "#bb9955", "#331111", "#bbbbff", "#88aa66"],
  7802: ["#5522cc", "#11ee99", "#77aa22", "#bb5511", "#66bb33"],
  7803: ["#77cc00", "#bb0011", "#33dd77", "#66dd77", "#552244"],
  7804: ["#ee2233", "#ddddff", "#22dd77", "#333300", "#225555"],
  7805: ["#bbbb44", "#33ddbb", "#44bbbb", "#ee22dd", "#77ffff"],
  7806: ["#3300dd", "#ff3399", "#cc9944", "#00dd33", "#9922ff"],
  7807: ["#44ddbb", "#11ddcc", "#aa3388", "#9922aa", "#226677"],
  7808: ["#00eecc", "#772288", "#ccdd77", "#11dd99", "#cc0011"],
  7809: ["#008844", "#1177bb", "#004433", "#dddd66", "#110077"],
  7810: ["#773344", "#aaaa77", "#00ee22", "#220088", "#77aa99"],
  7811: ["#117777", "#cccc11", "#880066", "#4499aa", "#66dd66"],
  7812: ["#ddcc11", "#bb66dd", "#cc00bb", "#11ff33", "#bb9988"],
  7813: ["#66ff66", "#22ee44", "#4422ee", "#bb5577", "#00ddcc"],
  7814: ["#112299", "#6699cc", "#bb5555", "#3344ee", "#339944"],
  7815: ["#bb4422", "#448877", "#4411aa", "#cc33ee", "#006644"],
  7816: ["#aaddee", "#77bb44", "#225533", "#88cc44", "#ffddbb"],
  7817: ["#ffbbdd", "#331199", "#99aa33", "#ccbbbb", "#661133"],
  7818: ["#eebb11", "#2266bb", "#0011dd", "#ffdd77", "#669933"],
  7819: ["#7766cc", "#993388", "#cc66ff", "#55ff88", "#eecc88"],
  7820: ["#885566", "#9988cc", "#bb0099", "#ddffcc", "#2288dd"],
  7821: ["#aacc44", "#ee0044", "#33bb00", "#ffaa22", "#002244"],
  7822: ["#001166", "#3344ee", "#00ddcc", "#cc2233", "#ccff66"],
  7823: ["#7766cc", "#bb33cc", "#ff44ff", "#5544aa", "#ddff55"],
  7824: ["#664422", "#cc2299", "#22ff22", "#aa3344", "#88ee66"],
  7825: ["#77ffee", "#6699bb", "#ccaa99", "#cc1100", "#77bb77"],
  7826: ["#eeaa00", "#bbee77", "#33bbbb", "#55aa33", "#9999dd"],
  7827: ["#aa6622", "#773377", "#bb3344", "#ddaa66", "#ddbb88"],
  7828: ["#22aa99", "#ffff22", "#4400ee", "#ff33ee", "#ccaadd"],
  7829: ["#001199", "#227733", "#669933", "#66ccaa", "#5577ee"],
  7830: ["#bb99ee", "#116611", "#ddaa66", "#44bb22", "#669999"],
  7831: ["#88dddd", "#ffccee", "#dd6655", "#7711bb", "#cc9988"],
  7832: ["#88ccdd", "#ffccaa", "#cc0033", "#66dd44", "#111177"],
  7833: ["#77aa11", "#dd1199", "#8855dd", "#442266", "#770022"],
  7834: ["#55cc00", "#6666ff", "#aa88bb", "#449966", "#ff8833"],
  7835: ["#337700", "#3322dd", "#66eeee", "#77ff66", "#ee3333"],
  7836: ["#7766aa", "#ee8899", "#00dddd", "#eeff88", "#447788"],
  7837: ["#44eebb", "#4488bb", "#11bb66", "#449900", "#4488aa"],
  7838: ["#aa4488", "#66aa44", "#bbccee", "#0088dd", "#334488"],
  7839: ["#99aaaa", "#ddff22", "#005588", "#6666bb", "#332277"],
  7840: ["#88ccff", "#ddccee", "#bb22aa", "#778888", "#66dd22"],
  7841: ["#ffbbcc", "#22ff77", "#22ee99", "#11ee77", "#22dd77"],
  7842: ["#664477", "#662233", "#11eedd", "#228855", "#22dd00"],
  7843: ["#113300", "#bbbb33", "#eeee66", "#44ffcc", "#00ffdd"],
  7844: ["#dd5555", "#11bb11", "#334411", "#223344", "#334488"],
  7845: ["#ff4477", "#330088", "#aa2222", "#aa77cc", "#cc55dd"],
  7846: ["#bb88ee", "#00aa44", "#ffdd66", "#559955", "#99dd77"],
  7847: ["#dd9977", "#bbcc66", "#ddbbdd", "#55aa77", "#ff99dd"],
  7848: ["#11cc55", "#660099", "#33bbdd", "#aa44ee", "#ee0088"],
  7849: ["#ee5522", "#3300ee", "#772211", "#ccee66", "#221199"],
  7850: ["#33bb33", "#bbaa66", "#ee7700", "#882211", "#117766"],
  7851: ["#22ccff", "#ee6677", "#995511", "#ee5511", "#225588"],
  7852: ["#bbccbb", "#55ff33", "#778888", "#11cc11", "#4455aa"],
  7853: ["#445522", "#eeaa99", "#99ddaa", "#8877cc", "#882288"],
  7854: ["#66ccff", "#bbdd22", "#22eebb", "#bbeedd", "#ccffff"],
  7855: ["#66aa11", "#dd2277", "#994455", "#9922aa", "#dddd88"],
  7856: ["#88ee33", "#bb2288", "#bbaa77", "#229911", "#4433ee"],
  7857: ["#223388", "#774488", "#229922", "#99ee22", "#229900"],
  7858: ["#ee77bb", "#55bb55", "#ddbb00", "#776622", "#ddbbdd"],
  7859: ["#ffddaa", "#dd5500", "#ee8866", "#66dddd", "#3300ee"],
  7860: ["#ff55ee", "#3322cc", "#33aaee", "#664466", "#337733"],
  7861: ["#aa1188", "#445511", "#997755", "#66aaaa", "#ffdd77"],
  7862: ["#445555", "#aaaa22", "#331199", "#aaff88", "#cc4455"],
  7863: ["#229977", "#dd1199", "#882288", "#00ff44", "#338888"],
  7864: ["#003399", "#ff6611", "#ff0077", "#9999ee", "#ccdd77"],
  7865: ["#1199cc", "#ff8888", "#ff55aa", "#aa00dd", "#0099bb"],
  7866: ["#7733aa", "#00dd77", "#664455", "#9900dd", "#eeaadd"],
  7867: ["#dddddd", "#9933cc", "#aa1111", "#dd44dd", "#ddaaaa"],
  7868: ["#88aa55", "#4400ff", "#99ee33", "#000033", "#cc5566"],
  7869: ["#dd99aa", "#33ddcc", "#ee1122", "#99cc11", "#448899"],
  7870: ["#3322aa", "#881111", "#22dd55", "#448888", "#334433"],
  7871: ["#4422aa", "#ee5533", "#9988ee", "#114400", "#ee4433"],
  7872: ["#990033", "#55dd99", "#44bb99", "#9955aa", "#2288dd"],
  7873: ["#66ff33", "#aa3377", "#998855", "#88bb55", "#44aabb"],
  7874: ["#332288", "#775544", "#220099", "#eeff22", "#bb33aa"],
  7875: ["#8844ff", "#330099", "#993333", "#ee8888", "#11aa00"],
  7876: ["#22ee33", "#aa44ee", "#11ddee", "#ee2244", "#8800bb"],
  7877: ["#1133ff", "#99ffcc", "#1100cc", "#cc33bb", "#88bbdd"],
  7878: ["#6633dd", "#0066dd", "#ff4444", "#44aa77", "#aa8888"],
  7879: ["#ee9944", "#ffcc33", "#88ff99", "#ccccaa", "#77ff77"],
  7880: ["#ff6666", "#99cc55", "#335566", "#448844", "#55bb22"],
  7881: ["#ff7711", "#bb2255", "#dd1133", "#333300", "#119900"],
  7882: ["#eeffaa", "#774444", "#1122bb", "#6611bb", "#bbccdd"],
  7883: ["#005599", "#331111", "#337744", "#007733", "#777733"],
  7884: ["#99dd88", "#99ff22", "#228877", "#559977", "#11ccaa"],
  7885: ["#0088dd", "#77dd22", "#77dd66", "#9933cc", "#cc88dd"],
  7886: ["#22aa77", "#4488ff", "#551133", "#338877", "#8811bb"],
  7887: ["#bb44aa", "#88bb00", "#44ffbb", "#6655dd", "#77bb44"],
  7888: ["#44bb55", "#776622", "#aa7722", "#ff7766", "#11ccaa"],
  7889: ["#aaffff", "#bbbbbb", "#dd99ff", "#55eeaa", "#66ff33"],
  7890: ["#9966ff", "#bb9977", "#5566aa", "#335555", "#ffdd44"],
  7891: ["#665599", "#cc9988", "#ccbbcc", "#ee2244", "#aaee99"],
  7892: ["#3300ff", "#dd6655", "#773366", "#667755", "#ccddcc"],
  7893: ["#bb7733", "#772288", "#995555", "#cc5522", "#ee44bb"],
  7894: ["#aa11dd", "#22bb11", "#dd8811", "#228800", "#77ee77"],
  7895: ["#006688", "#772222", "#bb66cc", "#eebbdd", "#880011"],
  7896: ["#339911", "#6622bb", "#ffbb66", "#4400bb", "#cc5588"],
  7897: ["#ddddee", "#eebb55", "#2299aa", "#998866", "#4433ff"],
  7898: ["#eeee88", "#bbdd55", "#dd8877", "#ff11ff", "#eedd11"],
  7899: ["#aa77cc", "#558888", "#00bbff", "#66bb66", "#5599ee"],
  7900: ["#88aa88", "#77ff00", "#663355", "#eeff00", "#5500cc"],
  7901: ["#dd6688", "#99eeaa", "#2266cc", "#77ff44", "#2277ff"],
  7902: ["#0066aa", "#00ee99", "#880033", "#442244", "#cc6699"],
  7903: ["#66aa66", "#cc44cc", "#11bb22", "#aa2266", "#ffeeff"],
  7904: ["#4444aa", "#11bbbb", "#009933", "#dd00dd", "#ddaa66"],
  7905: ["#dd2255", "#007700", "#88ffdd", "#444444", "#991199"],
  7906: ["#778822", "#992200", "#2255aa", "#22cc55", "#773366"],
  7907: ["#ff2244", "#00cc55", "#88cccc", "#ee9988", "#bb2277"],
  7908: ["#44eeff", "#7766bb", "#ccdddd", "#6677bb", "#6622ff"],
  7909: ["#990099", "#3377cc", "#dd8833", "#335511", "#ff0066"],
  7910: ["#ee55aa", "#88cc33", "#339955", "#00aacc", "#0077cc"],
  7911: ["#77ee77", "#ee99aa", "#553366", "#9922dd", "#aa0033"],
  7912: ["#330044", "#774411", "#ccccee", "#0033ee", "#11bbbb"],
  7913: ["#88dd66", "#77eedd", "#779911", "#88aa11", "#ee44aa"],
  7914: ["#aa44aa", "#4411ff", "#bb5599", "#009999", "#002200"],
  7915: ["#ffff99", "#339922", "#aa99ff", "#229999", "#44aa88"],
  7916: ["#77ee55", "#4499bb", "#44ff22", "#ccaa88", "#ff3300"],
  7917: ["#88ee44", "#8899ff", "#cc7722", "#ee5500", "#8833aa"],
  7918: ["#991144", "#001144", "#7733bb", "#77aa22", "#00dd77"],
  7919: ["#bbff11", "#3300cc", "#55aa77", "#77bb66", "#99eecc"],
  7920: ["#33dd88", "#bbccbb", "#008888", "#cc6699", "#11cc33"],
  7921: ["#888888", "#88ddee", "#4499dd", "#eebb44", "#ccaa88"],
  7922: ["#00bb55", "#668855", "#333399", "#776666", "#ddbb66"],
  7923: ["#44ee88", "#88aaee", "#cc3355", "#aa33bb", "#ffddee"],
  7924: ["#99aa88", "#449988", "#44dd55", "#bb2299", "#440022"],
  7925: ["#881155", "#22bb44", "#cc44cc", "#444422", "#2255ee"],
  7926: ["#eecc22", "#bbccdd", "#220022", "#334444", "#000044"],
  7927: ["#bbeeee", "#eedd44", "#cc6688", "#00dd11", "#bbeeee"],
  7928: ["#bb22dd", "#ff22bb", "#66dd88", "#002299", "#ff77cc"],
  7929: ["#3344bb", "#99bbcc", "#ff9911", "#44ff44", "#22ccee"],
  7930: ["#aa44dd", "#77aabb", "#3388ee", "#77ddff", "#dd2244"],
  7931: ["#337700", "#001166", "#111133", "#ffbb99", "#aaffcc"],
  7932: ["#77ccbb", "#ddbbbb", "#bb6655", "#bb7733", "#cc0099"],
  7933: ["#226688", "#77aaff", "#99ffff", "#cc66dd", "#8866dd"],
  7934: ["#88ddaa", "#119933", "#ccdd55", "#dd4422", "#8888cc"],
  7935: ["#dddd00", "#bb6699", "#ffaaaa", "#dd8877", "#aa66aa"],
  7936: ["#00bbbb", "#ff4444", "#ffcc77", "#bb33bb", "#88ddee"],
  7937: ["#cc88ff", "#44ee00", "#55bb88", "#229911", "#aa22ee"],
  7938: ["#3355dd", "#ddeebb", "#5500dd", "#ff3344", "#33ccff"],
  7939: ["#ccdd44", "#aaddff", "#cc1100", "#ee33ee", "#dd3311"],
  7940: ["#aa55bb", "#5522ee", "#449977", "#224433", "#88ffee"],
  7941: ["#33ff77", "#bbaaaa", "#22cccc", "#8866ee", "#3366ee"],
  7942: ["#667733", "#aa00dd", "#337700", "#7755aa", "#664499"],
  7943: ["#44bbcc", "#ff77ff", "#4433cc", "#8822ff", "#66ccbb"],
  7944: ["#0088cc", "#ddbb22", "#444466", "#bbffee", "#dd7733"],
  7945: ["#00aa44", "#ee1111", "#999955", "#333333", "#eeaa77"],
  7946: ["#44dd33", "#aa33cc", "#aaee66", "#ffaa22", "#ff00ff"],
  7947: ["#4455ff", "#884422", "#228888", "#007700", "#222233"],
  7948: ["#66aa11", "#cc44ee", "#332200", "#6633dd", "#3399aa"],
  7949: ["#553366", "#ccaa44", "#bb00dd", "#ccbb88", "#225566"],
  7950: ["#66cc11", "#228855", "#0044cc", "#aa00bb", "#00bbcc"],
  7951: ["#665533", "#004466", "#ee5533", "#552233", "#669966"],
  7952: ["#223311", "#66ee44", "#22cc22", "#cc2233", "#99cc00"],
  7953: ["#00ff88", "#44dd44", "#66bb22", "#dd0022", "#9966aa"],
  7954: ["#dd7755", "#ffbbdd", "#223333", "#aa8866", "#334433"],
  7955: ["#881100", "#ccff66", "#ffeeff", "#77ffee", "#554477"],
  7956: ["#22dd11", "#bb2233", "#553366", "#ccdd66", "#881188"],
  7957: ["#11ff11", "#4433ee", "#dd8833", "#33ff44", "#ddaadd"],
  7958: ["#553355", "#4477aa", "#114411", "#bb6611", "#225544"],
  7959: ["#5544cc", "#88aabb", "#6644dd", "#11ccff", "#336655"],
  7960: ["#552222", "#66ee22", "#eeaa55", "#ee11ff", "#ee7799"],
  7961: ["#bb2244", "#5555aa", "#223366", "#331144", "#22aa88"],
  7962: ["#ff4411", "#555599", "#7700bb", "#222255", "#777755"],
  7963: ["#551177", "#aa00ee", "#bb1133", "#669933", "#ee33ee"],
  7964: ["#11aa66", "#441177", "#bbee44", "#aa66ff", "#550022"],
  7965: ["#11dd00", "#bbff11", "#331166", "#dd3300", "#cc66aa"],
  7966: ["#555566", "#9999cc", "#774400", "#55bbaa", "#000055"],
  7967: ["#55bbdd", "#ee33cc", "#44aaaa", "#aa11cc", "#bbaa00"],
  7968: ["#5577ff", "#ee5588", "#443355", "#ffaaaa", "#3355aa"],
  7969: ["#ffaaff", "#118899", "#881111", "#dd1144", "#ff3322"],
  7970: ["#1100bb", "#229922", "#77ddbb", "#99aa55", "#ee00cc"],
  7971: ["#88dddd", "#dd8822", "#004411", "#5588ff", "#663377"],
  7972: ["#331166", "#ddff66", "#ffffbb", "#9911bb", "#bbaabb"],
  7973: ["#aaaaff", "#993333", "#ff6688", "#00ee66", "#4466aa"],
  7974: ["#998877", "#88eedd", "#ffddbb", "#55ddee", "#bb88ee"],
  7975: ["#33ee22", "#bbcccc", "#cc5533", "#664499", "#66aa88"],
  7976: ["#22ee44", "#33dd99", "#ff11cc", "#5533bb", "#ee6611"],
  7977: ["#11ccff", "#88aaee", "#ccbb77", "#aa7788", "#6666ee"],
  7978: ["#bb99dd", "#ee6611", "#223333", "#aa33dd", "#aa99cc"],
  7979: ["#aaeeaa", "#005555", "#aa3344", "#44dd77", "#33dd77"],
  7980: ["#ff9955", "#6622aa", "#ccbbaa", "#227711", "#ff6611"],
  7981: ["#11ff55", "#6688aa", "#0055dd", "#aa4444", "#661166"],
  7982: ["#7733ff", "#22ff77", "#552255", "#554466", "#ff5588"],
  7983: ["#44aadd", "#226655", "#bbee22", "#660088", "#aabb77"],
  7984: ["#ddccdd", "#666600", "#bb6633", "#557700", "#555544"],
  7985: ["#ff3333", "#cc7777", "#339933", "#110022", "#aa0099"],
  7986: ["#331122", "#66cc66", "#889922", "#99dd11", "#ee8800"],
  7987: ["#118877", "#dd6622", "#997777", "#ff88aa", "#aaaa00"],
  7988: ["#7733ff", "#dd4411", "#00bbcc", "#66eeff", "#3366aa"],
  7989: ["#77aaff", "#bbee77", "#ffbb33", "#bbbb11", "#556622"],
  7990: ["#cc0033", "#ddee44", "#885533", "#663366", "#cc0011"],
  7991: ["#0011ff", "#ee33ff", "#bb5599", "#ee99cc", "#004422"],
  7992: ["#772222", "#55dd44", "#dd4433", "#6677ff", "#6600bb"],
  7993: ["#333300", "#779977", "#ff4400", "#8811ee", "#007733"],
  7994: ["#aa1144", "#ccddcc", "#ee6611", "#77bbee", "#991144"],
  7995: ["#003300", "#dd1122", "#aa1111", "#55bb88", "#aa22ff"],
  7996: ["#999900", "#5566aa", "#22dd66", "#ff88aa", "#001188"],
  7997: ["#660033", "#6600cc", "#995522", "#cc8844", "#ff9900"],
  7998: ["#332211", "#ddaacc", "#cc4433", "#88ddee", "#dd9977"],
  7999: ["#bbdddd", "#444499", "#007777", "#99ff44", "#22aa66"],
  8000: ["#3366dd", "#cc66cc", "#ee6644", "#dd2244", "#ff1122"],
  8001: ["#11ffff", "#ff9911", "#9977aa", "#ee9955", "#9922bb"],
  8002: ["#228811", "#662255", "#aaaabb", "#dd44aa", "#005555"],
  8003: ["#eeffdd", "#55dd33", "#5533ff", "#77eebb", "#0099aa"],
  8004: ["#6655dd", "#aaff99", "#8800bb", "#22ff88", "#eeccbb"],
  8005: ["#66aacc", "#337755", "#8833aa", "#44aa77", "#0088dd"],
  8006: ["#bbdd22", "#223300", "#777777", "#dddd99", "#668855"],
  8007: ["#ffdd66", "#22ff66", "#552222", "#665533", "#771144"],
  8008: ["#1111cc", "#992277", "#dddd33", "#88ddaa", "#331144"],
  8009: ["#33ccdd", "#99cc99", "#ee9900", "#ee4400", "#cc1111"],
  8010: ["#22ee00", "#5544cc", "#66ffee", "#bb6655", "#55ff22"],
  8011: ["#22bb00", "#99bb99", "#5500ee", "#6688ee", "#ffcc88"],
  8012: ["#884488", "#aacc11", "#aaaaee", "#88dd88", "#44bb33"],
  8013: ["#11ffbb", "#11aabb", "#993322", "#55dd66", "#00ccee"],
  8014: ["#bbeeaa", "#ddbbbb", "#226655", "#77ee77", "#ee0000"],
  8015: ["#220044", "#ddeeff", "#aaff11", "#660099", "#448855"],
  8016: ["#004411", "#55cc55", "#ff7733", "#aaeeee", "#99cc00"],
  8017: ["#229922", "#ff33ee", "#777755", "#889966", "#445566"],
  8018: ["#dd6622", "#ddeebb", "#997722", "#99bbbb", "#008877"],
  8019: ["#88ee33", "#0011aa", "#ee8800", "#661122", "#ee2266"],
  8020: ["#7755dd", "#9999dd", "#ccff22", "#77ee44", "#00ffaa"],
  8021: ["#ddcc55", "#337766", "#33cc22", "#00bb00", "#3344cc"],
  8022: ["#bbee11", "#ee00cc", "#111133", "#772288", "#aa99aa"],
  8023: ["#ff99dd", "#443377", "#ee8899", "#dd33aa", "#777733"],
  8024: ["#bbcc22", "#ff0099", "#227733", "#442299", "#99cc22"],
  8025: ["#bb5566", "#22bbdd", "#cc00dd", "#2222ee", "#ff6666"],
  8026: ["#cc0066", "#99ee99", "#aa22ff", "#aa5555", "#993333"],
  8027: ["#33ff99", "#88ff66", "#66ee66", "#99aaff", "#88cc99"],
  8028: ["#888833", "#009922", "#660077", "#666644", "#cc00bb"],
  8029: ["#007755", "#ff6688", "#88ee77", "#ee22ee", "#99bbcc"],
  8030: ["#11ff77", "#33dd88", "#33aaff", "#aadd55", "#ff55aa"],
  8031: ["#116644", "#dd4400", "#667744", "#aa9933", "#551155"],
  8032: ["#66ee66", "#77ddcc", "#88ffbb", "#003322", "#dd66aa"],
  8033: ["#00cc00", "#113366", "#ccdd22", "#ffbb33", "#ddaaff"],
  8034: ["#ff22cc", "#008844", "#223311", "#2277ee", "#994400"],
  8035: ["#333311", "#339944", "#338888", "#0088ff", "#997744"],
  8036: ["#99ff11", "#ee11ff", "#ffff00", "#33bbff", "#ff5500"],
  8037: ["#88eedd", "#777788", "#bbccaa", "#000044", "#116611"],
  8038: ["#ccdd22", "#77ddff", "#aa22bb", "#dddd88", "#ddcc33"],
  8039: ["#ff3366", "#667711", "#88ee55", "#99bb88", "#99bb33"],
  8040: ["#882233", "#dd4433", "#cc22ff", "#662288", "#558811"],
  8041: ["#009999", "#dd3399", "#aa7788", "#cc9911", "#776655"],
  8042: ["#cc9999", "#4444cc", "#bb6644", "#ee1122", "#ee8866"],
  8043: ["#9922aa", "#330066", "#99aabb", "#cc6677", "#bbbbff"],
  8044: ["#8866ee", "#aa22cc", "#cc55ee", "#55ff11", "#880099"],
  8045: ["#bbee55", "#33bbcc", "#776644", "#55ff33", "#aacc77"],
  8046: ["#0066cc", "#772211", "#88cc66", "#cc66bb", "#bb77dd"],
  8047: ["#66eedd", "#55bb44", "#ff2255", "#aabbbb", "#00aa33"],
  8048: ["#22ff44", "#bb7755", "#000000", "#888800", "#cc99cc"],
  8049: ["#ccdd22", "#6611aa", "#773322", "#002288", "#5522dd"],
  8050: ["#22ddaa", "#0044aa", "#334400", "#33aaff", "#44ddbb"],
  8051: ["#99cc77", "#bbccaa", "#005555", "#995533", "#22ffee"],
  8052: ["#7700ee", "#999955", "#889911", "#663300", "#aaff44"],
  8053: ["#99dd44", "#441155", "#eebbbb", "#1199cc", "#991133"],
  8054: ["#55ffbb", "#cc11aa", "#33dd00", "#cccc11", "#eebb44"],
  8055: ["#2255ee", "#ee7755", "#11ccaa", "#661100", "#ddaaaa"],
  8056: ["#ffeecc", "#ddccaa", "#1100ff", "#cc22bb", "#339999"],
  8057: ["#55ff55", "#bbeeaa", "#1111ff", "#227700", "#ff8800"],
  8058: ["#779944", "#665544", "#ccaa44", "#7788bb", "#332255"],
  8059: ["#8899aa", "#44dd00", "#88cc22", "#776633", "#99cc66"],
  8060: ["#aa4477", "#111177", "#22ffbb", "#335566", "#eeccee"],
  8061: ["#aaee44", "#66bb88", "#bb3311", "#440033", "#ff9900"],
  8062: ["#dd8877", "#55ff33", "#44eeaa", "#33dddd", "#eeff11"],
  8063: ["#66aa77", "#5599aa", "#bb6699", "#ff44bb", "#44bbff"],
  8064: ["#eeeeee", "#ff6622", "#9955ff", "#aadd33", "#bbdd22"],
  8065: ["#663355", "#6666ff", "#779944", "#999988", "#cc0000"],
  8066: ["#00dd55", "#bbbb88", "#cc8866", "#bb4477", "#cc1188"],
  8067: ["#bb2200", "#cc4411", "#ee5566", "#667788", "#bbff11"],
  8068: ["#223355", "#bbaaff", "#886677", "#ffaaff", "#2233cc"],
  8069: ["#666622", "#cc4466", "#aaaa44", "#885500", "#112244"],
  8070: ["#eeee66", "#7722ee", "#eeaa55", "#554411", "#bb2277"],
  8071: ["#ddee44", "#cc8877", "#bb6655", "#44cc77", "#33bb22"],
  8072: ["#dd3344", "#ddaa22", "#003322", "#ee7711", "#7722cc"],
  8073: ["#8855ee", "#112211", "#aaee66", "#661100", "#2255bb"],
  8074: ["#55ee33", "#88eebb", "#bb8877", "#ee4466", "#11aa11"],
  8075: ["#4433aa", "#11bbdd", "#aa66cc", "#77cc77", "#aa11aa"],
  8076: ["#00bbcc", "#0099dd", "#6677bb", "#88cc00", "#aaff44"],
  8077: ["#aaaacc", "#eebbff", "#cc4411", "#7777cc", "#ddbb66"],
  8078: ["#aaee99", "#22cc11", "#ddff33", "#dd5588", "#aaaa44"],
  8079: ["#5588cc", "#221111", "#009933", "#ddcc22", "#224422"],
  8080: ["#ee22ff", "#112200", "#ffff33", "#eecc22", "#111133"],
  8081: ["#ee4411", "#445533", "#dd0033", "#ee1166", "#ddbbee"],
  8082: ["#8844dd", "#9999dd", "#dd1111", "#44aa55", "#669988"],
  8083: ["#aa6600", "#884444", "#ff8866", "#88ff66", "#77ffee"],
  8084: ["#ee7788", "#6666dd", "#eeccee", "#448800", "#cc1177"],
  8085: ["#66ff44", "#55aa11", "#6699ff", "#22aa55", "#ee7744"],
  8086: ["#ee9966", "#55ee77", "#bbaa00", "#55ee88", "#8811dd"],
  8087: ["#2233ff", "#bb2288", "#cc44dd", "#2200ff", "#66aa11"],
  8088: ["#8811dd", "#bbaa66", "#2277ee", "#666688", "#663311"],
  8089: ["#ccddaa", "#aacc99", "#22cc55", "#8811bb", "#22bbcc"],
  8090: ["#ff9922", "#aa66ff", "#bb5555", "#bbcc44", "#66ee33"],
  8091: ["#ee55aa", "#0055dd", "#ddffaa", "#bbff11", "#22ee44"],
  8092: ["#aa66ee", "#556655", "#111133", "#66ddcc", "#ee11dd"],
  8093: ["#ff00dd", "#ccdddd", "#44cc55", "#eeddff", "#bbbbdd"],
  8094: ["#77bb33", "#8855bb", "#44ffbb", "#777711", "#4433cc"],
  8095: ["#eeff00", "#ccff77", "#ff0044", "#eecc44", "#eeaacc"],
  8096: ["#880033", "#33ee00", "#ff11aa", "#99cc77", "#77ccdd"],
  8097: ["#bb5533", "#11dd55", "#9999cc", "#1100ff", "#ddff99"],
  8098: ["#ee3311", "#aa44dd", "#1111ff", "#ccff44", "#ccffaa"],
  8099: ["#ff22aa", "#aadd44", "#004466", "#00cc11", "#00ff66"],
  8100: ["#ddcc66", "#ee00aa", "#11cc33", "#aa0033", "#ddff00"],
  8101: ["#33eeaa", "#666622", "#77cc33", "#55aaff", "#775533"],
  8102: ["#ccff11", "#992266", "#555566", "#44aa55", "#dd8822"],
  8103: ["#33eeff", "#77cc22", "#118899", "#228833", "#229900"],
  8104: ["#bb66ee", "#dd4499", "#6699aa", "#99aabb", "#11cc11"],
  8105: ["#880099", "#aa44ee", "#88ff44", "#ee2233", "#11bbee"],
  8106: ["#338888", "#881177", "#dd5555", "#ccff55", "#008888"],
  8107: ["#00bb66", "#eeeebb", "#1166cc", "#4488cc", "#228844"],
  8108: ["#8855bb", "#77aaaa", "#ffdd55", "#8833ff", "#668877"],
  8109: ["#55cc44", "#551188", "#996655", "#996644", "#33eedd"],
  8110: ["#119900", "#99aa00", "#998888", "#aa55aa", "#ee4466"],
  8111: ["#ffdd44", "#993333", "#bb0011", "#55eebb", "#881111"],
  8112: ["#44cc77", "#33eecc", "#55ddcc", "#bb2200", "#ff8800"],
  8113: ["#44bb00", "#aaff88", "#9955ee", "#ff66ff", "#77aaff"],
  8114: ["#55dddd", "#bb4466", "#bb2277", "#1188ee", "#ff22ee"],
  8115: ["#99aaaa", "#aa6633", "#4466ff", "#992277", "#00dddd"],
  8116: ["#1133ff", "#555577", "#99aa00", "#00aaaa", "#66bbcc"],
  8117: ["#ccddee", "#ffee44", "#1111ff", "#33ff22", "#7744bb"],
  8118: ["#0033cc", "#ff9955", "#777700", "#cc77cc", "#dd9933"],
  8119: ["#0055ee", "#cc9977", "#ffee55", "#aadd44", "#99ddff"],
  8120: ["#00ff66", "#1177dd", "#66ff77", "#aa22cc", "#11aa00"],
  8121: ["#005511", "#ccccaa", "#bb0088", "#111177", "#6655dd"],
  8122: ["#66dd44", "#dd9999", "#669944", "#661155", "#bbeebb"],
  8123: ["#cc00cc", "#1199dd", "#44ee99", "#0077aa", "#2255dd"],
  8124: ["#33ee22", "#cceecc", "#33bb44", "#773388", "#5544cc"],
  8125: ["#778855", "#bb11ff", "#00aa00", "#6655aa", "#22dd33"],
  8126: ["#4433aa", "#22aa00", "#228888", "#ffaa33", "#778811"],
  8127: ["#bb8833", "#ff0077", "#995599", "#55aa99", "#ff7766"],
  8128: ["#bb7777", "#ee55cc", "#cc9922", "#777755", "#33eedd"],
  8129: ["#669966", "#bbddff", "#aa7744", "#118888", "#33ff88"],
  8130: ["#7788bb", "#112299", "#6666aa", "#555566", "#553377"],
  8131: ["#ccbbdd", "#ee11ff", "#66cc33", "#99eebb", "#ff99bb"],
  8132: ["#9999ff", "#3300aa", "#eeff77", "#ffee99", "#66bb22"],
  8133: ["#ccbb22", "#ddee00", "#3355cc", "#eebb55", "#bb00ee"],
  8134: ["#117799", "#bb9988", "#bbff44", "#aaaa66", "#112244"],
  8135: ["#7799cc", "#ccaa99", "#992299", "#001111", "#770099"],
  8136: ["#776677", "#330088", "#8833aa", "#ddff00", "#880000"],
  8137: ["#ccbbaa", "#8811cc", "#ccff99", "#dd0088", "#ffdd77"],
  8138: ["#dd11aa", "#9955ff", "#999933", "#88ee44", "#ffaa88"],
  8139: ["#ee3344", "#00eeaa", "#aa6611", "#ffdd77", "#554488"],
  8140: ["#bb88cc", "#002299", "#aaffee", "#ee3333", "#eedd77"],
  8141: ["#5544bb", "#ee2222", "#ff7722", "#992211", "#2200cc"],
  8142: ["#cc7788", "#ddaa77", "#009933", "#5566dd", "#888855"],
  8143: ["#bb7700", "#5577ff", "#00ddaa", "#9900ff", "#778888"],
  8144: ["#99cc99", "#11ff88", "#ee0022", "#55eeee", "#bbffbb"],
  8145: ["#2299cc", "#aa11dd", "#bbddff", "#777766", "#4499ee"],
  8146: ["#992255", "#009933", "#889911", "#99bb11", "#bb44dd"],
  8147: ["#441111", "#ccff66", "#dddd44", "#4422ee", "#2288cc"],
  8148: ["#994433", "#99ccff", "#bbbbff", "#6655bb", "#ddaa55"],
  8149: ["#331155", "#aa66aa", "#3366bb", "#3311cc", "#999955"],
  8150: ["#3355cc", "#887766", "#000000", "#336622", "#880033"],
  8151: ["#44eeff", "#778833", "#996688", "#33ccff", "#338888"],
  8152: ["#8866aa", "#bb1199", "#bb66cc", "#448800", "#cc99aa"],
  8153: ["#7700bb", "#bb2200", "#882299", "#224477", "#6611bb"],
  8154: ["#bbddee", "#eebbaa", "#ddeeee", "#332211", "#1177ee"],
  8155: ["#00eedd", "#3355dd", "#447744", "#cccccc", "#ee6611"],
  8156: ["#88ddbb", "#cc66ee", "#888833", "#443333", "#6600ff"],
  8157: ["#00ff22", "#6699cc", "#339966", "#996699", "#224422"],
  8158: ["#55aa88", "#776611", "#ffbb77", "#ffff99", "#22aaaa"],
  8159: ["#338800", "#778822", "#2222bb", "#dd33aa", "#dd11ff"],
  8160: ["#2233ee", "#3388ee", "#66dd77", "#229988", "#9922dd"],
  8161: ["#775533", "#66dd22", "#7766dd", "#77aacc", "#224444"],
  8162: ["#cc4422", "#7700cc", "#dd2233", "#11cc55", "#bbcc66"],
  8163: ["#8844cc", "#1199ee", "#881199", "#cc22cc", "#44bb00"],
  8164: ["#66dd44", "#bbdd44", "#dd99cc", "#ff44dd", "#ff8877"],
  8165: ["#223333", "#775533", "#333355", "#440099", "#0044ee"],
  8166: ["#0022bb", "#335511", "#991144", "#00cc22", "#4433ee"],
  8167: ["#551144", "#3377cc", "#bb4422", "#2288bb", "#2299cc"],
  8168: ["#77aa00", "#ee9955", "#7788ee", "#778877", "#2266cc"],
  8169: ["#22ff11", "#664444", "#11cccc", "#115555", "#aa88cc"],
  8170: ["#220000", "#00bb55", "#339944", "#cc5544", "#ccff44"],
  8171: ["#ffcc55", "#ccee77", "#ff0022", "#dd8844", "#ffbb55"],
  8172: ["#77eebb", "#448855", "#aaeeee", "#8800bb", "#009900"],
  8173: ["#2222ff", "#ee3322", "#ee33ff", "#55ee55", "#cceecc"],
  8174: ["#cc5588", "#bb7733", "#7755cc", "#eeaabb", "#88aa55"],
  8175: ["#11cc55", "#ccdd88", "#aa33aa", "#22ffee", "#115511"],
  8176: ["#aa7788", "#aaaabb", "#aa3355", "#00aadd", "#ccbb22"],
  8177: ["#aa33ff", "#ee9988", "#1144bb", "#5500cc", "#559955"],
  8178: ["#8888cc", "#994466", "#ff4400", "#662266", "#553333"],
  8179: ["#66dd55", "#0044dd", "#553333", "#aaee66", "#776622"],
  8180: ["#556699", "#bb77aa", "#aacc44", "#444444", "#eebb99"],
  8181: ["#993311", "#6611ee", "#eebbcc", "#ddaa11", "#9900ee"],
  8182: ["#22bb00", "#cc7733", "#44ff33", "#bb4499", "#441199"],
  8183: ["#779955", "#8866ee", "#aaaa55", "#775500", "#cc4499"],
  8184: ["#44bbdd", "#ee9999", "#11ee00", "#55cccc", "#88ffcc"],
  8185: ["#cc88ee", "#aacc88", "#661144", "#221133", "#bb1177"],
  8186: ["#aa4455", "#00ff00", "#33aa44", "#ddff77", "#ffaaff"],
  8187: ["#55ffee", "#ffee11", "#0088dd", "#4411ee", "#cccc88"],
  8188: ["#ddbb99", "#88bb33", "#cc0033", "#448844", "#669911"],
  8189: ["#99aa11", "#334400", "#44ffcc", "#cc0022", "#bb5599"],
  8190: ["#991111", "#664499", "#cc8855", "#aa0099", "#dd4433"],
  8191: ["#cc2200", "#2277dd", "#cc6611", "#88ffaa", "#6655ff"],
  8192: ["#447744", "#44ee88", "#ee3344", "#11dd55", "#99bb88"],
  8193: ["#eeccff", "#66ccbb", "#cc4444", "#114444", "#446688"],
  8194: ["#5511bb", "#ee6688", "#ee5500", "#cc44aa", "#88cc22"],
  8195: ["#7744aa", "#003333", "#669999", "#338855", "#338866"],
  8196: ["#8888dd", "#ee77aa", "#ffee22", "#8877dd", "#2244ff"],
  8197: ["#0011cc", "#ee5588", "#33ee55", "#66aa99", "#33dd44"],
  8198: ["#447722", "#7755ff", "#7733cc", "#884433", "#eebb77"],
  8199: ["#99dd11", "#665522", "#4444aa", "#995511", "#4400cc"],
  8200: ["#cc11bb", "#441122", "#dd3300", "#ffccbb", "#8822ff"],
  8201: ["#cc44cc", "#6644aa", "#88eeff", "#5544dd", "#dd7766"],
  8202: ["#dd3311", "#bbeeff", "#661100", "#ff1166", "#7766bb"],
  8203: ["#ff55ff", "#44bb66", "#330011", "#11ffee", "#6655dd"],
  8204: ["#99bbdd", "#661177", "#11ccee", "#555555", "#dd4444"],
  8205: ["#88ff88", "#22cccc", "#aa1177", "#bb44aa", "#88aa88"],
  8206: ["#221122", "#33ff88", "#224400", "#bb9900", "#8877dd"],
  8207: ["#9944cc", "#8800dd", "#eedddd", "#bb88dd", "#ee22cc"],
  8208: ["#aaff55", "#880044", "#44ddee", "#005555", "#ee7722"],
  8209: ["#2233cc", "#3344cc", "#cc0077", "#8811dd", "#99dd44"],
  8210: ["#770055", "#33dd00", "#771155", "#5555dd", "#662200"],
  8211: ["#881133", "#eeeecc", "#009911", "#22ee11", "#cc77dd"],
  8212: ["#99aa33", "#3311dd", "#11ddee", "#2211ff", "#aacc55"],
  8213: ["#779999", "#554400", "#880099", "#77bb88", "#886666"],
  8214: ["#0011dd", "#887777", "#ffbb00", "#88cc99", "#009977"],
  8215: ["#7722ee", "#bb99cc", "#886677", "#ffdd55", "#ddffff"],
  8216: ["#dd8855", "#44ff22", "#333388", "#aaff66", "#22bb44"],
  8217: ["#ff55ff", "#5566ee", "#bbee11", "#ddccee", "#bb33ff"],
  8218: ["#aa5500", "#114466", "#221133", "#bb6655", "#009900"],
  8219: ["#9988dd", "#8822aa", "#775533", "#5599ee", "#ddddaa"],
  8220: ["#997766", "#ffdd99", "#cc4444", "#777711", "#ff9988"],
  8221: ["#11ff11", "#1133aa", "#4488ee", "#661188", "#553322"],
  8222: ["#cc2299", "#00eeaa", "#330099", "#77ccbb", "#66dd11"],
  8223: ["#993399", "#ccff00", "#22ccdd", "#bb55bb", "#11bb22"],
  8224: ["#bb0055", "#00aa88", "#ee33ff", "#eeff88", "#dddd66"],
  8225: ["#dd9933", "#dd6600", "#7700bb", "#88ff55", "#9900bb"],
  8226: ["#bbbbaa", "#8844ff", "#00aa55", "#44ff66", "#dd88aa"],
  8227: ["#883355", "#bb55ff", "#44ddff", "#3366cc", "#aa22aa"],
  8228: ["#99aa99", "#1144ff", "#228800", "#bbdd22", "#444499"],
  8229: ["#dd66dd", "#8800bb", "#bb3322", "#22cc11", "#0011dd"],
  8230: ["#778844", "#0011ee", "#ddee22", "#dd1100", "#229922"],
  8231: ["#551100", "#88cc00", "#006600", "#eebb00", "#661133"],
  8232: ["#220044", "#eebb00", "#3388aa", "#44bb22", "#990099"],
  8233: ["#ff44ff", "#ee9999", "#88ddee", "#ff66dd", "#aa2266"],
  8234: ["#ddaa99", "#1111aa", "#55aa55", "#339944", "#888899"],
  8235: ["#dd8800", "#ff7777", "#eeddee", "#66bb55", "#11eecc"],
  8236: ["#88aacc", "#999977", "#aa2233", "#eeee66", "#556655"],
  8237: ["#dd9966", "#aa55aa", "#7788cc", "#008811", "#ffbb22"],
  8238: ["#dd55cc", "#773377", "#ddaa22", "#cc9900", "#88ee11"],
  8239: ["#55ff00", "#8855dd", "#bb22aa", "#aabb11", "#3333aa"],
  8240: ["#ff55dd", "#558844", "#775544", "#ee4422", "#885533"],
  8241: ["#22ee66", "#449988", "#2255ee", "#11ff33", "#55cc11"],
  8242: ["#dd66dd", "#ccccdd", "#887700", "#ff7700", "#557733"],
  8243: ["#441177", "#aa4466", "#4411aa", "#eebb22", "#22bb11"],
  8244: ["#ee22bb", "#22bbdd", "#ff9999", "#eeddaa", "#ff22ee"],
  8245: ["#eebbdd", "#99ddaa", "#44ee55", "#779944", "#221166"],
  8246: ["#004411", "#bb0011", "#eebb88", "#77dd55", "#eeeeff"],
  8247: ["#5544aa", "#cc4433", "#5599bb", "#886600", "#aa1177"],
  8248: ["#5533ee", "#663300", "#6699cc", "#115533", "#11aa77"],
  8249: ["#ee8899", "#998844", "#55eedd", "#88eecc", "#2233aa"],
  8250: ["#4488aa", "#11cc44", "#661144", "#aabb88", "#66aaff"],
  8251: ["#bb4499", "#ffff77", "#448811", "#11ee99", "#663333"],
  8252: ["#ee77ff", "#3377aa", "#554400", "#ff88bb", "#ee00ee"],
  8253: ["#6688ff", "#bbaa99", "#4433bb", "#4488aa", "#663366"],
  8254: ["#22cc44", "#44dd33", "#cc4444", "#9900ff", "#22bb33"],
  8255: ["#995588", "#55eeff", "#221166", "#bb8899", "#88dd99"],
  8256: ["#11bb99", "#8877ee", "#559922", "#551144", "#99aaff"],
  8257: ["#8866bb", "#ee0044", "#aabbaa", "#ee9988", "#ccff66"],
  8258: ["#33ee00", "#2233aa", "#559944", "#2266bb", "#001188"],
  8259: ["#44bb88", "#aa11aa", "#66ccff", "#8822cc", "#ff7777"],
  8260: ["#339955", "#3355ff", "#ffbb00", "#883300", "#bb6633"],
  8261: ["#77cc77", "#008888", "#3388aa", "#aa8811", "#cc33ff"],
  8262: ["#aa0077", "#99dd33", "#6633ff", "#225500", "#993377"],
  8263: ["#aaddaa", "#aa5522", "#dd9900", "#006611", "#44aa55"],
  8264: ["#4411dd", "#33ee44", "#885599", "#7744dd", "#220011"],
  8265: ["#7733ee", "#33aa44", "#aaaaff", "#883311", "#99ee66"],
  8266: ["#774455", "#1100ee", "#88ff00", "#448822", "#5577cc"],
  8267: ["#227744", "#ee44dd", "#775511", "#44ccff", "#8822cc"],
  8268: ["#887766", "#0022bb", "#445577", "#ee4433", "#33aa66"],
  8269: ["#557788", "#666688", "#11dd33", "#227755", "#009999"],
  8270: ["#dd55ee", "#775500", "#446666", "#ee4411", "#117788"],
  8271: ["#3366ff", "#339922", "#11eeff", "#bb5599", "#ee6600"],
  8272: ["#aaccbb", "#ccccff", "#bb88bb", "#77ddbb", "#88cc22"],
  8273: ["#aaaa66", "#aaeedd", "#aaee55", "#bb3300", "#eeff77"],
  8274: ["#dd33bb", "#ee88ee", "#ff55aa", "#66ddaa", "#9955ff"],
  8275: ["#115599", "#aa8833", "#aabbee", "#774466", "#88aa00"],
  8276: ["#554466", "#33ff99", "#8833aa", "#881177", "#667788"],
  8277: ["#bb6644", "#99eeaa", "#11bb11", "#0099aa", "#aa8888"],
  8278: ["#88dd22", "#55dd77", "#ff3344", "#5511aa", "#77cc99"],
  8279: ["#9911dd", "#333399", "#33bbee", "#2200bb", "#bbff22"],
  8280: ["#aacc00", "#7744ff", "#ffcc44", "#9999ff", "#770055"],
  8281: ["#000000", "#229999", "#77dd33", "#aa8877", "#449933"],
  8282: ["#66ff22", "#ff7722", "#cccc00", "#77ffff", "#ddffcc"],
  8283: ["#777755", "#220011", "#11ee55", "#8888ff", "#aa1100"],
  8284: ["#bb7788", "#4477ee", "#001177", "#660033", "#ddee22"],
  8285: ["#bb6688", "#7700ee", "#ee5577", "#888833", "#4455ff"],
  8286: ["#cc66ee", "#cc2299", "#777722", "#dd33dd", "#889955"],
  8287: ["#6644dd", "#11ff66", "#662211", "#66ffaa", "#aaaa33"],
  8288: ["#444455", "#5522dd", "#bb1166", "#9911aa", "#ff7755"],
  8289: ["#3300bb", "#8866dd", "#4477cc", "#226611", "#ccbbff"],
  8290: ["#ffaa66", "#66bb00", "#7711aa", "#ff1133", "#00dd88"],
  8291: ["#88ffee", "#aaccdd", "#ff11aa", "#2266ee", "#119988"],
  8292: ["#aaaa99", "#110066", "#aa4466", "#227799", "#005577"],
  8293: ["#ffbb33", "#666655", "#ff1111", "#225588", "#dd22aa"],
  8294: ["#99ee66", "#336622", "#887799", "#aacccc", "#1177aa"],
  8295: ["#2277cc", "#00ccee", "#55ddbb", "#77cc99", "#660055"],
  8296: ["#ffbbee", "#11eebb", "#ee2244", "#3355dd", "#22bb66"],
  8297: ["#112200", "#33cc00", "#33ff77", "#aaeeee", "#2299dd"],
  8298: ["#665544", "#110066", "#7788ee", "#bb88aa", "#55cc66"],
  8299: ["#11dddd", "#88dd00", "#773300", "#77eeee", "#995544"],
  8300: ["#00aadd", "#3366ff", "#441100", "#339977", "#bb88dd"],
  8301: ["#335577", "#448844", "#ff7722", "#118855", "#009966"],
  8302: ["#ee66ee", "#eebb33", "#2222dd", "#eecc88", "#bb6677"],
  8303: ["#66aa66", "#aaffff", "#99ff77", "#992200", "#00eeee"],
  8304: ["#4433ee", "#bbbbaa", "#ccddbb", "#ff5500", "#eeaa77"],
  8305: ["#dd8844", "#11eeaa", "#442255", "#bb6611", "#ee11bb"],
  8306: ["#0066cc", "#005500", "#ffaacc", "#bb3388", "#998899"],
  8307: ["#8855cc", "#4499bb", "#4400aa", "#ff7799", "#66ccff"],
  8308: ["#aa77cc", "#dd4411", "#110099", "#bb3355", "#ee22ff"],
  8309: ["#99ffaa", "#55ffbb", "#cc00aa", "#dd0011", "#00ff11"],
  8310: ["#aaff55", "#1133bb", "#bb2255", "#115533", "#5555aa"],
  8311: ["#555500", "#dd3388", "#009988", "#ffdd88", "#aaeecc"],
  8312: ["#aaaa00", "#aa0033", "#993377", "#0055bb", "#7700ff"],
  8313: ["#eebb66", "#442288", "#aa5522", "#8877dd", "#888855"],
  8314: ["#005522", "#ff3311", "#339999", "#9988ee", "#dd2299"],
  8315: ["#dddd22", "#4444dd", "#99aa11", "#ff3344", "#aacc44"],
  8316: ["#eecc88", "#ee8888", "#33bbff", "#88ddff", "#eeff33"],
  8317: ["#dd5588", "#ee4466", "#44cccc", "#449922", "#66aaff"],
  8318: ["#88ee33", "#eeaa66", "#ee8877", "#77ddaa", "#8844ff"],
  8319: ["#aa8833", "#112288", "#bbdd88", "#7766dd", "#44bb44"],
  8320: ["#eeee66", "#dddd00", "#ccaa11", "#aabb22", "#ffdd66"],
  8321: ["#ccff00", "#9999ff", "#ee00aa", "#994466", "#22bb44"],
  8322: ["#3388ee", "#ddee00", "#337744", "#66ccaa", "#cc77ff"],
  8323: ["#ee3366", "#990011", "#dd0044", "#aaaaee", "#ccccdd"],
  8324: ["#9955bb", "#33dd88", "#77eecc", "#aa11bb", "#cc55cc"],
  8325: ["#220099", "#cc7788", "#996600", "#88dd77", "#ff0033"],
  8326: ["#9933bb", "#bbff66", "#66bb22", "#ccff88", "#005500"],
  8327: ["#11bb99", "#774488", "#22ccaa", "#aa4400", "#881144"],
  8328: ["#bb88ff", "#dd1144", "#4466dd", "#886633", "#ffaa88"],
  8329: ["#bb0077", "#8899bb", "#ee99ff", "#ddddbb", "#dd33dd"],
  8330: ["#ee2266", "#cc3399", "#dd88cc", "#777722", "#aaee22"],
  8331: ["#cc77aa", "#6600cc", "#336611", "#cc0033", "#446655"],
  8332: ["#6633ff", "#88ccdd", "#22cc99", "#55dd22", "#009955"],
  8333: ["#669900", "#ccff22", "#666611", "#aaff66", "#77aabb"],
  8334: ["#22ee11", "#44bb11", "#ddff33", "#5566ff", "#ddaa77"],
  8335: ["#cc7777", "#443377", "#dd2211", "#220000", "#88ffff"],
  8336: ["#77ffff", "#99bbaa", "#1122ee", "#dd6666", "#449900"],
  8337: ["#cc8822", "#00ee22", "#2222cc", "#99ee33", "#cc22ff"],
  8338: ["#cc99aa", "#99ffaa", "#118866", "#55ff77", "#ff6600"],
  8339: ["#ff3333", "#88ff66", "#55bb44", "#770033", "#446611"],
  8340: ["#2277aa", "#995566", "#cc6688", "#aa3377", "#cc00aa"],
  8341: ["#8833ff", "#666600", "#883344", "#11dd77", "#ff11ff"],
  8342: ["#cc0099", "#ffaa99", "#005544", "#440022", "#ccbbaa"],
  8343: ["#998866", "#5577aa", "#007722", "#cc1111", "#77aaee"],
  8344: ["#ffeeaa", "#336611", "#66ff22", "#4400cc", "#8811ee"],
  8345: ["#bb00bb", "#883366", "#dd6699", "#3300cc", "#6644aa"],
  8346: ["#ff9977", "#ffbb00", "#dddd66", "#cc99cc", "#dd22ee"],
  8347: ["#4433aa", "#11ee00", "#885599", "#ee4400", "#bbaaaa"],
  8348: ["#ff2288", "#ee5544", "#9966bb", "#99cc33", "#cc1199"],
  8349: ["#00dd66", "#ee5500", "#3333aa", "#55bbff", "#aa9977"],
  8350: ["#bbcc11", "#ff55aa", "#bb88cc", "#2233dd", "#dd8866"],
  8351: ["#443355", "#77cc99", "#883333", "#77ffbb", "#332288"],
  8352: ["#44dd55", "#66bbaa", "#991199", "#aa1199", "#553399"],
  8353: ["#8888cc", "#aa22ff", "#99ddff", "#1155cc", "#9955ee"],
  8354: ["#ee55dd", "#777711", "#55ddee", "#bbdd88", "#66bb00"],
  8355: ["#ffdd44", "#eebb33", "#22cc55", "#000088", "#ffee33"],
  8356: ["#aa2277", "#11ff22", "#ee9900", "#55bbbb", "#224422"],
  8357: ["#aabbcc", "#00eeff", "#aaaa55", "#bbaa33", "#447777"],
  8358: ["#ccccff", "#aaccaa", "#88aabb", "#77bbaa", "#555555"],
  8359: ["#99ddaa", "#6688aa", "#ffbb88", "#2200cc", "#99bb88"],
  8360: ["#338855", "#ddaa55", "#55bbee", "#bb00bb", "#661133"],
  8361: ["#113333", "#11dd55", "#8811ee", "#449944", "#4455dd"],
  8362: ["#33ee11", "#776611", "#dd99bb", "#77cc11", "#110022"],
  8363: ["#556655", "#77aa66", "#7744ee", "#0011ee", "#999922"],
  8364: ["#55ffaa", "#cc9944", "#ddee11", "#dd9911", "#aacc11"],
  8365: ["#44ee33", "#88ddff", "#dd44bb", "#00ddcc", "#331199"],
  8366: ["#55ff88", "#3377dd", "#337799", "#aa66cc", "#22ccff"],
  8367: ["#5500aa", "#ccaa99", "#eecc77", "#ff55ff", "#44dd22"],
  8368: ["#7722bb", "#eeaacc", "#ffbb55", "#66eedd", "#ccff66"],
  8369: ["#eeccbb", "#116666", "#bb3333", "#aadddd", "#aaffff"],
  8370: ["#880022", "#44cc55", "#ee77ee", "#ff3322", "#332288"],
  8371: ["#aa5544", "#66eebb", "#33dd88", "#3366ff", "#22aa00"],
  8372: ["#6644aa", "#33ccaa", "#33ff55", "#44ccff", "#ff5511"],
  8373: ["#cc8800", "#2200dd", "#3377dd", "#ff3322", "#00dd99"],
  8374: ["#66aa00", "#bb4466", "#eeccff", "#2288aa", "#22bb33"],
  8375: ["#000022", "#eeddee", "#eeccee", "#99ee00", "#997788"],
  8376: ["#441122", "#990000", "#9988dd", "#ee0088", "#114411"],
  8377: ["#aa22dd", "#ddbbbb", "#bb7700", "#dd55ee", "#22cc00"],
  8378: ["#881155", "#dd6644", "#3344dd", "#55bb99", "#dd11dd"],
  8379: ["#77aa77", "#99ee11", "#33ee00", "#2211dd", "#331177"],
  8380: ["#9966aa", "#11ee55", "#bb99cc", "#1133dd", "#cc33ee"],
  8381: ["#ee33ee", "#33ee00", "#ff44ee", "#44cc00", "#111166"],
  8382: ["#339955", "#8822bb", "#9922cc", "#448833", "#aaaa22"],
  8383: ["#88ff44", "#44dd88", "#aa7733", "#ccbbdd", "#ff8800"],
  8384: ["#99cc33", "#ff8833", "#bb2211", "#22ff22", "#440033"],
  8385: ["#882244", "#445555", "#bbaa66", "#7755ee", "#88dd33"],
  8386: ["#11ee00", "#eeddbb", "#99ddbb", "#ee0011", "#4466dd"],
  8387: ["#ccee99", "#113333", "#4444ff", "#7711bb", "#9911ee"],
  8388: ["#8877ff", "#11ee33", "#8877dd", "#55ffee", "#884400"],
  8389: ["#11dddd", "#cc9922", "#3333cc", "#11ddcc", "#ccddaa"],
  8390: ["#44eeff", "#bbaa88", "#ee22bb", "#1144dd", "#aaaa55"],
  8391: ["#ff0011", "#2288bb", "#bb44aa", "#55ddbb", "#00aa44"],
  8392: ["#7755dd", "#aa8800", "#55cc00", "#66eecc", "#6611bb"],
  8393: ["#444455", "#ffbb66", "#ee5555", "#1144ee", "#331188"],
  8394: ["#0033aa", "#dd88ff", "#00aabb", "#ff99ee", "#33dd55"],
  8395: ["#ff66dd", "#999911", "#6666ee", "#ffee88", "#ee7777"],
  8396: ["#44dddd", "#224433", "#22bb66", "#11bb77", "#ff5555"],
  8397: ["#3366ee", "#0044aa", "#11ee00", "#ff7700", "#777733"],
  8398: ["#55ee99", "#7777aa", "#dd8811", "#88cccc", "#ee22dd"],
  8399: ["#99aaff", "#cc9933", "#2266ee", "#330055", "#225544"],
  8400: ["#cc00dd", "#ff9977", "#ff66ee", "#667777", "#dd4411"],
  8401: ["#bbcc88", "#995577", "#885522", "#ccbbbb", "#224466"],
  8402: ["#cc88aa", "#ccbbaa", "#ff5577", "#cc11dd", "#cc5500"],
  8403: ["#665511", "#551122", "#662233", "#dd0011", "#553311"],
  8404: ["#ee55cc", "#aa8833", "#990033", "#44bb11", "#995500"],
  8405: ["#bbcc88", "#ccbb66", "#bb8899", "#55eeee", "#bbaa77"],
  8406: ["#cc0011", "#990022", "#aacc44", "#551144", "#660011"],
  8407: ["#cc99ff", "#ddbb44", "#0044cc", "#008888", "#33aaaa"],
  8408: ["#8800ee", "#dd00ee", "#ee3311", "#113377", "#eecc33"],
  8409: ["#2200aa", "#44bb00", "#661111", "#776600", "#ddccaa"],
  8410: ["#441122", "#2211bb", "#dd7777", "#cc88aa", "#77ee22"],
  8411: ["#aa8877", "#003300", "#cceecc", "#8877ff", "#881166"],
  8412: ["#88bb88", "#00ee22", "#bbeebb", "#ff5577", "#33ee00"],
  8413: ["#eeeeee", "#ee0011", "#338822", "#aa9933", "#11ccff"],
  8414: ["#dd1144", "#55ff66", "#889966", "#771199", "#0011bb"],
  8415: ["#66dddd", "#bbee99", "#5511bb", "#bb22cc", "#8855bb"],
  8416: ["#bbcc88", "#88dd22", "#994433", "#4499ee", "#44ff55"],
  8417: ["#9933ee", "#dd33ee", "#3300aa", "#77bbaa", "#667722"],
  8418: ["#2211ee", "#ee33ee", "#99bb88", "#00aadd", "#007733"],
  8419: ["#11ff33", "#0044ee", "#55ff55", "#779988", "#33ccbb"],
  8420: ["#cc2211", "#dd55cc", "#22bbaa", "#cccc22", "#eeaa55"],
  8421: ["#55ff33", "#77dd88", "#bb5577", "#228822", "#554455"],
  8422: ["#22aaaa", "#667700", "#ee5599", "#6677cc", "#440022"],
  8423: ["#bbcc33", "#bb00bb", "#5511dd", "#eeaabb", "#ee7700"],
  8424: ["#ddaaff", "#eeaaee", "#8822dd", "#ee5555", "#bbaa11"],
  8425: ["#cc2200", "#22cc99", "#99ee33", "#778866", "#bbdd22"],
  8426: ["#ccbb88", "#9988aa", "#66bbcc", "#88aa33", "#bbaa11"],
  8427: ["#eeee88", "#113322", "#ccddff", "#11dd22", "#33ff55"],
  8428: ["#886622", "#6666ff", "#88bb99", "#88dd77", "#ff55ee"],
  8429: ["#3300bb", "#777711", "#66aa22", "#33aaff", "#9955aa"],
  8430: ["#cc55dd", "#994477", "#ff88bb", "#aa0033", "#aa0044"],
  8431: ["#225588", "#ee99bb", "#cc11dd", "#99ee55", "#0088ff"],
  8432: ["#664422", "#eeeedd", "#ffbbaa", "#ffbb99", "#11bb66"],
  8433: ["#223344", "#669933", "#44ee33", "#bb1155", "#ff1111"],
  8434: ["#ff11ee", "#665511", "#44ff44", "#ccdd11", "#99ccaa"],
  8435: ["#222277", "#dddddd", "#ccff22", "#88eecc", "#dd55cc"],
  8436: ["#440022", "#446611", "#335599", "#883377", "#ccddaa"],
  8437: ["#5588ff", "#55bbbb", "#aaee55", "#1122aa", "#0088ee"],
  8438: ["#778833", "#aaaa66", "#7711aa", "#334400", "#bb44aa"],
  8439: ["#aa77bb", "#99cc99", "#dd3355", "#1188bb", "#99ddee"],
  8440: ["#22cc00", "#ffaa99", "#ee66dd", "#99cc55", "#33bbaa"],
  8441: ["#55aadd", "#cc6633", "#dd6633", "#ddff11", "#aaaacc"],
  8442: ["#559922", "#99bb99", "#dd3355", "#11ffcc", "#448866"],
  8443: ["#ee9988", "#9977ee", "#ffdd66", "#ccddee", "#dddd55"],
  8444: ["#554466", "#115544", "#884433", "#002277", "#2288dd"],
  8445: ["#00ee00", "#ee88ee", "#22aadd", "#bbdd11", "#dd22bb"],
  8446: ["#aa8855", "#9966aa", "#112233", "#331199", "#ffdddd"],
  8447: ["#3377ee", "#446611", "#448833", "#884466", "#66bb88"],
  8448: ["#220099", "#116644", "#55ff55", "#2200aa", "#88aa00"],
  8449: ["#9955dd", "#9966ee", "#5511dd", "#33ee55", "#66eebb"],
  8450: ["#222200", "#5555ee", "#11bbbb", "#11aa22", "#bb0033"],
  8451: ["#8844dd", "#331177", "#668833", "#ff5533", "#998822"],
  8452: ["#6633ff", "#dd5566", "#66ccaa", "#88ddbb", "#112255"],
  8453: ["#115599", "#330088", "#ff33dd", "#775588", "#663388"],
  8454: ["#ee0088", "#ff7766", "#aa5544", "#66aadd", "#ff6622"],
  8455: ["#441155", "#dd77ff", "#2255aa", "#aaee00", "#66ddcc"],
  8456: ["#552277", "#000033", "#ffff00", "#44dd33", "#55ff22"],
  8457: ["#bbdd88", "#556699", "#bbbb77", "#ee33ff", "#221177"],
  8458: ["#bbaa22", "#995555", "#3311aa", "#7722aa", "#1155bb"],
  8459: ["#772233", "#ee2222", "#881133", "#993355", "#aa11ee"],
  8460: ["#ee11ee", "#cc1100", "#99dd00", "#33dd22", "#00cc99"],
  8461: ["#774422", "#ff88ff", "#eebb00", "#226633", "#66ff88"],
  8462: ["#ff8855", "#446633", "#77ff66", "#666699", "#88ccbb"],
  8463: ["#55cc55", "#bb5566", "#119911", "#226666", "#33ff66"],
  8464: ["#22ff88", "#22bb99", "#991111", "#00ff11", "#0000cc"],
  8465: ["#66ee00", "#eeffdd", "#aa33bb", "#77dd11", "#669911"],
  8466: ["#77aa88", "#2299bb", "#993377", "#aa3366", "#44ee66"],
  8467: ["#443311", "#00ff44", "#dd55aa", "#666688", "#cc6655"],
  8468: ["#dd7744", "#ee5555", "#4488ff", "#11ffff", "#8855ff"],
  8469: ["#229922", "#557700", "#2255aa", "#88bb99", "#eecccc"],
  8470: ["#5566ee", "#77aa00", "#883355", "#3366bb", "#ff22bb"],
  8471: ["#00ee99", "#ee66dd", "#66bb55", "#aaaadd", "#2222cc"],
  8472: ["#883377", "#332233", "#6633aa", "#668877", "#66eecc"],
  8473: ["#eedd33", "#9911bb", "#44ee11", "#11ff33", "#ccbbcc"],
  8474: ["#77ff66", "#55aa55", "#88aacc", "#ddaa55", "#55ddaa"],
  8475: ["#aa55bb", "#6633ee", "#660044", "#dd9966", "#443377"],
  8476: ["#7744ff", "#558866", "#8800dd", "#33ffaa", "#887711"],
  8477: ["#7755ff", "#ff99ee", "#aa4466", "#aa1144", "#ff9977"],
  8478: ["#ffcccc", "#0077bb", "#996666", "#550077", "#bb1188"],
  8479: ["#77ff00", "#88bb66", "#995588", "#aa66ff", "#eedd88"],
  8480: ["#ff5588", "#33bbff", "#aaccbb", "#44dd11", "#994499"],
  8481: ["#66ff11", "#337799", "#bb33cc", "#ee1144", "#00ee11"],
  8482: ["#ffff77", "#eeaa44", "#2255ee", "#aa44ff", "#33cc88"],
  8483: ["#332211", "#7755ff", "#aa7722", "#666666", "#112288"],
  8484: ["#dd55ee", "#dd6688", "#2288bb", "#558800", "#9977dd"],
  8485: ["#116600", "#66eecc", "#6611ff", "#22dd00", "#445500"],
  8486: ["#22aa33", "#22bb33", "#33eebb", "#22cc22", "#886600"],
  8487: ["#dd3300", "#33ddff", "#aaff44", "#ddbbdd", "#6600aa"],
  8488: ["#2255ee", "#aabbaa", "#44dddd", "#ffdd66", "#ff9966"],
  8489: ["#11dd11", "#ff1155", "#dd6600", "#bb1122", "#227744"],
  8490: ["#33ff44", "#663333", "#ccffaa", "#5544ff", "#ee5588"],
  8491: ["#ffcc88", "#ee5511", "#55bb66", "#ff3355", "#aa3355"],
  8492: ["#44ff99", "#eedddd", "#bb1111", "#aaeecc", "#ff4466"],
  8493: ["#cc1122", "#111111", "#11bbff", "#889988", "#cc77bb"],
  8494: ["#667722", "#ddee77", "#9988ff", "#880011", "#2277aa"],
  8495: ["#33cccc", "#0099dd", "#117799", "#aa5588", "#cc9944"],
  8496: ["#44ddaa", "#8822cc", "#996600", "#88ddbb", "#cc1199"],
  8497: ["#ee7755", "#ee9988", "#ff88aa", "#335566", "#ddee55"],
  8498: ["#004400", "#ff66ee", "#118844", "#00ee33", "#aa22cc"],
  8499: ["#dd22aa", "#11bb11", "#2233ff", "#66ccbb", "#bb2299"],
  8500: ["#9955aa", "#0055bb", "#774433", "#ee00bb", "#9911cc"],
  8501: ["#664444", "#99bb88", "#22bb22", "#bb3322", "#225544"],
  8502: ["#4499dd", "#aa0033", "#aabb33", "#8899aa", "#aaddff"],
  8503: ["#448899", "#ff0000", "#443355", "#dd4499", "#55ff00"],
  8504: ["#bb00aa", "#0011bb", "#5555dd", "#ff99bb", "#555544"],
  8505: ["#5533bb", "#221177", "#bb7755", "#1122aa", "#cc9955"],
  8506: ["#77ff11", "#224488", "#aa33bb", "#440044", "#111166"],
  8507: ["#88aa88", "#ee88aa", "#0088ff", "#889988", "#117700"],
  8508: ["#eeccff", "#dd44aa", "#553344", "#11cc44", "#dd33ee"],
  8509: ["#0022dd", "#3344cc", "#998811", "#55ff44", "#bbbbcc"],
  8510: ["#ddddcc", "#ee99bb", "#eeee99", "#2299ee", "#99bbaa"],
  8511: ["#1188ff", "#1133aa", "#773344", "#22ee77", "#449900"],
  8512: ["#8899cc", "#88bb11", "#0000ff", "#885566", "#119900"],
  8513: ["#ddffff", "#4411ff", "#ee3311", "#eeee00", "#117755"],
  8514: ["#bbdd55", "#117733", "#dddd33", "#99ee77", "#ee9999"],
  8515: ["#119933", "#22aa00", "#44dddd", "#aaaaff", "#22ddff"],
  8516: ["#66ddcc", "#aa8877", "#4455bb", "#00bb77", "#008800"],
  8517: ["#dd8800", "#773300", "#995555", "#551122", "#ccee77"],
  8518: ["#991188", "#66cc44", "#55dd44", "#ff2222", "#77cc22"],
  8519: ["#222299", "#88bbee", "#ffdd44", "#bb33bb", "#99bbff"],
  8520: ["#887722", "#7766bb", "#ff7722", "#aa5500", "#333344"],
  8521: ["#ff1199", "#773366", "#ddeedd", "#3333bb", "#338811"],
  8522: ["#dd3366", "#66bbcc", "#ff7733", "#eedd33", "#7733ff"],
  8523: ["#00dd44", "#bb6688", "#2277aa", "#33ddbb", "#11bbcc"],
  8524: ["#999900", "#aa0022", "#44bbcc", "#77cc99", "#cc7700"],
  8525: ["#5544aa", "#33aa22", "#553355", "#ff22cc", "#00cc33"],
  8526: ["#aa99cc", "#ccaaff", "#0077ff", "#004400", "#ff4488"],
  8527: ["#ee88ee", "#ff3377", "#553300", "#11bb99", "#bbbb55"],
  8528: ["#998822", "#ee22ff", "#ff8888", "#ccddcc", "#112255"],
  8529: ["#556622", "#558888", "#00ff99", "#2244ff", "#22ddaa"],
  8530: ["#9988bb", "#ddff33", "#ffaa00", "#aa6622", "#ee9944"],
  8531: ["#ee6622", "#446622", "#dd88ee", "#338877", "#88ee99"],
  8532: ["#55dddd", "#aa33cc", "#4433aa", "#eeffbb", "#332288"],
  8533: ["#bbaabb", "#77ffff", "#002266", "#ee00cc", "#ff44aa"],
  8534: ["#33ccaa", "#3377bb", "#114455", "#cc66cc", "#cc9966"],
  8535: ["#66dd44", "#66aa88", "#ccbbcc", "#bb77bb", "#44aa00"],
  8536: ["#33bb66", "#bb7711", "#9988cc", "#eeaa88", "#ddbb00"],
  8537: ["#bb22cc", "#117733", "#449966", "#113377", "#66aa55"],
  8538: ["#aadd77", "#5566dd", "#77ccee", "#aaff11", "#66ff11"],
  8539: ["#99aa33", "#774499", "#ffffee", "#88ffbb", "#ccbbdd"],
  8540: ["#11aa33", "#66dd22", "#5555ee", "#ddee33", "#1199ff"],
  8541: ["#3377bb", "#ffee77", "#aacc00", "#ff00bb", "#44bb99"],
  8542: ["#77ee33", "#aaaacc", "#009988", "#bbdd55", "#ff2277"],
  8543: ["#009966", "#ffeeee", "#dd9966", "#778822", "#5500bb"],
  8544: ["#7744dd", "#00cc55", "#ee55ff", "#330000", "#888877"],
  8545: ["#88ee55", "#66eebb", "#55dd77", "#dd4466", "#ffbb77"],
  8546: ["#224455", "#cc0044", "#dd5599", "#cc11bb", "#bbbb22"],
  8547: ["#006633", "#774477", "#999933", "#ff4466", "#cc8877"],
  8548: ["#22cc33", "#886666", "#eecc22", "#661177", "#330011"],
  8549: ["#dd0044", "#999933", "#9922bb", "#114444", "#55cccc"],
  8550: ["#77ff99", "#bb3377", "#331100", "#88eebb", "#33ffbb"],
  8551: ["#552233", "#337777", "#ddbb66", "#00aa55", "#11cc99"],
  8552: ["#7733bb", "#0022ff", "#009966", "#bb7733", "#3366cc"],
  8553: ["#ccbbcc", "#cc7788", "#774400", "#557788", "#88aa22"],
  8554: ["#7788bb", "#bbdd00", "#1122cc", "#558833", "#cc7755"],
  8555: ["#aaddff", "#bb88bb", "#ddaacc", "#bb55cc", "#993377"],
  8556: ["#ee3322", "#22dd44", "#ddeeff", "#66cc88", "#11bb66"],
  8557: ["#8866ff", "#dd2288", "#116600", "#111199", "#5588ee"],
  8558: ["#006677", "#22cccc", "#cc5599", "#cc7755", "#cc6699"],
  8559: ["#ff8899", "#99ee66", "#8877ff", "#ffee55", "#ff2255"],
  8560: ["#55eeee", "#cceecc", "#2277ee", "#44ee22", "#66cccc"],
  8561: ["#337755", "#555522", "#330066", "#aa7722", "#aaaa99"],
  8562: ["#00ddee", "#5599aa", "#bb55ee", "#55ff44", "#bb1155"],
  8563: ["#dd7788", "#aadd22", "#ccbbbb", "#cc66ff", "#2233dd"],
  8564: ["#0033ff", "#ffaa77", "#ffddaa", "#cc8844", "#55ff77"],
  8565: ["#aa0077", "#9900bb", "#882277", "#99aa77", "#bb00ee"],
  8566: ["#dd3388", "#333300", "#77bbff", "#33ffff", "#bb5522"],
  8567: ["#aa11ff", "#009944", "#33ddcc", "#114422", "#bb11cc"],
  8568: ["#33aa77", "#ffee33", "#33aacc", "#bb66aa", "#ee3377"],
  8569: ["#77bb00", "#88ee44", "#5599bb", "#770055", "#ccddcc"],
  8570: ["#bb6622", "#885555", "#cc4400", "#110033", "#332299"],
  8571: ["#bb55bb", "#aaddee", "#88ddcc", "#776688", "#333344"],
  8572: ["#114444", "#990044", "#7722cc", "#774455", "#0000aa"],
  8573: ["#ffddee", "#55aaaa", "#33ff00", "#99aadd", "#8844ff"],
  8574: ["#aaaacc", "#11ccdd", "#33bbdd", "#dd3322", "#dd4499"],
  8575: ["#bbaa33", "#aa6655", "#ffffff", "#ee7766", "#cc8855"],
  8576: ["#aabb99", "#ff22bb", "#aa66ee", "#227799", "#991199"],
  8577: ["#dd4422", "#bb22aa", "#1177bb", "#66bb33", "#442288"],
  8578: ["#550066", "#dd7722", "#11ee55", "#221111", "#bbeeaa"],
  8579: ["#00aa77", "#99aa66", "#44aa55", "#8888bb", "#5544bb"],
  8580: ["#ff0022", "#bbcc44", "#99ff55", "#ffbbdd", "#55cc88"],
  8581: ["#115577", "#8855dd", "#ffaacc", "#55eecc", "#552299"],
  8582: ["#335511", "#0099dd", "#668855", "#8899cc", "#ee0055"],
  8583: ["#5544aa", "#ee3355", "#330022", "#aa6688", "#cc7799"],
  8584: ["#11ffaa", "#ee4433", "#441100", "#aa0099", "#33cccc"],
  8585: ["#3344dd", "#55ff66", "#bb9911", "#dd22ee", "#88cc11"],
  8586: ["#4477ee", "#55cc88", "#00dd22", "#aa9911", "#ffff33"],
  8587: ["#6666ff", "#559933", "#aa3388", "#5588dd", "#dd3333"],
  8588: ["#0077dd", "#cceecc", "#1155bb", "#dd4411", "#ff8833"],
  8589: ["#bb88bb", "#bbaa11", "#0033cc", "#bb6677", "#aa55aa"],
  8590: ["#dd5522", "#44aa88", "#66ee55", "#440055", "#008833"],
  8591: ["#6677dd", "#229933", "#2277ee", "#553377", "#99cc77"],
  8592: ["#ccccee", "#4422ff", "#2288cc", "#11aa77", "#77aa99"],
  8593: ["#dd77aa", "#ee22ff", "#11eedd", "#997755", "#001122"],
  8594: ["#773366", "#ccbbcc", "#44aaff", "#33dd11", "#bbdd22"],
  8595: ["#44cc44", "#221122", "#6633bb", "#44eeaa", "#eebbaa"],
  8596: ["#dddd99", "#bb9999", "#9977bb", "#44ff33", "#33bb44"],
  8597: ["#881177", "#3377ff", "#117755", "#22aadd", "#aa00dd"],
  8598: ["#666677", "#bb3300", "#ee1133", "#0099aa", "#8844bb"],
  8599: ["#1155ff", "#cc9944", "#ee33dd", "#ddddcc", "#22aa88"],
  8600: ["#aaee00", "#1155aa", "#bbddaa", "#449933", "#77ee11"],
  8601: ["#77cc00", "#223399", "#eeeebb", "#7722ee", "#dd3344"],
  8602: ["#dd44bb", "#ee77ee", "#336655", "#dd6600", "#11bb99"],
  8603: ["#552299", "#22ee00", "#223366", "#119900", "#aa2255"],
  8604: ["#ff0066", "#00dd77", "#662277", "#449922", "#3377ff"],
  8605: ["#ffddbb", "#4477cc", "#88aa77", "#0000aa", "#1144bb"],
  8606: ["#33cc33", "#0077dd", "#eebbbb", "#ff0000", "#66ffee"],
  8607: ["#cc88ff", "#5555bb", "#99bbaa", "#334422", "#1122dd"],
  8608: ["#007788", "#ff8844", "#7788cc", "#996611", "#ee33cc"],
  8609: ["#33eebb", "#8833ff", "#77aa77", "#eeffdd", "#dd11ff"],
  8610: ["#999977", "#6633aa", "#002211", "#cc99cc", "#aa5588"],
  8611: ["#0066cc", "#88cccc", "#552244", "#1133ee", "#9966ee"],
  8612: ["#ff7755", "#ee7755", "#776644", "#55ddee", "#bb44dd"],
  8613: ["#ffff88", "#00ff33", "#557733", "#66ff77", "#33cc77"],
  8614: ["#5544ff", "#aa9977", "#557711", "#dd6611", "#aaaaee"],
  8615: ["#ddffaa", "#ee99dd", "#33eedd", "#66ddff", "#aa6622"],
  8616: ["#118822", "#aa6699", "#dd00ee", "#11ccff", "#cc0033"],
  8617: ["#557799", "#992222", "#ee0099", "#66ccaa", "#ffff99"],
  8618: ["#cc99ee", "#ff7777", "#3333cc", "#998888", "#771100"],
  8619: ["#ffaa44", "#6600ee", "#cc77ff", "#11bb00", "#223300"],
  8620: ["#cc5566", "#881144", "#119966", "#1177ff", "#dd66ee"],
  8621: ["#55ff00", "#66ffee", "#3344ff", "#77dd66", "#99cc22"],
  8622: ["#44ee44", "#664433", "#66ee99", "#99cc44", "#bb44dd"],
  8623: ["#44ff00", "#5544bb", "#446666", "#99ff77", "#bb66cc"],
  8624: ["#dd6688", "#88dd77", "#bbcc66", "#55dd66", "#44ee33"],
  8625: ["#bb9988", "#55cc11", "#776600", "#ee0044", "#0033dd"],
  8626: ["#cc99ff", "#aaee33", "#994488", "#aa7722", "#aaeeee"],
  8627: ["#00cc77", "#aabb44", "#aa99ee", "#55ee55", "#008833"],
  8628: ["#445522", "#88aaaa", "#ddcccc", "#bbeeaa", "#ee0011"],
  8629: ["#aadd88", "#77bbdd", "#55ddaa", "#ee99aa", "#7766cc"],
  8630: ["#004455", "#663388", "#33aacc", "#ccff55", "#55bb55"],
  8631: ["#444433", "#1111bb", "#dd6644", "#8811cc", "#bbee11"],
  8632: ["#00ee11", "#33dd22", "#770088", "#77dd99", "#ee1199"],
  8633: ["#77ee33", "#4455bb", "#884477", "#990022", "#99ee99"],
  8634: ["#ccccdd", "#88ddbb", "#4411ee", "#cc8811", "#22bbff"],
  8635: ["#dd6633", "#44bb33", "#ee5522", "#ff55ee", "#9933dd"],
  8636: ["#9922cc", "#11aa33", "#bbaa99", "#cc1177", "#aaee11"],
  8637: ["#77dd88", "#dd2288", "#3377aa", "#441199", "#dd4433"],
  8638: ["#33dd66", "#8833ff", "#cccccc", "#448855", "#bb9922"],
  8639: ["#003377", "#11ee66", "#bb0000", "#eeff22", "#55ff88"],
  8640: ["#cccc33", "#552244", "#000055", "#bb44dd", "#ee55ff"],
  8641: ["#bb44aa", "#666699", "#7722dd", "#110077", "#22cc00"],
  8642: ["#22ee22", "#3311aa", "#aa99ff", "#22ff66", "#88cc66"],
  8643: ["#aa1144", "#558888", "#4477aa", "#55ccaa", "#33dd00"],
  8644: ["#ffbbdd", "#ccbbaa", "#331188", "#773344", "#777799"],
  8645: ["#996611", "#3366dd", "#11ff00", "#227755", "#3311cc"],
  8646: ["#aaaa88", "#aa6611", "#0077cc", "#dd00aa", "#3399cc"],
  8647: ["#bb77dd", "#77dd66", "#11ff11", "#99ccbb", "#55aacc"],
  8648: ["#11cc22", "#dd55aa", "#33cc11", "#55dddd", "#880044"],
  8649: ["#550077", "#555511", "#3333cc", "#dd22dd", "#228877"],
  8650: ["#66bb66", "#bb8822", "#eedd55", "#ff5522", "#bbccaa"],
  8651: ["#bbaa22", "#559911", "#55aa88", "#66ddaa", "#55cc88"],
  8652: ["#223322", "#887755", "#cc7799", "#8822ee", "#bb2277"],
  8653: ["#aa7700", "#4477ff", "#8822dd", "#cc33bb", "#9933aa"],
  8654: ["#55ccbb", "#994411", "#88dd33", "#55aabb", "#cc9944"],
  8655: ["#22dd99", "#eecc66", "#dd11ee", "#dd1100", "#443377"],
  8656: ["#ee55bb", "#eeddaa", "#88bbdd", "#dd9900", "#ffdd33"],
  8657: ["#cc5588", "#ff11dd", "#ee11bb", "#4422dd", "#663344"],
  8658: ["#00aa33", "#6600aa", "#776644", "#4488dd", "#aabb66"],
  8659: ["#9955dd", "#22cc22", "#44bb22", "#44dd00", "#663333"],
  8660: ["#1111dd", "#ddeeee", "#dd33aa", "#bb4400", "#ffbbaa"],
  8661: ["#887766", "#55ff22", "#ee22ee", "#88ff22", "#119977"],
  8662: ["#ee2277", "#66ff33", "#99eeaa", "#669977", "#ffee66"],
  8663: ["#117744", "#99dd55", "#778866", "#88ee55", "#44bbaa"],
  8664: ["#bb8822", "#bbcc66", "#6622bb", "#55ff00", "#4477bb"],
  8665: ["#11bb55", "#11cc00", "#66cc33", "#ff0077", "#ee7788"],
  8666: ["#ff8800", "#ffff99", "#663366", "#774422", "#883377"],
  8667: ["#555577", "#cceeaa", "#cc66ee", "#11dd99", "#446688"],
  8668: ["#aa7733", "#99cccc", "#558844", "#664444", "#8822ee"],
  8669: ["#1133aa", "#33dd44", "#665599", "#00ccff", "#ee99bb"],
  8670: ["#aa1111", "#cc55dd", "#558855", "#bb1199", "#77aa99"],
  8671: ["#774455", "#bb7788", "#ccaa99", "#66ccee", "#33bb44"],
  8672: ["#99dd22", "#cc33cc", "#ffdd00", "#882299", "#4499dd"],
  8673: ["#551188", "#440000", "#0033aa", "#6611bb", "#aaaaee"],
  8674: ["#ff4466", "#33ffdd", "#117711", "#ff3399", "#4400ff"],
  8675: ["#eeddaa", "#553366", "#ee0099", "#4477aa", "#11ccaa"],
  8676: ["#443300", "#aaaadd", "#44ffbb", "#332244", "#2200bb"],
  8677: ["#3377ff", "#66ffff", "#bb2200", "#77bb77", "#33dd55"],
  8678: ["#99cc77", "#992299", "#ccee00", "#66ff55", "#ccbb77"],
  8679: ["#3366aa", "#449999", "#884477", "#ddff00", "#ee00ee"],
  8680: ["#774444", "#002222", "#770011", "#ff4466", "#2277ff"],
  8681: ["#88bbaa", "#5599ee", "#33aa44", "#cc5599", "#ddffff"],
  8682: ["#5555ee", "#bb33bb", "#ccbb88", "#ccbb99", "#33cc66"],
  8683: ["#ff5544", "#bb8822", "#ee6655", "#551111", "#006622"],
  8684: ["#88aa33", "#9922ff", "#66ee88", "#33ff99", "#2266dd"],
  8685: ["#dd8877", "#991188", "#00bbdd", "#22ee33", "#003344"],
  8686: ["#eebb00", "#110022", "#4455cc", "#33ccee", "#884477"],
  8687: ["#770055", "#bbee00", "#663333", "#668811", "#660033"],
  8688: ["#669955", "#883344", "#3355bb", "#662233", "#ee7744"],
  8689: ["#225577", "#77ccbb", "#aabb77", "#114433", "#cc9966"],
  8690: ["#999955", "#bb3399", "#ff8877", "#aa1133", "#998844"],
  8691: ["#880055", "#111133", "#551177", "#66dd44", "#dd22ee"],
  8692: ["#664455", "#00cc55", "#22aa22", "#773366", "#aa5566"],
  8693: ["#77aa22", "#880066", "#00aa22", "#7733aa", "#110033"],
  8694: ["#338833", "#22ffcc", "#002211", "#66aadd", "#33bb11"],
  8695: ["#ffbb66", "#990011", "#eeeeee", "#ee2211", "#223322"],
  8696: ["#aa55cc", "#773388", "#0088bb", "#6688cc", "#bb2244"],
  8697: ["#aa7700", "#bbff88", "#999988", "#ccaacc", "#11aabb"],
  8698: ["#2277dd", "#77aa99", "#aa00aa", "#229922", "#dd6677"],
  8699: ["#773322", "#bb0088", "#ee4433", "#22cc77", "#5544cc"],
  8700: ["#eeeeff", "#33cc77", "#bbbb00", "#eeeecc", "#333377"],
  8701: ["#007799", "#33ee00", "#dd7744", "#2211ff", "#eedd99"],
  8702: ["#aa77ff", "#889911", "#444422", "#00bb77", "#662255"],
  8703: ["#8811bb", "#661199", "#aa77bb", "#228844", "#77dd88"],
  8704: ["#6633cc", "#ee0099", "#ee0066", "#dd0033", "#cc44cc"],
  8705: ["#550055", "#115544", "#220000", "#77dddd", "#00ff33"],
  8706: ["#bb5577", "#5522ee", "#ccaa88", "#991111", "#aa6644"],
  8707: ["#555555", "#7766dd", "#eeee88", "#44ddbb", "#6622ff"],
  8708: ["#ee88bb", "#0099ff", "#dd1155", "#ddeeee", "#bb88ee"],
  8709: ["#dd44cc", "#8844cc", "#992200", "#66ee55", "#aa88dd"],
  8710: ["#ddffff", "#33ffdd", "#dd1122", "#bbbb00", "#ee00cc"],
  8711: ["#33dd11", "#ee7755", "#5533aa", "#441100", "#aabb33"],
  8712: ["#bb1122", "#ffcc88", "#00dd99", "#5533ff", "#dd88ff"],
  8713: ["#dd11ee", "#223377", "#772200", "#33aa66", "#ee55ee"],
  8714: ["#994455", "#33bb99", "#44dd55", "#ccddaa", "#559944"],
  8715: ["#006644", "#881188", "#cc3311", "#222211", "#ffaaaa"],
  8716: ["#bbcc77", "#0000aa", "#667711", "#11ffbb", "#44ff00"],
  8717: ["#008811", "#113333", "#aaeedd", "#ee7722", "#33bb22"],
  8718: ["#999922", "#114433", "#2211ee", "#cccc22", "#ccffff"],
  8719: ["#7799bb", "#ee88aa", "#aa9900", "#dddd99", "#4422ff"],
  8720: ["#557766", "#225588", "#bbbb55", "#cc8888", "#11bb88"],
  8721: ["#eecccc", "#33bb22", "#aa0099", "#226655", "#aa66dd"],
  8722: ["#ffdd77", "#dd7744", "#ccbbdd", "#aa1111", "#0088cc"],
  8723: ["#77aa33", "#44ee88", "#666688", "#003311", "#ccaa33"],
  8724: ["#55aa44", "#dd66dd", "#ffaaaa", "#aa44ee", "#88aa33"],
  8725: ["#44ddee", "#66aa00", "#664499", "#771188", "#663322"],
  8726: ["#4455ff", "#eecc00", "#ddccff", "#ee22dd", "#443366"],
  8727: ["#994433", "#aadddd", "#cc5500", "#00cc88", "#55dd00"],
  8728: ["#55dd88", "#ccee22", "#eedd99", "#dd44aa", "#1188ff"],
  8729: ["#114455", "#33ee77", "#00eebb", "#11ff88", "#ee9922"],
  8730: ["#443366", "#33ee22", "#55cc55", "#1133bb", "#bb4400"],
  8731: ["#44bb66", "#44ff11", "#77ffcc", "#335544", "#770033"],
  8732: ["#55ddaa", "#bb3377", "#00dd77", "#886611", "#11ff77"],
  8733: ["#886633", "#ffddff", "#4499aa", "#ee5566", "#ee0044"],
  8734: ["#229911", "#99ccdd", "#dd4466", "#aa88dd", "#11cc55"],
  8735: ["#112211", "#886699", "#00ffcc", "#112233", "#ffcc44"],
  8736: ["#44dd44", "#cc88ff", "#11aa44", "#006633", "#66ff55"],
  8737: ["#44aa33", "#44ddee", "#4433bb", "#dd8888", "#ee6600"],
  8738: ["#11dd99", "#332200", "#440077", "#ff22aa", "#bb2277"],
  8739: ["#33dddd", "#3377dd", "#00cc11", "#228844", "#7700aa"],
  8740: ["#dd00cc", "#99bb11", "#44ddaa", "#bb6666", "#8888ff"],
  8741: ["#aa22aa", "#00dd77", "#66ffbb", "#aa22bb", "#11bb77"],
  8742: ["#77ff00", "#ff6699", "#000011", "#007744", "#6622cc"],
  8743: ["#ddffaa", "#bbaa88", "#99bbff", "#bb6688", "#55cc22"],
  8744: ["#223333", "#55aa11", "#9955aa", "#552255", "#ff99aa"],
  8745: ["#dd7788", "#dd88cc", "#9977bb", "#661111", "#22aaff"],
  8746: ["#111177", "#77dd55", "#991122", "#443300", "#dd2233"],
  8747: ["#445511", "#dddd33", "#aa7799", "#99cc88", "#3388bb"],
  8748: ["#229900", "#cc2200", "#ccccff", "#33bb99", "#6677cc"],
  8749: ["#6688ee", "#cc4444", "#bb33aa", "#001111", "#dd4444"],
  8750: ["#223355", "#9922ee", "#99ff77", "#dd7755", "#993311"],
  8751: ["#88eeff", "#bbddee", "#22aabb", "#ccffff", "#7744bb"],
  8752: ["#ee0033", "#888888", "#bb0055", "#aa2277", "#ffbbdd"],
  8753: ["#aa22ee", "#881188", "#77ee22", "#661133", "#ee11cc"],
  8754: ["#002255", "#ffff99", "#99cc88", "#337722", "#44ff22"],
  8755: ["#aacc99", "#eecccc", "#223311", "#cc1111", "#117766"],
  8756: ["#aa5500", "#667722", "#cc00cc", "#aacc44", "#eeee55"],
  8757: ["#ffbb55", "#bb99ee", "#9966ee", "#110011", "#55bb77"],
  8758: ["#884422", "#00ccbb", "#0077ee", "#eeccbb", "#ffaa66"],
  8759: ["#33cc11", "#2222aa", "#88bbdd", "#112222", "#88ff22"],
  8760: ["#44bb77", "#bb2266", "#ddee55", "#aa99dd", "#66cccc"],
  8761: ["#4400ff", "#332288", "#cc0088", "#22dd44", "#cc3355"],
  8762: ["#22bb11", "#33bb66", "#ff0000", "#883366", "#44ff66"],
  8763: ["#ff0011", "#ff7711", "#eedd00", "#aa88ff", "#55dd77"],
  8764: ["#996600", "#66eeaa", "#441155", "#66ccdd", "#8899bb"],
  8765: ["#aaff88", "#ddffdd", "#66ee55", "#cc7788", "#996666"],
  8766: ["#55ddee", "#115511", "#6666cc", "#00ee99", "#ccaaff"],
  8767: ["#ee99cc", "#ff66bb", "#331111", "#77ffee", "#1100ee"],
  8768: ["#113333", "#443366", "#00aa11", "#ccbb00", "#aa2200"],
  8769: ["#bb3311", "#667744", "#880055", "#ffdd11", "#119955"],
  8770: ["#bb9944", "#221166", "#ee88bb", "#5566ff", "#008866"],
  8771: ["#eeffdd", "#3355ee", "#99bb55", "#882211", "#11dd66"],
  8772: ["#bb55ff", "#eecc33", "#dd8811", "#990011", "#114499"],
  8773: ["#55ddaa", "#4400ff", "#ffaacc", "#883311", "#9966aa"],
  8774: ["#11eedd", "#4488ff", "#ffccdd", "#dd4433", "#aa5522"],
  8775: ["#779944", "#1188dd", "#335500", "#8855ff", "#44ddaa"],
  8776: ["#557733", "#dd00cc", "#bb8833", "#bbff33", "#8833ee"],
  8777: ["#dddd66", "#44cc77", "#33ddbb", "#bb5555", "#777755"],
  8778: ["#ffccff", "#ccbb11", "#99eeff", "#22cc00", "#dddd22"],
  8779: ["#cccc33", "#554477", "#aa2255", "#7711ff", "#11ee77"],
  8780: ["#77eeee", "#663333", "#1122cc", "#aa11bb", "#aa44ff"],
  8781: ["#bb8877", "#ccbb33", "#aa88ff", "#bb9977", "#338899"],
  8782: ["#99cc33", "#eeddaa", "#114444", "#2222ee", "#558899"],
  8783: ["#661144", "#aa1100", "#77bb00", "#334455", "#00ff44"],
  8784: ["#2211dd", "#77aa55", "#11cc66", "#11cc22", "#11ee99"],
  8785: ["#558811", "#5533aa", "#00aaaa", "#ee11dd", "#005522"],
  8786: ["#ccaa55", "#ffccdd", "#dd11ff", "#99ffaa", "#888899"],
  8787: ["#2233cc", "#dd9900", "#dd3377", "#1111ee", "#66ff11"],
  8788: ["#770077", "#bb77dd", "#2211ff", "#00aa44", "#3311ff"],
  8789: ["#11aa55", "#bbbb55", "#dd2255", "#eeaa11", "#003333"],
  8790: ["#554499", "#aa2299", "#112266", "#1122cc", "#5577dd"],
  8791: ["#44ee22", "#339988", "#443366", "#22ccbb", "#ff4466"],
  8792: ["#00ddcc", "#77aa44", "#ccaa11", "#0022bb", "#337766"],
  8793: ["#bbdd11", "#88aa00", "#ffaa55", "#555533", "#778811"],
  8794: ["#2200cc", "#9900aa", "#dd88dd", "#77ff22", "#eeeedd"],
  8795: ["#aaddff", "#ddcc33", "#667711", "#88dd55", "#117700"],
  8796: ["#993333", "#22ccbb", "#449977", "#77aaaa", "#77cc88"],
  8797: ["#331144", "#eeff11", "#442266", "#44bbff", "#0022dd"],
  8798: ["#ffcc00", "#885588", "#448844", "#aa8877", "#6633cc"],
  8799: ["#aa88ff", "#22dd55", "#4477ee", "#8800bb", "#776611"],
  8800: ["#ee1100", "#11dd00", "#113366", "#886666", "#2200dd"],
  8801: ["#ddddcc", "#331188", "#ee6688", "#119922", "#446622"],
  8802: ["#dd1166", "#bbcc88", "#cc00ff", "#66ff88", "#110066"],
  8803: ["#ee6633", "#ff3344", "#ccff22", "#ccff33", "#bb2299"],
  8804: ["#aadd66", "#665533", "#aaee88", "#889911", "#777700"],
  8805: ["#003311", "#441144", "#ff5500", "#ddbb55", "#eecc33"],
  8806: ["#558844", "#cccc55", "#11aa44", "#88aabb", "#66aa88"],
  8807: ["#ff77ee", "#44cc55", "#cc00aa", "#dd2222", "#eeaaff"],
  8808: ["#55cc66", "#bb9955", "#99ee88", "#bb0044", "#6633aa"],
  8809: ["#4466ee", "#00bb77", "#0033aa", "#11ff22", "#ff8811"],
  8810: ["#885555", "#aadd22", "#aa5533", "#bb4477", "#cc8822"],
  8811: ["#ffddff", "#ee0044", "#aa1100", "#aaff00", "#44aa55"],
  8812: ["#66ddcc", "#003388", "#ddee44", "#4444ff", "#bb44dd"],
  8813: ["#dd4477", "#774422", "#4444bb", "#22ccaa", "#9966ff"],
  8814: ["#7788ee", "#55dd22", "#dd4400", "#1177ee", "#55eeff"],
  8815: ["#665555", "#7733ee", "#22ff22", "#995555", "#ffaa99"],
  8816: ["#eeffdd", "#dddd44", "#bbee33", "#8800cc", "#eecc44"],
  8817: ["#88dddd", "#2233aa", "#9966aa", "#664444", "#eedd99"],
  8818: ["#5511dd", "#aaaaff", "#77ffdd", "#dd4499", "#1199ff"],
  8819: ["#66ddcc", "#ffccdd", "#006600", "#99bbdd", "#447744"],
  8820: ["#ccbb33", "#ddaa22", "#11cccc", "#44eecc", "#ffcc55"],
  8821: ["#bbaacc", "#992200", "#22ffaa", "#cc88ee", "#559933"],
  8822: ["#999988", "#446666", "#ffdd44", "#5511dd", "#00eebb"],
  8823: ["#110011", "#66ff00", "#ffeeff", "#553344", "#11ee66"],
  8824: ["#00ffaa", "#44ccbb", "#00aa88", "#ddee88", "#662233"],
  8825: ["#ccddaa", "#44cc55", "#aa9955", "#779999", "#8811ee"],
  8826: ["#ee99cc", "#99dd55", "#994433", "#2244dd", "#0044ff"],
  8827: ["#aabbff", "#555555", "#ee99ff", "#226655", "#ee9933"],
  8828: ["#442244", "#88dd99", "#22cccc", "#cceecc", "#44cc99"],
  8829: ["#ee9966", "#220044", "#998811", "#113344", "#88cc88"],
  8830: ["#ee99ff", "#bbee22", "#ff44aa", "#772233", "#aa1122"],
  8831: ["#ee33bb", "#1166bb", "#ffdd00", "#553333", "#9922ff"],
  8832: ["#6644bb", "#9900ff", "#996633", "#bbeecc", "#bbaabb"],
  8833: ["#ee88ff", "#00bb33", "#33ccff", "#88aa66", "#8822ff"],
  8834: ["#99bbaa", "#88eebb", "#993399", "#ff1155", "#000000"],
  8835: ["#99bb77", "#995511", "#99aa88", "#553355", "#11bbff"],
  8836: ["#55dd33", "#cc8866", "#44aa11", "#ff7755", "#993311"],
  8837: ["#8800ee", "#4488ff", "#6666ee", "#33cc22", "#22bbdd"],
  8838: ["#551122", "#00bbdd", "#5500ee", "#88cccc", "#99aaaa"],
  8839: ["#115588", "#bb44aa", "#aa0077", "#5555dd", "#aa00ff"],
  8840: ["#ddff00", "#bb6677", "#4466aa", "#44ee77", "#66bb00"],
  8841: ["#9999bb", "#99bb55", "#44ddbb", "#cc7799", "#55dd88"],
  8842: ["#ee1177", "#eeaa77", "#ee6666", "#99bb33", "#77bb22"],
  8843: ["#cc22aa", "#aa77ee", "#cc4411", "#119922", "#665588"],
  8844: ["#112288", "#77eeee", "#ff22dd", "#33dd66", "#226611"],
  8845: ["#5533dd", "#55aa11", "#557744", "#55ee11", "#ee99ee"],
  8846: ["#667755", "#113311", "#ee7788", "#dd6622", "#66ccff"],
  8847: ["#ff0044", "#116655", "#7777cc", "#bb1144", "#881144"],
  8848: ["#cc88ff", "#11cc33", "#11cc33", "#557755", "#8811aa"],
  8849: ["#443399", "#aa6644", "#5555ff", "#cc8855", "#bb5599"],
  8850: ["#660077", "#dd11aa", "#aa0044", "#881133", "#004455"],
  8851: ["#554422", "#cc22bb", "#ff99dd", "#ee4455", "#88cc11"],
  8852: ["#442233", "#ee33dd", "#ddffcc", "#aa7744", "#5588aa"],
  8853: ["#99ff66", "#661122", "#337777", "#77aa77", "#dd1199"],
  8854: ["#11aacc", "#eeaaaa", "#55bbbb", "#44aacc", "#662211"],
  8855: ["#663355", "#551199", "#bbbb11", "#0066ee", "#ddff00"],
  8856: ["#77ddcc", "#114455", "#ccee11", "#bbaa44", "#bbaadd"],
  8857: ["#ddcc99", "#ee88ff", "#0066bb", "#00cccc", "#445588"],
  8858: ["#00dd11", "#991177", "#4488cc", "#882266", "#ddcc99"],
  8859: ["#885533", "#3322cc", "#5544ee", "#cc3333", "#99ffdd"],
  8860: ["#bb00dd", "#00cc00", "#995588", "#aaaacc", "#dd2244"],
  8861: ["#aa2288", "#99bb11", "#772255", "#ee2222", "#3344dd"],
  8862: ["#ee66bb", "#995599", "#3355ee", "#bb66ee", "#cc55aa"],
  8863: ["#996666", "#336622", "#005577", "#88ee88", "#ff0077"],
  8864: ["#ff4499", "#dd66ff", "#225533", "#441166", "#2299bb"],
  8865: ["#559955", "#bbeedd", "#aa3311", "#44aa99", "#556611"],
  8866: ["#22ccdd", "#227711", "#4455ee", "#bbee88", "#66aacc"],
  8867: ["#0033dd", "#ffff55", "#0033aa", "#00bb55", "#aa5599"],
  8868: ["#55bb55", "#336699", "#66cc33", "#22ee44", "#22eedd"],
  8869: ["#5566ff", "#880088", "#664444", "#ffdd22", "#00cc99"],
  8870: ["#3333ee", "#eebb11", "#22ee00", "#eeff11", "#ee66aa"],
  8871: ["#002200", "#ff9966", "#ff9999", "#bbffff", "#ee2266"],
  8872: ["#cc5588", "#442200", "#55cc44", "#330022", "#775511"],
  8873: ["#226644", "#4455cc", "#ff6666", "#66ee22", "#88ffff"],
  8874: ["#ee5522", "#88ee11", "#662244", "#00ff22", "#aa88dd"],
  8875: ["#ee0088", "#bb7777", "#228855", "#4433bb", "#775555"],
  8876: ["#eeccaa", "#aa7722", "#77bbff", "#33ddcc", "#ccbbbb"],
  8877: ["#006622", "#ee3344", "#009966", "#884455", "#884433"],
  8878: ["#2222ff", "#5577bb", "#11bbff", "#997744", "#66ccdd"],
  8879: ["#8800ee", "#446611", "#ee44bb", "#4444dd", "#6622ee"],
  8880: ["#ee1122", "#ccdddd", "#66ff55", "#7700bb", "#cc9911"],
  8881: ["#55ffdd", "#33bb22", "#22ccee", "#775555", "#9922ee"],
  8882: ["#44aaff", "#dd66dd", "#7711dd", "#553399", "#9944ee"],
  8883: ["#44aaaa", "#449944", "#774477", "#bbff00", "#ee5511"],
  8884: ["#330077", "#dd6666", "#22aa11", "#00bb66", "#5588dd"],
  8885: ["#ff8811", "#55bb44", "#77bb00", "#11aa55", "#bb8899"],
  8886: ["#446600", "#776688", "#ff9944", "#00bbee", "#227766"],
  8887: ["#55ddff", "#998822", "#004466", "#ff66bb", "#111144"],
  8888: ["#3388bb", "#3355cc", "#443344", "#99dd66", "#cc11aa"],
  8889: ["#996688", "#ee44ff", "#ddbbee", "#444422", "#5522dd"],
  8890: ["#cc7777", "#ccccbb", "#55aa22", "#448800", "#ee2233"],
  8891: ["#dd6699", "#66cccc", "#ffbb22", "#008899", "#3399aa"],
  8892: ["#5588cc", "#77ee66", "#994433", "#bb9966", "#bb3399"],
  8893: ["#662299", "#ffee22", "#ccaa44", "#ee0099", "#551188"],
  8894: ["#777700", "#772277", "#115522", "#77bbee", "#bbaaee"],
  8895: ["#22ee77", "#88cc44", "#1199ee", "#33bb55", "#99ee66"],
  8896: ["#66cc66", "#224433", "#ee3377", "#77bb88", "#aa55ee"],
  8897: ["#cc44aa", "#66ee99", "#bbee22", "#8855dd", "#004466"],
  8898: ["#dd33ff", "#bbcc99", "#115522", "#dd5522", "#bbbb00"],
  8899: ["#11aaaa", "#446633", "#2233ee", "#88dd33", "#440066"],
  8900: ["#2211ff", "#88ff88", "#33bb44", "#ffee22", "#33eeee"],
  8901: ["#22eebb", "#dd8822", "#44cc55", "#2222ee", "#662255"],
  8902: ["#669977", "#cc0044", "#88bb00", "#00bb77", "#4477ff"],
  8903: ["#3377bb", "#004411", "#eedd22", "#337711", "#22cc77"],
  8904: ["#662211", "#bb55dd", "#bb1111", "#002222", "#aa8877"],
  8905: ["#88bb55", "#ee5500", "#55dd55", "#663311", "#77ffdd"],
  8906: ["#eeff66", "#000011", "#449966", "#eeeebb", "#1166dd"],
  8907: ["#33dd44", "#cc0000", "#558811", "#11bbaa", "#cc1199"],
  8908: ["#ffbbdd", "#aa3377", "#9977aa", "#eeaaaa", "#99dd11"],
  8909: ["#aa33dd", "#eecccc", "#66ff22", "#ee8811", "#bb0022"],
  8910: ["#005544", "#dd55ff", "#bb5588", "#bbccbb", "#ff22aa"],
  8911: ["#aaffbb", "#aa7755", "#bbcc33", "#55eeaa", "#88cc22"],
  8912: ["#661177", "#aa66dd", "#551111", "#11bb22", "#99bbaa"],
  8913: ["#eeff88", "#33ee77", "#449944", "#ff3366", "#888800"],
  8914: ["#448888", "#776644", "#118811", "#446699", "#885511"],
  8915: ["#11bb00", "#ccee44", "#667744", "#66bb55", "#ee99aa"],
  8916: ["#444444", "#ffaa33", "#5577cc", "#1155ff", "#558811"],
  8917: ["#bb9999", "#330066", "#88aa88", "#ee8822", "#9900ee"],
  8918: ["#00aa22", "#339988", "#ee8833", "#8866ee", "#0099cc"],
  8919: ["#eeeeee", "#993366", "#997777", "#442244", "#4400dd"],
  8920: ["#aadd55", "#777777", "#bb0077", "#33dd77", "#5522ee"],
  8921: ["#99ffbb", "#ee5500", "#cc44ee", "#ee4488", "#bbff00"],
  8922: ["#550022", "#225588", "#331100", "#ee6611", "#11ffee"],
  8923: ["#55ee99", "#115577", "#55bbee", "#9900ff", "#ff2222"],
  8924: ["#119988", "#ee66aa", "#66eecc", "#66ee88", "#8833ee"],
  8925: ["#6644aa", "#ccdd88", "#11eedd", "#33dd88", "#aa88ee"],
  8926: ["#88bb22", "#991166", "#ccff44", "#ff8833", "#cc0011"],
  8927: ["#ccdd66", "#007799", "#22ff00", "#99ff99", "#771144"],
  8928: ["#33ff66", "#aa44cc", "#33bb66", "#5566bb", "#77bbff"],
  8929: ["#9955cc", "#4499aa", "#009933", "#774400", "#99cc77"],
  8930: ["#3344ee", "#4433ff", "#dd2288", "#66aa33", "#66cc55"],
  8931: ["#446699", "#6633aa", "#3300cc", "#bb4455", "#44aabb"],
  8932: ["#ccaadd", "#ee4455", "#2299bb", "#8822cc", "#7700bb"],
  8933: ["#1188dd", "#2200cc", "#cc6611", "#8855bb", "#ee5500"],
  8934: ["#11aa00", "#ff4477", "#bb3355", "#cc6677", "#118888"],
  8935: ["#112277", "#9922ee", "#6666bb", "#770033", "#22bbcc"],
  8936: ["#227766", "#dd2299", "#55bbff", "#ddcc44", "#eeeeff"],
  8937: ["#ee9988", "#444488", "#666655", "#88dd66", "#99ffbb"],
  8938: ["#552288", "#2266ff", "#449933", "#bbbbcc", "#eedd33"],
  8939: ["#664466", "#ee0033", "#771166", "#cccc77", "#ee8899"],
  8940: ["#ff8877", "#225522", "#558866", "#996622", "#772266"],
  8941: ["#ff44aa", "#66aa77", "#001199", "#554477", "#dd7766"],
  8942: ["#ff1199", "#eeee44", "#119911", "#665588", "#aaeeee"],
  8943: ["#330033", "#7755cc", "#bb1144", "#cc9933", "#771133"],
  8944: ["#ff7777", "#66ffee", "#dd88dd", "#cc22bb", "#007744"],
  8945: ["#aaaadd", "#0044ff", "#220000", "#dddddd", "#aa22dd"],
  8946: ["#55dd22", "#99ddff", "#dd66dd", "#55cc66", "#bbee99"],
  8947: ["#dd1155", "#bb0044", "#dd55ee", "#4477aa", "#3344aa"],
  8948: ["#007711", "#ffff77", "#885533", "#997766", "#ff2255"],
  8949: ["#99bbbb", "#000022", "#cceeff", "#003311", "#99ee44"],
  8950: ["#2277bb", "#99bb11", "#1111ee", "#4499ee", "#cc22dd"],
  8951: ["#aa6633", "#99dddd", "#337722", "#99ee11", "#88bb88"],
  8952: ["#88aabb", "#8811bb", "#0022ee", "#ee7700", "#00ddaa"],
  8953: ["#ff22bb", "#664400", "#ffcc00", "#889944", "#991111"],
  8954: ["#223399", "#44aa00", "#dd0055", "#ff33ff", "#33ee33"],
  8955: ["#33ff33", "#bbbb55", "#773388", "#0099ee", "#6666cc"],
  8956: ["#aacccc", "#ee1144", "#9955cc", "#77ff22", "#bbbbcc"],
  8957: ["#1133cc", "#aa44aa", "#22cc00", "#999922", "#00ddaa"],
  8958: ["#77eebb", "#aaee88", "#553300", "#5566cc", "#cc66bb"],
  8959: ["#ee66cc", "#227777", "#1111bb", "#550000", "#77ccdd"],
  8960: ["#ffff33", "#55ddee", "#ddbb88", "#bb7722", "#663300"],
  8961: ["#3300aa", "#66bb55", "#eeffff", "#00aadd", "#996622"],
  8962: ["#aaee88", "#1111ee", "#6688ff", "#33bbff", "#1111cc"],
  8963: ["#aaffee", "#22ddbb", "#aa7799", "#bbee44", "#441133"],
  8964: ["#cc5599", "#ffdd55", "#6611cc", "#eeaa66", "#1155ee"],
  8965: ["#dd3333", "#ff8866", "#ee2200", "#115511", "#ee33dd"],
  8966: ["#bb8877", "#119900", "#cc8877", "#55aa44", "#0022ee"],
  8967: ["#6655bb", "#55eedd", "#ccee22", "#ee11aa", "#225511"],
  8968: ["#9977ff", "#ff00ff", "#bb1122", "#448833", "#3355cc"],
  8969: ["#889944", "#cc6600", "#3333bb", "#88ee88", "#aabb33"],
  8970: ["#7700ff", "#aa44ee", "#662288", "#66bb99", "#ccbb11"],
  8971: ["#774400", "#aaeebb", "#55aaff", "#66aa00", "#227777"],
  8972: ["#228855", "#bb8866", "#dd3300", "#22cc11", "#55dd11"],
  8973: ["#66aa77", "#ffffaa", "#aabb77", "#ee44aa", "#33ddee"],
  8974: ["#99cccc", "#aadd88", "#334477", "#cc3388", "#442255"],
  8975: ["#99ff00", "#aaddee", "#ee55aa", "#338888", "#666699"],
  8976: ["#55cc99", "#88ee44", "#aa0033", "#555544", "#9922ee"],
  8977: ["#aa8811", "#99dd33", "#dd00aa", "#ffaa99", "#ff7766"],
  8978: ["#99aa00", "#77ccaa", "#bb1188", "#5522aa", "#339988"],
  8979: ["#8877bb", "#6677cc", "#99bb00", "#aa99bb", "#002288"],
  8980: ["#443377", "#66ff55", "#11eeff", "#bbaa55", "#ccbbff"],
  8981: ["#66ffff", "#ddee77", "#008822", "#11bbaa", "#99dd33"],
  8982: ["#880011", "#ee3366", "#33bb44", "#bb9944", "#88bb77"],
  8983: ["#ff9955", "#bbddbb", "#ff22cc", "#9933ee", "#ee9944"],
  8984: ["#997722", "#44aa00", "#aa6666", "#aa9988", "#66dd44"],
  8985: ["#ee8800", "#bb1144", "#11ff99", "#334488", "#113399"],
  8986: ["#9911ee", "#ff6666", "#992288", "#22bbaa", "#3388aa"],
  8987: ["#ee88dd", "#11cc22", "#66eeee", "#2266ff", "#33ff55"],
  8988: ["#7766aa", "#880044", "#8822ff", "#8877ee", "#221166"],
  8989: ["#77dd66", "#ff1133", "#ddee77", "#449988", "#ee11dd"],
  8990: ["#dd22cc", "#006600", "#99ddff", "#ee66aa", "#ff1188"],
  8991: ["#cc44ee", "#ddcc66", "#dd7744", "#88eeee", "#66ee44"],
  8992: ["#aa5577", "#3311dd", "#6699dd", "#88dd99", "#cc9922"],
  8993: ["#ddddbb", "#4455dd", "#8800cc", "#aadd00", "#008888"],
  8994: ["#2277cc", "#dd55bb", "#bbee44", "#1100aa", "#aa6644"],
  8995: ["#ee88aa", "#77aa44", "#885533", "#77dd33", "#ccff44"],
  8996: ["#666666", "#ff7733", "#aaaa88", "#dd8811", "#aaddaa"],
  8997: ["#eeff77", "#99aa33", "#77cccc", "#cc4455", "#eedd55"],
  8998: ["#667777", "#007733", "#eeffff", "#55ccdd", "#22ff00"],
  8999: ["#55ff55", "#996622", "#ff4433", "#aaaaaa", "#ffff44"],
  9000: ["#cc0000", "#ffaa22", "#9922dd", "#337722", "#eeaa00"],
  9001: ["#0099ff", "#88aa55", "#ddccaa", "#337733", "#55ee66"],
  9002: ["#8866ff", "#889999", "#44ccbb", "#660044", "#991177"],
  9003: ["#55bbcc", "#8888ff", "#11cc77", "#9999bb", "#998899"],
  9004: ["#664488", "#ee99aa", "#005588", "#33ffee", "#553366"],
  9005: ["#669922", "#77bb44", "#77dd55", "#bb4466", "#667766"],
  9006: ["#99aaaa", "#4411aa", "#77cc44", "#77ccff", "#bbbbee"],
  9007: ["#bb1111", "#66ee55", "#33ffee", "#88aa66", "#227700"],
  9008: ["#00ccdd", "#ddaaaa", "#ffeeff", "#2299dd", "#11aa22"],
  9009: ["#33cc88", "#003322", "#bbddee", "#3344bb", "#880000"],
  9010: ["#0044aa", "#88aa00", "#ffffcc", "#773322", "#226688"],
  9011: ["#6699cc", "#33ff22", "#667733", "#88aa11", "#880044"],
  9012: ["#bb5577", "#cc0077", "#ee5599", "#00ffaa", "#77dd11"],
  9013: ["#bb5511", "#11eedd", "#ccaa77", "#77ff55", "#ffdd77"],
  9014: ["#ccaa88", "#4488aa", "#aaff99", "#3388dd", "#000000"],
  9015: ["#229922", "#220033", "#ff3300", "#ffbb66", "#8866ee"],
  9016: ["#7788aa", "#11bb22", "#dd4433", "#22cc99", "#ff22dd"],
  9017: ["#441166", "#eeaa55", "#5500ff", "#bb99aa", "#885599"],
  9018: ["#dd8833", "#44ff11", "#55aa33", "#33ddbb", "#44cc11"],
  9019: ["#33aaee", "#44aacc", "#0055bb", "#dd33bb", "#0088bb"],
  9020: ["#ee5555", "#ff77bb", "#227799", "#4411ff", "#774400"],
  9021: ["#cc0000", "#992222", "#992200", "#22ddee", "#1177dd"],
  9022: ["#bb33cc", "#ee3322", "#993399", "#ddcc11", "#3311cc"],
  9023: ["#88ccdd", "#111166", "#440011", "#bbdd44", "#886666"],
  9024: ["#0077aa", "#eedd22", "#779911", "#55ee33", "#669933"],
  9025: ["#eeff11", "#ffbb22", "#eeaa99", "#115599", "#774455"],
  9026: ["#cc1155", "#334477", "#44ddbb", "#aa11ee", "#111188"],
  9027: ["#880011", "#88bb55", "#dd22aa", "#996688", "#ddbbaa"],
  9028: ["#ee3333", "#660077", "#ffee99", "#ffaa33", "#bb7766"],
  9029: ["#4477ff", "#66aa11", "#eebbbb", "#ff7755", "#aa22bb"],
  9030: ["#11ccaa", "#1122ee", "#ccddff", "#66aa11", "#cc3355"],
  9031: ["#33ffdd", "#ff44dd", "#cc99cc", "#1144ee", "#bb88cc"],
  9032: ["#ff22cc", "#aa0088", "#553344", "#775533", "#0066cc"],
  9033: ["#995522", "#ffaa00", "#dd1111", "#9966aa", "#0033ff"],
  9034: ["#669966", "#00ee11", "#88cc88", "#aa88dd", "#eecc00"],
  9035: ["#337722", "#ee55ee", "#eebbaa", "#99dd55", "#669900"],
  9036: ["#cc3300", "#33dd99", "#ee1100", "#2255ee", "#ff66ff"],
  9037: ["#66ee00", "#eedd55", "#1100dd", "#0044ee", "#666622"],
  9038: ["#4466ee", "#1177cc", "#3399ee", "#eebb44", "#558866"],
  9039: ["#ffff11", "#eedd99", "#448855", "#ff55cc", "#995500"],
  9040: ["#77cccc", "#aaaaee", "#77dd66", "#5522aa", "#776622"],
  9041: ["#ffff44", "#112200", "#cc7711", "#116644", "#995500"],
  9042: ["#44aa44", "#bb00aa", "#66aa77", "#dd3377", "#660066"],
  9043: ["#11bb66", "#cc9900", "#bb3366", "#00aa77", "#3344ff"],
  9044: ["#dd99cc", "#992255", "#0099ff", "#779900", "#3366cc"],
  9045: ["#ddccbb", "#558800", "#117700", "#5511aa", "#0055ee"],
  9046: ["#661166", "#220022", "#3399bb", "#66ee88", "#33bb22"],
  9047: ["#99cc00", "#33bb99", "#bb55bb", "#ddaaff", "#eeff99"],
  9048: ["#ccbbee", "#eeaa77", "#110055", "#dd66aa", "#008866"],
  9049: ["#115588", "#997755", "#cc1133", "#eeff00", "#ffaaaa"],
  9050: ["#66ee77", "#551111", "#bb0099", "#333355", "#552277"],
  9051: ["#668833", "#aaffcc", "#ddee55", "#55ff11", "#444488"],
  9052: ["#44ddbb", "#66ee55", "#99dd11", "#5577aa", "#77ffcc"],
  9053: ["#889944", "#880099", "#99dddd", "#889966", "#ff4499"],
  9054: ["#1144ff", "#aaeeee", "#6655ee", "#111155", "#00ff55"],
  9055: ["#aabb55", "#dd7733", "#ff44aa", "#9966dd", "#993300"],
  9056: ["#337722", "#99dd22", "#994422", "#337766", "#3388ee"],
  9057: ["#55dd00", "#990099", "#44bb00", "#33ff66", "#8855dd"],
  9058: ["#99ddff", "#558866", "#ffdd22", "#9966ee", "#ee1188"],
  9059: ["#eecc66", "#334422", "#4477ee", "#22eeaa", "#9944ff"],
  9060: ["#bb88dd", "#225588", "#773300", "#bb44ee", "#ffaaaa"],
  9061: ["#66bbaa", "#eeeebb", "#33aacc", "#ffdd99", "#11ff88"],
  9062: ["#bb9933", "#bb1155", "#bb4433", "#cc44aa", "#33ddcc"],
  9063: ["#553322", "#662222", "#77bb99", "#44dd11", "#9988cc"],
  9064: ["#aa4499", "#bbeedd", "#77eedd", "#88dddd", "#bb00aa"],
  9065: ["#3399aa", "#77aa55", "#44ccff", "#ff1166", "#0055bb"],
  9066: ["#aa3377", "#cc6600", "#ccbb33", "#55dd55", "#997755"],
  9067: ["#225500", "#99cc44", "#9933bb", "#55ee00", "#ff3355"],
  9068: ["#008844", "#447700", "#22bb00", "#4477cc", "#bb9977"],
  9069: ["#7755ee", "#ff88bb", "#ee3322", "#440077", "#44dd88"],
  9070: ["#224455", "#0077aa", "#ee3300", "#770077", "#cc11aa"],
  9071: ["#11dd33", "#88aa66", "#eecc88", "#11bb88", "#117744"],
  9072: ["#0022bb", "#996600", "#118811", "#44cc88", "#447799"],
  9073: ["#8855aa", "#44aaee", "#ff4444", "#1166ff", "#00ccbb"],
  9074: ["#cc5599", "#aaffff", "#7733ee", "#664411", "#1199dd"],
  9075: ["#22ccee", "#ff44ee", "#00cc55", "#117744", "#4499cc"],
  9076: ["#333300", "#220044", "#2255bb", "#ddaaaa", "#dd4422"],
  9077: ["#00bbcc", "#ee99dd", "#77ee44", "#77bb66", "#1177bb"],
  9078: ["#7733ee", "#22ffaa", "#8800ff", "#aabbff", "#0011dd"],
  9079: ["#cc8800", "#226611", "#22bbcc", "#77aabb", "#779988"],
  9080: ["#66ee77", "#bbff44", "#887755", "#1100bb", "#55ff88"],
  9081: ["#2233aa", "#883322", "#44bbaa", "#bb8888", "#1155cc"],
  9082: ["#448855", "#2222dd", "#66cccc", "#ccaa44", "#33aa33"],
  9083: ["#8877dd", "#334488", "#ee1111", "#220033", "#992200"],
  9084: ["#994455", "#bbaa77", "#330055", "#883377", "#dd22cc"],
  9085: ["#7733dd", "#441111", "#772277", "#886633", "#bbccaa"],
  9086: ["#5599dd", "#007722", "#88cccc", "#ddbbcc", "#77dd11"],
  9087: ["#ff33cc", "#440011", "#ccaaee", "#112255", "#2222ff"],
  9088: ["#cc99bb", "#bbaaff", "#77cc22", "#88ee33", "#4466bb"],
  9089: ["#4477dd", "#44ff33", "#77ffaa", "#2200ff", "#777700"],
  9090: ["#99bbcc", "#668811", "#998844", "#55ee55", "#007777"],
  9091: ["#443388", "#22bb22", "#3355ff", "#44ccee", "#777777"],
  9092: ["#ffff77", "#bbddbb", "#9988dd", "#00ee00", "#cc77bb"],
  9093: ["#66ffaa", "#eeddff", "#bbbbff", "#bbcc22", "#99ddcc"],
  9094: ["#005566", "#6677bb", "#118800", "#11ee11", "#883344"],
  9095: ["#dd1188", "#44dd66", "#88dd88", "#33cc00", "#ddff44"],
  9096: ["#00ccff", "#884477", "#eeee11", "#228822", "#119966"],
  9097: ["#eeee44", "#55ddcc", "#8855ee", "#887766", "#88cc22"],
  9098: ["#88cc33", "#22aa44", "#441122", "#bbdd22", "#ff5522"],
  9099: ["#1155ff", "#aabb77", "#661100", "#331133", "#551188"],
  9100: ["#000044", "#dd00bb", "#44ccee", "#33aadd", "#ddff88"],
  9101: ["#889988", "#44ff66", "#77bbff", "#2211ee", "#00dd00"],
  9102: ["#771155", "#bbdd88", "#bb7700", "#886611", "#5522aa"],
  9103: ["#66cc77", "#ff0044", "#11aa11", "#bb2266", "#77aa33"],
  9104: ["#dddd55", "#dd4400", "#3311bb", "#7777ff", "#0000ee"],
  9105: ["#222211", "#000088", "#44cc77", "#dd2211", "#ffee99"],
  9106: ["#8899ff", "#6633dd", "#aa6688", "#44ccaa", "#ff5577"],
  9107: ["#7744ee", "#bb11dd", "#112222", "#4411ff", "#99aaaa"],
  9108: ["#5555ff", "#770088", "#99ff00", "#6699aa", "#bbbb22"],
  9109: ["#884411", "#aabb66", "#ff2288", "#88ff88", "#00dddd"],
  9110: ["#228866", "#889977", "#44ee88", "#11ee11", "#bbeeaa"],
  9111: ["#aa3311", "#44ee99", "#008888", "#ee9911", "#ff0033"],
  9112: ["#997788", "#eedd22", "#dd4455", "#00ddff", "#33dd11"],
  9113: ["#880055", "#cc4400", "#44dddd", "#ffbbcc", "#778877"],
  9114: ["#223355", "#77bb66", "#aa88bb", "#33ccee", "#44aaaa"],
  9115: ["#330088", "#33cc66", "#cc66ff", "#ee9977", "#550077"],
  9116: ["#0011ee", "#88cc44", "#226633", "#88ff00", "#33ddff"],
  9117: ["#aaccff", "#dd55dd", "#9988ee", "#dd66aa", "#22aa00"],
  9118: ["#cc2266", "#aa7788", "#550055", "#5588dd", "#997700"],
  9119: ["#bb7700", "#6633dd", "#ee44ee", "#ddeeee", "#440099"],
  9120: ["#002200", "#eeffff", "#44ffcc", "#55bb55", "#22dd11"],
  9121: ["#ffaa00", "#8855bb", "#aa22ee", "#bb1111", "#662255"],
  9122: ["#995566", "#ee6600", "#993399", "#11bb77", "#8888ff"],
  9123: ["#44aa66", "#6699ee", "#11bbdd", "#00ddee", "#002277"],
  9124: ["#ddbbff", "#44aadd", "#ee8855", "#ffaa66", "#ee4499"],
  9125: ["#22ff33", "#dd6611", "#00ee99", "#003366", "#007799"],
  9126: ["#ee3333", "#dd5566", "#ff9999", "#229911", "#8811ee"],
  9127: ["#007700", "#55cc11", "#ffdd33", "#cc6600", "#bb7733"],
  9128: ["#aacc00", "#bbee77", "#33eeff", "#0022aa", "#33ff22"],
  9129: ["#9966cc", "#227711", "#22dd44", "#118811", "#0033ff"],
  9130: ["#445588", "#996644", "#778866", "#cc3322", "#5577aa"],
  9131: ["#cc3355", "#eeccdd", "#8811dd", "#88eecc", "#4444dd"],
  9132: ["#dd99ee", "#cc5544", "#11cc77", "#6633ee", "#77aa99"],
  9133: ["#eedd00", "#aa99ee", "#cc8833", "#33ff77", "#333333"],
  9134: ["#0088bb", "#884433", "#116688", "#77ee33", "#776622"],
  9135: ["#77cc22", "#00bb00", "#ee1100", "#11aaaa", "#ee66aa"],
  9136: ["#ffff55", "#22ee99", "#aa5522", "#88bb00", "#990011"],
  9137: ["#aa99aa", "#22eecc", "#00aaff", "#2200bb", "#4455cc"],
  9138: ["#ddbb33", "#eeaa00", "#33ee22", "#ccaa00", "#88ff55"],
  9139: ["#4444dd", "#88bbcc", "#cc22aa", "#88cc66", "#77ff99"],
  9140: ["#333377", "#55ffee", "#ee8877", "#555555", "#ddaadd"],
  9141: ["#003333", "#663333", "#6644dd", "#1144bb", "#442288"],
  9142: ["#7744bb", "#00eeaa", "#ffbbdd", "#66ccbb", "#55eeee"],
  9143: ["#cc8844", "#bb33ee", "#66bb44", "#6699bb", "#ff0000"],
  9144: ["#779944", "#22cc88", "#998844", "#dddd88", "#775599"],
  9145: ["#eeaa11", "#00aabb", "#11cc22", "#ee22aa", "#aaaabb"],
  9146: ["#bb6699", "#ccee99", "#dd44cc", "#550066", "#ddee11"],
  9147: ["#aa4455", "#006688", "#ff3366", "#662255", "#bbff11"],
  9148: ["#cc9999", "#cccc66", "#aa9911", "#ff2244", "#889933"],
  9149: ["#7744cc", "#aa00ff", "#eebb55", "#55ee00", "#7788dd"],
  9150: ["#663300", "#226677", "#443300", "#9911ee", "#2200ff"],
  9151: ["#ee88bb", "#113322", "#77ccbb", "#eeee88", "#ee88cc"],
  9152: ["#111199", "#666688", "#7766ee", "#eedd11", "#003322"],
  9153: ["#ee66dd", "#665577", "#bb7711", "#bb0077", "#ccddbb"],
  9154: ["#cc5522", "#bb22ee", "#cccc99", "#00cccc", "#5544dd"],
  9155: ["#1133cc", "#bb8855", "#33aa22", "#220011", "#dd11ee"],
  9156: ["#00ff99", "#ff3377", "#117799", "#330088", "#11ffdd"],
  9157: ["#eeee11", "#bb33aa", "#aa66cc", "#0022bb", "#335511"],
  9158: ["#007722", "#77ee55", "#11ff77", "#44cc55", "#3399bb"],
  9159: ["#ee6688", "#ddff11", "#55ddaa", "#110011", "#33bb55"],
  9160: ["#44aa11", "#00ee88", "#994444", "#aaccaa", "#11ddcc"],
  9161: ["#eeee11", "#444477", "#55cccc", "#33ee99", "#002299"],
  9162: ["#111199", "#55bbbb", "#aa6666", "#ccbb99", "#aa22ee"],
  9163: ["#cc66cc", "#667788", "#bb99bb", "#aaddaa", "#55eedd"],
  9164: ["#229977", "#22ff99", "#1100ff", "#0099aa", "#443322"],
  9165: ["#9911aa", "#eeddee", "#ddee55", "#33dd22", "#44ddff"],
  9166: ["#ff7788", "#ffff55", "#dd22ee", "#aa1144", "#bb8811"],
  9167: ["#66ffaa", "#2288bb", "#7777dd", "#5588dd", "#ff9977"],
  9168: ["#aaaa11", "#bbbb33", "#331188", "#11cc88", "#99ccff"],
  9169: ["#223355", "#bb6622", "#eeaabb", "#3300bb", "#99dd11"],
  9170: ["#bbddbb", "#117733", "#aabbaa", "#cc3311", "#119988"],
  9171: ["#dd99cc", "#ff4411", "#ddbbff", "#dd5522", "#99dd33"],
  9172: ["#11ee99", "#ee5555", "#5500ff", "#eeccff", "#3388dd"],
  9173: ["#335511", "#eebb11", "#ee6688", "#99ffbb", "#99ffbb"],
  9174: ["#bbaa66", "#eeee55", "#dd99bb", "#669922", "#bb8855"],
  9175: ["#22eeff", "#33ff77", "#55eecc", "#ff33bb", "#aa3388"],
  9176: ["#559911", "#774400", "#443344", "#ccff11", "#772277"],
  9177: ["#eebb88", "#ee9900", "#88bb88", "#33ddff", "#ffccdd"],
  9178: ["#bbee88", "#44dddd", "#eedd66", "#bbcc77", "#dddd88"],
  9179: ["#2211aa", "#ccaacc", "#00ee77", "#445588", "#8800ee"],
  9180: ["#ffee55", "#66aa00", "#bbbbee", "#aa22dd", "#cc88cc"],
  9181: ["#444455", "#661122", "#77aa22", "#bb11ff", "#ccff00"],
  9182: ["#cc6633", "#11ee55", "#11bb22", "#5500cc", "#331155"],
  9183: ["#2277dd", "#115566", "#ee3344", "#3388dd", "#66bb66"],
  9184: ["#8877aa", "#ccee11", "#2211ff", "#8888dd", "#5588ff"],
  9185: ["#9911ff", "#ff2266", "#99aa00", "#33dd66", "#774444"],
  9186: ["#22bbff", "#336622", "#775511", "#bbffee", "#88ee99"],
  9187: ["#66ee00", "#2222ee", "#bbee22", "#225566", "#ccccdd"],
  9188: ["#778811", "#ff44dd", "#4400cc", "#dd1155", "#aabb00"],
  9189: ["#cccc11", "#ee66bb", "#002299", "#44cc33", "#bbaa33"],
  9190: ["#ffddff", "#ff9988", "#66bb44", "#eeaabb", "#55ff00"],
  9191: ["#551188", "#33aa33", "#dd99bb", "#77ddee", "#8855aa"],
  9192: ["#cc3333", "#bb99aa", "#33ccee", "#dd4411", "#22eeee"],
  9193: ["#cc4433", "#772244", "#9933ff", "#447722", "#ddaaaa"],
  9194: ["#77ee22", "#2288cc", "#88ffee", "#229911", "#33dd88"],
  9195: ["#3366ff", "#6655dd", "#990088", "#cc7777", "#88bb11"],
  9196: ["#33aa00", "#aa99cc", "#4444ff", "#bb2200", "#221122"],
  9197: ["#5566aa", "#ddee00", "#669977", "#22cccc", "#11eeaa"],
  9198: ["#443355", "#2200cc", "#bbee99", "#dd1188", "#ff6622"],
  9199: ["#cc3311", "#556655", "#dddd44", "#553300", "#55aadd"],
  9200: ["#99ff44", "#aabbdd", "#bb1144", "#0066ee", "#aabbcc"],
  9201: ["#ddccbb", "#ee99dd", "#447711", "#004477", "#11aa99"],
  9202: ["#ee00dd", "#66cc99", "#331177", "#88ff55", "#aa33dd"],
  9203: ["#bb9955", "#0011ee", "#aabb44", "#0055ff", "#22cc66"],
  9204: ["#220000", "#446622", "#885533", "#00ccff", "#22ee33"],
  9205: ["#77ffaa", "#bbee00", "#bb3300", "#44cccc", "#eedd88"],
  9206: ["#aa6633", "#eebb88", "#7788cc", "#bb1166", "#bb1188"],
  9207: ["#dd11cc", "#dd4433", "#eecc33", "#88bbee", "#88aa33"],
  9208: ["#998877", "#115555", "#111122", "#cc2288", "#66bb00"],
  9209: ["#cccccc", "#226600", "#44ffbb", "#ffddee", "#cc00aa"],
  9210: ["#77ccbb", "#33ee44", "#775566", "#992299", "#3322aa"],
  9211: ["#881122", "#226677", "#774422", "#117722", "#33cc88"],
  9212: ["#339922", "#ff6622", "#aa00ee", "#ddaadd", "#ff44ee"],
  9213: ["#66cc66", "#33ccbb", "#11dd11", "#6633dd", "#556633"],
  9214: ["#ffff77", "#2233bb", "#99ffff", "#eeeecc", "#996655"],
  9215: ["#222255", "#ff77ff", "#994499", "#ff1100", "#664499"],
  9216: ["#22aa77", "#22ff33", "#ddee66", "#77ff88", "#11cc22"],
  9217: ["#aa33bb", "#33dd11", "#8866aa", "#77ee99", "#ffbbbb"],
  9218: ["#88ffaa", "#77bb33", "#22ee11", "#44dd66", "#ff7766"],
  9219: ["#ffffdd", "#998800", "#ee7799", "#bb1133", "#998811"],
  9220: ["#ddbbaa", "#dd0088", "#7755ff", "#114455", "#bb4433"],
  9221: ["#99cc88", "#33bb00", "#ddbb66", "#556600", "#ee3311"],
  9222: ["#ee6655", "#44bb11", "#99cc88", "#ffdd22", "#772277"],
  9223: ["#9933bb", "#bbaa88", "#338899", "#2222cc", "#ee9933"],
  9224: ["#0022ff", "#dd9977", "#ffaa00", "#1188ff", "#339955"],
  9225: ["#776633", "#992266", "#55ddcc", "#ffbbdd", "#44aadd"],
  9226: ["#220000", "#000011", "#0055dd", "#ff1199", "#cc2299"],
  9227: ["#cc66aa", "#551188", "#448899", "#887766", "#116655"],
  9228: ["#77ee55", "#bb9977", "#aa66bb", "#ff11aa", "#0033ff"],
  9229: ["#bb5500", "#bbee44", "#4433ff", "#dd2222", "#445511"],
  9230: ["#778866", "#ee77bb", "#550000", "#33bbcc", "#bb5544"],
  9231: ["#448866", "#5577cc", "#882233", "#66cc11", "#00cc00"],
  9232: ["#4477bb", "#ccdd44", "#6644aa", "#bbbbaa", "#ff7733"],
  9233: ["#55aa00", "#11aa33", "#2244ff", "#ee99ff", "#5555bb"],
  9234: ["#999933", "#443311", "#554477", "#552244", "#aa6666"],
  9235: ["#11cccc", "#eeff77", "#5533bb", "#226600", "#dd7722"],
  9236: ["#ddff99", "#33bb11", "#77dd44", "#ff1144", "#dd5544"],
  9237: ["#119933", "#ff3300", "#114411", "#666677", "#223333"],
  9238: ["#88aa99", "#998833", "#aabb22", "#dd6699", "#cc55dd"],
  9239: ["#993311", "#118800", "#008866", "#22cc22", "#bbdd55"],
  9240: ["#ffaaaa", "#888822", "#000088", "#118866", "#335511"],
  9241: ["#ee6633", "#bb5588", "#3399aa", "#dd2211", "#44ff66"],
  9242: ["#00ee66", "#665544", "#55aa99", "#ff11bb", "#22ee55"],
  9243: ["#ff9900", "#995522", "#88aa33", "#773300", "#115544"],
  9244: ["#22dd77", "#44dddd", "#225555", "#6677dd", "#bbffbb"],
  9245: ["#ee55bb", "#22bb44", "#ffcc44", "#443366", "#999955"],
  9246: ["#332233", "#ffff33", "#77dd55", "#cc6611", "#aa0044"],
  9247: ["#bbaa55", "#558866", "#dd9911", "#11ff33", "#443388"],
  9248: ["#33ffff", "#880033", "#774400", "#ee4455", "#3366ee"],
  9249: ["#446611", "#5566cc", "#116677", "#449933", "#4444aa"],
  9250: ["#ee0044", "#ddaa11", "#88cc22", "#0022bb", "#005599"],
  9251: ["#6633bb", "#aa77cc", "#00aadd", "#dd22dd", "#117711"],
  9252: ["#4400ff", "#886699", "#662255", "#44ccbb", "#ff5533"],
  9253: ["#22cc22", "#eeeedd", "#88dd55", "#009944", "#bb2222"],
  9254: ["#1122ee", "#ccee77", "#4455bb", "#5511cc", "#77ee77"],
  9255: ["#00eeaa", "#777733", "#996644", "#cccc00", "#6688ee"],
  9256: ["#55dd55", "#dd66cc", "#cc6688", "#339999", "#995577"],
  9257: ["#ee9977", "#ffee33", "#22ee66", "#ccdd77", "#886677"],
  9258: ["#002288", "#ddeeee", "#77ff22", "#dd66ff", "#665511"],
  9259: ["#aabb00", "#006677", "#556611", "#bb6633", "#66cc00"],
  9260: ["#cc1166", "#7799dd", "#332299", "#bb0088", "#bbaa77"],
  9261: ["#00cc88", "#88ee11", "#aa66bb", "#ff1100", "#884466"],
  9262: ["#dd77cc", "#554433", "#44cc22", "#4411cc", "#dd3355"],
  9263: ["#3355ff", "#dd0055", "#119988", "#dd6699", "#442200"],
  9264: ["#ffaaee", "#cc99ee", "#11ccdd", "#ee1133", "#bb9900"],
  9265: ["#cccc55", "#cc00dd", "#11ddbb", "#ffffee", "#aaaadd"],
  9266: ["#bb77aa", "#77aaff", "#7733aa", "#bb9977", "#669922"],
  9267: ["#ddee55", "#889977", "#cc99ee", "#88bbee", "#66ff11"],
  9268: ["#cc7722", "#443322", "#3344cc", "#ddee55", "#33cc22"],
  9269: ["#44cc44", "#33cc11", "#bbdd99", "#66aa55", "#66ee66"],
  9270: ["#009900", "#ddaa44", "#0033aa", "#7733ff", "#eeee55"],
  9271: ["#99ee77", "#7766aa", "#aa3311", "#eeee33", "#882299"],
  9272: ["#44ddaa", "#44aaee", "#aa8877", "#aa9933", "#5544aa"],
  9273: ["#887733", "#227722", "#cccc33", "#11bb88", "#dd1122"],
  9274: ["#ff3377", "#00ffee", "#dd6611", "#ffdd88", "#aabb00"],
  9275: ["#11ee66", "#cc1188", "#999911", "#4444ee", "#8855aa"],
  9276: ["#113388", "#bb5555", "#11bb44", "#bb2200", "#114477"],
  9277: ["#2200dd", "#335511", "#779933", "#ff0077", "#3344cc"],
  9278: ["#889944", "#ffbbcc", "#bbff77", "#bb5533", "#ff9977"],
  9279: ["#00bbee", "#6699aa", "#44ee44", "#99aa77", "#aabbdd"],
  9280: ["#cc55bb", "#2277bb", "#3388cc", "#ee22ff", "#bb11bb"],
  9281: ["#33bb33", "#bb3333", "#eeff77", "#33bbdd", "#ccbbbb"],
  9282: ["#2255aa", "#55cc88", "#ff8844", "#ee4466", "#ff1133"],
  9283: ["#0066ee", "#110044", "#3322cc", "#3366ff", "#ff3344"],
  9284: ["#00ff33", "#778844", "#cc2266", "#558822", "#9944aa"],
  9285: ["#8822bb", "#9933ee", "#005533", "#aa88aa", "#cc8811"],
  9286: ["#aacc99", "#ee55ee", "#77dd66", "#0000bb", "#003388"],
  9287: ["#cc88bb", "#aa2255", "#550066", "#774477", "#aa99ee"],
  9288: ["#bbaa22", "#000088", "#555577", "#11aa77", "#9922ff"],
  9289: ["#66bb22", "#445500", "#44dd88", "#6600aa", "#cc2211"],
  9290: ["#aaffbb", "#66ffbb", "#dd7733", "#000088", "#554488"],
  9291: ["#996666", "#997755", "#22ffdd", "#dd9966", "#ff11bb"],
  9292: ["#5566ee", "#005599", "#00cc00", "#00bb33", "#ff55ee"],
  9293: ["#dd3333", "#ddcc22", "#bb00ff", "#228800", "#bb5511"],
  9294: ["#eebb99", "#994433", "#3399dd", "#aa9933", "#ccee55"],
  9295: ["#228855", "#0066ee", "#ccbb66", "#5544ff", "#ee7711"],
  9296: ["#113377", "#bb1199", "#00ddaa", "#ddaa77", "#118866"],
  9297: ["#8811cc", "#ccee11", "#335500", "#dd1111", "#44ccaa"],
  9298: ["#ee3399", "#445577", "#77eeee", "#5533dd", "#66ee77"],
  9299: ["#99aa77", "#222277", "#cc7777", "#113399", "#0055cc"],
  9300: ["#bbdd33", "#99aa55", "#33ffcc", "#558888", "#4411aa"],
  9301: ["#004400", "#448833", "#666699", "#aaaaee", "#669911"],
  9302: ["#990000", "#77bbbb", "#55aa00", "#ff9944", "#770033"],
  9303: ["#88aa66", "#ee8899", "#4455dd", "#554455", "#8844ff"],
  9304: ["#ff00cc", "#006655", "#bbdd44", "#55ff22", "#33ee88"],
  9305: ["#bb44bb", "#eebb44", "#7766dd", "#ccdd66", "#eeccbb"],
  9306: ["#ccee33", "#aaaa66", "#660033", "#9955cc", "#33ff99"],
  9307: ["#889900", "#8866aa", "#000088", "#cc2299", "#aa8800"],
  9308: ["#bb22cc", "#88aacc", "#aaffbb", "#224444", "#4499ff"],
  9309: ["#772233", "#88cc88", "#33bb22", "#8877aa", "#22ee33"],
  9310: ["#ff22ff", "#ff55aa", "#5566ee", "#7788ff", "#aa5511"],
  9311: ["#884477", "#0066aa", "#aa0022", "#ee77bb", "#ff0077"],
  9312: ["#99bb11", "#cccc33", "#bbcc44", "#9999dd", "#11ff77"],
  9313: ["#44ff88", "#1144cc", "#bb0033", "#884455", "#aaddff"],
  9314: ["#4466aa", "#224422", "#660011", "#ffbbee", "#ee4411"],
  9315: ["#ff8811", "#11eedd", "#aa3333", "#55ee44", "#ee88dd"],
  9316: ["#bbdddd", "#aaaa33", "#554488", "#44bbbb", "#77cc22"],
  9317: ["#33eecc", "#ff1100", "#aa99bb", "#5500bb", "#33dd77"],
  9318: ["#5522bb", "#ee9966", "#55ff44", "#ff7722", "#6699ee"],
  9319: ["#994444", "#114411", "#ee8855", "#55bb22", "#cc99dd"],
  9320: ["#667733", "#000099", "#333333", "#553311", "#00aa22"],
  9321: ["#aaeecc", "#881100", "#1166aa", "#99bbbb", "#bb9955"],
  9322: ["#667744", "#558855", "#558877", "#5544ff", "#888844"],
  9323: ["#990033", "#66ee99", "#0022ff", "#990055", "#99bbcc"],
  9324: ["#997788", "#22ff33", "#339944", "#33ffcc", "#2299dd"],
  9325: ["#bb6600", "#0044aa", "#dd33bb", "#22eeaa", "#8855dd"],
  9326: ["#4444cc", "#ee22ee", "#88ffaa", "#556633", "#ff11ff"],
  9327: ["#88ccee", "#aa6644", "#771188", "#ff2299", "#ee2277"],
  9328: ["#551177", "#bbbb44", "#55ccdd", "#55aaee", "#88ccaa"],
  9329: ["#ffcc99", "#cc7744", "#ee2200", "#ccbbff", "#cc00ee"],
  9330: ["#553344", "#1122ee", "#66bbff", "#ccffcc", "#116633"],
  9331: ["#ffeedd", "#22ffaa", "#ee5522", "#aa99bb", "#55ee99"],
  9332: ["#22eecc", "#cc22ee", "#003388", "#ee55ff", "#ff00cc"],
  9333: ["#33bb88", "#9988bb", "#4455bb", "#886611", "#776644"],
  9334: ["#bb00cc", "#55dd66", "#778899", "#dd3300", "#bbccdd"],
  9335: ["#44bb11", "#8888ee", "#885555", "#332299", "#ffcc99"],
  9336: ["#00ccff", "#cc3344", "#aa5511", "#8833ee", "#003366"],
  9337: ["#88cc77", "#6677cc", "#4444ff", "#dd7766", "#ffaadd"],
  9338: ["#33aaee", "#0033ee", "#995555", "#77cccc", "#00ee33"],
  9339: ["#88ff33", "#7777dd", "#996688", "#eeff99", "#44ee55"],
  9340: ["#886622", "#6622cc", "#99ee33", "#44ee22", "#66ff55"],
  9341: ["#ffaa66", "#ccaaaa", "#0044aa", "#bb3399", "#bb33cc"],
  9342: ["#772233", "#aaeedd", "#00ffff", "#44ffcc", "#774477"],
  9343: ["#aa5566", "#555577", "#ffff00", "#888888", "#44ee11"],
  9344: ["#aa11ff", "#887744", "#88cc55", "#ee4499", "#ee2277"],
  9345: ["#556600", "#cc3344", "#119955", "#cc4488", "#aa33ff"],
  9346: ["#4400ee", "#226699", "#550055", "#eeffee", "#88bb66"],
  9347: ["#ff99ff", "#ccddaa", "#552288", "#66eecc", "#11eeff"],
  9348: ["#99dd22", "#223355", "#771144", "#ff77dd", "#883311"],
  9349: ["#777777", "#bb9944", "#66ccbb", "#55ffaa", "#cc22ff"],
  9350: ["#554466", "#002277", "#446655", "#0066bb", "#11ff33"],
  9351: ["#ffbb00", "#0055bb", "#ddeebb", "#ddbbbb", "#bb6600"],
  9352: ["#6633ee", "#11dd00", "#552244", "#1177ff", "#223322"],
  9353: ["#555566", "#444444", "#cc8855", "#66aabb", "#6655dd"],
  9354: ["#66ccaa", "#668866", "#eebb00", "#eecc55", "#9977aa"],
  9355: ["#eecc88", "#66aa11", "#22aabb", "#cc5555", "#ffeeff"],
  9356: ["#0000aa", "#aa6622", "#aa88aa", "#cc66dd", "#6644cc"],
  9357: ["#554400", "#ccbbcc", "#1199bb", "#cc99ee", "#cc6688"],
  9358: ["#00ff00", "#9911cc", "#55dd44", "#3311ee", "#ddbb88"],
  9359: ["#88aa00", "#cc6600", "#ffffcc", "#44aa55", "#99eedd"],
  9360: ["#aa1133", "#bbaabb", "#44cc22", "#55ff11", "#dd22aa"],
  9361: ["#997788", "#5588ff", "#9988ee", "#9955aa", "#666699"],
  9362: ["#bbccbb", "#ff7799", "#ee8800", "#77cc00", "#bb66aa"],
  9363: ["#ee7744", "#8844aa", "#eeffdd", "#226666", "#ee99dd"],
  9364: ["#992266", "#6688ff", "#882255", "#662299", "#663311"],
  9365: ["#ccffff", "#ddaabb", "#3366cc", "#ee6644", "#66cc11"],
  9366: ["#5533ff", "#44aa33", "#4466ee", "#0000aa", "#dd8822"],
  9367: ["#6622bb", "#002233", "#ee55dd", "#eebbff", "#447766"],
  9368: ["#aa11dd", "#7755ff", "#5566dd", "#6611ff", "#33eecc"],
  9369: ["#22cc44", "#008899", "#660055", "#22ffdd", "#55dd22"],
  9370: ["#aaccaa", "#779922", "#ee11cc", "#00cc55", "#ee88bb"],
  9371: ["#770077", "#aa2222", "#44cc22", "#3300ff", "#eecc33"],
  9372: ["#ff22aa", "#66ff66", "#bbff33", "#cc9955", "#ffdd44"],
  9373: ["#22eebb", "#111100", "#eeaaaa", "#66aaee", "#226611"],
  9374: ["#993388", "#5522dd", "#aa55bb", "#99ddcc", "#cccccc"],
  9375: ["#11ee66", "#22ee55", "#ff99ee", "#77eedd", "#33eeaa"],
  9376: ["#553344", "#77bb55", "#77ff22", "#cc1133", "#440044"],
  9377: ["#00aaff", "#005533", "#221111", "#cc8888", "#bb99aa"],
  9378: ["#ccdd55", "#330033", "#3311bb", "#9977aa", "#ccaa00"],
  9379: ["#551199", "#dd00cc", "#ff7744", "#2222bb", "#1177ee"],
  9380: ["#661166", "#eeaadd", "#bbbbbb", "#44bb33", "#dd0022"],
  9381: ["#77aa55", "#228888", "#dd99ff", "#5555dd", "#bbee66"],
  9382: ["#99dd11", "#ccee77", "#2222dd", "#993311", "#0022ee"],
  9383: ["#ffeeff", "#44bb55", "#66ddaa", "#77dd77", "#88aa44"],
  9384: ["#aacc22", "#557700", "#cc1188", "#44aacc", "#11cc11"],
  9385: ["#2266bb", "#44aa11", "#ee2200", "#55eedd", "#2200bb"],
  9386: ["#996688", "#339944", "#bb9922", "#8844aa", "#88dd77"],
  9387: ["#004400", "#7722ff", "#770088", "#11ff88", "#663311"],
  9388: ["#449900", "#ff8811", "#337722", "#ee1177", "#0011ee"],
  9389: ["#11ee99", "#9944cc", "#66dd99", "#991199", "#881166"],
  9390: ["#11ddcc", "#11cc44", "#00ee55", "#33ffff", "#44aa00"],
  9391: ["#aabb00", "#9999ff", "#ff1100", "#22ccdd", "#8844aa"],
  9392: ["#eeaaff", "#1122cc", "#992200", "#99bbff", "#005588"],
  9393: ["#dd5500", "#dd4466", "#883399", "#ccffff", "#99dd55"],
  9394: ["#cc6677", "#ddddff", "#77dd11", "#2266dd", "#334433"],
  9395: ["#226666", "#3388dd", "#5544bb", "#ee0088", "#994488"],
  9396: ["#55ee00", "#225533", "#eeddcc", "#55cc22", "#cc22dd"],
  9397: ["#77aa00", "#22dddd", "#331111", "#ff33cc", "#334488"],
  9398: ["#223388", "#aacc88", "#9977aa", "#99ee44", "#11ff55"],
  9399: ["#bb99bb", "#6699ee", "#007744", "#22ee55", "#77aa99"],
  9400: ["#aa1155", "#22bbcc", "#cc1155", "#9988ff", "#885599"],
  9401: ["#bb4422", "#005511", "#33ccdd", "#227777", "#ddaa00"],
  9402: ["#3322dd", "#aa66cc", "#11aacc", "#449922", "#ccbb44"],
  9403: ["#228811", "#77dd22", "#ee6600", "#ff77cc", "#bb6644"],
  9404: ["#11dd99", "#ddaa66", "#00bbaa", "#ee22dd", "#44ffcc"],
  9405: ["#556677", "#2288aa", "#dd1111", "#660033", "#ee22ee"],
  9406: ["#bb44aa", "#aaaaff", "#774477", "#4444ff", "#99dd11"],
  9407: ["#338866", "#888877", "#2200ff", "#663344", "#bb8899"],
  9408: ["#dd55aa", "#55aa99", "#bb11dd", "#773311", "#449922"],
  9409: ["#ddeeff", "#881100", "#6666cc", "#44bbbb", "#cc0044"],
  9410: ["#dddd88", "#226655", "#11aa99", "#883300", "#55cc00"],
  9411: ["#77bb99", "#0000ff", "#aacccc", "#9977aa", "#ee8844"],
  9412: ["#44aaaa", "#bb1199", "#99ee66", "#ddaa33", "#ff9955"],
  9413: ["#66ff11", "#339922", "#3322bb", "#0055ff", "#ff00cc"],
  9414: ["#668844", "#444488", "#669900", "#bb00ee", "#1144ee"],
  9415: ["#778811", "#665588", "#ee22cc", "#9922dd", "#aabbdd"],
  9416: ["#22ee66", "#9999ee", "#aaff00", "#aa99bb", "#ff5588"],
  9417: ["#229999", "#77aacc", "#114477", "#ccaaaa", "#ff6611"],
  9418: ["#997766", "#cc6666", "#88aa00", "#aa77cc", "#0022ff"],
  9419: ["#6600dd", "#00ffee", "#0077aa", "#bb11dd", "#ccff44"],
  9420: ["#9999ee", "#88bbcc", "#005566", "#ccbb44", "#22ccdd"],
  9421: ["#008888", "#aa7777", "#66ff99", "#99eedd", "#442277"],
  9422: ["#ee0077", "#dd0088", "#665500", "#00cc44", "#0066aa"],
  9423: ["#55ee55", "#88ccdd", "#ff88dd", "#665566", "#eeaaaa"],
  9424: ["#229922", "#bb2233", "#22bb77", "#444499", "#005511"],
  9425: ["#223333", "#446600", "#557733", "#77ff55", "#0000ff"],
  9426: ["#bbcc33", "#22dd66", "#334422", "#aaee55", "#88ee44"],
  9427: ["#446600", "#bb00cc", "#550044", "#443300", "#aa7777"],
  9428: ["#992200", "#bb4422", "#33cc33", "#2211aa", "#0088bb"],
  9429: ["#00ee22", "#cc7711", "#aadd55", "#992299", "#ffff22"],
  9430: ["#bbcc33", "#dddd55", "#55ee33", "#00cc66", "#aa0022"],
  9431: ["#ff9933", "#338800", "#556677", "#bbcc00", "#cc8855"],
  9432: ["#dd5511", "#ff88aa", "#551122", "#ff00aa", "#3311bb"],
  9433: ["#1100bb", "#00cc77", "#aa2266", "#889922", "#ee7755"],
  9434: ["#44ccbb", "#441133", "#eeaa00", "#77dd00", "#bb11dd"],
  9435: ["#ff5522", "#aabbbb", "#77aaff", "#ff22cc", "#ee00ff"],
  9436: ["#444400", "#77ee44", "#44bbdd", "#339988", "#ee66ee"],
  9437: ["#bbee44", "#4433bb", "#665533", "#00ee77", "#661177"],
  9438: ["#22ee55", "#223322", "#44cc77", "#cccc55", "#005533"],
  9439: ["#2299ee", "#3366cc", "#990033", "#ffdd33", "#885555"],
  9440: ["#99bbff", "#7733ee", "#887755", "#33dd88", "#777755"],
  9441: ["#4488ee", "#66aaee", "#990033", "#ddaaee", "#441133"],
  9442: ["#cc4444", "#ff0099", "#227733", "#4422bb", "#330055"],
  9443: ["#9999cc", "#88ddaa", "#88ddaa", "#ffaa11", "#66ddaa"],
  9444: ["#11aaee", "#99ffee", "#ffbb55", "#66bbdd", "#aaccbb"],
  9445: ["#dd6600", "#bbee55", "#dd0088", "#003399", "#9944ee"],
  9446: ["#666611", "#ffdd66", "#664466", "#ff5588", "#aaddbb"],
  9447: ["#33aa00", "#dd0000", "#444411", "#331111", "#0077ee"],
  9448: ["#ee11aa", "#cc8833", "#776622", "#33ff44", "#2299ff"],
  9449: ["#339922", "#773388", "#aa1188", "#44ff88", "#776666"],
  9450: ["#440077", "#88ffbb", "#2233ff", "#4455bb", "#667755"],
  9451: ["#0011aa", "#7711aa", "#440033", "#9922bb", "#bbee88"],
  9452: ["#aa9922", "#ff44ee", "#ddcc22", "#339977", "#ddbb99"],
  9453: ["#338844", "#885511", "#ff0055", "#226644", "#11eebb"],
  9454: ["#2266ff", "#66ee44", "#ccff77", "#3377cc", "#229944"],
  9455: ["#7799cc", "#110099", "#ee5577", "#ee3322", "#cc22ee"],
  9456: ["#aabb44", "#aa77dd", "#bb6622", "#22ee00", "#5522ff"],
  9457: ["#66ddcc", "#551100", "#88aa88", "#eeaacc", "#776622"],
  9458: ["#00eeee", "#8877ee", "#aa11cc", "#007744", "#aa3333"],
  9459: ["#55ee77", "#66ddbb", "#880099", "#0055cc", "#aabbaa"],
  9460: ["#88bb33", "#5544bb", "#cc66aa", "#770044", "#dd11ee"],
  9461: ["#77dd99", "#ff2200", "#ff0000", "#33ccee", "#ee99ee"],
  9462: ["#cc9911", "#6655bb", "#bbbbcc", "#ffdd99", "#4488ee"],
  9463: ["#bb33cc", "#cc3300", "#006666", "#ee7788", "#eecc77"],
  9464: ["#0055dd", "#774488", "#5577dd", "#ccbb44", "#3399cc"],
  9465: ["#cc44bb", "#8844ee", "#88ddbb", "#aa4466", "#3366bb"],
  9466: ["#88ff55", "#11cc99", "#cc44cc", "#cc55cc", "#2211ff"],
  9467: ["#77ee88", "#888822", "#2211cc", "#4477aa", "#66aadd"],
  9468: ["#883399", "#7700dd", "#ccdd44", "#4400aa", "#998822"],
  9469: ["#6655ff", "#ccdd88", "#ff0011", "#553322", "#aaff11"],
  9470: ["#88eeee", "#880066", "#aa44cc", "#2266aa", "#8899dd"],
  9471: ["#44ffee", "#cc88bb", "#8877aa", "#557733", "#33ccff"],
  9472: ["#115555", "#0088aa", "#225566", "#ff4455", "#887766"],
  9473: ["#ee3366", "#44bbee", "#aaddbb", "#aa7733", "#bb6600"],
  9474: ["#dd0022", "#227799", "#99bb77", "#66cc11", "#ee55bb"],
  9475: ["#884455", "#00bb00", "#aa7744", "#ff4499", "#3300bb"],
  9476: ["#33aa00", "#886666", "#0044ee", "#dddd88", "#cceedd"],
  9477: ["#8800ee", "#337733", "#bb0022", "#ff6644", "#229988"],
  9478: ["#ff7788", "#664499", "#44dd66", "#664422", "#9944ff"],
  9479: ["#ddeedd", "#44aa33", "#99cc33", "#ff5500", "#223322"],
  9480: ["#22ff66", "#889955", "#bb3355", "#990022", "#667700"],
  9481: ["#000011", "#33dd11", "#bbeedd", "#0044bb", "#ee7788"],
  9482: ["#00cc55", "#bb6699", "#886688", "#aa22dd", "#bb33bb"],
  9483: ["#ee7733", "#bb66bb", "#11ccdd", "#553311", "#bbddaa"],
  9484: ["#2266ff", "#22cc55", "#1199aa", "#bb8822", "#4488ff"],
  9485: ["#22bbee", "#ff7700", "#bb33aa", "#22ddff", "#88ff99"],
  9486: ["#ff8899", "#aa1111", "#77cc55", "#993344", "#2288ee"],
  9487: ["#bbbb88", "#dd7711", "#ff33cc", "#338822", "#99bb33"],
  9488: ["#ddbbff", "#22bb11", "#ddbb99", "#999911", "#00eebb"],
  9489: ["#ff2233", "#6666dd", "#662200", "#6633ff", "#995500"],
  9490: ["#ff9988", "#77ddcc", "#777700", "#ee00aa", "#bbdd33"],
  9491: ["#99ee44", "#11bb11", "#99aa66", "#ffcc00", "#ccbbcc"],
  9492: ["#5599dd", "#774455", "#dd8866", "#55ffdd", "#99ccee"],
  9493: ["#ff5577", "#88ccdd", "#aa2200", "#007788", "#5566aa"],
  9494: ["#443399", "#dd77ff", "#77ee66", "#eeeecc", "#664455"],
  9495: ["#882288", "#221188", "#2288bb", "#55aaaa", "#22aa44"],
  9496: ["#cc1177", "#eeff33", "#99ee66", "#cc8899", "#4422bb"],
  9497: ["#99cc99", "#002299", "#22eecc", "#44bb66", "#77aabb"],
  9498: ["#3388dd", "#ee88cc", "#0033bb", "#7799cc", "#eeaa44"],
  9499: ["#77ff99", "#9933ff", "#44ff66", "#441188", "#aa3333"],
  9500: ["#6600ee", "#88aa22", "#dd7733", "#882222", "#aaee99"],
  9501: ["#9955ff", "#33cc77", "#7755ff", "#44ff33", "#116699"],
  9502: ["#886688", "#000000", "#772288", "#ff22dd", "#886633"],
  9503: ["#00ddaa", "#3366bb", "#6633cc", "#5522aa", "#222222"],
  9504: ["#008888", "#1155aa", "#55ee33", "#22ccff", "#885555"],
  9505: ["#ff00ff", "#ff3388", "#cc3366", "#6633bb", "#bbaa99"],
  9506: ["#8833ff", "#8822bb", "#bb88ee", "#11ee44", "#2277cc"],
  9507: ["#bb66ff", "#2244ee", "#22ee77", "#aa44ff", "#332211"],
  9508: ["#88ff77", "#668877", "#cc6633", "#ee00ee", "#4400ff"],
  9509: ["#dd0066", "#004499", "#33ee77", "#88aa88", "#668866"],
  9510: ["#8899ee", "#dd4422", "#993399", "#994488", "#aa33ee"],
  9511: ["#11ee55", "#666677", "#77cc55", "#33dd11", "#bbaa55"],
  9512: ["#cccc77", "#227799", "#aaee77", "#22aa33", "#ccff77"],
  9513: ["#44dd22", "#223300", "#1199ff", "#dd1199", "#6655bb"],
  9514: ["#3399cc", "#888877", "#dddd22", "#22ffcc", "#ee77cc"],
  9515: ["#77aa99", "#dd3377", "#8888ff", "#441122", "#eebb22"],
  9516: ["#33ddbb", "#ff88ff", "#992211", "#663399", "#bbbbbb"],
  9517: ["#ee11cc", "#55cc33", "#55ee88", "#44ee22", "#8833ee"],
  9518: ["#883300", "#888877", "#889977", "#bbaa22", "#115599"],
  9519: ["#2266ff", "#5500dd", "#55ff88", "#221188", "#667700"],
  9520: ["#99ff22", "#ccff44", "#998866", "#66eeff", "#22dd77"],
  9521: ["#447711", "#aa0066", "#663355", "#ee44ff", "#99ffaa"],
  9522: ["#6622cc", "#ccdd77", "#1199ff", "#ee1177", "#ff2222"],
  9523: ["#4411bb", "#998877", "#99aaff", "#cc7711", "#77bbdd"],
  9524: ["#115511", "#88ddee", "#bbbbdd", "#77ff55", "#551177"],
  9525: ["#33dd44", "#ddccee", "#ff22dd", "#0033dd", "#446644"],
  9526: ["#77aa33", "#aa00aa", "#55aa66", "#ee33aa", "#33cc66"],
  9527: ["#226699", "#bb88aa", "#eeccdd", "#2288dd", "#cc77aa"],
  9528: ["#44bb77", "#dd3377", "#ffccdd", "#aaaa77", "#cc3388"],
  9529: ["#cc1166", "#44aa55", "#333322", "#11ee77", "#ff9988"],
  9530: ["#4400dd", "#885599", "#117788", "#446699", "#7733bb"],
  9531: ["#cc22dd", "#777744", "#cc55bb", "#55aabb", "#cc66bb"],
  9532: ["#999955", "#33ddaa", "#ee5555", "#1155cc", "#00ff22"],
  9533: ["#bb9966", "#881177", "#7700aa", "#ff0011", "#aa0077"],
  9534: ["#00ccaa", "#11bbcc", "#335511", "#5511dd", "#ddccee"],
  9535: ["#cceedd", "#aa1155", "#443300", "#77ee11", "#bb9933"],
  9536: ["#aa9999", "#ee44ff", "#559944", "#0000aa", "#ffee33"],
  9537: ["#ee4422", "#ffbb22", "#aa1155", "#eeddee", "#440099"],
  9538: ["#3355ee", "#990011", "#ee11ee", "#22ff00", "#3366bb"],
  9539: ["#442277", "#998844", "#bb4422", "#77eeee", "#ee5511"],
  9540: ["#55aacc", "#770044", "#ddee00", "#ccaa00", "#77ee55"],
  9541: ["#995511", "#ffbb00", "#66bb44", "#9900ee", "#ee8855"],
  9542: ["#ddbbbb", "#77eeee", "#11ff33", "#aa4488", "#ff5500"],
  9543: ["#aa77ee", "#dd1199", "#5599cc", "#ff55ee", "#cc55bb"],
  9544: ["#116611", "#110077", "#bb00cc", "#229900", "#008877"],
  9545: ["#ee0055", "#66aa44", "#aa8844", "#aa33dd", "#8844ff"],
  9546: ["#66ee22", "#66eebb", "#995511", "#ddee11", "#bbffcc"],
  9547: ["#33ee55", "#77ccee", "#7733bb", "#5511dd", "#66cccc"],
  9548: ["#99ff22", "#8811ff", "#44ccdd", "#bb66aa", "#55eebb"],
  9549: ["#bbccaa", "#eeeeff", "#99bbcc", "#441177", "#dd00aa"],
  9550: ["#88dd77", "#44ff33", "#11eeaa", "#119944", "#bb66dd"],
  9551: ["#aa7755", "#aa7788", "#ffcc99", "#007766", "#00ee33"],
  9552: ["#aa2266", "#aaaa00", "#ddcc99", "#33ff66", "#5533cc"],
  9553: ["#449999", "#cc3399", "#774422", "#110088", "#661133"],
  9554: ["#446633", "#cc00bb", "#5566cc", "#eeddbb", "#22bbbb"],
  9555: ["#dd7722", "#bbcc66", "#cc0077", "#997788", "#2299bb"],
  9556: ["#aa9900", "#88bb55", "#ccffbb", "#99cc00", "#116666"],
  9557: ["#1166aa", "#1177bb", "#ee0077", "#ffffdd", "#2266dd"],
  9558: ["#113377", "#cc66dd", "#22ff00", "#66ee55", "#99ee77"],
  9559: ["#44ff00", "#99aa77", "#335511", "#66cc00", "#cc7700"],
  9560: ["#3300cc", "#004466", "#111155", "#88eecc", "#ff88aa"],
  9561: ["#443300", "#33cccc", "#5577dd", "#ee22ff", "#33ddbb"],
  9562: ["#cc4455", "#00ffff", "#116677", "#00aa00", "#dd8822"],
  9563: ["#88bb44", "#77eeaa", "#1144bb", "#7700aa", "#118855"],
  9564: ["#3311bb", "#1166aa", "#cc44dd", "#eeee99", "#cc4455"],
  9565: ["#11ee11", "#1100aa", "#aa2233", "#993322", "#447755"],
  9566: ["#33bb88", "#bb5555", "#aa2222", "#aa22cc", "#66ddaa"],
  9567: ["#ee3311", "#775544", "#339944", "#77aadd", "#1199bb"],
  9568: ["#332200", "#ee6644", "#22ccaa", "#55ff88", "#eeff22"],
  9569: ["#448844", "#11ff66", "#bb00cc", "#44ee44", "#2255ee"],
  9570: ["#6677ff", "#5566ee", "#1122bb", "#557711", "#bb55ee"],
  9571: ["#55bb00", "#aa88ff", "#5511ee", "#bb9977", "#bb77bb"],
  9572: ["#aaaa55", "#dd4488", "#ee9988", "#0088bb", "#dd9966"],
  9573: ["#999911", "#ee1100", "#5522ff", "#66bbaa", "#cc7777"],
  9574: ["#001122", "#1155ee", "#3388dd", "#44eedd", "#3399bb"],
  9575: ["#662255", "#cccc00", "#ff9944", "#0099ff", "#dd5533"],
  9576: ["#0044aa", "#cc0077", "#cc9988", "#88ee11", "#00bb99"],
  9577: ["#dd4444", "#ccffff", "#11dd88", "#004444", "#55ddaa"],
  9578: ["#dd44bb", "#66bb00", "#33bb22", "#dd55bb", "#ee44bb"],
  9579: ["#66ddee", "#5588dd", "#00bbaa", "#00cc11", "#773300"],
  9580: ["#663322", "#0066ee", "#ddcc88", "#cc3311", "#ffbb99"],
  9581: ["#bb2299", "#11ee22", "#332233", "#ffaa88", "#cccc88"],
  9582: ["#bbaa77", "#dd00cc", "#9900ee", "#ee3399", "#ff11cc"],
  9583: ["#dd88dd", "#aa88ee", "#dd11ff", "#887788", "#223333"],
  9584: ["#ee2200", "#cc88dd", "#ffffcc", "#00ff44", "#882255"],
  9585: ["#44ee44", "#55dd11", "#773300", "#661199", "#eebb99"],
  9586: ["#4422aa", "#aa9977", "#0077dd", "#7722ee", "#772200"],
  9587: ["#2288ee", "#cc5511", "#44cc77", "#eecc11", "#ee6622"],
  9588: ["#ffaa55", "#ffbbdd", "#ee66cc", "#cc5533", "#22dd66"],
  9589: ["#ff55bb", "#aa33ee", "#6699dd", "#00ff11", "#005588"],
  9590: ["#8855ee", "#550077", "#aaffdd", "#ff3333", "#dd7766"],
  9591: ["#bb77ff", "#bb44cc", "#ee0000", "#ff5522", "#cc99aa"],
  9592: ["#bb1100", "#bbeedd", "#88bbff", "#00cc55", "#99aabb"],
  9593: ["#6666ee", "#663300", "#44ee99", "#000033", "#5500ff"],
  9594: ["#77ff00", "#cc77ee", "#449955", "#eecc99", "#001133"],
  9595: ["#5500ff", "#11ee88", "#5522dd", "#77aaee", "#55cc00"],
  9596: ["#224488", "#883300", "#44bb66", "#9977dd", "#55cc66"],
  9597: ["#005511", "#dd4433", "#bb0044", "#cc22ff", "#773355"],
  9598: ["#0088ee", "#cc00dd", "#4444ff", "#113366", "#8888bb"],
  9599: ["#778866", "#ff44ff", "#ee22cc", "#22ff44", "#66dd22"],
  9600: ["#ff2211", "#4444bb", "#2288cc", "#ee77dd", "#00ffaa"],
  9601: ["#ffee99", "#334477", "#66bb55", "#dd22ee", "#ddbb11"],
  9602: ["#4455aa", "#22ddee", "#dd9922", "#88bb44", "#33ee00"],
  9603: ["#aa1177", "#eeddaa", "#44bb66", "#779988", "#dd22bb"],
  9604: ["#5522ff", "#ee5544", "#33ff77", "#77aa33", "#dd3322"],
  9605: ["#dd9944", "#77aa33", "#aa2200", "#22ccee", "#7722bb"],
  9606: ["#55ff11", "#bbaaee", "#ee1177", "#00ee00", "#ee5599"],
  9607: ["#5555aa", "#ffee99", "#55ffaa", "#ee00ee", "#aaff44"],
  9608: ["#009944", "#bbdd66", "#11ff11", "#003344", "#ddeeaa"],
  9609: ["#dd3366", "#ddcc00", "#3322ff", "#77bbee", "#44aa44"],
  9610: ["#2222ee", "#9977dd", "#6600ff", "#cc8866", "#2299dd"],
  9611: ["#aa1177", "#ff22ff", "#6655dd", "#116644", "#ee7799"],
  9612: ["#338855", "#992288", "#ff6611", "#cc33dd", "#eeff66"],
  9613: ["#aa3377", "#007788", "#ee0066", "#3366aa", "#44ffff"],
  9614: ["#cc99aa", "#66eeff", "#4477dd", "#9933dd", "#446611"],
  9615: ["#66bb88", "#bb22ff", "#aa0077", "#33bbbb", "#117733"],
  9616: ["#776677", "#2299cc", "#997777", "#5533aa", "#448899"],
  9617: ["#ddee77", "#66ccee", "#336666", "#dd5599", "#113388"],
  9618: ["#446666", "#004466", "#aa3300", "#ff0000", "#551166"],
  9619: ["#dd4488", "#ddbb77", "#99dd55", "#77ffdd", "#221199"],
  9620: ["#ff6600", "#99ffff", "#bb9955", "#6677aa", "#aa00dd"],
  9621: ["#7799bb", "#554499", "#99ee00", "#ee2211", "#115500"],
  9622: ["#771188", "#001144", "#11ee99", "#eeffbb", "#777777"],
  9623: ["#662211", "#777799", "#771166", "#bb3355", "#bb4433"],
  9624: ["#bbddaa", "#4466bb", "#997766", "#00bb00", "#ee0000"],
  9625: ["#ee6600", "#1122cc", "#ee0000", "#77dd88", "#995599"],
  9626: ["#cc3355", "#884477", "#ff1133", "#dd44bb", "#bb22bb"],
  9627: ["#00ccee", "#442266", "#778800", "#bbaaee", "#22ff00"],
  9628: ["#667755", "#dd00dd", "#cc7755", "#332233", "#dd6666"],
  9629: ["#22ccee", "#663377", "#bbee44", "#77bbdd", "#bb33dd"],
  9630: ["#ccdd99", "#55ddcc", "#ff44cc", "#aa5599", "#aa11dd"],
  9631: ["#441111", "#008888", "#bb1133", "#00ddbb", "#445500"],
  9632: ["#991111", "#887744", "#221122", "#008877", "#771166"],
  9633: ["#117766", "#cc9900", "#33cccc", "#aaccff", "#bb4455"],
  9634: ["#dd9944", "#aa8833", "#aa00dd", "#9999dd", "#99ff44"],
  9635: ["#88bb66", "#11bb00", "#556677", "#556666", "#11ee88"],
  9636: ["#7799ff", "#22eebb", "#ddeebb", "#bb5533", "#55aaee"],
  9637: ["#11aaaa", "#995588", "#aa6644", "#aa2233", "#3322ee"],
  9638: ["#aa88ee", "#22bbaa", "#883311", "#5544aa", "#cc33cc"],
  9639: ["#aaffcc", "#3366bb", "#dd8800", "#55bbdd", "#dd3333"],
  9640: ["#bb99aa", "#772200", "#88ff11", "#1144cc", "#9999ff"],
  9641: ["#11dd88", "#114422", "#66dd44", "#556600", "#552255"],
  9642: ["#ddeecc", "#22ff77", "#998877", "#ddaacc", "#880088"],
  9643: ["#aaddbb", "#339966", "#ddbbcc", "#88cc00", "#00bb99"],
  9644: ["#77ee11", "#aa6600", "#dd0044", "#ee6699", "#bbffcc"],
  9645: ["#4477cc", "#223344", "#2222cc", "#33dd11", "#662233"],
  9646: ["#44ee00", "#ff1199", "#bb77aa", "#cc99cc", "#cc33ee"],
  9647: ["#ee8822", "#aa66ee", "#aa2277", "#2266cc", "#33aa88"],
  9648: ["#3322dd", "#44ddcc", "#bbaa44", "#443322", "#555566"],
  9649: ["#bb99ee", "#ddaaee", "#dd4422", "#553377", "#779999"],
  9650: ["#2233ee", "#0011ee", "#77eedd", "#0099aa", "#ccddbb"],
  9651: ["#11aaee", "#668844", "#cc1111", "#dd8866", "#885500"],
  9652: ["#00ee88", "#11eebb", "#006677", "#ee6622", "#ccffcc"],
  9653: ["#0000ee", "#55ffaa", "#dd9933", "#4411aa", "#5511bb"],
  9654: ["#bb4455", "#449900", "#5555cc", "#11cc66", "#dd1100"],
  9655: ["#333344", "#aadd33", "#9955dd", "#5533ee", "#99cc88"],
  9656: ["#88bbee", "#aaaadd", "#7733ff", "#1100bb", "#bb6633"],
  9657: ["#aa2277", "#ee6688", "#882266", "#99ee00", "#773377"],
  9658: ["#4477ee", "#bb2277", "#ff3344", "#ffaaaa", "#669977"],
  9659: ["#116699", "#cceeaa", "#ff0044", "#aabb77", "#22aaff"],
  9660: ["#449977", "#997700", "#448844", "#ff0088", "#4422ee"],
  9661: ["#dd7799", "#ee9955", "#ddccbb", "#bbaaff", "#ddbb22"],
  9662: ["#8822ee", "#99ddff", "#663366", "#bbeeee", "#338877"],
  9663: ["#88bb55", "#9977ee", "#bb9999", "#778866", "#aa8888"],
  9664: ["#3300ee", "#4433cc", "#7766ff", "#448800", "#66ff66"],
  9665: ["#cc2288", "#99dd66", "#002244", "#4411bb", "#220088"],
  9666: ["#3322bb", "#bb6600", "#77dd00", "#1199cc", "#ddaadd"],
  9667: ["#dd4422", "#5577ee", "#6644bb", "#00aa44", "#44bbcc"],
  9668: ["#ffff99", "#33ee33", "#aa44dd", "#44ffaa", "#2244cc"],
  9669: ["#ff11dd", "#882211", "#77dd66", "#228800", "#cc88bb"],
  9670: ["#77dd44", "#55ff33", "#225522", "#995588", "#669988"],
  9671: ["#66bbff", "#333366", "#331188", "#4400ff", "#cc77dd"],
  9672: ["#334433", "#99aa22", "#ee66dd", "#771155", "#99ccaa"],
  9673: ["#881144", "#ee7722", "#aa4455", "#333311", "#11ffbb"],
  9674: ["#44ccee", "#33cc33", "#66ee11", "#44ff00", "#dd4400"],
  9675: ["#eeaa00", "#ddbb77", "#aa33ff", "#33cc44", "#5533ff"],
  9676: ["#22dd44", "#555511", "#ee1111", "#eebbee", "#dd3311"],
  9677: ["#669955", "#6611aa", "#443399", "#440033", "#00cc44"],
  9678: ["#668833", "#8844cc", "#1177cc", "#55ffee", "#005511"],
  9679: ["#00ccaa", "#2200aa", "#aa3333", "#aacc11", "#00eecc"],
  9680: ["#22aaff", "#3355bb", "#bbee44", "#669933", "#5500cc"],
  9681: ["#884477", "#00ddaa", "#ffaa88", "#ddccee", "#99ddbb"],
  9682: ["#cc44ff", "#bbbb44", "#bb9955", "#007799", "#558866"],
  9683: ["#002222", "#0000ee", "#883333", "#bbbbff", "#ddbbff"],
  9684: ["#dd00ee", "#bbff77", "#3300cc", "#ff1155", "#eeff66"],
  9685: ["#aaaa44", "#ddaa55", "#553377", "#8811aa", "#dd9933"],
  9686: ["#11ee44", "#88bb99", "#ee88aa", "#119955", "#7744aa"],
  9687: ["#007700", "#44dd66", "#22dd77", "#cc0099", "#6633cc"],
  9688: ["#44dd33", "#44aaff", "#aa2277", "#003322", "#119999"],
  9689: ["#aacccc", "#bb11cc", "#777700", "#dd99bb", "#22ee66"],
  9690: ["#441122", "#2200cc", "#8888ee", "#00dd88", "#33ee77"],
  9691: ["#3399aa", "#007788", "#eebb11", "#ccffcc", "#ff00ee"],
  9692: ["#5599ff", "#cc33dd", "#22ee33", "#66ee55", "#664499"],
  9693: ["#bbddbb", "#556633", "#dd11ee", "#88eeaa", "#cc33bb"],
  9694: ["#ff55ee", "#ff3333", "#112255", "#aaee88", "#bb0088"],
  9695: ["#339988", "#ccffff", "#55dd99", "#8822aa", "#eebb11"],
  9696: ["#dd55ee", "#55dddd", "#330044", "#2200bb", "#dd4444"],
  9697: ["#88dd99", "#eeaacc", "#336699", "#11eeff", "#990022"],
  9698: ["#bb88ff", "#ccaa77", "#8899ee", "#ee3377", "#66ccff"],
  9699: ["#ee8866", "#88bb44", "#44dd66", "#ee77bb", "#55ccbb"],
  9700: ["#cc33cc", "#cc6622", "#22ff44", "#9922bb", "#776688"],
  9701: ["#ff6644", "#22bb33", "#665566", "#44ff33", "#bbbb22"],
  9702: ["#8877ee", "#ccee66", "#995599", "#555544", "#bb4477"],
  9703: ["#11bbcc", "#00aa22", "#5511ee", "#ff1144", "#668800"],
  9704: ["#00ffdd", "#774466", "#336633", "#dd7722", "#115566"],
  9705: ["#110066", "#00cc22", "#cc2244", "#eecc77", "#eeccee"],
  9706: ["#551133", "#ffcc44", "#7788ff", "#338811", "#11ff99"],
  9707: ["#ff7722", "#44bbaa", "#ddcc77", "#660000", "#aabb33"],
  9708: ["#22cc55", "#ee55bb", "#779955", "#aabb44", "#444499"],
  9709: ["#55dd88", "#6677bb", "#aabbdd", "#44aadd", "#557777"],
  9710: ["#ffbb44", "#ff4444", "#ddccdd", "#dd9966", "#7733ff"],
  9711: ["#4455bb", "#9999dd", "#33ee99", "#dddd22", "#bb55aa"],
  9712: ["#7777dd", "#66ddee", "#bb55ee", "#bbccaa", "#7788aa"],
  9713: ["#331100", "#6600aa", "#aacc33", "#2244dd", "#0088cc"],
  9714: ["#77aa00", "#55ffee", "#881166", "#bb5511", "#2211aa"],
  9715: ["#778877", "#88aabb", "#00ff33", "#22ff11", "#997777"],
  9716: ["#22ee11", "#884466", "#cc33bb", "#bb22cc", "#335544"],
  9717: ["#77ff44", "#eebb99", "#eecc11", "#aa55aa", "#1133dd"],
  9718: ["#66ee00", "#88bb22", "#ee77aa", "#6666aa", "#66eeaa"],
  9719: ["#0099cc", "#dd11aa", "#22ffcc", "#00ee99", "#ff88dd"],
  9720: ["#ee1188", "#ee33ff", "#ff1133", "#aa8811", "#887733"],
  9721: ["#9977bb", "#bb11ff", "#111144", "#cc22aa", "#999900"],
  9722: ["#881122", "#bbccff", "#ffee99", "#5522ff", "#bbffbb"],
  9723: ["#ee2244", "#88ee22", "#dd3322", "#cc8844", "#dd6688"],
  9724: ["#4488dd", "#dd4411", "#000055", "#ffcc22", "#2244dd"],
  9725: ["#44aabb", "#22bb22", "#aa22dd", "#554411", "#8822dd"],
  9726: ["#221122", "#997755", "#555511", "#ddbb33", "#bb8844"],
  9727: ["#cc4455", "#ffaa00", "#ddee55", "#cc9900", "#00eedd"],
  9728: ["#001100", "#55cc99", "#992200", "#aa99bb", "#ff44dd"],
  9729: ["#1122cc", "#330044", "#66ff99", "#bbffff", "#884444"],
  9730: ["#11ee77", "#ff7766", "#669933", "#339911", "#bbbb99"],
  9731: ["#cc99ee", "#114466", "#ccff22", "#44aa22", "#dd6677"],
  9732: ["#88dd22", "#dd3300", "#6644aa", "#ff0066", "#002244"],
  9733: ["#cc4455", "#55bbff", "#2244ff", "#22dd00", "#33eeee"],
  9734: ["#111133", "#aa5511", "#00bbff", "#22cc00", "#224477"],
  9735: ["#0000cc", "#3355ee", "#228888", "#77bb33", "#22bb11"],
  9736: ["#442255", "#99bb77", "#991133", "#ee55ee", "#448822"],
  9737: ["#eeeedd", "#ff8844", "#111111", "#bbdd66", "#444422"],
  9738: ["#99dd11", "#448855", "#22bb99", "#994400", "#4400aa"],
  9739: ["#ff1111", "#009911", "#eeeebb", "#ffeedd", "#113322"],
  9740: ["#8899dd", "#443355", "#228800", "#2288dd", "#cc0099"],
  9741: ["#aa3388", "#3300dd", "#ddccdd", "#774444", "#bb44ee"],
  9742: ["#6688cc", "#ee3311", "#55dd88", "#4411cc", "#003300"],
  9743: ["#aaaa88", "#44eeee", "#222233", "#0099ee", "#bb8822"],
  9744: ["#aadddd", "#bb2288", "#0099dd", "#aadd77", "#0099aa"],
  9745: ["#994400", "#66bbbb", "#88ee44", "#446611", "#555599"],
  9746: ["#00aabb", "#112288", "#ff8833", "#2288dd", "#66cc88"],
  9747: ["#4466ff", "#bb99ee", "#9988aa", "#cc1177", "#55eecc"],
  9748: ["#dd99aa", "#ddee00", "#3377aa", "#77eecc", "#44ff22"],
  9749: ["#99dd99", "#88dd66", "#550099", "#993399", "#ff22bb"],
  9750: ["#229966", "#88dd77", "#11ffff", "#ee5555", "#66eedd"],
  9751: ["#6688bb", "#664411", "#22bb11", "#ee8899", "#223399"],
  9752: ["#77bbee", "#3322ee", "#3311dd", "#221122", "#00ccff"],
  9753: ["#dd77ff", "#77aa33", "#997766", "#66ddaa", "#77eedd"],
  9754: ["#117700", "#44ccaa", "#11ffaa", "#554400", "#11cccc"],
  9755: ["#883399", "#dd3322", "#ee9966", "#660000", "#665599"],
  9756: ["#774411", "#44eeee", "#9900cc", "#4444cc", "#11dd88"],
  9757: ["#bb22ee", "#7733bb", "#11ff44", "#992222", "#996611"],
  9758: ["#994488", "#77bb99", "#dd11ee", "#990033", "#cccc33"],
  9759: ["#ffbbbb", "#ddccaa", "#66ccbb", "#66bbcc", "#22bbaa"],
  9760: ["#222244", "#66ff66", "#ff3366", "#aa6666", "#88ee44"],
  9761: ["#11aaff", "#88bbaa", "#66cc99", "#2255ee", "#11aa99"],
  9762: ["#667700", "#cc3300", "#6600ee", "#110022", "#000099"],
  9763: ["#aa22ee", "#3333dd", "#cc9999", "#99ccdd", "#bb00cc"],
  9764: ["#22dd88", "#3311ff", "#ccff88", "#44ccaa", "#3311aa"],
  9765: ["#dd9988", "#668866", "#22eebb", "#334466", "#bb99aa"],
  9766: ["#66dd44", "#aaaa66", "#225577", "#dddd11", "#66ccaa"],
  9767: ["#5588ee", "#99dd88", "#332200", "#44ffbb", "#77cc00"],
  9768: ["#22bbbb", "#992255", "#006655", "#779999", "#22cccc"],
  9769: ["#cccc11", "#1177ee", "#333344", "#3344ee", "#aa00ff"],
  9770: ["#ff44bb", "#bb4466", "#dd6666", "#115599", "#aa8877"],
  9771: ["#003377", "#aa8899", "#aa1133", "#aaff33", "#3333cc"],
  9772: ["#332222", "#bb7711", "#77bbff", "#ccccaa", "#eecc77"],
  9773: ["#22cc44", "#1133ff", "#8877dd", "#cccc77", "#446699"],
  9774: ["#ffaa66", "#885544", "#772277", "#eecc44", "#884499"],
  9775: ["#88aa44", "#996644", "#332288", "#ee8822", "#44aa66"],
  9776: ["#111144", "#224411", "#cccc44", "#cceedd", "#bb1155"],
  9777: ["#6699aa", "#bb00ff", "#997744", "#ee1199", "#88bbee"],
  9778: ["#ee88ee", "#aadd77", "#77cc88", "#ff3355", "#770088"],
  9779: ["#aadd11", "#cc99dd", "#00bbdd", "#553399", "#ff66cc"],
  9780: ["#336677", "#9944aa", "#88ccaa", "#557766", "#00ffee"],
  9781: ["#aadd33", "#ff33bb", "#007733", "#bb55cc", "#99ee11"],
  9782: ["#dd99ff", "#dd00dd", "#000088", "#00ff88", "#55ee66"],
  9783: ["#bb6666", "#448866", "#aa55aa", "#dd44dd", "#44ee11"],
  9784: ["#cc0022", "#00aa77", "#2266dd", "#3333dd", "#ff2255"],
  9785: ["#dd1133", "#447733", "#557777", "#11dddd", "#5544bb"],
  9786: ["#99aa55", "#dd4433", "#55aa55", "#dd22ff", "#bb3333"],
  9787: ["#00cccc", "#330088", "#aa44cc", "#aa7799", "#662255"],
  9788: ["#33bb88", "#dd7711", "#1188dd", "#0088ee", "#778844"],
  9789: ["#aacc44", "#77bbdd", "#223322", "#006677", "#aaee99"],
  9790: ["#bbee44", "#ffcc11", "#77bb11", "#555577", "#55bb88"],
  9791: ["#991133", "#112222", "#7777ee", "#55bb44", "#886644"],
  9792: ["#eeff88", "#442200", "#ff8833", "#778811", "#0000aa"],
  9793: ["#220066", "#ddee99", "#cc66cc", "#99ccee", "#1122bb"],
  9794: ["#00eedd", "#cc66bb", "#cc3388", "#221100", "#bb44bb"],
  9795: ["#bb3311", "#009977", "#33aaee", "#77ee66", "#eeaa00"],
  9796: ["#44bb00", "#55dd33", "#88ddff", "#2299cc", "#22ff33"],
  9797: ["#ccaa88", "#7755dd", "#ee5566", "#887788", "#22bb66"],
  9798: ["#ee55bb", "#aaee55", "#6600bb", "#114411", "#0011aa"],
  9799: ["#99bb44", "#77eedd", "#55dd33", "#33eebb", "#cc4455"],
  9800: ["#0044ff", "#0044dd", "#cc4499", "#eeaa44", "#cc8877"],
  9801: ["#dd77aa", "#115544", "#0055aa", "#00dd66", "#3377ff"],
  9802: ["#77ff66", "#1166ff", "#550022", "#ee99ff", "#116622"],
  9803: ["#006688", "#ffff88", "#22ff44", "#dd8899", "#110044"],
  9804: ["#557700", "#88eeee", "#aa33bb", "#33aaee", "#eeccdd"],
  9805: ["#bb5577", "#3311aa", "#bb8822", "#2299ee", "#77ddff"],
  9806: ["#33ff99", "#aa55dd", "#997744", "#bbee33", "#66ffcc"],
  9807: ["#2266ff", "#ff2244", "#008877", "#00bb22", "#88bbaa"],
  9808: ["#1111cc", "#eeff44", "#2255cc", "#cc4488", "#bb0066"],
  9809: ["#eeff33", "#663344", "#33aa00", "#33cc33", "#22eeaa"],
  9810: ["#77ffbb", "#11aa66", "#aa00ee", "#77cccc", "#22bb44"],
  9811: ["#4411ff", "#55bb88", "#666666", "#111199", "#bbccee"],
  9812: ["#77bbcc", "#dd8855", "#ccff55", "#7777dd", "#bb7788"],
  9813: ["#33ff66", "#227799", "#eeee44", "#cc2222", "#22eebb"],
  9814: ["#aaeeff", "#ee44cc", "#eeee22", "#220077", "#99ff33"],
  9815: ["#443322", "#554422", "#ee5533", "#224400", "#444466"],
  9816: ["#226666", "#33bbbb", "#11ffdd", "#551133", "#6622cc"],
  9817: ["#993355", "#cc2244", "#bbff44", "#996600", "#111188"],
  9818: ["#4488ee", "#338877", "#eeee11", "#ccee99", "#0099ff"],
  9819: ["#55aacc", "#ddeeaa", "#11cccc", "#55ff77", "#99bbee"],
  9820: ["#66dd77", "#99aa55", "#0077bb", "#ddcc66", "#99bb77"],
  9821: ["#8855bb", "#88ddff", "#9977bb", "#664455", "#114422"],
  9822: ["#000044", "#ffcc88", "#773355", "#224488", "#5533ff"],
  9823: ["#770099", "#cc44dd", "#ee44ff", "#33bb22", "#778899"],
  9824: ["#cc3322", "#dd55ee", "#00aabb", "#cc88cc", "#33bb66"],
  9825: ["#668833", "#00bbff", "#aa6699", "#ee4477", "#557788"],
  9826: ["#996644", "#aa8844", "#886688", "#dd8800", "#cc6677"],
  9827: ["#aacc77", "#dd8844", "#994433", "#5544ee", "#aabb66"],
  9828: ["#882255", "#337733", "#664411", "#331111", "#55ddbb"],
  9829: ["#eeaa11", "#bb9977", "#ddbbaa", "#22ddaa", "#ff1177"],
  9830: ["#33ddff", "#8866cc", "#44cc11", "#bb7722", "#ee00ff"],
  9831: ["#226699", "#11dd66", "#116644", "#1155bb", "#ff0000"],
  9832: ["#1188ee", "#ccdddd", "#99cc22", "#eecc11", "#9977cc"],
  9833: ["#ddffff", "#ff2288", "#77ee11", "#773399", "#22dd99"],
  9834: ["#7700cc", "#44dd33", "#ee4433", "#ffffdd", "#5588bb"],
  9835: ["#bbeeee", "#99eeee", "#1199ee", "#336699", "#77dd11"],
  9836: ["#00dd11", "#118855", "#9955aa", "#662200", "#dd99ee"],
  9837: ["#66ee11", "#88aaaa", "#66cc44", "#dd00ff", "#883355"],
  9838: ["#772266", "#11cc11", "#442266", "#441155", "#99eedd"],
  9839: ["#2233cc", "#4488bb", "#669933", "#ee5588", "#224466"],
  9840: ["#772299", "#bbeedd", "#eeff44", "#33ff88", "#884455"],
  9841: ["#440022", "#008899", "#cc88dd", "#dd66dd", "#6622ff"],
  9842: ["#ffcc44", "#11ffdd", "#2211cc", "#ccdd22", "#11ddcc"],
  9843: ["#ddddbb", "#eecc66", "#ee88bb", "#6622ee", "#112222"],
  9844: ["#00dd77", "#ee4477", "#227700", "#7788bb", "#1111ff"],
  9845: ["#ddccaa", "#bbeecc", "#770044", "#7799bb", "#553377"],
  9846: ["#ddff88", "#2211ee", "#7711ee", "#440066", "#ff6688"],
  9847: ["#ff22cc", "#226666", "#dd3300", "#882288", "#11ffaa"],
  9848: ["#cceeee", "#88bbdd", "#cc1166", "#11bb55", "#5588bb"],
  9849: ["#00aa55", "#77bb00", "#ddcccc", "#aadd22", "#555522"],
  9850: ["#ffaa77", "#884422", "#77ee22", "#dd66ff", "#bb11ff"],
  9851: ["#447733", "#bb1177", "#002222", "#ff7788", "#552244"],
  9852: ["#bbff33", "#99ffbb", "#884488", "#ee3322", "#009966"],
  9853: ["#99aacc", "#dd1111", "#22bb00", "#ff2299", "#004433"],
  9854: ["#77aaee", "#ee11dd", "#11eecc", "#558855", "#ff9977"],
  9855: ["#bbccee", "#33bb55", "#6633dd", "#bb3355", "#4422ff"],
  9856: ["#4422ff", "#88dd33", "#ee0099", "#119933", "#bb44dd"],
  9857: ["#ee11bb", "#11cc88", "#bb55cc", "#cc11bb", "#77ee22"],
  9858: ["#ddddff", "#444477", "#66dd55", "#220055", "#cc99bb"],
  9859: ["#883322", "#ddee88", "#cc11cc", "#eebbee", "#aa44dd"],
  9860: ["#1144ee", "#55aabb", "#aa66dd", "#770022", "#997744"],
  9861: ["#66aadd", "#eeee88", "#886699", "#226688", "#2266aa"],
  9862: ["#777711", "#2244ee", "#442222", "#881144", "#554455"],
  9863: ["#2277cc", "#00eeff", "#ccaa33", "#aa9988", "#669933"],
  9864: ["#ee99ee", "#11aaff", "#dd44dd", "#0099dd", "#00ddaa"],
  9865: ["#ff5577", "#aa77cc", "#00aa44", "#ff7711", "#331100"],
  9866: ["#771188", "#33aa44", "#0022ff", "#88ff44", "#aa0088"],
  9867: ["#55dd77", "#ee7733", "#113399", "#99cc66", "#ff55bb"],
  9868: ["#bb1122", "#330066", "#883377", "#3322ee", "#aa7766"],
  9869: ["#22cccc", "#dd55aa", "#889966", "#aabb11", "#11ee00"],
  9870: ["#88bbbb", "#3333cc", "#55ffff", "#99bbdd", "#55cc55"],
  9871: ["#bb2211", "#880055", "#445555", "#ee88aa", "#0077ee"],
  9872: ["#bb7755", "#ff00ee", "#bb66ee", "#dd88ee", "#00aa00"],
  9873: ["#44ffdd", "#0044cc", "#55cc99", "#88eeff", "#55dd88"],
  9874: ["#ee4477", "#cc5566", "#77cccc", "#ee4400", "#77eeee"],
  9875: ["#ee66bb", "#dd2244", "#005522", "#3333aa", "#99bbbb"],
  9876: ["#997733", "#77bbee", "#3355aa", "#775544", "#dd1122"],
  9877: ["#bb3344", "#77aa22", "#0088aa", "#ddcc55", "#77bb44"],
  9878: ["#1166cc", "#ddffcc", "#bb4422", "#002211", "#33aabb"],
  9879: ["#bb5577", "#dd3355", "#ccddaa", "#ff7788", "#ff22ff"],
  9880: ["#0044dd", "#552244", "#aa5555", "#55eedd", "#cc1122"],
  9881: ["#3355dd", "#dd2288", "#558800", "#ddccff", "#dd8811"],
  9882: ["#008811", "#88dd00", "#6677cc", "#6611ee", "#9955bb"],
  9883: ["#1155ee", "#aabb22", "#775511", "#ddccdd", "#bbee33"],
  9884: ["#777722", "#bb00bb", "#dd77cc", "#ff5511", "#22bb66"],
  9885: ["#330055", "#005588", "#55cc66", "#ffbbbb", "#66bb00"],
  9886: ["#336644", "#999977", "#008800", "#66dd99", "#44ddff"],
  9887: ["#ffee77", "#bbaaee", "#22cc55", "#11ffdd", "#669966"],
  9888: ["#5511ee", "#990066", "#55ee22", "#cc8877", "#eeff33"],
  9889: ["#8888dd", "#224477", "#cc6666", "#11cc00", "#bb4477"],
  9890: ["#001122", "#558877", "#55dd00", "#aa22dd", "#ffddcc"],
  9891: ["#ddaa77", "#11ddbb", "#3311cc", "#66aaaa", "#aadd77"],
  9892: ["#663399", "#221155", "#550066", "#11dddd", "#ff33ff"],
  9893: ["#ccaa99", "#bb6644", "#66aa88", "#552211", "#338855"],
  9894: ["#88ccff", "#cc4433", "#996699", "#bbee44", "#ff77cc"],
  9895: ["#0044ff", "#66ffff", "#dd66dd", "#228888", "#2233ff"],
  9896: ["#ee0055", "#887777", "#dd1177", "#339944", "#aa88ee"],
  9897: ["#44aaff", "#ff7766", "#55ee88", "#ccdd66", "#110099"],
  9898: ["#cc22dd", "#aa3311", "#ccaa33", "#dd77dd", "#9988ff"],
  9899: ["#eeeeaa", "#6611dd", "#bbdd33", "#ddee44", "#5588aa"],
  9900: ["#cc66ff", "#4400ee", "#55ff55", "#ff7700", "#6600aa"],
  9901: ["#116666", "#bbff88", "#eeff66", "#ee9944", "#330088"],
  9902: ["#5577cc", "#dd44dd", "#ff22dd", "#11ddee", "#dddd88"],
  9903: ["#2288ee", "#bb4466", "#99ffdd", "#886655", "#2211dd"],
  9904: ["#22ddbb", "#11aaee", "#cc44bb", "#997700", "#ddccaa"],
  9905: ["#cc6611", "#ddbbee", "#2211aa", "#00ee77", "#9955bb"],
  9906: ["#ee77aa", "#cc4433", "#3300aa", "#1188cc", "#dd1155"],
  9907: ["#00ddcc", "#bb22ee", "#22bb33", "#338833", "#33ff77"],
  9908: ["#66bb22", "#6644cc", "#55ffaa", "#bb00dd", "#004400"],
  9909: ["#cc00ff", "#440011", "#337799", "#ff22ee", "#ff88cc"],
  9910: ["#88ccbb", "#cc8888", "#bbcc99", "#aaff33", "#775599"],
  9911: ["#dd8877", "#0000aa", "#bb4411", "#ee7766", "#bbffdd"],
  9912: ["#9900bb", "#dddd99", "#11ee77", "#66aa55", "#bb2222"],
  9913: ["#000077", "#cc8855", "#772255", "#222277", "#aaff55"],
  9914: ["#66eeff", "#00ff55", "#aaee88", "#883333", "#665599"],
  9915: ["#bbbbdd", "#ff5555", "#7722dd", "#aa00dd", "#11ddff"],
  9916: ["#777788", "#ff2200", "#2211ee", "#66bb33", "#00dd77"],
  9917: ["#77bb55", "#229966", "#dd0066", "#ff8866", "#bb3388"],
  9918: ["#bb9966", "#3388dd", "#33eeaa", "#224400", "#dd55bb"],
  9919: ["#4400cc", "#220066", "#22bbcc", "#44aaff", "#001122"],
  9920: ["#dd1199", "#aa4477", "#aa5544", "#8822ee", "#99ddcc"],
  9921: ["#ff22ff", "#33bb77", "#88bbff", "#eeaadd", "#226677"],
  9922: ["#eeaadd", "#eebb44", "#7755ee", "#00ff55", "#55ccaa"],
  9923: ["#55eeee", "#110044", "#44eeff", "#660033", "#11cc88"],
  9924: ["#ffcc33", "#44ffff", "#117766", "#331166", "#771111"],
  9925: ["#aa7722", "#339966", "#aa44ee", "#aaff55", "#7744dd"],
  9926: ["#6633aa", "#33ddcc", "#aaaacc", "#bbee88", "#4422aa"],
  9927: ["#44ee99", "#ccff99", "#55ccff", "#664488", "#55aa22"],
  9928: ["#668822", "#7711ff", "#ccdd77", "#0066dd", "#66aa77"],
  9929: ["#66ccaa", "#556699", "#33ee77", "#559944", "#dd9977"],
  9930: ["#112244", "#ffee55", "#44bb55", "#11dd44", "#66aa22"],
  9931: ["#dd5511", "#55cc88", "#ff2233", "#22ddee", "#ffddcc"],
  9932: ["#447722", "#cc7788", "#88eeff", "#663333", "#bb7777"],
  9933: ["#337799", "#99dd22", "#6633aa", "#220000", "#cceeff"],
  9934: ["#228822", "#ffdd88", "#6699bb", "#22ccaa", "#bb6666"],
  9935: ["#77aaaa", "#66aaee", "#0088aa", "#eebb55", "#7799cc"],
  9936: ["#117744", "#77aa66", "#99ffff", "#331188", "#4400ee"],
  9937: ["#110066", "#aadd22", "#886633", "#11cc00", "#aa4488"],
  9938: ["#558888", "#ee99ee", "#006600", "#bb1188", "#11ff33"],
  9939: ["#44ddcc", "#11bb33", "#ddffee", "#11dd44", "#ee8800"],
  9940: ["#ee77dd", "#ee99bb", "#ffbbee", "#6644dd", "#998822"],
  9941: ["#ffff11", "#00aa00", "#558899", "#ddaaff", "#555588"],
  9942: ["#001155", "#660099", "#cc2222", "#33dd44", "#bb66dd"],
  9943: ["#eeeeaa", "#ccff22", "#ff5577", "#117777", "#00aa33"],
  9944: ["#882222", "#44aadd", "#ccdddd", "#dd44aa", "#aaffbb"],
  9945: ["#11ee33", "#11bbff", "#0033dd", "#8833cc", "#cc3388"],
  9946: ["#eedd66", "#ff1166", "#ff66cc", "#ee22aa", "#44dd99"],
  9947: ["#eedd55", "#ffaaaa", "#ddbbaa", "#ffcc11", "#bbee77"],
  9948: ["#330033", "#ccddcc", "#774400", "#ffcc44", "#bbaa88"],
  9949: ["#ccbb00", "#227744", "#0066bb", "#227777", "#114400"],
  9950: ["#66cc33", "#dd55cc", "#0066ee", "#331199", "#338888"],
  9951: ["#8800dd", "#aaccaa", "#44ccdd", "#ffddff", "#77aa88"],
  9952: ["#aa33ee", "#996600", "#66aa44", "#ff7799", "#661166"],
  9953: ["#44dd44", "#77dd11", "#ffee22", "#cceedd", "#330088"],
  9954: ["#bb0044", "#661177", "#00dd55", "#77bbbb", "#11ffee"],
  9955: ["#aaffff", "#4477aa", "#66bbbb", "#dd11ee", "#221188"],
  9956: ["#33ee22", "#ee99aa", "#446644", "#66dd44", "#22ee33"],
  9957: ["#002299", "#88dd00", "#5577ee", "#551122", "#333366"],
  9958: ["#aa33aa", "#6677ff", "#dd0044", "#55ee55", "#5555ee"],
  9959: ["#7733ee", "#228899", "#ff44aa", "#ee7777", "#119988"],
  9960: ["#7700cc", "#dd0055", "#669966", "#99dd77", "#aaaa22"],
  9961: ["#cc0033", "#dddd66", "#5522cc", "#cc22aa", "#55dd88"],
  9962: ["#ddffcc", "#ddddcc", "#aa1177", "#00ffcc", "#662211"],
  9963: ["#bb6699", "#aabbff", "#bbff55", "#ff55cc", "#cc44ff"],
  9964: ["#aa00ee", "#cc2244", "#ccee99", "#4433ff", "#884400"],
  9965: ["#7733cc", "#7766ee", "#6622ee", "#aa6600", "#22ee77"],
  9966: ["#33ee99", "#ccee44", "#ccdd77", "#ee0000", "#dd2266"],
  9967: ["#22ff55", "#aaddcc", "#22ffcc", "#bbee88", "#bb88ff"],
  9968: ["#dd7711", "#774466", "#dd99aa", "#ee9977", "#112200"],
  9969: ["#991188", "#99bbbb", "#dd1122", "#dd2255", "#6677dd"],
  9970: ["#aaff55", "#ff4466", "#ddff11", "#eeeeff", "#5544ff"],
  9971: ["#9955cc", "#eeee33", "#bbbb77", "#2244ff", "#ee2244"],
  9972: ["#cc0000", "#ddeecc", "#bbaaaa", "#11ff88", "#226699"],
  9973: ["#44bb00", "#1166ee", "#ff0033", "#6633ee", "#1177aa"],
  9974: ["#11aa11", "#cc99ff", "#6699ff", "#226622", "#eebbee"],
  9975: ["#55ee77", "#33aaaa", "#9933dd", "#669922", "#dd4455"],
  9976: ["#cc9933", "#33bb44", "#ffff55", "#eeddee", "#eebb33"],
  9977: ["#55cc44", "#aa8833", "#333322", "#ffff44", "#993322"],
  9978: ["#bbff00", "#4455bb", "#eecccc", "#446677", "#99ffcc"],
  9979: ["#ee9900", "#66ff00", "#000022", "#ff4411", "#992255"],
  9980: ["#dd6699", "#ccbb33", "#44ee00", "#dd22ff", "#ddff77"],
  9981: ["#998833", "#00ddaa", "#5544ff", "#88cc66", "#bb77dd"],
  9982: ["#99eeaa", "#55dddd", "#bbcc33", "#779911", "#116644"],
  9983: ["#aacc66", "#ddbb77", "#440099", "#bb44ee", "#007766"],
  9984: ["#6655cc", "#ccddee", "#eeccaa", "#cc44bb", "#77ff22"],
  9985: ["#55aa88", "#669911", "#aaaa77", "#aa00ff", "#aadd99"],
  9986: ["#883377", "#669933", "#ccaa11", "#227788", "#ff33ee"],
  9987: ["#bb00bb", "#5544dd", "#aa44cc", "#00ff88", "#22cc99"],
  9988: ["#998844", "#00dd99", "#aabb44", "#997700", "#dd5588"],
  9989: ["#88bbaa", "#334455", "#88bbaa", "#eeff55", "#227733"],
  9990: ["#aa7777", "#44ddaa", "#0088bb", "#bbccdd", "#55ff55"],
  9991: ["#228844", "#ff66ee", "#665577", "#000011", "#990088"],
  9992: ["#331155", "#00ff44", "#992222", "#9900bb", "#881177"],
  9993: ["#bbffbb", "#00ee11", "#225555", "#4444aa", "#228888"],
  9994: ["#440000", "#aa88dd", "#2233cc", "#228877", "#22dd22"],
  9995: ["#ee6633", "#881177", "#4411ee", "#aa55ff", "#11ddaa"],
  9996: ["#eeaa44", "#55bb66", "#11cc33", "#ee88ee", "#220066"],
  9997: ["#ddff55", "#7755aa", "#44bbff", "#00ff22", "#00ff55"],
  9998: ["#88bbee", "#778844", "#aa33ee", "#bb44bb", "#660099"],
  9999: ["#3311aa", "#9900ff", "#dd5522", "#ee3344", "#441111"],
  10000: ["#11dd22", "#6622cc", "#aaff99", "#116611", "#ee2255"],
};
